import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ReportService } from "../../../../service/reportService";
import { LeadActions } from "../../../../store/action";
import { CSVLink } from "react-csv";
import PageLoader from "../../../elements/PageLoader";
import { PureComponent } from "react";

const crmStatus = [
	{
		label: "Booking",
		value: 1,
	},
	{
		label: "Retail",
		value: 2,
	},
	{
		label: "Lost Purchase",
		value: 3,
	},
];

class RetailProcessWiseData extends PureComponent {
	csvLink = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			reportData: {},
			filterData: {},
			csvDataIndividual: [],
			make_map: {},
			model_map: {},
			city_map: {},
			selected_type: "Retail",
			loading: false,
			isSearchClicked: false,
		};
	}

	static getDerivedStateFromProps = (newProps, oldState) => {
		let updateState = {};
		if (oldState.reportData !== newProps.reportData) {
			updateState["reportData"] = newProps.reportData;
		}
		if (oldState.filterData !== newProps.filterData) {
			updateState["filterData"] = newProps.filterData;
		}
		if (newProps.make_map && newProps.make_map !== oldState.make_map) {
			updateState["make_map"] = newProps.make_map;
		}
		if (newProps.model_map && newProps.model_map !== oldState.model_map) {
			updateState["model_map"] = newProps.model_map;
		}
		if (newProps.city_map && newProps.city_map !== oldState.city_map) {
			updateState["city_map"] = newProps.city_map;
		}
		if (newProps.loading !== oldState.loading) {
			updateState["loading"] = newProps.loading;
		}
		if (newProps.isSearchClicked !== oldState.isSearchClicked) {
			updateState["isSearchClicked"] = newProps.isSearchClicked;
		}
		return updateState;
	};
	handleContentClick = (e) => {
		const {
			filterData: { process_id = null },
		} = this.state;
		let event = e.target;
		let value = event.innerText;
		if (event && value && +value > 0 && process_id) {
			let indentifier = event.getAttribute("data-indentifier");
			let slug = event.getAttribute("data-slug");
			const [month, year] = indentifier.split("_");
			this.requestCsvDownload(slug, process_id, month, year);
		}
	};
	requestCsvDownload = (slug, process_id, month, year) => {
		const { filterData } = this.state;
		// console.log(filterData);
		const postData = {
			slug: slug,
			report_type: "retail_process_summary",
			month: month,
			year: year,
			process_ids: process_id,
			...filterData,
			process_id: 0,
		};

		ReportService.getReportDetail(postData)
			.then((result) => {
				const {
					data: { data = [] },
				} = result;
				this.downloadCsvForDetail(data);
			})
			.catch((err) => {});
	};
	downloadCsvForDetail = (data) => {
		const { make_map, model_map, city_map } = this.state;
		if (data instanceof Array) {
			for (let i of data) {
				for (let j in i) {
					if (j === "Make") {
						i[j] = make_map[i[j]] ? make_map[i[j]] : "";
					} else if (j === "Model") {
						i[j] = model_map[i[j]] ? model_map[i[j]] : "";
					} else if (j === "City") {
						i[j] = city_map[i[j]] ? city_map[i[j]] : "";
					} else if (j === "CrossSell-City") {
						i[j] = city_map[i[j]] ? city_map[i[j]] : "";
					}
				}
			}

			this.setState({ csvDataIndividual: data }, async () => {
				if (data instanceof Array && data.length) {
					this.csvLink.current.link.click();
					await this.props.actions.SaveDownloadLog({
						module: "RetailReport",
						action: "1",
					});
				}
			});
		}
	};
	render() {
		let {
			reportData,
			csvDataIndividual,
			loading,
			selected_type,
			filterData,
			isSearchClicked,
		} = this.state;
		const found = Object.keys(reportData).length > 0;
		selected_type = crmStatus.find(
			(e) => e.value === (filterData.current_crm_status || 2)
		).label;

		return (
			<div className="container-fluid reportContainer">
				<h2>{this.props.t("RETAIL_PROCESS_REPORT.TITLE")}</h2>
				<div className="hidden">
					<CSVLink
						className="hidden"
						ref={this.csvLink}
						filename="lead_detail.csv"
						data={csvDataIndividual}
					>
						{" "}
					</CSVLink>
				</div>
				<div className="result-wrap">
					<div className="card">
						{isSearchClicked ? (
							<div className="table-responsive table-colFixed">
								<table className="table table-bordered table-hover salesReportTable ">
									<thead>
										<tr key={"head_row_1"}>
											<th></th>
											<th></th>
											<th></th>
											<th
												style={{
													textAlign: "center",
												}}
												colSpan="6"
											>
												{this.props.t(
													"RETAIL_PROCESS_REPORT.TABLE.DAYS"
												)}
											</th>
										</tr>
										<tr key={"head_row_2"}>
											<th>
												{this.props.t(
													"RETAIL_PROCESS_REPORT.TABLE.CREATED_MONTH"
												)}
											</th>
											<th>
												{this.props.t(
													"RETAIL_PROCESS_REPORT.TABLE.LEADS"
												)}
											</th>
											<th>
												{this.props.t(
													`RETAIL_PROCESS_REPORT.TABLE.${selected_type}`
												)}
											</th>
											<th>
												{this.props.t(
													"RETAIL_PROCESS_REPORT.TABLE.UPTO_15"
												)}
											</th>
											<th>
												{this.props.t(
													"RETAIL_PROCESS_REPORT.TABLE.UPTO_30"
												)}
											</th>
											<th>
												{this.props.t(
													"RETAIL_PROCESS_REPORT.TABLE.UPTO_60"
												)}
											</th>
											<th>
												{this.props.t(
													"RETAIL_PROCESS_REPORT.TABLE.UPTO_120"
												)}
											</th>
											<th>
												{this.props.t(
													"RETAIL_PROCESS_REPORT.TABLE.UPTO_180"
												)}
											</th>
											<th>
												{this.props.t(
													"RETAIL_PROCESS_REPORT.TABLE.GT_180"
												)}
											</th>
										</tr>
									</thead>
									<tbody onClick={this.handleContentClick}>
										{Object.keys(reportData).map(
											(obj, item) => (
												<tr key={`body_${item}`}>
													<td
														key={`body_key_${item}_1`}
													>
														{obj}
													</td>
													<td
														key={`body_key_${item}_2`}
														className={`${
															reportData[obj][
																"total_leads"
															] || 0
																? "report-csv-list"
																: ""
														}`}
														data-indentifier={
															reportData[obj][
																"identifier"
															]
														}
														data-slug={
															"total_leads"
														}
													>
														{reportData[obj][
															"total_leads"
														] || 0}
													</td>
													<td
														key={`body_key_${item}_3`}
														className={`${
															reportData[obj][
																"current_total"
															] || 0
																? "report-csv-list"
																: ""
														}`}
														data-indentifier={
															reportData[obj][
																"identifier"
															]
														}
														data-slug={
															"current_total"
														}
													>
														{reportData[obj][
															"current_total"
														] || 0}
													</td>
													<td
														key={`body_key_${item}_4`}
														className={`${
															reportData[obj][
																"upto_15_crm"
															] || 0
																? "report-csv-list"
																: ""
														}`}
														data-indentifier={
															reportData[obj][
																"identifier"
															]
														}
														data-slug={
															"upto_15_crm"
														}
													>
														{reportData[obj][
															"upto_15_crm"
														] || 0}
													</td>
													<td
														key={`body_key_${item}_5`}
														className={`${
															reportData[obj][
																"upto_30_crm"
															] || 0
																? "report-csv-list"
																: ""
														}`}
														data-indentifier={
															reportData[obj][
																"identifier"
															]
														}
														data-slug={
															"upto_30_crm"
														}
													>
														{reportData[obj][
															"upto_30_crm"
														] || 0}
													</td>
													<td
														key={`body_key_${item}_6`}
														className={`${
															reportData[obj][
																"upto_60_crm"
															] || 0
																? "report-csv-list"
																: ""
														}`}
														data-indentifier={
															reportData[obj][
																"identifier"
															]
														}
														data-slug={
															"upto_60_crm"
														}
													>
														{" "}
														{reportData[obj][
															"upto_60_crm"
														] || 0}
													</td>
													<td
														key={`body_key_${item}_7`}
														className={`${
															reportData[obj][
																"upto_120_crm"
															] || 0
																? "report-csv-list"
																: ""
														}`}
														data-indentifier={
															reportData[obj][
																"identifier"
															]
														}
														data-slug={
															"upto_120_crm"
														}
													>
														{reportData[obj][
															"upto_120_crm"
														] || 0}
													</td>
													<td
														key={`body_key_${item}_8`}
														className={`${
															reportData[obj][
																"upto_180_crm"
															] || 0
																? "report-csv-list"
																: ""
														}`}
														data-indentifier={
															reportData[obj][
																"identifier"
															]
														}
														data-slug={
															"upto_180_crm"
														}
													>
														{reportData[obj][
															"upto_180_crm"
														] || 0}
													</td>
													<td
														key={`body_key_${item}_9`}
														className={`${
															reportData[obj][
																"gt_180_crm"
															] || 0
																? "report-csv-list"
																: ""
														}`}
														data-indentifier={
															reportData[obj][
																"identifier"
															]
														}
														data-slug={"gt_180_crm"}
													>
														{reportData[obj][
															"gt_180_crm"
														] || 0}
													</td>
												</tr>
											)
										)}
									</tbody>
								</table>
							</div>
						) : (
							""
						)}
						{loading && (
							<PageLoader
								loaderClass=" text-center loader content-loader"
								loadertext="true"
							/>
						)}
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ inventory }) => {
	return {
		make_map: inventory.make_map ? inventory.make_map : {},
		model_map: inventory.model_map ? inventory.model_map : {},
		city_map:
			inventory.city instanceof Array
				? inventory.city.reduce(
						(obj, item) => ((obj[item.id] = item.name), obj),
						{}
				  )
				: {},
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			SaveDownloadLog: bindActionCreators(
				LeadActions.SaveDownloadLog,
				dispatch
			),
		},
	};
};
export default withTranslation("report")(
	connect(mapStateToProps, mapDispatchToProps)(RetailProcessWiseData)
);
