import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import SearchPerformanceReport from "./SearchPerformanceReport";
import { ReportService } from "../../../../service/reportService";
import PageLoader from "../../../elements/PageLoader";
import { CSVLink } from "react-csv";
import dateformat from "dateformat";
import config from "../../../../config/config";
import { LeadActions } from "../../../../store/action";
import { bindActionCreators } from "redux";

class AgentPerformanceReport extends Component {
	csvLink = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			performanceList: {},
			filterData: {},
			loading: false,
			isSearchClicked: false,
			csvDataIndividual: [],
			is_search: false,
			campaign_name: "",
			last_updated: "",
			csvJsonData: [],
			campaign_data: [],
			subHeaders: [],
			campaign_list: [],
		};
	}

	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		if (
			props.campaign_list &&
			props.campaign_list !== state.campaign_list
		) {
			updateProps["campaign_list"] = props.campaign_list;
		}
		if (props.make_map && props.make_map !== state.make_map) {
			updateProps["make_map"] = props.make_map;
		}
		if (props.model_map && props.model_map !== state.model_map) {
			updateProps["model_map"] = props.model_map;
		}
		if (props.city_map && props.city_map !== state.city_map) {
			updateProps["city_map"] = props.city_map;
		}
		return updateProps;
	}

	downloadCsvForDetail = (data) => {
		const { make_map, model_map, city_map } = this.state;
		if (data instanceof Array) {
			for (let i of data) {
				for (let j in i) {
					if (j === "Make") {
						i[j] = make_map[i[j]] ? make_map[i[j]] : "";
					} else if (j === "Model") {
						i[j] = model_map[i[j]] ? model_map[i[j]] : "";
					} else if (j === "City") {
						i[j] = city_map[i[j]] ? city_map[i[j]] : "";
					} else if (j === "CrossSell-City") {
						i[j] = city_map[i[j]] ? city_map[i[j]] : "";
					}
				}
			}

			this.setState({ csvDataIndividual: data }, async () => {
				if (data instanceof Array && data.length) {
					this.csvLink.current.link.click();
					await this.props.actions.SaveDownloadLog({
						module: "AdminPerformanceReport",
						action: "1",
					});
				}
			});
		}
	};

	getSlugPartition = (slug, encounter, len) => {
		let heading = "",
			tempData = [];
		if (slug.includes("cross_sell") && !encounter.includes("cross_sell")) {
			encounter.push("cross_sell");
			heading = this.props.t("AGENT_REPORT.CROSS_SELL");
		} else if (slug.includes("nyc_nyc") && !encounter.includes("nyc_nyc")) {
			encounter.push("nyc_nyc");
			heading = this.props.t("AGENT_REPORT.STATUS_CHANGED_TO");
		} else if (
			slug.includes("bought_a_car_unknown_new_to_attribution") &&
			!encounter.includes("bought_a_car_unknown_new_to_attribution")
		) {
			encounter.push("bought_a_car_unknown_new_to_attribution");
			heading = this.props.t("AGENT_REPORT.BOUGHT_A_CAR_UNKNOWN_BREAKUP");
		} else if (
			slug.includes("whatsapp_lead_click_count") &&
			!encounter.includes("whatsapp_lead_click_count")
		) {
			encounter.push("whatsapp_lead_click_count");
			heading = this.props.t(
				"AGENT_REPORT." +
					config.constants.WHATSAPP_TITLE +
					"_DISTRIBUTION"
			);
		}
		if (heading) {
			tempData.push(heading);
			for (let c = 0; c < len; c++) {
				tempData.push("");
			}
		}
		return tempData;
	};

	getTableData = () => {
		const { campaign_data } = this.state;
		let html = [],
			encounter = [];
		for (let i = 0; i < campaign_data.length; i++) {
			let tempObj = campaign_data[i];
			if (tempObj) {
				const tempRow = this.getSlugPartitionHtml(
					tempObj["slug"],
					encounter,
					Object.keys(tempObj).length - 2
				);
				if (tempRow.length) html.push(tempRow);
			}

			let innerTr = this.getInnerTr(i, campaign_data[i]);
			html.push(innerTr);
		}

		return html;
	};
	getParticularRecordDetail = (slug, property) => {
		const {
			filterData: {
				process_id,
				selected_month = "",
				campaign_id,
				agent_id,
			},
		} = this.state;
		let curMnth = new Date(selected_month).getMonth() + 1;

		const postData = {
			process_id: process_id,
			all_dates: property.indexOf("MTD") >= 0 ? true : false,
			month:
				property.indexOf("MTD") > 0
					? new Date(property.split(" ")[0] + "-1-01").getMonth() + 1
					: property.indexOf("MTD") == 0
					? curMnth
					: new Date().getMonth() + 1,
			slug: slug,
		};
		if (campaign_id) {
			postData["campaign_id"] = campaign_id;
		}
		if (selected_month) {
			postData["selected_month"] = selected_month;
		}

		const date = Date.parse(property)
			? dateformat(property, config.constants.DATE_FORMAT_YMD)
			: "";
		if (date) postData["date"] = date;
		postData["report_type"] = "agent_performance_report";
		postData["agent_id"] = agent_id;

		ReportService.getReportDetail(postData)
			.then((result) => {
				const {
					data: { data },
				} = result;
				this.downloadCsvForDetail(data);
			})
			.catch((err) => {});
	};

	getSlugPartitionHtml = (slug, encounter, len) => {
		let heading = "",
			tempData = [],
			html = [];
		console.log("slug======>", slug);
		if (slug.includes("cross_sell") && !encounter.includes("cross_sell")) {
			encounter.push("cross_sell");
			heading = <b>{this.props.t("AGENT_REPORT.CROSS_SELL")}</b>;
		} else if (slug.includes("nyc_nyc") && !encounter.includes("nyc_nyc")) {
			encounter.push("nyc_nyc");
			heading = <b>{this.props.t("AGENT_REPORT.STATUS_CHANGED_TO")}</b>;
		} else if (
			slug.includes("bought_a_car_unknown_new_to_attribution") &&
			!encounter.includes("bought_a_car_unknown_new_to_attribution")
		) {
			encounter.push("bought_a_car_unknown_new_to_attribution");
			heading = (
				<b>
					{this.props.t("AGENT_REPORT.BOUGHT_A_CAR_UNKNOWN_BREAKUP")}
				</b>
			);
		} else if (
			slug.includes("whatsapp_lead_click_count") &&
			!encounter.includes("whatsapp_lead_click_count")
		) {
			encounter.push("whatsapp_lead_click_count");
			heading = (
				<b>{this.props.t("AGENT_REPORT.WHATSAPP_DISTRIBUTION")}</b>
			);
		} else if (
			slug.includes("viber_lead_click_count") &&
			!encounter.includes("viber_lead_click_count")
		) {
			encounter.push("viber_lead_click_count");
			heading = <b>{this.props.t("AGENT_REPORT.VIBER_DISTRIBUTION")}</b>;
		}
		if (heading) {
			tempData.push(heading);
			for (let c = 0; c < len - 1; c++) {
				tempData.push("");
			}
			html.push(
				<tr key={`slu_tr_${slug}`}>
					{tempData.map((e, cuIn) => {
						return <td key={cuIn}>{e}</td>;
					})}
				</tr>
			);
		}
		return html;
	};

	getInnerTr = (i, campaign_data) => {
		return <tr key={i}>{this.getInnerTable(campaign_data, i)}</tr>;
	};

	getInnerTable = (obj, index) => {
		const { campaign_name } = this.state;
		let innerTd = [],
			countIndex = 0;

		const slug = obj["slug"] ? obj["slug"] : "";
		// const list_id = obj["List_id"] ? obj["List_id"] : "";
		let isHeading = false;

		for (let i in obj) {
			if (
				i !== "slug" &&
				i !== "description" /*&& i !== 'prevMonthRecord'*/
			) {
				isHeading = false;
				let property = "";
				if (
					slug &&
					!slug.includes("total_agent_loggened_in") &&
					!["prevMonth1", "prevMonth2"].includes(i) &&
					![campaign_name].includes(i)
				) {
					property = (
						<span
							className={`${obj[i] > 0 ? "report-csv-list" : ""}`}
							onClick={() =>
								obj[i] > 0
									? this.getParticularRecordDetail(slug, i)
									: ""
							}
						>
							{obj[i]}
						</span>
					);
				} else if (["prevMonth1", "prevMonth2"].includes(i)) {
					property = obj[i]["attempts"];
					isHeading = true;
				} else {
					property = obj[i];
					isHeading = true;
				}
				innerTd.push(
					<td
						key={`${countIndex}_${index}`}
						title={
							isHeading && obj.description ? obj.description : ""
						}
					>
						{property}
					</td>
				);
				countIndex++;
			}
		}

		return innerTd;
	};

	submitRequest = (filterData) => {
		this.setState({ loading: true, is_search: true });
		let {
			campaign_name,
			campaign_list,
			campaign_data,
			csvJsonData,
		} = this.state;
		campaign_data = [];

		ReportService.getAgentPerformanceReport(filterData)
			.then((result) => {
				let subHeader = [],
					encounter = [];

				const {
					data: { data: response = [] },
				} = result;

				if (response instanceof Array && response.length) {
					const data = response;
					const { process_id } = filterData;
					const campaignData = campaign_list.find(
						(e) => e.value === process_id
					);

					if (data.length) {
						campaign_name = Object.keys(data[0])[0];
						// campaign_name = campaignData.label;
					}

					csvJsonData = [];
					campaign_data = [];
					for (let i = 0; i < data.length; i++) {
						let rowData = [];

						for (let j in data[i]) {
							//console.log('jjjjjjj', j)
							if (j === "List_id") {
								// list_id = data[i][j];
							}
							if (
								![
									"slug",
									"description",
									"prevMonth1",
									"prevMonth2",
								].includes(j)
							) {
								if (i === 0) subHeader.push(j);
								rowData.push(data[i][j]);
							} else if (
								["prevMonth1", "prevMonth2"].includes(j)
							) {
								// for(let prevM of data[i][j]){
								if (i === 0)
									subHeader.push(data[i][j]["month_mtd"]);
								// }
							} else {
								if (["slug"].includes(j)) {
									const response = this.getSlugPartition(
										data[i][j],
										encounter,
										data[i].length - 2
									);
									if (response.length)
										csvJsonData.push(response);
								}
							}
						}

						csvJsonData.push(rowData);
					}

					campaign_data = data;
					if (subHeader instanceof Array && subHeader.length) {
						csvJsonData.unshift(subHeader);
					}
				}
				this.setState(
					{
						campaign_name: campaign_name,
						subHeaders: subHeader,
						csvJsonData: csvJsonData,
						campaign_data: campaign_data,
						// list_id: list_id,
						filterData: filterData,
						is_search: true,
						loading: false,
					},
					async () => {
						if (
							campaign_data instanceof Array &&
							campaign_data.length
						) {
							await this.props.actions.SaveDownloadLog({
								module: "AdminPerformanceReport",
								action: "2",
							});
						}
					}
				);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	render() {
		const {
			loading,
			csvDataIndividual,
			is_search,
			campaign_name,
			last_updated,
			csvJsonData,
			campaign_data,
			subHeaders,
		} = this.state;
		return (
			<div>
				<SearchPerformanceReport
					onSubmitFilter={this.submitRequest}
					loading={loading}
				/>

				<div className="hidden">
					<CSVLink
						className="hidden"
						ref={this.csvLink}
						filename="lead_detail.csv"
						data={csvDataIndividual}
					>
						{" "}
					</CSVLink>
				</div>

				<div className="container-fluid reportContainer">
					<h2>Agent Performance Report</h2>
					{is_search && (
						<div className="result-wrap">
							<div className="row mrg-b5">
								<div className="col-sm-8"></div>

								<div className="col-sm-4 text-right">
									<span>
										{csvJsonData && csvJsonData.length ? (
											<li className="searchitems text-right">
												<CSVLink
													data={csvJsonData}
													filename={
														"agent_performance_report.csv"
													}
													className="btn btn-link"
													target="_blank"
												>
													<i className="ic-file_download"></i>
													{this.props.t(
														"OPS_REPORT.DOWNLOAD_CSV"
													)}
												</CSVLink>
											</li>
										) : (
											""
										)}
									</span>
								</div>
							</div>
							<div className="card">
								<div className="table-responsive table-colFixed">
									{campaign_data instanceof Array &&
									campaign_data.length != 0 ? (
										<table className="table table-bordered table-hover salesReportTable ">
											<thead>
												<tr>
													{subHeaders instanceof Array
														? subHeaders.map(
																(e, i) => (
																	<th key={i}>
																		{e}
																	</th>
																)
														  )
														: ""}
												</tr>
											</thead>

											<tbody>{this.getTableData()}</tbody>
										</table>
									) : (
										this.props.t("OPS_REPORT.NOT_FOUND")
									)}
								</div>
								{loading && (
									<PageLoader
										loaderClass=" text-center loader content-loader"
										loadertext="true"
									/>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ inventory }) => {
	return {
		campaign_list:
			config.gm_campaign_list instanceof Array
				? config.gm_campaign_list
						.filter((f) => {
							return Number(f.dialer_flag) !== 1;
						})
						.map((e) => {
							return { value: e.id, label: e.name };
						})
				: [],
		make_map: inventory.make_map ? inventory.make_map : {},
		model_map: inventory.model_map ? inventory.model_map : {},
		city_map:
			inventory.city instanceof Array
				? inventory.city.reduce(
						(obj, item) => ((obj[item.id] = item.name), obj),
						{}
				  )
				: {},
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			SaveDownloadLog: bindActionCreators(
				LeadActions.SaveDownloadLog,
				dispatch
			),
		},
	};
};
export default withTranslation("report")(
	connect(mapStateToProps, mapDispatchToProps)(AgentPerformanceReport)
);
