import MasterService from "./MasterService";

export const AuthService = {
	...MasterService,

	login(username, password) {
		return this.post("/user/login", { username, password });
	},
	loginCaptcha() {
		return this.get("/user/get_captcha_image");
	},

	forgotPassword(postData) {
		return this.post("/user/forgot_password", postData);
	},

	authLogout() {
		return this.get("/auth/logout", {});
	},

	getRefreshToken(postData) {
		return this.post("/user/getRefreshToken", postData);
	},

	resendOtp(postData) {
		return this.post("/user/resendOtp", postData);
	},

	getResetPasswordToken(postData) {
		return this.post("/user/getResetPasswordToken", postData);
	},

	validateResetToken(postData) {
		return this.post("/user/validateResetToken", postData);
	},

	resetPassword(postData) {
		return this.post("/user/resetPassword", postData);
	},
	getLeadAccess() {
		return this.get("/roles/get_user_access_list");
	},
	getSiteSettings() {
		const postData = { config_data: ["site_settings"] };
		return this.post("/config/get_data", postData);
	},
};
