import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { LeadService } from "../../../service/leadService";
import { toast } from "react-toastify";
import PageLoader from "../../elements/PageLoader";
import { ConfigAction, LeadActions } from "../../../store/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
class UpdateSpkDateviaCsv extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			headers: [
				{ name: "BULK_UPLOAD.HEADER.MOBILE" },
				{ name: "BULK_UPLOAD.HEADER.SPK_DATE" },
				{ name: "BULK_UPLOAD.HEADER.SPK_TYPE" },
			],
			action: "leadBulkUpload",
			isPremiumField: true,
			errors: {},
			formData: {},
			errorList: [],
			alreadyProcessed: [],
		};
	}

	handleChange = async (key, event) => {
		let { formData, errors, is_future_date } = this.state;
		if (event) {
			formData[key] = event.list_id;
			errors = "";
		} else {
			delete formData[key];
		}

		await this.setState({
			formData: formData,
			errors: errors,
			is_future_date: is_future_date,
		});
	};

	onChangeHandler = (event) => {
		let selectedFile = event.target.files[0];
		if (
			selectedFile &&
			selectedFile.name &&
			selectedFile.name.split(".")[1] !== "csv"
		) {
			toast.error(this.props.t("BULK_UPLOAD.UPLOAD_CSV_FILE_ONLY"));
		} else {
			this.setState({
				selectedFile: selectedFile,
			});
		}
	};

	submitUpload = async () => {
		let postData = {};
		if (this.state.selectedFile) {
			this.setState({ loading: true });
			postData = new FormData();
			postData.append("file", this.state.selectedFile);
			let uploadResult = await LeadService.updateLeadSpkDateViaCsv(
				postData
			);
			if (
				uploadResult &&
				uploadResult.status === 200 &&
				uploadResult.data
			) {
				toast.success(uploadResult.data.message);
				this.setState({
					loading: false,
					formData: {},
					selectedFile: null,
					errors: {},
					errorList:
						uploadResult.data.data && uploadResult.data.data.errors
							? uploadResult.data.data.errors
							: [],
					alreadyProcessed:
						uploadResult.data.data &&
						uploadResult.data.data.alreadyProcessed
							? uploadResult.data.data.alreadyProcessed
							: [],
				});
				this.fileInput.value = "";
			} else {
				this.setState({
					loading: false,
					selectedFile: null,
					errorList:
						uploadResult.data.data && uploadResult.data.data.errors
							? uploadResult.data.data.errors
							: [],
					alreadyProcessed:
						uploadResult.data.data &&
						uploadResult.data.data.alreadyProcessed
							? uploadResult.data.data.alreadyProcessed
							: [],
				});
				this.fileInput.value = "";
			}

			await this.props.actions.SaveDownloadLog({
				module: "UpdateSpkDateViaCsv",
				action: "3",
			});
		} else {
			toast.error(this.props.t("BULK_UPLOAD.SELECT_FILE_TO_UPLOAD"));
			this.setState({ loading: false });
		}
	};

	render() {
		const { headers, loading, errorList, alreadyProcessed } = this.state;
		console.log("errorList", errorList);
		return (
			<div className="container-fluid">
				<h1>{this.props.t("BULK_UPLOAD.IMPROT_DATA")}</h1>
				<div className="col-md-8 col-md-offset-2 bulkUploadLeads">
					{loading && (
						<PageLoader
							loaderClass=" text-center loader content-loader"
							loadertext="true"
						/>
					)}

					<div className="row">
						<div className=" clearfix  mrg-T20">
							<div className="col-sm-3">
								<label>
									<strong>
										{this.props.t(
											"BULK_UPLOAD.SELECT_FILE_TO_UPLOAD"
										)}
									</strong>
									<span className="text-danger">*</span>
								</label>
							</div>

							<div className="col-sm-4">
								<div className=" form-group">
									<input
										type="file"
										id="file"
										name="myfile"
										onChange={this.onChangeHandler}
										ref={(ref) => (this.fileInput = ref)}
									/>
									<span></span>
									<div id="fileErr"></div>
								</div>
							</div>

							<div className="col-sm-3 clearfix form-group">
								<span className="mrg-L40">
									<a
										href="/files/LeadSpkdateSample.csv"
										target="_blank"
										download="LeadSpkdateSample.csv"
									>
										{this.props.t(
											"BULK_UPLOAD.DOWNLOAD_SAMPALE_FILE"
										)}
									</a>
								</span>
							</div>
						</div>

						<div className="clearfix mrg-T20">
							<div className="col-sm-12">
								<label>
									<strong>
										{this.props.t(
											"BULK_UPLOAD.REQUIRED_FORMAT"
										)}
									</strong>
									<span>*</span>
								</label>
							</div>
						</div>

						<div className="clearfix">
							<div className="col-sm-12 table-responsive">
								<table className="table table-bordered table-striped table-hover enquiry-table">
									<tbody>
										<tr>
											{headers &&
												headers.map((el, key) => {
													return (
														<th key={key}>
															{this.props.t(
																el.name
															)}
														</th>
													);
												})}
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div className="clearfix">
							<div className="col-sm-10">
								<div id="status"></div>
							</div>
						</div>

						<div className="clearfix mrg-T20 form-group">
							<div className="col-sm-3 text-right"></div>
							<div className="col-sm-4">
								{loading ? (
									<button
										type="submit"
										className="btn btn-lg btn-primary btn- pull-right"
										disabled
									>
										{this.props.t("BULK_UPLOAD.UPLOADING")}
									</button>
								) : (
									<button
										type="submit"
										className="btn btn-lg btn-primary btn- pull-right"
										onClick={this.submitUpload}
										disabled={
											this.state.selectedFile
												? false
												: true
										}
									>
										{this.props.t("BULK_UPLOAD.UPLOAD")}
									</button>
								)}
							</div>
						</div>

						{errorList && errorList.length ? (
							<div className="selectFilters">
								{errorList.map((current_val, Index) => (
									<span
										key={Index}
										className="text-danger badge mrg-r10"
									>
										{`${current_val.msg}(${
											current_val.mobile
										})-${this.props.t(
											"BULK_UPLOAD.ROW_NO"
										)}:${current_val.row_no}`}
									</span>
								))}
							</div>
						) : (
							""
						)}

						{alreadyProcessed && alreadyProcessed.length ? (
							<div className="selectFilters">
								<hr></hr>
								{alreadyProcessed.map((current_val, Index) => (
									<span
										key={Index}
										className="text-danger badge mrg-r10"
									>
										{`${this.props.t(
											"BULK_UPLOAD.ALLREADY_PROCESSED"
										)}(${
											current_val.mobile
										})-${this.props.t(
											"BULK_UPLOAD.ROW_NO"
										)}:${current_val.row_no}`}
									</span>
								))}
							</div>
						) : (
							""
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
			SaveDownloadLog: bindActionCreators(
				LeadActions.SaveDownloadLog,
				dispatch
			),
		},
	};
};
export default withTranslation("webfrom")(
	connect(mapStateToProps, mapDispatchToProps)(UpdateSpkDateviaCsv)
);
