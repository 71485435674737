/* eslint-disable */
import React, { Component } from "react";
import InputField from "../../../elements/InputField";
import Select from "react-select";
import { FordReportService } from "../../../../service/FordReportService";
import { LeadService } from "../../../../service/leadService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import PageLoader from "../../../elements/PageLoader";
import { Redirect } from "react-router-dom";
class EditSm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: this.props.match.params.id ? true : false,
			leadinfo: this.props.leadinfo || {},
			lead_id: this.props.match.params.id || 0,
			errors: {},
			redirectTolist: false,
			regexp: /^[0-9\b]+$/,
			regexpEmail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			ford_dealers: this.props.ford_dealers || [],
		};
	}

	componentDidMount = async () => {
		await this.getConfigData();
		if (this.state.lead_id) await this.getDetail();
	};

	getConfigData = async () => {
		const requestBody = {
			config_data: ["ford_dealers"],
		};
		this.setState({ loading: true });
		LeadService.getConfig(requestBody)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					this.setState({
						ford_dealers: response.data.data.ford_dealers || {},
						loading: false,
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	getDetail = async () => {
		this.setState({ loading: true });
		let lead_id = this.state.lead_id;
		FordReportService.getSmDetail({ id: lead_id })
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let leadinfo = response.data.data ? response.data.data : {};
					this.setState({
						leadinfo: leadinfo || {},
						loading: false,
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	handleChange = async (sname, ovalue) => {
		const { leadinfo } = this.state;
		if (sname === "dealer_id") {
			leadinfo[sname] = ovalue.dealer_id;
		} else {
			leadinfo[sname] = ovalue.target.value;
		}

		this.setState({
			leadinfo: leadinfo,
		});
	};

	validForm = () => {
		let { leadinfo, regexpEmail } = this.state;
		let formIsValid = true,
			errors = {};

		if (!leadinfo["sm_id"]) {
			errors["sm_id"] = this.props.t("FORM.VALIDATION_ERROR.REQUIRED");
			formIsValid = false;
		}
		if (!leadinfo["dealer_id"]) {
			errors["dealer_id"] = this.props.t(
				"FORM.VALIDATION_ERROR.REQUIRED"
			);
			formIsValid = false;
		}
		if (!leadinfo["sm_name"]) {
			errors["sm_name"] = this.props.t("FORM.VALIDATION_ERROR.REQUIRED");
			formIsValid = false;
		}

		if (!leadinfo["sm_email"]) {
			errors["sm_email"] = this.props.t("FORM.VALIDATION_ERROR.REQUIRED");
			formIsValid = false;
		} else if (
			!regexpEmail.test(String(leadinfo["sm_email"]).toLowerCase())
		) {
			errors["sm_email"] = this.props.t(
				"FORM.VALIDATION_ERROR.INVALID_EMAIL"
			);
			formIsValid = false;
		}

		this.setState({ errors: errors });
		return formIsValid;
	};

	saveLead = (event) => {
		event.preventDefault();
		let { leadinfo, lead_id } = this.state;
		if (lead_id) leadinfo["id"] = lead_id;
		if (this.validForm()) {
			this.setState({ loading: true });
			leadinfo["status"] = Number(leadinfo["status"]) === 1 ? "1" : "0";
			FordReportService.saveSm(leadinfo)
				.then((response) => {
					if (
						response.status === 200 &&
						response.data.status === 200
					) {
						toast.success(response.data.message);
						this.setState({
							leadinfo: leadinfo,
							loading: false,
							redirectTolist: true,
						});
					} else {
						this.setState({ loading: false });
					}
				})
				.catch((error) => {
					toast.error(error);
				});
		}
	};
	handleRedirect = () => {
		this.setState({ redirectTolist: true });
	};

	render() {
		const {
			loading,
			leadinfo,
			lead_id,
			errors,
			redirectTolist,
			ford_dealers,
		} = this.state;

		if (redirectTolist) return <Redirect to="/sm_list" />;
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-6 col-sm-offset-3">
						<h1>
							{lead_id
								? this.props.t("FORM.UPDATE_LEAD_SM") +
								  ` #${lead_id}`
								: this.props.t("FORM.ADD_LEAD_SM")}
						</h1>
						<div className="card">
							{loading && (
								<PageLoader loaderClass="text-center" />
							)}

							<div className="card-body">
								<div className="row">
									<form onSubmit={this.saveLead}>
										<div className="col-sm-6 col-md-6">
											<InputField
												inputProps={{
													id: "sm_id",
													type: "text",
													value: leadinfo.sm_id || "",
													name: "sm_id",
													autoComplete: "off",
													placeholder: this.props.t(
														"FORM.SM_ID"
													),
													label: this.props.t(
														"FORM.SM_ID"
													),
													dataerror:
														errors.sm_id || "",
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"sm_id"
												)}
											/>
										</div>

										<div className="col-sm-6 col-md-6">
											<InputField
												inputProps={{
													id: "sm_name",
													type: "text",
													value:
														leadinfo.sm_name || "",
													name: "sm_name",
													autoComplete: "off",
													placeholder: this.props.t(
														"FORM.SM_NAME"
													),
													label: this.props.t(
														"FORM.SM_NAME"
													),
													dataerror:
														errors.sm_name || "",
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"sm_name"
												)}
											/>
										</div>

										<div className="col-sm-6 col-md-6">
											<InputField
												inputProps={{
													id: "sm_email",
													type: "text",
													value:
														leadinfo.sm_email || "",
													name: "sm_email",
													autoComplete: "off",
													placeholder: this.props.t(
														"FORM.SM_EMAIL"
													),
													label: this.props.t(
														"FORM.SM_EMAIL"
													),
													dataerror:
														errors.sm_email || "",
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"sm_email"
												)}
											/>
										</div>

										<div className="col-sm-6 col-md-6 form-group">
											<label>
												{this.props.t(
													"FORM.DEALER_CODE"
												)}
											</label>

											<Select
												id="dealer_id"
												onChange={this.handleChange.bind(
													this,
													"dealer_id"
												)}
												options={ford_dealers}
												name="dealer_id"
												placeholder={this.props.t(
													"LIST.DEALER_CODE"
												)}
												value={ford_dealers.filter(
													({ dealer_id }) =>
														dealer_id ===
														leadinfo.dealer_id
												)}
												getOptionLabel={({ name }) =>
													name
												}
												getOptionValue={({
													dealer_id,
												}) => dealer_id}
											/>
											<span className="error show">
												{errors.dealer_id || ""}
											</span>
										</div>

										<div className="col-sm-12 form-group text-right">
											<button
												type="button"
												className="btn btn-default mrg-r15"
												onClick={this.handleRedirect}
											>
												{this.props.t("FORM.CANCEL")}
											</button>
											<button
												type="submit"
												className="btn btn-primary "
											>
												{lead_id
													? this.props.t(
															"FORM.UPDATE"
													  )
													: this.props.t(
															"FORM.CREATE"
													  )}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation("ford")(EditSm);
