import React, { Component } from "react";
import InputField from "../../../elements/InputField";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import PageLoader from "../../../elements/PageLoader";
import { webFormService } from "../../../../service/webFormService";
import Pagination from "react-js-pagination";
class UtmCampaigns extends Component {
	constructor(props) {
		super(props);
		this.state = {
			utmDatainfo: this.props.utmDatainfo || {},
			loading: false,
			errors: {},
			utmDataList: this.props.utmDataList || [],
			pagination: this.props.pagination || {},
			filterData: { page: 1 },
		};
	}
	componentDidMount = async () => {
		await this.getUtmList();
	};

	getUtmList = async () => {
		let { filterData } = this.state;
		filterData.webid_id = this.props.webids_id;
		this.setState({ loading: true });
		webFormService
			.getUtmList(filterData)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let utmDataList = response.data.data.utmCampaignInfo || [];
					let pagination = response.data.data.pagination || {};
					this.setState({
						utmDataList: utmDataList,
						pagination: pagination,
						loading: false,
					});
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	submitFilterForm = (event) => {
		event.preventDefault();
		let { utmDatainfo } = this.state;
		utmDatainfo["webid_id"] = this.props.webids_id || "";
		if (this.validForm()) {
			this.setState({ loading: true });
			webFormService
				.saveUtm(utmDatainfo)
				.then((response) => {
					if (
						response.status === 200 &&
						response.data.status === 200
					) {
						toast.success(response.data.message);
						this.setState(
							{
								loading: false,
								utmDatainfo: {},
							},
							() => {
								this.getUtmList();
							}
						);
					} else {
						this.setState({ loading: false });
					}
				})
				.catch((error) => {
					toast.error(error);
				});
		}
	};

	validForm = () => {
		let { utmDatainfo } = this.state;
		let formIsValid = true,
			errors = {};
		if (!utmDatainfo["utm_campaign_name"]) {
			errors["utm_campaign_name"] = this.props.t(
				"WEBIDS.VALIDATION_ERROR.REQUIRED"
			);
			formIsValid = false;
		}
		this.setState({ errors: errors });
		return formIsValid;
	};

	handleChange = (sname, valueObj) => {
		let { utmDatainfo } = this.state;
		let value = valueObj.value || valueObj.id;
		if (sname === "utm_campaign_name") value = valueObj.target.value;
		utmDatainfo[sname] = value;
		this.setState({ utmDatainfo: utmDatainfo });
	};

	handlePageChange(pageNumber) {
		let { filterData } = this.state;
		filterData["page"] = pageNumber;
		this.setState({ filterData: filterData, loading: true });
		this.getUtmList();
	}

	render() {
		const {
			utmDatainfo,
			loading,
			errors,
			utmDataList,
			pagination,
			filterData,
		} = this.state;
		return (
			<div className="card">
				<div className="card-heading">
					<h2 className="card-title">
						{this.props.t("WEBIDS.UTM_CAMPAIGN")}
					</h2>
				</div>
				<div className="card-body">
					{loading && <PageLoader loaderClass="text-center" />}
					<form onSubmit={this.submitFilterForm}>
						<ul className="search-flex">
							<li className="searchitems">
								<InputField
									inputProps={{
										id: "utm_campaign_name",
										type: "text",
										value:
											utmDatainfo.utm_campaign_name || "",
										name: "utm_campaign_name",
										autoComplete: "off",
										placeholder: this.props.t(
											"WEBIDS.UTM_CAMPAIGN_NAME"
										),
										label: this.props.t(
											"WEBIDS.UTM_CAMPAIGN_NAME"
										),
										dataerror:
											errors.utm_campaign_name || "",
									}}
									autoComplete="off"
									onChange={this.handleChange.bind(
										this,
										"utm_campaign_name"
									)}
								/>
							</li>

							<li className="searchitems form-group">
								<div>
									<label></label>
								</div>
								<div>
									<button
										type="submit"
										className="btn btn-primary"
									>
										{this.props.t("WEBIDS.ADD")}
									</button>
								</div>
							</li>
						</ul>
					</form>

					<div className="table-responsive">
						<table className="table table-bordered-h table-hover ">
							<thead>
								<tr>
									<th>
										{this.props.t(
											"WEBIDS.UTM_CAMPAIGN_NAME"
										)}
									</th>

									{/* <th>{this.props.t("WEBIDS.ACTION")}</th> */}
								</tr>
							</thead>

							<tbody>
								{utmDataList.length > 0 ? (
									utmDataList.map((singleinfo, index) => (
										<React.Fragment key={singleinfo.id}>
											<tr>
												<td>
													<div>
														{singleinfo.utm_campaign_name ||
															""}
													</div>
												</td>

												{/* <td>
													<div></div>
												</td> */}
											</tr>
										</React.Fragment>
									))
								) : (
									<tr>
										<td colSpan="10">
											{!loading ? (
												<h6 className="text-danger">
													{this.props.t(
														"WEBIDS.NOT_FOUND"
													)}
												</h6>
											) : (
												""
											)}
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
					<div className="clearfix">
						{pagination.totalpage >= 1 ? (
							<div className="main-div-pagination">
								<div className="col-sm-6 text-light">
									{this.props.t("WEBIDS.SHOWING")}{" "}
									{filterData.page}{" "}
									{this.props.t("WEBIDS.OF")}{" "}
									{pagination.totalpage}{" "}
									{this.props.t("WEBIDS.PAGES")}
								</div>
								<div className="col-sm-6">
									<Pagination
										activePage={filterData.page}
										itemsCountPerPage={pagination.pagelimit}
										totalItemsCount={
											pagination.totalrecords
										}
										pageRangeDisplayed={5}
										itemClass="page-item"
										linkClass="page-link"
										onChange={this.handlePageChange}
									/>
								</div>
							</div>
						) : (
							""
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation("webfrom")(UtmCampaigns);
