import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { default as config } from "../../../../config/config";
import Select from "react-select";
import { ConfigAction } from "../../../../store/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import * as constant from "../../../../config/constant";
const currenDate = new Date();
var curryear = currenDate.getFullYear(),
	currmonth = currenDate.getMonth();
const hours = currenDate.getHours();
const day2add =
	hours >= constant.SETTING.OFFICE_START_TIME &&
	hours <= constant.SETTING.OFFICE_END_TIME - 1
		? 5
		: 31;
class SearchAssignCarReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterData: {
				start_date: new Date(),
				end_date: new Date(),
			},
			campaign_list: [],
			csvJsonData: [],
			max_date: new Date(),
		};
	}

	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		if (props.csvJsonData && props.csvJsonData !== state.csvJsonData) {
			updateProps["csvJsonData"] = props.csvJsonData;
		}
		return updateProps;
	}
	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		if (props.gm_id && props.gm_id !== state.gm_id) {
			updateProps["gm_id"] = props.gm_id;
		}
		return updateProps;
	}
	addDays = (date, days) => {
		var result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	};
	handleChange = async (sname, ovalue) => {
		let { filterData, max_date } = this.state;

		if (ovalue && ovalue.value) {
			filterData[sname] = ovalue.value;
		} else if (sname instanceof Date) {
			filterData[ovalue] = new Date(sname);
			if (ovalue === "start_date") {
				delete filterData["end_date"];
			}
		} else {
			delete filterData[sname];
		}
		max_date = this.addDays(
			filterData["start_date"] ? filterData["start_date"] : new Date(),
			day2add
		);
		if (max_date > new Date()) {
			max_date = new Date();
		}

		this.setState({
			filterData: filterData,
			max_date: max_date,
		});
	};
	componentDidMount = () => {
		this.getConfigData();
	};
	resetFilterForm = () => {
		this.setState({
			filterData: {},
		});
	};
	getConfigData = async () => {
		const requestBody = {
			config_data: ["gm_campaign_process_list"],
		};
		this.props.actions.getConfigData(requestBody);
	};
	submitFilterForm = (event) => {
		if (event) event.preventDefault();
		const { filterData } = this.state;
		if (typeof this.props.onSubmitFilter === "function") {
			this.props.onSubmitFilter(filterData);
		}
	};
	render() {
		const { filterData, gm_id, max_date } = this.state;
		let disableBtn = !filterData.end_date ? true : false;
		return (
			<div className="search-wrap">
				<form onSubmit={this.submitFilterForm}>
					<ul className="search-flex">
						<li className="searchitems selectWidth form-group">
							<DatePicker
								className="form-control"
								autoComplete="off"
								placeholderText={this.props.t(
									"ASSIGN_CAR.START_DATE"
								)}
								name="start_date"
								id="start_date"
								onChange={(date) =>
									this.handleChange(date, "start_date")
								}
								dateFormat={config.constants.DATE_FORMAT_INPUT}
								maxDate={new Date()}
								selected={filterData.start_date || ""}
								onKeyDown={(e) => e.preventDefault()}
								title={this.props.t("ASSIGN_CAR.START_DATE")}
							/>
						</li>
						<li className="searchitems selectWidth form-group">
							<DatePicker
								className="form-control"
								autoComplete="off"
								placeholderText={this.props.t(
									"ASSIGN_CAR.END_DATE"
								)}
								name="end_date"
								id="end_date"
								onChange={(date) =>
									this.handleChange(date, "end_date")
								}
								dateFormat={config.constants.DATE_FORMAT_INPUT}
								minDate={filterData.start_date}
								maxDate={max_date}
								selected={filterData.end_date || ""}
								onKeyDown={(e) => e.preventDefault()}
								title={this.props.t("ASSIGN_CAR.END_DATE")}
							/>
						</li>
						<li
							className="searchitems selectWidth form-group"
							title={this.props.t("ASSIGN_CAR.CAMPAIGN")}
						>
							<Select
								id="campaign_select"
								options={gm_id}
								name="campaign_id"
								isClearable
								onChange={this.handleChange.bind(
									this,
									"campaign_id"
								)}
								placeholder={this.props.t(
									"ASSIGN_CAR.CAMPAIGN"
								)}
							/>
						</li>
						<li className="searchitems form-group">
							<div>
								<button
									type="submit"
									className="btn btn-primary"
									disabled={disableBtn}
								>
									{this.props.t("ASSIGN_CAR.DOWNLOAD_CSV")}
								</button>
							</div>
						</li>
					</ul>
					<span className="assign-report-date-restriction">
						{this.props.t("ASSIGN_CAR.DAYS_RESTRICTION")}
					</span>
					<br />
					<span className="assign-report-date-restriction">
						{this.props.t(
							"ASSIGN_CAR.DAYS_RESTRICTION_IN_OFFICE_HOUR"
						)}
					</span>
				</form>
			</div>
		);
	}
}
const mapStateToProps = ({ config }) => {
	return {
		gm_id:
			config.gm_campaign_process_list instanceof Array
				? config.gm_campaign_process_list.map(function (e) {
						return {
							label: `${
								e.campaign_name
									? e.campaign_name.charAt(0).toUpperCase() +
									  e.campaign_name.slice(1)
									: ""
							} - ${e.gm_id}`,
							value: e.campaign_id,
						};
				  })
				: [],
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
		},
	};
};
export default withTranslation("report")(
	connect(mapStateToProps, mapDispatchToProps)(SearchAssignCarReport)
);
