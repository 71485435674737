import React, { Component } from "react";
import { default as Config } from "../../../../../config/config";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { ConfigAction } from "../../../../../store/action";
import InputField from "../../../../elements/InputField";

class SearchProcessCampaignList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchItems: {},
			processList: [],
			campaignList: [],
		};
	}
	handleChange = (sname, ovalue) => {
		const { target } = ovalue;
		const { searchItems } = this.state;
		if (target) {
			const { value } = target;
			searchItems[sname] = value;
		} else {
			searchItems[sname] = ovalue.value;
		}
		if (!searchItems[sname]) delete searchItems[sname];
		this.setState({ searchItems: searchItems });
	};
	static getDerivedStateFromProps = (nextProps, prevProps) => {
		const updateProps = [];
		if (
			nextProps.processList &&
			nextProps.processList !== prevProps.processList &&
			prevProps.processList &&
			prevProps.processList.length === 0
		) {
			updateProps["processList"] = nextProps.processList.map(function (
				e
			) {
				return {
					value: e.process_id,
					label: e.name,
				};
			});
		}
		if (
			nextProps.campaignList &&
			nextProps.campaignList !== prevProps.campaignList
		) {
			updateProps["campaignList"] = nextProps.campaignList;
		}
		return updateProps;
	};
	componentDidMount = () => {
		this.getConfigData();
	};
	getConfigData = () => {
		const requestBody = {
			config_data: ["process_list", "campaign_list"],
		};
		this.props.actions.getConfigData(requestBody);
	};
	getSearchRecord = (reset = false, obj) => {
		obj.preventDefault();
		let { searchItems } = this.state;
		if (reset) {
			searchItems = {};
			this.setState({ searchItems: {} });
		}
		this.props.getProcessCampaignList(searchItems);
	};
	render() {
		const { searchItems, processList, campaignList } = this.state;

		return (
			<div className="search-wrap">
				<form onSubmit={this.submitFilterForm}>
					<ul className="search-flex">
						<li className="searchitems">
							<InputField
								inputProps={{
									id: "list_name",
									type: "text",
									value: searchItems["list_name"] || "",
									name: "list_name",
									autoComplete: "off",
									placeholder: this.props.t(
										"LIST_PROCESS_CAMPAIGN.LIST_NAME"
									),
									label: this.props.t(
										"LIST_PROCESS_CAMPAIGN.LIST_NAME"
									),
								}}
								autoComplete="off"
								onChange={this.handleChange.bind(
									this,
									"list_name"
								)}
							/>
						</li>
						<li className="searchitems">
							<InputField
								inputProps={{
									id: "list_id",
									type: "text",
									value: searchItems["list_id"] || "",
									name: "list_id",
									autoComplete: "off",
									placeholder: this.props.t(
										"LIST_PROCESS_CAMPAIGN.LIST_ID"
									),
									label: this.props.t(
										"LIST_PROCESS_CAMPAIGN.LIST_ID"
									),
								}}
								autoComplete="off"
								onChange={this.handleChange.bind(
									this,
									"list_id"
								)}
							/>
						</li>

						<li className="searchitems selectWidth form-group">
							<Select
								id="process_id"
								onChange={this.handleChange.bind(
									this,
									"process_id"
								)}
								options={processList}
								name="process_id"
								placeholder={this.props.t(
									"LIST_PROCESS_CAMPAIGN.PROCESS_ID"
								)}
								value={processList.filter(
									({ value }) =>
										value === searchItems.process_id
								)}
								getOptionLabel={({ label }) => label}
								getOptionValue={({ value }) => value}
							/>
						</li>
						<li className="searchitems selectWidth form-group">
							<Select
								id="campaign_id"
								onChange={this.handleChange.bind(
									this,
									"campaign_id"
								)}
								options={campaignList}
								name="process_id"
								placeholder={this.props.t(
									"LIST_PROCESS_CAMPAIGN.CAMPAIGN_NAME"
								)}
								value={campaignList.filter(
									({ value }) =>
										value === searchItems.campaign_id
								)}
								getOptionLabel={({ label }) => label}
								getOptionValue={({ value }) => value}
							/>
						</li>
						<li className="searchitems selectWidth form-group">
							<Select
								id="active_sattus"
								onChange={this.handleChange.bind(
									this,
									"status"
								)}
								options={Config.constants.ACTIVE_STATUS}
								name="status"
								placeholder={this.props.t(
									"LIST_PROCESS_CAMPAIGN.ACTIVE_STATUS"
								)}
								value={Config.constants.ACTIVE_STATUS.filter(
									({ value }) => value === searchItems.status
								)}
								getOptionLabel={({ label }) => label}
								getOptionValue={({ value }) => value}
							/>
						</li>
						{/* <li className="searchitems">
							<InputField
                                inputProps={{
									id: "trigger_name",
									type: "text",
									value: searchItems['trigger_name'] || "",
									name: "trigger_name",
									autoComplete: "off",
									placeholder: this.props.t("LIST_PROCESS_CAMPAIGN.TRIGGER_NAME"),
									label: this.props.t("LIST_PROCESS_CAMPAIGN.TRIGGER_NAME"),
								}}
								autoComplete="off"
								onChange={this.handleChange.bind(this, "trigger_name")}
                            />
						</li> */}
						<li className="searchitems">
							<InputField
								inputProps={{
									id: "description",
									type: "text",
									value: searchItems["description"] || "",
									name: "description",
									autoComplete: "off",
									placeholder: this.props.t(
										"LIST_PROCESS_CAMPAIGN.DESCRIPTION"
									),
									label: this.props.t(
										"LIST_PROCESS_CAMPAIGN.DESCRIPTION_NAME"
									),
								}}
								autoComplete="off"
								onChange={this.handleChange.bind(
									this,
									"description"
								)}
							/>
						</li>

						<li className="searchitems form-group">
							<div>
								<button
									type="submit"
									className="btn btn-primary"
									onClick={this.getSearchRecord.bind(
										this,
										false
									)}
								>
									{this.props.t(
										"LIST_PROCESS_CAMPAIGN.SEARCH"
									)}
								</button>
							</div>
						</li>

						<li className="searchitems form-group">
							<button
								type="reset"
								className="btn btn-default btn-reset"
								onClick={this.getSearchRecord.bind(this, true)}
							>
								{this.props.t("LIST_PROCESS_CAMPAIGN.RESET")}
							</button>
						</li>
					</ul>
				</form>
			</div>
		);
	}
}
const mapStateToProps = ({ config }) => {
	return {
		processList: config.process_list,
		campaignList:
			config.campaign_list instanceof Array
				? config.campaign_list.map(function (e) {
						return { label: e.name, value: e.id };
				  })
				: [],
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
		},
	};
};
export default withTranslation("webfrom")(
	connect(mapStateToProps, mapDispatchToProps)(SearchProcessCampaignList)
);
