import React from "react";
class PageLoader extends React.Component {
	render() {
		return (
			<div className={this.props.loaderClass}>
				<span className="loading">
					<span className={this.props.loadertext}>Loading ...</span>
				</span>
			</div>
		);
	}
}

export default PageLoader;
