import React, { Component } from "react";
import { bindActionCreators } from "redux";

import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import SearchSalesReport from "./SearchSalesReport";
import { ReportService } from "../../../../service/reportService";
import PageLoader from "../../../elements/PageLoader";
import { CSVLink } from "react-csv";
import { LeadActions } from "../../../../store/action";
import config from "../../../../config/config";
class SalesReport extends Component {
	csvLink = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			reportData: {},
			filterData: {},
			isSearchClicked: false,
			loading: false,
			isSearchButtonDisabled: false,
			csvDataIndividual: [],
		};
	}
	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		if (props.make_map && props.make_map !== state.make_map) {
			updateProps["make_map"] = props.make_map;
		}
		if (props.model_map && props.model_map !== state.model_map) {
			updateProps["model_map"] = props.model_map;
		}
		if (props.city_map && props.city_map !== state.city_map) {
			updateProps["city_map"] = props.city_map;
		}
		return updateProps;
	}
	onSubmitFilter = (filterData) => {
		this.setState({ loading: true, isSearchButtonDisabled: true });
		ReportService.getSalesReport(filterData)
			.then((result) => {
				const {
					data: { data: response = {} },
				} = result;
				this.setState(
					{
						filterData: filterData,
						reportData: response,
						isSearchClicked: true,
						loading: false,
						isSearchButtonDisabled: false,
					},
					async () => {
						if (Object.keys(response).length) {
							await this.props.actions.SaveDownloadLog({
								module: "SalesReport",
								action: "2",
							});
						}
					}
				);
			})
			.catch((err) => {});
	};
	downloadCsvForDetail = (data) => {
		const { make_map, model_map, city_map } = this.state;
		if (data instanceof Array) {
			for (let i of data) {
				for (let j in i) {
					if (j === "Make") {
						i[j] = make_map[i[j]] ? make_map[i[j]] : "";
					} else if (j === "Model") {
						i[j] = model_map[i[j]] ? model_map[i[j]] : "";
					} else if (j === "City") {
						i[j] = city_map[i[j]] ? city_map[i[j]] : "";
					} else if (j === "CrossSell-City") {
						i[j] = city_map[i[j]] ? city_map[i[j]] : "";
					}
				}
			}

			this.setState({ csvDataIndividual: data }, async () => {
				if (data instanceof Array && data.length) {
					this.csvLink.current.link.click();
					await this.props.actions.SaveDownloadLog({
						module: "SalesReport",
						action: "1",
					});
				}
			});
		}
	};
	requestCsvDownload = (slug, value, campaign_id) => {
		// console.log("here", slug, value,campaign_id);
		if (value > 0) {
			const {
				filterData: { selected_month },
			} = this.state;
			const postData = {
				slug: slug,
				report_type: "sales_report",
				process_id: campaign_id,
			};
			if (selected_month) {
				postData["selected_month"] = selected_month;
			}
			ReportService.getReportDetail(postData)
				.then((result) => {
					const {
						data: { data = [] },
					} = result;
					this.downloadCsvForDetail(data);
				})
				.catch((err) => {});
		}
	};
	tableBody = (reportData) => {
		let tableData = [],
			count = 0;
		for (let data of reportData) {
			tableData.push(
				<tr key={count}>
					<td>{data.reporting_name}</td>
					<td
						className={
							Number(data.all_active_leads) +
								Number(data.all_closed_leads) >
							0
								? "report-csv-list"
								: ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"total_inspection",
								Number(data.all_active_leads) +
									Number(data.all_closed_leads),
								data.campaign_id
							)
						}
					>
						{Number(data.all_active_leads) +
							Number(data.all_closed_leads)}
					</td>
					<td
						className={
							Number(data.all_active_leads) > 0
								? "report-csv-list"
								: ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"active_inspection",
								Number(data.all_active_leads),
								data.campaign_id
							)
						}
					>
						{data.all_active_leads}
					</td>
					<td
						className={
							Number(data.all_closed_leads) > 0
								? "report-csv-list"
								: ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"closed_inspection",
								Number(data.all_closed_leads),
								data.campaign_id
							)
						}
					>
						{data.all_closed_leads}
					</td>
					<td
						className={
							Number(data.interested_hot) > 0
								? "report-csv-list"
								: ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"interested_hot",
								Number(data.interested_hot),
								data.campaign_id
							)
						}
					>
						{data.interested_hot}
					</td>
					<td
						className={
							Number(data.interested_warm) > 0
								? "report-csv-list"
								: ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"interested_warm",
								Number(data.interested_warm),
								data.campaign_id
							)
						}
					>
						{data.interested_warm}
					</td>
					<td
						className={
							Number(data.lms_spk_lead) > 0
								? "report-csv-list"
								: ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"lms_spk_lead",
								Number(data.lms_spk_lead),
								data.campaign_id
							)
						}
					>
						{data.lms_spk_lead}
					</td>
					<td
						className={
							Number(data.crm_spk_lead) > 0
								? "report-csv-list"
								: ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"crm_spk_lead",
								Number(data.crm_spk_lead),
								data.campaign_id
							)
						}
					>
						{data.crm_spk_lead}
					</td>
					<td
						className={
							Number(data.lms_spk_lead) +
								Number(data.crm_spk_lead) >
							0
								? "report-csv-list"
								: ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"total_lms_crm_spk_lead",
								Number(data.lms_spk_lead) +
									Number(data.crm_spk_lead),
								data.campaign_id
							)
						}
					>
						{Number(data.lms_spk_lead) + Number(data.crm_spk_lead)}
					</td>
					<td
						className={
							Number(data.attribution_not_sent) > 0
								? "report-csv-list"
								: ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"attribution_not_sent",
								Number(data.attribution_not_sent),
								data.campaign_id
							)
						}
					>
						{data.attribution_not_sent}
					</td>
					<td
						className={
							Number(data.attribution_sent) > 0
								? "report-csv-list"
								: ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"attribution_sent",
								Number(data.attribution_sent),
								data.campaign_id
							)
						}
					>
						{data.attribution_sent}
					</td>
					<td
						className={
							Number(data.attribution_attributed) > 0
								? "report-csv-list"
								: ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"attribution_attributed",
								Number(data.attribution_attributed),
								data.campaign_id
							)
						}
					>
						{data.attribution_attributed}
					</td>
					<td
						className={
							Number(data.attribution_rejected) > 0
								? "report-csv-list"
								: ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"attribution_rejected",
								Number(data.attribution_rejected),
								data.campaign_id
							)
						}
					>
						{data.attribution_rejected}
					</td>
					<td
						className={
							Number(data.attribution_pending) > 0
								? "report-csv-list"
								: ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"attribution_pending",
								Number(data.attribution_pending),
								data.campaign_id
							)
						}
					>
						{data.attribution_pending}
					</td>
					<td
						className={
							Number(data.billing_not_sent) > 0
								? "report-csv-list"
								: ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"billing_not_sent",
								Number(data.billing_not_sent),
								data.campaign_id
							)
						}
					>
						{data.billing_not_sent}
					</td>
					<td
						className={
							Number(data.billing_sent) > 0
								? "report-csv-list"
								: ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"billing_sent",
								Number(data.billing_sent),
								data.campaign_id
							)
						}
					>
						{data.billing_sent}
					</td>
					<td
						className={
							Number(data.billing_completed) > 0
								? "report-csv-list"
								: ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"billing_completed",
								Number(data.billing_completed),
								data.campaign_id
							)
						}
					>
						{data.billing_completed}
					</td>
				</tr>
			);
			count += 1;
		}

		return tableData;
	};
	render() {
		const {
			reportData,
			filterData,
			isSearchClicked,
			loading,
			isSearchButtonDisabled,
			csvDataIndividual,
		} = this.state;
		let selectedMonth =
			filterData && filterData.selected_month
				? +filterData.selected_month.getMonth() + +1
				: "";
		return (
			<div>
				<SearchSalesReport
					onSubmitFilter={this.onSubmitFilter}
					isSearchButtonDisabled={isSearchButtonDisabled}
				/>
				<div className="hidden">
					<CSVLink
						className="hidden"
						ref={this.csvLink}
						filename="lead_detail.csv"
						data={csvDataIndividual}
					>
						{" "}
					</CSVLink>
				</div>
				<div className="container-fluid reportContainer">
					<div className="result-wrap">
						<h2>Sales Report</h2>
						<div className="card">
							{isSearchClicked ? (
								<div className="table-responsive table-colFixed">
									<table className="table table-bordered table-hover salesReportTable ">
										<thead>
											<tr>
												<th>
													{/* {this.props.t(
														"PERFORMANCE_REPORT.TABLE.CAMPAIGN_NAME"
													)} */}
												</th>
												<th colSpan="3">
													{!selectedMonth
														? this.props.t(
																"SALES_REPORT.TABLE.ALL_TIME_LEADS"
														  )
														: this.props.t(
																"SALES_REPORT.TABLE." +
																	selectedMonth
														  ) +
														  " " +
														  this.props.t(
																"SALES_REPORT.TABLE.LEADS"
														  )}
												</th>
												<th
													colSpan="2"
													className="text-center"
												>
													{this.props.t(
														"SALES_REPORT.TABLE.INTERESTED"
													)}
												</th>
												<th colSpan="3">
													{this.props.t(
														"SALES_REPORT.TABLE." +
															config.constants
																.LABEL_SPK
													)}
												</th>
												<th colSpan="5">
													{this.props.t(
														"SALES_REPORT.TABLE.CRM_" +
															config.constants
																.LABEL_SPK +
															"_ATTRIBUTION_STATUS"
													)}
												</th>
												<th colSpan="3">
													{this.props.t(
														"SALES_REPORT.TABLE.CRM_ATTRIBUTED_" +
															config.constants
																.LABEL_SPK +
															"_BILLING_STATUS"
													)}
												</th>
											</tr>

											<tr>
												<th>
													{this.props.t(
														"SALES_REPORT.TABLE.CAMPAIGN_NAME"
													)}
												</th>
												<th>
													{this.props.t(
														"SALES_REPORT.TABLE.TOTAL"
													)}
												</th>
												<th>
													{this.props.t(
														"SALES_REPORT.TABLE.ACTIVE"
													)}
												</th>
												<th>
													{this.props.t(
														"SALES_REPORT.TABLE.CLOSED"
													)}
												</th>
												<th>
													{this.props.t(
														"SALES_REPORT.TABLE.HOT"
													)}
												</th>
												<th>
													{this.props.t(
														"SALES_REPORT.TABLE.WARM"
													)}
												</th>
												<th>
													{this.props.t(
														"SALES_REPORT.TABLE.LMS"
													)}
												</th>
												<th>
													{this.props.t(
														"SALES_REPORT.TABLE.CRM"
													)}
												</th>
												<th>
													{this.props.t(
														"SALES_REPORT.TABLE.TOTAL"
													)}
												</th>
												<th>
													{this.props.t(
														"SALES_REPORT.TABLE.NOT_SENT_FOR_ATTRIBUTION"
													)}
												</th>
												<th>
													{this.props.t(
														"SALES_REPORT.TABLE.SENT_FOR_ATTRIBUTION"
													)}
												</th>
												<th>
													{this.props.t(
														"SALES_REPORT.TABLE.ATTRIBUTED"
													)}
												</th>
												<th>
													{this.props.t(
														"SALES_REPORT.TABLE.REJECTED"
													)}
												</th>
												<th>
													{this.props.t(
														"SALES_REPORT.TABLE.PENDING_AT_CLIENT"
													)}
												</th>
												<th>
													{this.props.t(
														"SALES_REPORT.TABLE.NOT_SENT_FOR_BILLING"
													)}
												</th>
												<th>
													{this.props.t(
														"SALES_REPORT.TABLE.SENT_FOR_BILLING"
													)}
												</th>
												<th>
													{this.props.t(
														"SALES_REPORT.TABLE.BILLING_COMPLETED"
													)}
												</th>
											</tr>
										</thead>
										<tbody>
											{reportData &&
											Object.keys(reportData).length ? (
												this.tableBody(reportData)
											) : (
												<tr>
													<td>No record found</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							) : (
								""
							)}
							{loading && (
								<PageLoader
									loaderClass=" text-center loader content-loader"
									loadertext="true"
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ config, inventory }) => {
	return {
		make_map: inventory.make_map ? inventory.make_map : {},
		model_map: inventory.model_map ? inventory.model_map : {},
		city_map:
			inventory.city instanceof Array
				? inventory.city.reduce(
						(obj, item) => ((obj[item.id] = item.name), obj),
						{}
				  )
				: {},
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			SaveDownloadLog: bindActionCreators(
				LeadActions.SaveDownloadLog,
				dispatch
			),
		},
	};
};
export default withTranslation("report")(
	connect(mapStateToProps, mapDispatchToProps)(SalesReport)
);
