// 'use strict'

import React from "react";
//import { HtmlTag } from 'elements';

const defaultProps = {
	error: {
		status: true,
		message: "",
	},
	popupCls: "",
};
class CheckBox extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			active: (props.locked && props.active) || false,
			value: props.value || "",
			id: props.id || "",
			name: props.name || "",
			type: props.type || "type",
			error: props.error || "",
			label: props.label || "",
			lclname: props.lclname || "gs_control gs_checkbox",
			checked: props.checked || "",
			disabled: props.disabled || "",
		};
	}

	changeValue(event) {
		const value = event.target.value;
		this.setState({ value, error: "" });
	}

	// componentWillReceiveProps(nextProps) {
	//     this.setState({
	//         checked: nextProps.checked
	//     });
	// }

	static getDerivedStateFromProps = (nextProps, prevState) => {
		// let prevVal = prevState.value;
		if (prevState.checked !== nextProps.checked) {
			return { checked: nextProps.checked };
		}
		if (prevState.disabled !== nextProps.disabled) {
			return { disabled: nextProps.disabled };
		}
		return null;
	};

	render() {
		const { value, label, id, type, name, lclname } = this.state;
		//let error = this.props.error;
		return (
			<label htmlFor={id} className={lclname}>
				{label}

				<input
					id={id}
					className={
						this.props.className
							? this.props.className
							: "form-control"
					}
					type={type}
					value={value}
					onChange={this.props.onChange}
					checked={this.props.checked}
					disabled={this.props.disabled}
					name={name}
				/>
				<span className="gs_control__indicator"></span>
			</label>
		);
	}
}
CheckBox.defaultProps = defaultProps;
export default CheckBox;
