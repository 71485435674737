/* eslint-disable */
import React, { Component } from "react";
import Select from "react-select";
import { LeadService } from "../../../service/leadService";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DealerComments from "../component/dealersComments";
import PageLoader from "../../elements/PageLoader";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { LeadActions } from "./../../../store/action";
import { bindActionCreators } from "redux";
import dateformat from "dateformat";
import config from "../../../config/config";
import InputField from "../../elements/InputField";
import Q from "q";
import CheckBox from "../../elements/CheckBox";
import secureStorage from "../../../config/encrypt";
import * as constant from "../../../config/constant";
const now = new Date();
const currhour = Number(now.getHours());
const currmin = now.getMinutes();

class ManageStatusCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			comments: [],
			statusList: this.props.statusList || [],
			subStatusList: this.props.subStatusList || [],
			filterData: this.props.filterData || {},
			commentBoxes: this.props.commentBoxes || [0],
			loading: true,
			dealersData: this.props.dealersData || [],
			usedDealersData: this.props.usedDealersData || [],
			unusedDealersData: this.props.unusedDealersData || [],
			addCommentButton: false,
			enableDateFields: false,
			enableDealersFields: false,
			dseList: this.props.dseList || [],
			manageStatus_error: {},
			is_future_date: false,
			leadDetail: this.props.leadDetail || [],
			modelList: [],
			model_list: this.props.model_list,
			mappedDseList: this.props.mappedDseList || [],
			filteredDse: [],
			updateStatus: false,
			addDseTextBoxStatus: false,
			optional_fields: [],
			originalStatus: 0,
			originalSubStatus: 0,
			enableMmvFields: false,
			addDealerTextBoxStatus: false,
			selectedFile: null,
			attribution_list: [],
			billing_status_list: [],
			tabs: [
				{
					id: 1,
					title: this.props.t("Internal"),
				},
				{
					id: 2,
					title: this.props.t("External"),
				},
			],
			showTab: 1,
			commentData: [],
			internalComment: "",
			uploaded_document: [],
			tempStatusList: [],
			enableReopenFlag: false,
			enableEaf: false,
			enableNcf: false,
			enableDseProcessFlag: false,
			relationship_list: [],
			city_list: [],
			regexp: /^[0-9\b]+$/,
			authUser: secureStorage.getItem("authUserInfo"),
			reasons_list: [],
			status_reasons: [],
			stnk_list: [],
		};
		this.addComment = this.addCommentBoxes.bind(this);
	}

	componentDidMount = async () => {
		await this.getAssigncarDealerList();
		await this.getUploadedDocument();
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		const updatedState = {};
		let make_id = 0,
			model_id = 0,
			follow_up_date = "",
			followup_time = "",
			purchase_date = "",
			is_future_date_status = false,
			filterdseList = [],
			booking_date = "";

		let statusArray = nextProps.statusList;
		let mappedDseArr = nextProps.mappedDseList;
		let status_id = nextProps.leadDetail.status_id || 0;
		let sub_status_id = nextProps.leadDetail.sub_status_id || 0;
		let attribution_id = nextProps.leadDetail.attribution_id || 0;
		let billing_status_id = nextProps.leadDetail.billing_status_id || 0;
		let reopen_flag = nextProps.leadDetail.rf || 0;
		let ncf = nextProps.leadDetail.ncf || 0;
		let eaf = nextProps.leadDetail.eaf || 0;
		let dse_process = nextProps.leadDetail.dse_process || 0;
		let booking_name = nextProps.leadDetail.booking_name || "";
		let booking_mobile = nextProps.leadDetail.booking_mobile || "";
		let booking_city_id = nextProps.leadDetail.booking_city_id || "";
		let booking_relation = nextProps.leadDetail.booking_relation || "";
		let reason_id = nextProps.leadDetail.reason_id || 0;
		let status_reason_id = nextProps.leadDetail.status_reason_id || 0;
		let other_reason_text = nextProps.leadDetail.other_reason_text || 0;

		let stnk_id = nextProps.leadDetail.stnk_id || 0;
		let stnk_follow_up_date =
			(nextProps.leadDetail.stnk_follow_up_date &&
				Date.parse(nextProps.leadDetail.stnk_follow_up_date)) ||
			null;
		let stnk_number = nextProps.leadDetail.stnk_number || 0;

		follow_up_date =
			Date.parse(nextProps.leadDetail.followup_date) > 0
				? nextProps.leadDetail.followup_date
				: "";
		purchase_date =
			Date.parse(nextProps.leadDetail.purchase_date) > 0
				? nextProps.leadDetail.purchase_date
				: "";
		booking_date =
			Date.parse(nextProps.leadDetail.booking_date) > 0
				? nextProps.leadDetail.booking_date
				: "";
		followup_time =
			Date.parse(nextProps.leadDetail.followup_date) > 0
				? dateformat(
						nextProps.leadDetail.followup_date,
						config.constants.TIME_FORMAT
				  )
				: "";
		// followup_time = nextProps.leadDetail.followup_time
		// 	? nextProps.leadDetail.followup_time
		// 	: "";

		let other_dealer_status =
			nextProps.leadDetail.other_dealer_status || "0";
		let other_dse_status = nextProps.leadDetail.other_dse_status || "0";
		let other_dse_name = nextProps.leadDetail.other_dse_name || "";
		let other_dealer_name = nextProps.leadDetail.other_dealer_name || "";
		//let purchase_from_dse_id = nextProps.leadDetail.purchase_dse_id || 0;
		let buy_time = nextProps.leadDetail.buy_time || "";
		let purchase_from_dse_id =
			other_dse_status === "1"
				? "other"
				: nextProps.leadDetail.purchase_dse_id || 0;
		let purchase_dealer_id =
			other_dealer_status === "1"
				? "other"
				: nextProps.leadDetail.purchase_dealer_id || 0;
		if (nextProps.leadDetail.purchase_car_details) {
			let purchase_car_details = JSON.parse(
				nextProps.leadDetail.purchase_car_details
			);
			make_id = +purchase_car_details.make_id || 0;
			model_id = +purchase_car_details.model_id || 0;
		}

		if (
			follow_up_date &&
			new Date(follow_up_date).setHours(0, 0, 0, 0) >
				new Date().setHours(0, 0, 0, 0)
		) {
			is_future_date_status = true;
		}
		if (nextProps && nextProps.mappedDseList !== prevState.mappedDseList) {
			updatedState["mappedDseList"] = mappedDseArr;
			filterdseList =
				mappedDseArr.filter((v) => v.dealer_id == purchase_dealer_id) ||
				[];
			updatedState["filteredDse"] = filterdseList;
		}
		if (prevState.statusList !== nextProps.statusList) {
			updatedState["statusList"] = statusArray;
			updatedState["tempStatusList"] = statusArray;
			let sub_status_Arr =
				statusArray
					.filter((v) => v.status_id == status_id)
					.map((v) => v.sub_status_master)[0] || [];
			updatedState["filterData"] = {
				status_id: status_id,
				sub_status_id: sub_status_id,
				follow_up_date: follow_up_date || "",
				followup_time: followup_time || "",
				make_id: make_id,
				model_id: model_id,
				purchase_date: purchase_date || "",
				booking_date: booking_date || "",
				purchase_from_dse_id: purchase_from_dse_id,
				purchase_dealer_id: purchase_dealer_id,
				other_dse_name: other_dse_name,
				other_dealer_name: other_dealer_name,
				attribution_id: attribution_id,
				billing_status_id: billing_status_id,
				rf: reopen_flag,
				eaf: eaf,
				ncf: ncf,
				dse_process: dse_process,
				booking_name: booking_name,
				booking_mobile: booking_mobile,
				booking_city_id: booking_city_id,
				booking_relation: booking_relation,
				reason_id: reason_id,
				status_reason_id: status_reason_id,
				other_reason_text: other_reason_text,
				stnk_id: stnk_id,
				stnk_follow_up_date: stnk_follow_up_date,
				stnk_number: stnk_number,
				buy_time: buy_time,
			};
			updatedState["subStatusList"] = sub_status_Arr;
			updatedState.loading = nextProps.statusList ? false : true;
		}
		if (prevState.leadDetail !== nextProps.leadDetail) {
			let sub_status_Arr =
				statusArray
					.filter((v) => v.status_id == status_id)
					.map((v) => v.sub_status_master)[0] || [];
			updatedState["filterData"] = {
				status_id: status_id,
				sub_status_id: sub_status_id,
				follow_up_date: follow_up_date,
				followup_time: followup_time,
				make_id: make_id,
				model_id: model_id,
				purchase_date: purchase_date,
				booking_date: booking_date,
				purchase_from_dse_id: purchase_from_dse_id,
				purchase_dealer_id: purchase_dealer_id,
				other_dse_name: other_dse_name,
				other_dealer_name: other_dealer_name,
				attribution_id: attribution_id,
				billing_status_id: billing_status_id,
				rf: reopen_flag,
				eaf: eaf,
				ncf: ncf,
				dse_process: dse_process,
				booking_name: booking_name,
				booking_mobile: booking_mobile,
				booking_city_id: booking_city_id,
				booking_relation: booking_relation,
				reason_id: reason_id,
				status_reason_id: status_reason_id,
				other_reason_text: other_reason_text,
				stnk_id: stnk_id,
				stnk_follow_up_date: stnk_follow_up_date,
				stnk_number: stnk_number,
				buy_time: buy_time,
			};
			updatedState["originalStatus"] = status_id;
			updatedState["originalSubStatus"] = sub_status_id;
			if (make_id) {
				let modelList =
					nextProps.model_list &&
					nextProps.model_list.filter((v) => v.mk_id === make_id);
				updatedState.modelList = modelList;
			}

			if (nextProps.leadDetail.purchase_dse_id)
				updatedState["subStatusList"] = sub_status_Arr;
			updatedState["leadDetail"] = nextProps.leadDetail;

			filterdseList =
				mappedDseArr.filter((v) => v.dealer_id == purchase_dealer_id) ||
				[];
			if (filterdseList.length)
				updatedState["filteredDse"] = filterdseList;

			let enableDateFields = false,
				enableDealersFields = false;
			if (
				status_id === 1 ||
				status_id === 2 ||
				(status_id === 6 &&
					((sub_status_id === 13 && attribution_id != 4) ||
						attribution_id === 5))
			)
				enableDateFields = true;
			if (status_id === 6 && sub_status_id != 16)
				enableDealersFields = true;
			if (sub_status_id === 16) enableDateFields = false;
			updatedState.enableDateFields = enableDateFields;
			updatedState.enableDealersFields = enableDealersFields;
			updatedState.enableMmvFields = status_id === 6 ? true : false;
			updatedState.is_future_date = is_future_date_status;
			updatedState.addDseTextBoxStatus =
				other_dealer_status === "1" || other_dse_status === "1"
					? true
					: false;
			updatedState.addDealerTextBoxStatus =
				other_dealer_status === "1" ? true : false;
			updatedState.optional_fields =
				status_id === 6 && sub_status_id === 13
					? [
							"make_id",
							"model_id",
							"purchase_date",
							"purchase_dealer_id",
							"purchase_from_dse_id",
							"other_dealer_name",
							"other_dse_name",
							"internal_comment",
							"booking_name",
							"booking_mobile",
							"booking_city_id",
							"booking_relation",
							"booking_date",
							"reason_id",
					  ]
					: status_id === 6 && sub_status_id === 16
					? ["make_id", "model_id", "internal_comment"]
					: [
							"other_dealer_name",
							"other_dse_name",
							"internal_comment",
							"booking_name",
							"booking_mobile",
							"booking_city_id",
							"booking_relation",
							"model_id"
					  ];
		}
		if (nextProps && nextProps.model_list !== prevState.model_list) {
			updatedState["model_list"] = nextProps.model_list;
		}
		if (
			nextProps &&
			nextProps.attribution_list !== prevState.attribution_list
		) {
			updatedState["attribution_list"] = nextProps.attribution_list;
		}
		if (
			nextProps &&
			nextProps.billing_status_list !== prevState.billing_status_list
		) {
			updatedState["billing_status_list"] = nextProps.billing_status_list;
		}
		updatedState["enableReopenFlag"] =
			Number(reopen_flag) === 1 ? true : false;
		updatedState["enableEaf"] = Number(eaf) === 1 ? true : false;
		updatedState["enableNcf"] = Number(ncf) === 1 ? true : false;
		updatedState["enableDseProcessFlag"] =
			Number(dse_process) === 1 ? true : false;
		if (
			nextProps &&
			nextProps.relationship_list !== prevState.relationship_list
		) {
			updatedState["relationship_list"] = nextProps.relationship_list;
		}
		if (nextProps && nextProps.city_list !== prevState.city_list) {
			updatedState["city_list"] = nextProps.city_list;
		}
		if (nextProps && nextProps.reasons_list !== prevState.reasons_list) {
			updatedState["reasons_list"] = nextProps.reasons_list;
		}
		if (
			nextProps &&
			nextProps.status_reasons !== prevState.status_reasons
		) {
			updatedState["status_reasons"] = nextProps.status_reasons;
		}

		if (nextProps && nextProps.stnk_list !== prevState.stnk_list) {
			updatedState["stnk_list"] = nextProps.stnk_list;
		}

		return updatedState;
	}

	handleDateTimeChange = (date, sname) => {
		let {filterData,manageStatus_error} = this.state,
			state2Upd = {};
		let inputDate = new Date(date);
		if (sname === "followup_time")
			filterData[sname] = dateformat(date, config.constants.TIME_FORMAT);
		else filterData[sname] = inputDate;
		let is_future_date_status = false;
		if (sname === "follow_up_date") {
			if (
				new Date(date).setHours(0, 0, 0, 0) >
				new Date().setHours(0, 0, 0, 0)
			) {
				is_future_date_status = true;
			}
			filterData["followup_time"] = "";
			state2Upd.is_future_date = is_future_date_status;
		}
		state2Upd.filterData = filterData;
		manageStatus_error[sname] = ""
		this.setState({state2Upd:state2Upd,manageStatus_error:manageStatus_error});
	};

	handleChange = async (sname, valueObj) => {
		let value;
		if (valueObj) value = valueObj.value || valueObj.id;
		let stateObj2upd = {},
			enableDateFields = false,
			enableDealersFields = false,
			enableMmvFields = false;
		let {
			filterData,
			model_list,
			modelList,
			manageStatus_error,
			filteredDse,
			mappedDseList,
			leadDetail,
		} = this.state;
		
		if (valueObj) {
			if (sname === "status_id") {
				value = valueObj.status_id;
				let sub_status_list = valueObj.sub_status_master;
				stateObj2upd.subStatusList = sub_status_list;
				filterData.sub_status_id = "";
				if (value === 1 || value === 2) {
					enableDateFields = true;
				}
				if (value === 6) {
					enableDealersFields = true;
					enableMmvFields = true;
				}

				stateObj2upd.enableDateFields = enableDateFields;
				stateObj2upd.enableDealersFields = enableDealersFields;
				stateObj2upd.enableMmvFields = enableMmvFields;
			}
			if (sname === "make_id") {
				delete filterData.model_id;
				modelList =
					(await model_list) &&
					model_list.filter(
						(v) => Number(v.mk_id) === Number(valueObj.id)
					);
			}
			if (sname === "sub_status_id") {
				value = valueObj.sub_status_id;
				let curr_status_id = filterData.status_id;
				filterData.attribution_id = "";
				stateObj2upd.enableDateFields =
					curr_status_id === 1 ||
					curr_status_id === 2 ||
					value === 13 ||
					filterData.attribution_id === 5
						? true
						: false;
				stateObj2upd.enableDealersFields =
					curr_status_id === 6 && value !== 16 ? true : false;
				if (
					filterData &&
					filterData.attribution_id &&
					+filterData.attribution_id === 5
				)
					stateObj2upd.enableDateFields = true;
				if (value === 16) {
					stateObj2upd.enableDateFields = false;
					filterData.attribution_id = 0;
				}
				stateObj2upd.optional_fields =
					value === 13
						? [
								"make_id",
								"model_id",
								"purchase_date",
								"purchase_dealer_id",
								"purchase_from_dse_id",
								"other_dealer_name",
								"other_dse_name",
								"internal_comment",
								"booking_name",
								"booking_mobile",
								"booking_city_id",
								"booking_relation",
								"booking_date",
								"reason_id",
						  ]
						: value === 16
						? ["make_id", "model_id", "internal_comment"]
						: [
								"other_dealer_name",
								"other_dse_name",
								"internal_comment",
								"booking_name",
								"booking_mobile",
								"booking_city_id",
								"booking_relation",
								"model_id",
						  ];
				filterData.buy_time = "";
			}
			if (sname === "purchase_dealer_id") {
				value = valueObj.dealer_id;
				delete filterData.purchase_from_dse_id;
				filteredDse =
					mappedDseList && mappedDseList.length
						? mappedDseList.filter((v) => v.dealer_id === +value)
						: [];
				stateObj2upd.addDseTextBoxStatus =
					value === "other" ? true : false;
				stateObj2upd.addDealerTextBoxStatus =
					value === "other" ? true : false;
			}
			if (sname === "purchase_from_dse_id") {
				stateObj2upd.addDseTextBoxStatus =
					value === "other" ? true : false;
			}
			if (
				sname === "other_dse_name" ||
				sname === "other_dealer_name" ||
				sname === "other_reason_text" ||
				sname === "stnk_number"
			) {
				value = valueObj.target.value;
			}
			if (sname === "attribution_id") {
				value = valueObj.attribution_id;
				stateObj2upd.enableDateFields =
					valueObj.attribution_id === 5 ||
					(filterData.sub_status_id === 13 && value !== 4)
						? true
						: false;
				filterData.follow_up_date = new Date();
			}
			if (sname === "billing_status_id") {
				value = valueObj.billing_status_id;
			}
			if (
				sname === "rf" ||
				sname === "eaf" ||
				sname === "ncf" ||
				sname === "dse_process"
			) {
				let temp = valueObj.target.checked;
				value = temp ? 1 : 0;
			}
			if (sname === "booking_name") {
				value = valueObj.target.value;
			}
			if (sname === "booking_mobile") {
				if (
					valueObj.target.value === "" ||
					this.state.regexp.test(valueObj.target.value)
				) {
					value = valueObj.target.value;
					filterData[sname] = value;
				}
			}
			if (sname === "booking_city_id") {
				value = valueObj.id;
			}
			if (sname === "booking_relation") {
				value = valueObj.relation_id;
			}
			if (sname === "booking_name_chk") {
				let temp = valueObj.target.checked;
				value = temp ? 1 : 0;
				filterData.booking_name = leadDetail.lead_name || "";
			}
			if (sname === "booking_mobile_chk") {
				let temp = valueObj.target.checked;
				value = temp ? 1 : 0;
				filterData.booking_mobile = leadDetail.lead_mobile || "";
			}
			if (sname === "booking_city_chk") {
				let temp = valueObj.target.checked;
				value = temp ? 1 : 0;
				filterData.booking_city_id = leadDetail.lead_city || "";
			}
			if (sname !== "booking_mobile") filterData[sname] = value;
			if (sname === "reason_id") {
				value = valueObj.reason_id;
			}
			if (sname === "status_reason_id") {
				value = valueObj.reason_id;
			}
			if (sname === "stnk_id") {
				if(leadDetail && Number(leadDetail.myactive_agent_id) === 0 && valueObj.stnk_id === 1) toast.error(this.props.t("MANAGE_STATUS.MY_ACTIVE_IS_NOT_ENABLED"));
				else
					value = valueObj.stnk_id;
			}
			filterData[sname] = value;
			console.log('sname==========',sname)
			manageStatus_error[sname] = "";
		} else {
			if (sname === "make_id") modelList = [];
			if (sname === "status_id") stateObj2upd.subStatusList = [];
			if (sname === "purchase_dealer_id") filteredDse = [];
			delete filterData[sname];
		}
		console.log('manageStatus_error==========',manageStatus_error)
		stateObj2upd = {
			...stateObj2upd,
			filterData: filterData,
			modelList: modelList,
			manageStatus_error: manageStatus_error,
			filteredDse: filteredDse,
		};
		this.setState(stateObj2upd);
	};

	addCommentBoxes = () => {
		let { commentBoxes, dseList } = this.state;
		let addCommentButton = false;
		commentBoxes.push(commentBoxes.length);
		if (dseList.length > commentBoxes.length) addCommentButton = true;
		this.setState({
			commentBoxes: commentBoxes,
			addCommentButton: addCommentButton,
		});
	};
	removeCommentBoxes = (index, selectedDealers_ids) => {
		let { commentBoxes, usedDealersData } = this.state;
		let state2Upd = {};
		//commentBoxes.splice(index, 1);
		delete commentBoxes[index];
		if (selectedDealers_ids.length) {
			delete usedDealersData[index];
			let usedDealers = usedDealersData;
			state2Upd.usedDealersData = usedDealers;
		}
		state2Upd.commentBoxes = commentBoxes;
		state2Upd.addCommentButton = true;
		this.setState(state2Upd);
	};
	manageCommentBoxes = (index, selected_dealers) => {
		const { usedDealersData } = this.state;
		let state2Upd = { updateStatus: false };
		usedDealersData[index] = selected_dealers;
		state2Upd = {
			...state2Upd,
			usedDealersData: usedDealersData,
		};
		this.setState(state2Upd);
	};

	// Assigned car delaers
	getAssigncarDealerList = async () => {
		let leadId = new URLSearchParams(window.location.search).get("id");
		await LeadService.getAssignCarDealers({ lead_id: leadId })
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let responseData = response.data.data;
					let dseList = [];
					let dealersDataArray = [];
					if (responseData.length) {
						responseData.map((v) => {
							if (v.dse && v.dse.length) {
								dseList = dseList.concat(v.dse);
								v.dse.map((dseinfo) => {
									dealersDataArray.push({
										value: dseinfo.dse_id,
										id: dseinfo.dse_id,
										name: `${dseinfo.dse_name} - ${
											v.dealer_name ||
											v.dealer_organization
										}`,
										isChecked: false,
									});
								});
							}
						});
					}
					this.setState({
						dealersData: dealersDataArray,
						dseList: dseList,
						addCommentButton:
							dseList && dseList.length && dseList.length - 1
								? true
								: false,
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	stringifyFormData(fd) {
		const data = {};
		for (let key of fd.keys()) {
			data[key] = fd.get(key);
		}
		return JSON.parse(JSON.stringify(data, null, 2));
	}
	UpdateStatus = async (event) => {
		event.preventDefault();
		const form = event.target;
		const data = new FormData(form);
		const formdata = this.stringifyFormData(data);
		const leadId = new URLSearchParams(window.location.search).get("id");
		let inpval = "",
			all_dse = false,
			lead_comment = [],
			car_details = {},
			request_data = { lead_id: leadId },
			manageStatus_error = {};
		let formdataKeys = Object.keys(formdata);
		if (formdataKeys.length) {
			const filterItems = (needle, heystack) => {
				let query = needle.toLowerCase();
				return heystack.filter(
					(item) => item.toLowerCase().indexOf(query) >= 0
				);
			};
			formdataKeys.map((v) => {
				let keys = v.split("__");

				if (keys.length > 1) {
					if (
						all_dse &&
						(keys[0] === "dseid_0" ||
							(keys[0] === "comments" && keys[1] === "0"))
					) {
						//console.log("keys[1]",keys[1])
						// used in dse all key
					} else if (keys[0] !== "comments") {
						let dse_id = formdata[v] ? Number(formdata[v]) : 0;
						let commentIndex = keys[0].replace("dseid_", "");
						let comment_txt =
							formdata[`comments__${Number(commentIndex)}`];
						//console.log("comment_txt1")
						if (comment_txt) {
							lead_comment.push({
								dse_id: dse_id,
								comment: comment_txt,
								comment_type: "EXTERNAL",
							});
						} else {
							if (activeTab === "external")
								manageStatus_error[
									"comments_" + commentIndex
								] = this.props.t(
									"MANAGE_STATUS.COMMENTS_REQUIRED"
								);
						}
					} else {
						// check if comments exist
						let check4Key = `dseid_${keys[1]}`;
						let comment_txt =
							formdata[`comments__${Number(keys[1])}`];
						let isDseSelected = filterItems(
							check4Key,
							formdataKeys
						);

						if (comment_txt && !isDseSelected.length) {
							manageStatus_error[
								"comments_" + keys[1]
							] = this.props.t("MANAGE_STATUS.DSE_REQUIRED");
						}
					}
				} else {
					if (keys[0] === "all_dse") {
						all_dse = true;
						if (formdata["comments__0"]) {
							lead_comment.push({
								all_dse: true,
								comment: formdata["comments__0"],
								comment_type: "EXTERNAL",
							});
						} else {
							if (activeTab === "external")
								manageStatus_error["comments_0"] = this.props.t(
									"MANAGE_STATUS.COMMENTS_REQUIRED"
								);
						}
					} else {
						inpval = formdata[keys[0]];
						let optional_fields = this.state.optional_fields || [];
						optional_fields.push("status_reason_id");
						let keyname2send = keys[0];
						if (inpval) {
							if (
								keys[0] === "make_id" ||
								keys[0] === "model_id"
							) {
								car_details[keys[0]] = inpval;
							} else {
								if (keys[0] === "followup_time") {
									inpval = this.state.filterData
										.followup_time;
								}
								if (keys[0] === "booking_name_chk") {
									inpval = this.state.filterData.booking_name;
									keyname2send = "booking_name";
								}
								if (keys[0] === "booking_mobile_chk") {
									inpval = this.state.filterData
										.booking_mobile;
									keyname2send = "booking_mobile";
								}
								if (keys[0] === "booking_city_chk") {
									inpval = this.state.filterData
										.booking_city_id;
									keyname2send = "booking_city_id";
								}
								if (keys[0] === "booking_relation") {
									inpval = this.state.filterData
										.booking_relation;
								}
								request_data[keyname2send] = inpval;
							}
						} else if (!optional_fields.includes(keys[0])) {
							manageStatus_error[keys[0]] = this.props.t(
								"MANAGE_STATUS.REQUIRED_FIELDS"
							);
						}
					}
				}
			});
		}
		if (this.state.internalComment) {
			lead_comment.push({
				all_dse: true,
				comment: this.state.internalComment,
				comment_type: "INTERNAL",
			});
			if (request_data.internal_comment)
				delete request_data.internal_comment;
		}
		if (
			this.state.enableReopenFlag ||
			this.state.enableEaf ||
			this.state.enableNcf ||
			this.state.enableDseProcessFlag
		) {
			let car_details = {};
			request_data = { ...request_data, ...this.state.filterData };
			if (request_data && request_data.purchase_date)
				request_data.purchase_date = dateformat(
					request_data.purchase_date,
					config.constants.DATE_FORMAT_YMD
				);
			else delete request_data.purchase_date;
			if (this.state.filterData && this.state.filterData.make_id) {
				car_details["make_id"] = this.state.filterData.make_id;
				delete request_data.make_id;
			}
			if (this.state.filterData && this.state.filterData.model_id) {
				car_details["model_id"] = this.state.filterData.model_id;
				delete request_data.model_id;
			}
			if (car_details && car_details.make_id)
				request_data["car_details"] = car_details;
			if (
				Number(request_data.rf) === 0 &&
				Number(request_data.eaf) === 0 &&
				Number(request_data.ncf) === 0
			) {
				request_data["follow_up_date"] = this.state.enableDateFields
					? request_data.follow_up_date
					: null;
			}
		}
		if (lead_comment.length && lead_comment[0].comment) {
			request_data.lead_comment = lead_comment;
		}
		if (Object.keys(car_details).length)
			request_data.car_details = car_details;
		// Update status
		if (!Object.keys(manageStatus_error).length) {
			this.setState({
				loading: true,
				manageStatus_error: manageStatus_error,
			});
			let responseData = {},
				message = "";
			if (request_data.follow_up_date && request_data.followup_time) {
				let followup_date2send = new Date(
					dateformat(
						dateformat(
							request_data.follow_up_date,
							config.constants.DATE_FORMAT_YMD
						) +
							" " +
							request_data.followup_time,
						config.constants.DATE_FORMAT
					)
				).toISOString();
				request_data.follow_up_date = followup_date2send;
			}

			await LeadService.updateStatus(request_data)
				.then(async (response) => {
					if (
						response.status === 200 &&
						response.data.status === 200
					) {
						responseData = response.data.data;
						message = response.data.message;
						toast.success(message);
						this.myFormRef.reset();
						let dseList = this.state.dseList;
						let isFileSelected = this.state.selectedFile
							? true
							: false;
						if (isFileSelected) {
							let uplres = await this.uploadFileOnAWS(
								request_data.lead_id,
								responseData.activity_hash
							);
							console.log("ires=", uplres);
						}
						this.setState(
							{
								filterData: {},
								loading: false,
								updateStatusMsg: message,
								commentBoxes: [0],
								manageStatus_error: {},
								updateStatus: true,
								addCommentButton:
									dseList && dseList.length ? true : false,
								usedDealersData: [],
								commentData: [],
								showTab: 1,
								internalComment: "",
								statusList: this.state.tempStatusList,
								selectedFile: null,
							},
							async () => {
								this.props.getCustomerInformation(
									request_data.lead_id
								);
								if (!isFileSelected)
									await this.props.actions.updateHistoryFlag(
										true
									);
							}
						);
					} else {
						message = response.data.message;
						this.setState({
							loading: false,
							updateStatusMsg: message,
						});
					}
				})
				.catch((error) => {
					toast.error(error);
				});
		} else {
			this.setState({ manageStatus_error: manageStatus_error });
		}
	};
	resetFormManageStatus = () => {
		this.myFormRef.reset();
		const leadId = new URLSearchParams(window.location.search).get("id");
		this.props.getCustomerInformation(leadId);
		this.setState({ filterData: {}, commentBoxes: [0] });
	};

	onChangeHandler = async (event) => {
		let selectedFile = event.target.files[0];
		await this.setState({ selectedFile: selectedFile });
	};

	uploadFileOnAWS = async (leadId, activity_hash) => {
		let { selectedFile } = this.state; //event.target.files;
		let files = Array.from([selectedFile]);
		let awsRes = [],
			leadDocArr = {};
		if (files && files.length) {
			var formData = new FormData();
			formData.append("upload_type", "crm_docs");
			formData.append("lead_id", leadId);
			files.forEach((file, index) => {
				formData.append("images", file);
			});
			awsRes.push(
				LeadService.uploadFilesToS3(formData, {
					"Content-Type": "multipart/form-data",
				})
			);
		} else {
			awsRes.push(
				Promise.resolve({
					data: {
						status: 200,
						message: "Success",
						data: ["not_updated"],
					},
				})
			);
			this.setState({ loaderClass: "" });
		}

		Q.allSettled(awsRes)
			.then((uploadOutput) => {
				uploadOutput.forEach(async (res, index) => {
					let response = res.value || [];
					if (res.state === "fulfilled") {
						if (response.data.data.length) {
							let resData = response.data.data;

							leadDocArr["lead_id"] = leadId;
							leadDocArr["file_name"] = resData[0].file_name;
							leadDocArr["file_url"] = resData[0].file_url;
							leadDocArr["file_size"] = resData[0].file_size;
							leadDocArr["activity_hash"] = activity_hash;
							let uploadResult = await LeadService.uploadDocInfo(
								leadDocArr
							);

							if (
								uploadResult &&
								uploadResult.status === 200 &&
								uploadResult.data
							) {
								this.props.actions.updateHistoryFlag(true);
								//toast.success(uploadResult.data.message);
								this.setState({
									loading: false,
									formData: {},
									selectedFile: null,
									errors: {},
									uploaded_document: uploadResult.data.data,
								});
								this.fileInput.value = "";
							} else {
								this.setState({
									loading: false,
									selectedFile: null,
								});
								this.fileInput.value = "";
							}
						}
					}
				});
			})
			.catch((error) => {
				this.setState({ loaderClass: "" });
			});
		return true;
	};

	handleTabChange = async (event) => {
		await this.setState({
			showTab: +event.currentTarget.dataset.key,
		});
	};

	getCommentByType = (commentData, commentType, index) => {
		let commentText = "",
			commentIndex = -1;
		let key = "comments__" + commentType + "__" + index;
		commentIndex = commentData.findIndex(function (el) {
			return key in el;
		});

		if (commentIndex !== -1) commentText = commentData[commentIndex][key];
		return commentText;
	};

	changeCommentBox = (key, e) => {
		let { internalComment } = this.state;
		internalComment = e.target.value;
		this.setState({ internalComment: internalComment });
	};

	getUploadedDocument = async () => {
		let leadId = new URLSearchParams(window.location.search).get("id");
		let getResult = await LeadService.getDocumentList({ lead_id: leadId });
		if (getResult && getResult.status === 200 && getResult.data) {
			this.setState({
				uploaded_document: getResult.data.data,
			});
		}
	};
	modifiedStatusInfo = () => {
		let {
			statusList,
			subStatusList,
			attribution_list,
			originalStatus,
			originalSubStatus,
			leadDetail,
			authUser,
			filterData,
		} = this.state;
		//console.log("filterData", filterData);
		let { eaf, ncf, rf, attribution_id } = leadDetail;
		//console.log("eaf",eaf,"ncf",ncf,"rf",rf)
		let role_id =
			authUser && authUser.authUser && authUser.authUser.role_id;

		let modifiedStatus = [],
			modifiedsubStatusList = [],
			modified_attribution_list = [],
			disableStatusIds = [],
			disableSubStatusIds = [],
			disableAtrributionStatusIds = [];

		// case NYC/NYC
		if (originalStatus === 1 && originalSubStatus === 1) {
			disableStatusIds = [3, 24];
			disableSubStatusIds = [15];
			disableAtrributionStatusIds = [2, 3, 5, 4];
			if (+filterData.sub_status_id === 13)
				disableAtrributionStatusIds = [2, 3, 5];
		}
		// case Interested/HOT and case Interested/Warm and case Interested/Cold
		if (
			(originalStatus === 2 && originalSubStatus === 2) ||
			(originalStatus === 2 && originalSubStatus === 3) ||
			(originalStatus === 2 && originalSubStatus === 4)
		) {
			disableStatusIds = [3, 1];
			disableSubStatusIds = [15, 22];
			disableAtrributionStatusIds = [2, 3, 5, 4];
			if (+filterData.sub_status_id === 13)
				disableAtrributionStatusIds = [2, 3, 5];
		}

		// case Not Contactable/Not Reachable / Not Contactable/Not Answering / Not Interested/Just Exploring / Not Interested/Low Budget / Invalid/Wrong Number / Invalid/DND / Closed/Not Purchased / Closed/SPK Cancelled

		if (
			(originalStatus === 3 && originalSubStatus === 5) ||
			(originalStatus === 3 && originalSubStatus === 6) ||
			(originalStatus === 4 && originalSubStatus === 7) ||
			(originalStatus === 4 && originalSubStatus === 8) ||
			(originalStatus === 5 && originalSubStatus === 9) ||
			(originalStatus === 5 && originalSubStatus === 10) ||
			(originalStatus === 24 && originalSubStatus === 21) ||
			(originalStatus === 24 && originalSubStatus === 22)
		) {
			disableStatusIds = [1, 3, 4, 5, 24];
			disableSubStatusIds = [11, 12, 15, 16];
			disableAtrributionStatusIds = [2, 3, 5, 4];
			//if (+rf === 0 && +ncf === 0) disableStatusIds.push(2);
			if (originalStatus === 24 && originalSubStatus === 21)
				disableStatusIds.push(6);

			disableStatusIds = disableStatusIds.filter(
				(v) => v != originalStatus
			);

			let additionalids4disable =
				subStatusList && subStatusList.length
					? subStatusList
							.filter(
								(v) =>
									v.status_id != 2 &&
									v.status_id != 6 &&
									v.sub_status_id != originalSubStatus
							)
							.map((v) => v.sub_status_id)
					: [];
			disableSubStatusIds = disableSubStatusIds.concat(
				additionalids4disable
			);
		}

		// case Bought A Car/Used
		if (originalStatus === 6 && originalSubStatus === 16) {
			disableStatusIds = [1, 2, 3, 4, 5, 24];
			disableSubStatusIds = [11, 12, 15, 13];
			disableAtrributionStatusIds = [2, 3, 5, 4, 1];
			//if (+eaf === 0) disableSubStatusIds.push(13);
		}

		// case Bought A Car/Lost Purchased

		if (originalStatus === 6 && originalSubStatus === 15) {
			disableStatusIds = [1, 2, 3, 4, 5, 24];
			disableSubStatusIds = [11, 12, 13, 16];
			disableAtrributionStatusIds = [2, 3, 5, 4, 1];
		}

		// case Bought A Car/SPK/New To Attribution
		if (
			originalStatus === 6 &&
			originalSubStatus === 11 &&
			attribution_id === 1
		) {
			disableStatusIds = [1, 2, 3, 4, 5, 24];
			disableSubStatusIds = [13, 15, 16, 21, 12];
			disableAtrributionStatusIds = [2, 3, 4, 5];
			if (role_id === 1 || role_id === 7 || role_id === 5) {
				disableAtrributionStatusIds = [2, 3, 5];
				disableStatusIds = [1, 2, 3, 4, 5];
				disableSubStatusIds = [13, 15, 16, 21];
			}

			if (+filterData.sub_status_id === 12)
				disableAtrributionStatusIds = [2, 3, 5, 4];
		}

		// case Bought A Car/DO/New To Attribution

		if (
			originalStatus === 6 &&
			originalSubStatus === 12 &&
			attribution_id === 1
		) {
			disableStatusIds = [1, 2, 3, 4, 5, 24];
			disableSubStatusIds = [11, 13, 15, 16];
			disableAtrributionStatusIds = [2, 3, 5, 4];
			if (role_id === 1 || role_id === 7 || role_id === 5)
				disableAtrributionStatusIds = [];
		}

		// case Bought A Car/DO/Attribution Pending

		if (
			originalStatus === 6 &&
			originalSubStatus === 12 &&
			attribution_id === 2
		) {
			disableStatusIds = [1, 2, 3, 4, 5, 24];
			disableSubStatusIds = [11, 13, 15, 16];
			disableAtrributionStatusIds = [1, 3, 5, 4];
			if (role_id === 1 || role_id === 7 || role_id === 5)
				disableAtrributionStatusIds = [1, 4];
		}

		// case Bought A Car/DO/Rejected

		if (
			originalStatus === 6 &&
			originalSubStatus === 12 &&
			attribution_id === 5
		) {
			disableStatusIds = [1, 2, 3, 4, 5, 24];
			disableSubStatusIds = [11, 13, 15, 16];
			disableAtrributionStatusIds = [1, 2, 3, 4];
			if (role_id === 1 || role_id === 7 || role_id === 5)
				disableAtrributionStatusIds = [2, 3];
		}

		// case Bought A Car/DO/Attributed

		if (
			originalStatus === 6 &&
			originalSubStatus === 12 &&
			attribution_id === 3
		) {
			disableStatusIds = [1, 2, 3, 4, 5, 24];
			disableSubStatusIds = [11, 13, 15, 16];
			disableAtrributionStatusIds = [1, 2, 4, 5];
		}

		// case Bought A Car/DO/Closed

		if (
			originalStatus === 6 &&
			originalSubStatus === 12 &&
			attribution_id === 4
		) {
			disableStatusIds = [1, 2, 3, 4, 5, 24];
			disableSubStatusIds = [11, 13, 15, 16];
			disableAtrributionStatusIds = [1, 2, 3, 5];
		}

		// case Bought A Car/Unknown/New To Attribution
		if (
			originalStatus === 6 &&
			originalSubStatus === 13 &&
			attribution_id === 1
		) {
			disableStatusIds = [1, 3, 4, 5];
			disableSubStatusIds = [12, 15, 22];
			disableAtrributionStatusIds = [2, 3, 5];
			if (+filterData.sub_status_id === 11)
				disableAtrributionStatusIds = [2, 3, 5, 4];
		}

		// case Bought A Car/Unknown/Closed
		if (
			originalStatus === 6 &&
			originalSubStatus === 13 &&
			attribution_id === 4
		) {
			disableStatusIds = [1, 2, 3, 4, 5, 24];
			disableSubStatusIds = [11, 12, 15, 16];
			disableAtrributionStatusIds = [2, 3, 5, 1];
		}

		// case Bought A Car/SPK/Closed
		if (
			originalStatus === 6 &&
			originalSubStatus === 11 &&
			attribution_id === 4
		) {
			disableStatusIds = [1, 2, 3, 4, 5, 24];
			disableSubStatusIds = [12, 13, 15, 16];
			disableAtrributionStatusIds = [1, 2, 3, 5];
		}

		// final array of status and others
		modifiedStatus = statusList.map((v) => {
			if (
				disableStatusIds.length &&
				disableStatusIds.includes(v.status_id)
			) {
				v.isDisabled = true;
			} else {
				v.isDisabled = false;
			}
			return v;
		});

		modifiedsubStatusList = subStatusList.map((v) => {
			if (
				disableSubStatusIds.length &&
				disableSubStatusIds.includes(v.sub_status_id)
			) {
				v.isDisabled = true;
			} else {
				v.isDisabled = false;
			}
			return v;
		});

		modified_attribution_list = attribution_list.map((v) => {
			if (
				disableAtrributionStatusIds.length &&
				disableAtrributionStatusIds.includes(v.attribution_id)
			) {
				v.isDisabled = true;
			} else {
				v.isDisabled = false;
			}
			return v;
		});

		return {
			modifiedStatus,
			modifiedsubStatusList,
			modified_attribution_list,
		};
	};

	getMaxMinDate = (type) => {
		let { filterData } = this.state;
		let date = new Date();
		let noOfDaysAdd = 0;
		//let min_buy_time = new Date();
		if (filterData.sub_status_id === 2) {
			noOfDaysAdd = type === "MAX" ? 30 : 1;
		}
		if (filterData.sub_status_id === 3) {
			noOfDaysAdd = type === "MAX" ? 60 : 31;
		}
		if (filterData.sub_status_id === 4) {
			noOfDaysAdd = type === "MAX" ? 1000000000000 : 61;
		}
		date.setDate(date.getDate() + noOfDaysAdd);
		return date;
	};

	render() {
		const {
			filterData,
			commentBoxes,
			dealersData,
			addCommentButton,
			usedDealersData,
			enableDateFields,
			enableDealersFields,
			loading,
			manageStatus_error,
			is_future_date,
			modelList,
			filteredDse,
			addDseTextBoxStatus,
			enableMmvFields,
			addDealerTextBoxStatus,
			showTab,
			tabs,
			billing_status_list,
			uploaded_document,
			enableReopenFlag,
			enableEaf,
			enableNcf,
			enableDseProcessFlag,
			relationship_list,
			city_list,
			reasons_list,
			status_reasons,
			stnk_list
			
		} = this.state;
		const { make_list, dealerList } = this.props;

		// new logic for display of status and sub status start (GIN-1646)
		let {
			modifiedStatus,
			modifiedsubStatusList,
			modified_attribution_list,
		} = this.modifiedStatusInfo();

		// new logic for display of status and sub status end

		/// add option for other in dealer start

		if (
			dealerList &&
			dealerList.length &&
			!dealerList.filter((v) => v.dealer_id === "other").length
		) {
			dealerList.push({
				dealer_id: "other",
				name: "Other",
				organization: "Other",
				locality: "",
			});
		}
		/// add option for other in dealer end

		/// split time of follow up start

		if (filterData && filterData.followup_time) {
			let timeArr = filterData.followup_time.split(":");
			filterData.followup_time =
				timeArr &&
				timeArr.length &&
				String(timeArr[0]) === "00" &&
				String(timeArr[1]) === "00"
					? ""
					: filterData.followup_time;
		}

		/// split time of follow up end

		/// add option for other in dse dropdown start

		if (
			filteredDse &&
			filteredDse.length &&
			!filteredDse.filter((v) => v.id === "other").length
		) {
			filteredDse.push({
				id: "other",
				name: "Other",
			});
		}

		/// add option for other in dse dropdown end

		/// enable / disable attribution status start

		let enableBillingStatusFields = false,
			enableAttributionFields = false,
			enableDocumentUploadField = false;

		if (
			filterData &&
			filterData.status_id &&
			filterData.sub_status_id &&
			+filterData.status_id === 6 &&
			+filterData.sub_status_id !== 16 &&
			modified_attribution_list.length &&
			modified_attribution_list.filter((v) => !v.isDisabled).length
		) {
			enableAttributionFields = true;
		}
		if (
			enableAttributionFields &&
			filterData &&
			!filterData.attribution_id
		) {
			filterData.attribution_id = 1;
		}
		if (
			filterData &&
			filterData.attribution_id &&
			+filterData.attribution_id === 3 &&
			enableAttributionFields
		) {
			enableBillingStatusFields = true;
			if (filterData && !filterData.billing_status_id) {
				filterData.billing_status_id = 1;
			}
		}
		if (filterData && filterData.status_id && +filterData.status_id === 6)
			enableDocumentUploadField = true;
		let reopenClass = "",
			isFlagEnabled = false;
		if (
			enableReopenFlag ||
			enableNcf ||
			enableEaf ||
			enableDseProcessFlag
		) {
			reopenClass = "reopenClass";
			isFlagEnabled = true;
		}
		if (
			filterData &&
			Number(filterData.rf) === 0 &&
			Number(filterData.ncf) === 0 &&
			Number(filterData.eaf) === 0
		)
			isFlagEnabled = false;
		let isSaveButtonDisabled = true;
		if (filterData && Number(filterData.rf) === 0 && enableReopenFlag)
			isSaveButtonDisabled = false;
		if (filterData && Number(filterData.eaf) === 0 && enableEaf)
			isSaveButtonDisabled = false;
		if (filterData && Number(filterData.ncf) === 0 && enableNcf)
			isSaveButtonDisabled = false;
		if (!isFlagEnabled) isSaveButtonDisabled = false;
		let enableBookingInfoField = false;
		if (
			filterData &&
			filterData.status_id &&
			+filterData.status_id === 6 &&
			(filterData.sub_status_id === 11 ||
				filterData.sub_status_id === 12 ||
				filterData.sub_status_id === 13)
		)
			enableBookingInfoField = true;

		/// enable / disable attribution status end

		/// enable / disable reason fields start

		let enableReasonsFields =
			+filterData.status_id === 6 &&
			(+filterData.sub_status_id === 11 ||
				+filterData.sub_status_id === 12 ||
				+filterData.sub_status_id === 13);
		let enableReasonTextbox =
			+filterData.reason_id === 10 && enableReasonsFields ? true : false;

		/// enable / disable reason fields end

		// status reason code start
		let attribution_id2com = filterData.attribution_id || 0;
		let modifiedStatusResons = status_reasons
			? status_reasons.filter(
					(v) =>
						v.status_id === filterData.status_id &&
						v.sub_status_id === filterData.sub_status_id &&
						v.attribution_status_id === attribution_id2com
			  )
			: [];
		let enableStatusReasonsFields =
			modifiedStatusResons && modifiedStatusResons.length ? true : false;

		let enableStnk =
			filterData.status_id === 6 &&
			(filterData.sub_status_id === 11 || filterData.sub_status_id === 12)
				? true
				: false;

		let enableSTNKFollowupbox = filterData.stnk_id === 1 ? true : false;
		let enableSTNKTextbox = [2,5].includes(filterData.stnk_id) ? true : false;

		return (
			<div>
				<div className={"card" + " " + reopenClass}>
					{loading && <PageLoader loaderClass="text-center" />}
					<div className="card-heading">
						<div className="row">
							<div className="col-sm-6">
								<h2 className="card-title">
									{this.props.t(
										"MANAGE_STATUS.MANAGE_STATUS"
									)}
								</h2>
							</div>
							<div className="col-sm-6 text-right">
								<button
									type="button"
									className="btn btn-link"
									onClick={this.resetFormManageStatus}
								>
									{this.props.t(
										"MANAGE_STATUS.RESET_ALL_FIELDS"
									)}
								</button>
							</div>
						</div>
					</div>
					<div className="card-body">
						<form
							onSubmit={this.UpdateStatus}
							ref={(el) => (this.myFormRef = el)}
						>
							<div className="row">
								<div className="col-sm-6 form-group">
									<Select
										id="status_id"
										onChange={this.handleChange.bind(
											this,
											"status_id"
										)}
										options={modifiedStatus}
										name="status_id"
										placeholder={this.props.t(
											"MANAGE_STATUS.STATUS"
										)}
										value={modifiedStatus.filter(
											({ status_id }) =>
												status_id ===
												filterData.status_id
										)}
										getOptionLabel={({ name }) => name}
										getOptionValue={({ status_id }) =>
											status_id
										}
										isClearable
										isDisabled={isFlagEnabled}
									/>
									{manageStatus_error &&
									manageStatus_error.status_id ? (
										<span className="error show">
											{manageStatus_error.status_id}
										</span>
									) : (
										""
									)}
								</div>
								<div className="col-sm-6 form-group">
									<Select
										key={filterData.status_id}
										id="sub_status_id"
										onChange={this.handleChange.bind(
											this,
											"sub_status_id"
										)}
										options={modifiedsubStatusList}
										name="sub_status_id"
										placeholder={this.props.t(
											"MANAGE_STATUS.SUB_STATUS"
										)}
										value={modifiedsubStatusList.filter(
											({ sub_status_id }) =>
												sub_status_id ===
												filterData.sub_status_id
										)}
										getOptionLabel={({ sub_status_name }) =>
											sub_status_name
										}
										getOptionValue={({ sub_status_id }) =>
											sub_status_id
										}
										isClearable
										isDisabled={isFlagEnabled}
									/>
									{manageStatus_error &&
									manageStatus_error.sub_status_id ? (
										<span className="error show">
											{manageStatus_error.sub_status_id}
										</span>
									) : (
										""
									)}
								</div>
								{enableAttributionFields &&
								constant.isUserHasRightsToAccessPage(
									"lead",
									"lead",
									"/attribution_status"
								) ? (
									<div className="col-sm-12 form-group">
										<Select
											key={filterData.sub_status_id}
											id="attribution_id"
											onChange={this.handleChange.bind(
												this,
												"attribution_id"
											)}
											options={modified_attribution_list}
											name="attribution_id"
											placeholder={this.props.t(
												"MANAGE_STATUS.ATTRIBUTION_STATUS"
											)}
											value={modified_attribution_list.filter(
												({ attribution_id }) =>
													attribution_id ===
													filterData.attribution_id
											)}
											getOptionLabel={({ name }) => name}
											getOptionValue={({
												attribution_id,
											}) => attribution_id}
											isClearable
											isDisabled={isFlagEnabled}
										/>
										{manageStatus_error &&
										manageStatus_error.attribution_id ? (
											<span className="error show">
												{
													manageStatus_error.attribution_id
												}
											</span>
										) : (
											""
										)}
									</div>
								) : (
									""
								)}

								{enableStatusReasonsFields ? (
									<>
										<div className="col-sm-12 form-group">
											<label>
												{this.props.t(
													"MANAGE_STATUS.STATUS_REASONS"
												)}
											</label>
											<Select
												id="status_reason_id"
												onChange={this.handleChange.bind(
													this,
													"status_reason_id"
												)}
												options={modifiedStatusResons}
												name="status_reason_id"
												placeholder={this.props.t(
													"MANAGE_STATUS.STATUS_REASONS"
												)}
												value={modifiedStatusResons.filter(
													({ reason_id }) =>
														reason_id ===
														filterData.status_reason_id
												)}
												getOptionLabel={({ name }) =>
													name
												}
												getOptionValue={({
													reason_id,
												}) => reason_id}
												isClearable
											/>
											{manageStatus_error &&
											manageStatus_error.status_reason_id ? (
												<span className="error show">
													{
														manageStatus_error.status_reason_id
													}
												</span>
											) : (
												""
											)}
										</div>
									</>
								) : null}

								{enableBillingStatusFields &&
								constant.isUserHasRightsToAccessPage(
									"lead",
									"lead",
									"/billing_status"
								) ? (
									<div className="col-sm-12 form-group">
										<Select
											id="billing_status_id"
											onChange={this.handleChange.bind(
												this,
												"billing_status_id"
											)}
											options={billing_status_list}
											name="billing_status_id"
											placeholder={this.props.t(
												"MANAGE_STATUS.BILLING_STATUS"
											)}
											value={billing_status_list.filter(
												({ billing_status_id }) =>
													billing_status_id ===
													filterData.billing_status_id
											)}
											getOptionLabel={({ name }) => name}
											getOptionValue={({
												billing_status_id,
											}) => billing_status_id}
											isClearable
											isDisabled={isFlagEnabled}
										/>
										{manageStatus_error &&
										manageStatus_error.attribution_id ? (
											<span className="error show">
												{
													manageStatus_error.billing_status_id
												}
											</span>
										) : (
											""
										)}
									</div>
								) : (
									""
								)}
								{enableReopenFlag ? (
									<div className="col-sm-12 form-group">
										<CheckBox
											label={this.props.t(
												"MANAGE_STATUS.RF"
											)}
											name="rf"
											type="checkbox"
											id="rf"
											checked={
												filterData &&
												filterData.rf &&
												Number(filterData.rf) === 1
													? true
													: false
											}
											value="1"
											onChange={this.handleChange.bind(
												this,
												"rf"
											)}
										/>
									</div>
								) : (
									""
								)}
								{enableEaf ? (
									<div className="col-sm-12 form-group">
										<CheckBox
											label={this.props.t(
												"MANAGE_STATUS.EAF"
											)}
											name="eaf"
											type="checkbox"
											id="eaf"
											checked={
												filterData &&
												filterData.eaf &&
												Number(filterData.eaf) === 1
													? true
													: false
											}
											value="1"
											onChange={this.handleChange.bind(
												this,
												"eaf"
											)}
										/>
									</div>
								) : (
									""
								)}
								{enableNcf ? (
									<div className="col-sm-12 form-group">
										<CheckBox
											label={this.props.t(
												"MANAGE_STATUS.NCF"
											)}
											name="ncf"
											type="checkbox"
											id="ncf"
											checked={
												filterData &&
												filterData.ncf &&
												Number(filterData.ncf) === 1
													? true
													: false
											}
											value="1"
											onChange={this.handleChange.bind(
												this,
												"ncf"
											)}
										/>
									</div>
								) : (
									""
								)}
								{enableDseProcessFlag ? (
									<div className="col-sm-12 form-group">
										<CheckBox
											label={this.props.t(
												"MANAGE_STATUS.DSE_PROCESS"
											)}
											name="dse_process"
											type="checkbox"
											id="dse_process"
											checked={
												filterData &&
												filterData.dse_process &&
												Number(
													filterData.dse_process
												) === 1
													? true
													: false
											}
											value="1"
											onChange={this.handleChange.bind(
												this,
												"dse_process"
											)}
										/>
									</div>
								) : (
									""
								)}
								{enableDateFields || isFlagEnabled ? (
									<>
										{" "}
										<div className="col-sm-6 form-group">
											<label>
												{this.props.t(
													"MANAGE_STATUS.FOLLOW_UP_DATE"
												)}
											</label>
											<DatePicker
												showYearDropdown
												showMonthDropdown
												selected={
													filterData &&
													filterData.follow_up_date
														? new Date(
																filterData.follow_up_date
														  )
														: ""
												}
												onChange={(date) =>
													this.handleDateTimeChange(
														date,
														"follow_up_date"
													)
												}
												name="follow_up_date"
												id="follow_up_date"
												autoComplete="off"
												placeholderText={this.props.t(
													"MANAGE_STATUS.FOLLOW_UP_DATE"
												)}
												dateFormat={
													config.constants
														.DATE_FORMAT_INPUT
												}
												className="form-control"
												minDate={new Date()}
												onKeyDown={(e) =>
													e.preventDefault()
												}
												disabled={isFlagEnabled}
											/>
											{manageStatus_error &&
											manageStatus_error.follow_up_date ? (
												<span className="error show">
													{
														manageStatus_error.follow_up_date
													}
												</span>
											) : (
												""
											)}
										</div>
										<div className="col-sm-6 form-group">
											<label>
												{this.props.t(
													"MANAGE_STATUS.FOLLOW_UP_TIME"
												)}
											</label>
											<DatePicker
												showTimeSelect
												showTimeSelectOnly
												// selected={
												// 	filterData &&
												// 	filterData.followup_time
												// 		?
												// 		filterData.followup_time

												// 		: ""
												// }
												value={
													(filterData &&
														filterData.followup_time) ||
													""
												}
												onChange={(date) =>
													this.handleDateTimeChange(
														date,
														"followup_time"
													)
												}
												name="followup_time"
												id="followup_time"
												autoComplete="off"
												placeholderText={this.props.t(
													"MANAGE_STATUS.FOLLOW_UP_TIME"
												)}
												dateFormat={
													config.constants
														.TIME_FORMAT_INPUT
												}
												timeFormat={
													config.constants
														.TIME_FORMAT_INPUT
												}
												timeIntervals={15}
												className="form-control"
												minTime={
													is_future_date
														? now.setHours(7, 59)
														: now.setHours(
																currhour,
																currmin
														  )
												}
												// isClearable
												maxTime={now.setHours(20, 11)}
												onKeyDown={(e) =>
													e.preventDefault()
												}
												disabled={isFlagEnabled}
											/>
											{manageStatus_error &&
											manageStatus_error.followup_time ? (
												<span className="error show">
													{
														manageStatus_error.followup_time
													}
												</span>
											) : (
												""
											)}
										</div>{" "}
									</>
								) : (
									""
								)}
								{enableMmvFields ? (
									<>
										<div className="col-sm-6 form-group">
											<Select
												id="make_id"
												onChange={this.handleChange.bind(
													this,
													"make_id"
												)}
												options={make_list}
												name="make_id"
												placeholder={this.props.t(
													"MANAGE_STATUS.MAKE"
												)}
												value={make_list.filter(
													({ id }) =>
														id ===
														filterData.make_id
												)}
												getOptionLabel={({ make }) =>
													make
												}
												getOptionValue={({ id }) => id}
												isClearable
												isDisabled={isFlagEnabled}
											/>
											{manageStatus_error &&
											manageStatus_error.make_id ? (
												<span className="error show">
													{manageStatus_error.make_id}
												</span>
											) : (
												""
											)}
										</div>
										<div className="col-sm-6 form-group">
											<Select
												id="model_id"
												onChange={this.handleChange.bind(
													this,
													"model_id"
												)}
												options={modelList}
												name="model_id"
												placeholder={this.props.t(
													"MANAGE_STATUS.MODEL"
												)}
												value={modelList.filter(
													({ id }) =>
														id ===
														filterData.model_id
												)}
												getOptionLabel={({ m }) => m}
												getOptionValue={({ id }) => id}
												isClearable
												isDisabled={isFlagEnabled}
											/>
											{manageStatus_error &&
											manageStatus_error.model_id ? (
												<span className="error show">
													{
														manageStatus_error.model_id
													}
												</span>
											) : (
												""
											)}
										</div>
									</>
								) : (
									""
								)}
								{enableDealersFields ? (
									<>
										<div className="col-sm-12 form-group">
											<label>
												{this.props.t(
													"MANAGE_STATUS.DEALER_DSE_DETAIL"
												)}
											</label>
											<Select
												id="purchase_dealer_id"
												onChange={this.handleChange.bind(
													this,
													"purchase_dealer_id"
												)}
												options={dealerList}
												name="purchase_dealer_id"
												placeholder={this.props.t(
													"MANAGE_STATUS.DEALER_DSE_DETAIL"
												)}
												value={dealerList.filter(
													({ dealer_id }) =>
														dealer_id ===
														filterData.purchase_dealer_id
												)}
												getOptionLabel={({
													name,
													locality,
												}) =>
													name === "Other"
														? name
														: locality
														? name + "- " + locality
														: name
												}
												getOptionValue={({
													dealer_id,
												}) => dealer_id}
												isClearable
												isDisabled={isFlagEnabled}
											/>
											{manageStatus_error &&
											manageStatus_error.purchase_dealer_id ? (
												<span className="error show">
													{
														manageStatus_error.purchase_dealer_id
													}
												</span>
											) : (
												""
											)}
										</div>
										{addDealerTextBoxStatus ? (
											<div className="col-sm-12">
												<InputField
													inputProps={{
														id: "other_dealer_name",
														type: "text",
														value:
															filterData.other_dealer_name ||
															"",
														name:
															"other_dealer_name",
														autoComplete: "off",
														placeholder: this.props.t(
															"MANAGE_STATUS.DEALER_NAME"
														),
														// label_before_input:
														// 	"No",
														label: this.props.t(
															"MANAGE_STATUS.DEALER_NAME"
														),
														validation_error:
															manageStatus_error &&
															manageStatus_error.other_dealer_name
																? manageStatus_error.other_dealer_name
																: "",
														disabled: isFlagEnabled,
													}}
													autoComplete="off"
													onChange={this.handleChange.bind(
														this,
														"other_dealer_name"
													)}
												/>
											</div>
										) : (
											""
										)}
										{filterData.purchase_dealer_id ===
										"other" ? (
											""
										) : (
											<div className="col-sm-12 form-group">
												<label>
													{this.props.t(
														"MANAGE_STATUS.DSE_DETAIL"
													)}
												</label>
												<Select
													id="purchase_from_dse_id"
													onChange={this.handleChange.bind(
														this,
														"purchase_from_dse_id"
													)}
													options={filteredDse}
													name="purchase_from_dse_id"
													placeholder={this.props.t(
														"MANAGE_STATUS.DSE_DETAIL"
													)}
													value={filteredDse.filter(
														({ id }) =>
															id ===
															filterData.purchase_from_dse_id
													)}
													getOptionLabel={({
														name,
													}) => name}
													getOptionValue={({ id }) =>
														id
													}
													isClearable
													isDisabled={isFlagEnabled}
												/>

												{manageStatus_error &&
												manageStatus_error.purchase_from_dse_id ? (
													<span className="error show">
														{
															manageStatus_error.purchase_from_dse_id
														}
													</span>
												) : (
													""
												)}
											</div>
										)}

										{addDseTextBoxStatus ? (
											<div className="col-sm-12">
												<InputField
													inputProps={{
														id: "other_dse_name",
														type: "text",
														value:
															filterData.other_dse_name ||
															"",
														name: "other_dse_name",
														autoComplete: "off",
														placeholder: this.props.t(
															"MANAGE_STATUS.DSE_NAME"
														),
														// label_before_input:
														// 	"Yes",
														label: this.props.t(
															"MANAGE_STATUS.DSE_NAME"
														),
														validation_error:
															manageStatus_error &&
															manageStatus_error.other_dse_name
																? manageStatus_error.other_dse_name
																: "",
														disabled: isFlagEnabled,
													}}
													autoComplete="off"
													onChange={this.handleChange.bind(
														this,
														"other_dse_name"
													)}
												/>
											</div>
										) : (
											""
										)}

										{filterData.sub_status_id === 11 ||
										filterData.sub_status_id === 12 ||
										filterData.sub_status_id === 13 ? (
											<div
												className={
													filterData.sub_status_id !==
													12
														? "col-sm-12 form-group"
														: "col-sm-6 form-group"
												}
											>
												<label>
													{this.props.t(
														"MANAGE_STATUS.BOOKING_DATE"
													)}
												</label>
												<DatePicker
													showYearDropdown
													showMonthDropdown
													selected={
														filterData &&
														filterData.booking_date
															? new Date(
																	filterData.booking_date
															  )
															: ""
													}
													onChange={(date) =>
														this.handleDateTimeChange(
															date,
															"booking_date"
														)
													}
													name="booking_date"
													id="booking_date"
													autoComplete="off"
													placeholderText={this.props.t(
														"MANAGE_STATUS.BOOKING_DATE"
													)}
													dateFormat="yyyy-MM-dd"
													className="form-control"
													maxDate={new Date()}
													disabled={isFlagEnabled}
												/>
												{manageStatus_error &&
												manageStatus_error.booking_date ? (
													<span className="error show">
														{
															manageStatus_error.booking_date
														}
													</span>
												) : (
													""
												)}
											</div>
										) : null}

										{filterData.sub_status_id === 12 ? (
											<div
												className={
													"col-sm-6 form-group"
												}
											>
												<label>
													{this.props.t(
														"MANAGE_STATUS.PURCHASE_DATE"
													)}
												</label>
												<DatePicker
													showYearDropdown
													showMonthDropdown
													selected={
														filterData &&
														filterData.purchase_date
															? new Date(
																	filterData.purchase_date
															  )
															: ""
													}
													onChange={(date) =>
														this.handleDateTimeChange(
															date,
															"purchase_date"
														)
													}
													name="purchase_date"
													id="purchase_date"
													autoComplete="off"
													placeholderText={this.props.t(
														"MANAGE_STATUS.PURCHASE_DATE"
													)}
													dateFormat="yyyy-MM-dd"
													className="form-control"
													maxDate={new Date()}
													disabled={isFlagEnabled}
												/>
												{manageStatus_error &&
												manageStatus_error.purchase_date ? (
													<span className="error show">
														{
															manageStatus_error.purchase_date
														}
													</span>
												) : (
													""
												)}
											</div>
										) : null}
									</>
								) : (
									""
								)}

								{enableBookingInfoField ? (
									<>
										{" "}
										<div className="col-sm-6 form-group">
											<InputField
												inputProps={{
													id: "booking_name",
													type: "text",
													value:
														filterData.booking_name ||
														"",
													name: "booking_name",
													autoComplete: "off",
													placeholder: this.props.t(
														"MANAGE_STATUS.BOOKING_NAME"
													),
													label: this.props.t(
														"MANAGE_STATUS.BOOKING_NAME"
													),
													disabled:
														filterData.booking_name_chk ||
														isFlagEnabled,
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"booking_name"
												)}
											/>
											<CheckBox
												label={this.props.t(
													"MANAGE_STATUS.SAME_AS_CONSUMER"
												)}
												name="booking_name_chk"
												type="checkbox"
												id="booking_name_chk"
												checked={
													filterData &&
													filterData.booking_name_chk &&
													Number(
														filterData.booking_name_chk
													) === 1
														? true
														: false
												}
												value="1"
												onChange={this.handleChange.bind(
													this,
													"booking_name_chk"
												)}
												disabled={isFlagEnabled}
											/>
										</div>
										<div className="col-sm-6 form-group">
											<InputField
												inputProps={{
													id: "booking_mobile",
													type: "text",
													value:
														filterData.booking_mobile ||
														"",
													name: "booking_mobile",
													autoComplete: "off",
													placeholder: this.props.t(
														"MANAGE_STATUS.BOOKING_MOBILE"
													),
													label: this.props.t(
														"MANAGE_STATUS.BOOKING_MOBILE"
													),
													disabled:
														filterData.booking_mobile_chk ||
														isFlagEnabled,
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"booking_mobile"
												)}
											/>
											<CheckBox
												label={this.props.t(
													"MANAGE_STATUS.SAME_AS_CONSUMER"
												)}
												name="booking_mobile_chk"
												type="checkbox"
												id="booking_mobile_chk"
												checked={
													filterData &&
													filterData.booking_mobile_chk &&
													Number(
														filterData.booking_mobile_chk
													) === 1
														? true
														: false
												}
												value="1"
												onChange={this.handleChange.bind(
													this,
													"booking_mobile_chk"
												)}
												disabled={isFlagEnabled}
											/>
										</div>
										<div className="col-sm-6 form-group">
											<label>
												{this.props.t(
													"MANAGE_STATUS.BOOKING_CITY"
												)}
											</label>
											<Select
												id="booking_city_id"
												onChange={this.handleChange.bind(
													this,
													"booking_city_id"
												)}
												options={city_list}
												name="booking_city_id"
												placeholder={this.props.t(
													"MANAGE_STATUS.BOOKING_CITY"
												)}
												value={city_list.filter(
													({ id }) =>
														id ===
														filterData.booking_city_id
												)}
												getOptionLabel={({ name }) =>
													name
												}
												getOptionValue={({ id }) => id}
												isClearable
												isDisabled={
													filterData.booking_city_chk ||
													isFlagEnabled
												}
											/>
											<CheckBox
												label={this.props.t(
													"MANAGE_STATUS.SAME_AS_CONSUMER"
												)}
												name="booking_city_chk"
												type="checkbox"
												id="booking_city_chk"
												checked={
													filterData &&
													filterData.booking_city_chk &&
													Number(
														filterData.booking_city_chk
													) === 1
														? true
														: false
												}
												value="1"
												onChange={this.handleChange.bind(
													this,
													"booking_city_chk"
												)}
												disabled={isFlagEnabled}
											/>
										</div>
										<div className="col-sm-6 form-group">
											<label>
												{this.props.t(
													"MANAGE_STATUS.BOOKING_RELATION"
												)}
											</label>
											<Select
												id="booking_relation"
												onChange={this.handleChange.bind(
													this,
													"booking_relation"
												)}
												options={relationship_list}
												name="booking_relation"
												placeholder={this.props.t(
													"MANAGE_STATUS.BOOKING_RELATION"
												)}
												value={relationship_list.filter(
													({ relation_id }) =>
														relation_id ===
														filterData.booking_relation
												)}
												getOptionLabel={({ name }) =>
													name
												}
												getOptionValue={({
													relation_id,
												}) => relation_id}
												isClearable
												isDisabled={isFlagEnabled}
											/>
										</div>{" "}
									</>
								) : (
									""
								)}

								{enableStnk ? (
									<>
										<div className="col-sm-12 form-group">
											<label>
												{this.props.t(
													`MANAGE_STATUS.${config.constants.STNK_DETAIL}`
												)}
											</label>
											<Select
												id="stnk_id"
												onChange={this.handleChange.bind(
													this,
													"stnk_id"
												)}
												options={stnk_list}
												name="stnk_id"
												placeholder={this.props.t(
													`MANAGE_STATUS.${config.constants.STNK_DETAIL}`
												)}
												value={stnk_list.filter(
													({ stnk_id }) =>
														stnk_id ===
														filterData.stnk_id
												)}
												getOptionLabel={({ name }) =>
													name
												}
												getOptionValue={({ stnk_id }) =>
													stnk_id
												}
												isClearable
												isDisabled={isFlagEnabled}
											/>
											{manageStatus_error &&
											manageStatus_error.stnk_id ? (
												<span className="error show">
													{manageStatus_error.stnk_id}
												</span>
											) : (
												""
											)}
										</div>
									</>
								) : null}

								{enableSTNKFollowupbox ? (
									<div className={"col-sm-12 form-group"}>
										<label>
											{this.props.t(
												`MANAGE_STATUS.${config.constants.STNK_FOLLOW_UP_DATE}`
											)}
										</label>
										<DatePicker
											showYearDropdown
											showMonthDropdown
											selected={
												filterData &&
												filterData.stnk_follow_up_date
													? new Date(
															filterData.stnk_follow_up_date
													  )
													: ""
											}
											onChange={(date) =>
												this.handleDateTimeChange(
													date,
													"stnk_follow_up_date"
												)
											}
											name="stnk_follow_up_date"
											id="stnk_follow_up_date"
											autoComplete="off"
											placeholderText={this.props.t(
												`MANAGE_STATUS.${config.constants.STNK_FOLLOW_UP_DATE}`
											)}
											dateFormat="yyyy-MM-dd"
											className="form-control"
											minDate={new Date()}
											disabled={isFlagEnabled}
										/>
										{manageStatus_error &&
										manageStatus_error.stnk_follow_up_date ? (
											<span className="error show">
												{
													manageStatus_error.stnk_follow_up_date
												}
											</span>
										) : (
											""
										)}
									</div>
								) : null}

								{enableSTNKTextbox ? (
									<div className="col-sm-12">
										<InputField
											inputProps={{
												id: "stnk_number",
												type: "text",
												value:
													filterData.stnk_number ||
													"",
												name: "stnk_number",
												autoComplete: "off",
												placeholder: this.props.t(
													`MANAGE_STATUS.${config.constants.STNK_NUMBER}`
												),

												label: this.props.t(
													`MANAGE_STATUS.${config.constants.STNK_NUMBER}`
												),
												validation_error:
													manageStatus_error &&
													manageStatus_error.stnk_number
														? manageStatus_error.stnk_number
														: "",
												disabled: isFlagEnabled,
											}}
											autoComplete="off"
											onChange={this.handleChange.bind(
												this,
												"stnk_number"
											)}
										/>
									</div>
								) : (
									""
								)}

								{enableReasonsFields ? (
									<>
										<div className="col-sm-12 form-group">
											<label>
												{this.props.t(
													"MANAGE_STATUS.REASONS"
												)}
											</label>
											<Select
												id="reason_id"
												onChange={this.handleChange.bind(
													this,
													"reason_id"
												)}
												options={reasons_list}
												name="reason_id"
												placeholder={this.props.t(
													"MANAGE_STATUS.REASONS"
												)}
												value={reasons_list.filter(
													({ reason_id }) =>
														reason_id ===
														filterData.reason_id
												)}
												getOptionLabel={({ name }) =>
													name
												}
												getOptionValue={({
													reason_id,
												}) => reason_id}
												isClearable
												isDisabled={isFlagEnabled}
											/>
											{manageStatus_error &&
											manageStatus_error.reason_id ? (
												<span className="error show">
													{
														manageStatus_error.reason_id
													}
												</span>
											) : (
												""
											)}
										</div>
									</>
								) : null}

								{enableReasonTextbox ? (
									<div className="col-sm-12">
										<InputField
											inputProps={{
												id: "other_reason_text",
												type: "text",
												value:
													filterData.other_reason_text ||
													"",
												name: "other_reason_text",
												autoComplete: "off",
												placeholder: this.props.t(
													"MANAGE_STATUS.REASONS_TEXT"
												),

												label: this.props.t(
													"MANAGE_STATUS.REASONS_TEXT"
												),
												validation_error:
													manageStatus_error &&
													manageStatus_error.other_reason_text
														? manageStatus_error.other_reason_text
														: "",
												disabled: isFlagEnabled,
											}}
											autoComplete="off"
											onChange={this.handleChange.bind(
												this,
												"other_reason_text"
											)}
										/>
									</div>
								) : (
									""
								)}

								{enableDocumentUploadField ? (
									<div className="col-sm-12 form-group">
										<div className="col-sm-12">
											<label>
												<strong>
													{this.props.t(
														"MANAGE_STATUS.SELECT_FILE_TO_UPLOAD"
													)}
												</strong>
											</label>
										</div>

										<div className="col-sm-6">
											<input
												type="file"
												id="file"
												name="myfile"
												onChange={this.onChangeHandler}
												ref={(ref) =>
													(this.fileInput = ref)
												}
												disabled={isFlagEnabled}
											/>
											<span></span>
											<div id="fileErr"></div>
										</div>
									</div>
								) : (
									""
								)}
								{enableDocumentUploadField ? (
									<div className="col-sm-12 form-group">
										{uploaded_document &&
											uploaded_document.length !== 0 &&
											uploaded_document.map((data, k) => (
												<div
													key={k}
													className="col-sm-6 form-group"
												>
													{data.file_name && data.base_url ? (
														<span className="mrg-L40">
															<a
																href={
																	data?.doc_aws_path || data.base_url+data.file_name
																}
																target="_blank"
																download={data?.doc_aws_path || data.base_url+data.file_name}
															>
																{data.file_name
																	? data.file_name.split(
																			"/"
																	  )[2]
																	: ""}
															</a>
														</span>
													) : (
														""
													)}
												</div>
											))}
									</div>
								) : (
									""
								)}

								{filterData.status_id === 2 ? (
									<div className={"col-sm-12 form-group"}>
										<label>
											{this.props.t(
												"MANAGE_STATUS.BUY_TIME"
											)}
											<span className="text-danger">
												*
											</span>
										</label>
										<DatePicker
											showYearDropdown
											showMonthDropdown
											selected={
												filterData &&
												filterData.buy_time
													? new Date(
															filterData.buy_time
													  )
													: ""
											}
											onChange={(date) =>
												this.handleDateTimeChange(
													date,
													"buy_time"
												)
											}
											name="buy_time"
											id="buy_time"
											autoComplete="off"
											placeholderText={this.props.t(
												"MANAGE_STATUS.BUY_TIME"
											)}
											dateFormat={
												config.constants
													.DATE_FORMAT_INPUT
											}
											className="form-control"
											maxDate={this.getMaxMinDate("MAX")}
											minDate={this.getMaxMinDate("MIN")}
											disabled={isFlagEnabled}
										/>
										{manageStatus_error &&
										manageStatus_error.buy_time ? (
											<span className="error show">
												{manageStatus_error.buy_time}
											</span>
										) : (
											""
										)}
									</div>
								) : null}

								<div className="col-sm-12 form-group">
									<div className="flex-item">
										<ul
											className="tagacdtionbtn nav-tabs mrg-b0"
											role="tablist"
										>
											{tabs.map((e, index) => (
												<li
													key={index}
													onClick={
														this.handleTabChange
													}
													className={
														"nav-item " +
														(Number(showTab) ===
														Number(e.id)
															? "active"
															: "")
													}
													data-key={e.id}
												>
													<a
														href="#firstsubmit"
														id="first-submit"
														role="tab"
													>
														{e.title}
													</a>
												</li>
											))}
										</ul>
									</div>
									{Number(showTab) === 2 ? (
										<div>
											{commentBoxes.map((val, index) => (
												<DealerComments
													key={index}
													manageCommentBoxes={
														this.manageCommentBoxes
													}
													dealersData={dealersData}
													usedDealers={
														usedDealersData
													}
													index={index}
													removeCommentBoxes={
														this.removeCommentBoxes
													}
													filterDataKeys={
														Object.keys(filterData)
															.length
													}
													commentBoxes={
														commentBoxes.length
													}
													manageStatus_error={
														manageStatus_error
													}
													updateStatus={
														this.state.updateStatus
													}
													activeTab={
														Number(showTab) === 1
															? "internal"
															: "external"
													}
													commentData={
														this.state.commentData
													}
													isLmsFlagEnabled={
														isFlagEnabled
													}
												/>
											))}
											{Number(showTab) === 2 &&
											!isFlagEnabled ? (
												<div className="text-right">
													{addCommentButton ? (
														<span
															className="link-text f12"
															onClick={
																this
																	.addCommentBoxes
															}
														>
															{this.props.t(
																"MANAGE_STATUS.ADD_COMMENT"
															)}
														</span>
													) : (
														this.props.t(
															"MANAGE_STATUS.ADD_COMMENT"
														)
													)}
												</div>
											) : (
												""
											)}
										</div>
									) : (
										<div className="commentArea">
											<textarea
												name="internal_comment"
												placeholder={this.props.t(
													"MANAGE_STATUS.COMMENTS_HERE"
												)}
												onChange={this.changeCommentBox.bind(
													this,
													"internal_comment"
												)}
												value={
													this.state.internalComment
												}
												disabled={isFlagEnabled}
											></textarea>
										</div>
									)}

									<button
										type="submit"
										className="btn btn-primary"
										disabled={isSaveButtonDisabled}
									>
										{this.props.t("MANAGE_STATUS.UPDATE")}
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			updateHistoryFlag: bindActionCreators(
				LeadActions.updateHistoryFlag,
				dispatch
			),
		},
	};
};
export default withTranslation("lead_detail")(
	connect(null, mapDispatchToProps)(ManageStatusCard)
);
