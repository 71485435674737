import React, { Component } from "react";
import Select from "react-select";
import InputField from "../../../elements/InputField";
import { LeadService } from "../../../../service/leadService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";

class SearchCampaign extends Component {
	constructor(props) {
		super(props);
		this.state = {
			process_list: this.props.process_list || [],
			type: this.props.type || [],
			server: this.props.server || [],
			filterData: this.props.filterData || {},
		};
	}
	componentDidMount = async () => {
		await this.getConfigData();
	};
	static getDerivedStateFromProps(props, state) {
		return {
			filterData: props.filterData,
		};
	}

	submitFilterForm = (event) => {
		event.preventDefault();
		if (typeof this.props.onSubmitFilter === "function") {
			let { filterData } = this.state;
			this.props.onSubmitFilter(filterData);
		}
	};

	ResetFilterForm = (event) => {
		event.preventDefault();
		let filterData = { page: 1 };
		this.props.onSubmitFilter(filterData, {});
	};

	// get config
	getConfigData = async () => {
		const requestBody = {
			config_data: ["process_list", "type_list", "server_list"],
		};
		LeadService.getConfig(requestBody)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					this.setState({
						process_list: response.data.data.process_list || [],
						type: response.data.data.type_list || [],
						server: response.data.data.server_list || [],
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	handleChange = (sname, valueObj) => {
		let { filterData } = this.state;
		let value = valueObj.value || valueObj.process_id;
		if (sname === "name") value = valueObj.target.value;
		filterData[sname] = value;
		this.setState({ filterData: filterData });
	};

	render() {
		const { process_list, filterData, type } = this.state;

		return (
			<div className="search-wrap">
				<form onSubmit={this.submitFilterForm}>
					<ul className="search-flex">
						<li className="searchitems">
							<InputField
								inputProps={{
									id: "name",
									type: "text",
									value: filterData.name || "",
									name: "name",
									autoComplete: "off",
									placeholder: this.props.t("CAMPAIGN.NAME"),
									label: this.props.t("CAMPAIGN.NAME"),
								}}
								autoComplete="off"
								onChange={this.handleChange.bind(this, "name")}
							/>
						</li>

						<li className="searchitems selectWidth form-group">
							<Select
								id="process_id"
								onChange={this.handleChange.bind(
									this,
									"process_id"
								)}
								options={process_list}
								name="process_id"
								placeholder={this.props.t("CAMPAIGN.PROCESS")}
								value={process_list.filter(
									({ process_id }) =>
										process_id === filterData.process_id
								)}
								getOptionLabel={({ name }) => name}
								getOptionValue={({ process_id }) => process_id}
							/>
						</li>

						<li className="searchitems selectWidth form-group">
							<Select
								id="type"
								onChange={this.handleChange.bind(this, "type")}
								options={type}
								name="type"
								placeholder={this.props.t("CAMPAIGN.TYPE")}
								value={type.filter(
									({ value }) => value === filterData.type
								)}
								getOptionLabel={({ label }) => label}
								getOptionValue={({ value }) => value}
							/>
						</li>
						{/* <li className="searchitems selectWidth form-group">
							<Select
								id="server"
								onChange={this.handleChange.bind(
									this,
									"server"
								)}
								options={server}
								name="server"
								placeholder={this.props.t("CAMPAIGN.SERVER")}
								value={server.filter(
									({ value }) => value === filterData.server
								)}
								getOptionLabel={({ label }) => label}
								getOptionValue={({ value }) => value}
							/>
						</li> */}

						<li className="searchitems form-group">
							<div>
								<button
									type="submit"
									className="btn btn-primary"
								>
									{this.props.t("CAMPAIGN.SEARCH")}
								</button>
							</div>
						</li>

						<li className="searchitems form-group">
							<button
								type="reset"
								className="btn btn-default btn-reset"
								onClick={this.ResetFilterForm}
							>
								{this.props.t("CAMPAIGN.RESET")}
							</button>
						</li>
					</ul>
				</form>
			</div>
		);
	}
}

export default withTranslation("webfrom")(SearchCampaign);
