import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
//Custom component

import Header from "./../view/common/Header";
//import Footer from "./../view/common/Footer";
import Login from "../view/auth/login/component/Login";
import ForgotPassword from "../view/auth/forgot_password/component/ForgotPassword";
import RefreshToken from "../view/auth/RefreshToken";
import ResetPassword from "../view/auth/ResetPassword";
import Logout from "../view/auth/Logout";
import LeadFinder from "../view/lead/component/LeadFinder";
import LeadsDetails from "../view/lead/component/Leads-Details";
import BeforeLogin from "./beforeLogin";
import PrivateRoute from "./privateRoute";

import EditWebRules from "../view/webform/component/webrule/rule/EditWebRules";
import ListWebRules from "../view/webform/component/webrule/rule/ListWebRules";
import EditList from "../view/webform/component/webrule/lists/EditList";
import ProcessCampaignList from "../view/webform/component/webrule/lists/ProcessCampaignList";
import CampaignList from "../view/webform/component/campaign/CampaignList";
import EditCampaign from "../view/webform/component/campaign/EditCampaign";
import ProcessList from "../view/webform/component/process/ProcessList";
import EditProcess from "../view/webform/component/process/EditProcess";
import DomainList from "../view/webform/component/domain/DomainList";
import EditDomain from "../view/webform/component/domain/EditDomain";
import WebidsList from "../view/webform/component/webids/WebidsList";
import Editwebids from "../view/webform/component/webids/Editwebids";
import Dashboard from "../view/dashboard/dashboard";
import * as constant from "../config/constant";
import BulkUpload from "../view/webform/component/bulkupload/bulkUpload";
import BlackList from "../view/webform/component/blacklist/BlackList";
// import EditBlackList from "../view/webform/component/blacklist/EditBlackList";
import LeadTypeList from "../view/webform/component/leadType/LeadTypeList";
import NotFound from "../view/notfound/NotFound";
import RolePrivilegeList from "../view/role-privilege/component/role-privilege/RolePrivilegeList";
import EditRolePrivilege from "../view/role-privilege/component/role-privilege/EditRolePrivilege";

import UploadCsv from "../view/lead/component/UploadCsv";
import DialerReport from "../view/report/component/DialerReport";
import UpdateStatusViaCsv from "../view/lead/component/UpdateStatusViaCsv";
import FordList from "../view/report/component/ford/FordList";
import EditFord from "../view/report/component/ford/EditFord";
import OpsReport from "../view/report/component/OpsReport";

import CrossSellReport from "../view/report/component/CrossSellReport";
import secureStorage from "./../config/encrypt";

import PackCampaignList from "../view/campaign/CampaignList";
import EditPackCampaign from "../view/campaign/AddEditCampaign";
import AssignCarReport from "../view/report/component/assignCarReport/AssignCarReport";

import PerformanceReport from "../view/report/component/performance/PerformanceReport";
import AgentPerformanceReport from "../view/report/component/agentPerformance/AgentPerformanceReport";
import SalesReport from "../view/report/component/sales/SalesReport";
import SaveUser from "../view/user/component/saveUser";

import SmList from "../view/report/component/ford/SmList";
import EditSm from "../view/report/component/ford/EditSm";

import DealerList from "../view/report/component/ford/DealerList";
import EditDealer from "../view/report/component/ford/EditDealer";

import MyActiveReport from "../view/report/component/myactive/MyActiveReport";
import UpdateSpkDateViaCsv from "../view/lead/component/UpdateSpkDateViaCsv";
import UpdateFordLeadCsv from "../view/report/component/ford/UpdateFordLeadCsv";

import RetailReport from "../view/report/component/retailDashboard/RetailReport";
import RetailCreateReport from "../view/report/component/retailDashboard/RetailCreateReport";
import RetailUpdateReport from "../view/report/component/retailDashboard/RetailUpdateReport";
import RetailProcessWise from "../view/report/component/retailDashboard/RetailProcessWise";
import { connect } from "react-redux";
import AuditList from "../view/audit/component/AuditList";
import SaveAudit from "../view/audit/component/SaveAudit";
import BrandTieUpList from "../view/audit/component/BrandTieUpList";
import EditTieupBrand from "../view/audit/component/EditTieupBrand";
import UserList from "../view/user/component/UserList";

class CustomRouter extends Component {
	constructor(props) {
		super(props);
		this.state = { ht: 500, logged_in: false };
	}

	static getDerivedStateFromProps = (nextProps, prevState) => {
		let updatedStateData = {};
		if (nextProps.logged_in != prevState.logged_in) {
			updatedStateData["logged_in"] = nextProps.logged_in;
		}
		return updatedStateData;
	};

	componentDidMount() {
		this.setState({
			ht: window.innerHeight - 127,
		});
		window.addEventListener(
			"resize",
			() => {
				this.setState({
					ht: window.innerHeight - 127,
				});
			},
			false
		);
	}

	render() {
		const loggingIn = this.props.logged_in;
		let site_settings = secureStorage.getItem("site_settings") || {};
		let menu_denied =
			site_settings && site_settings.menu_denied
				? site_settings.menu_denied
				: [];
		//console.log("menu_denied",menu_denied)
		return (
			<Router>
				{loggingIn === true ? <Header /> :"" }
				<div style={{ minHeight: this.state.ht }}>
					<Switch>
					<PrivateRoute
						path="/"
						exact
						component={
							menu_denied &&
							!menu_denied.includes("/lead-list") &&
							constant.isUserHasRightsToAccessPage(
								"lead",
								"lead",
								"/lead-list"
							)
								? LeadFinder
								: Dashboard
						}
					/>
					<PrivateRoute
						path="/dashboard"
						exact
						component={
							menu_denied &&
							!menu_denied.includes("/lead-list") &&
							constant.isUserHasRightsToAccessPage(
								"lead",
								"lead",
								"/lead-list"
							)
								? LeadFinder
								: Dashboard
						}
					/>
					<PrivateRoute
						path="/lead-list"
						exact
						component={
							menu_denied && !menu_denied.includes("/lead-list")
								? LeadFinder
								: NotFound
						}
					/>
					<PrivateRoute
						path="/Leads-Details"
						exact
						component={
							menu_denied &&
							!menu_denied.includes("/Leads-Details") &&
							constant.isUserHasRightsToAccessPage(
								"lead",
								"lead",
								"/Leads-Details"
							)
								? LeadsDetails
								: NotFound
						}
					/>
					<BeforeLogin
						path="/Login"
						component={
							menu_denied && !menu_denied.includes("/Login")
								? Login
								: NotFound
						}
					/>
					<BeforeLogin
						path="/forgot-password"
						exact
						component={
							menu_denied &&
							!menu_denied.includes("/forgot-password")
								? ForgotPassword
								: NotFound
						}
					/>
					<BeforeLogin
						path="/reset-password/:token"
						exact
						component={
							menu_denied &&
							!menu_denied.includes("/reset-password")
								? ResetPassword
								: NotFound
						}
					/>
					<PrivateRoute
						path="/refresh-token"
						exact
						component={
							menu_denied &&
							!menu_denied.includes("/refresh-token")
								? RefreshToken
								: NotFound
						}
					/>
					<PrivateRoute
						path="/edit-web-rule"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/edit-web-rule")
								? EditWebRules
								: NotFound
						}
					/>
					<PrivateRoute
						path="/list-web-rule"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/list-web-rule")
								? ListWebRules
								: NotFound
						}
					/>
					<PrivateRoute
						path="/edit-process-campaign-list"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/edit-process-campaign-list")
								? EditList
								: NotFound
						}
					/>
					<PrivateRoute
						path="/process-campaign-list"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/process-campaign-list")
								? ProcessCampaignList
								: NotFound
						}
					/>
					<PrivateRoute
						path="/campaigns"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/campaigns") &&
							constant.isUserHasRightsToAccessPage(
								"webform",
								"campaign",
								"/campaigns"
							)
								? CampaignList
								: NotFound
						}
					/>
					<PrivateRoute
						path="/edit_campaign/:id"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/edit_campaign") &&
							constant.isUserHasRightsToAccessPage(
								"webform",
								"campaign",
								"/edit_campaign"
							)
								? EditCampaign
								: NotFound
						}
					/>
					<PrivateRoute
						path="/add_campaign"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/add_campaign") &&
							constant.isUserHasRightsToAccessPage(
								"webform",
								"campaign",
								"/add_campaign"
							)
								? EditCampaign
								: NotFound
						}
					/>
					<PrivateRoute
						path="/process"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/process") &&
							constant.isUserHasRightsToAccessPage(
								"webform",
								"process",
								"/process"
							)
								? ProcessList
								: NotFound
						}
					/>
					<PrivateRoute
						path="/edit_process/:id"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/edit_process") &&
							constant.isUserHasRightsToAccessPage(
								"webform",
								"process",
								"/edit_process"
							)
								? EditProcess
								: NotFound
						}
					/>
					<PrivateRoute
						path="/add_process"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/add_process") &&
							constant.isUserHasRightsToAccessPage(
								"webform",
								"process",
								"/add_process"
							)
								? EditProcess
								: NotFound
						}
					/>
					<PrivateRoute
						path="/domains"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/domains") &&
							constant.isUserHasRightsToAccessPage(
								"webform",
								"domain",
								"/domains"
							)
								? DomainList
								: NotFound
						}
					/>
					<PrivateRoute
						path="/edit_domain/:id"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/edit_domain") &&
							constant.isUserHasRightsToAccessPage(
								"webform",
								"domain",
								"/edit_domain"
							)
								? EditDomain
								: NotFound
						}
					/>
					<PrivateRoute
						path="/add_domain"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/add_domain") &&
							constant.isUserHasRightsToAccessPage(
								"webform",
								"domain",
								"/add_domain"
							)
								? EditDomain
								: NotFound
						}
					/>
					<PrivateRoute
						path="/webids"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/webids") &&
							constant.isUserHasRightsToAccessPage(
								"webform",
								"webids",
								"/webids"
							)
								? WebidsList
								: NotFound
						}
					/>
					<PrivateRoute
						path="/edit_webids/:id"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/edit_webids") &&
							constant.isUserHasRightsToAccessPage(
								"webform",
								"webids",
								"/edit_webids"
							)
								? Editwebids
								: NotFound
						}
					/>
					<PrivateRoute
						path="/add_webids"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/add_webids") &&
							constant.isUserHasRightsToAccessPage(
								"webform",
								"webids",
								"/add_webids"
							)
								? Editwebids
								: NotFound
						}
					/>
					<PrivateRoute
						path="/lead_upload"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/lead_upload") &&
							constant.isUserHasRightsToAccessPage(
								"webform",
								"bulkUpload",
								"/lead_upload"
							)
								? BulkUpload
								: NotFound
						}
					/>
					<PrivateRoute
						path="/black-list"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/black-list") &&
							constant.isUserHasRightsToAccessPage(
								"webform",
								"blackList",
								"/black-list"
							)
								? BlackList
								: NotFound
						}
					/>
					<PrivateRoute
						path="/lead-type"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/lead-type") &&
							constant.isUserHasRightsToAccessPage(
								"webform",
								"leadType",
								"/lead-type"
							)
								? LeadTypeList
								: NotFound
						}
					/>
					<PrivateRoute
						path="/role-list"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/role-list") &&
							constant.isUserHasRightsToAccessPage(
								"admin",
								"rolePrivilege",
								"/role-list"
							)
								? RolePrivilegeList
								: NotFound
						}
					/>
					<PrivateRoute
						path="/user-list"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/user-list") &&
							constant.isUserHasRightsToAccessPage(
								"admin",
								"rolePrivilege",
								"/user-list"
							)
								? UserList
								: NotFound
						}
					/>
					{/* <PrivateRoute
						path="/edit-role-privilege/:id"
						exact={true}
						component={EditRolePrivilege}
					/> */}
					<PrivateRoute
						path="/update_lead"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/update_lead") &&
							constant.isUserHasRightsToAccessPage(
								"report",
								"uploadlead",
								"/update_lead"
							)
								? UploadCsv
								: NotFound
						}
					/>
					<PrivateRoute
						path="/dialer_report"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/dialer_report") &&
							constant.isUserHasRightsToAccessPage(
								"report",
								"dialerReport",
								"/dialer_report"
							)
								? DialerReport
								: NotFound
						}
					/>
					<PrivateRoute
						path="/update_lead_status"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/update_lead_status") &&
							constant.isUserHasRightsToAccessPage(
								"report",
								"updateStatus",
								"/update_lead_status"
							)
								? UpdateStatusViaCsv
								: NotFound
						}
					/>
					<PrivateRoute
						path="/campaign_configurator"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/campaign_configurator") &&
							constant.isUserHasRightsToAccessPage(
								"report",
								"campaignPack",
								"/campaign_configurator"
							)
								? PackCampaignList
								: NotFound
						}
					/>
					<PrivateRoute
						path="/add_pack_campaign"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/add_pack_campaign") &&
							constant.isUserHasRightsToAccessPage(
								"report",
								"campaignPack",
								"/add_pack_campaign"
							)
								? EditPackCampaign
								: NotFound
						}
					/>
					<PrivateRoute
						path="/edit_pack_campaign/:id"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/edit_pack_campaign") &&
							constant.isUserHasRightsToAccessPage(
								"report",
								"campaignPack",
								"/edit_pack_campaign"
							)
								? EditPackCampaign
								: NotFound
						}
					/>
					<PrivateRoute
						path="/ops_report"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/ops_report") &&
							constant.isUserHasRightsToAccessPage(
								"report",
								"opsReport",
								"/ops_report"
							)
								? OpsReport
								: NotFound
						}
					/>
					<PrivateRoute
						path="/cross_sell"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/cross_sell") &&
							constant.isUserHasRightsToAccessPage(
								"report",
								"crossSellReport",
								"/cross_sell"
							)
								? CrossSellReport
								: NotFound
						}
					/>
					<PrivateRoute
						path="/city_dealer_mapping"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/city_dealer_mapping") &&
							constant.isUserHasRightsToAccessPage(
								"ford",
								"ford",
								"/city_dealer_mapping"
							)
								? FordList
								: NotFound
						}
					/>
					<PrivateRoute
						path="/edit_mapping/:id"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/edit_mapping") &&
							constant.isUserHasRightsToAccessPage(
								"ford",
								"ford",
								"/edit_mapping"
							)
								? EditFord
								: NotFound
						}
					/>
					<PrivateRoute
						path="/add_mapping"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/add_mapping") &&
							constant.isUserHasRightsToAccessPage(
								"ford",
								"ford",
								"/add_mapping"
							)
								? EditFord
								: NotFound
						}
					/>
					<PrivateRoute
						path="/assigned_lead_summary"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/assigned_lead_summary") &&
							constant.isUserHasRightsToAccessPage(
								"report",
								"MyActiveReport",
								"/assigned_lead_summary"
							)
								? MyActiveReport
								: NotFound
						}
					/>
					<PrivateRoute
						path="/not-found"
						exact
						component={NotFound}
					/>
					<PrivateRoute
						path="/lead_report"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/lead_report") &&
							constant.isUserHasRightsToAccessPage(
								"report",
								"assignCarReport",
								"/lead_report"
							)
								? AssignCarReport
								: NotFound
						}
					/>
					<PrivateRoute
						path="/performance_report"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/performance_report") &&
							constant.isUserHasRightsToAccessPage(
								"report",
								"performanceReport",
								"/performance_report"
							)
								? PerformanceReport
								: NotFound
						}
					/>
					<PrivateRoute
						path="/sales_report"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/sales_report") &&
							constant.isUserHasRightsToAccessPage(
								"report",
								"salesReport",
								"/sales_report"
							)
								? SalesReport
								: NotFound
						}
					/>
					<PrivateRoute path="/logout" exact component={Logout} />
					<PrivateRoute
						path="/create_user"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/create_user") &&
							constant.isUserHasRightsToAccessPage(
								"admin",
								"rolePrivilege",
								"/create_user"
							)
								? SaveUser
								: NotFound
						}
					/>
					<PrivateRoute
						path="/edit_user/:id"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/edit_user") &&
							constant.isUserHasRightsToAccessPage(
								"admin",
								"rolePrivilege",
								"/edit_user"
							)
								? SaveUser
								: NotFound
						}
					/>
					<PrivateRoute
						path="/update_spk_date"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/update_spk_date") &&
							constant.isUserHasRightsToAccessPage(
								"report",
								"updateSpkdate",
								"/update_spk_date"
							)
								? UpdateSpkDateViaCsv
								: NotFound
						}
					/>
					<PrivateRoute
						path="/sm_list"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/sm_list") &&
							constant.isUserHasRightsToAccessPage(
								"ford",
								"SmList",
								"/sm_list"
							)
								? SmList
								: NotFound
						}
					/>
					<PrivateRoute
						path="/edit_sm/:id"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/edit_sm") &&
							constant.isUserHasRightsToAccessPage(
								"ford",
								"ford",
								"/edit_sm"
							)
								? EditSm
								: NotFound
						}
					/>
					<PrivateRoute
						path="/add_sm"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/add_sm") &&
							constant.isUserHasRightsToAccessPage(
								"ford",
								"ford",
								"/add_sm"
							)
								? EditSm
								: NotFound
						}
					/>
					<PrivateRoute
						path="/dealer_list"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/dealer_list") &&
							constant.isUserHasRightsToAccessPage(
								"ford",
								"DealerList",
								"/dealer_list"
							)
								? DealerList
								: NotFound
						}
					/>
					<PrivateRoute
						path="/edit_dealer/:id"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/edit_dealer") &&
							constant.isUserHasRightsToAccessPage(
								"ford",
								"ford",
								"/edit_dealer"
							)
								? EditDealer
								: NotFound
						}
					/>
					<PrivateRoute
						path="/add_dealer"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/add_dealer") &&
							constant.isUserHasRightsToAccessPage(
								"ford",
								"ford",
								"/add_dealer"
							)
								? EditDealer
								: NotFound
						}
					/>

					<PrivateRoute
						path="/agent_performance_report"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes(
								"/agent_performance_report"
							) &&
							constant.isUserHasRightsToAccessPage(
								"report",
								"AgentPerformanceReport",
								"/agent_performance_report"
							)
								? AgentPerformanceReport
								: NotFound
						}
					/>
					<PrivateRoute
						path="/retail_report"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/retail_report") &&
							constant.isUserHasRightsToAccessPage(
								"report",
								"retailReport",
								"/retail_report"
							)
								? RetailReport
								: NotFound
						}
					/>
					<PrivateRoute
						path="/retail_create_report"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/retail_create_report") &&
							constant.isUserHasRightsToAccessPage(
								"report",
								"retailCreateReport",
								"/retail_create_report"
							)
								? RetailCreateReport
								: NotFound
						}
					/>
					<PrivateRoute
						path="/retail_update_report"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/retail_update_report") &&
							constant.isUserHasRightsToAccessPage(
								"report",
								"retailUpdateReport",
								"/retail_update_report"
							)
								? RetailUpdateReport
								: NotFound
						}
					/>
					<PrivateRoute
						path="/retail_process_summary"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/retail_process_summary") &&
							constant.isUserHasRightsToAccessPage(
								"report",
								"retailProcessWiseSummary",
								"/retail_process_summary"
							)
								? RetailProcessWise
								: NotFound
						}
					/>

					<PrivateRoute
						path="/update_ford_lead_csv"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/update_ford_lead_csv") &&
							constant.isUserHasRightsToAccessPage(
								"ford",
								"UpdateFordLead",
								"/update_ford_lead_csv"
							)
								? UpdateFordLeadCsv
								: NotFound
						}
					/>
					<PrivateRoute
						path="/audit_list"
						exact={true}
						component={
							menu_denied &&
							menu_denied &&
								!menu_denied.includes("/audit_list") &&
								constant.isUserHasRightsToAccessPage(
									"audit",
									"audit",
									"/audit_list"
								) 
							
								? AuditList
								: NotFound
						}
					/>

					
					<PrivateRoute
						path="/tie-up-brand"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/tie-up-brand") &&
							constant.isUserHasRightsToAccessPage(
								"audit",
								"tieupBrand",
								"/tie-up-brand"
							)
							? BrandTieUpList
							: NotFound
						}
					/>

					<PrivateRoute
						path="/add_tie_up_brand"
						exact={true}
						component={
							menu_denied &&
							!menu_denied.includes("/add_tie_up_brand") &&
							constant.isUserHasRightsToAccessPage(
								"audit",
								"tieupBrand",
								"/add_tie_up_brand"
							)
							? EditTieupBrand
							: NotFound
						}
					/>

					<Route component={NotFound}/>
					</Switch>
				</div>
				{/* <Footer /> */}
			</Router>
		);
	}
}
const mapStateToProps = ({ auth }) => {
	return {
		logged_in: auth.loggedIn,
	};
};
export default connect(mapStateToProps, null)(CustomRouter);
