import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ConfigAction, LeadActions } from "../../../../store/action";
import RetailProcessWiseSearch from "./RetailProcessWiseSearch";
import { ReportService } from "../../../../service/reportService";
import RetailProcessWiseData from "./RetailProcessWiseData";

class RetailProcessWise extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			reportData: {},
			isSearchButtonDisabled: false,
			isSearchClicked: false,
			filterData: {},
		};
	}
	onSubmitFilter = (filterData) => {
		this.setState({ loading: true, isSearchButtonDisabled: true });
		filterData["report_type"] = "retail_process_summary";
		filterData["selected_month"] = new Date();
		ReportService.getRetailReportProcessSummary(filterData)
			.then((result) => {
				const {
					data: { data: response = {} },
				} = result;
				this.setState(
					{
						filterData: filterData,
						reportData: response,
						isSearchClicked: true,
						loading: false,
						isSearchButtonDisabled: false,
					},
					async () => {
						if (Object.keys(response).length) {
							await this.props.actions.SaveDownloadLog({
								module: "RetailReport",
								action: "2",
							});
						}
					}
				);
			})
			.catch((err) => {});
	};
	render() {
		const {
			isSearchButtonDisabled,
			reportData,
			isSearchClicked,
			filterData,
			loading,
		} = this.state;
		return (
			<>
				<RetailProcessWiseSearch
					onSubmitFilter={this.onSubmitFilter}
					isSearchButtonDisabled={isSearchButtonDisabled}
				/>

				<RetailProcessWiseData
					reportData={reportData}
					filterData={filterData}
					loading={loading}
					isSearchClicked={isSearchClicked}
				/>
			</>
		);
	}
}

const mapDispatchToProps = function (dispatch) {
	return {
		actions: {
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
			SaveDownloadLog: bindActionCreators(
				LeadActions.SaveDownloadLog,
				dispatch
			),
		},
	};
};
export default withTranslation("report")(
	connect(null, mapDispatchToProps)(RetailProcessWise)
);
