/* eslint-disable */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import SearchDialer from "./SearchDialer";
import { ReportService } from "../../../service/reportService";
import { ConfigAction, UserActions, LeadActions } from "../../../store/action";
import { CSVLink, CSVDownload } from "react-csv";
import dateformat from "dateformat";
import config from "../../../config/config";
import PageLoader from "../../elements/PageLoader";
import { toast } from "react-toastify";
import moment from "moment";
class DialerReport extends Component {
	csvLink = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			campaign_name: "",
			campaign_list: [],
			campaign_data: [],
			subHeaders: [],
			csvJsonData: [],
			call_data: [],
			filterData: {},
			list_id: -1,
			make_map: {},
			model_map: {},
			city_map: {},
			csvDataIndividual: [],
			last_updated: null,
			loading: false,
			isSearchClicked: false,
			selected_date: "",
		};
	}
	getConfigData = async () => {
		const requestBody = {
			config_data: ["gm_campaign_list"],
		};
		this.props.actions.getConfigData(requestBody);
	};
	componentDidMount = () => {
		this.getConfigData();
	};
	downloadCsvForDetail = (data) => {
		const { make_map, model_map, city_map } = this.state;
		if (data instanceof Array) {
			for (let i of data) {
				for (let j in i) {
					if (j === "Make") {
						i[j] = make_map[i[j]] ? make_map[i[j]] : "";
					} else if (j === "Model") {
						i[j] = model_map[i[j]] ? model_map[i[j]] : "";
					} else if (j === "City") {
						i[j] = city_map[i[j]] ? city_map[i[j]] : "";
					} else if (j === "City") {
						i[j] = city_map[i[j]] ? city_map[i[j]] : "";
					} else if (j === "CrossSell-City") {
						i[j] = city_map[i[j]] ? city_map[i[j]] : "";
					}
				}
			}
			this.setState({ csvDataIndividual: data }, async () => {
				if (data instanceof Array && data.length) {
					this.csvLink.current.link.click();
					await this.props.actions.SaveDownloadLog({
						module: "DialerReport",
						action: "1",
					});
				}
			});
		}
	};
	getParticularRecordDetail = (list_id, slug, property, val) => {
		if (val && val > 0) {
			const {
				filterData: { selected_month = "" },
			} = this.state;

			const postData = {
				list_id: list_id,
				all_dates: property.split(" ")[0] === "MTD" ? true : false,
				slug: slug,
				// selected_month : selected_month
			};
			let tempI = property.split(" ");
			let lastMonthDate = moment(this.state.selected_date).subtract(
				1,
				"months"
			);
			let lastMnth = dateformat(lastMonthDate, "mmm");
			let secLastMonthDate = moment(this.state.selected_date).subtract(
				2,
				"months"
			);
			let secLastMnth = dateformat(secLastMonthDate, "mmm");
			if (tempI.length && tempI[0] === "MTD" && tempI[1]) {
				if (tempI[1] === lastMnth)
					postData["selected_month"] = lastMonthDate;
				if (tempI[1] === secLastMnth)
					postData["selected_month"] = secLastMonthDate;
			} else {
				if (selected_month) {
					postData["selected_month"] = selected_month;
				}
			}
			const date = Date.parse(property)
				? dateformat(property, config.constants.DATE_FORMAT_YMD)
				: "";
			if (date) postData["date"] = date;
			postData["report_type"] = "dialer_report";
			ReportService.getReportDetail(postData)
				.then((result) => {
					const {
						data: { data },
					} = result;
					this.downloadCsvForDetail(data);
				})
				.catch((err) => {});
		}
	};
	submitRequest = (filterData) => {
		this.setState({
			loading: true,
			isSearchClicked: true,
			selected_date: filterData.selected_month,
		});
		let {
			campaign_name,
			campaign_list,
			campaign_data,
			csvJsonData,
		} = this.state;
		ReportService.getDialerReport(filterData)
			.then((result) => {
				const {
					data: { data: response },
				} = result;
				const { data, last_updated } = response;
				const { campaign_id } = filterData;
				const campaignData = campaign_list.find(
					(e) => e.value === campaign_id
				);
				if (campaignData) {
					campaign_name = campaignData.label;
				}
				csvJsonData = [];
				campaign_data = [];
				let subHeader = [],
					list_id = -1,
					isEncountered = false,
					isEncounteredCrossSell = "";
				for (let i = 0; i < data.length; i++) {
					let rowData = [],
						tempData = [];
					for (let j in data[i]) {
						if (j === "List_id") {
							list_id = data[i][j];
						}
						if (j != "slug") {
							if (i === 0) subHeader.push(j);
							rowData.push(data[i][j]);
						} else {
							if (
								data[i][j] &&
								data[i][j].includes("call_") &&
								!isEncountered
							) {
								isEncountered = true;
								tempData.push(
									this.props.t("DIALER.CONNECTED_CALLS")
								);
								for (
									let c = 0;
									c < Object.keys(data[i]).length - 2;
									c++
								) {
									tempData.push("");
								}
								csvJsonData.push(tempData);
							}
							if (
								data[i][j] &&
								data[i][j].includes("cross_sell") &&
								!isEncounteredCrossSell
							) {
								isEncounteredCrossSell = true;
								tempData.push(
									this.props.t("DIALER.CROSS_SELL")
								);
								for (
									let c = 0;
									c < Object.keys(data[i]).length - 2;
									c++
								) {
									tempData.push("");
								}
								csvJsonData.push(tempData);
							}
						}
					}

					csvJsonData.push(rowData);
				}
				campaign_data = data;
				csvJsonData.unshift(subHeader);
				this.setState(
					{
						campaign_name: campaign_name,
						subHeaders: subHeader,
						csvJsonData: csvJsonData,
						campaign_data: campaign_data,
						list_id: list_id,
						filterData: filterData,
						last_updated: last_updated,
						loading: false,
					},
					async () => {
						if (
							campaign_data instanceof Array &&
							campaign_data.length
						) {
							await this.props.actions.SaveDownloadLog({
								module: "DialerReport",
								action: "2",
							});
						}
					}
				);
			})
			.catch((err) => {});
	};

	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		if (
			props.campaign_list &&
			props.campaign_list !== state.campaign_list
		) {
			updateProps["campaign_list"] = props.campaign_list;
		}
		if (props.make_map && props.make_map !== state.make_map) {
			updateProps["make_map"] = props.make_map;
		}
		if (props.model_map && props.model_map !== state.model_map) {
			updateProps["model_map"] = props.model_map;
		}
		if (props.city_map && props.city_map !== state.city_map) {
			updateProps["city_map"] = props.city_map;
		}
		return updateProps;
	}
	getInnerTable = (obj, index) => {
		const { campaign_name } = this.state;
		let innerTd = [],
			countIndex = 0;
		const slug = obj["slug"] ? obj["slug"] : "";
		const list_id = obj["List_id"] ? obj["List_id"] : "";
		for (let i in obj) {
			if (i !== "slug") {
				let property = "";
				if (
					slug &&
					!slug.includes("total_agent_loggened_in") &&
					![campaign_name, "List_id"].includes(i)
				) {
					property = (
						<span
							className={`${obj[i] > 0 ? "report-csv-list" : ""}`}
							onClick={() =>
								this.getParticularRecordDetail(
									list_id,
									slug,
									i,
									obj[i]
								)
							}
						>
							{obj[i]}
						</span>
					);
				} else {
					property = obj[i];
				}
				innerTd.push(
					<td key={`${countIndex}_${index}`}>{property}</td>
				);
				countIndex++;
			}
		}
		return innerTd;
	};
	getInnerTr = (i, campaign_data) => {
		return <tr key={i}>{this.getInnerTable(campaign_data, i)}</tr>;
	};
	getTableData = () => {
		const { campaign_data } = this.state;
		let html = [],
			isEncountered = false,
			isEncounteredCrossSell = false;
		for (let i = 0; i < campaign_data.length; i++) {
			let tempObj = campaign_data[i],
				tempData = [];
			if (
				tempObj &&
				tempObj["slug"] &&
				tempObj["slug"].includes("call_") &&
				!isEncountered
			) {
				isEncountered = true;
				tempData.push(this.props.t("DIALER.CONNECTED_CALLS"));
				for (let c = 0; c < Object.keys(tempObj).length - 2; c++) {
					tempData.push("");
				}
				html.push(
					<tr key={`slu_tr_${i}`}>
						{tempData.map((e, cuIn) => {
							return <td key={cuIn}>{e}</td>;
						})}
					</tr>
				);
			}
			if (
				tempObj &&
				tempObj["slug"] &&
				tempObj["slug"].includes("cross_sell") &&
				!isEncounteredCrossSell
			) {
				isEncounteredCrossSell = true;
				tempData.push(this.props.t("DIALER.CROSS_SELL"));
				for (let c = 0; c < Object.keys(tempObj).length - 2; c++) {
					tempData.push("");
				}
				html.push(
					<tr key={`slu_tr_${i}`}>
						{tempData.map((e, cuIn) => {
							return <td key={cuIn}>{e}</td>;
						})}
					</tr>
				);
			}
			let innerTr = this.getInnerTr(i, campaign_data[i]);
			html.push(innerTr);
		}

		return html;
	};
	handleSyncCall = async () => {
		this.setState({ loading: true });
		const resp = await this.props.actions.syncDialerReportData();
		if (resp && resp.response && resp.status) {
			toast.success(resp.response);
			const { filterData } = this.state;
			this.submitRequest(filterData);
		}
	};
	render() {
		const {
			csvJsonData,
			campaign_list,
			campaign_name,
			campaign_data,
			subHeaders,
			csvDataIndividual,
			last_updated,
			loading,
			isSearchClicked,
		} = this.state;
		return (
			<div>
				<SearchDialer
					onSubmitFilter={this.submitRequest}
					campaign_list={campaign_list}
					// csvJsonData={csvJsonData}
					loading={loading}
				/>
				<div className="hidden">
					<CSVLink
						className="hidden"
						ref={this.csvLink}
						filename="lead_detail.csv"
						data={csvDataIndividual}
					>
						{" "}
					</CSVLink>
				</div>
				{isSearchClicked && (
					<div className="container-fluid reportContainer">
						<div className="result-wrap">
							<div className="row mrg-b5">
								{campaign_name && (
									<div className="col-sm-6">
										<p className="countNo mrg-t5 mrg-b0">
											{this.props.t("DIALER.CAMPAIGNS")}-
											{campaign_name}
										</p>
										{last_updated && (
											<p className="countNo mrg-t5 mrg-b0">
												{this.props.t(
													"DIALER.LAST_UPDATED"
												)}
												-
												{dateformat(
													last_updated,
													config.constants.DATE_FORMAT
												)}
											</p>
										)}
									</div>
								)}
								<div className="col-sm-6 text-right">
									{csvJsonData && csvJsonData.length ? (
										<ul className="dialer-report-options">
											<li className="searchitems form-group">
												<i
													className="ic-sync-24px icons-normal"
													onClick={() =>
														this.handleSyncCall()
													}
													title={this.props.t(
														"DIALER.SYNC_REPORT"
													)}
												></i>
											</li>
											<li className="searchitems text-right">
												<CSVLink
													data={csvJsonData}
													filename={
														"dialer_report.csv"
													}
													className="btn btn-primary"
													target="_blank"
													onClick={async () =>
														await this.props.actions.SaveDownloadLog(
															{
																module:
																	"DialerReport",
																action: "1",
															}
														)
													}
												>
													<i className="ic-file_download"></i>
													{this.props.t(
														"DIALER.DOWNLOAD_CSV"
													)}
												</CSVLink>
											</li>
										</ul>
									) : (
										""
									)}
								</div>
							</div>
							<div className="card">
								<div className="table-responsive table-colFixed">
									{campaign_data instanceof Array &&
									campaign_data.length != 0 ? (
										<table className="table table-bordered table-hover salesReportTable ">
											<thead>
												<tr>
													{subHeaders instanceof Array
														? subHeaders.map(
																(e, i) => (
																	<th key={i}>
																		{e}
																	</th>
																)
														  )
														: ""}
												</tr>
											</thead>

											<tbody>{this.getTableData()}</tbody>
										</table>
									) : (
										this.props.t("DIALER.NOT_FOUND")
									)}
								</div>
								{loading && (
									<PageLoader
										loaderClass=" text-center loader content-loader"
										loadertext="true"
									/>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}
const mapStateToProps = ({ config, inventory }) => {
	return {
		campaign_list:
			config.gm_campaign_list instanceof Array
				? config.gm_campaign_list.map((e) => {
						return { value: e.id, label: e.name };
				  })
				: [],
		make_map: inventory.make_map ? inventory.make_map : {},
		model_map: inventory.model_map ? inventory.model_map : {},
		city_map:
			inventory.city instanceof Array
				? inventory.city.reduce(
						(obj, item) => ((obj[item.id] = item.name), obj),
						{}
				  )
				: {},
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
			syncDialerReportData: bindActionCreators(
				UserActions.syncDialerReportData,
				dispatch
			),
			SaveDownloadLog: bindActionCreators(
				LeadActions.SaveDownloadLog,
				dispatch
			),
		},
	};
};
export default withTranslation("report")(
	connect(mapStateToProps, mapDispatchToProps)(DialerReport)
);
