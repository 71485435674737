import React, { Component } from "react";
import Select from "react-select";
import InputField from "../../elements/InputField";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import dateformat from "dateformat";
import _ from "lodash";
import { default as config } from "./../../../config/config";
import { debounce } from "throttle-debounce";
import { Redirect, useHistory } from "react-router-dom";
import secureStorage from "./../../../config/encrypt";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { ConfigAction, AuditAction } from "./../../../store/action";
import { bindActionCreators } from "redux";
import { AuditService } from "../../../service/auditService";
import { LeadService } from "../../../service/leadService";
import Q from "q";
import AuditDetails from "./AuditDetails";
import ModalPopup from "./../../elements/Popup";

const now = new Date();
const currhour = Number(now.getHours());
const currmin = now.getMinutes();

class SaveAudit extends Component {
	user_id;
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			validationreq: true,
			formData: {},
			successResponse: false,
			redirect: false,
			errors: {},
			show: false,
			loaderClass: "",
			searchParams: {},
			logginUser: secureStorage.getItem("authUserInfo")["authUser"],
			agent_list: [],
			action_needed_list: [],
			selectedFile: null,
			isFormSubmited: false,
			isReaudit: false,
			showAuditDetails: false,
			lead_id: this.props.lead_id || 0,
			loggedInUserRoleId: this.props.authUser.role_id,
			parent_id: 0,
			isFileSizeOkay:true
		};
		//this.user_id = this.props.match.params.id;
		if (this.user_id) this.getUser = this.getUser.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.isUserExist = debounce(700, this.isUserExist);
	}

	componentDidMount = async () => {
		await this.getConfigData();
		await this.getAuditDetailsData();
	};

	getAuditDetailsData = async () => {
		const lead_id = new URLSearchParams(window.location.search).get("id");
		const is_reaudit = new URLSearchParams(window.location.search).get(
			"is_reaudit"
		);
		const auditlead_id = new URLSearchParams(window.location.search).get(
			"auditlead_id"
		);
		const parent_id =
			new URLSearchParams(window.location.search).get("parent_id") || 0;
		if (is_reaudit) {
			this.setState({ is_reaudit: true });
			this.getAuditDetails({
				lead_id: lead_id,
				audit_id: auditlead_id,
				is_reaudit: 1,
				parent_id: parent_id || 0,
			});
		} else {
			this.getAuditDetails({
				lead_id: lead_id,
				audit_id: auditlead_id,
				parent_id: parent_id || 0,
			});
		}
	}

	static getDerivedStateFromProps(props, state) {
		const updateProps = {};

		if (props.agent_list && props.agent_list !== state.agent_list) {
			updateProps["agent_list"] = props.agent_list;
		}
		if (
			props.action_needed_list &&
			props.action_needed_list !== state.action_needed_list
		) {
			updateProps["action_needed_list"] = props.action_needed_list;
		}
		return updateProps;
	}

	getConfigData = async () => {
		const requestBody = {
			config_data: ["agent_list", "action_needed_list"],
		};
		this.props.actions.getConfigData(requestBody);
	};

	getAuditDetails = async (postData) => {
		let response = await this.props.actions.getAuditDetails(postData);
		const parent_id =
			new URLSearchParams(window.location.search).get("parent_id") || 0;
		let { loggedInUserRoleId } = this.state;
		const { AGENT_ROLE_ID } = config.constants;
		let isUserTypeAgent =
			loggedInUserRoleId === AGENT_ROLE_ID ? true : false;
		if (response && response.status && response.data) {
			let auditData = response.data;
			if (auditData && auditData.calling_date) {
				auditData["calling_time"] = dateformat(
					auditData.calling_date,
					config.constants.TIME_FORMAT
				);
			}
			this.setState({
				formData: auditData,
				parent_id: parent_id,
				isFormSubmited: parent_id || isUserTypeAgent ? true : false,
			});
		}
	};

	submitForm = async (event) => {
		event.preventDefault();
		if (this.validForm()) {
			let { formData, selectedFile } = this.state;
			let compliance = await this.calculateComplianceValue();
			formData["audit_lead_status"] =
				Number(compliance) < 100 ? "PENDING_ON_AGENT" : "ACCEPTED";
			formData["compliance"] = String(compliance);
			let leadId = new URLSearchParams(window.location.search).get("id");
			let auditlead_id = new URLSearchParams(window.location.search).get(
				"auditlead_id"
			);
			formData.lead_id = leadId;
			formData.auditlead_id = auditlead_id;
			if (formData.id) {
				let parentId = formData.id;
				formData["parent_id"] = parentId;
				delete formData.id;
			}
			if (formData.calling_date && formData.calling_time) {
				let calling_date2send = new Date(
					dateformat(
						dateformat(
							formData.calling_date,
							config.constants.DATE_FORMAT_YMD
						) +
							" " +
							formData.calling_time,
						config.constants.DATE_FORMAT
					)
				).toISOString();
				formData.calling_date = calling_date2send;
			}
			//this.setState({formData:formData})
			if (selectedFile) {
				await this.uploadFileOnAWS(formData);
			} else {
				await this.saveAuditDetails(formData);
			}
		}
	};

	saveAuditDetails = async (formData) => {
		//let {formData} = this.state;
		AuditService.saveAudit(formData)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					toast.success(response.data.message);
					this.getAuditDetailsData()
					this.setState({
						loading: false,
						redirectTolist: true,
						selectedFile: null,
						isFormSubmited: true,
					});
				} else if (
					response.status === 401 &&
					response.data.status === 401
				) {
					this.setState({ redirect: true, selectedFile: null });
				} else {
					// toast.error(response.data.message);
					this.setState({ loading: false, selectedFile: null });
				}
			})
			.catch((error) => {
				this.setState({ loading: false, selectedFile: null });
			});
	};

	validForm = () => {
		let { formData, errors, is_reaudit ,isFileSizeOkay} = this.state;
		let formIsValid = true,
			inputKey = [];
		let requiredKey = [
			"agent_id",
			"calling_date",
			"calling_time",
			"new_used",
			"purchase_cps_brand_car",
			"vehicle_brand",
			"dealership_name",
			"dealership_city",
			"dse_name",
			"booking_name",
			"booking_mobile",
			"purchase_date",
			"lost_reason",
			"crm_update",
			"cash_or_finance",
			"purchase_proof",
			"action_required",
		];
		for (let key in formData) {
			inputKey.push(key);
		}
		for (let reqKey of requiredKey) {
			if (
				!inputKey.includes(reqKey) &&
				!is_reaudit &&
				reqKey !== "calling_time"
			) {
				errors[reqKey] = this.props.t("SAVE.REQUIRED_FIELD");
				formIsValid = false;
			}
		}
		errors["attachment_name"]="";
		if(!isFileSizeOkay){
			let {MAX_UPLOAD_FILE_SIZE}=config.constants;
			let allowSize = MAX_UPLOAD_FILE_SIZE / 1000000;
			errors["attachment_name"] = this.props.t("SAVE.THE_FILE_ALLOW_WITH_SIZE")+`${allowSize} MB`;
			formIsValid = false;
		}
		console.log("formIsValid",formIsValid)
		return formIsValid;
	};

	handleChange = (key, event) => {
		let { formData, errors } = this.state;
		formData = formData ? formData : {};
		let value;
		if (event) {
			if (["calling_date", "calling_time"].includes(key)) {
				if (key === "calling_time")
					value = dateformat(event, config.constants.TIME_FORMAT);
				else value = event;
			} else if (["comments"].includes(key)) value = event.target.value;
			else value = event.value || event.id;
			formData[key] = value;
			errors[key] = "";
		} else {
			delete formData[key];
			delete errors[key];
		}

		this.setState({ formData: formData, errors: errors });
	};

	calculateComplianceValue = () => {
		let { formData } = this.state;
		let yesCount = 0,
			noCount = 0,
			fatalCount = 0,
			naCount = 0,
			compliance = 0,
			totalQesCount = 0;
		for (let [key, value] of Object.entries(formData)) {
			if (value === "YES") yesCount++;
			else if (value === "NO") noCount++;
			else if (value === "NA") naCount++;
			else if (value === "FATAL") fatalCount++;
			if (["YES", "NO", "FATAL", "NA"].includes(value)) totalQesCount++;
		}
		if (fatalCount === 0) {
			compliance = ((yesCount / (totalQesCount - naCount)) * 100).toFixed(
				2
			);
		} else compliance = 0;
		return compliance;
	};

	onChangeHandler = async (event) => {
		let selectedFile = event.target.files[0];
		let {errors,isFileSizeOkay}=this.state;
		const { MAX_UPLOAD_FILE_SIZE } = config.constants;
		let allowSize = MAX_UPLOAD_FILE_SIZE / 1000000;
		errors["attachment_name"]="";
		if(selectedFile.size > MAX_UPLOAD_FILE_SIZE) {
			isFileSizeOkay=false;
			errors["attachment_name"] = this.props.t("SAVE.THE_FILE_ALLOW_WITH_SIZE")+`${allowSize} MB`;
		}else{
			isFileSizeOkay=true;
		}
		console.log("isFileSizeOkay",isFileSizeOkay,MAX_UPLOAD_FILE_SIZE,selectedFile.size)
		await this.setState({ selectedFile: selectedFile ,isFileSizeOkay:isFileSizeOkay,errors:errors});
	};

	uploadFileOnAWS = async (postData) => {
		let { selectedFile } = this.state; //event.target.files;
		let files = Array.from([selectedFile]);
		let awsRes = [],
			leadDocArr = {};
		if (files && files.length) {
			var formData = new FormData();
			formData.append("upload_type", "crm_docs");
			formData.append("lead_id", postData.lead_id);
			files.forEach((file, index) => {
				formData.append("images", file);
			});
			awsRes.push(
				LeadService.uploadFilesToS3(formData, {
					"Content-Type": "multipart/form-data",
				})
			);
		} else {
			awsRes.push(
				Promise.resolve({
					data: {
						status: 200,
						message: "Success",
						data: ["not_updated"],
					},
				})
			);
			this.setState({ loaderClass: "" });
		}

		Q.allSettled(awsRes)
			.then((uploadOutput) => {
				uploadOutput.forEach(async (res, index) => {
					let response = res.value || [];
					if (res.state === "fulfilled") {
						if (
							response &&
							response.data &&
							response.data.data &&
							response.data.data.length
						) {
							let resData = response.data.data;

							postData["attachment_url"] = resData[0].file_url;
							await this.saveAuditDetails(postData);
						}
					}
				});
			})
			.catch((error) => {
				this.setState({ loaderClass: "" });
			});
		return true;
	};

	getAuditList = async (audit_id = 0) => {
		const lead_id = new URLSearchParams(window.location.search).get("id");
		let audit_res = await this.props.actions.getAllAudits({
			lead_id: lead_id,
			audit_id: audit_id,
		});
		let isFormSubmited = false;
		if (
			audit_res &&
			audit_res.status &&
			audit_res.data &&
			audit_res.data.length
		) {
			isFormSubmited = true;
			let auditData = audit_res.data[0];
			if (auditData) {
				auditData["calling_time"] = dateformat(
					auditData.calling_date,
					config.constants.TIME_FORMAT
				);
			}
		}
		this.setState({
			formData: audit_res.data[0],
			isFormSubmited: isFormSubmited,
		});
	};

	showHideModal = () => {
		let { showAuditDetails } = this.state;
		this.setState({ showAuditDetails: showAuditDetails ? false : true });
	};

	render() {
		
		const {
			formData,
			loading,
			errors,
			redirectTolist,
			logginUser,
			agent_list,
			action_needed_list,
			isFormSubmited,
			is_reaudit,
			lead_id,
			showAuditDetails,
			loggedInUserRoleId,
			parent_id
		} = this.state;
		const {
			AUDIT_QUEST_ANS,
			DATE_FORMAT_INPUT,
			TIME_FORMAT_INPUT,
			AGENT_ROLE_ID,
		} = config.constants;
		let isUserTypeAgent =
			loggedInUserRoleId === AGENT_ROLE_ID ? true : false;
		// if (is_reaudit && formData && formData.attachment_url) {
		// 	let att_split = formData.attachment_url.split("/");
		// 	formData.attachment_url = att_split[att_split.length - 1];
		// }
		//if (redirectTolist) return <Redirect to="/list-user" />;
		return (
			<div className="container-fluid">
				<div className="row">
					<div className=" col-sm-8">
						<h3>{this.props.t("SAVE.TITLE")} </h3>
					</div>
					<div className=" col-sm-4">
						<span
							className="btn text-right"
							onClick={this.showHideModal}
						>
							Previous Audits ({formData.previous_audits || 0})
						</span>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<form
							method="post"
							id="formDetails"
							onSubmit={this.submitForm}
						>
							{is_reaudit ? (
								<>
									{" "}
									<div className="row">
										<div className=" col-sm-2 form-group">
											<label>
												{this.props.t("VIEW.AUDITOR")}
											</label>
										</div>
										<div className=" col-sm-2 form-group">
											<strong>
												{formData && formData.auditor
													? formData.auditor
													: ""}
											</strong>
										</div>
										<div className=" col-sm-2 form-group">
											<label>
												{this.props.t(
													"VIEW.AUDIT_DATE_TIME"
												)}
											</label>
										</div>
										<div className=" col-sm-4 form-group">
											<strong>
												{formData &&
												formData.created_date
													? dateformat(
															formData.created_date,
															config.constants
																.DATE_FORMAT_WITH_TIME
													  )
													: ""}
											</strong>
										</div>
									</div>
									<div className="row">
										<div className=" col-sm-2 form-group">
											<label>
												{this.props.t(
													"VIEW.AGENT_NAME"
												)}
											</label>
										</div>
										<div className=" col-sm-2 form-group">
											<strong>
												{formData && formData.agent_name
													? formData.agent_name
													: ""}
											</strong>
										</div>
										<div className="col-sm-2 ">
											<label>
												{this.props.t(
													"VIEW.CALLING_DATE_TIME"
												)}
											</label>
										</div>
										<div className=" col-sm-4 form-group">
											<strong>
												{formData &&
												formData.calling_date
													? dateformat(
															formData.calling_date,
															config.constants
																.DATE_FORMAT_WITH_TIME
													  )
													: ""}
											</strong>
										</div>
									</div>{" "}
								</>
							) : (
								<div className="row">
									<div className=" col-sm-4 form-group">
										<label>
											{this.props.t("SAVE.AGENT_NAME")}
										</label>
										<span className="text-danger">*</span>
										<Select
											id="agent_id"
											options={agent_list}
											name="agent_id"
											onChange={this.handleChange.bind(
												this,
												"agent_id"
											)}
											value={
												formData &&
												agent_list.filter(
													({ value }) =>
														value ===
														formData.agent_id
												)
											}
											isDisabled={isFormSubmited}
											isClearable
										/>
										<span className="text-danger">
											{errors.agent_id || ""}
										</span>
									</div>
									<div className="col-sm-4 ">
										<label>
											{this.props.t("SAVE.CALLING_DATE")}
											<span className="text-danger">
												*
											</span>
										</label>

										<DatePicker
											className="form-control"
											autoComplete="off"
											placeholderText={this.props.t(
												"SAVE.CALLING_DATE"
											)}
											onChange={this.handleChange.bind(
												this,
												"calling_date"
											)}
											selected={
												formData &&
												formData.calling_date
													? new Date(
															formData.calling_date
													  )
													: ""
											}
											dateFormat={DATE_FORMAT_INPUT}
											maxDate={new Date()}
											onKeyDown={(e) =>
												e.preventDefault()
											}
											disabled={isFormSubmited}
										/>
										<span className="text-danger">
											{errors.calling_date || ""}
										</span>
									</div>
									<div className="col-sm-4 ">
										<label>
											{this.props.t("SAVE.CALLING_TIME")}
											<span className="text-danger">
												*
											</span>
										</label>

										<DatePicker
											showTimeSelect
											showTimeSelectOnly
											className="form-control"
											autoComplete="off"
											placeholderText={this.props.t(
												"SAVE.CALLING_TIME"
											)}
											onChange={this.handleChange.bind(
												this,
												"calling_time"
											)}
											value={
												formData &&
												formData.calling_time
													? formData.calling_time
													: ""
											}
											timeFormat={
												config.constants
													.TIME_FORMAT_INPUT
											}
											timeIntervals={15}
											onKeyDown={(e) =>
												e.preventDefault()
											}
											disabled={isFormSubmited}
										/>
										<span className="text-danger">
											{errors.calling_time || ""}
										</span>
									</div>
								</div>
							)}

							<div className="row">
								<div className=" col-sm-4 form-group">
									<label>
										{this.props.t("SAVE.NEW_USED")}
									</label>
									<span className="text-danger">*</span>
									<Select
										id="new_used"
										options={AUDIT_QUEST_ANS}
										name="new_used"
										onChange={this.handleChange.bind(
											this,
											"new_used"
										)}
										value={
											formData &&
											AUDIT_QUEST_ANS.filter(
												({ value }) =>
													value === formData.new_used
											)
										}
										isDisabled={isFormSubmited}
										isClearable
									/>
									<span className="text-danger">
										{errors.new_used || ""}
									</span>
								</div>
								<div className="col-sm-4 ">
									<label>
										{this.props.t(
											"SAVE.PURCHASED_CPS_BRAND_CAR"
										)}
										<span className="text-danger">*</span>
									</label>
									<Select
										id="purchase_cps_brand_car"
										options={AUDIT_QUEST_ANS}
										name="purchase_cps_brand_car"
										onChange={this.handleChange.bind(
											this,
											"purchase_cps_brand_car"
										)}
										value={
											formData &&
											AUDIT_QUEST_ANS.filter(
												({ value }) =>
													value ===
													formData.purchase_cps_brand_car
											)
										}
										isDisabled={isFormSubmited}
										isClearable
									/>
									<span className="text-danger">
										{errors.purchase_cps_brand_car || ""}
									</span>
								</div>
								<div className="col-sm-4 ">
									<label>
										{this.props.t("SAVE.VEHICLE_BRAND")}
										<span className="text-danger">*</span>
									</label>
									<Select
										id="vehicle_brand"
										options={AUDIT_QUEST_ANS}
										name="vehicle_brand"
										onChange={this.handleChange.bind(
											this,
											"vehicle_brand"
										)}
										value={
											formData &&
											AUDIT_QUEST_ANS.filter(
												({ value }) =>
													value ===
													formData.vehicle_brand
											)
										}
										isDisabled={isFormSubmited}
										isClearable
									/>
									<span className="text-danger">
										{errors.vehicle_brand || ""}
									</span>
								</div>
							</div>

							<div className="row">
								<div className=" col-sm-4 form-group">
									<label>
										{this.props.t("SAVE.DEALERSHIP_NAME")}
									</label>
									<span className="text-danger">*</span>
									<Select
										id="dealership_name"
										options={AUDIT_QUEST_ANS}
										name="dealership_name"
										onChange={this.handleChange.bind(
											this,
											"dealership_name"
										)}
										value={
											formData &&
											AUDIT_QUEST_ANS.filter(
												({ value }) =>
													value ===
													formData.dealership_name
											)
										}
										isDisabled={isFormSubmited}
										isClearable
									/>
									<span className="text-danger">
										{errors.dealership_name || ""}
									</span>
								</div>
								<div className="col-sm-4 ">
									<label>
										{this.props.t("SAVE.DEALERSHIP_CITY")}
										<span className="text-danger">*</span>
									</label>
									<Select
										id="dealership_city"
										options={AUDIT_QUEST_ANS}
										name="dealership_city"
										onChange={this.handleChange.bind(
											this,
											"dealership_city"
										)}
										value={
											formData &&
											AUDIT_QUEST_ANS.filter(
												({ value }) =>
													value ===
													formData.dealership_city
											)
										}
										isDisabled={isFormSubmited}
										isClearable
									/>
									<span className="text-danger">
										{errors.dealership_city || ""}
									</span>
								</div>
								<div className="col-sm-4 ">
									<label>
										{this.props.t("SAVE.DSE_NAME")}
										<span className="text-danger">*</span>
									</label>
									<Select
										id="dse_name"
										options={AUDIT_QUEST_ANS}
										name="dse_name"
										onChange={this.handleChange.bind(
											this,
											"dse_name"
										)}
										value={
											formData &&
											AUDIT_QUEST_ANS.filter(
												({ value }) =>
													value === formData.dse_name
											)
										}
										isDisabled={isFormSubmited}
										isClearable
									/>
									<span className="text-danger">
										{errors.dse_name || ""}
									</span>
								</div>
							</div>

							<div className="row">
								<div className=" col-sm-4 form-group">
									<label>
										{this.props.t("SAVE.BOOKING_NAME")}
									</label>
									<span className="text-danger">*</span>
									<Select
										id="booking_name"
										options={AUDIT_QUEST_ANS}
										name="booking_name"
										onChange={this.handleChange.bind(
											this,
											"booking_name"
										)}
										value={
											formData &&
											AUDIT_QUEST_ANS.filter(
												({ value }) =>
													value ===
													formData.booking_name
											)
										}
										isDisabled={isFormSubmited}
										isClearable
									/>
									<span className="text-danger">
										{errors.booking_name || ""}
									</span>
								</div>
								<div className="col-sm-4 ">
									<label>
										{this.props.t("SAVE.BOOKING_MOBILE")}
										<span className="text-danger">*</span>
									</label>
									<Select
										id="booking_mobile"
										options={AUDIT_QUEST_ANS}
										name="booking_mobile"
										onChange={this.handleChange.bind(
											this,
											"booking_mobile"
										)}
										value={
											formData &&
											AUDIT_QUEST_ANS.filter(
												({ value }) =>
													value ===
													formData.booking_mobile
											)
										}
										isDisabled={isFormSubmited}
										isClearable
									/>
									<span className="text-danger">
										{errors.booking_mobile || ""}
									</span>
								</div>
								<div className="col-sm-4 ">
									<label>
										{this.props.t("SAVE.PURCHASED_DATE")}
										<span className="text-danger">*</span>
									</label>
									<Select
										id="purchase_date"
										options={AUDIT_QUEST_ANS}
										name="purchase_date"
										onChange={this.handleChange.bind(
											this,
											"purchase_date"
										)}
										value={
											formData &&
											AUDIT_QUEST_ANS.filter(
												({ value }) =>
													value ===
													formData.purchase_date
											)
										}
										isDisabled={isFormSubmited}
										isClearable
									/>
									<span className="text-danger">
										{errors.purchase_date || ""}
									</span>
								</div>
							</div>

							<div className="row">
								<div className=" col-sm-4 form-group">
									<label>
										{this.props.t("SAVE.LOST_REASON")}
									</label>
									<span className="text-danger">*</span>
									<Select
										id="lost_reason"
										options={AUDIT_QUEST_ANS}
										name="lost_reason"
										onChange={this.handleChange.bind(
											this,
											"lost_reason"
										)}
										value={
											formData &&
											AUDIT_QUEST_ANS.filter(
												({ value }) =>
													value ===
													formData.lost_reason
											)
										}
										isDisabled={isFormSubmited}
										isClearable
									/>
									<span className="text-danger">
										{errors.lost_reason || ""}
									</span>
								</div>
								<div className="col-sm-4 ">
									<label>
										{this.props.t("SAVE.CRM_UPDATE")}
										<span className="text-danger">*</span>
									</label>
									<Select
										id="crm_update"
										options={AUDIT_QUEST_ANS}
										name="crm_update"
										onChange={this.handleChange.bind(
											this,
											"crm_update"
										)}
										value={
											formData &&
											AUDIT_QUEST_ANS.filter(
												({ value }) =>
													value ===
													formData.crm_update
											)
										}
										isDisabled={isFormSubmited}
										isClearable
									/>
									<span className="text-danger">
										{errors.crm_update || ""}
									</span>
								</div>
								<div className="col-sm-4 ">
									<label>
										{this.props.t("SAVE.CASH_FINANCE")}
										<span className="text-danger">*</span>
									</label>
									<Select
										id="cash_or_finance"
										options={AUDIT_QUEST_ANS}
										name="cash_or_finance"
										onChange={this.handleChange.bind(
											this,
											"cash_or_finance"
										)}
										value={
											formData &&
											AUDIT_QUEST_ANS.filter(
												({ value }) =>
													value ===
													formData.cash_or_finance
											)
										}
										isDisabled={isFormSubmited}
										isClearable
									/>
									<span className="text-danger">
										{errors.cash_or_finance || ""}
									</span>
								</div>
							</div>

							<div className="row">
								<div className=" col-sm-4 form-group">
									<label>
										{this.props.t("SAVE.PURCHASED_PROOF")}
									</label>
									<span className="text-danger">*</span>
									<Select
										id="purchase_proof"
										options={AUDIT_QUEST_ANS}
										name="purchase_proof"
										onChange={this.handleChange.bind(
											this,
											"purchase_proof"
										)}
										value={
											formData &&
											AUDIT_QUEST_ANS.filter(
												({ value }) =>
													value ===
													formData.purchase_proof
											)
										}
										isDisabled={isFormSubmited}
										isClearable
									/>
									<span className="text-danger">
										{errors.purchase_proof || ""}
									</span>
								</div>
								<div className=" col-sm-4 form-group">
									<label>
										{this.props.t("SAVE.ACTION_NEEDED")}
									</label>
									<span className="text-danger">*</span>
									<Select
										id="action_required"
										options={action_needed_list}
										name="action_required"
										onChange={this.handleChange.bind(
											this,
											"action_required"
										)}
										value={
											formData &&
											action_needed_list.filter(
												({ value }) =>
													value ===
													formData.action_required
											)
										}
										isDisabled={isFormSubmited}
										isClearable
									/>
									<span className="text-danger">
										{errors.action_required || ""}
									</span>
								</div>
								<div className=" col-sm-4 form-group">
									<label>
										<strong>
											{this.props.t("SAVE.ATTACHMENT")}
										</strong>
									</label>

									<input
										type="file"
										id="file"
										name="attachment_name"
										onChange={this.onChangeHandler}
										ref={(ref) => (this.fileInput = ref)}
										disabled={isFormSubmited}
									/>
									<span className="text-danger">
										{errors.attachment_name || ""}
									</span>
									<div id="fileErr"></div>
								</div>
								&nbsp;&nbsp;&nbsp;
								{is_reaudit && formData.attachment_url ? (
									<strong>
										<a
											href={formData.attachment_url}
											target="_blank"
										>
											{this.props.t("SAVE.VIEW")}
										</a>
									</strong>
								) : null}
							</div>
							<div className="row">
								<div className="col-sm-6 form-group">
									<label>
										{this.props.t("SAVE.COMMENTS")}
									</label>
									<textarea
										name="commentss"
										placeholder={this.props.t(
											"SAVE.COMMENTS"
										)}
										className="form-control"
										value={
											(formData && formData.comments) ||
											""
										}
										onChange={this.handleChange.bind(
											this,
											"comments"
										)}
										disabled={isFormSubmited}
									/>
								</div>
							</div>

							<div className="row">
								<div className=" col-sm-4 form-group text-center">
									{!loading ? (
										<button
											type="submit"
											onClick={this.submitForm}
											className="btn btn-primary"
											disabled={isFormSubmited || (formData && ['PENDING_ON_AGENT','ACCEPTED'].indexOf(formData.audit_status) !== -1)}
										>
											{this.props.t("SAVE.UPDATE")}
										</button>
									) : (
										<button
											type="submit"
											className="btn btn-primary"
										>
											{this.props.t("SAVE.PLEASE_WAIT")}
										</button>
									)}
								</div>
							</div>
						</form>
					</div>
					{showAuditDetails ? (
						<ModalPopup
							id="asd"
							className="modal modal-lg"
							title=""
							modalClose={this.showHideModal}
						>
							<AuditDetails
								modalClose={this.closeModal}
								selectedLeadId={lead_id}
								parentId={parent_id}
								isUserTypeAgent={isUserTypeAgent}
							/>
						</ModalPopup>
					) : null}
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ config, inventory, auth }) => {
	return {
		agent_list:
			config.agent_list instanceof Array
				? config.agent_list.map((e) => {
						return {
							label: e.name,
							value: e.id,
						};
				  })
				: [],
		action_needed_list:
			config.action_needed_list instanceof Array
				? config.action_needed_list.map((e) => {
						return {
							label: e.name,
							value: e.action_id,
						};
				  })
				: [],
		authUser: auth.authUser.authUser,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
			getAuditDetails: bindActionCreators(
				AuditAction.getAuditDetails,
				dispatch
			),
			getAllAudits: bindActionCreators(
				AuditAction.getAllAudits,
				dispatch
			),
		},
	};
};
export default withTranslation("audit")(
	connect(mapStateToProps, mapDispatchToProps)(SaveAudit)
);
