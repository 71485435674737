import React, { Component } from "react";
import Select from "react-select";
import InputField from "../../../elements/InputField";
import CheckBox from "../../../elements/CheckBox";
import { webFormService } from "../../../../service/webFormService";
import { LeadService } from "../../../../service/leadService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import PageLoader from "../../../elements/PageLoader";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { Redirect } from "react-router-dom";
class EditProcess extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: this.props.match.params.id ? true : false,
			processinfo: this.props.processinfo || {},
			country_list: this.props.country_list || [],
			business_type_list: this.props.business_type_list || [],
			domain_list: this.props.domain_list || [],
			lead_type_list: this.props.lead_type_list || [],
			process_id: this.props.match.params.id || 0,
			errors: {},
			redirectTolist: false,
		};
		this.ChangeCheckbox = this.ChangeCheckbox.bind(this);
	}

	componentDidMount = async () => {
		await this.getConfigData();
	};

	getConfigData = async () => {
		const requestBody = {
			config_data: [
				"country_list",
				"business_type_list",
				"domain_list",
				"lead_type_list",
			],
		};
		LeadService.getConfig(requestBody)
			.then(async (response) => {
				if (response.status === 200 && response.data.status === 200) {
					this.setState({
						country_list: response.data.data.country_list || [],
						business_type_list:
							response.data.data.business_type_list || [],
						domain_list:
							response.data.data.domain_list &&
							response.data.data.domain_list.length
								? response.data.data.domain_list[0]
								: [],
						lead_type_list: response.data.data.lead_type_list || [],
					});
					if (this.state.process_id) await this.getProcess();
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	getProcess = async () => {
		this.setState({ loading: true });
		let process_id = this.state.process_id;
		webFormService
			.getProcessList({ id: process_id, list_type: "ALL" })
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let processinfo = response.data.data
						? response.data.data[0]
						: [];
					let { domain_list, lead_type_list } = this.state;

					if (processinfo.lead_type_ids && lead_type_list.length) {
						let lead_type_ids = processinfo.lead_type_ids.split(
							","
						);
						processinfo[
							"lead_type_id"
						] = lead_type_list.filter((v) =>
							lead_type_ids.includes(v.id.toString())
						);
					}

					if (processinfo.domain_ids && domain_list.length) {
						let domain_ids = processinfo.domain_ids.split(",");
						processinfo["domain_id"] = domain_list.filter((v) =>
							domain_ids.includes(v.id.toString())
						);
					}
					this.setState({
						processinfo: processinfo || {},
						loading: false,
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	handleChange = (sname, valueObj) => {
		let { processinfo, errors } = this.state;
		let value = valueObj.value || valueObj.business_type_id;
		if (sname === "name") {
			value = valueObj.target.value;
		}
		errors[sname] = "";
		processinfo[sname] = value;
		this.setState({ processinfo: processinfo, errors: errors });
	};

	handleChangeMultiSel = (field_name, field_obj) => {
		let { processinfo, errors } = this.state;
		processinfo[field_name] = field_obj;
		if (field_name === "domain_id") errors.domain_ids = "";
		if (field_name === "lead_type_id") errors.lead_type_ids = "";
		this.setState({ processinfo: processinfo, errors: errors });
	};

	ChangeCheckbox = (event) => {
		const target = event.target;
		let { processinfo } = this.state;
		processinfo["is_client_process"] = target.checked ? 1 : 0;
		this.setState({ processinfo: processinfo });
	};

	validForm = () => {
		let { processinfo } = this.state;
		let formIsValid = true,
			errors = {};
		if (!processinfo["name"]) {
			errors["name"] = this.props.t("PROCESS.VALIDATION_ERROR.REQUIRED");
			formIsValid = false;
		}
		if (!processinfo["country_id"]) {
			errors["country_id"] = this.props.t(
				"PROCESS.VALIDATION_ERROR.REQUIRED"
			);
			formIsValid = false;
		}
		if (!processinfo["business_type_id"]) {
			errors["business_type_id"] = this.props.t(
				"PROCESS.VALIDATION_ERROR.REQUIRED"
			);
			formIsValid = false;
		}
		if (!processinfo["domain_id"] || !processinfo["domain_id"].length) {
			errors["domain_ids"] = this.props.t(
				"PROCESS.VALIDATION_ERROR.REQUIRED"
			);
			formIsValid = false;
		}
		if (
			!processinfo["lead_type_id"] ||
			!processinfo["lead_type_id"].length
		) {
			errors["lead_type_ids"] = this.props.t(
				"PROCESS.VALIDATION_ERROR.REQUIRED"
			);
			formIsValid = false;
		}

		this.setState({ errors: errors });
		return formIsValid;
	};

	saveProcess = (event) => {
		event.preventDefault();
		let { processinfo, process_id } = this.state;
		if (process_id) processinfo["id"] = process_id;
		if (this.validForm()) {
			processinfo["domain_ids"] = processinfo.domain_id.map((v) => v.id);
			processinfo["lead_type_ids"] = processinfo.lead_type_id.map(
				(v) => v.id
			);
			//processinfo["status"] = "1";
			processinfo["is_client_process"] = processinfo.is_client_process
				? 1
				: 0;
			this.setState({ loading: true });
			webFormService
				.saveProcess(processinfo)
				.then((response) => {
					if (
						response.status === 200 &&
						response.data.status === 200
					) {
						toast.success(response.data.message);
						this.setState({
							processinfo: processinfo,
							loading: false,
							redirectTolist: true,
						});
					} else {
						this.setState({ loading: false });
					}
				})
				.catch((error) => {
					toast.error(error);
				});
		}
	};

	handleRedirect = () => {
		this.setState({ redirectTolist: true });
	};

	render() {
		const {
			loading,
			processinfo,
			country_list,
			process_id,
			business_type_list,
			lead_type_list,
			domain_list,
			errors,
			redirectTolist,
		} = this.state;
		let filtered_domain_list = processinfo.business_type_id
			? domain_list.filter(
					(v) =>
						Number(v.business_type_id) ===
						Number(processinfo.business_type_id)
			  )
			: [];
		if (redirectTolist) return <Redirect to="/process" />;
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-6 col-sm-offset-3">
						<h1>
							{process_id
								? this.props.t("PROCESS.UPDATE_PROCESS") +
								  ` #${process_id}`
								: this.props.t("PROCESS.ADD_PROCESS")}
						</h1>
						<div className="card">
							{loading && (
								<PageLoader loaderClass="text-center" />
							)}
							<div className="card-body">
								<div className="row">
									<form onSubmit={this.saveProcess}>
										<div className="col-sm-6 col-md-6 form-group">
											<label>
												{this.props.t(
													"PROCESS.COUNTRY"
												)}
											</label>

											<Select
												id="country_id"
												onChange={this.handleChange.bind(
													this,
													"country_id"
												)}
												options={country_list}
												name="country_id"
												placeholder={this.props.t(
													"PROCESS.COUNTRY"
												)}
												value={country_list.filter(
													({ value }) =>
														value ===
														processinfo.country_id
												)}
												getOptionLabel={({ label }) =>
													label
												}
												getOptionValue={({ value }) =>
													value
												}
											/>
											<span className="text-danger">
												{errors.country_id || ""}
											</span>
										</div>
										<div className="col-sm-6 col-md-6 form-group">
											<label>
												{this.props.t(
													"PROCESS.BUSINESS_TYPE"
												)}
											</label>

											<Select
												id="business_type_id"
												onChange={this.handleChange.bind(
													this,
													"business_type_id"
												)}
												options={business_type_list}
												name="business_type_id"
												placeholder={this.props.t(
													"PROCESS.BUSINESS_TYPE"
												)}
												value={business_type_list.filter(
													({ business_type_id }) =>
														business_type_id ===
														processinfo.business_type_id
												)}
												getOptionLabel={({ name }) =>
													name
												}
												getOptionValue={({
													business_type_id,
												}) => business_type_id}
											/>
											<span className="text-danger">
												{errors.business_type_id || ""}
											</span>
										</div>

										<div className="col-sm-6 col-md-6">
											<InputField
												inputProps={{
													id: "name",
													type: "text",
													value:
														processinfo.name || "",
													name: "name",
													autoComplete: "off",
													placeholder: this.props.t(
														"PROCESS.NAME"
													),
													label: this.props.t(
														"PROCESS.NAME"
													),
													dataerror:
														errors.name || "",
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"name"
												)}
											/>
										</div>

										<div className="col-sm-6 col-md-6 form-group">
											<label>
												{this.props.t("PROCESS.DOMAIN")}
											</label>
											<ReactMultiSelectCheckboxes
												isMulti
												placeholderButtonLabel={this.props.t(
													"PROCESS.DOMAIN"
												)}
												getDropdownButtonLabel={({
													placeholderButtonLabel,
													value,
												}) =>
													value && value.length > 0
														? value.length +
														  " " +
														  this.props.t(
																"PROCESS.SELECTED"
														  )
														: placeholderButtonLabel
												}
												defaultValue={
													processinfo &&
													filtered_domain_list.length &&
													processinfo.domain_id &&
													processinfo.domain_id.length
														? processinfo.domain_id
														: []
												}
												value={
													processinfo &&
													filtered_domain_list.length &&
													processinfo.domain_id &&
													processinfo.domain_id.length
														? processinfo.domain_id
														: []
												}
												onChange={this.handleChangeMultiSel.bind(
													this,
													"domain_id"
												)}
												options={filtered_domain_list}
												getOptionLabel={({ name }) =>
													name
												}
												getOptionValue={({ id }) => id}
											/>

											<span className="text-danger">
												{errors.domain_ids || ""}
											</span>
										</div>
										<div className="col-sm-6 col-md-6 form-group">
											<label>
												{this.props.t(
													"PROCESS.LEAD_TYPE"
												)}
											</label>
											<ReactMultiSelectCheckboxes
												isMulti
												placeholderButtonLabel={this.props.t(
													"PROCESS.LEAD_TYPE"
												)}
												getDropdownButtonLabel={({
													placeholderButtonLabel,
													value,
												}) =>
													value && value.length > 0
														? value.length +
														  " " +
														  this.props.t(
																"PROCESS.SELECTED"
														  )
														: placeholderButtonLabel
												}
												defaultValue={
													processinfo.lead_type_id ||
													[]
												}
												value={
													processinfo.lead_type_id ||
													[]
												}
												onChange={this.handleChangeMultiSel.bind(
													this,
													"lead_type_id"
												)}
												options={lead_type_list}
												getOptionLabel={({ name }) =>
													name
												}
												getOptionValue={({ id }) => id}
											/>

											<span className="text-danger">
												{errors.lead_type_ids || ""}
											</span>
										</div>
										<div className="col-sm-12 form-group">
											<CheckBox
												label={this.props.t(
													"PROCESS.IS_CLIENT_PROCESS"
												)}
												name="is_client_process"
												type="checkbox"
												id="is_client_process"
												checked={
													processinfo.is_client_process ||
													0
												}
												value="1"
												onChange={this.ChangeCheckbox}
											/>
										</div>

										<div className="col-sm-12 col-md-12 form-group text-right">
											<button
												type="button"
												className="btn btn-default mrg-r15"
												onClick={this.handleRedirect}
											>
												{this.props.t("PROCESS.CANCEL")}
											</button>
											<button
												type="submit"
												className="btn btn-primary"
											>
												{process_id
													? this.props.t(
															"PROCESS.UPDATE"
													  )
													: this.props.t(
															"PROCESS.CREATE"
													  )}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation("webfrom")(EditProcess);
