import React, { Component } from "react";
import InputField from "../../../elements/InputField";
import { withTranslation } from "react-i18next";

class SearchLeadType extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterData: this.props.filterData || {},
		};
	}

	static getDerivedStateFromProps(props, state) {
		let updateState = {};
		updateState["filterData"] = props.filterData;
		return updateState;
	}

	submitFilterForm = (event) => {
		event.preventDefault();
		if (typeof this.props.onSubmitFilter === "function") {
			let { filterData } = this.state;
			this.props.onSubmitFilter(filterData);
		}
	};

	ResetFilterForm = (event) => {
		event.preventDefault();
		let filterData = { page: 1 };
		this.props.onSubmitFilter(filterData, {});
	};

	handleChange = (sname, valueObj) => {
		let { filterData } = this.state;
		let value = valueObj.value || valueObj.business_type_id;
		if (sname === "name") value = valueObj.target.value;
		filterData[sname] = value;
		this.setState({ filterData: filterData });
	};

	render() {
		const { filterData } = this.state;

		return (
			<div className="search-wrap">
				<form onSubmit={this.submitFilterForm}>
					<ul className="search-flex">
						<li className="searchitems">
							<InputField
								inputProps={{
									id: "name",
									type: "text",
									value: filterData.name || "",
									name: "name",
									autoComplete: "off",
									placeholder: this.props.t(
										"LEAD_TYPE_LIST.NAME"
									),
									label: this.props.t("LEAD_TYPE_LIST.NAME"),
								}}
								autoComplete="off"
								onChange={this.handleChange.bind(this, "name")}
							/>
						</li>

						<li className="searchitems form-group">
							<div>
								<button
									type="submit"
									className="btn btn-primary"
								>
									{this.props.t("LEAD_TYPE_LIST.SEARCH")}
								</button>
							</div>
						</li>

						<li className="searchitems form-group">
							<button
								type="reset"
								className="btn btn-default btn-reset"
								onClick={this.ResetFilterForm}
							>
								{this.props.t("LEAD_TYPE_LIST.RESET")}
							</button>
						</li>
					</ul>
				</form>
			</div>
		);
	}
}

export default withTranslation("webfrom")(SearchLeadType);
