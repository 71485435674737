import React, { Component } from "react";
import TestDriveForm from "./TestDriveForm";
import DealerVisit from "./DealerVisit";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { LeadActions, ConfigAction } from "./../../../store/action";
import { bindActionCreators } from "redux";
class TabTestDriveDealer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showTab: "1",
			subLeadInfo: this.props.subLeadInfo,
			leadId: 0,
			subListDetails: {},
			tabDetails: { "TAB-1": true, "TAB-2": false },
		};
	}

	componentDidMount = async () => {
		await this.getSubLeadDetails();
	};

	static getDerivedStateFromProps = (nextProps, prevState) => {
		let updatedStateData = {};
		if (nextProps.subLeadInfo !== prevState.subLeadInfo) {
			updatedStateData["subLeadInfo"] = nextProps.subLeadInfo;
		}

		return updatedStateData;
	};

	getSubLeadDetails = async () => {
		const requestBody = {
			id: this.state.subLeadInfo.sub_lead_id,
			lead_id: this.state.subLeadInfo.lead_id,
		};
		let subListDetails = await this.props.actions.getSubLeadDetails(
			requestBody
		);
		if (subListDetails) this.setState({ subListDetails: subListDetails });
	};

	getStatusList = async () => {
		await this.props.actions.getStatus();
	};

	handleChange = (sname, ovalue) => {
		this.setState({
			[sname]: ovalue.value,
		});
	};

	handleTabChange = (index) => {
		let { tabDetails } = this.state;
		if (index === 1) {
			tabDetails["TAB-1"] = !tabDetails["TAB-1"];
			tabDetails["TAB-2"] = !tabDetails["TAB-1"];
		} else if (index === 2) {
			tabDetails["TAB-2"] = !tabDetails["TAB-2"];
			tabDetails["TAB-1"] = !tabDetails["TAB-1"];
		}

		this.setState({ tabDetails });
	};

	closeModal = () => {
		if (typeof this.props.closeModal === "function")
			this.props.closeModal();
	};

	render() {
		const { tabDetails } = this.state;
		return (
			<div className="pad-15 pad-t0">
				<div className="card-heading mrg-b30">
					<ul className="nav-tabs modal-tabs mrg-b15" role="tablist">
						<li
							className={
								tabDetails["TAB-1"]
									? "nav-item active "
									: "nav-item "
							}
							onClick={() => this.handleTabChange(1)}
						>
							<div>
								{this.props.t(
									"TEST_DRIVE.TAB_HEADING.TEST_DRIVE"
								)}
							</div>
						</li>
						<li
							className={
								tabDetails["TAB-2"]
									? "nav-item active "
									: "nav-item "
							}
							onClick={() => this.handleTabChange(2)}
						>
							<div>
								{this.props.t(
									"TEST_DRIVE.TAB_HEADING.DEALER_VISIT"
								)}{" "}
							</div>
						</li>
					</ul>
				</div>
				<div>
					<div className="tab-content">
						<div
							className={
								"tab-pane " +
								(tabDetails["TAB-1"] ? "active" : "")
							}
						>
							<TestDriveForm
								subLeadDetails={this.state.subListDetails}
								closeModal={this.closeModal}
							/>
						</div>
						<div
							className={
								"tab-pane " +
								(tabDetails["TAB-2"] ? "active" : "")
							}
						>
							<DealerVisit
								subLeadDetails={this.state.subListDetails}
								closeModal={this.closeModal}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ config }) => {
	return {
		statusList: config.statusList,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getSubLeadDetails: bindActionCreators(
				LeadActions.getSubLeadDetails,
				dispatch
			),
			getStatus: bindActionCreators(ConfigAction.getStatus, dispatch),
		},
	};
};
export default withTranslation("lead_detail")(
	connect(mapStateToProps, mapDispatchToProps)(TabTestDriveDealer)
);
