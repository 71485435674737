import React from "react";
import { connect } from "react-redux";
import { AuthActions } from "../../store/action";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import secureStorage from "../../config/encrypt";

class Logout extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			id: secureStorage.getItem("authUserInfo") || "",
		};
	}

	componentDidMount = () => {
		this.authLogout();
	};

	authLogout = () => {
		this.props.actions.authLogout();
	};

	render() {
		const { loggedOut } = this.props;
		// alert('loggedOut ='+loggedOut);
		if (typeof loggedOut !== "undefined") {
			return <Redirect to="/login" />;
		}

		return "";
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth.auth,
		loggedOut: state.auth.loggedOut,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			authLogout: bindActionCreators(AuthActions.logout, dispatch),
		},
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));
