/* eslint-disable */
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AuthActions, LeadActions } from "../../store/action";
import { withTranslation } from "react-i18next";
import secureStorage from "./../../config/encrypt";
import { default as config } from "./../../config/config";
// import ModalPopup from '../elements/Popup';
import * as constant from "./../../config/constant";
import { toast } from "react-toastify";
import ModalPopup from "../elements/Popup";
import AllDialerPush from "./AllDialerPush";
class Navbar extends Component {
	constructor(props) {
		// if(!secureStorage.getItem('authUserInfo'))
		//     window.location = '/login';
		super(props);
		this.state = {
			langOption: [],
			dialerPopOver: false,
			dialerPopUpModal: false,
		};
	}

	componentDidMount = () => {
		let langData = config.constants.language;
		secureStorage.setItem("langOption", langData);
		this.setState({ langOption: langData });
	};

	/**
	 * Logout
	 */
	authLogout = (e) => {
		e.preventDefault();
		this.props.actions.authLogout();
		window.location = "/login";
	};

	/**
	 * Active Nav on route change
	 */
	checkActiveBtn = (routeArray) => {
		let curPath = window.location.pathname.replace(/\//g, "");
		return routeArray.includes(curPath);
	};

	getCurrentClass = (menuData) => {
		let currentPath = window.location.pathname;
		let activeClass = "";
		Object.keys(menuData).forEach((el) => {
			if (typeof menuData[el] === "object") {
				Object.keys(menuData[el]).forEach((elm) => {
					if (elm === currentPath) {
						activeClass = "active";
						return;
					}
				});
			} else {
				if (el === currentPath) {
					activeClass = "active";
					return;
				}
			}
		});

		return activeClass;
	};

	handleAddToDialer = async (postData) => {
		let result = await this.props.actions.pushedToDailer({
			...postData,
		});
		if (result.status === 200) {
			toast.success(result.message);
			this.setState({ dialerPopUpModal: false });
		}
	};
	handleDisplayPopOver = async () => {
		this.setState({ dialerPopOver: true });
	};
	confirmNo = () => {
		this.setState({ dialerPopOver: false });
	};
	confirmPushToDialer = (e) => {
		e.preventDefault();
		this.setState({ dialerPopUpModal: true });
		// const response = window.confirm(this.props.t("MENU.ALERT_ADD_DIALER"));
		// if (response == true) {
		// 	// console.log("Confirm push to dialer");
		// 	this.handleAddToDialer();
		// }
	};
	closeEditModal = () => {
		this.setState({ dialerPopUpModal: false });
	};
	render() {
		const { loggedIn, authUserInfo, loggingIn, i18n } = this.props;
		const setLang = secureStorage.getItem("lang") || "en";
		const langOption = this.state.langOption;
		const navigation = [
			{
				index: "1",
				navlabel: this.props.t("MENU.MANAGE_PROCESS"),
				url: "/process",
				controller: "process",
			},
			{
				index: "2",
				navlabel: this.props.t("MENU.MANAGE_CAMPAIGN"),
				url: "/campaigns",
				controller: "campaign",
			},
			{
				index: "3",
				navlabel: this.props.t("MENU.WEB_RULE"),
				url: "/process-campaign-list",
				controller: "processCampaign",
			},
			{
				index: "4",
				navlabel: this.props.t("MENU.LEAD_TYPE"),
				url: "/lead-type",
				controller: "leadType",
			},
			{
				index: "5",
				navlabel: this.props.t("MENU.MANAGE_DOMAINS"),
				url: "/domains",
				controller: "domain",
			},
			{
				index: "6",
				navlabel: this.props.t("MENU.MANAGE_WEBIDS"),
				url: "/webids",
				controller: "webids",
			},
			{
				index: "7",
				navlabel: this.props.t("MENU.BLACK_LIST"),
				url: "/black-list",
				controller: "blackList",
			},
			{
				index: "8",
				navlabel: this.props.t("MENU.UPLOAD_LEADS"),
				url: "/lead_upload",
				controller: "bulkUpload",
			},
			{
				index: "9",
				navlabel: this.props.t("MENU.UPDATE_LEADS"),
				url: "/update_lead",
				controller: "bulkUpload",
			},
		];
		let site_settings = secureStorage.getItem("site_settings") || {};
		let menu_denied =
			site_settings && site_settings.menu_denied
				? site_settings.menu_denied
				: [];
		return (
			<>
				<nav className={this.props.navClass}>
					<ul className="navbar-nav">
						{menu_denied &&
						!menu_denied.includes("/lead-list") &&
						constant.isUserHasRightsToAccessPage(
							"dashboard",
							"dashboard",
							""
						) ? (
							<li>
								<NavLink to="/lead-list" exact>
									{this.props.t("MENU.DASHBOARD")}
								</NavLink>
							</li>
						) : (
							<li>
								<NavLink to="/dashboard" exact>
									{this.props.t("MENU.DASHBOARD")}
								</NavLink>
							</li>
						)}
						{menu_denied &&
						!menu_denied.includes("/audit_list") &&
						constant.isUserHasRightsToAccessPage(
							"audit",
							"audit",
							"/audit_list"
						) ? (
						<li>
							<NavLink
								to="/audit_list"
								isActive={(matched, location) =>
									location.pathname === "/audit_list"
										? true
										: false
								}
							>
								{this.props.t("MENU.AUDIT_DASHBOARD")}
							</NavLink>
						</li>
						) : null}

						{!menu_denied.includes("webform") &&
						constant.isUserHasRightsToAccessPage(
							"webform",
							"webform",
							""
						) ? (
							<li
								className="dropdown"
								// title={this.props.t("MENU.RULE_CONFIGURATION")}
							>
								<NavLink
									to="#"
									isActive={(matched, location) =>
										navigation.filter(
											(v) => v.url === location.pathname
										).length
											? true
											: false
									}
								>
									{this.props.t("MENU.RULE_CONFIGURATION")}
									<span className="caret"></span>
								</NavLink>
								<ul className="dropdown-menu">
									{navigation.map((curr, index) =>
										!menu_denied.includes(curr.url) &&
										constant.isUserHasRightsToAccessPage(
											"webform",
											curr.controller,
											curr.url
										) ? (
											<li key={curr.index}>
												<NavLink
													aria-current="page"
													to={curr.url}
													key={curr.index}
												>
													{curr.navlabel}
												</NavLink>
											</li>
										) : null
									)}
								</ul>
							</li>
						) : null}

						{constant.isUserHasRightsToAccessPage(
							"report",
							"report",
							""
						) ? (
							<li className="dropdown">
								<NavLink
									to="#"
									isActive={(matched, location) =>
										[
											"/ops_report",
											"/cross_sell",
											"/sales_report",
											"/performance_report",
											"/dialer_report",
											"/lead_report",
											"/agent_performance_report",
										].filter((v) => v === location.pathname)
											.length
											? true
											: false
									}
								>
									{this.props.t("MENU.REPORT")}
									<span className="caret"></span>
								</NavLink>
								<ul className="dropdown-menu">
									{constant.isUserHasRightsToAccessPage(
										"report",
										"business",
										""
									) ? (
										<li className="level2">
											<a href="#">
												{this.props.t("MENU.BUSINESS")}
											</a>
											<ul className="dropdown-menu">
												{menu_denied &&
												!menu_denied.includes(
													"/ops_report"
												) &&
												constant.isUserHasRightsToAccessPage(
													"report",
													"opsReport",
													"/ops_report"
												) ? (
													<li>
														<NavLink
															to="/ops_report"
															isActive={(
																matched,
																location
															) =>
																location.pathname ===
																"ops_report"
																	? true
																	: false
															}
														>
															{this.props.t(
																"MENU.OPS_REPORT"
															)}
														</NavLink>
													</li>
												) : null}
												{menu_denied &&
												!menu_denied.includes(
													"/performance_report"
												) &&
												constant.isUserHasRightsToAccessPage(
													"report",
													"performanceReport",
													"/performance_report"
												) ? (
													<li>
														<NavLink
															to="/performance_report"
															isActive={(
																matched,
																location
															) =>
																location.pathname ===
																"performance_report"
																	? true
																	: false
															}
														>
															{this.props.t(
																"MENU.PERFORMANCE_REPORT"
															)}
														</NavLink>
													</li>
												) : null}
												{menu_denied &&
												!menu_denied.includes(
													"/sales_report"
												) &&
												constant.isUserHasRightsToAccessPage(
													"report",
													"salesReport",
													"/sales_report"
												) ? (
													<li>
														<NavLink
															to="/sales_report"
															isActive={(
																matched,
																location
															) =>
																location.pathname ===
																"sales_report"
																	? true
																	: false
															}
														>
															{this.props.t(
																"MENU.SALES_REPORT"
															)}
														</NavLink>
													</li>
												) : null}
												{menu_denied &&
												!menu_denied.includes(
													"/cross_sell"
												) &&
												constant.isUserHasRightsToAccessPage(
													"report",
													"crossSellReport",
													"/cross_sell"
												) ? (
													<li>
														<NavLink
															to="/cross_sell"
															isActive={(
																matched,
																location
															) =>
																location.pathname ===
																"cross_sell"
																	? true
																	: false
															}
														>
															{this.props.t(
																"MENU.CROSS_SELL"
															)}
														</NavLink>
													</li>
												) : null}
												{menu_denied &&
												!menu_denied.includes(
													"/dialer_report"
												) &&
												constant.isUserHasRightsToAccessPage(
													"report",
													"dialerReport",
													"/dialer_report"
												) ? (
													<li>
														<NavLink
															to="/dialer_report"
															isActive={(
																matched,
																location
															) =>
																location.pathname ===
																"dialer_report"
																	? true
																	: false
															}
														>
															{this.props.t(
																"MENU.DIALER_REPORT"
															)}
														</NavLink>
													</li>
												) : null}

												{menu_denied &&
												!menu_denied.includes(
													"/agent_performance_report"
												) &&
												constant.isUserHasRightsToAccessPage(
													"report",
													"AgentPerformanceReport",
													"/agent_performance_report"
												) ? (
													<li>
														<NavLink
															to="/agent_performance_report"
															isActive={(
																matched,
																location
															) =>
																location.pathname ===
																"agent_performance_report"
																	? true
																	: false
															}
														>
															{this.props.t(
																"MENU.AGENT_PERFORMANCE_REPORT"
															)}
														</NavLink>
													</li>
												) : null}
											</ul>
										</li>
									) : null}
									{constant.isUserHasRightsToAccessPage(
										"report",
										"leads",
										""
									) ? (
										<li className="level2">
											<a href="#">
												{this.props.t("MENU.LEADS")}
											</a>
											<ul className="dropdown-menu">
												{menu_denied &&
												!menu_denied.includes(
													"/lead_report"
												) &&
												constant.isUserHasRightsToAccessPage(
													"report",
													"assignCarReport",
													"/lead_report"
												) ? (
													<li>
														<NavLink
															to="/lead_report"
															isActive={(
																matched,
																location
															) =>
																location.pathname ===
																"lead_report"
																	? true
																	: false
															}
														>
															{this.props.t(
																"MENU.ASSIGN_CAR_REPORT"
															)}
														</NavLink>
													</li>
												) : null}
												{menu_denied &&
												!menu_denied.includes(
													"/assigned_lead_summary"
												) &&
												constant.isUserHasRightsToAccessPage(
													"report",
													"MyActiveReport",
													"/assigned_lead_summary"
												) ? (
													<li>
														<NavLink
															to="/assigned_lead_summary"
															isActive={(
																matched,
																location
															) =>
																location.pathname ===
																"assigned_lead_summary"
																	? true
																	: false
															}
														>
															{this.props.t(
																"MENU.ASSIGNED_LEAD_SUMMARY"
															)}
														</NavLink>
													</li>
												) : null}
											</ul>
										</li>
									) : null}
									{constant.isUserHasRightsToAccessPage(
										"report",
										"retailDashboard",
										""
									) ? (
										<li className="level2">
											<a href="#">
												{this.props.t(
													"MENU.RETAIL_DASHBOARD"
												)}
											</a>
											<ul className="dropdown-menu">
												{menu_denied &&
												!menu_denied.includes(
													"/retail_report"
												) &&
												constant.isUserHasRightsToAccessPage(
													"report",
													"retailReport",
													"/retail_report"
												) ? (
													<li>
														<NavLink
															to="/retail_report"
															isActive={(
																matched,
																location
															) =>
																location.pathname ===
																"retail_report"
																	? true
																	: false
															}
														>
															{this.props.t(
																"MENU.RETAIL_REPORT"
															)}
														</NavLink>
													</li>
												) : null}
												{menu_denied &&
												!menu_denied.includes(
													"/retail_create_report"
												) &&
												constant.isUserHasRightsToAccessPage(
													"report",
													"retailCreateReport",
													"/retail_create_report"
												) ? (
													<li>
														<NavLink
															to="/retail_create_report"
															isActive={(
																matched,
																location
															) =>
																location.pathname ===
																"retail_create_report"
																	? true
																	: false
															}
														>
															{this.props.t(
																"MENU.RETAIL_CREATE_REPORT"
															)}
														</NavLink>
													</li>
												) : null}
												{menu_denied &&
												!menu_denied.includes(
													"/retail_update_report"
												) &&
												constant.isUserHasRightsToAccessPage(
													"report",
													"retailUpdateReport",
													"/retail_update_report"
												) ? (
													<li>
														<NavLink
															to="/retail_update_report"
															isActive={(
																matched,
																location
															) =>
																location.pathname ===
																"retail_update_report"
																	? true
																	: false
															}
														>
															{this.props.t(
																"MENU.RETAIL_UPDATE_REPORT"
															)}
														</NavLink>
													</li>
												) : null}
												{menu_denied &&
												!menu_denied.includes(
													"/retail_update_report"
												) &&
												constant.isUserHasRightsToAccessPage(
													"report",
													"retailProcessWiseSummary",
													"/retail_process_summary"
												) ? (
													<li>
														<NavLink
															to="/retail_process_summary"
															isActive={(
																matched,
																location
															) =>
																location.pathname ===
																"retail_process_summary"
																	? true
																	: false
															}
														>
															{this.props.t(
																"MENU.RETAIL_PROCESS_SUMMARY"
															)}
														</NavLink>
													</li>
												) : null}
											</ul>
										</li>
									) : null}
								</ul>
							</li>
						) : null}

						<li className="dropdown">
							{constant.isUserHasRightsToAccessPage(
								"admin",
								"admin",
								""
							) ? (
								<NavLink
									to="#"
									isActive={(matched, location) =>
										[
											"push_to_dailer",
											"/campaign_configurator",
											"/update_lead_status",
											"/update_lead",
										].filter((v) => v === location.pathname)
											.length
											? true
											: false
									}
								>
									{this.props.t("MENU.ADMIN")}
									<span className="caret"></span>
								</NavLink>
							) : null}
							<ul className="dropdown-menu">
								{!menu_denied.includes("/push_to_dailer") &&
								constant.isUserHasRightsToAccessPage(
									"report",
									"pushtodialerreport",
									"/push_to_dailer"
								) ? (
									<li>
										<NavLink
											to="#"
											isActive={(matched, location) =>
												location.pathname ===
												"push_to_dailer"
													? true
													: false
											}
											// onClick={() => this.handleAddToDialer()}
											onClick={this.confirmPushToDialer.bind(
												this
											)}
										>
											<i className="ic-call-24px mrg-r5"></i>
											{this.props.t(
												"MENU.PUSHED_TO_DAILER"
											)}
										</NavLink>
									</li>
								) : null}
								{!menu_denied.includes(
									"/campaign_configurator"
								) &&
								constant.isUserHasRightsToAccessPage(
									"report",
									"campaignPack",
									"/campaign_configurator"
								) ? (
									<li>
										<NavLink
											to="/campaign_configurator"
											isActive={(matched, location) =>
												location.pathname ===
												"campaign_configurator"
													? true
													: false
											}
										>
											{this.props.t("MENU.CAMPAIGN_PACK")}
										</NavLink>
									</li>
								) : null}

								{/* {constant.isUserHasRightsToAccessPage(
									"report",
									"uploadlead",
									"/update_lead"
								) ? (
									<li>
										<NavLink
											to="/update_lead"
											isActive={(matched, location) =>
												location.pathname ===
												"update_lead"
													? true
													: false
											}
										>
											{this.props.t(
												"MENU.UPLOAD_FORD_LEADS"
											)}
										</NavLink>
									</li>
								) : null} */}

								{constant.isUserHasRightsToAccessPage(
									"report",
									"updateStatus",
									"/update_lead_status"
								) ? (
									<li>
										<NavLink
											to="/update_lead_status"
											isActive={(matched, location) =>
												location.pathname ===
												"update_lead_status"
													? true
													: false
											}
										>
											{this.props.t(
												"MENU.LEAD_STATUS_BULK_UPDATE"
											)}
										</NavLink>
									</li>
								) : null}

								{constant.isUserHasRightsToAccessPage(
									"report",
									"updateSpkdate",
									"/update_spk_date"
								) ? (
									<li>
										<NavLink
											to="/update_spk_date"
											isActive={(matched, location) =>
												location.pathname ===
												"update_spk_date"
													? true
													: false
											}
										>
											{this.props.t(
												"MENU.LEAD_BULK_SPK_DATE_UPDATE"
											)}
										</NavLink>
									</li>
								) : null}

								{/* {!menu_denied.includes(
									"/assigned_lead_summary"
								) &&
								constant.isUserHasRightsToAccessPage(
									"report",
									"uploadlead",
									"/update_lead"
								) ? (
									<li>
										<NavLink
											to="/update_lead"
											isActive={(matched, location) =>
												location.pathname ===
												"update_lead"
													? true
													: false
											}
										>
											{this.props.t(
												"MENU.DIALER_DATA_MIGRATION"
											)}
										</NavLink>
									</li>
								) : null} */}
								{constant.isUserHasRightsToAccessPage(
									"audit",
									"tieupBrand",
									"/tie-up-brand"
								) ? (
									<li>
										<NavLink
											to="/tie-up-brand"
											isActive={(matched, location) =>
												location.pathname ===
												"tie-up-brand"
													? true
													: false
											}
										>
											{this.props.t(
												"MENU.TIE_UP_BRAND"
											)}
										</NavLink>
									</li>
								) : null}

								{constant.isUserHasRightsToAccessPage(
									"admin",
									"rolePrivilege",
									"/user-list"
								) ? (
									<li>
										<NavLink
											to="/user-list"
											isActive={(matched, location) =>
												location.pathname ===
												"user-list"
													? true
													: false
											}
										>
											{this.props.t(
												"MENU.USER_LIST"
											)}
										</NavLink>
									</li>
								) : null}
							</ul>
						</li>

						{!menu_denied.includes("ford") &&
						constant.isUserHasRightsToAccessPage(
							"ford",
							"ford",
							""
						) ? (
							<li className="dropdown">
								<NavLink
									to="#"
									isActive={(matched, location) =>
										navigation.filter(
											(v) => v.url === location.pathname
										).length
											? true
											: false
									}
								>
									{this.props.t("MENU.FORD_REPORT")}
									<span className="caret"></span>
								</NavLink>
								<ul className="dropdown-menu">
									{!menu_denied.includes(
										"/city_dealer_mapping"
									) &&
									constant.isUserHasRightsToAccessPage(
										"ford",
										"ford",
										"/city_dealer_mapping"
									) ? (
										<li>
											<NavLink
												to="/city_dealer_mapping"
												exact
											>
												{this.props.t(
													"MENU.FORD_LEADS"
												)}
											</NavLink>
										</li>
									) : (
										""
									)}

									{!menu_denied.includes("/sm_list") &&
									constant.isUserHasRightsToAccessPage(
										"ford",
										"SmList",
										"/sm_list"
									) ? (
										<li>
											<NavLink to="/sm_list" exact>
												{this.props.t(
													"MENU.FORD_SM_LIST"
												)}
											</NavLink>
										</li>
									) : (
										""
									)}

									{!menu_denied.includes("/dealer_list") &&
									constant.isUserHasRightsToAccessPage(
										"ford",
										"DealerList",
										"/dealer_list"
									) ? (
										<li>
											<NavLink to="/dealer_list" exact>
												{this.props.t(
													"MENU.FORD_DEALER_LIST"
												)}
											</NavLink>
										</li>
									) : (
										""
									)}

									{!menu_denied.includes(
										"/update_ford_lead_csv"
									) &&
									constant.isUserHasRightsToAccessPage(
										"ford",
										"UpdateFordLead",
										"/update_ford_lead_csv"
									) ? (
										<li>
											<NavLink
												to="/update_ford_lead_csv"
												exact
											>
												{this.props.t(
													"MENU.FORD_UPDATE_FORD_LEAD"
												)}
											</NavLink>
										</li>
									) : (
										""
									)}
								</ul>
							</li>
						) : null}

						{loggedIn || loggingIn ? (
							<li
								className="dropdown myac"
								title={
									authUserInfo.authUser.name +
									" (GCD69) | Co-Owner"
								}
							>
								<NavLink
									to="#"
									isActive={(matched, location) =>
										location.pathname === "logout"
											? true
											: false
									}
								>
									<span className="userac">
										<i className="ic-person"></i>
									</span>
									{this.props.t("MENU.HI")},{" "}
									{authUserInfo.authUser.name}
									<span className="caret"></span>
								</NavLink>
								<ul className="dropdown-menu">
									{/* <li><NavLink to="/my-account/basic-details">{this.props.t('MENU.MYACCOUNT')}</NavLink></li> */}
									<li>
										<NavLink
											aria-current="page"
											to=""
											onClick={this.authLogout}
										>
											{this.props.t("MENU.LOGOUT")}
										</NavLink>
									</li>
								</ul>
							</li>
						) : (
							""
						)}

						<li>
							<div className="language-change">
								{langOption.map((lang, index) => (
									<button
										key={index}
										type="button"
										className={
											setLang === lang.iso_code
												? "btn btn-primary"
												: "btn btn-default"
										}
										onClick={() => {
											secureStorage.setItem(
												"lang",
												lang.iso_code
											);
											i18n.changeLanguage(lang.iso_code);
										}}
									>
										{lang.iso_code}
									</button>
								))}
							</div>
						</li>
					</ul>
				</nav>
				{this.state.dialerPopUpModal === false ? (
					""
				) : (
					<ModalPopup
						id="dialerpopup"
						className="modal "
						title="Push to Dialer"
						modalClose={this.closeEditModal}
					>
						<AllDialerPush
							closeModal={this.closeEditModal}
							handleAddToDialer={this.handleAddToDialer}
						/>
					</ModalPopup>
				)}
			</>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	authUserInfo: state.auth.authUser,
	loggedIn: state.auth.loggedIn,
	loggingIn: state.auth.loggingIn,
	redirectTo: state.auth.redirectTo,
	location: ownProps.location,
	userRights: state.auth.userAccess,
});

const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			authLogout: bindActionCreators(AuthActions.logout, dispatch),
			pushedToDailer: bindActionCreators(
				LeadActions.getLeadList,
				dispatch
			),
		},
	};
};
export default withTranslation("common")(
	connect(mapStateToProps, mapDispatchToProps)(Navbar)
);
