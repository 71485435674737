import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

class Comment extends Component {
	constructor(props) {
		super(props);
	}
	getActivityRelatedData(activityData) {
		let activity = activityData.activity_type;
		let activityQueue = activityData.comment || [];

		switch (activity) {
			case "dialer_push":
				return (
					<>
						{activityQueue.map((e, i) => (
							<div key={i}>
								<strong>
									{this.props.t(`HISTORY.TBL_DETAILS.REASON`)}{" "}
								</strong>
								{e.comment ? <span>{e.comment}</span> : ""}{" "}
							</div>
						))}
					</>
				);
			case "dialer_disposition":
				return <></>;
			default:
				return (
					<>
						{activityQueue.map((e, i) => (
							<div key={i}>
								{e.dse_name ? (
									<strong>{e.dse_name}</strong>
								) : (
									""
								)}{" "}
								{e.dse_name ? (
									`:${e.comment}`
								) : (
									<span>
										<strong>
											{activityData.activity_source &&
											activityData.activity_source ===
												"dialer"
												? this.props.t(
														`HISTORY.TBL_DETAILS.REASON`
												  )
												: ""}
										</strong>
										{e.comment}
									</span>
								)}
							</div>
						))}
					</>
				);
		}
	}
	render() {
		const { activityData } = this.props;

		return <>{this.getActivityRelatedData(activityData)}</>;
	}
}
export default withTranslation("lead_detail")(connect(null)(Comment));
