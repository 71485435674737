import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AuditService } from "../../../service/auditService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import PageLoader from "../../elements/PageLoader";
import { bindActionCreators } from "redux";
import { InventoryAction } from "./../../../store/action";
import { connect } from "react-redux";

class BrandTieUpList extends Component {
	constructor(props) {
		super(props);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.state = {
			loading: true,
			mmv: [],
			make: (this.props.mmv && this.props.mmv.make) || [],
			tiupBrandList: this.props.tiupBrandList || [],
			pagination: this.props.pagination || {},
			filterData: { page: 1 },
			actionType: "status",
		};
	}

	componentDidMount = async () => {
		await this.getBrandTieupList();
	};

	getBrandTieupList = async () => {
		let { filterData } = this.state;
		this.setState({ loading: true });
		AuditService.getTieUpBrand(filterData)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let tiupBrandList = response.data.data || [];
					let pagination = response.data.data.pagination || {};

					this.setState({
						tiupBrandList: tiupBrandList,
						pagination: pagination,
						loading: false,
					});
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	handlePageChange(pageNumber) {
		let { filterData } = this.state;
		filterData["page"] = pageNumber;
		this.setState({ filterData: filterData, loading: true });
		this.getBrandTieupList();
	}

	render() {
		const {
			loading,
			tiupBrandList,
			filterData,
			pagination,
			make,
		} = this.state;

		return (
			<div>
				<div className="container-fluid">
					<div className="result-wrap">
						<div className="row mrg-b15">
							<div className="col-sm-12 text-right">
								<Link
									to={"/add_tie_up_brand"}
									className="btn btn-success btn-sm"
									title={this.props.t("TIEUP_BRAND.ADD")}
								>
									{this.props.t("TIEUP_BRAND.ADD")}
								</Link>
							</div>
						</div>
						<div className="card">
							<div className="table-responsive">
								{loading && (
									<PageLoader loaderClass="text-center" />
								)}
								<table className="table table-bordered-h table-hover processTable">
									<thead>
										<tr>
											<th>
												{this.props.t(
													"TIEUP_BRAND.BRAND"
												)}
											</th>

											<th>
												{this.props.t(
													"TIEUP_BRAND.IS_OEM"
												)}
											</th>
											<th>
												{this.props.t(
													"TIEUP_BRAND.STATUS"
												)}
											</th>
										</tr>
									</thead>

									<tbody>
										{tiupBrandList.length > 0 ? (
											tiupBrandList.map(
												(singleinfo, index) => (
													<React.Fragment key={index}>
														<tr id={singleinfo.id}>
															<td>
																{
																	make
																		.filter(
																			(
																				data
																			) =>
																				data.id ===
																				singleinfo.make_id
																		)
																		.map(
																			(
																				v
																			) =>
																				v.make
																		)[0]
																}
															</td>

															<td>
																<div>
																	{singleinfo.is_oem ===
																	"1"
																		? this.props.t(
																				"TIEUP_BRAND.YES"
																		  )
																		: this.props.t(
																				"TIEUP_BRAND.NO"
																		  )}
																</div>
															</td>

															<td>
																<div>
																	{singleinfo.status ===
																	"1"
																		? this.props.t(
																				"TIEUP_BRAND.ACTIVE"
																		  )
																		: this.props.t(
																				"TIEUP_BRAND.INACTIVE"
																		  )}
																</div>
															</td>
														</tr>
													</React.Fragment>
												)
											)
										) : (
											<tr>
												<td colSpan="10">
													{!loading ? (
														<h6 className="text-danger">
															{this.props.t(
																"TIEUP_BRAND.NOT_FOUND"
															)}
														</h6>
													) : (
														""
													)}
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div className="clearfix">
								{pagination.totalpage >= 1 ? (
									<div className="main-div-pagination">
										<div className="col-sm-6 text-light">
											{this.props.t(
												"TIEUP_BRAND.SHOWING"
											)}{" "}
											{filterData.page}{" "}
											{this.props.t("TIEUP_BRAND.OF")}{" "}
											{pagination.totalpage}{" "}
											{this.props.t("TIEUP_BRAND.PAGES")}
										</div>
										<div className="col-sm-6">
											<Pagination
												activePage={filterData.page}
												itemsCountPerPage={
													pagination.limit
												}
												totalItemsCount={
													pagination.totalrecords
												}
												pageRangeDisplayed={5}
												itemClass="page-item"
												linkClass="page-link"
												onChange={this.handlePageChange}
											/>
										</div>
									</div>
								) : (
									""
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ config, inventory, lead, auth }) => {
	return {
		mmv: inventory.mmv,
		authUser: auth.authUser.authUser,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getMMV: bindActionCreators(InventoryAction.getMMV, dispatch),
			getCityState: bindActionCreators(
				InventoryAction.getCityState,
				dispatch
			),
		},
	};
};
export default withTranslation("audit")(
	connect(mapStateToProps, mapDispatchToProps)(BrandTieUpList)
);
