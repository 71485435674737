import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import { default as Config } from "../../../../config/config";
import Search from "./Search";
import ConfirmPopUp from "../../../common/ConfirmPopUp";
import SwitchBtn from "../../../elements/SwitchBtn";
import { UserActions, ConfigAction } from "../../../../store/action";
import dateformat from "dateformat";

class BlackList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			blackList: [],
			pagination: {},
			searchParams: {},
			reset: false,
			searchItems: {},
			PopOver_id: null,
			loading: false,
		};
	}
	componentDidMount = () => {
		this.getBlackList();
		// this.getConfigData();
	};
	getBlackList = (search, toReset, page) => {
		let { searchParams, reset } = this.state;
		if (search) {
			this.setState({ searchParams: search });
			searchParams = search;
			searchParams["page"] = 1;
		} else {
			searchParams["page"] = page;
		}
		if (toReset && toReset !== reset) {
			searchParams["page"] = 1;
			this.setState({ reset: true });
		} else if (reset) {
			this.setState({ reset: false });
		}
		this.props.actions.getBlackList(searchParams);
	};
	static getDerivedStateFromProps = (nextProps, prevProps) => {
		const updateProps = [];
		if (
			nextProps.blackList &&
			nextProps.blackList !== prevProps.blackList
		) {
			updateProps["blackList"] = nextProps.blackList;
		}
		if (nextProps.pagination !== prevProps.pagination) {
			updateProps["pagination"] = nextProps.pagination;
		}

		return updateProps;
	};
	handleEditAction = (id) => {
		window.open(`/edit-black-list?id=${id}`, "_blank");
	};
	handleAddItem = () => {
		window.location.href = "/edit-black-list";
	};
	handleChange = (sname, ovalue) => {
		const { target } = ovalue;
		const { searchItems } = this.state;
		if (target) {
			const { value } = target;
			searchItems[sname] = value;
		} else {
			searchItems[sname] = ovalue.value;
		}
		if (!searchItems[sname]) delete searchItems[sname];
		this.setState({ searchItems: searchItems });
	};
	submitAddRequest = (cancel, e) => {
		e.preventDefault();
		const { searchItems } = this.state;
		if (cancel) {
			delete searchItems["process_id"];
			this.setState({ addListRequest: false });
		}
		if (searchItems["process_id"]) {
			this.handleAddList(searchItems["process_id"]);
		}
		// this.setState({searchItems:searchItems})
	};
	confirmNo = () => {
		this.setState({ PopOver_id: null });
	};
	confirmYes = (data) => {
		data.status = data.status === "1" ? "0" : "1";
		data.updated_date = dateformat(Config.constants.DATE_FORMAT);
		this.setState({ loading: true });
		this.updateStatus(data);
	};
	updateStatus = async (data) => {
		const { status } = await this.props.actions.saveBlackList(data);
		if (status) {
			this.displayPopUp(null);
			this.setState({ loading: false });
		}
	};
	handlePageChange = (page) => {
		this.getBlackList(null, false, page);
	};
	displayPopUp = (list_id) => {
		this.setState({ PopOver_id: list_id });
	};
	render() {
		const { blackList, addBlackList, pagination, PopOver_id } = this.state;

		return (
			<div className="container-fluid">
				<div className="row">
					<div className="edit-list-card">
						<Search getBlackList={this.getBlackList} />
						<div className="container-fluid">
							<div className="result-wrap">
								<div className="row mrg-b15">
									<div className="col-sm-6">
										<p className="countNo mrg-t5 mrg-b0">
											{pagination &&
												` Total Records- ${pagination.total}`}
										</p>
									</div>
									<div className="col-sm-6 ">
										{!addBlackList && (
											<div className="text-right">
												{/* <input
													type="submit"
													value={this.props.t(
														"BLACK_LIST.ADD_ITEM"
													)}
													onClick={this.handleAddItem}
													className="btn btn-success btn-sm"
												/> */}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="card">
								<div className="table-responsive">
									<div>
										<table className="table table-bordered-h table-hover processTable">
											<thead>
												<tr>
													<th>
														{this.props.t(
															"BLACK_LIST.TBL_HEADING.MOBILE"
														)}
													</th>
													<th>
														{this.props.t(
															"BLACK_LIST.TBL_HEADING.AGENT_ID"
														)}
													</th>
													<th>
														{this.props.t(
															"BLACK_LIST.TBL_HEADING.LEAD_NAME"
														)}
													</th>
													<th>
														{this.props.t(
															"BLACK_LIST.TBL_HEADING.ACTIVE_STATUS"
														)}
													</th>
													<th>
														{this.props.t(
															"BLACK_LIST.TBL_HEADING.REASON"
														)}
													</th>
													<th>
														{this.props.t(
															"BLACK_LIST.TBL_HEADING.CREATED_DATE"
														)}
													</th>
													<th>
														{this.props.t(
															"BLACK_LIST.TBL_HEADING.UPDATED_DATE"
														)}
													</th>
													<th>
														{this.props.t(
															"BLACK_LIST.TBL_HEADING.ACTIONS"
														)}
													</th>
												</tr>
											</thead>
											<tbody>
												{blackList instanceof Array &&
													blackList.map((e, i) => (
														<tr key={i}>
															<td>
																{
																	e.mobile_number
																}
															</td>
															<td>
																{e.agent_name}
															</td>
															<td>
																{e.lead_name}
															</td>
															<td>
																{e.status_type}
															</td>
															<td>{e.reason}</td>
															<td>
																{
																	/* {moment(
																	e.created_date
																).format(
																	Config
																		.constants
																		.DATE_FORMAT
																)} */
																	dateformat(
																		e.created_date,
																		Config
																			.constants
																			.DATE_FORMAT
																	)
																}
															</td>
															<td>
																{
																	/* {moment(
																	e.updated_date
																).format(
																	Config
																		.constants
																		.DATE_FORMAT
																)} */
																	dateformat(
																		e.updated_date,
																		Config
																			.constants
																			.DATE_FORMAT
																	)
																}
															</td>
															<td>
																{/* <span
																		className="btn btn-link text-link mrg-r15"
																		onClick={() =>
																			this.handleEditAction(
																				e.id
																			)
																		}
																	>
																		<i className="ic-create-24px"></i>
																	</span> */}
																<span>
																	<SwitchBtn
																		label=""
																		htmlFor={
																			"Status" +
																			i
																		}
																		id={
																			"Status" +
																			i
																		}
																		name={
																			"Status" +
																			i
																		}
																		checked={
																			e.status ===
																			"1"
																				? true
																				: false
																		}
																		onChange={this.displayPopUp.bind(
																			this,
																			e.id
																		)}
																	/>

																	{PopOver_id ===
																		e.id && (
																		<ConfirmPopUp
																			onConfirm={() =>
																				this.confirmYes(
																					e
																				)
																			}
																			onReject={() =>
																				this.confirmNo()
																			}
																			message={this.props.t(
																				"COMMON.ARE_YOU_SURE"
																			)}
																		/>
																	)}
																</span>
																<span>
																	{/* {loading && (
																			<PageLoader loaderClass="text-center" />
																		)} */}
																</span>
															</td>
														</tr>
													))}
											</tbody>
										</table>
										<div className="clearfix">
											{pagination &&
											pagination.pages >= 1 ? (
												<div className="main-div-pagination">
													<div className="col-sm-6 text-light">
														{this.props.t(
															"CAMPAIGN.SHOWING"
														)}{" "}
														{pagination.cur_page}{" "}
														{this.props.t(
															"CAMPAIGN.OF"
														)}{" "}
														{pagination.pages}{" "}
														{this.props.t(
															"CAMPAIGN.PAGES"
														)}
													</div>
													<div className="col-sm-6">
														<Pagination
															activePage={
																pagination.cur_page
															}
															itemsCountPerPage={
																pagination.limit
															}
															totalItemsCount={
																pagination.total
															}
															pageRangeDisplayed={
																5
															}
															itemClass="page-item"
															linkClass="page-link"
															onChange={
																this
																	.handlePageChange
															}
														/>
													</div>
												</div>
											) : (
												""
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ user, config }) => {
	return {
		blackList: user.blackList ? user.blackList.record : [],
		pagination: user.blackList ? user.blackList.pagination : {},
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			saveBlackList: bindActionCreators(
				UserActions.saveBlackList,
				dispatch
			),
			getBlackList: bindActionCreators(
				UserActions.getBlackList,
				dispatch
			),
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
		},
	};
};
export default withTranslation("webfrom")(
	connect(mapStateToProps, mapDispatchToProps)(BlackList)
);
