/* eslint-disable */
import React, { Component } from "react";
import Select from "react-select";
import InputField from "../../elements/InputField";
import CheckBox from "../../elements/CheckBox";
import { UserService } from "../../../service/userService";
import { LeadService } from "../../../service/leadService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import PageLoader from "../../elements/PageLoader";
import { withRouter } from "react-router-dom";
class SaveUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			role_list: [],
			user_id: this.props.match.params.id || 0,
			errors: {},
			redirectTolist: false,
			userInfo: {},
		};
		this.ChangeCheckbox = this.ChangeCheckbox.bind(this);
	}

	componentDidMount = async () => {
		await this.getRoleList();
		if (this.state.user_id) {
			await this.getUserById(this.state.user_id);
		}
	};

	componentDidUpdate = () => {
		if (this.state.redirectTolist) {
			this.props.history.push("/user-list");
		}
	}

	getRoleList = async () => {
		UserService.getRoleList({})
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					this.setState({
						role_list: response.data.data || [],
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};
	
	getUserById = async (id) => {
		let body = { 
			id: id,
		}
		await UserService.getUserById(body)
			.then((response) => {
				if (response.data && response.data.data && response.data.data.length) {
					this.setState({
						userInfo: response.data.data[0]
					})
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	}

	handleChange = (sname, valueObj) => {
		let { userInfo, errors } = this.state;
		if (sname === "role_id") userInfo[sname] = valueObj.id;
		else userInfo[sname] = valueObj.target.value;
		errors[sname] = "";
		this.setState({ userInfo: userInfo, errors: errors });
	};

	ChangeCheckbox = (event) => {
		const target = event.target;
		let { userInfo } = this.state;
		userInfo[target.name] = target.checked ? 1 : 0;
		this.setState({ userInfo: userInfo });
	};

	validForm = () => {
		let { user_id, userInfo } = this.state;
		let formIsValid = true,
			errors = {};
		if (!userInfo["username"]) {
			errors["username"] = this.props.t("USER.REQUIRED");
			formIsValid = false;
		}
		if (!userInfo["name"]) {
			errors["name"] = this.props.t("USER.REQUIRED");
			formIsValid = false;
		}
		if (!userInfo["role_id"]) {
			errors["role_id"] = this.props.t("USER.REQUIRED");
			formIsValid = false;
		}
		if (!userInfo["email"]) {
			errors["email"] = this.props.t("USER.REQUIRED");
			formIsValid = false;
		}
		if (!user_id && !userInfo["password"]) {
			errors["password"] = this.props.t("USER.REQUIRED");
			formIsValid = false;
		}
		if (!userInfo["position"]) {
			errors["position"] = this.props.t("USER.REQUIRED");
			formIsValid = false;
		}
		if (userInfo && userInfo['role_id'] == 3 && !userInfo["dialer_user_id"]) {
			errors["dialer_user_id"] = this.props.t("USER.REQUIRED");
			formIsValid = false;
		}

		this.setState({ errors: errors });
		return formIsValid;
	};

	saveUser = (event) => {
		event.preventDefault();
		let { userInfo, domain_id } = this.state;
		if (this.validForm()) {
			this.setState({ loading: true });
			if (this.state.user_id) {
				userInfo.is_update = true
			} else {
				userInfo.status = 1;
			}
			UserService.saveUser(userInfo)
				.then((response) => {
					if (
						response.status === 200 &&
						response.data.status === 200
					) {
						toast.success(response.data.message);
						this.setState({
							userInfo: {},
							loading: false,
							redirectTolist: true,
						});
					} else {
						this.setState({ loading: false });
					}
				})
				.catch((error) => {
					toast.error(error);
				});
		}
	};
	handleRedirect = () => {
		this.setState({ redirectTolist: true });
	};

	render() {
		const { loading, userInfo, role_list, user_id, errors } = this.state;
		console.log("userInfo==>", userInfo);
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-6 col-sm-offset-3">
						<h1>
							{user_id
								? this.props.t("USER.UPDATE_USER")
								: this.props.t("USER.ADD_USER")}
						</h1>
						<div className="card">
							{loading && (
								<PageLoader loaderClass="text-center" />
							)}

							<div className="card-body">
								<div className="row">
									<form onSubmit={this.saveUser}>
										<div className="col-sm-6 col-md-6">
											<label className="required">
												{this.props.t("USER.ROLE")}
											</label>

											<Select
												id="role_id"
												onChange={this.handleChange.bind(
													this,
													"role_id"
												)}
												options={role_list}
												name="role_id"
												placeholder={this.props.t(
													"USER.ROLE"
												)}
												value={role_list.filter(
													({ id }) =>
														id === userInfo.role_id
												)}
												getOptionLabel={({ name }) =>
													name
												}
												getOptionValue={({ id }) => id}
											/>
											<span className="text-danger">
												{errors.role_id || ""}
											</span>
										</div>

										<div className="col-sm-6 col-md-6">
											<InputField
												inputProps={{
													id: "name",
													type: "text",
													value: userInfo.name || "",
													name: "name",
													autoComplete: "off",
													placeholder: this.props.t(
														"USER.NAME"
													),
													label: this.props.t(
														"USER.NAME"
													),
													validationreq: "yes",
													dataerror:
														errors.name || "",
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"name"
												)}
											/>
										</div>

										<div className="col-sm-6 col-md-6">
											<InputField
												inputProps={{
													id: "username",
													type: "text",
													value:
														userInfo.username || "",
													name: "username",
													autoComplete: "off",
													placeholder: this.props.t(
														"USER.USERNAME"
													),
													label: this.props.t(
														"USER.USERNAME"
													),
													validationreq: "yes",
													dataerror:
														errors.username || "",
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"username"
												)}
											/>
										</div>

										{ !user_id ? (
											<div className="col-sm-6 col-md-6">
												<InputField
													inputProps={{
														id: "password",
														type: "text",
														value:
															userInfo.password || "",
														name: "password",
														autoComplete: "off",
														placeholder: this.props.t(
															"USER.PASSWORD"
														),
														label: this.props.t(
															"USER.PASSWORD"
														),
														validationreq: "yes",
														dataerror:
															errors.password || "",
													}}
													autoComplete="off"
													onChange={this.handleChange.bind(
														this,
														"password"
													)}
												/>
											</div>
										) : ""}

										<div className="col-sm-6 col-md-6">
											<InputField
												inputProps={{
													id: "first_name",
													type: "text",
													value:
														userInfo.first_name ||
														"",
													name: "first_name",
													autoComplete: "off",
													placeholder: this.props.t(
														"USER.FIRST_NAME"
													),
													label: this.props.t(
														"USER.FIRST_NAME"
													),
													dataerror:
														errors.first_name || "",
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"first_name"
												)}
											/>
										</div>

										<div className="col-sm-6 col-md-6">
											<InputField
												inputProps={{
													id: "last_name",
													type: "text",
													value:
														userInfo.last_name ||
														"",
													name: "last_name",
													autoComplete: "off",
													placeholder: this.props.t(
														"USER.LAST_NAME"
													),
													label: this.props.t(
														"USER.LAST_NAME"
													),
													dataerror:
														errors.last_name || "",
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"last_name"
												)}
											/>
										</div>

										<div className="col-sm-6 col-md-6">
											<InputField
												inputProps={{
													id: "email",
													type: "text",
													value: userInfo.email || "",
													name: "email",
													autoComplete: "off",
													placeholder: this.props.t(
														"USER.EMAIL"
													),
													validationreq: "yes",
													label: this.props.t(
														"USER.EMAIL"
													),
													dataerror:
														errors.email || "",
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"email"
												)}
											/>
										</div>

										<div className="col-sm-6 col-md-6">
											<InputField
												inputProps={{
													id: "mobile",
													type: "text",
													value:
														userInfo.mobile || "",
													name: "name",
													autoComplete: "off",
													placeholder: this.props.t(
														"USER.MOBILE"
													),
													label: this.props.t(
														"USER.MOBILE"
													),
													dataerror:
														errors.mobile || "",
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"mobile"
												)}
											/>
										</div>

										<div className="col-sm-6 col-md-6">
											<InputField
												inputProps={{
													id: "position",
													type: "text",
													value:
														userInfo.position || "",
													name: "position",
													autoComplete: "off",
													placeholder: this.props.t(
														"USER.POSITION"
													),
													label: this.props.t(
														"USER.POSITION"
													),
													validationreq: "yes",
													dataerror:
														errors.position || "",
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"position"
												)}
											/>
										</div>

										<div className="col-sm-6 col-md-6">
											<InputField
												inputProps={{
													id: "dialer_user_id",
													type: "text",
													value:
														userInfo.dialer_user_id ||
														"",
													name: "dialer_user_id",
													autoComplete: "off",
													placeholder: this.props.t(
														"USER.DIALER_USER_ID"
													),
													label: this.props.t(
														"USER.DIALER_USER_ID"
													),
													dataerror:
														errors.dialer_user_id ||
														"",
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"dialer_user_id"
												)}
											/>
										</div>

										{/* <div className="col-sm-12 form-group rc-inline">
											
											<CheckBox
												label={this.props.t(
													"USER.ACTIVE"
												)}
												name="status"
												type="checkbox"
												id="status"
												checked={
													Number(
														userInfo.status
													) === 1
														? true
														: false
												}
												value="1"
												onChange={this.ChangeCheckbox}
											/>
										</div> */}

										<div className="col-sm-12 form-group text-right">
											<button
												type="button"
												className="btn btn-default mrg-r15"
												onClick={this.handleRedirect}
											>
												{this.props.t("USER.CANCEL")}
											</button>
											<button
												type="submit"
												className="btn btn-primary "
											>
												{user_id
												? this.props.t("USER.UPDATE_USER")
												: this.props.t("USER.ADD_USER")}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation("common")(withRouter(SaveUser));
