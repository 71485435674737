import { AuditService } from "../../service/auditService";
export const AuditAction = {
	getAllAudits,
	getAuditDetails,
	updateAuditStatus,
	changePriority,
};

function getAllAudits(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { message, data } = {},
			} = await AuditService.getAllAudits(postData);

			if (status === 200) {
				return {
					status: true,
					response: message,
					data: data,
				};
			} else
				return {
					status: false,
					response: message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}

function getAuditDetails(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { message, data } = {},
			} = await AuditService.getAuditDetails(postData);

			if (status === 200) {
				return {
					status: true,
					response: message,
					data: data,
				};
			} else
				return {
					status: false,
					response: message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}

function updateAuditStatus(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { message, data } = {},
			} = await AuditService.updateStatus(postData);

			if (status === 200) {
				return {
					status: true,
					response: message,
					data: data,
				};
			} else
				return {
					status: false,
					response: message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}

function changePriority(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { message, data } = {},
			} = await AuditService.changePriority(postData);

			if (status === 200) {
				return {
					status: true,
					response: message,
					data: data,
				};
			} else
				return {
					status: false,
					response: message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}
