import React, { Component } from "react";
import { webFormService } from "../../../../service/webFormService";
import { LeadService } from "../../../../service/leadService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import PageLoader from "../../../elements/PageLoader";
import SearchLeadType from "../../component/leadType/SearchLeadType";
class LeadTypeList extends Component {
	constructor(props) {
		super(props);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.state = {
			loading: true,
			leadTypeList: this.props.leadTypeList || [],
			pagination: this.props.pagination || {},
			process_list: this.props.process_list || [],
			business_type_list: this.props.business_type_list || [],
			filterData: { page: 1 },
			PopOver: false,
			actionType: "status",
		};
	}

	componentDidMount = async () => {
		await this.getConfigData();
		await this.getLeadTypeList();
	};

	// get config
	getConfigData = async () => {
		const requestBody = {
			config_data: ["process_list"],
		};
		LeadService.getConfig(requestBody)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					this.setState({
						process_list: response.data.data.process_list || [],
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	getLeadTypeList = async () => {
		let { filterData } = this.state;
		this.setState({ loading: true });
		filterData["list_type"] = "ALL";
		webFormService
			.getLeadTypeList(filterData)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let leadTypeList = response.data.data || [];
					let pagination = response.data.pagination || {};

					this.setState({
						leadTypeList: leadTypeList,
						pagination: pagination,
						loading: false,
					});
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	handlePageChange(pageNumber) {
		let { filterData } = this.state;
		filterData["page"] = pageNumber;
		this.setState({ filterData: filterData, loading: true });
		this.getLeadTypeList();
	}
	submitFilterForm = async (filterData) => {
		filterData.page = 1;
		this.setState(
			{
				loading: true,
				filterData: filterData,
			},
			async () => {
				await this.getLeadTypeList();
			}
		);
	};

	updateProcessStatus = async (id, status) => {
		this.setState({ loading: true });
		webFormService
			.updateLeadType({ id: id, active: status })
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					this.setState(
						{
							loading: false,
							PopOver: false,
						},
						async () => {
							await this.getLeadTypeList();
						}
					);
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	displayPopUp = (list_id, type) => {
		this.setState({ PopOver: list_id, actionType: type });
	};
	confirmNo = (status_id) => {
		this.setState({ PopOver: false });
	};
	confirmYes = (status_id) => {
		let process_id = this.state.PopOver;
		let actionType = this.state.actionType,
			status2upd = "2";
		if (actionType !== "delete")
			status2upd = Number(status_id) === 1 ? "0" : "1";
		this.updateProcessStatus(process_id, status2upd);
	};

	render() {
		const {
			loading,
			leadTypeList,
			filterData,
			pagination,
			process_list,
		} = this.state;
		return (
			<div>
				<SearchLeadType
					onSubmitFilter={this.submitFilterForm}
					filterData={filterData}
				/>
				<div className="container-fluid">
					<div className="result-wrap">
						<div className="row mrg-b15">
							<div className="col-sm-6">
								<p className="countNo mrg-t5 mrg-b0">
									{pagination.total} -{" "}
									{this.props.t(
										"LEAD_TYPE_LIST.LEAD_TYPE_LIST"
									)}
								</p>
							</div>
						</div>

						<div className="card">
							<div className="table-responsive">
								{loading && (
									<PageLoader loaderClass="text-center" />
								)}
								<table className="table table-bordered-h table-hover processTable">
									<thead>
										<tr>
											<th>
												{this.props.t(
													"LEAD_TYPE_LIST.TBL_HEADING.NAME"
												)}
											</th>
											<th>
												{this.props.t(
													"LEAD_TYPE_LIST.TBL_HEADING.VALUE"
												)}
											</th>
											<th>
												{this.props.t(
													"LEAD_TYPE_LIST.TBL_HEADING.PROCESS"
												)}
											</th>
											<th>
												{this.props.t(
													"LEAD_TYPE_LIST.TBL_HEADING.LEAD_QUALIFICATION"
												)}
											</th>
											<th>
												{this.props.t(
													"LEAD_TYPE_LIST.TBL_HEADING.IS_CALLABLE"
												)}
											</th>

											{/* <th>
												{this.props.t("PROCESS.ACTION")}
											</th> */}
										</tr>
									</thead>

									<tbody>
										{leadTypeList.length > 0 ? (
											leadTypeList.map(
												(singleinfo, index) => (
													<React.Fragment
														key={singleinfo.id}
													>
														<tr>
															<td>
																<div>
																	{singleinfo.name ||
																		""}
																</div>
															</td>
															<td>
																<div>
																	{singleinfo.value ||
																		""}
																</div>
															</td>
															<td>
																<div>
																	{process_list &&
																	process_list.length &&
																	singleinfo.process_id
																		? process_list
																				.filter(
																					(
																						v
																					) =>
																						v.process_id ===
																						singleinfo.process_id
																				)
																				.map(
																					(
																						v
																					) =>
																						v.name
																				)[0]
																		: ""}
																</div>
															</td>
															<td>
																<div>
																	{singleinfo.lead_qualification ||
																		""}
																</div>
															</td>
															<td>
																<div>
																	{Number(
																		singleinfo.is_callable
																	) === 1
																		? "Yes"
																		: "No"}
																</div>
															</td>

															{/* <td>
																<div>
																	<SwitchBtn
																		label=""
																		htmlFor={
																			"Status" +
																			singleinfo.id
																		}
																		id={
																			"Status" +
																			singleinfo.id
																		}
																		name={
																			"Status" +
																			singleinfo.id
																		}
																		checked={
																			Number(
																				singleinfo.active
																			) ===
																			1
																				? true
																				: false
																		}
																		onChange={this.displayPopUp.bind(
																			this,
																			singleinfo.id,
																			"status"
																		)}
																	/>
																	{PopOver ===
																		singleinfo.id && (
																		<ConfirmPopUp
																			onConfirm={() =>
																				this.confirmYes(
																					singleinfo.active
																				)
																			}
																			onReject={() =>
																				this.confirmNo(
																					singleinfo.active
																				)
																			}
																			message={this.props.t(
																				"COMMON.ARE_YOU_SURE"
																			)}
																		/>
																	)}
																	
																</div>
															</td> */}
														</tr>
													</React.Fragment>
												)
											)
										) : (
											<tr>
												<td colSpan="10">
													{!loading ? (
														<h6 className="text-danger">
															{this.props.t(
																"LEAD_TYPE_LIST.NOT_FOUND"
															)}
														</h6>
													) : (
														""
													)}
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div className="clearfix">
								{pagination.pages >= 1 ? (
									<div className="main-div-pagination">
										<div className="col-sm-6 text-light">
											{this.props.t("PROCESS.SHOWING")}{" "}
											{filterData.page}{" "}
											{this.props.t("PROCESS.OF")}{" "}
											{pagination.pages}{" "}
											{this.props.t("PROCESS.PAGES")}
										</div>
										<div className="col-sm-6">
											<Pagination
												activePage={filterData.page}
												itemsCountPerPage={
													pagination.limit
												}
												totalItemsCount={
													pagination.total
												}
												pageRangeDisplayed={5}
												itemClass="page-item"
												linkClass="page-link"
												onChange={this.handlePageChange}
											/>
										</div>
									</div>
								) : (
									""
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation("webfrom")(LeadTypeList);
