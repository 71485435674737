import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Select from "react-select";

class PriorityCalling extends Component {
	constructor(props) {
		super(props);
		this.state = {
			process_list: [],
			formData: {},
			errors: {},
		};
	}
	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		if (props.process_list && props.process_list !== state.process_list) {
			updateProps["process_list"] = props.process_list;
		}
		return updateProps;
	}

	handleChange = (sname, ovalue) => {
		let { formData, errors } = this.state;
		if (ovalue) {
			formData[sname] = ovalue.value;
			errors[sname] = "";
		} else delete formData[sname];
		this.setState({ formData: formData, errors: errors });
	};

	pushToDialer = () => {
		let { formData, errors } = this.state;
		if (formData && formData.list_id)
			this.props.addPriorityCalling(formData);
		else
			errors["list_id"] = this.props.t(
				"PRIORITY_CALL.PRIORITY_CALL_REQUIRED"
			);
		this.setState({ errors: errors });
	};

	render() {
		const { process_list, formData, errors } = this.state;
		return (
			<div>
				<div className="modal-header">
					<div className="modal-title h4">
						{this.props.t("PRIORITY_CALL.TITLE")}
					</div>
				</div>
				<div className="row">
					<div className="col-sm-8">
						<div>
							<div className="form-group">
								<label>
									{this.props.t("PRIORITY_CALL.PROCESS_NAME")}
									<span className="text-danger">*</span>
								</label>
								<Select
									id="status"
									onChange={this.handleChange.bind(
										this,
										"list_id"
									)}
									options={process_list}
									name="status"
									placeholder={this.props.t(
										"PRIORITY_CALL.PROCESS_NAME"
									)}
									value={
										formData &&
										process_list.filter(
											({ value }) =>
												value === formData.list_id
										)
									}
									// getOptionLabel={({ name }) => name}
									// getOptionValue={({ id }) => id}
									isClearable
								/>
								<span className="text-danger">
									{errors.list_id || ""}
								</span>
							</div>
						</div>
					</div>
				</div>

				<div className="actionFooter">
					<button
						type="button"
						className="btn btn-primary-outline mrg-r15"
						onClick={this.props.modalClose}
					>
						{this.props.t("PRIORITY_CALL.CANCEL")}
					</button>
					<button
						type="button"
						className="btn btn-primary"
						onClick={this.pushToDialer}
					>
						{this.props.t("PRIORITY_CALL.ADD_TO_DIALER")}
					</button>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ config }) => {
	return {
		process_list:
			config.gm_campaign_list instanceof Array
				? config.gm_campaign_list
						.filter((f) => {
							return Number(f.dialer_flag) === 1;
						})
						.map(function (e) {
							return {
								label: e.description || e.list_id,
								value: e.list_id,
							};
						})
				: [],
	};
};
export default withTranslation("lead_detail")(
	connect(mapStateToProps, null)(PriorityCalling)
);
