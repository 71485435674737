import React, { Component } from "react";
import Select from "react-select";
import InputField from "../elements/InputField";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import PageLoader from "../elements/PageLoader";
import { Redirect } from "react-router-dom";
import { ConfigAction, UserActions } from "../../store/action";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FormActivity from "./FormActivity";
import { default as Config } from "../../config/config";
class EditPackCampaign extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// loading: this.props.match.params.id ? true : false,
			campaigninfo: {
				process_status: "0",
			},
			process_list: this.props.process_list || [],
			type: this.props.type || [],
			server: this.props.server || [],
			campaign_id: this.props.match.params.id || 0,
			errors: {},
			redirectTolist: false,
			dealerType: [],
			campaignType: [],
			availablePacks: [],
			redirectUrl: "/campaign_configurator",
			gmList: [],
			loading: true,
		};
	}

	componentDidMount = async () => {
		await this.getConfigData();
		if (this.state.campaign_id) await this.getCampaign();
		this.getPackList();
	};
	getPackList = () => {
		const { campaign_id = 0 } = this.state;
		const postData = {
			campaign_id: campaign_id,
		};
		this.props.actions.getPackMapping(postData).then((res) => {
			this.setState({ loading: false });
		});
	};
	getConfigData = async () => {
		const requestBody = {
			config_data: ["dealer_type", "campaign_type", "gm_list"],
		};
		this.props.actions.getConfigData(requestBody);
	};

	getCampaign = async () => {
		// this.setState({ loading: true });
		let { campaign_id } = this.state;
		const postData = {
			campaign_id: campaign_id,
		};
		this.props.actions.getCampaignPackList(postData);
	};
	static getDerivedStateFromProps = (nextProps, prevProps) => {
		const updateProps = [];
		if (
			nextProps.dealer_type instanceof Array &&
			prevProps.dealerType !== nextProps.dealer_type
		) {
			updateProps["dealerType"] = nextProps.dealer_type;
		}
		if (
			nextProps.campaign_type instanceof Array &&
			prevProps.campaignType !== nextProps.campaign_type
		) {
			updateProps["campaignType"] = nextProps.campaign_type;
		}
		if (
			nextProps.campaignPack &&
			prevProps.campaign_id != 0 &&
			prevProps.campaigninfo !== nextProps.campaignPack
		) {
			updateProps["campaigninfo"] = nextProps.campaignPack;
		}
		if (nextProps.availablePacks != prevProps.availablePacks) {
			updateProps["availablePacks"] = nextProps.availablePacks;
		}
		if (nextProps.gm_list != prevProps.gmList) {
			updateProps["gmList"] = nextProps.gm_list;
		}
		return updateProps;
	};
	handleChange = (sname, valueObj) => {
		let { campaigninfo } = this.state;
		let value = valueObj ? valueObj.value || valueObj.process_id : null;
		if (["campaign_name", "reporting_name"].includes(sname))
			value = valueObj.target.value;
		campaigninfo[sname] = value;
		this.setState({ campaigninfo: campaigninfo });
	};

	validForm = () => {
		let { campaigninfo } = this.state;
		let formIsValid = true,
			errors = {};
		if (!campaigninfo["name"]) {
			errors["name"] = this.props.t("CAMPAIGN.VALIDATION_ERROR.REQUIRED");
			formIsValid = false;
		}
		if (!campaigninfo["type"]) {
			errors["type"] = this.props.t("CAMPAIGN.VALIDATION_ERROR.REQUIRED");
			formIsValid = false;
		}
		// if (!campaigninfo["server"]) {
		// 	errors["server"] = this.props.t(
		// 		"CAMPAIGN.VALIDATION_ERROR.REQUIRED"
		// 	);
		// 	formIsValid = false;
		// }
		if (!campaigninfo["process_id"]) {
			errors["process_id"] = this.props.t(
				"CAMPAIGN.VALIDATION_ERROR.REQUIRED"
			);
			formIsValid = false;
		}

		this.setState({ errors: errors });
		return formIsValid;
	};
	validateCampaignData = (data) => {
		const { availablePacks, campaign_id } = this.state;
		let error = null;
		if (!data.campaign_name)
			error = this.props.t("CAMPAIGN_PACK.ERROR.CAMPAIGN_NAME");
		else if (!data.reporting_name)
			error = this.props.t("CAMPAIGN_PACK.ERROR.REPORTING_NAME");
		else if (!data.campaign_type)
			error = this.props.t("CAMPAIGN_PACK.ERROR.CAMPAIGN_TYPE");
		else if (!data.dealer_type)
			error = this.props.t("CAMPAIGN_PACK.ERROR.DEALER_TYPE");
		else if (
			campaign_id &&
			availablePacks.filter((e) => e.selected).length === 0
		) {
			if (window.confirm(this.props.t("SAVE_CAMPAIGN.PACK_ERROR"))) {
				// Save it!
			} else {
				// Do nothing!
				return false;
			}
		} else if (
			campaign_id === 0 &&
			availablePacks.filter((e) => e.selected).length === 0
		) {
			error = this.props.t("CAMPAIGN_PACK.ERROR.PACK_ERROR");
		} else if (
			!data.gm_id &&
			data.process_status &&
			data.process_status === "1"
		) {
			error = this.props.t("CAMPAIGN_PACK.ERROR.CANNOT_ACTIVATE_PROCESS");
		}
		if (error) {
			toast.error(error);
			return false;
		}
		return true;
	};
	Updatecampaign = async (event) => {
		event.preventDefault();
		let { campaigninfo, campaign_id, availablePacks } = this.state;
		if (this.validateCampaignData(campaigninfo)) {
			const postData = {};
			postData["campaign_name"] = campaigninfo.campaign_name;
			postData["reporting_name"] = campaigninfo.reporting_name;
			postData["campaign_type"] = campaigninfo.campaign_type;
			postData["dealer_type"] = campaigninfo.dealer_type;
			postData["process_status"] = campaigninfo.process_status;
			if (campaigninfo.gm_id) {
				postData["gm_id"] = campaigninfo.gm_id;
			} else {
				postData["process_status"] = "0";
			}

			postData["pack_id"] = availablePacks
				.filter((e) => e.selected)
				.map((e) => e.value);
			if (postData["pack_id"].length === 0) {
				postData["status"] = "0";
			}
			if (campaign_id) {
				postData["campaign_id"] = campaign_id;
			}

			// postData['status'] = campaigninfo.status

			const {
				status,
				data = "",
			} = await this.props.actions.updateCampaign(postData);
			if (status) {
				toast.success(this.props.t("SAVE_CAMPAIGN.SUCCESS"));
				this.handleRedirect();
				// if (campaign_id) this.handleRedirect(2);
				// else if (!campaign_id) {
				// 	this.setState({ campaign_id: data }, () => {
				// 		this.handleRedirect(1);
				// 	});
				// }
			}
		}
	};

	handleRedirect = () => {
		// type = 2
		// this.setState({ redirectTolist: type });
		this.setState({ redirectTolist: true });
	};
	handleAddChangeEvent = (selectedIds, type) => {
		const { availablePacks } = this.state;

		switch (type) {
			case "pack_id":
				availablePacks.map((e) => {
					if (selectedIds === -1 || e.value === selectedIds) {
						e.selected = true;
						e.error = false;
					}
				});
				break;
			default:
				break;
		}

		this.setState({ availablePacks: availablePacks });
	};
	handleRemoveEvent = (selectedIds, type) => {
		const { availablePacks } = this.state;

		switch (type) {
			case "pack_id":
				availablePacks.map((e) => {
					if (selectedIds === -1 || e.value === selectedIds) {
						e.selected = false;
						e.error = false;
					}
				});
				break;
			default:
				break;
		}

		this.setState({ availablePacks: availablePacks });
	};
	render() {
		const {
			loading,
			campaigninfo,
			campaign_id,
			errors,
			redirectTolist,
			dealerType,
			campaignType,
			availablePacks,
			gmList,
		} = this.state;

		// if (redirectTolist) {
		// 	if (redirectTolist === 1)
		// 		return (
		// 			<Redirect to={`/assign_pack_to_campaign/${campaign_id}`} />
		// 		);
		// 	else return <Redirect to={"/list_campaign"} />;
		// }
		if (redirectTolist) {
			return <Redirect to={"/campaign_configurator"} />;
		}
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-6 col-sm-offset-3">
						<h1>
							{campaign_id
								? this.props.t(
										"SAVE_CAMPAIGN.UPDATE_CAMPAIGN"
								  ) + ` #${campaign_id}`
								: this.props.t("SAVE_CAMPAIGN.ADD_CAMPAIGN")}
						</h1>
						<div className="card">
							{loading && (
								<PageLoader loaderClass="text-center" />
							)}
							<div className="card-body">
								<div className="row">
									<form onSubmit={this.Updatecampaign}>
										<div className="col-sm-6 col-md-6">
											<InputField
												inputProps={{
													id: "campaign_name",
													type: "text",
													value:
														campaigninfo.campaign_name ||
														"",
													name: "campaign_name",
													autoComplete: "off",
													placeholder: this.props.t(
														"SAVE_CAMPAIGN.CAMPAIGN_NAME"
													),
													label: this.props.t(
														"SAVE_CAMPAIGN.CAMPAIGN_NAME"
													),
													dataerror:
														errors.campaign_name ||
														"",
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"campaign_name"
												)}
											/>
										</div>
										<div className="col-sm-6 col-md-6">
											<InputField
												inputProps={{
													id: "reporting_name",
													type: "text",
													value:
														campaigninfo.reporting_name ||
														"",
													name: "reporting_name",
													autoComplete: "off",
													placeholder: this.props.t(
														"SAVE_CAMPAIGN.REPORTING_NAME"
													),
													label: this.props.t(
														"SAVE_CAMPAIGN.REPORTING_NAME"
													),
													dataerror:
														errors.name || "",
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"reporting_name"
												)}
											/>
										</div>
										<div className="col-sm-6 col-md-6 form-group">
											<label>
												{this.props.t(
													"SAVE_CAMPAIGN.CAMPAIGN_TYPE"
												)}
											</label>

											<Select
												id="campaign_type"
												onChange={this.handleChange.bind(
													this,
													"campaign_type"
												)}
												options={campaignType}
												name="campaign_type"
												placeholder={this.props.t(
													"SAVE_CAMPAIGN.CAMPAIGN_TYPE"
												)}
												value={campaignType.filter(
													({ value }) =>
														value ===
														campaigninfo.campaign_type
												)}
												getOptionLabel={({ label }) =>
													label
												}
												getOptionValue={({ value }) =>
													value
												}
											/>
											<span className="text-danger">
												{errors.campaign_name || ""}
											</span>
										</div>
										<div className="col-sm-6 col-md-6 form-group">
											<label>
												{this.props.t(
													"SAVE_CAMPAIGN.GM_ID"
												)}
											</label>

											<Select
												id="gm_id"
												onChange={this.handleChange.bind(
													this,
													"gm_id"
												)}
												options={gmList}
												name="gm_id"
												placeholder={this.props.t(
													"SAVE_CAMPAIGN.GM_ID"
												)}
												value={gmList.filter(
													({ value }) =>
														value ===
														campaigninfo.gm_id
												)}
												getOptionLabel={({ label }) =>
													label
												}
												getOptionValue={({ value }) =>
													value
												}
												isClearable
											/>
											<span className="text-danger">
												{errors.gm_id || ""}
											</span>
										</div>
										<div className="col-sm-6 col-md-6 form-group">
											<label>
												{this.props.t(
													"SAVE_CAMPAIGN.DEALER_TYPE"
												)}
											</label>

											<Select
												id="dealer_type"
												onChange={this.handleChange.bind(
													this,
													"dealer_type"
												)}
												options={dealerType}
												name="dealer_type"
												placeholder={this.props.t(
													"SAVE_CAMPAIGN.DEALER_TYPE"
												)}
												value={dealerType.filter(
													({ value }) =>
														value ===
														campaigninfo.dealer_type
												)}
												getOptionLabel={({ label }) =>
													label
												}
												getOptionValue={({ value }) =>
													value
												}
											/>
											<span className="text-danger">
												{errors.dealer_type || ""}
											</span>
										</div>
										{/* <div className="col-sm-6 col-md-6 form-group">
											<label>
												{this.props.t(
													"SAVE_CAMPAIGN.PROCESS_STATUS"
												)}
											</label>
											{console.log(
												"--------",
												campaigninfo.process_status
											)}
											<Select
												id="process_status"
												onChange={this.handleChange.bind(
													this,
													"process_status"
												)}
												options={
													Config.constants
														.ACTIVE_STATUS
												}
												name="process_status"
												placeholder={this.props.t(
													"SAVE_CAMPAIGN.PROCESS_STATUS"
												)}
												value={Config.constants.ACTIVE_STATUS.filter(
													({ value }) =>
														value ===
														campaigninfo.process_status
												)}
												getOptionLabel={({ label }) =>
													label
												}
												getOptionValue={({ value }) =>
													value
												}
											/>
											<span className="text-danger">
												{errors.process_status || ""}
											</span>
										</div> */}
										<div className="col-sm-12 form-group assign_pack">
											<h4>
												{this.props.t(
													"SAVE_CAMPAIGN.ASSIGN_PACK"
												)}
											</h4>
											<FormActivity
												title={this.props.t(
													"CAMPAIGN_MAPPING.PACKS"
												)}
												type="pack_id"
												value={availablePacks}
												onAdd={
													this.handleAddChangeEvent
												}
												onRemove={
													this.handleRemoveEvent
												}
												loading={loading}
											/>
										</div>

										<div className="col-sm-12 form-group text-right">
											<button
												type="button"
												className="btn btn-default mrg-r15"
												onClick={this.handleRedirect}
											>
												{this.props.t(
													"SAVE_CAMPAIGN.CANCEL"
												)}
											</button>
											<button
												type="submit"
												className="btn btn-primary "
												disabled={loading}
											>
												{campaign_id
													? this.props.t(
															"SAVE_CAMPAIGN.UPDATE"
													  )
													: this.props.t(
															"SAVE_CAMPAIGN.CREATE"
													  )}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ config, user }) => {
	return {
		dealer_type: config.dealer_type,
		campaign_type: config.campaign_type,
		campaignPack: user.campaignPackList
			? user.campaignPackList.record instanceof Array
				? user.campaignPackList.record[0]
				: []
			: [],
		availablePacks:
			user.campaignAvailablePacks instanceof Array
				? user.campaignAvailablePacks.map((e) => {
						return {
							label: e.pack_name,
							value: e.pack_id,
							selected: e.is_selected ? true : false,
							error: false,
						};
				  })
				: [],
		gm_list:
			config.gm_list instanceof Array
				? config.gm_list.map((e) => {
						return {
							label: `${e.name}-${e.gm_id}`,
							value: e.gm_id,
						};
				  })
				: [],
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
			updateCampaign: bindActionCreators(
				UserActions.updateCampaignPackData,
				dispatch
			),
			getCampaignPackList: bindActionCreators(
				UserActions.getCampaignPackList,
				dispatch
			),
			getPackMapping: bindActionCreators(
				UserActions.getAssignPackCampaign,
				dispatch
			),
		},
	};
};
export default withTranslation("campaign_pack")(
	connect(mapStateToProps, mapDispatchToProps)(EditPackCampaign)
);
