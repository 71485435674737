/* eslint-disable */
import React, { Component } from "react";
import Select from "react-select";
import { AuditService } from "../../../service/auditService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import PageLoader from "../../elements/PageLoader";
import { bindActionCreators } from "redux";
import { InventoryAction } from "./../../../store/action";
import { connect } from "react-redux";
import CheckBox from "../../elements/CheckBox";
import { Redirect } from "react-router-dom";

class EditTieupBrand extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mmv: this.props.mmv || {},
			make: (this.props.mmv && this.props.mmv.make) || [],
			loading: this.props.match.params.id ? true : false,
			tieupinfo: this.props.tieupinfo || {},
			tieup_id: this.props.match.params.id || 0,
			errors: {},
			tiupBrandList: [],
			redirectTolist: false,
		};
		this.ChangeCheckbox = this.ChangeCheckbox.bind(this);
	}

	componentDidMount = async () => {
		await this.getBrandTieupList();
	};

	getBrandTieupList = async () => {
		let { filterData } = this.state;
		let [...make] = this.state.make;
		AuditService.getTieUpBrand(filterData)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let tiupBrandList = response.data.data || [];
					tiupBrandList.forEach((item, index) => {
						var ItemIndex = make.findIndex(
							(b) => b.id === item.make_id
						);
						make.splice(ItemIndex, 1);
					});
					this.setState({
						tiupBrandList: tiupBrandList,
						loading: false,
						make: make,
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	handleChange = (sname, ovalue) => {
		const { tieupinfo } = this.state;
		if (sname === "make_id") {
			tieupinfo[sname] = ovalue.id;
		}
		if (sname === "is_oem") {
			tieupinfo[sname] = ovalue.oem;
		}
		this.setState({
			tieupinfo: tieupinfo,
		});
	};

	ChangeCheckbox = (event) => {
		const target = event.target;
		let { tieupinfo } = this.state;
		tieupinfo[target.name] = target.checked ? "1" : "0";
		this.setState({ tieupinfo: tieupinfo });
	};

	validForm = () => {
		let { tieupinfo, regexpEmail } = this.state;
		let formIsValid = true,
			errors = {};

		if (!tieupinfo["make_id"]) {
			errors["make_id"] = this.props.t("TIEUP_BRAND.REQUIRED");
			formIsValid = false;
		}

		this.setState({ errors: errors });
		return formIsValid;
	};

	saveTieupBrand = (event) => {
		event.preventDefault();
		let { tieupinfo, tieup_id } = this.state;
		if (tieup_id) tieupinfo["id"] = tieup_id;
		if (this.validForm()) {
			this.setState({ loading: true });
			tieupinfo = {
				data: [
					{
						make_id: tieupinfo.make_id,
						is_oem: tieupinfo.is_oem ? tieupinfo.is_oem : "0",
					},
				],
			};
			AuditService.saveTieUpBrand(tieupinfo)
				.then((response) => {
					if (
						response.status === 200 &&
						response.data.status === 200
					) {
						toast.success(response.data.message);
						this.setState({
							tieupinfo: tieupinfo,
							loading: false,
							redirectTolist: true,
						});
					} else {
						this.setState({ loading: false });
					}
				})
				.catch((error) => {
					toast.error(error);
				});
		}
	};
	handleRedirect = () => {
		this.setState({ redirectTolist: true });
	};

	render() {
		const {
			loading,
			tieupinfo,
			tieup_id,
			errors,
			make,
			redirectTolist,
		} = this.state;
		if (redirectTolist) return <Redirect to="/tie-up-brand" />;

		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-6 col-sm-offset-3">
						<h1>
							{tieup_id
								? this.props.t(
										"TIEUP_BRAND.UPDATE_TIE_UP_BRAND"
								  ) + ` #${tieup_id}`
								: this.props.t("TIEUP_BRAND.ADD_TIE_UP_BRAND")}
						</h1>
						<div className="card">
							{loading && (
								<PageLoader loaderClass="text-center" />
							)}

							<div className="card-body">
								<div className="row">
									<form onSubmit={this.saveTieupBrand}>
										<div className="col-sm-6 col-md-6 form-group">
											<label>
												{this.props.t(
													"TIEUP_BRAND.MAKE"
												)}
											</label>

											<Select
												id="make_id"
												onChange={this.handleChange.bind(
													this,
													"make_id"
												)}
												options={make}
												name="make_id"
												placeholder={this.props.t(
													"TIEUP_BRAND.MAKE"
												)}
												value={make.filter(
													({ id }) =>
														id === tieupinfo.make_id
												)}
												getOptionLabel={({ make }) =>
													make
												}
												getOptionValue={({ id }) => id}
											/>
											<span className="error show">
												{errors.make_id || ""}
											</span>
										</div>

										<div className="col-sm-6 col-md-6 form-group">
											<label></label>

											<CheckBox
												label={this.props.t(
													"TIEUP_BRAND.IS_OEM"
												)}
												name="is_oem"
												type="checkbox"
												id="is_oem"
												checked={
													tieupinfo.is_oem === "1"
														? true
														: false
												}
												value="1"
												onChange={this.ChangeCheckbox}
											/>
										</div>

										<div className="col-sm-12 form-group text-right">
											<button
												type="button"
												className="btn btn-default mrg-r15"
												onClick={this.handleRedirect}
											>
												{this.props.t(
													"TIEUP_BRAND.CANCEL"
												)}
											</button>
											<button
												type="submit"
												className="btn btn-primary "
												disabled={loading}
											>
												{tieup_id
													? this.props.t(
															"TIEUP_BRAND.UPDATE"
													  )
													: this.props.t(
															"TIEUP_BRAND.CREATE"
													  )}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ config, inventory, lead, auth }) => {
	return {
		mmv: inventory.mmv,
		authUser: auth.authUser.authUser,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getMMV: bindActionCreators(InventoryAction.getMMV, dispatch),
			getCityState: bindActionCreators(
				InventoryAction.getCityState,
				dispatch
			),
		},
	};
};

export default withTranslation("audit")(
	connect(mapStateToProps, mapDispatchToProps)(EditTieupBrand)
);
