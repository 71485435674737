import React, { Component } from "react";
import PreviousActivity from "./PreviousActivity";
import PreferencesCard from "./PreferencesCard";
import FinanceDetailsCard from "./FinanceDetailsCard";
import ManageStatusCard from "./ManageStatusCard";
import AssignCarList from "./AssignCarList";
import ModalPopup from "./../../elements/Popup";
import PersonalDetailForm from "./PersonalDetailForm";
import {
	LeadActions,
	ConfigAction,
	InventoryAction,
} from "../../../store/action";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { default as config } from "../../../config/config";
import dateformat from "dateformat";
import PageLoader from "../../elements/PageLoader";
import MyactiveForm from "./MyactiveForm";
import * as constant from "../../../config/constant";
import _ from "lodash";
import SaveAudit from "../../audit/component/SaveAudit";

class LeadsDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showTab: "1",
			personalModal: false,
			leadDetail: {},
			cityList: [],
			statusList: [],
			dealerList: [],
			mappedDseList: [],
			attributionList: [],
			billingStatusList: [],
			relationship_list: [],
			reasonsList: [],
			toploading: true,
			statusReasonList: [],
			myactiveModal: false,
			copiedText: "",
			istextCopied: false,
			assigncarList: [],
			mmv: {},
			stnkList: [],
			showAuditForm: false,
		};
		this.scrollDiv = React.createRef();
	}
	componentDidMount = async () => {
		const leadId = new URLSearchParams(window.location.search).get("id");
		const is_audit = new URLSearchParams(window.location.search).get(
			"is_audit"
		);
		const is_reaudit = new URLSearchParams(window.location.search).get(
			"is_reaudit"
		);
		let showAuditForm = is_audit || is_reaudit ? true : false;
		await this.setState({ showAuditForm: showAuditForm });
		this.fetchDesiredData();
		if (leadId) {
			this.getCustomerInformation(leadId);
		}
		if (showAuditForm) this.scrollToBottom();
	};
	fetchDesiredData = async () => {
		const postData = [];
		const secondPost = [];
		if (
			!(
				this.props.config &&
				this.props.config.city_state_list instanceof Array
			)
		) {
			postData.push("city_state_list");
		}
		if (
			!(
				this.props.config &&
				this.props.config.status_list instanceof Array
			)
		) {
			postData.push("status_list");
		}
		if (
			!(
				this.props.config &&
				this.props.config.test_drive_status_list instanceof Array
			)
		) {
			secondPost.push("test_drive_status_list");
		}
		if (
			!(
				this.props.config &&
				this.props.config.dealer_visit_status_list instanceof Array
			)
		) {
			secondPost.push("dealer_visit_status_list");
		}
		if (
			!(
				this.props.config &&
				this.props.config.dealer_list instanceof Array
			)
		) {
			postData.push("dealer_list");
		}
		if (
			!(this.props.config && this.props.config.dse_list instanceof Array)
		) {
			postData.push("dse_list");
		}
		if (
			!(
				this.props.config &&
				this.props.config.source_list instanceof Array
			)
		) {
			secondPost.push("source_list");
		}
		if (
			!(
				this.props.config &&
				this.props.config.attribution_status_list instanceof Array
			)
		) {
			postData.push("attribution_status_list");
		}
		if (
			!(
				this.props.config &&
				this.props.config.billing_status_list instanceof Array
			)
		) {
			postData.push("billing_status_list");
		}
		if (
			!(
				this.props.config &&
				this.props.config.relationship_list instanceof Array
			)
		) {
			postData.push("relationship_list");
		}
		if (
			!(
				this.props.config &&
				this.props.config.reasons_list instanceof Array
			)
		) {
			postData.push("reasons_list");
		}
		if (
			!(
				this.props.config &&
				this.props.config.status_reasons instanceof Array
			)
		) {
			postData.push("status_reasons");
		}
		if (
			!(this.props.config && this.props.config.stnk_list instanceof Array)
		) {
			postData.push("stnk_list");
		}
		if (postData.length) {
			const requestData = {
				config_data: [...postData],
				page_type: "detail",
			};
			this.props.actions.getConfigData(requestData);
		}
		if (secondPost.length) {
			const requestData = {
				config_data: [...secondPost],
				page_type: "detail",
			};
			this.props.actions.getConfigData(requestData);
		}
		if (!(this.props.mmv && Object.keys(this.props.mmv).length > 0)) {
			await this.props.actions.getMMV();
		}
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		const updatedState = {};
		if (prevState.leadDetail !== nextProps.leadDetail) {
			updatedState["leadDetail"] = nextProps.leadDetail;
			updatedState["toploading"] = nextProps.leadDetail ? false : true;
		}

		if (nextProps.city && nextProps.city !== prevState.cityList) {
			updatedState["cityList"] = nextProps.city;
		}

		if (
			nextProps.configData &&
			nextProps.configData.status_list &&
			nextProps.configData.status_list !== prevState.statusList
		) {
			updatedState["statusList"] = nextProps.configData.status_list;
		}

		if (
			nextProps.configData &&
			nextProps.configData.dealer_list &&
			nextProps.configData.dealer_list !== prevState.dealerList
		) {
			updatedState["dealerList"] = nextProps.configData.dealer_list;
		}
		if (
			nextProps.configData &&
			nextProps.configData.dse_list &&
			nextProps.configData.dse_list !== prevState.mappedDseList
		) {
			updatedState["mappedDseList"] = nextProps.configData.dse_list;
		}
		if (
			nextProps.configData &&
			nextProps.configData.attribution_status_list &&
			nextProps.configData.attribution_status_list !==
				prevState.attributionList
		) {
			updatedState["attributionList"] =
				nextProps.configData.attribution_status_list;
		}
		if (
			nextProps.configData &&
			nextProps.configData.billing_status_list &&
			nextProps.configData.billing_status_list !==
				prevState.billingStatusList
		) {
			updatedState["billingStatusList"] =
				nextProps.configData.billing_status_list;
		}
		if (
			nextProps.configData &&
			nextProps.configData.relationship_list &&
			nextProps.configData.relationship_list !==
				prevState.relationship_list
		) {
			updatedState["relationship_list"] =
				nextProps.configData.relationship_list;
		}

		if (
			nextProps.configData &&
			nextProps.configData.reasons_list &&
			nextProps.configData.reasons_list !== prevState.reasonsList
		) {
			updatedState["reasonsList"] = nextProps.configData.reasons_list;
		}

		if (
			nextProps.configData &&
			nextProps.configData.status_reasons &&
			nextProps.configData.status_reasons !== prevState.statusReasonList
		) {
			updatedState["statusReasonList"] =
				nextProps.configData.status_reasons;
		}
		if (
			nextProps.assigncarList &&
			nextProps.assigncarList.record &&
			prevState.assigncarList !== nextProps.assigncarList.record
		) {
			updatedState["assigncarList"] = nextProps.assigncarList.record;
		}
		if (nextProps.mmv !== prevState.mmv) {
			updatedState["mmv"] = nextProps.mmv;
		}

		if (
			nextProps.configData &&
			nextProps.configData.stnk_list &&
			nextProps.configData.stnk_list !== prevState.stnkList
		) {
			updatedState["stnkList"] = nextProps.configData.stnk_list;
		}

		return updatedState;
	}
	getCustomerInformation = async (leadId) => {
		const postData = {
			lead_id: leadId,
			_with: ["assigned_cars", "preference_list", "lead_detail"],
		};
		this.props.actions.getLeadDetail(postData);
	};
	showPersonalModal = (type = "personal_detail") => {
		let showmodal =
			type === "personal_detail" ? "personalModal" : "myactiveModal";
		this.setState({ [showmodal]: true });
	};
	closePersonalModal = () => {
		this.setState({ personalModal: false, myactiveModal: false });
	};
	updateCustomer = async (leadDetail, unchangedProps) => {
		const { status, response } = await this.props.actions.updateCustomer(
			leadDetail
		);
		if (status) {
			await this.props.actions.updateHistoryFlag(true);
			const leadId = new URLSearchParams(window.location.search).get(
				"id"
			);
			this.getCustomerInformation(leadId);
			toast.success(response);
			this.closePersonalModal();
			// this.setState({ leadDetail: unchangedProps });
		}
	};
	updateWhatsappClickCount = async (history_type) => {
		const leadId = new URLSearchParams(window.location.search).get("id");
		const postData = {
			lead_id: leadId,
			update_type: "lead",
			history_type: history_type,
		};

		this.props.actions.updateWhatsappClickCount(postData);
	};
	updateMyActive = async (
		action_type = "reassign",
		agent_id = 0,
		agent_name = "",
		comment = ""
	) => {
		let leadDetail = this.state.leadDetail || {};
		let lead_ids = [leadDetail.id || 0];
		this.setState({ toploading: true });
		const postData = {
			lead_id: lead_ids,
			agent_id: agent_id,
			action_type: leadDetail.myactive_agent_id ? "reassign" : "assign",
			comment: comment || "",
		};
		const { status, response } = await this.props.actions.updateMyActive(
			postData
		);
		if (status) {
			toast.success(response);
			this.props.actions.updateHistoryFlag(true);
		}
		this.state.leadDetail.ActiveAgent = agent_name;
		this.state.leadDetail.myactive_agent_id = agent_id;
		this.setState({ toploading: false, myactiveModal: false });
	};

	copyTextToClipboard = (leadDetail) => {
		let { copiedText, mmv, assigncarList, cityList } = this.state;
		assigncarList = assigncarList.filter((e) => Number(e.status) === 1);
		let cityName = "";
		if (
			cityList &&
			cityList instanceof Array &&
			leadDetail &&
			Object.keys(leadDetail).length
		) {
			const cityInfo = cityList.find(
				(e) => +e.id === +leadDetail.lead_city
			);

			if (cityInfo) cityName = cityInfo.name;
		}
		let textSapmle = `Please find below the Lead Details.\n`;
		textSapmle += `\nName : ${leadDetail.lead_name}\nMobile : ${leadDetail.lead_mobile}\nCity : ${cityName}`;
		if (assigncarList.length && assigncarList.length === 1) {
			let makeName =
				mmv && assigncarList[0].make_id && mmv.make && mmv.make.length
					? mmv.make
							.filter(
								(v) =>
									Number(v.id) ===
									Number(assigncarList[0].make_id)
							)
							.map((v) => v.make)[0]
					: "";
			let modelName =
				mmv &&
				assigncarList[0].model_id &&
				mmv.model &&
				mmv.model.length
					? mmv.model
							.filter(
								(v) =>
									Number(v.id) ===
									Number(assigncarList[0].model_id)
							)
							.map((v) => v.m)[0]
					: "";

			textSapmle += `\n\nCustomer is looking for ${makeName} ${modelName}.`;
		}
		if (assigncarList.length && assigncarList.length > 1) {
			textSapmle += `\n\nCustomer is comparing following cars :`;
			let makeModeText = "";
			let uniqueMake = [],
				uniqueModel = [];
			_.forEach(assigncarList, (value) => {
				/* Check to display unique make & model on copy lead details */
				if (
					!(
						uniqueMake.includes(Number(value.make_id)) &&
						uniqueModel.includes(Number(value.model_id))
					)
				) {
					uniqueMake.push(Number(value.make_id));
					uniqueModel.push(Number(value.model_id));
					let makeName =
						mmv && value.make_id && mmv.make && mmv.make.length
							? mmv.make
									.filter(
										(v) =>
											Number(v.id) ===
											Number(value.make_id)
									)
									.map((v) => v.make)[0]
							: "";
					let modelName =
						mmv && value.model_id && mmv.model && mmv.model.length
							? mmv.model
									.filter(
										(v) =>
											Number(v.id) ===
											Number(value.model_id)
									)
									.map((v) => v.m)[0]
							: "";
					makeModeText += `\n${makeName} ${modelName}`;
				}
			});
			textSapmle += makeModeText;
		}
		copiedText = textSapmle;
		setTimeout(() => {
			this.setState({ istextCopied: false });
		}, 1000);
		this.setState({ copiedText: copiedText, istextCopied: true });
		navigator.clipboard.writeText(copiedText);
	};

	scrollToBottom() {
		this.scrollDiv.current.scrollIntoView({ behavior: "smooth" });
	}

	render() {
		const {
			leadDetail,
			cityList,
			statusList,
			dealerList,
			mappedDseList,
			attributionList,
			billingStatusList,
			toploading,
			relationship_list,
			reasonsList,
			statusReasonList,
			copiedTitle,
			stnkList,
			showAuditForm,
		} = this.state;

		let cityName = "";
		if (
			cityList &&
			cityList instanceof Array &&
			leadDetail &&
			Object.keys(leadDetail).length
		) {
			const cityInfo = cityList.find(
				(e) => +e.id === +leadDetail.lead_city
			);

			if (cityInfo) cityName = cityInfo.name;
		}
		let { authUser } = this.props;
		if (
			leadDetail &&
			leadDetail.is_audit_eligible_lead == 1 &&
			showAuditForm
		) {
			this.scrollToBottom();
		}
		return (
			<div>
				<div className="mainDetails">
					{toploading && (
						<PageLoader
							loaderClass=" text-center loader content-loader"
							loadertext="true"
						/>
					)}
					{leadDetail && (
						<ul className="mainDetails-list">
							{/* <li>
              <button type="button" className="backBtn">
                <i className="ic-arrow_back-black-24dp"></i>
              </button>
            </li> */}
							<li className="nameStatus">
								<div className="title">
									<strong>
										{leadDetail.id
											? `#${leadDetail.id}`
											: ""}
									</strong>
								</div>
								<div className="details">
									<span className="sub-value">
										<strong className="f16">
											{leadDetail.lead_name}
										</strong>
									</span>
									<span className="sub-value">
										{leadDetail.status}{" "}
										<span className="badge badge-error">
											{leadDetail.sub_status}
										</span>
									</span>
								</div>
							</li>
							<li>
								<div className="title">
									{this.props.t("CUSTOMER_INFO.EMAIL")}
								</div>
								<div className="details">
									<span className="sub-value">
										{leadDetail.lead_email}
									</span>
								</div>
							</li>
							<li>
								<div className="title">
									{this.props.t("CUSTOMER_INFO.MOBILE")}
								</div>
								<div className="details">
									<span className="sub-value">
										{(leadDetail.lead_mobile
											? leadDetail.lead_mobile
											: ""
										).includes("+")
											? leadDetail.lead_mobile
											: `${config.constants.ISD_CODE[0].isdCode}${leadDetail.lead_mobile}`}
									</span>
									{config.constants.IS_VIBER_SHOW ? (
										<a
											href={
												"https://msng.link/o/?" +
												`${
													(leadDetail.lead_mobile
														? leadDetail.lead_mobile
														: ""
													).includes("+")
														? leadDetail.lead_mobile
														: `${config.constants.ISD_CODE[0].isdCode}${leadDetail.lead_mobile}`
												}` +
												"=vi"
											}
											target="_blank"
											onClick={() =>
												this.updateWhatsappClickCount(
													"VIBER"
												)
											}
										>
											<i
												className="viber-img"
												aria-hidden="true"
												title={this.props.t(
													"CUSTOMER_INFO.VIBER_TITLE"
												)}
											></i>
										</a>
									) : null}
									<a
										href={
											"https://web.whatsapp.com/send?phone=" +
											`${
												(leadDetail.lead_mobile
													? leadDetail.lead_mobile
													: ""
												).includes("+")
													? leadDetail.lead_mobile
													: `${config.constants.ISD_CODE[0].isdCode}${leadDetail.lead_mobile}`
											}` +
											"&text="
										}
										target="_blank"
										onClick={() =>
											this.updateWhatsappClickCount(
												"WHATSAPP"
											)
										}
									>
										<i
											className="whatsapp-img"
											aria-hidden="true"
											title={this.props.t(
												"CUSTOMER_INFO.WHATSAPP_TITLE"
											)}
										></i>
									</a>
								</div>
								<span
									title={this.props.t(
										"CUSTOMER_INFO.COPY_TITLE"
									)}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 24 24"
										onClick={this.copyTextToClipboard.bind(
											this,
											leadDetail
										)}
									>
										<path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
									</svg>
									{this.state.istextCopied ? (
										<span style={{ color: "green" }}>
											{this.props.t(
												"CUSTOMER_INFO.COPY_TEXT"
											)}
										</span>
									) : null}
								</span>
							</li>
							<li>
								<div className="title">
									{this.props.t("CUSTOMER_INFO.CITY")}
								</div>
								<div className="details">
									<span className="sub-value">
										{cityName}
									</span>
								</div>
							</li>
							<li>
								<div className="title">
									{this.props.t("CUSTOMER_INFO.FOLLOW_UP")}
								</div>
								<div className="details">
									<span className="sub-value">
										{leadDetail.followup_date &&
											dateformat(
												leadDetail.followup_date,
												config.constants.DATE_FORMAT
											)}
									</span>
								</div>
							</li>
							<li>
								<div className="title">
									{this.props.t("CUSTOMER_INFO.CREATED_ON")}
								</div>
								<div className="details">
									<span className="sub-value">
										{leadDetail.created_date &&
											dateformat(
												leadDetail.created_date,
												config.constants.DATE_FORMAT
											)}
									</span>
								</div>
							</li>
							<li>
								<div className="title">
									{this.props.t(
										"CUSTOMER_INFO.LAST_CALL_STATUS"
									)}
								</div>
								<div className="details">
									<span className="sub-value">
										{leadDetail.call_status}
									</span>
								</div>
							</li>
							<li>
								<div className="title">
									{this.props.t("CUSTOMER_INFO.TOTAL_CALL")}
								</div>
								<div className="details">
									<span className="sub-value">
										{leadDetail.total_calls}
									</span>
								</div>
							</li>
							<li>
								<div className="title">
									{this.props.t("CUSTOMER_INFO.MY_ACTIVE")}
								</div>
								<div className="details">
									<span className="sub-value">
										{leadDetail.ActiveAgent || "N/A"}
									</span>
									{constant.isUserHasRightsToAccessPage(
										"lead",
										"lead",
										"/reassign_my_active"
									) ? (
										<span
											className="link-text"
											onClick={() =>
												this.showPersonalModal(
													"myactive"
												)
											}
										>
											<i className="ic-create-24px"></i>
										</span>
									) : (
										""
									)}
								</div>
							</li>
							<li>
								<span
									className="link-text"
									onClick={() =>
										this.showPersonalModal(
											"personal_detail"
										)
									}
								>
									{this.props.t("CUSTOMER_INFO.EDIT")}
								</span>
							</li>
						</ul>
					)}
				</div>
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-8 pad-r10">
							<PreviousActivity />
							<AssignCarList />
							<div ref={this.scrollDiv}>
								{leadDetail &&
								leadDetail.is_audit_eligible_lead == 1 &&
								showAuditForm ? (
									<SaveAudit
										lead_id={leadDetail && leadDetail.id}
									/>
								) : null}
							</div>
						</div>
						<div className="col-sm-4 pad-l10">
							<PreferencesCard />
							<FinanceDetailsCard />
							<ManageStatusCard
								statusList={statusList}
								leadId={leadDetail && leadDetail.id}
								make_list={
									this.props.filterMMV &&
									this.props.filterMMV.make
										? this.props.filterMMV.make
										: []
								}
								model_list={
									this.props.filterMMV &&
									this.props.filterMMV.model
										? this.props.filterMMV.model
										: []
								}
								version_list={
									this.props.filterMMV &&
									this.props.filterMMV.version
										? this.props.filterMMV.version
										: []
								}
								leadDetail={leadDetail || {}}
								getCustomerInformation={
									this.getCustomerInformation
								}
								dealerList={dealerList || []}
								mappedDseList={mappedDseList || []}
								attribution_list={attributionList}
								billing_status_list={billingStatusList}
								relationship_list={relationship_list}
								city_list={cityList}
								reasons_list={reasonsList}
								status_reasons={statusReasonList}
								stnk_list={stnkList}
							/>
						</div>
					</div>
				</div>
				{this.state.personalModal === false ? (
					""
				) : (
					<ModalPopup
						id="asd"
						className="modal modal-lg"
						title=""
						modalClose={this.closePersonalModal}
					>
						<PersonalDetailForm
							leadDetail={leadDetail}
							cityList={cityList}
							modalClose={this.closePersonalModal}
							updateCustomer={this.updateCustomer}
							authUser={authUser}
						/>
					</ModalPopup>
				)}
				{this.state.myactiveModal === false ? (
					""
				) : (
					<ModalPopup
						id="myactive_modal"
						className="modal modal-lg"
						title=""
						modalClose={this.closePersonalModal}
					>
						<MyactiveForm
							modalClose={this.closePersonalModal}
							updateMyActive={this.updateMyActive}
							current_agent_id={leadDetail.myactive_agent_id || 0}
							current_agent_name={leadDetail.ActiveAgent || ""}
						/>
					</ModalPopup>
				)}
			</div>
		);
	}
}
const mapStateToProps = ({ lead, config, inventory, auth }) => {
	return {
		leadDetail: lead.lead_detail,
		configData: config,
		mmv: inventory.mmv,
		authUser: auth.authUser.authUser,
		city: inventory.city,
		state: inventory.state,
		filterMMV: inventory.filterMMV,
		assigncarList: lead.assigned_cars,
		// preference_list : lead.preference_list
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getLeadDetail: bindActionCreators(
				LeadActions.getLeadDetail,
				dispatch
			),
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
			updateCustomer: bindActionCreators(
				LeadActions.updateCustomer,
				dispatch
			),
			getMMV: bindActionCreators(InventoryAction.getMMV, dispatch),
			getDealerDse: bindActionCreators(
				LeadActions.getDealerDse,
				dispatch
			),
			updateWhatsappClickCount: bindActionCreators(
				LeadActions.updateWhatsappClickCount,
				dispatch
			),
			updateHistoryFlag: bindActionCreators(
				LeadActions.updateHistoryFlag,
				dispatch
			),
			updateMyActive: bindActionCreators(
				LeadActions.updateMyActive,
				dispatch
			),
		},
	};
};
export default withTranslation("lead_detail")(
	connect(mapStateToProps, mapDispatchToProps)(LeadsDetails)
);
