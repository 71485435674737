import MasterService from "./MasterService";

export const ConfigService = {
	...MasterService,

	getAllModalStatus() {
		const postData = {
			config_data: ["model_status_list"],
		};
		return this.post("/config/get_data", postData);
	},
	getSources() {
		const postData = {
			config_data: ["source_list"],
		};
		return this.post("/config/get_data", postData);
	},
	getConfigData(postData) {
		return this.post("/config/get_data", postData);
	},
};
