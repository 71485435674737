import { UserConstants } from "../constants/user.constants";
import { UserService } from "../../service/userService";
export const UserActions = {
	updateWebRule,
	getRuleData,
	getProcessCampaignList,
	saveProcessCampaignList,
	getBlackList,
	saveBlackList,
	getCampaignPackList,
	updateCampaignPackData,
	getAssignPackCampaign,
	updateAssignPackCampaign,
	getCampaignConfiguratorInfo,
	getGmAssociatedDse,
	syncDialerReportData,
};
function updateWebRule(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { message } = {},
			} = await UserService.saveRules(postData);

			if (status === 200) {
				return {
					status: true,
					response: message,
				};
			} else
				return {
					status: false,
					response: message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}
function getRuleData(postData) {
	return async (dispatch) => {
		try {
			const { status, data: { data } = {} } = await UserService.getRules(
				postData
			);
			if (status === 200) {
				dispatch(success(data));
			} else {
				dispatch(failure("not_found"));
			}
		} catch (err) {
			dispatch(failure(err));
		}
	};
	function success(resp) {
		return { type: UserConstants.GET_RULE, resp };
	}
	function failure(error) {
		return { type: UserConstants.GET_RULE_ERR, error };
	}
}
function getProcessCampaignList(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { data, pagination } = {},
			} = await UserService.getProcessCampaignList(postData);
			if (status === 200) {
				const resData = {
					record: data,
					pagination,
				};
				dispatch(success(resData));
			} else {
				dispatch(failure("not_found"));
			}
		} catch (err) {
			dispatch(failure(err));
		}
	};
	function success(resp) {
		return { type: UserConstants.GET_PROCESS_CAMPAIGN_LIST, resp };
	}
	function failure(error) {
		return { type: UserConstants.GET_PROCESS_CAMPAIGN_LIST_ERR, error };
	}
}
function saveProcessCampaignList(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { message } = {},
			} = await UserService.saveProcessCampaignList(postData);

			if (status === 200) {
				return {
					status: true,
					response: message,
				};
			} else
				return {
					status: false,
					response: message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}
function getBlackList(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { data, pagination } = {},
			} = await UserService.getBlackList(postData);
			if (status === 200) {
				const resData = {
					record: data,
					pagination,
				};
				dispatch(success(resData));
			} else {
				dispatch(failure("not_found"));
			}
		} catch (err) {
			dispatch(failure(err));
		}
	};
	function success(resp) {
		return { type: UserConstants.GET_BLACK_LIST, resp };
	}
	function failure(error) {
		return { type: UserConstants.GET_BLACK_LIST_ERR, error };
	}
}
function saveBlackList(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { message } = {},
			} = await UserService.saveBlackList(postData);

			if (status === 200) {
				return {
					status: true,
					response: message,
				};
			} else
				return {
					status: false,
					response: message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}
function getCampaignPackList(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { data, pagination } = {},
			} = await UserService.getCampaignPackList(postData);
			if (status === 200) {
				const resData = {
					record: data,
					pagination,
				};
				dispatch(success(resData));
				return resData;
			} else {
				dispatch(failure("not_found"));
				return "not_found";
			}
		} catch (err) {
			dispatch(failure(err));
		}
	};
	function success(resp) {
		return { type: UserConstants.GET_CAMPAIGN_PACK_LIST, resp };
	}
	function failure(error) {
		return { type: UserConstants.GET_CAMPAIGN_PACK_LIST_ERR, error };
	}
}
function getCampaignConfiguratorInfo(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: {
					data: { campaign_info = {}, all_packs = [] } = {},
				} = {},
			} = await UserService.getCampaignConfiguratorInfo(postData);
			if (status === 200) {
				const resData = {
					campaign_info: campaign_info,
					all_packs: all_packs,
				};
				dispatch(success(resData));
			} else {
				dispatch(failure("not_found"));
			}
		} catch (err) {
			dispatch(failure(err));
		}
	};
	function success(resp) {
		return { type: UserConstants.GET_CAMPAIGN_INFO, resp };
	}
	function failure(error) {
		return { type: UserConstants.GET_CAMPAIGN_INFO_ERR, error };
	}
}
function updateCampaignPackData(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { message, data } = {},
			} = await UserService.saveCampaignList(postData);

			if (status === 200) {
				const { campaign_id } = data;
				return {
					status: true,
					response: message,
					data: campaign_id,
				};
			} else
				return {
					status: false,
					response: message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}
function getAssignPackCampaign(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { data } = {},
			} = await UserService.getCampaignPackMapping(postData);
			if (status === 200) {
				dispatch(success(data));
				return true;
			} else {
				dispatch(failure("not_found"));
				return false;
			}
		} catch (err) {
			dispatch(failure(err));
			return false;
		}
	};
	function success(resp) {
		return { type: UserConstants.GET_ASSIGNED_PACKS, resp };
	}
	function failure(error) {
		return { type: UserConstants.GET_ASSIGNED_PACKS_ERR, error };
	}
}
function updateAssignPackCampaign(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { message, data } = {},
			} = await UserService.updateCampaignPackMapping(postData);

			if (status === 200) {
				return {
					status: true,
					response: message,
					data: data,
				};
			} else
				return {
					status: false,
					response: message,
					data: data,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}
function getGmAssociatedDse(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { data } = {},
			} = await UserService.getGmAssociatedDse(postData);
			if (status === 200) {
				dispatch(success(data));
				return true;
			} else {
				dispatch(failure("not_found"));
				return false;
			}
		} catch (err) {
			dispatch(failure(err));
			return false;
		}
	};
	function success(resp) {
		return { type: UserConstants.GET_ASSOCIATED_GM_DSE, resp };
	}
	function failure(error) {
		return { type: UserConstants.GET_ASSOCIATED_GM_DSE_ERR, error };
	}
}
function syncDialerReportData() {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { message, data } = {},
			} = await UserService.syncDialerReportData();

			if (status === 200) {
				return {
					status: true,
					response: message,
				};
			} else
				return {
					status: false,
					response: message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}
