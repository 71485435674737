import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import SearchCampaignList from "./SearchCampaignList";
import { UserActions } from "../../store/action";
import { bindActionCreators } from "redux";
import dateformat from "dateformat";
import { default as Config } from "../../config/config";
import SwitchBtn from "../elements/SwitchBtn";
import ConfirmPopUp from "../common/ConfirmPopUp";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import PageLoader from "../elements/PageLoader";

class CampaignList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 1,
			searchData: {},
			reset: false,
			campaignPackList: [],
			pagination: {},
			PopOver_id: null,
			loading: false,
		};
	}
	componentDidMount = () => {
		this.getCampaignPackList();
	};
	static getDerivedStateFromProps = (nextProps, prevProps) => {
		const updateProps = [];
		if (
			nextProps.campaignPackList &&
			nextProps.campaignPackList !== prevProps.campaignPackList
		) {
			updateProps["campaignPackList"] = nextProps.campaignPackList;
		}
		if (nextProps.pagination !== prevProps.pagination) {
			updateProps["pagination"] = nextProps.pagination;
		}

		return updateProps;
	};
	handlePageChange = (page) => {
		this.setState({ page: page }, () => {
			this.getCampaignPackList();
		});
	};
	getCampaignPackList = async (search = {}, toReset = false) => {
		await this.setState({ loading: true });
		let { searchData, page } = this.state;

		let postData = {};
		if (search) {
			searchData = search;
		}
		if (toReset) {
			searchData = {};
		}
		postData = {
			...searchData,
			page: page,
		};

		this.setState(
			{ searchData: searchData, campaignPackList: [], loading: true },
			async () => {
				let packRes = await this.props.actions.getCampaignPackList(
					postData
				);
				if (packRes) this.setState({ loading: false });
			}
		);
	};
	displayPopUp = (list_id) => {
		this.setState({ PopOver_id: list_id });
	};
	confirmNo = () => {
		this.setState({ PopOver_id: null });
	};
	confirmYes = async (data) => {
		const dataval = { ...data };
		dataval.status = data.status === "1" ? "0" : "1";
		dataval.is_status_request = true;
		this.updateCampaign(dataval);
		// this.setState({ loading: true });

		this.displayPopUp(null);
	};
	validateCampaignData = (data) => {
		let error = null;
		if (!data.campaign_name)
			error = this.props.t("CAMPAIGN_PACK.ERROR.CAMPAIGN_NAME");
		else if (!data.reporting_name)
			error = this.props.t("CAMPAIGN_PACK.ERROR.REPORTING_NAME");
		else if (!data.campaign_type)
			error = this.props.t("CAMPAIGN_PACK.ERROR.CAMPAIGN_TYPE");
		else if (!data.dealer_type)
			error = this.props.t("CAMPAIGN_PACK.ERROR.DEALER_TYPE");

		if (error) {
			toast.error(error);
			return false;
		}
		return true;
	};
	updateCampaign = async (data) => {
		const { searchData } = this.state;
		if (this.validateCampaignData(data)) {
			const postData = {};
			postData["campaign_name"] = data.campaign_name;
			postData["reporting_name"] = data.reporting_name;
			postData["campaign_type"] = data.campaign_type;
			postData["dealer_type"] = data.dealer_type;
			postData["campaign_id"] = data.id;
			if (data.gm_id) postData["gm_id"] = data.gm_id;
			postData["status"] = data.status;
			postData["is_status_request"] = data.is_status_request || false;
			const {
				status,
				response,
			} = await this.props.actions.updateCampaign(postData);
			if (status) {
				console.log(response);
				this.getCampaignPackList(searchData);
			}
			//
		}
	};
	render() {
		const {
			campaignPackList,
			PopOver_id,
			pagination,
			loading,
		} = this.state;
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="edit-list-card">
						<SearchCampaignList
							getCampaignPackList={this.getCampaignPackList}
						/>
						<div className="container-fluid">
							<div className="row mrg-b15">
								<div className="col-sm-6">
									{pagination && (
										<p className="countNo mrg-t5 mrg-b0">
											{pagination.total} -{" "}
											{this.props.t(
												"CAMPAIGN_PACK.CAMPAIGN_LIST"
											)}
										</p>
									)}
								</div>
								<div className="col-sm-6 text-right">
									<Link
										to={"/add_pack_campaign"}
										className="btn btn-success btn-sm"
										title={this.props.t(
											"CAMPAIGN_PACK.ADD_CAMPAIGN"
										)}
									>
										{this.props.t(
											"CAMPAIGN_PACK.ADD_CAMPAIGN"
										)}
									</Link>
								</div>
							</div>
							<div className="card">
								<div className="table-responsive">
									{campaignPackList instanceof Array && (
										<div>
											{campaignPackList.length > 0 && (
												<table className="table table-bordered-h table-hover processTable">
													<thead>
														<tr>
															{/* <th>
                                                    {this.props.t(
                                                        "CAMPAIGN_PACK.TBL_HEADING.CAMPAIGN_ID"
                                                    )}
                                                </th> */}
															<th>
																{this.props.t(
																	"CAMPAIGN_PACK.TBL_HEADING.CAMPAIGN_NAME"
																)}
															</th>
															<th>
																{this.props.t(
																	"CAMPAIGN_PACK.TBL_HEADING.CAMPAIGN_TYPE"
																)}
															</th>
															<th>
																{this.props.t(
																	"CAMPAIGN_PACK.TBL_HEADING.REPORTING_NAME"
																)}
															</th>
															<th>
																{this.props.t(
																	"CAMPAIGN_PACK.TBL_HEADING.DIALER_TYPE"
																)}
															</th>
															<th>
																{this.props.t(
																	"CAMPAIGN_PACK.TBL_HEADING.CREATED_DATE"
																)}
															</th>
															<th>
																{this.props.t(
																	"CAMPAIGN_PACK.TBL_HEADING.UPDATED_DATE"
																)}
															</th>
															<th>
																{this.props.t(
																	"CAMPAIGN_PACK.TBL_HEADING.ACTIONS"
																)}
															</th>
														</tr>
													</thead>
													<tbody>
														{campaignPackList.map(
															(e, index) => (
																<tr key={index}>
																	{/* <td>{e.id}</td> */}
																	<td>
																		{
																			e.campaign_name
																		}
																	</td>
																	<td>
																		{
																			e.campaign_type
																		}
																	</td>
																	<td>
																		{
																			e.reporting_name
																		}
																	</td>
																	<td>
																		{
																			e.dealer_type
																		}
																	</td>
																	<td>
																		{dateformat(
																			e.created_date,
																			Config
																				.constants
																				.DATE_FORMAT
																		)}
																	</td>
																	<td>
																		{dateformat(
																			e.updated_date,
																			Config
																				.constants
																				.DATE_FORMAT
																		)}
																	</td>
																	<td>
																		<Link
																			to={`/edit_pack_campaign/${e.id}`}
																			className="btn btn-link text-link mrg-r15"
																			title={this.props.t(
																				"CAMPAIGN_PACK.EDIT_CAMPAIGN"
																			)}
																		>
																			<i
																				className="ic-create-24px"
																				aria-hidden="true"
																			></i>
																		</Link>

																		{/* <Link
																			to={`/assign_pack_to_campaign/${e.id}`}
																			className="btn btn-link text-link mrg-r15"
																			title={this.props.t(
																				"CAMPAIGN_PACK.VIEW_CAMPAIGN_PACK"
																			)}
																		>
																			<i
																				className="ic-visibility-24px"
																				aria-hidden="true"
																			></i>
																		</Link> */}

																		<span>
																			<SwitchBtn
																				label=""
																				htmlFor={
																					"Status" +
																					index
																				}
																				id={
																					"Status" +
																					index
																				}
																				name={
																					"Status" +
																					index
																				}
																				checked={
																					e.status ===
																					"1"
																						? true
																						: false
																				}
																				onChange={this.displayPopUp.bind(
																					this,
																					e.id
																				)}
																			/>

																			{PopOver_id ===
																				e.id && (
																				<ConfirmPopUp
																					onConfirm={() =>
																						this.confirmYes(
																							e
																						)
																					}
																					onReject={() =>
																						this.confirmNo()
																					}
																					message={this.props.t(
																						"CAMPAIGN_PACK.ARE_YOU_SURE"
																					)}
																				/>
																			)}
																		</span>
																	</td>
																</tr>
															)
														)}
													</tbody>
												</table>
											)}

											{
												<div className="clearfix">
													{pagination &&
													pagination.total >= 1 ? (
														<div className="main-div-pagination">
															<div className="col-sm-6 text-light">
																{this.props.t(
																	"CAMPAIGN_PACK.PAGINATION.SHOWING"
																)}{" "}
																{
																	pagination.cur_page
																}{" "}
																{this.props.t(
																	"CAMPAIGN_PACK.PAGINATION.OF"
																)}{" "}
																{
																	pagination.pages
																}{" "}
																{this.props.t(
																	"CAMPAIGN_PACK.PAGINATION.PAGES"
																)}
															</div>
															<div className="col-sm-6">
																<Pagination
																	activePage={
																		+pagination.cur_page
																	}
																	itemsCountPerPage={
																		pagination.limit
																	}
																	totalItemsCount={
																		pagination.total
																	}
																	pageRangeDisplayed={
																		5
																	}
																	itemClass="page-item"
																	linkClass="page-link"
																	onChange={
																		this
																			.handlePageChange
																	}
																/>
															</div>
														</div>
													) : (
														""
													)}
												</div>
											}
										</div>
									)}
									{campaignPackList.length === 0 &&
									loading === false ? (
										<h4>
											{this.props.t(
												"CAMPAIGN_PACK.NOT_FOUND"
											)}
										</h4>
									) : (
										""
									)}
								</div>
								{loading && (
									<PageLoader
										loaderClass=" text-center loader content-loader"
										loadertext="true"
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ user, inventory, config }) => {
	return {
		campaignPackList: user.campaignPackList
			? user.campaignPackList.record
			: [],
		pagination: user.campaignPackList
			? user.campaignPackList.pagination
			: {},
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getCampaignPackList: bindActionCreators(
				UserActions.getCampaignPackList,
				dispatch
			),
			updateCampaign: bindActionCreators(
				UserActions.updateCampaignPackData,
				dispatch
			),
		},
	};
};
export default withTranslation("campaign_pack")(
	connect(mapStateToProps, mapDispatchToProps)(CampaignList)
);
