import React from "react";
import { Link } from "react-router-dom";
//import siteLogo from "../../webroot/images/carmudi-crm-logo.png";
import { default as config } from "../../config/config";
import * as constant from "./../../config/constant";
class Brandlogo extends React.Component {
	render() {
		return constant.isUserHasRightsToAccessPage(
			"lead",
			"lead",
			"/lead-list"
		) ? (
			<Link className="navbar-brand" to="/lead-list">
				<img src={config.constants.SITE_LOGO} alt="" />
			</Link>
		) : (
			<Link className="navbar-brand" to="/dashboard">
				<img src={config.constants.SITE_LOGO} alt="" />
			</Link>
		);
	}
}
export default Brandlogo;
