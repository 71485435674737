import React, { Component } from "react";
import { Link } from "react-router-dom";
import { webFormService } from "../../../../service/webFormService";
import { LeadService } from "../../../../service/leadService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import PageLoader from "../../../elements/PageLoader";
import SearchProcess from "../../component/process/SearchProcess";
class processList extends Component {
	constructor(props) {
		super(props);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.state = {
			loading: true,
			processList: this.props.processList || [],
			pagination: this.props.pagination || {},
			country_list: this.props.country_list || [],
			business_type_list: this.props.business_type_list || [],
			filterData: { page: 1 },
			PopOver: false,
			actionType: "status",
		};
	}

	componentDidMount = async () => {
		await this.getConfigData();
		await this.getProcessList();
	};

	// get config
	getConfigData = async () => {
		const requestBody = {
			config_data: ["country_list", "business_type_list"],
		};
		LeadService.getConfig(requestBody)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					this.setState({
						country_list: response.data.data.country_list || [],
						business_type_list:
							response.data.data.business_type_list || [],
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	getProcessList = async () => {
		let { filterData } = this.state;
		this.setState({ loading: true });
		filterData["list_type"] = "ALL";
		webFormService
			.getProcessList(filterData)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let processList = response.data.data || [];
					let pagination = response.data.pagination || {};

					this.setState({
						processList: processList,
						pagination: pagination,
						loading: false,
					});
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	handlePageChange(pageNumber) {
		let { filterData } = this.state;
		filterData["page"] = pageNumber;
		this.setState({ filterData: filterData, loading: true });
		this.getProcessList();
	}
	submitFilterForm = async (filterData) => {
		filterData.page = 1;
		this.setState(
			{
				loading: true,
				filterData: filterData,
			},
			async () => {
				await this.getProcessList();
			}
		);
	};

	updateProcessStatus = async (id, status) => {
		this.setState({ loading: true });
		webFormService
			.updateProcessStatus({ id: id, status: status })
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					this.setState(
						{
							loading: false,
							PopOver: false,
						},
						async () => {
							await this.getProcessList();
						}
					);
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	displayPopUp = (list_id, type) => {
		this.setState({ PopOver: list_id, actionType: type });
	};
	confirmNo = (status_id) => {
		this.setState({ PopOver: false });
	};
	confirmYes = (status_id) => {
		let process_id = this.state.PopOver;
		let actionType = this.state.actionType,
			status2upd = "2";
		if (actionType !== "delete") status2upd = status_id === "1" ? "0" : "1";
		this.updateProcessStatus(process_id, status2upd);
	};

	render() {
		const {
			loading,
			processList,
			filterData,
			pagination,
			country_list,
			business_type_list,
		} = this.state;
		return (
			<div>
				<SearchProcess
					onSubmitFilter={this.submitFilterForm}
					filterData={filterData}
					country_list={country_list}
					business_type_list={business_type_list}
				/>
				<div className="container-fluid">
					<div className="result-wrap">
						<div className="row mrg-b15">
							<div className="col-sm-6">
								<p className="countNo mrg-t5 mrg-b0">
									{pagination.total} -{" "}
									{this.props.t("PROCESS.PROCESS_LIST")}
								</p>
							</div>
							<div className="col-sm-6 text-right">
								<Link
									to={"/add_process"}
									className="btn btn-success btn-sm"
									title={this.props.t("PROCESS.ADD_PROCESS")}
								>
									{this.props.t("PROCESS.ADD")}
								</Link>
							</div>
						</div>

						<div className="card">
							<div className="table-responsive">
								{loading && (
									<PageLoader loaderClass="text-center" />
								)}
								<table className="table table-bordered-h table-hover processTable">
									<thead>
										<tr>
											<th>
												{this.props.t(
													"PROCESS.PROCESS"
												)}
											</th>
											<th>
												{this.props.t(
													"PROCESS.COUNTRY"
												)}
											</th>
											<th>
												{this.props.t(
													"PROCESS.BUSINESS_TYPE"
												)}
											</th>
											<th>
												{this.props.t("PROCESS.NAME")}
											</th>

											<th>
												{this.props.t("PROCESS.ACTION")}
											</th>
										</tr>
									</thead>

									<tbody>
										{processList.length > 0 ? (
											processList.map(
												(singleinfo, index) => (
													<React.Fragment
														key={singleinfo.id}
													>
														<tr>
															<td>
																<div>
																	{singleinfo.id ||
																		""}
																</div>
															</td>
															<td>
																<div>
																	{country_list &&
																	country_list.length &&
																	singleinfo.country_id
																		? country_list
																				.filter(
																					(
																						v
																					) =>
																						v.value ===
																						singleinfo.country_id
																				)
																				.map(
																					(
																						v
																					) =>
																						v.label
																				)[0]
																		: ""}
																</div>
															</td>
															<td>
																<div>
																	{business_type_list &&
																	business_type_list.length &&
																	singleinfo.business_type_id
																		? business_type_list
																				.filter(
																					(
																						v
																					) =>
																						v.business_type_id ===
																						singleinfo.business_type_id
																				)
																				.map(
																					(
																						v
																					) =>
																						v.name
																				)[0]
																		: ""}
																</div>
															</td>
															<td>
																<div>
																	{singleinfo.name ||
																		""}
																</div>
															</td>

															<td>
																<div>
																	<Link
																		to={
																			"/edit_process/" +
																			singleinfo.id
																		}
																		className="btn btn-link text-link mrg-r15"
																		title={this.props.t(
																			"PROCESS.EDIT"
																		)}
																	>
																		<i className="ic-create-24px"></i>
																	</Link>
																	{/* <SwitchBtn
																		label=""
																		htmlFor={
																			"Status" +
																			singleinfo.id
																		}
																		id={
																			"Status" +
																			singleinfo.id
																		}
																		name={
																			"Status" +
																			singleinfo.id
																		}
																		checked={
																			singleinfo.status ===
																			"1"
																				? true
																				: false
																		}
																		onChange={this.displayPopUp.bind(
																			this,
																			singleinfo.id,
																			"status"
																		)}
																	/>
																	{PopOver ===
																		singleinfo.id && (
																		<ConfirmPopUp
																			onConfirm={() =>
																				this.confirmYes(
																					singleinfo.status
																				)
																			}
																			onReject={() =>
																				this.confirmNo(
																					singleinfo.status
																				)
																			}
																			message={this.props.t(
																				"COMMON.ARE_YOU_SURE"
																			)}
																		/>
																	)} */}
																	{/* <span
																		className="btn btn-link text-link mrg-l15"
																		onClick={this.displayPopUp.bind(
																			this,
																			singleinfo.id,
																			"delete"
																		)}
																	>
																		<i className="ic-delete-24px"></i>
																	</span> */}
																</div>
															</td>
														</tr>
													</React.Fragment>
												)
											)
										) : (
											<tr>
												<td colSpan="10">
													{!loading ? (
														<h6 className="text-danger">
															{this.props.t(
																"PROCESS.NOT_FOUND"
															)}
														</h6>
													) : (
														""
													)}
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div className="clearfix">
								{pagination.pages >= 1 ? (
									<div className="main-div-pagination">
										<div className="col-sm-6 text-light">
											{this.props.t("PROCESS.SHOWING")}{" "}
											{filterData.page}{" "}
											{this.props.t("PROCESS.OF")}{" "}
											{pagination.pages}{" "}
											{this.props.t("PROCESS.PAGES")}
										</div>
										<div className="col-sm-6">
											<Pagination
												activePage={filterData.page}
												itemsCountPerPage={
													pagination.limit
												}
												totalItemsCount={
													pagination.total
												}
												pageRangeDisplayed={5}
												itemClass="page-item"
												linkClass="page-link"
												onChange={this.handlePageChange}
											/>
										</div>
									</div>
								) : (
									""
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation("webfrom")(processList);
