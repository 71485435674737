/* eslint-disable */
import React, { Component } from "react";
import Select from "react-select";
import { FordReportService } from "../../../../service/FordReportService";
import { LeadService } from "../../../../service/leadService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import PageLoader from "../../../elements/PageLoader";
import { Redirect } from "react-router-dom";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
class EditFord extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: this.props.match.params.id ? true : false,
			leadinfo: this.props.leadinfo || {},
			city_states: this.props.city_states || [],
			lead_id: this.props.match.params.id || 0,
			errors: {},
			redirectTolist: false,
			regexp: /^[0-9\b]+$/,
			regexpEmail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			ford_dealers: this.props.ford_dealers || [],
			ford_sm_list: this.props.ford_sm_list || [],
		};
	}

	componentDidMount = async () => {
		await this.getConfigData();
		if (this.state.lead_id) await this.getDetail();
	};

	getConfigData = async () => {
		const requestBody = {
			config_data: ["city_state_list_ph", "ford_dealers", "ford_sm_list"],
		};
		this.setState({ loading: true });
		LeadService.getConfig(requestBody)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					this.setState({
						city_states:
							response.data.data.city_state_list_ph || {},
						ford_dealers: response.data.data.ford_dealers || {},
						ford_sm_list: response.data.data.ford_sm_list || {},
						loading: false,
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	getDetail = async () => {
		let lead_id = this.state.lead_id;
		FordReportService.getDetail({ id: lead_id })
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let leadinfo = response.data.data ? response.data.data : {};
					if (
						leadinfo.customer_city_id &&
						leadinfo.customer_city_id instanceof Array
					) {
						leadinfo.customer_city_id = leadinfo.customer_city_id;
						leadinfo["customer_city"] = [
							{
								id: leadinfo.customer_city_id[0],
								name: leadinfo.customer_city,
							},
						];
					} else {
						leadinfo.customer_city_id = [leadinfo.customer_city_id];
						leadinfo["customer_city"] = [
							{
								id: leadinfo.customer_city_id[0],
								name: leadinfo.customer_city,
							},
						];
					}
					this.setState({ leadinfo: leadinfo || {} });
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	handleChange = (sname, ovalue) => {
		const { leadinfo } = this.state;
		if (sname instanceof Date) {
			leadinfo[ovalue] = new Date(sname);
		} else if (sname === "customer_city_id") {
			if (leadinfo.id) {
				leadinfo[sname] = [ovalue.id];
				leadinfo["customer_city"] = [
					{ id: ovalue.id, name: ovalue.name },
				];
			} else {
				leadinfo[sname] = ovalue.map((e) => e.id);
				leadinfo["customer_city"] = ovalue.map((e) => {
					return { id: e.id, name: e.name };
				});
			}
		} else if (sname === "dealer_id") {
			leadinfo[sname] = ovalue.dealer_id;
			leadinfo["sm_id"] = "";
		} else {
			leadinfo[sname] = ovalue.sm_id;
		}
		this.setState({
			leadinfo: leadinfo,
		});
	};

	validForm = () => {
		let { leadinfo, regexpEmail } = this.state;
		let formIsValid = true,
			errors = {};

		if (!leadinfo["customer_city_id"].length) {
			errors["customer_city_id"] = this.props.t(
				"FORM.VALIDATION_ERROR.REQUIRED"
			);
			formIsValid = false;
		}

		if (!leadinfo["dealer_id"]) {
			errors["dealer_id"] = this.props.t(
				"FORM.VALIDATION_ERROR.REQUIRED"
			);
			formIsValid = false;
		}

		// if (!leadinfo["sm_id"]) {
		// 	errors["sm_id"] = this.props.t("FORM.VALIDATION_ERROR.REQUIRED");
		// 	formIsValid = false;
		// }

		this.setState({ errors: errors });
		return formIsValid;
	};

	saveLead = (event) => {
		event.preventDefault();
		let { leadinfo, lead_id, city } = this.state;
		if (lead_id) leadinfo["id"] = lead_id;
		if (this.validForm()) {
			this.setState({ loading: true });
			leadinfo["status"] = lead_id
				? Number(leadinfo["status"]) === 1
					? "1"
					: "0"
				: "1";
			FordReportService.saveMapping(leadinfo)
				.then((response) => {
					if (
						response.status === 200 &&
						response.data.status === 200
					) {
						toast.success(response.data.message);
						this.setState({
							leadinfo: leadinfo,
							loading: false,
							redirectTolist: true,
						});
					} else {
						this.setState({ loading: false });
					}
				})
				.catch((error) => {
					toast.error(error);
				});
		}
	};
	handleRedirect = () => {
		this.setState({ redirectTolist: true });
	};

	render() {
		const {
			loading,
			leadinfo,
			lead_id,
			city_states,
			errors,
			redirectTolist,
			ford_dealers,
			ford_sm_list,
		} = this.state;
		const states =
			city_states && city_states.state ? city_states.state : [];
		const cities = city_states && city_states.city ? city_states.city : [];
		if (cities.length && states.length && !cities[0].city_state_name) {
			cities.map((v) => {
				if (v.state_id) {
					let state_name =
						states
							.filter((s) => +s.id === +v.state_id)
							.map((f) => f.name)[0] || "";
					let cityname = v.name;
					v.city_state_name = `${cityname}-${state_name}`;
					v.label = `${cityname}-${state_name}`;
				}
				return v;
			});
		}

		let filteredFord_sm_list =
			ford_sm_list && ford_sm_list.length && leadinfo.dealer_id
				? ford_sm_list.filter(
						(v) => v.dealer_id === +leadinfo.dealer_id
				  )
				: [];

		if (redirectTolist) return <Redirect to="/city_dealer_mapping" />;
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-6 col-sm-offset-3">
						<h1>
							{lead_id
								? this.props.t("FORM.UPDATE_LEAD") +
								  ` #${lead_id}`
								: this.props.t("FORM.ADD_LEAD")}
						</h1>
						<div className="card">
							{loading && (
								<PageLoader loaderClass="text-center" />
							)}

							<div className="card-body">
								<div className="row">
									<form onSubmit={this.saveLead}>
										<div className="col-sm-6 col-md-6 form-group">
											<label>
												{this.props.t(
													"FORM.DEALER_CODE"
												)}
											</label>

											<Select
												id="dealer_id"
												onChange={this.handleChange.bind(
													this,
													"dealer_id"
												)}
												options={ford_dealers}
												name="dealer_id"
												placeholder={this.props.t(
													"LIST.DEALER_CODE"
												)}
												value={ford_dealers.filter(
													({ dealer_id }) =>
														dealer_id ===
														leadinfo.dealer_id
												)}
												getOptionLabel={({ name }) =>
													name
												}
												getOptionValue={({
													dealer_id,
												}) => dealer_id}
											/>
											<span className="error show">
												{errors.dealer_id || ""}
											</span>
										</div>

										<div className="col-sm-6 col-md-6 form-group">
											<label>
												{this.props.t("FORM.CITY")}
											</label>
											{leadinfo.id ? (
												<Select
													id="customer_city_id"
													onChange={this.handleChange.bind(
														this,
														"customer_city_id"
													)}
													options={cities}
													name="customer_city_id"
													placeholder={this.props.t(
														"LIST.CITY"
													)}
													value={cities.filter(
														({ id }) =>
															id ===
															leadinfo
																.customer_city_id[0]
													)}
													getOptionLabel={({
														city_state_name,
													}) => city_state_name}
													getOptionValue={({ id }) =>
														id
													}
												/>
											) : (
												<ReactMultiSelectCheckboxes
													isMulti
													placeholderButtonLabel={this.props.t(
														"LIST.CITY"
													)}
													value={
														(cities instanceof
															Array &&
															leadinfo.customer_city_id &&
															cities.filter((e) =>
																[
																	...(leadinfo.customer_city_id instanceof
																	Array
																		? leadinfo.customer_city_id
																		: []),
																].includes(e.id)
															)) ||
														[]
													}
													onChange={this.handleChange.bind(
														this,
														"customer_city_id"
													)}
													options={cities}
													getOptionLabel={({
														city_state_name,
													}) => city_state_name}
													getOptionValue={({ id }) =>
														id
													}
												/>
											)}

											<span className="error show">
												{errors.customer_city_id || ""}
											</span>
										</div>

										{/* <div className="col-sm-6 col-md-6 form-group">
											<label>
												{this.props.t("FORM.SM_ID")}
											</label>

											<Select
												key={leadinfo.dealer_id}
												id="sm_id"
												onChange={this.handleChange.bind(
													this,
													"sm_id"
												)}
												options={filteredFord_sm_list}
												name="sm_id"
												placeholder={this.props.t(
													"LIST.SM_ID"
												)}
												value={filteredFord_sm_list.filter(
													({ sm_id }) =>
														sm_id === leadinfo.sm_id
												)}
												getOptionLabel={({ name }) =>
													name
												}
												getOptionValue={({ sm_id }) =>
													sm_id
												}
											/>
											<span className="error show">
												{errors.sm_id || ""}
											</span>
										</div> */}

										<div className="col-sm-12 form-group text-right">
											<button
												type="button"
												className="btn btn-default mrg-r15"
												onClick={this.handleRedirect}
											>
												{this.props.t("FORM.CANCEL")}
											</button>
											<button
												type="submit"
												className="btn btn-primary "
												disabled={loading}
											>
												{lead_id
													? this.props.t(
															"FORM.UPDATE"
													  )
													: this.props.t(
															"FORM.CREATE"
													  )}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation("ford")(EditFord);
