import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import SearchProcessCampaignList from "./SearchProcessCampaignList";
import { UserActions, ConfigAction } from "../../../../../store/action";
import Select from "react-select";
import Pagination from "react-js-pagination";
import SwitchBtn from "../../../../elements/SwitchBtn";
import ConfirmPopUp from "../../../../common/ConfirmPopUp";
import moment from "moment";

class ProcessCampaignList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasMoreRecord: true,
			processCampaignList: [],
			pagination: {},
			searchParams: {},
			reset: false,
			addListRequest: false,
			processList: [],
			searchItems: {},
			PopOver_id: null,
			loading: false,
			dateOf7DayAgo: moment().subtract(7, "d").format("YYYY-MM-DD"),
		};
	}
	componentDidMount = () => {
		this.getProcessCampaignList();
		this.getConfigData();
	};
	getProcessCampaignList = (search, toReset, page) => {
		let { searchParams, reset } = this.state;
		if (search) {
			this.setState({ searchParams: search });
			searchParams = search;
			searchParams["page"] = 1;
		} else {
			searchParams["page"] = page;
		}
		if (toReset && toReset !== reset) {
			searchParams["page"] = 1;
			this.setState({ reset: true });
		} else if (reset) {
			this.setState({ reset: false });
		}
		this.props.actions.getProcessCampaignList(searchParams);
	};
	static getDerivedStateFromProps = (nextProps, prevProps) => {
		const updateProps = [];
		if (
			nextProps.processCampaignList &&
			nextProps.processCampaignList !== prevProps.processCampaignList
		) {
			updateProps["processCampaignList"] = nextProps.processCampaignList;
		}
		if (
			nextProps.processList &&
			nextProps.processList !== prevProps.processList &&
			prevProps.processList &&
			prevProps.processList.length === 0
		) {
			updateProps["processList"] = nextProps.processList.map(function (
				e
			) {
				return {
					value: e.process_id,
					label: e.name,
				};
			});
		}
		if (nextProps.pagination !== prevProps.pagination) {
			updateProps["pagination"] = nextProps.pagination;
		}

		return updateProps;
	};
	getConfigData = () => {
		const requestBody = {
			config_data: ["process_list"],
		};
		this.props.actions.getConfigData(requestBody);
	};
	handleEditAction = (id, process_id) => {
		// window.open(
		// 	`/edit-process-campaign-list?id=${id}&process_id=${process_id}`
		// );
	};
	handleViewAction = (id) => {
		window.open(`/list-web-rule?id=${id}`);
	};
	handleAddList = (process_id) => {
		window.location.href = `/edit-process-campaign-list?process_id=${process_id}`;
	};
	handleAddListRequest = () => {
		const { addListRequest } = this.state;
		this.setState({ addListRequest: !addListRequest });
	};
	handleChange = (sname, ovalue) => {
		const { target } = ovalue;
		const { searchItems } = this.state;
		if (target) {
			const { value } = target;
			searchItems[sname] = value;
		} else {
			searchItems[sname] = ovalue.value;
		}
		if (!searchItems[sname]) delete searchItems[sname];
		this.setState({ searchItems: searchItems });
	};
	submitAddRequest = (cancel, e) => {
		e.preventDefault();
		const { searchItems } = this.state;
		if (cancel) {
			delete searchItems["process_id"];
			this.setState({ addListRequest: false });
		}
		if (searchItems["process_id"]) {
			this.handleAddList(searchItems["process_id"]);
		}
		// this.setState({searchItems:searchItems})
	};
	confirmNo = () => {
		this.setState({ PopOver_id: null });
	};
	confirmYes = (data) => {
		data.status = data.status === "1" ? "0" : "1";
		this.setState({ loading: true });
		this.updateStatus(data);
	};
	updateStatus = async (data) => {
		const { status } = await this.props.actions.saveProcessCampaignList(
			data
		);
		if (status) {
			this.displayPopUp(null);
			this.setState({ loading: false });
		}
	};
	handlePageChange = (page) => {
		this.getProcessCampaignList(null, false, page);
	};
	displayPopUp = (list_id) => {
		this.setState({ PopOver_id: list_id });
	};
	render() {
		const {
			processCampaignList,
			addListRequest,
			processList,
			searchItems,
			pagination,
			PopOver_id,
			dateOf7DayAgo,
		} = this.state;
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="edit-list-card">
						<SearchProcessCampaignList
							getProcessCampaignList={this.getProcessCampaignList}
						/>
						<div className="container-fluid">
							<div className="result-wrap">
								<div className="row mrg-b15">
									<div className="col-sm-6">
										<p className="countNo mrg-t5 mrg-b0">
											{pagination &&
												` Total Records- ${pagination.total}`}
										</p>
									</div>
									<div className="col-sm-6 ">
										{!addListRequest && (
											<div className="text-right">
												<input
													type="submit"
													value={this.props.t(
														"LIST_PROCESS_CAMPAIGN.ADD_LIST"
													)}
													onClick={
														this
															.handleAddListRequest
													}
													className="btn btn-success btn-sm"
												/>
											</div>
										)}

										{addListRequest && (
											<div className="row">
												<div className="col-sm-6">
													<Select
														id="process_id"
														onChange={this.handleChange.bind(
															this,
															"process_id"
														)}
														options={processList}
														name="process_id"
														placeholder={this.props.t(
															"LIST_PROCESS_CAMPAIGN.PROCESS_ID"
														)}
														value={processList.filter(
															({ value }) =>
																value ===
																searchItems.process_id
														)}
														getOptionLabel={({
															label,
														}) => label}
														getOptionValue={({
															value,
														}) => value}
													/>
												</div>
												<div className="col-sm-5">
													<button
														type="submit"
														value="Go"
														onClick={this.submitAddRequest.bind(
															this,
															false
														)}
														className="btn btn-primary mrg-r15"
													>
														Go
													</button>
													<button
														type="button"
														value="Cancel"
														onClick={this.submitAddRequest.bind(
															this,
															true
														)}
														className="btn btn-default"
													>
														Cancel
													</button>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="card">
								<div className="table-responsive">
									<div>
										<table className="table table-bordered-h table-hover processTable">
											<thead>
												<tr>
													<th>
														{this.props.t(
															"LIST_PROCESS_CAMPAIGN.TBL_HEADING.LIST_NAME"
														)}
													</th>
													<th>
														{this.props.t(
															"LIST_PROCESS_CAMPAIGN.TBL_HEADING.LIST_ID"
														)}
													</th>
													<th>
														{this.props.t(
															"LIST_PROCESS_CAMPAIGN.TBL_HEADING.PROCESS_ID"
														)}
													</th>
													<th>
														{this.props.t(
															"LIST_PROCESS_CAMPAIGN.TBL_HEADING.CAMPAIGN_NAME"
														)}
													</th>
													<th>
														{this.props.t(
															"LIST_PROCESS_CAMPAIGN.TBL_HEADING.ACTIVE_STATUS"
														)}
													</th>
													<th>
														{this.props.t(
															"LIST_PROCESS_CAMPAIGN.TBL_HEADING.CREATED_DATE"
														)}
													</th>
													<th>
														{this.props.t(
															"LIST_PROCESS_CAMPAIGN.TBL_HEADING.DESCRIPTION"
														)}
													</th>
													<th>
														{this.props.t(
															"LIST_PROCESS_CAMPAIGN.TBL_HEADING.ACTIONS"
														)}
													</th>
												</tr>
											</thead>
											<tbody>
												{processCampaignList instanceof
													Array &&
													processCampaignList.map(
														(e, i) => (
															<tr key={i}>
																<td>
																	{
																		e.list_name
																	}
																</td>
																<td>
																	{e.list_id}
																</td>
																<td>
																	{
																		e.process_name
																	}
																</td>
																<td>
																	{
																		e.campaign_name
																	}
																</td>
																<td>
																	{
																		e.status_type
																	}
																</td>
																<td>
																	{dateOf7DayAgo >=
																	moment(
																		e.created_date
																	).format(
																		"YYYY-MM-DD"
																	)
																		? "No"
																		: "Yes"}
																</td>
																<td>
																	{
																		e.description
																	}
																</td>
																<td>
																	<Link
																		to={`/edit-process-campaign-list?id=${e.id}&process_id=${e.process_id}`}
																		className="btn btn-link text-link mrg-r15"
																		title={this.props.t(
																			"PROCESS.EDIT"
																		)}
																	>
																		<i className="ic-create-24px"></i>
																	</Link>

																	<Link
																		to={`/list-web-rule?id=${e.id}`}
																		className="btn btn-link text-link mrg-r15"
																		title={this.props.t(
																			"PROCESS.VIEW"
																		)}
																	>
																		<i
																			className="ic-visibility-24px"
																			aria-hidden="true"
																		></i>
																	</Link>
																	<span>
																		<SwitchBtn
																			label=""
																			htmlFor={
																				"Status" +
																				i
																			}
																			id={
																				"Status" +
																				i
																			}
																			name={
																				"Status" +
																				i
																			}
																			checked={
																				e.status ===
																				"1"
																					? true
																					: false
																			}
																			onChange={this.displayPopUp.bind(
																				this,
																				e.id
																			)}
																		/>

																		{PopOver_id ===
																			e.id && (
																			<ConfirmPopUp
																				onConfirm={() =>
																					this.confirmYes(
																						e
																					)
																				}
																				onReject={() =>
																					this.confirmNo()
																				}
																				message={this.props.t(
																					"COMMON.ARE_YOU_SURE"
																				)}
																			/>
																		)}
																	</span>
																	<span>
																		{/* {loading && (
																			<PageLoader loaderClass="text-center" />
																		)} */}
																	</span>
																</td>
															</tr>
														)
													)}
											</tbody>
										</table>
										<div className="clearfix">
											{pagination &&
											pagination.pages >= 1 ? (
												<div className="main-div-pagination">
													<div className="col-sm-6 text-light">
														{this.props.t(
															"CAMPAIGN.SHOWING"
														)}{" "}
														{pagination.cur_page}{" "}
														{this.props.t(
															"CAMPAIGN.OF"
														)}{" "}
														{pagination.pages}{" "}
														{this.props.t(
															"CAMPAIGN.PAGES"
														)}
													</div>
													<div className="col-sm-6">
														<Pagination
															activePage={
																pagination.cur_page
															}
															itemsCountPerPage={
																pagination.limit
															}
															totalItemsCount={
																pagination.total
															}
															pageRangeDisplayed={
																5
															}
															itemClass="page-item"
															linkClass="page-link"
															onChange={
																this
																	.handlePageChange
															}
														/>
													</div>
												</div>
											) : (
												""
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ user, config }) => {
	return {
		processCampaignList: user.processCampaignList
			? user.processCampaignList.record
			: [],
		pagination: user.processCampaignList
			? user.processCampaignList.pagination
			: {},
		processList: config.process_list,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			saveProcessCampaignList: bindActionCreators(
				UserActions.saveProcessCampaignList,
				dispatch
			),
			getProcessCampaignList: bindActionCreators(
				UserActions.getProcessCampaignList,
				dispatch
			),
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
		},
	};
};
export default withTranslation("webfrom")(
	connect(mapStateToProps, mapDispatchToProps)(ProcessCampaignList)
);
