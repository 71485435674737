import MasterService from "./MasterService";

export const ReportService = {
	...MasterService,
	getDialerReport(postData) {
		return this.post("/report/dialer_report", postData);
	},
	// getDialerReportDetail(postData) {
	// 	postData.report_type=""
	// 	return this.post("/report/report_detail", postData);
	// },
	getOpsReport(postData) {
		return this.post("/report/ops_report", postData);
	},
	// getOpsReportDetail(postData) {
	// 	return this.post("/report/ops_report_detail", postData);
	// },
	getCrossSellLead(postData) {
		return this.post("/report/cross_sell_leads", postData);
	},
	getPerformanceReport(postData) {
		return this.get("/report/get_performance_report", postData);
	},
	updateOpsReport(postData) {
		return this.get("/report/sync_dialer_ops_report", postData);
	},
	getMyActiveLead(postData) {
		return this.post("/report/assigned_lead_summary", postData);
	},
	getAllLeads(postData) {
		return this.post("/report/lead_assign_data", postData);
	},
	getSalesReport(postData) {
		return this.get("/report/get_sales_report", postData);
	},
	getReportDetail(postData) {
		return this.post("/report/report_detail", postData);
	},
	getRetailReport(postData) {
		return this.post("/report/retail_oem_summary", postData);
	},
	getRetailReportSummary(postData) {
		return this.post("/report/retail_summary", postData);
	},
	getAgentPerformanceReport(postData) {
		return this.post("/report/get_agent_performance_report", postData);
	},
	getRetailReportProcessSummary(postData) {
		return this.post("/report/get_retail_process_summary", postData);
	},
};
