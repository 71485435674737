import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { UserService } from '../../../service/userService';
import PageLoader from '../../elements/PageLoader';
import SwitchBtn from '../../elements/SwitchBtn';
import secureStorage from '../../../config/encrypt';

const UserList = (props) => {
    const [ loading, setLoading ] = useState(false);
    const [ userList, setUsersList ] = useState([]);

    async function getUsersList() {
        setLoading(true);
        await UserService.getAllUsers().then((response) => {
            if (response && response.status === 200) {
                let logged_in_user_role = secureStorage.getItem("authUserInfo").authUser["role_info.name"];
                let userList = response.data.data;
                userList.forEach(user => {
                    user["isEditable"] = false;
                    if (logged_in_user_role === "Administrator") {
                        user["isEditable"] = true
                    } else if (logged_in_user_role === "Supervisor") {
                        if (user.role !== "Administrator") {
                            user["isEditable"] = true;
                        }
                    }
                })
                setUsersList(userList);
                setLoading(false)
            } else {
                setUsersList([]);
                setLoading(false);
            }
        })
        .catch((error) => {
            toast.error(error);
        })
    };

    useEffect(() => {
        getUsersList();
    }, []);

    const updateStatus = async (index) => {
        let updatedUserList = userList;
        let status = userList[index].status
        updatedUserList[index].status = status && Number(status) === 1 ? "0" : "1";
        let body = {
            "is_update": true,
            "id": updatedUserList[index].id,
            "status": updatedUserList[index].status,
        }
        if (window.confirm(props.t("USER.STATUS_CHANGE_WARNING"))) {
            toast.success(props.t("USER.STATUS_UPDATED"));
            await UserService.saveUser(body).then((response) => {
                if (response && response.status === 200) {
                    getUsersList();
                }
            })
            .catch((error) => {
                toast.error(error);
            })
        } else {
            return false;
        }
    }

    const showEditUser = (id) => {
        props.history.push(`/edit_user/${id}`);
    }

    const showAddUser = () => {
        props.history.push('/create_user');
    }

    return (
        <div className='container-fluid'>
            <div className="result-wrap">
                <div className="row mrg-b15 user-list-header">
                    <div className="col-sm-12">
                        <p className="countNo mrg-t5 mrg-b0">
                            {props.t("USER.USER_LIST")}
                        </p>
                    </div>
                    <button className='add-user-btn' onClick={() => showAddUser()}>{props.t("USER.ADD_USER")}</button>
                </div>
                <div className="card">
                    <div className="table-responsive">
                        {loading && (
                            <PageLoader loaderClass="text-center" />
                        )}
                        <table className="table table-bordered-h table-hover processTable">
                            <thead>
                                <tr>
                                    <th>{props.t("USER.NAME")}</th>
                                    <th>{props.t("USER.USERNAME")}</th>
                                    <th>{props.t("USER.ROLE")}</th>
                                    <th>{props.t("USER.EMAIL")}</th>
                                    <th>{props.t("USER.MOBILE")}</th>
                                    <th>{props.t("USER.ACTIVE_INACTIVE")}</th>
                                    <th>{props.t("USER.ACTION")}</th>
                                </tr>
                            </thead>

                            <tbody>
                                {userList.length > 0 ? (
                                    userList.map(
                                        (singleinfo, index) => (
                                            <React.Fragment
                                                key={singleinfo.id}
                                            >
                                                <tr>
                                                    <td>{singleinfo.name || ""}</td>
                                                    <td>{singleinfo.username || ""}</td>
                                                    <td>{singleinfo.role || ""}</td>
                                                    <td>{singleinfo.email || ""}</td>
                                                    <td>{singleinfo.mobile || ""}</td>
                                                    <td className='actionBtn'>
                                                    <SwitchBtn
                                                        label=""
                                                        htmlFor={"Status" + index}
                                                        id={"Status" + index}
                                                        name={"Status" + index}
                                                        checked={
                                                            singleinfo.status ==
                                                            '1'
                                                                ? true
                                                                : false
                                                        }
                                                        disabled={secureStorage.getItem("authUserInfo").authUser.user_id === singleinfo.id || !singleinfo.isEditable || false}
                                                        onChange={() => updateStatus(index)}
                                                    />
                                                    </td>
                                                   

                                                    <td className="actionBtn">
                                                    { singleinfo.isEditable ? (
                                                        <div>
                                                            <button
                                                                type="button"
                                                                onClick={() => showEditUser(singleinfo.id)}
                                                            >
                                                                <i className="ic-create-24px"></i>
                                                            </button>
                                                        </div>
                                                    ) : <span style={{ fontSize: "14px"}}> NA </span>}
                                                    </td> 
                                                </tr>
                                            </React.Fragment>
                                        )
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan="10">
                                            {!loading ? (
                                                <h6 className="text-danger">
                                                    {props.t(
                                                        "USER.NOT_FOUND"
                                                    )}
                                                </h6>
                                            ) : (
                                                ""
                                            )}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation("common")(withRouter(UserList));