import React, { Component } from "react";
import PreferenceForm from "./PreferenceForm";
import RecommendedForm from "./RecommendedForm";

class TabPrefreRecommend extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showTab: "1",
		};
	}
	handleChange = (sname, ovalue) => {
		this.setState({
			[sname]: ovalue.value,
		});
	};
	handleTabChange = (event) => {
		this.setState({ showTab: event.currentTarget.dataset.key });
		//console.log({ showTab: event.currentTarget.dataset.key });
	};
	render() {
		const { showTab } = this.state;
		const tabs = [
			{
				id: 1,
				title: "Preference Summary",
				count: 60,
			},
			{
				id: 2,
				title: "Recommend Used Cars",
				count: 3,
			},
		];
		return (
			<div className="pad-15 pad-t0">
				<div className="card-heading mrg-b30">
					<ul className="nav-tabs modal-tabs mrg-b15" role="tablist">
						{tabs.map((e, index) => (
							<li
								key={index}
								onClick={this.handleTabChange}
								className={
									"nav-item " +
									(+showTab === +e.id ? "active" : "")
								}
								data-key={e.id}
							>
								<a
									href="#firstsubmit"
									id="first-submit"
									role="tab"
								>
									{e.title}
								</a>
							</li>
						))}
					</ul>
				</div>
				<div>
					<div className="tab-content">
						<div
							className={
								"tab-pane " + (showTab === "1" ? "active" : "")
							}
						>
							<PreferenceForm
								modalClose={this.props.modalClose}
								leadId={this.props.leadId}
								preferenceList={this.props.preferenceList}
								getPreferenceList={this.props.getPreferenceList}
							/>
						</div>
						<div
							className={
								"tab-pane " + (showTab === "2" ? "active" : "")
							}
						>
							<RecommendedForm
								modalClose={this.props.modalClose}
								leadId={this.props.leadId}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default TabPrefreRecommend;
