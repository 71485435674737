import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import config from "../../../config/config";
import dateformat from "dateformat";
class TestDriveCarDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			subListDetails: {},
			mmv: {},
		};
	}

	static getDerivedStateFromProps = (nextProps, prevState) => {
		let updatedStateData = {};
		if (nextProps.subListDetails !== prevState.subListDetails) {
			updatedStateData["subListDetails"] = nextProps.subListDetails;
		}
		if (nextProps.mmv !== prevState.mmv) {
			updatedStateData["mmv"] = nextProps.mmv;
		}
		return updatedStateData;
	};

	render() {
		const { subListDetails, mmv } = this.state;
		return (
			<div className="col-sm-4">
				<div className="bg-gray">
					<div className="mrg-b15">
						<div>
							<strong>
								{this.props.t(
									"TEST_DRIVE.CAR_DETAILS.CAR_DETAILS"
								)}
							</strong>
						</div>
						<div>
							{`${
								mmv &&
								subListDetails &&
								subListDetails.make_id &&
								mmv.make &&
								mmv.make.length
									? mmv.make
											.filter(
												(v) =>
													Number(v.id) ===
													Number(
														subListDetails.make_id
													)
											)
											.map((v) => v.make)[0]
									: ""
							}
															${
																mmv &&
																subListDetails &&
																subListDetails.model_id &&
																mmv.model &&
																mmv.model.length
																	? mmv.model
																			.filter(
																				(
																					v
																				) =>
																					Number(
																						v.id
																					) ===
																					Number(
																						subListDetails.model_id
																					)
																			)
																			.map(
																				(
																					v
																				) =>
																					v.m
																			)[0] ||
																	  ""
																	: ""
															}`}
						</div>
						{subListDetails && subListDetails.price ? (
							<div>
								{this.props.t("TEST_DRIVE.CAR_DETAILS.RP")}.{" "}
								{subListDetails && subListDetails.price
									? subListDetails.price
									: ""}
							</div>
						) : (
							""
						)}
						<div className="date-text">
							{subListDetails && subListDetails.color
								? subListDetails.color + " ."
								: ""}
							{subListDetails && subListDetails.fuel
								? subListDetails.fuel + " ."
								: ""}
							{subListDetails && subListDetails.transmission
								? subListDetails.transmission
								: ""}
						</div>
					</div>
					<div className="mrg-b15">
						<div>
							<strong>
								{this.props.t(
									"TEST_DRIVE.CAR_DETAILS.DEALER_DETAILS"
								)}
							</strong>
						</div>
						<div>
							{this.props.t("TEST_DRIVE.CAR_DETAILS.DEALER")}:{" "}
							{subListDetails && subListDetails.dealer_name
								? subListDetails.dealer_name
								: ""}
						</div>
						<div className="date-text">
							{this.props.t("TEST_DRIVE.CAR_DETAILS.DSE")}:{" "}
							{subListDetails && subListDetails.dse_name
								? subListDetails.dse_name &&
								  subListDetails.dse_mobile
									? `${subListDetails.dse_name}(${subListDetails.dse_mobile})`
									: subListDetails.dse_name
								: ""}
						</div>
						<div className="text-orange f12">
							{this.props.t("TEST_DRIVE.CAR_DETAILS.HOME_TD")}{" "}
							{subListDetails &&
							subListDetails.home_td_available_status
								? subListDetails.home_td_available_status
								: ""}
						</div>
					</div>
					<div className="mrg-b15">
						<div>
							<strong>
								{this.props.t(
									"TEST_DRIVE.CAR_DETAILS.ASSIGNMENT_DETAILS"
								)}
							</strong>
						</div>

						<div>
							<div>
								{this.props.t(
									"TEST_DRIVE.CAR_DETAILS.LD_STATUS"
								)}
								:{" "}
								{subListDetails &&
								subListDetails &&
								Number(subListDetails.status) === 1
									? this.props.t(
											"TEST_DRIVE.CAR_DETAILS.APPROVED"
									  )
									: subListDetails &&
									  Number(subListDetails.status) === 2
									? this.props.t(
											"TEST_DRIVE.CAR_DETAILS.REJECTED"
									  )
									: this.props.t(
											"TEST_DRIVE.CAR_DETAILS.PENDING"
									  )}
							</div>
							{subListDetails && subListDetails.triggered_date ? (
								<div>
									{this.props.t("TEST_DRIVE.CAR_DETAILS.ON")}:{" "}
									{subListDetails &&
									subListDetails.triggered_date
										? dateformat(
												subListDetails.triggered_date,
												config.constants.DATE_FORMAT_DMY
										  )
										: ""}
								</div>
							) : (
								""
							)}
							{subListDetails &&
							subListDetails.updated_by_user &&
							subListDetails.source_id &&
							+subListDetails.source_id === 3 ? (
								<div>
									{this.props.t("TEST_DRIVE.CAR_DETAILS.BY")}:{" "}
									{subListDetails &&
									subListDetails.updated_by_user
										? subListDetails.updated_by_user
										: ""}
								</div>
							) : (
								""
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ lead, inventory }) => {
	return {
		//subListDetails: lead.subListDetails,
		mmv: inventory.mmv,
	};
};
export default withTranslation("lead_detail")(
	connect(mapStateToProps, null)(TestDriveCarDetails)
);
