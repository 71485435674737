/* eslint-disable */
import React, { Component } from "react";
import SearchAudit from "./SearchAudit";
import AuditDetails from "./AuditDetails";
import AuditActivity from "./AuditActivity";
import ActionPopup from "./ActionPopup";
import ChangePriority from "./ChangePriority";
import { AuditService } from "../../../service/auditService";
import { LeadService } from "../../../service/leadService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { InventoryAction, LeadActions } from "./../../../store/action";
import { bindActionCreators } from "redux";
import config from "../../../config/config";
import PageLoader from "../../elements/PageLoader";
import InfiniteScroll from "react-infinite-scroller";
import * as constant from "../../../config/constant";
import dateformat from "dateformat";
import CheckBox from "../../elements/CheckBox";
import ModalPopup from "./../../elements/Popup";
import { Redirect, Link } from "react-router-dom";
import { CSVLink } from "react-csv";

class AuditList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			leadList: this.props.leadList || [],
			pagination: this.props.pagination || {},
			filterData: {},
			filterDataText: {},
			mmv: [],
			make_model: {},
			hasMoreItems: false,
			tempFilterData: {},
			tempTextData: {},
			toploading: false,
			is_pushed_to_dailer: false,
			currentInstance: this,
			dialerPopOver: false,
			displayCheckBox: false,
			myActivePopOver: false,
			myactiveModal: false,
			priorityPushPopOver: false,
			priorityCallingModal: false,
			isPriorityPushButtonEnable: false,
			tabs: [
				{
					id: 1,
					title: this.props.t("SEARCH_AUDIT.TAB_HEADING.NEW"),
				},
				{
					id: 2,
					title: this.props.t(
						"SEARCH_AUDIT.TAB_HEADING.PENDING_ON_AGENT"
					),
				},
				{
					id: 3,
					title: this.props.t(
						"SEARCH_AUDIT.TAB_HEADING.PENDING_ON_AUDITOR"
					),
				},
				{
					id: 4,
					title: this.props.t("SEARCH_AUDIT.TAB_HEADING.RE_AUDIT"),
				},
				{
					id: 5,
					title: this.props.t("SEARCH_AUDIT.TAB_HEADING.HISTORY"),
				},
			],
			showTab: 1,
			showAuditDetails: false,
			showAuditActivity: false,
			loggedInUserRoleId: this.props.authUser.role_id,
			agent_role_id: config.constants.AGENT_ROLE_ID,
			showActionPopup: false,
			auditlead_id: 0,
			actionType: "",
			showChangePriority: false,
			selectedIds: [],
			isUserTypeAgent: false,
			selectedLeadId: 0,
			csvData: [],
		};
		this.ChangeCheckbox = this.ChangeCheckbox.bind(this);
	}

	static getDerivedStateFromProps = (nextProps, prevState) => {
		let updatedStateData = {};
		return updatedStateData;
	};

	componentDidMount = async () => {
		let {
			filterData,
			filterDataText,
			isUserTypeAgent,
			loggedInUserRoleId,
			agent_role_id,
			showTab,
		} = this.state;
		isUserTypeAgent = loggedInUserRoleId === agent_role_id ? true : false;
		if (Number(showTab) === 1) {
			// DEFAULT FILTER
			filterData.type = isUserTypeAgent ? ["PENDING_ON_AGENT"] : ["NEW"];
		}
		await this.setState({
			filterData,
			filterDataText,
			isUserTypeAgent: isUserTypeAgent,
		});
		await this.getAuditList();

		if (
			!(
				this.props.city &&
				this.props.city instanceof Array &&
				this.props.city.length
			)
		) {
			await this.props.actions.getCityState();
		}
	};
	validator = (filterData) => {
		let error = "";
		if (filterData.date_type) {
			if (!filterData.start_date) {
				error = this.props.t(
					"SEARCH_AUDIT.ERROR.PLEASE_ENTER_VALID_START_DATE"
				);
			} else if (!filterData.end_date) {
				error = this.props.t(
					"SEARCH_AUDIT.ERROR.PLEASE_ENTER_VALID_END_DATE"
				);
			}
		}
		if (error) {
			toast.error(error);
			return false;
		}
		return true;
	};
	submitFilterForm = async (
		filterData,
		filterDataText,
		toUpdate = false,
		reset = false
	) => {
		await this.setActiveTabInFilter(filterData);
		if (this.validator(filterData)) {
			if (Object.keys(filterData).length === 0) {
				toUpdate = true;
			}
			if (filterData.call_status)
				filterData.call_status = String(filterData.call_status);
			this.setState(
				{
					loading: false,
					toploading: reset ? false : true,
					filterData: { ...filterData, toUpdate: toUpdate },
					filterDataText: { ...filterDataText, toUpdate: toUpdate },
					displayCheckBox:
						(this.props.authUser.role_id == 1 ||
							this.props.authUser.role_id == 5) &&
						+filterData.my_active > 0
							? true
							: false,
				},
				async () => {
					if (!reset) await this.getAuditList();
				}
			);
		}
	};
	ClearAllHandle = async (action) => {
		let { filterData, filterDataText } = this.state;
		if (action === "all") {
			filterData = {};
			filterDataText = {};
		} else {
			delete filterData[action];
			delete filterDataText[action];
			if (action === "date_type") {
				delete filterData["start_date"];
				delete filterData["end_date"];
			}
		}
		this.submitFilterForm(filterData, filterDataText, true, true);
	};

	getAuditList = async (loadmoreStatus = 0) => {
		let {
			filterData,
			pagination,
			isUserTypeAgent,
			loggedInUserRoleId,
		} = this.state;
		let postData = { ...filterData };
		this.setState({
			loading: loadmoreStatus ? true : false,
			hasMoreItems: false,
			toploading: loadmoreStatus ? false : true,
		});
		postData["page"] = loadmoreStatus
			? (pagination.page ? pagination.page : 0) + 1
			: 1;
		if (isUserTypeAgent) postData["agent_id"] = this.props.authUser.user_id;
		if(postData && !postData.tab_name) postData.tab_name='new';
		AuditService.getAuditList(postData)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let responseData = response.data.data || [];
					let currentList = this.state.leadList || [];
					let updatedLeadlist =
						loadmoreStatus === 1
							? currentList.concat(responseData)
							: responseData;
					this.setState(
						{
							leadList: updatedLeadlist,
							pagination: response.data.pagination || {},
							loading: false,
							toploading: false,
							hasMoreItems:
								responseData.length &&
								response.data.pagination &&
								response.data.pagination.next_page
									? true
									: false,
							isPriorityPushButtonEnable: true,
							csvData: updatedLeadlist,
						},
						() => {
							//console.log("state set successfully");
						}
					);
					//this.makeCSVData();
				}
			})
			.catch((error) => {
				// toast.error(error);
			});
	};

	makeCSVData = () => {
		let { leadList } = this.state;
		const cityList = this.props.city || [];
		let jsonData = [];
		console.log("selectedIds", selectedIds);
		leadList.map((obj) => {
			jsonData.push({
				"CRM Id": obj.lead_id,
				Name:
					obj.customer_details && obj.customer_details.customer_name
						? obj.customer_details.customer_name
						: "",
				Mobile:
					obj.customer_details && obj.customer_details.customer_mobile
						? obj.customer_details.customer_mobile
						: "",
				city:
					obj.customer_details &&
					obj.customer_details.customer_city_id &&
					cityList.length
						? cityList
								.filter(
									(v) =>
										v.id ==
										obj.customer_details.customer_city_id
								)
								.map((v) => v.name)[0] || ""
						: "",
				Status: obj.status,
				"Sub Status": obj.sub_status,
				"Attribution Status": obj.attribution_status,
				"Audit Created Date Time": obj.audit_date,
				"Audit Priority": obj.audit_priority,
				"Last Auditor Name": obj.last_audited_by,
				"Calling Agent Name": obj.calling_agent_name,
				"Calling Date Time": obj.calling_date,
				"Audit Status": obj.audit_status,
				"Last Action By": obj.last_action_by,
				"Last Action Date Time": obj.last_action_date,
				"Last Action Comments": obj.last_action_comment,
				"New/Used": obj.new_used,
				"Purchase CPS Brand Car": obj.purchase_cps_brand_car,
				"Vehicle Brand/Model": obj.vehicle_brand,
				"Dealership Name": obj.dealership_name,
				"Dealership City": obj.dealership_city,
				"DSE Name": obj.dse_name,
				"Booking Name": obj.booking_name,
				"Booking Mobile": obj.booking_mobile,
				"Purchase Date": obj.purchase_date,
				"Lost Reason": obj.lost_reason,
				"CRM Update": obj.crm_update,
				"Cash/Finance": obj.cash_or_finance,
				"Compliance Score": obj.compliance,
				"Action Needed": obj.action_needed,
				Comments: obj.comments,
			});
		});
		this.setState({ csvData: jsonData });
	};

	loadMoreitems = async () => {
		this.getAuditList(1);
	};
	handleTempUpdate = (tempData) => {
		this.setState({ tempFilterData: tempData });
	};

	handleDisplayPopOver = async (type) => {
		if (type === "ADD_TO_DIALER") this.setState({ dialerPopOver: true });
		else if (type === "PRIORITY_PUSH")
			this.setState({ priorityPushPopOver: true });
	};

	handleButtonClick = async (type) => {
		let { selectedIds } = this.state;
		let stateData = {
			selectedIds: selectedIds,
			actionType: type,
		};
		if (type === "CLOSED") stateData["showActionPopup"] = true;
		if (type === "CHANGE_PRIORITY") stateData["showChangePriority"] = true;
		this.setState(stateData);
	};

	showModal = () => {
		this.setState({ myactiveModal: true });
	};
	closeModal = () => {
		this.setState({
			showAuditDetails: false,
			showActionPopup: false,
			showChangePriority: false,
		});
	};

	closeActivityModal = () => {
		this.setState({ showAuditActivity: false });
	};

	nameMapping = (name) => {
		switch (name) {
			case "cluster_list":
				name = config.constants.cluster_list;
				break;
		}
		return name;
	};

	handleTabChange = async (event) => {
		let activeTab = event.currentTarget.dataset.key;
		let { filterData } = this.state;
		await this.setState({ showTab: activeTab });
		await this.setActiveTabInFilter(filterData);
		this.getAuditList();
	};

	setActiveTabInFilter = async (filterData) => {
		let { showTab, isUserTypeAgent } = this.state;
		let activeTab = showTab;
		filterData["is_agent_reaudit"] = false;
		if (Number(activeTab) === 1) {
			// NEW TAB FILTER
			filterData.type = isUserTypeAgent ? ["PENDING_ON_AGENT"] : ["NEW"];
			filterData.tab_name = 'new';
		} else if (Number(activeTab) === 2) {
			// PENDING ON AGENT TAB FILTER
			filterData.type = ["PENDING_ON_AGENT"];
			filterData.tab_name = 'pending_on_agent';
		} else if (Number(activeTab) === 3) {
			// PENDING ON AUDITOR TAB FILTER
			filterData.type = isUserTypeAgent
				? ["REJECTED"]
				: ["PENDING_ON_AUDITOR"];
				filterData.tab_name = 'pending_on_auditor';
		} else if (Number(activeTab) === 4) {
			// RE-AUDIT TAB FILTER
			filterData.type = isUserTypeAgent
				? ["PENDING_ON_AGENT"]
				: ["REJECTED"];
			filterData["is_agent_reaudit"] = isUserTypeAgent ? true : false;
			filterData.tab_name = 're_audit';
		} else if (Number(activeTab) === 5) {
			// HISTORY ON AGENT TAB FILTER
			filterData.type = ["ACCEPTED", "CLOSED", "REFUSED"];
			filterData.tab_name = 'history';
		}
		await this.setState({
			filterData: filterData,
		});
	};

	ChangeCheckbox = (event) => {
		const target = event.target;
		const lead_id = target.value;
		let { leadList, selectedIds } = this.state;
		leadList
			.filter((v) => +v.lead_id === +lead_id)
			.map((v) => {
				v.checked = target.checked ? 1 : 0;
				return v;
			});
		selectedIds = leadList
			.filter((e) => e.checked)
			.map((m) => m.auditlead_id);

		this.setState({ leadList: leadList, selectedIds: selectedIds });
	};

	updateList = () => {
		this.setState({ selectedIds: [] });
		this.closeModal();
		this.getAuditList();
	};

	getTabCountInfo = (tabId) => {
		let { pagination, isUserTypeAgent } = this.state;
		let tabCount = 0;
		if (pagination && pagination.tabcountInfo) {
			let countInfo = pagination.tabcountInfo;
			switch (Number(tabId)) {
				case 1: // NEW TAB
					tabCount = isUserTypeAgent
						? countInfo.count_new_agent
						: countInfo.count_new_auditor;
					break;
				case 2: // PENDING ON AGENT TAB
					tabCount = countInfo.count_pending_on_agent;
					break;
				case 3: // PENDING ON AUDITOR TAB
					tabCount = countInfo.count_pending_on_auditor;
					break;
				case 4: // RE-AUDIT TAB
					tabCount = isUserTypeAgent
						? countInfo.count_re_audit_agent
						: countInfo.count_re_audit_auditor;
					break;
				default:
					// HISTORY TAB
					tabCount = countInfo.count_history;
					break;
			}
		}
		return tabCount;
	};

	// fun for download csv

	handleDownloadCsv = async () => {
		let { filterData, pagination } = this.state;
		let postData = { ...filterData };
		this.setState({ toploading: true }, async () => {
			await this.props.actions.SaveDownloadLog({
				module: "ExportAuditCsv",
				action: "1",
			});
		});
		postData.total_record = (pagination && pagination.total) || 0;
		postData.csvtype = "audit";
		LeadService.saveRequestDownloadCsv(postData)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let message2display = this.props.t(
						"SEARCH_AUDIT.CSV_DATA_WILL_SEND_OVER_EMAIL"
					);
					toast.success(message2display);
				} else {
					toast.error(response.data.message);
				}
				this.setState({ toploading: false });
			})
			.catch((error) => {
				toast.error(error);
				this.setState({ toploading: false });
			});
	};

	render() {
		if (!constant.isUserHasRightsToAccessPage("lead", "lead", "/lead-list"))
			return <Redirect to="/not-found" />;
		const {
			loading,
			leadList,
			pagination,
			filterData,
			filterDataText,
			toploading,
			tabs,
			showTab,
			auditlead_id,
			actionType,
			selectedIds,
			isUserTypeAgent,
			selectedLeadId,
			csvData,
		} = this.state;
		const { AUDIT_PRIORITY, DISPLAY_AUDIT_STATUS } = config.constants;
		const cityList = this.props.city || [];
		const { page, toUpdate, ...filters } = filterDataText
			? filterDataText
			: {};
		let tabList = tabs.filter((e) => {
			return isUserTypeAgent ? e.id !== 2 : e.id !== 3;
		});
		return (
			<div>
				<SearchAudit
					onSubmitFilter={this.submitFilterForm}
					filterData={filterData}
					filterDataText={filterDataText}
					onUpdateFilter={this.handleTempUpdate}
					authUser={this.props.authUser}
					showHidePriorityPushButton={this.showHidePriorityPushButton}
				/>

				<div className="container-fluid">
					<div className="result-wrap">
						<div className="row">
							<div className="col-sm-6">
								<p className="countNo">
									{pagination && pagination.total
										? pagination.total
										: 0}{" "}
									- {this.props.t("SEARCH_AUDIT.TOTAL_CASES")}
								</p>
							</div>
						</div>

						{filters && Object.keys(filters).length ? (
							<div className="selectFilters">
								{Object.keys(filters).map(
									(current_filter, filerIndex) => (
										<span
											className="badge badge-secondery mrg-r10"
											key={filerIndex}
										>
											{`${this.props.t(
												`SEARCH_AUDIT.${this.nameMapping(
													current_filter
												)}`
											)} - ${
												filterDataText[current_filter]
											}`}
											<span
												className="close"
												onClick={this.ClearAllHandle.bind(
													this,
													current_filter
												)}
											>
												×
											</span>
										</span>
									)
								)}

								<span
									className="link-text"
									onClick={this.ClearAllHandle.bind(
										this,
										"all"
									)}
								>
									{this.props.t("SEARCH_AUDIT.CLEAR_ALL")}
								</span>
							</div>
						) : (
							""
						)}
						<div className="flex-item">
							<div className="row">
								<div className="col-sm-6">
									<ul
										className="tagacdtionbtn nav-tabs mrg-b0"
										role="tablist"
									>
										{tabList.map((e, index) => (
											<li
												key={index}
												onClick={this.handleTabChange}
												className={
													"nav-item " +
													(Number(showTab) ===
													Number(e.id)
														? "active"
														: "")
												}
												data-key={e.id}
											>
												<a
													href="#firstsubmit"
													id="first-submit"
													role="tab"
													className="text-transform"
												>
													{e.title}

													<span>
														(
														{this.getTabCountInfo(
															e.id
														)}
														)
													</span>
												</a>
											</li>
										))}
									</ul>
								</div>
								{leadList && leadList.length ? (
									<div className="col-sm-6 text-right">
										{!isUserTypeAgent &&
										Number(showTab) !== 5 ? (
											<span>
												<button
													onClick={() =>
														this.handleButtonClick(
															"CHANGE_PRIORITY"
														)
													}
													className="btn btn-success btn-sm mrg-b5 mrg-l15"
													disabled={
														selectedIds &&
														selectedIds.length === 0
													}
												>
													{this.props.t(
														"SEARCH_AUDIT.CHANGE_PRIORITY"
													)}
												</button>
												<button
													onClick={() =>
														this.handleButtonClick(
															"CLOSED"
														)
													}
													className="btn btn-success btn-sm mrg-b5 mrg-l15"
													disabled={
														selectedIds &&
														selectedIds.length === 0
													}
												>
													{this.props.t(
														"SEARCH_AUDIT.CLOSE"
													)}
												</button>
											</span>
										) : null}

										{/* <CSVLink
											filename="audit_list.csv"
											data={csvData}
										>
											<button className="btn btn-success btn-sm mrg-b5 mrg-l15">
												{this.props.t(
													"SEARCH_AUDIT.EXPORT"
												)}
											</button>
										</CSVLink> */}
										{filterData &&
										leadList &&
										leadList.length &&
										constant.isUserHasRightsToAccessPage(
											"lead",
											"lead",
											"/download-csv"
										) ? (
											<button
												onClick={() =>
													this.handleDownloadCsv()
												}
												className="btn btn-success btn-sm mrg-b5 mrg-l15"
											>
												{this.props.t(
													"SEARCH_AUDIT.EXPORT"
												)}
											</button>
										) : null}
									</div>
								) : null}
							</div>
						</div>
						<div className="card">
							{toploading && (
								<PageLoader
									loaderClass=" text-center loader content-loader"
									loadertext="true"
								/>
							)}
							<div ref={(ref) => (this.scrollParentRef = ref)}>
								<div className="table-responsive">
									<InfiniteScroll
										pageStart={0}
										loadMore={this.loadMoreitems}
										hasMore={
											this.state.hasMoreItems || false
										}
										loader={
											<PageLoader
												loaderClass="text-cente"
												loadertext="false"
												key={0}
											/>
										}
										getScrollParent={() =>
											this.scrollParentRef
										}
										useWindow={true}
									>
										<table className="table table-bordered-h table-hover">
											<thead>
												<tr>
													<th>&nbsp;</th>

													<th>
														{this.props.t(
															"SEARCH_AUDIT.Lead_ID"
														)}
													</th>
													<th>
														{this.props.t(
															"SEARCH_AUDIT.LEAD_DETAILS"
														)}
													</th>

													<th>
														{this.props.t(
															"SEARCH_AUDIT.LEAD_STATUS"
														)}
													</th>
													<th>
														{this.props.t(
															"SEARCH_AUDIT.AUDIT_DETAILS"
														)}
													</th>
													<th>
														{this.props.t(
															"SEARCH_AUDIT.CALLING_DETAILS"
														)}
													</th>
													<th>
														{this.props.t(
															"SEARCH_AUDIT.AUDIT_STATUS"
														)}
													</th>
													<th>
														{this.props.t(
															"SEARCH_AUDIT.ACTION"
														)}
													</th>
												</tr>
											</thead>

											<tbody>
												{leadList && leadList.length ? (
													leadList.map(
														(
															current_lead,
															index
														) => (
															<React.Fragment
																key={
																	current_lead.lead_id
																}
															>
																<tr>
																	<td>
																		{!isUserTypeAgent ? (
																			<CheckBox
																				label={
																					false
																				}
																				name="checked_id"
																				type="checkbox"
																				id="checked_id"
																				checked={
																					current_lead.checked
																						? true
																						: false
																				}
																				value={
																					current_lead.lead_id
																				}
																				onChange={
																					this
																						.ChangeCheckbox
																				}
																			/>
																		) : null}
																	</td>
																	<td>
																		{
																			current_lead.lead_id
																		}
																	</td>
																	<td>
																		<div id="customer_name">
																			<strong className="mrg-r5">
																				{current_lead
																					.customer_details
																					.customer_name ||
																					""}
																			</strong>
																		</div>
																		<div>
																			{current_lead
																				.customer_details
																				.customer_mobile ||
																				""}
																		</div>
																		<div>
																			{current_lead
																				.customer_details
																				.customer_city_id &&
																			cityList.length
																				? cityList
																						.filter(
																							(
																								v
																							) =>
																								v.id ==
																								current_lead
																									.customer_details
																									.customer_city_id
																						)
																						.map(
																							(
																								v
																							) =>
																								v.name
																						)[0] ||
																				  ""
																				: ""}
																		</div>
																	</td>

																	<td>
																		<div>
																			{current_lead.status ||
																				""}
																		</div>
																		<div>
																			<span
																				className={
																					Number(
																						current_lead.sub_status_id
																					) ===
																					2
																						? "badge badge-error"
																						: Number(
																								current_lead.sub_status_id
																						  ) ===
																						  4
																						? "badge badge-primary"
																						: "badge badge-warm"
																				}
																			>
																				{current_lead.sub_status ||
																					""}
																			</span>
																		</div>
																		<div className="badge badge-warm">
																			{current_lead.attribution_status ||
																				""}
																		</div>
																	</td>
																	<td>
																		<div>
																			{current_lead.audit_date ? (
																				<React.Fragment>
																					{dateformat(
																						current_lead.audit_date,
																						config
																							.constants
																							.DATE_FORMAT
																					)}
																				</React.Fragment>
																			) : (
																				""
																			)}
																		</div>
																		<div>
																			{current_lead.audit_priority ? (
																				<React.Fragment>
																					{(AUDIT_PRIORITY &&
																						AUDIT_PRIORITY.filter(
																							(
																								e
																							) =>
																								e.value ===
																								current_lead.audit_priority
																						).map(
																							(
																								m
																							) =>
																								m.label
																						)[0]) ||
																						""}
																				</React.Fragment>
																			) : (
																				""
																			)}
																		</div>
																	</td>
																	<td>
																		<div>
																			{current_lead.calling_date ? (
																				<React.Fragment>
																					{dateformat(
																						current_lead.calling_date,
																						config
																							.constants
																							.DATE_FORMAT
																					)}
																				</React.Fragment>
																			) : (
																				""
																			)}
																		</div>
																		<div>
																			{current_lead.calling_agent_name ? (
																				<React.Fragment>
																					{
																						current_lead.calling_agent_name
																					}
																				</React.Fragment>
																			) : (
																				""
																			)}
																		</div>
																	</td>
																	<td>
																		<div>
																			{current_lead.audit_state ? (
																				<React.Fragment>
																					{(DISPLAY_AUDIT_STATUS &&
																						DISPLAY_AUDIT_STATUS.filter(
																							(
																								e
																							) =>
																								e.value ===
																								current_lead.audit_state
																						).map(
																							(
																								m
																							) =>
																								m.label
																						)[0]) ||
																						""}
																				</React.Fragment>
																			) : (
																				"New"
																			)}
																		</div>
																		{isUserTypeAgent ||
																		Number(
																			showTab
																		) !==
																			1 ? (
																			<>
																				{" "}
																				{
																					""
																				}
																				<div>
																					{current_lead.last_action_by ? (
																						<React.Fragment>
																							{
																								current_lead.last_action_by
																							}
																						</React.Fragment>
																					) : (
																						""
																					)}
																				</div>
																				<div>
																					{current_lead.last_action_date ? (
																						<React.Fragment>
																							{dateformat(
																								current_lead.last_action_date,
																								config
																									.constants
																									.DATE_FORMAT
																							)}
																						</React.Fragment>
																					) : (
																						""
																					)}
																				</div>
																				{
																					""
																				}
																			</>
																		) : null}
																	</td>
																	<td>
																		<a
																			href={`#`}
																			className="btn btn-link text-link mrg-r15"
																			title={this.props.t(
																				"SEARCH_AUDIT.ACTION_LIST.VIEW"
																			)}
																			onClick={() =>
																				this.setState(
																					{
																						showAuditDetails: true,
																						selectedLeadId:
																							current_lead.lead_id,
																					}
																				)
																			}
																		>
																			<i
																				className="fa fa-eye"
																				aria-hidden="true"
																			></i>
																		</a>
																		{constant.isUserHasRightsToAccessPage(
																			"lead",
																			"lead",
																			"/Leads-Details"
																		) &&
																		!isUserTypeAgent &&
																		current_lead.audit_status ===
																			"NEW" ? (
																			<a
																				className="btn btn-link text-link mrg-r15"
																				href={`/Leads-Details?id=
																					${current_lead.lead_id}
																					&auditlead_id=${current_lead.auditlead_id}
																					&is_audit=true`}
																				target="_blank"
																				title={this.props.t(
																					"SEARCH_AUDIT.ACTION_LIST.AUDIT"
																				)}
																			>
																				<i
																					className="fa fa-pencil-square-o"
																					aria-hidden="true"
																				></i>
																			</a>
																		) : null}

																		{!isUserTypeAgent &&
																		current_lead.audit_status ===
																			"REJECTED" ? (
																			<>
																				{" "}
																				{
																					""
																				}
																				<a
																					className="btn btn-link text-link mrg-r15"
																					href={`/Leads-Details?id=
																					${current_lead.lead_id}
																					&auditlead_id=${current_lead.auditlead_id}
																					&is_reaudit=true`}
																					target="_blank"
																					title={this.props.t(
																						"SEARCH_AUDIT.ACTION_LIST.REAUDIT"
																					)}
																				>
																					<i
																						className="fa fa-pencil-square-o"
																						aria-hidden="true"
																					></i>
																				</a>
																				<a
																					className="btn btn-link text-link mrg-r15"
																					title={this.props.t(
																						"SEARCH_AUDIT.ACTION_LIST.REFUSE"
																					)}
																					onClick={() =>
																						this.setState(
																							{
																								showActionPopup: true,
																								auditlead_id:
																									current_lead.auditlead_id,
																								actionType:
																									"REFUSED",
																							}
																						)
																					}
																				>
																					<i
																						className="fa fa-close"
																						aria-hidden="true"
																					></i>
																				</a>
																				{
																					""
																				}
																			</>
																		) : null}

																		{isUserTypeAgent &&
																		current_lead.audit_status ===
																			"PENDING_ON_AGENT" ? (
																			<a
																				className="btn btn-link text-link mrg-r15"
																				href={`#`}
																				title={this.props.t(
																					"SEARCH_AUDIT.ACTION_LIST.ACCEPT"
																				)}
																				onClick={() =>
																					this.setState(
																						{
																							showActionPopup: true,
																							auditlead_id:
																								current_lead.auditlead_id,
																							actionType:
																								"ACCEPTED",
																						}
																					)
																				}
																			>
																				<i
																					className="ic-check_circle-24px"
																					aria-hidden="true"
																				></i>
																			</a>
																		) : null}

																		{isUserTypeAgent &&
																		current_lead.audit_status ===
																			"PENDING_ON_AGENT" ? (
																			<a
																				className="btn btn-link text-link mrg-r15"
																				href={`#`}
																				title={this.props.t(
																					"SEARCH_AUDIT.ACTION_LIST.REJECT"
																				)}
																				onClick={() =>
																					this.setState(
																						{
																							showActionPopup: true,
																							auditlead_id:
																								current_lead.auditlead_id,
																							actionType:
																								"REJECTED",
																						}
																					)
																				}
																			>
																				<i
																					className="ic-cancel-24px"
																					aria-hidden="true"
																				></i>
																			</a>
																		) : null}

																		<Link
																			to={`#`}
																			className="btn btn-link text-link mrg-r15"
																			title={this.props.t(
																				"SEARCH_AUDIT.ACTION_LIST.ACTIVITY_HISTORY"
																			)}
																			onClick={() =>
																				this.setState(
																					{
																						showAuditActivity: true,
																						auditlead_id:
																							current_lead.auditlead_id,
																					}
																				)
																			}
																		>
																			<i
																				className="fa fa-history"
																				aria-hidden="true"
																			></i>
																		</Link>
																	</td>
																</tr>
															</React.Fragment>
														)
													)
												) : (
													<tr>
														<td colSpan="10">
															{!loading ? (
																<h6 className="text-danger">
																	{this.props.t(
																		"SEARCH_AUDIT.NOT_FOUND"
																	)}
																</h6>
															) : (
																""
															)}
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</InfiniteScroll>
									{loading && (
										<PageLoader
											loaderClass="text-center"
											loadertext="false"
										/>
									)}
								</div>
							</div>

							{this.state.showAuditDetails === false ? (
								""
							) : (
								<ModalPopup
									id="asd"
									className="modal modal-lg"
									title=""
									modalClose={this.closeModal}
								>
									<AuditDetails
										modalClose={this.closeModal}
										selectedLeadId={selectedLeadId}
										isUserTypeAgent={isUserTypeAgent}
									/>
								</ModalPopup>
							)}
							{this.state.showAuditActivity === false ? (
								""
							) : (
								<ModalPopup
									id="asd"
									className="modal modal-lg"
									title="Activity"
									modalClose={this.closeActivityModal}
								>
									<AuditActivity
										auditId={auditlead_id}
										modalClose={this.closeActivityModal}
									/>
								</ModalPopup>
							)}
							{this.state.showActionPopup === false ? (
								""
							) : (
								<ModalPopup
									id="asd"
									className="modal modal"
									title="Activity"
									modalClose={this.closeModal}
								>
									<ActionPopup
										selectedIds={selectedIds}
										auditlead_id={auditlead_id}
										actionType={actionType}
										updateList={this.updateList}
									/>
								</ModalPopup>
							)}
							{this.state.showChangePriority === false ? (
								""
							) : (
								<ModalPopup
									id="asd"
									className="modal modal"
									title="Activity"
									modalClose={this.closeModal}
								>
									<ChangePriority
										selectedIds={selectedIds}
										actionType={actionType}
										updateList={this.updateList}
									/>
								</ModalPopup>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ config, inventory, lead, auth }) => {
	return {
		mmv: inventory.mmv,
		city: inventory.city,
		is_pushed_to_dailer: lead.is_pushed_to_dailer,
		authUser: auth.authUser.authUser,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getMMV: bindActionCreators(InventoryAction.getMMV, dispatch),
			getCityState: bindActionCreators(
				InventoryAction.getCityState,
				dispatch
			),
			SaveDownloadLog: bindActionCreators(
				LeadActions.SaveDownloadLog,
				dispatch
			),
		},
	};
};
export default withTranslation("audit")(
	connect(mapStateToProps, mapDispatchToProps)(AuditList)
);
