import { AuthService } from "../../service";
import { AuthConstants } from "../constants/auth.constants";
import { toast } from "react-toastify";
import secureStorage from "../../config/encrypt";

export const AuthActions = {
	login,
	logout,
	loginCaptcha,
	forgotPassword,
	getRefreshToken,
	resendOtp,
	getResetPasswordToken,
	validateResetToken,
	resetPassword,
	getLeadAccess,
};

function login(email, password) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			AuthService.login(email, password).then(
				async (user) => {
					if (user.data.status === 200) {
						user.data.data["authUser"] = Object.assign(
							{},
							user.data.data.user_data
						);
						delete user.data.data.user_data;
						secureStorage.setItem("authUserInfo", user.data.data);
						toast.success(user.data.message);
						await getLeadAccess(dispatch);
						await getSiteSettings(dispatch);
						dispatch(success(user.data.data));

						return resolve(user.data);
					} else {
						dispatch(failure("Invalid credentials "));
						return resolve(user && user.data);
					}
				},
				(error) => {
					dispatch(failure(error));
					return reject(error);
				}
			);
		});
	};

	function success(user) {
		return { type: AuthConstants.LOGIN_SUCCESS, user };
	}
	function failure(error) {
		return { type: AuthConstants.LOGIN_FAILURE, error };
	}
}

function logout() {
	AuthService.authLogout();
	secureStorage.removeItem("authUserInfo");
	secureStorage.removeItem("userAccess");

	return { type: AuthConstants.LOGOUT };
}

function loginCaptcha() {
	return (dispatch) => {
		AuthService.loginCaptcha().then(
			(captcha) => {
				if (captcha.data.status === 200) {
					dispatch(success(captcha.data.data));
				} else {
					dispatch(failure(captcha.data.message));
				}
			},
			(error) => {
				dispatch(failure(error));
			}
		);
	};

	function success(captcha) {
		return { type: AuthConstants.LOGIN_CAPTCHA, captcha };
	}
	function failure(error) {
		return { type: AuthConstants.LOGIN_CAPTCHA, error };
	}
}

function forgotPassword(postData) {
	return (dispatch) => {
		AuthService.forgotPassword(postData).then(
			(user) => {
				if (user.data.status === 200) {
					toast.success(user.data.message);
					dispatch(success(user.data.data));
				} else {
					dispatch(failure(user.data.message));
				}
			},
			(error) => {
				dispatch(failure(error));
			}
		);
	};

	function success(user) {
		return { type: AuthConstants.FORGOT_PASSWORD, user };
	}
	function failure(error) {
		return { type: AuthConstants.FORGOT_PASSWORD_ERROR, error };
	}
}

function getRefreshToken(postData) {
	return (dispatch) => {
		AuthService.getRefreshToken(postData).then(
			(token) => {
				if (token && token.data.status === 200) {
					token.data.data["authUser"] = Object.assign(
						{},
						token.data.data.user_data
					);
					delete token.data.data.user_data;
					secureStorage.setItem("authUserInfo", token.data.data);
					dispatch(success(token.data.data));
				} else {
					dispatch(failure("token_invalid"));
				}
			},
			(error) => {
				dispatch(failure(error));
			}
		);
	};

	function success(user) {
		return { type: AuthConstants.REFRESH_TOKEN, user };
	}
	function failure(error) {
		return { type: AuthConstants.REFRESH_TOKEN_ERROR, error };
	}
}

function resendOtp(postData) {
	return (dispatch) => {
		AuthService.resendOtp(postData).then(
			(user) => {
				if (user.data.status === 200) {
					toast.success(user.data.message);
					dispatch(success(user.data.data));
				} else {
					dispatch(failure(user.data.message));
				}
			},
			(error) => {
				dispatch(failure(error));
			}
		);
	};

	function success(user) {
		return { type: AuthConstants.RESEND_OTP, user };
	}
	function failure(error) {
		return { type: AuthConstants.RESEND_OTP_ERROR, error };
	}
}

function getResetPasswordToken(postData) {
	return (dispatch) => {
		AuthService.getResetPasswordToken(postData).then(
			(user) => {
				if (user.data.status === 200) {
					toast.success(user.data.message);
					dispatch(success(user.data.data));
				} else {
					if (user.data.data && user.data.data.otpMatchPerform) {
						dispatch(failure(user.data.data));
					} else {
						dispatch(failure(user.data.message));
					}
				}
			},
			(error) => {
				dispatch(failure(error));
			}
		);
	};

	function success(user) {
		return { type: AuthConstants.RESET_PASSWORD_TOKEN, user };
	}
	function failure(error) {
		return { type: AuthConstants.RESET_PASSWORD_TOKEN_ERROR, error };
	}
}

function validateResetToken(postData) {
	return (dispatch) => {
		AuthService.validateResetToken(postData).then(
			(user) => {
				if (user.data.status === 200) {
					dispatch(success(user.data.data));
				} else {
					dispatch(failure(user.data.message));
				}
			},
			(error) => {
				dispatch(failure(error));
			}
		);
	};

	function success(user) {
		return { type: AuthConstants.RESET_PASSWORD_TOKEN, user };
	}
	function failure(error) {
		return { type: AuthConstants.RESET_PASSWORD_TOKEN_ERROR, error };
	}
}

function resetPassword(postData) {
	return (dispatch) => {
		AuthService.resetPassword(postData).then(
			(user) => {
				if (user.data.status === 200) {
					toast.success(user.data.message);
					dispatch(success(user.data.data));
				} else {
					dispatch(failure(user.data.message));
				}
			},
			(error) => {
				dispatch(failure(error));
			}
		);
	};

	function success(user) {
		return { type: AuthConstants.RESET_PASSWORD, user };
	}
	function failure(error) {
		return { type: AuthConstants.RESET_PASSWORD_ERROR, error };
	}
}
async function getLeadAccess(dispatch) {
	try {
		const { status, data } = await AuthService.getLeadAccess();
		if (status === 200) {
			const { data: userAccess } = data;
			secureStorage.setItem("userAccess", userAccess);
			dispatch(success(userAccess));
		} else {
			dispatch(failure(data.message));
		}
	} catch (err) {
		dispatch(failure(err));
	}
	function success(user) {
		return { type: AuthConstants.ACCESS_FETCHED, user };
	}
	function failure(error) {
		return { type: AuthConstants.ACCESS_FETCHED_ERR, error };
	}
}

async function getSiteSettings(dispatch) {
	try {
		const { status, data } = await AuthService.getSiteSettings();
		if (status === 200) {
			const {
				data: { site_settings },
			} = data;
			secureStorage.setItem("site_settings", site_settings);
			dispatch(success(site_settings));
		} else {
			dispatch(failure(data.message));
		}
	} catch (err) {
		dispatch(failure(err));
	}
	function success(user) {
		return { type: AuthConstants.SITE_SETTING_FETCHED, user };
	}
	function failure(error) {
		return { type: AuthConstants.SITE_SETTING_FETCHED_ERR, error };
	}
}
