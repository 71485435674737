import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import SearchPerformcance from "./SearchPerformcance";
import { ReportService } from "../../../../service/reportService";
import PageLoader from "../../../elements/PageLoader";
import { CSVLink } from "react-csv";
import { LeadActions } from "../../../../store/action";
import { bindActionCreators } from "redux";
import config from "../../../../config/config";
class PerformanceReport extends Component {
	csvLink = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			performanceList: {},
			filterData: {},
			loading: false,
			isSearchClicked: false,
			csvDataIndividual: [],
		};
	}
	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		if (props.make_map && props.make_map !== state.make_map) {
			updateProps["make_map"] = props.make_map;
		}
		if (props.model_map && props.model_map !== state.model_map) {
			updateProps["model_map"] = props.model_map;
		}
		if (props.city_map && props.city_map !== state.city_map) {
			updateProps["city_map"] = props.city_map;
		}
		return updateProps;
	}
	onSubmitFilter = (filterData) => {
		this.setState({ loading: true });
		ReportService.getPerformanceReport(filterData)
			.then((result) => {
				const {
					data: { data: response = {} },
				} = result;
				this.setState(
					{
						filterData: filterData,
						performanceList: response,
						loading: false,
						isSearchClicked: true,
					},
					async () => {
						if (Object.keys(response).length) {
							await this.props.actions.SaveDownloadLog({
								module: "PerformanceReport",
								action: "2",
							});
						}
					}
				);
			})
			.catch((err) => {});
	};
	downloadCsvForDetail = (data) => {
		const { make_map, model_map, city_map } = this.state;
		if (data instanceof Array) {
			for (let i of data) {
				for (let j in i) {
					if (j === "Make") {
						i[j] = make_map[i[j]] ? make_map[i[j]] : "";
					} else if (j === "Model") {
						i[j] = model_map[i[j]] ? model_map[i[j]] : "";
					} else if (j === "City") {
						i[j] = city_map[i[j]] ? city_map[i[j]] : "";
					} else if (j === "CrossSell-City") {
						i[j] = city_map[i[j]] ? city_map[i[j]] : "";
					}
				}
			}

			this.setState({ csvDataIndividual: data }, async () => {
				if (data instanceof Array && data.length) {
					this.csvLink.current.link.click();
					await this.props.actions.SaveDownloadLog({
						module: "PerformanceReport",
						action: "1",
					});
				}
			});
		}
	};
	requestCsvDownload = (slug, value, campaign_id) => {
		// console.log("here", slug, value);
		if (value > 0) {
			const {
				filterData: { selected_month },
			} = this.state;
			const postData = {
				slug: slug,
				selected_month: selected_month,
				report_type: "performance_report",
				process_id: campaign_id,
			};
			ReportService.getReportDetail(postData)
				.then((result) => {
					const {
						data: { data = [] },
					} = result;
					this.downloadCsvForDetail(data);
				})
				.catch((err) => {});
		}
	};
	tableBody = (performanceList) => {
		let tableData = [],
			count = 0;
		for (let i in performanceList) {
			const {
				total: all_total = 0,
				active: all_active = 0,
				closed: all_closed = 0,
			} = performanceList[i].till_current_month
				? performanceList[i].till_current_month
				: {};
			const {
				total: mtd_current_total = 0,
				active: mtd_current_active = 0,
				closed: mtd_current_closed = 0,
			} = performanceList[i].mtd_current
				? performanceList[i].mtd_current
				: {};
			const {
				lms: mtd_current_spk_lms = 0,
				other: mtd_current_spk_other = 0,
				total: mtd_current_spk_total = 0,
			} = performanceList[i].mtd_current_spk
				? performanceList[i].mtd_current_spk
				: {};
			const {
				lms: sdlm_lms = 0,
				other: sdlm_other = 0,
				total: sdlm_total = 0,
			} = performanceList[i].sdlm ? performanceList[i].sdlm : {};
			const {
				lms: ftd_lms = 0,
				other: ftd_other = 0,
				total: ftd_total = 0,
			} = performanceList[i].ftd ? performanceList[i].ftd : {};

			tableData.push(
				<tr key={count}>
					<td key={`${count}_1`}>{i}</td>
					<td
						key={`${count}_2`}
						className={all_total > 0 ? "report-csv-list" : ""}
						onClick={() =>
							this.requestCsvDownload(
								"total_inspection",
								all_total,
								performanceList[i].campaign_id
							)
						}
					>
						{all_total}
					</td>
					<td
						key={`${count}_3`}
						className={all_active > 0 ? "report-csv-list" : ""}
						onClick={() =>
							this.requestCsvDownload(
								"active_inspection",
								all_active,
								performanceList[i].campaign_id
							)
						}
					>
						{all_active}
					</td>
					<td
						key={`${count}_4`}
						className={all_closed > 0 ? "report-csv-list" : ""}
						onClick={() =>
							this.requestCsvDownload(
								"closed_inspection",
								all_closed,
								performanceList[i].campaign_id
							)
						}
					>
						{all_closed}
					</td>
					<td
						key={`${count}_5`}
						className={
							mtd_current_total > 0 ? "report-csv-list" : ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"mtd_current_total",
								mtd_current_total,
								performanceList[i].campaign_id
							)
						}
					>
						{mtd_current_total}
					</td>
					<td
						key={`${count}_6`}
						className={
							mtd_current_active > 0 ? "report-csv-list" : ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"mtd_current_active",
								mtd_current_active,
								performanceList[i].campaign_id
							)
						}
					>
						{mtd_current_active}
					</td>
					<td
						key={`${count}_7`}
						className={
							mtd_current_closed > 0 ? "report-csv-list" : ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"mtd_current_closed",
								mtd_current_closed,
								performanceList[i].campaign_id
							)
						}
					>
						{mtd_current_closed}
					</td>
					<td
						key={`${count}_8`}
						className={
							mtd_current_spk_lms > 0 ? "report-csv-list" : ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"mtd_current_spk_lms",
								mtd_current_spk_lms,
								performanceList[i].campaign_id
							)
						}
					>
						{mtd_current_spk_lms}
					</td>
					<td
						key={`${count}_9`}
						className={
							mtd_current_spk_other > 0 ? "report-csv-list" : ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"mtd_current_spk_other",
								mtd_current_spk_other,
								performanceList[i].campaign_id
							)
						}
					>
						{mtd_current_spk_other}
					</td>
					<td
						key={`${count}_10`}
						className={
							mtd_current_spk_total > 0 ? "report-csv-list" : ""
						}
						onClick={() =>
							this.requestCsvDownload(
								"mtd_current_spk_total",
								mtd_current_spk_total,
								performanceList[i].campaign_id
							)
						}
					>
						{mtd_current_spk_total}
					</td>
					<td
						key={`${count}_11`}
						className={sdlm_lms > 0 ? "report-csv-list" : ""}
						onClick={() =>
							this.requestCsvDownload(
								"sdlm_lms",
								sdlm_lms,
								performanceList[i].campaign_id
							)
						}
					>
						{sdlm_lms}
					</td>
					<td
						key={`${count}_12`}
						className={sdlm_other > 0 ? "report-csv-list" : ""}
						onClick={() =>
							this.requestCsvDownload(
								"sdlm_other",
								sdlm_other,
								performanceList[i].campaign_id
							)
						}
					>
						{sdlm_other}
					</td>
					<td
						key={`${count}_13`}
						className={sdlm_total > 0 ? "report-csv-list" : ""}
						onClick={() =>
							this.requestCsvDownload(
								"sdlm_total",
								sdlm_total,
								performanceList[i].campaign_id
							)
						}
					>
						{sdlm_total}
					</td>
					<td
						key={`${count}_14`}
						className={ftd_lms > 0 ? "report-csv-list" : ""}
						onClick={() =>
							this.requestCsvDownload(
								"ftd_lms",
								ftd_lms,
								performanceList[i].campaign_id
							)
						}
					>
						{ftd_lms}
					</td>
					<td
						key={`${count}_15`}
						className={ftd_other > 0 ? "report-csv-list" : ""}
						onClick={() =>
							this.requestCsvDownload(
								"ftd_other",
								ftd_other,
								performanceList[i].campaign_id
							)
						}
					>
						{ftd_other}
					</td>
					<td
						key={`${count}_16`}
						className={ftd_total > 0 ? "report-csv-list" : ""}
						onClick={() =>
							this.requestCsvDownload(
								"ftd_total",
								ftd_total,
								performanceList[i].campaign_id
							)
						}
					>
						{ftd_total}
					</td>
				</tr>
			);
			count += 1;
		}

		return tableData;
	};
	render() {
		const {
			performanceList,
			filterData,
			loading,
			isSearchClicked,
			csvDataIndividual,
		} = this.state;
		return (
			<div>
				<SearchPerformcance
					onSubmitFilter={this.onSubmitFilter}
					loading={loading}
				/>
				<div className="hidden">
					<CSVLink
						className="hidden"
						ref={this.csvLink}
						filename="lead_detail.csv"
						data={csvDataIndividual}
					>
						{" "}
					</CSVLink>
				</div>
				<div className="container-fluid reportContainer">
					<div className="result-wrap">
						<h2>Performance Report</h2>
						<div className="card">
							{isSearchClicked && (
								<div className="table-responsive table-colFixed">
									<table className="table table-bordered table-hover salesReportTable ">
										<thead>
											<tr>
												<th colSpan="1">
													{this.props.t(
														"PERFORMANCE_REPORT.TABLE.CAMPAIGN_NAME"
													)}
												</th>
												<th colSpan="3">
													{this.props.t(
														"PERFORMANCE_REPORT.TABLE.ALL_TIME_LEADS"
													)}
												</th>
												<th colSpan="3">
													{this.props.t(
														"PERFORMANCE_REPORT.TABLE.MTD_LEADS"
													)}
												</th>
												<th colSpan="3">
													{this.props.t(
														"PERFORMANCE_REPORT.TABLE.MTD_" +
															config.constants
																.LABEL_SPK
													)}
												</th>
												<th colSpan="3">
													{this.props.t(
														"PERFORMANCE_REPORT.TABLE.SDLM_MTD_" +
															config.constants
																.LABEL_SPK
													)}
												</th>
												<th colSpan="3">
													{this.props.t(
														"PERFORMANCE_REPORT.TABLE.FTD_" +
															config.constants
																.LABEL_SPK
													)}
												</th>
											</tr>
											<tr>
												<th></th>
												<th>
													{this.props.t(
														"PERFORMANCE_REPORT.TABLE.SUB_HEAD.TOTAL"
													)}
												</th>
												<th>
													{this.props.t(
														"PERFORMANCE_REPORT.TABLE.SUB_HEAD.ACTIVE"
													)}
												</th>
												<th>
													{this.props.t(
														"PERFORMANCE_REPORT.TABLE.SUB_HEAD.CLOSED"
													)}
												</th>

												<th>
													{this.props.t(
														"PERFORMANCE_REPORT.TABLE.SUB_HEAD.TOTAL"
													)}
												</th>
												<th>
													{this.props.t(
														"PERFORMANCE_REPORT.TABLE.SUB_HEAD.ACTIVE"
													)}
												</th>
												<th>
													{this.props.t(
														"PERFORMANCE_REPORT.TABLE.SUB_HEAD.CLOSED"
													)}
												</th>

												<th>
													{this.props.t(
														"PERFORMANCE_REPORT.TABLE.SUB_HEAD.LMS_" +
															config.constants
																.LABEL_SPK
													)}
												</th>
												<th>
													{this.props.t(
														"PERFORMANCE_REPORT.TABLE.SUB_HEAD.OTHER_" +
															config.constants
																.LABEL_SPK
													)}
												</th>
												<th>
													{this.props.t(
														"PERFORMANCE_REPORT.TABLE.SUB_HEAD.TOTAL_" +
															config.constants
																.LABEL_SPK
													)}
												</th>

												<th>
													{this.props.t(
														"PERFORMANCE_REPORT.TABLE.SUB_HEAD.LMS_" +
															config.constants
																.LABEL_SPK
													)}
												</th>
												<th>
													{this.props.t(
														"PERFORMANCE_REPORT.TABLE.SUB_HEAD.OTHER_" +
															config.constants
																.LABEL_SPK
													)}
												</th>
												<th>
													{this.props.t(
														"PERFORMANCE_REPORT.TABLE.SUB_HEAD.TOTAL_" +
															config.constants
																.LABEL_SPK
													)}
												</th>

												<th>
													{this.props.t(
														"PERFORMANCE_REPORT.TABLE.SUB_HEAD.LMS_" +
															config.constants
																.LABEL_SPK
													)}
												</th>
												<th>
													{this.props.t(
														"PERFORMANCE_REPORT.TABLE.SUB_HEAD.OTHER_" +
															config.constants
																.LABEL_SPK
													)}
												</th>
												<th>
													{this.props.t(
														"PERFORMANCE_REPORT.TABLE.SUB_HEAD.TOTAL_" +
															config.constants
																.LABEL_SPK
													)}
												</th>
											</tr>
										</thead>
										<tbody>
											{performanceList &&
											Object.keys(performanceList)
												.length ? (
												this.tableBody(performanceList)
											) : (
												<tr>
													<td>No record found</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							)}
							{loading && (
								<PageLoader
									loaderClass=" text-center loader content-loader"
									loadertext="true"
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ inventory }) => {
	return {
		make_map: inventory.make_map ? inventory.make_map : {},
		model_map: inventory.model_map ? inventory.model_map : {},
		city_map:
			inventory.city instanceof Array
				? inventory.city.reduce(
						(obj, item) => ((obj[item.id] = item.name), obj),
						{}
				  )
				: {},
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			SaveDownloadLog: bindActionCreators(
				LeadActions.SaveDownloadLog,
				dispatch
			),
		},
	};
};
export default withTranslation("report")(
	connect(mapStateToProps, mapDispatchToProps)(PerformanceReport)
);
