import React from "react";
import InputField from "../elements/InputField";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AuthActions } from "../../store/action";
import { bindActionCreators } from "redux";

class Captcha extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			submitted: false,
			captcha: "",
			captchaInvalid: "",
			errors: this.props.errors,
		};
	}

	static getDerivedStateFromProps = (nextProps, prevState) => {
		let updatedStateData = {};
		if (nextProps.errors !== prevState.errors) {
			updatedStateData["errors"] = nextProps.errors;
		}

		return updatedStateData;
	};

	componentDidMount() {
		const { childRef } = this.props;
		childRef(this);
		this.loadCaptchaImage();
	}

	componentWillUnmount() {
		const { childRef } = this.props;
		childRef(undefined);
	}

	/**
	 *  Load Captcha Image
	 */

	loadCaptchaImage = (err = []) => {
		this.setState({
			captcha: "",
		});

		if (typeof err.captcha !== "undefined") {
			this.setState({
				captchaInvalid: err.captcha,
			});
		}
		this.props.actions.loginCaptcha();
	};

	/**
	 * Input Handler
	 * @param e (synthatic element)
	 */
	handleInputChange = async (e) => {
		await this.setState({
			captcha: e.target.value,
		});
		this.props.onKeyPressCaptcha(this.state.captcha);
	};

	/**
	 * Reset Captcha Error
	 */
	resetCaptchaError = async () => {
		await this.setState({
			captchaInvalid: "",
		});
	};

	render() {
		const { captchaImage, captchaError } = this.props;
		const { captchaInvalid, errors } = this.state;
		return (
			<div className="row">
				<div className="col-sm-6">
					<div
						className={
							"form-group" + (captchaInvalid ? " has-error" : "")
						}
					>
						<InputField
							inputProps={{
								id: "captcha",
								type: "text",
								name: "captcha",
								label: this.props.t("LOGIN.CAPTCHA"),
								placeholder: this.props.t(
									"LOGIN.CAPTCHA_PLCAEHOLDER"
								),
								value: this.state.captcha,
								dataerror: (errors && errors.captcha) || "",
							}}
							onChange={this.handleInputChange}
						/>
					</div>
				</div>
				<div className="col-xs-6  col-sm-5 col-md-4">
					{captchaError ? (
						<span>{captchaError}</span>
					) : (
						<img src={captchaImage} className="captchaimg" alt="" />
					)}
				</div>

				<div className="col-xs-3 col-sm-1 col-md-2">
					<span
						className="reload"
						onClick={this.loadCaptchaImage}
						title="reload captcha"
					>
						&#x21bb;
					</span>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth.auth,
		loggingIn: state.auth.loggingIn,
		captchaImage: state.auth.captchaImage,
		captchaValue: state.auth.captchaValue,
		captchaError: state.auth.captchaError,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			loginCaptcha: bindActionCreators(
				AuthActions.loginCaptcha,
				dispatch
			),
		},
	};
};

export default withTranslation("common")(
	connect(mapStateToProps, mapDispatchToProps)(Captcha)
);
