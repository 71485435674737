/* eslint-disable */
import React, { Component } from "react";
import CheckBox from "../../elements/CheckBox";
import { withTranslation } from "react-i18next";
class DealerComments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkedItems: false,
			searchtable: "",
			selectedDealers: this.props.selectedDealers || [],
			selectedDealers_ids: this.props.selectedDealers_ids || [],
			search: null,
			allChecked: false,
			dealerDropDown: false,
			dealersData: [],
			boxIndex: this.props.index || 0,
			tabs: [
				{
					id: 1,
					title: this.props.t("Internal"),
				},
				{
					id: 2,
					title: this.props.t("External"),
				},
			],
			showTab: 1,
			activeTab: "internal",
			commentData: [],
			isLmsFlagEnabled: false,
		};
		this.ChangeCheckbox = this.ChangeCheckbox.bind(this);
		this.handleOutsideClick = this.handleOutsideClick.bind(this);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const updatedState = {};
		if (
			prevState.dealersData !== nextProps.dealersData ||
			nextProps.updateStatus
		) {
			if (prevState.dealersData !== nextProps.dealersData) {
				updatedState["dealersData"] = nextProps.dealersData;
			}

			if (nextProps.index === 0 || nextProps.updateStatus) {
				let dealers_list = nextProps.dealersData || [];
				let namelist = dealers_list.map((v) => v.name);
				let dealerid_list = dealers_list.map((v) => v.value);
				namelist.unshift("To all Dealers");
				dealerid_list.unshift("toAll");
				updatedState["selectedDealers"] = namelist;
				updatedState["selectedDealers_ids"] = dealerid_list;
				nextProps.manageCommentBoxes(nextProps.index, dealerid_list);
			}
		}
		if (prevState.activeTab !== nextProps.activeTab) {
			updatedState["activeTab"] = nextProps.activeTab;
		}
		if (prevState.commentData !== nextProps.commentData) {
			updatedState["commentData"] = nextProps.commentData;
		}
		if (prevState.isLmsFlagEnabled !== nextProps.isLmsFlagEnabled) {
			updatedState["isLmsFlagEnabled"] = nextProps.isLmsFlagEnabled;
		}

		updatedState["boxIndex"] = nextProps.index;
		return updatedState;
	}

	searchSpace = (event) => {
		let keyword = event.target.value;
		this.setState({ search: keyword });
	};

	// toggleDealers = () => {
	// 	this.setState({ dealerDropDown: !this.state.dealerDropDown });
	// 	//console.log("sdf");
	// };
	toggleDealers = () => {
		if (!this.state.dealerDropDown) {
			// attach/remove event handler
			document.addEventListener("click", this.handleOutsideClick, false);
		} else {
			document.removeEventListener(
				"click",
				this.handleOutsideClick,
				false
			);
		}

		this.setState((prevState) => ({
			dealerDropDown: !this.state.dealerDropDown,
			//   dealerDropDown: !prevState.dealerDropDown,
		}));
	};
	handleOutsideClick = (e) => {
		// ignore clicks on the component itself
		if (this.node && this.node.contains(e.target)) {
			return;
		}

		this.toggleDealers();
	};
	ChangeCheckbox = (event) => {
		let {
			selectedDealers_ids,
			selectedDealers,
			boxIndex,
			dealersData,
		} = this.state;
		const target = event.target;
		// const value =
		// 	target.name === "checkedItems" ? target.checked : target.id;

		let usedDealersArray = this.props.usedDealers || [],
			dealer2Disable = [];
		if (usedDealersArray.length) {
			usedDealersArray.map((dealers, currindex) => {
				if (dealers.length && currindex != boxIndex) {
					dealer2Disable = dealer2Disable.concat(dealers);
				}
			});
		}

		if (target.checked && selectedDealers.indexOf(event.target.id) === -1) {
			//var index = this.state.selectedDealers.indexOf(event.target.value);
			if (boxIndex === 0 && event.target.value === "toAll") {
				let namelist = dealersData
					.filter((v) => !dealer2Disable.includes(v.id))
					.map((v) => v.name);
				let dealerid_list = dealersData
					.filter((v) => !dealer2Disable.includes(v.value))
					.map((v) => v.value);
				namelist.unshift("To all Dealers");
				dealerid_list.unshift("toAll");
				selectedDealers = namelist;
				selectedDealers_ids = dealerid_list;
			} else {
				selectedDealers.push(event.target.id);
				selectedDealers_ids.push(event.target.value);
				if (
					dealersData.length === selectedDealers_ids.length &&
					boxIndex === 0
				) {
					selectedDealers.unshift("To all Dealers");
					selectedDealers_ids.unshift("toAll");
				}
			}
		} else {
			var index = selectedDealers.indexOf(event.target.id);
			if (index !== -1) {
				selectedDealers.splice(index, 1);
				selectedDealers_ids.splice(index, 1);
				if (boxIndex === 0 && selectedDealers_ids.includes("toAll")) {
					selectedDealers.splice("To all Dealers", 1);
					selectedDealers_ids.splice("toAll", 1);
				}
			}
			if (
				(boxIndex === 0 && event.target.value === "toAll") ||
				(selectedDealers_ids.length === 1 &&
					selectedDealers_ids[0] === "toAll")
			) {
				selectedDealers = [];
				selectedDealers_ids = [];
			}
		}
		this.setState({
			selectedDealers_ids: selectedDealers_ids,
			selectedDealers: selectedDealers,
		});
		this.props.manageCommentBoxes(boxIndex, selectedDealers_ids);
	};

	closeComment = () => {
		this.props.removeCommentBoxes(
			this.state.boxIndex,
			this.state.selectedDealers_ids
		);
	};

	handleTabChange = async (event) => {
		await this.setState({
			showTab: +event.currentTarget.dataset.key,
		});
	};

	changeCommentbox = async (key, e) => {
		let { activeTab } = this.state;
		if (typeof this.props.changeCommentText === "function")
			this.props.changeCommentText(key, e, activeTab);
	};

	render() {
		const {
			dealersData,
			boxIndex,
			selectedDealers_ids,
			activeTab,
			commentData,
			isLmsFlagEnabled,
		} = this.state;
		let usedDealersArray = this.props.usedDealers || [],
			dealer2Disable = [];
		if (usedDealersArray.length) {
			usedDealersArray.map((dealers, currindex) => {
				if (dealers.length && currindex != boxIndex) {
					dealer2Disable = dealer2Disable.concat(dealers);
				}
			});
		}
		let manageStatus_error = this.props.manageStatus_error || {};
		console.log(
			"isLmsFlagEnabled in comme====>",
			isLmsFlagEnabled,
			"dealer2Disable====>",
			dealer2Disable.length,
			"dealedsdata=>",
			dealersData.length
		);
		return (
			<div>
				{activeTab === "external" ? (
					<div className="commentArea" key={`commentArea${boxIndex}`}>
						<div className="multiCheckArea">
							{boxIndex ? (
								<span
									className="commentClose"
									onClick={this.closeComment}
								>
									×
								</span>
							) : (
								""
							)}
							<div
								className="dealerLabel name-ellipsis"
								onClick={this.toggleDealers}
							>
								{this.state.selectedDealers_ids.length &&
								this.state.selectedDealers_ids.includes("toAll")
									? this.props.t(
											"MANAGE_STATUS.TO_ALL_DEALEARS"
									  )
									: this.state.selectedDealers.map(
											(role, index) => (
												<span
													key={boxIndex + "_" + index}
												>
													{role},{" "}
												</span>
											)
									  )}
								{/* {this.state.selectedDealers.map((role, index) => (
							<span key={boxIndex + "_" + index}>{role}, </span>
						))} */}
								<i className="ic-keyboard_arrow_down-24px"></i>
							</div>
							<div
								ref={(node) => {
									this.node = node;
								}}
								className={
									this.state.dealerDropDown === true
										? "mulitCheckDropDown open"
										: "mulitCheckDropDown"
								}
							>
								<div className="mrg-b10">
									{/* <SearchBox /> */}
									<input
										type="text"
										className="form-control searchInput"
										placeholder={this.props.t(
											"MANAGE_STATUS.ENTER_ITEM_TO_SEARCH"
										)}
										onChange={(e) => this.searchSpace(e)}
									/>
								</div>
								<ul key={`list` + boxIndex}>
									{boxIndex === 0 ? (
										<li className="text-bold">
											<CheckBox
												label={this.props.t(
													"MANAGE_STATUS.TO_ALL_DEALEARS"
												)}
												name="all_dse"
												type="checkbox"
												id={this.props.t(
													"MANAGE_STATUS.TO_ALL_DEALEARS"
												)}
												checked={
													selectedDealers_ids.includes(
														"toAll"
													) &&
													selectedDealers_ids.length
														? true
														: false
												}
												disabled={
													dealer2Disable.length >=
														dealersData.length ||
													isLmsFlagEnabled
														? true
														: false
												}
												value="toAll"
												onChange={this.ChangeCheckbox}
											/>
										</li>
									) : (
										""
									)}

									{dealersData
										.filter((data) => {
											// console.log(this.state.search);

											if (this.state.search == null)
												return data;
											else if (
												data.name
													.toLowerCase()
													.includes(
														this.state.search.toLowerCase()
													)
											) {
												return data;
											}
										})
										.map((dealer, index) => (
											<li
												key={`${dealer.id}_${boxIndex}`}
											>
												<CheckBox
													label={dealer.name}
													name={`dseid_${boxIndex}__${index}`}
													type="checkbox"
													id={dealer.name}
													value={dealer.id}
													disabled={
														(dealer2Disable.length &&
															dealer2Disable.includes(
																dealer.id
															)) ||
														isLmsFlagEnabled
													}
													checked={
														selectedDealers_ids.includes(
															dealer.id
														) &&
														selectedDealers_ids.length
															? true
															: false
													}
													onChange={
														this.ChangeCheckbox
													}
												/>
											</li>
										))}
								</ul>
							</div>
						</div>

						<textarea
							name={"comments__" + boxIndex}
							placeholder={this.props.t(
								"MANAGE_STATUS.COMMENTS_HERE"
							)}
							disabled={isLmsFlagEnabled}
						></textarea>

						{manageStatus_error &&
						manageStatus_error["comments_" + boxIndex] ? (
							<span className="error show">
								{manageStatus_error["comments_" + boxIndex]}
							</span>
						) : (
							""
						)}
					</div>
				) : (
					""
				)}
			</div>
		);
	}
}
export default withTranslation("lead_detail")(DealerComments);
