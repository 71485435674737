import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { AuditAction } from "../../../store/action";
import { bindActionCreators } from "redux";
import InputField from "../../elements/InputField";
import { toast } from "react-toastify";

class ActionPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			actionType: this.props.actionType,
			selectedIds: this.props.selectedIds,
			auditlead_id: this.props.auditlead_id,
			comment: "",
			errors: {},
		};
	}
	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		return updateProps;
	}
	componentDidMount = () => {};

	handleChange = (sname, event) => {
		const { target } = event ? event : {};
		const { value, type } = target;
		this.setState({ comment: value, errors: {} });
	};
	closeModal = () => {
		if (typeof this.props.closeModal === "function") {
			this.props.closeModal();
		}
	};
	updateStatus = async () => {
		const {
			selectedIds,
			actionType,
			comment,
			errors,
			auditlead_id,
		} = this.state;
		let selectedAuditLead = [];
		selectedAuditLead = ["ACCEPTED", "REJECTED", "REFUSED"].includes(
			actionType
		)
			? [auditlead_id]
			: selectedIds;
		if (!comment) {
			errors["comment"] = this.props.t("SAVE.REQUIRED_FIELD");
			this.setState({ errors });
			return;
		} else {
			let postData = {
				auditlead_id: selectedAuditLead,
				audit_status: actionType,
				comment: comment,
			};
			let response = await this.props.actions.updateAuditStatus(postData);
			if (response && response.status) {
				this.props.updateList();
				toast.success(response.response);
			}
		}
	};

	render() {
		const { actionType, comment, errors, selectedIds } = this.state;
		return (
			<div className="">
				<span>
					{actionType === "ACCEPTED"
						? this.props.t("SEARCH_AUDIT.ACCEPT_MSG")
						: actionType === "REJECTED"
						? this.props.t("SEARCH_AUDIT.REJECT_MSG")
						: actionType === "REFUSED"
						? this.props.t("SEARCH_AUDIT.REFUSE_MSG")
						: this.props.t("SEARCH_AUDIT.CLOSE_MSG")}
					&nbsp;&nbsp;&nbsp;
				</span>
				<div>
					<InputField
						inputProps={{
							id: "comment",
							type: "text",
							value: comment,
							name: "comment",
							autoComplete: "off",
						}}
						autoComplete="off"
						onChange={this.handleChange.bind(this, "comment")}
					/>
					<span className="text-danger">{errors.comment || ""}</span>
				</div>
				<div className="add-to-dialer-footer">
					<div className="clearfix">
						<div className="col-sm-6">
							<button
								type="button"
								className="btn btn-primary"
								onClick={this.updateStatus}
							>
								{this.props.t("SEARCH_AUDIT.SUBMIT")}
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ config }) => {
	return {};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			updateAuditStatus: bindActionCreators(
				AuditAction.updateAuditStatus,
				dispatch
			),
		},
	};
};
export default withTranslation("audit")(
	connect(mapStateToProps, mapDispatchToProps)(ActionPopup)
);
