import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { ConfigAction, LeadActions } from "../../../store/action";
import { withTranslation } from "react-i18next";
import SearchOpsReport from "./SearchOpsReport";
import { connect } from "react-redux";
import config from "../../../config/config";
import dateformat from "dateformat";
import { CSVLink, CSVDownload } from "react-csv";
import { ReportService } from "../../../service/reportService";
import DatePicker from "react-datepicker";
import dateFormat from "dateformat";
import PageLoader from "../../elements/PageLoader";
import moment from "moment";

class OpsReport extends Component {
	csvLink = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			campaign_name: "",
			last_updated: "",
			campaign_list: [],
			campaign_data: [],
			subHeaders: [],
			csvJsonData: [],
			call_data: [],
			filterData: {},
			list_id: -1,
			make_map: {},
			model_map: {},
			city_map: {},
			csvDataIndividual: [],
			gm_campaign_process_list: [],
			is_search: false,
			report_date_to_update: new Date(),
			loading: false,
			selected_date: "",
		};
	}
	componentDidMount = () => {
		const requestBody = {
			config_data: ["gm_campaign_list", "gm_campaign_process_list"],
		};
		this.props.actions.getConfigData(requestBody);
	};
	static getDerivedStateFromProps(props, state) {
		console.log(props);
		const updateProps = {};
		if (
			props.campaign_list &&
			props.campaign_list !== state.campaign_list
		) {
			updateProps["campaign_list"] = props.campaign_list;
		}
		if (
			props.gm_campaign_process_list &&
			props.gm_campaign_process_list !== state.gm_campaign_process_list
		) {
			updateProps["gm_campaign_process_list"] =
				props.gm_campaign_process_list;
		}
		if (props.make_map && props.make_map !== state.make_map) {
			updateProps["make_map"] = props.make_map;
		}
		if (props.model_map && props.model_map !== state.model_map) {
			updateProps["model_map"] = props.model_map;
		}
		if (props.city_map && props.city_map !== state.city_map) {
			updateProps["city_map"] = props.city_map;
		}
		return updateProps;
	}
	handleSlugPartitionData = () => {
		// if (
		// 	data[i][j] &&
		// 	data[i][j].includes("call_") &&
		// 	!isEncountered
		// ) {
		// 	isEncountered = true;
		// 	tempData.push(
		// 		this.props.t("OPS_REPORT.CONNECTED_CALLS")
		// 	);
		// 	for (
		// 		let c = 0;
		// 		c < Object.keys(data[i]).length - 2;
		// 		c++
		// 	) {
		// 		tempData.push("");
		// 	}
		// 	csvJsonData.push(tempData);
		// }
	};
	getSlugPartition = (slug, encounter, len) => {
		let heading = "",
			tempData = [];
		if (
			slug.includes("leads_attempted") &&
			!encounter.includes("leads_attempted")
		) {
			encounter.push("leads_attempted");
			heading = this.props.t("OPS_REPORT.CALL_DETAILS");
		} else if (
			slug.includes("cross_sell") &&
			!encounter.includes("cross_sell")
		) {
			encounter.push("cross_sell");
			heading = this.props.t("OPS_REPORT.CROSS_SELL");
		} else if (
			slug.includes("call_report") &&
			!encounter.includes("call_report")
		) {
			encounter.push("call_report");
			heading = this.props.t("OPS_REPORT.CURRENT_STATUS");
		} else if (
			slug.includes("bought_a_car_unknown") &&
			!encounter.includes("bought_a_car_unknown")
		) {
			encounter.push("bought_a_car_unknown");
			heading = this.props.t("OPS_REPORT.BOUGHT_A_CAR");
		}
		if (heading) {
			tempData.push(heading);
			for (let c = 0; c < len; c++) {
				tempData.push("");
			}
		}
		return tempData;
	};
	submitRequest = (filterData) => {
		this.setState({
			loading: true,
			is_search: true,
			selected_date: filterData.selected_month,
		});
		let {
			campaign_name,
			campaign_list,
			campaign_data,
			csvJsonData,
		} = this.state;
		campaign_data = [];
		ReportService.getOpsReport(filterData)
			.then((result) => {
				let subHeader = [],
					encounter = [];

				const {
					data: { data: response = [] },
				} = result;

				if (response instanceof Array && response.length) {
					const data = response;
					const { process_id } = filterData;
					const campaignData = campaign_list.find(
						(e) => e.value === process_id
					);

					if (campaignData) {
						campaign_name = Object.keys(data[0])[0];
						// campaign_name = campaignData.label;
					}

					csvJsonData = [];
					campaign_data = [];
					for (let i = 0; i < data.length; i++) {
						let rowData = [];
						for (let j in data[i]) {
							if (j === "List_id") {
								// list_id = data[i][j];
							}
							if (!["slug", "description"].includes(j)) {
								if (i === 0) subHeader.push(j);
								rowData.push(data[i][j]);
							} else {
								if (["slug"].includes(j)) {
									const response = this.getSlugPartition(
										data[i][j],
										encounter,
										data[i].length - 2
									);
									if (response.length)
										csvJsonData.push(response);
								}
							}
						}

						csvJsonData.push(rowData);
					}

					campaign_data = data;
					if (subHeader instanceof Array && subHeader.length) {
						csvJsonData.unshift(subHeader);
					}
				}

				this.setState(
					{
						campaign_name: campaign_name,
						subHeaders: subHeader,
						csvJsonData: csvJsonData,
						campaign_data: campaign_data,
						// list_id: list_id,
						filterData: filterData,
						is_search: true,
						loading: false,
					},
					async () => {
						if (
							campaign_data instanceof Array &&
							campaign_data.length
						) {
							await this.props.actions.SaveDownloadLog({
								module: "OpsReport",
								action: "2",
							});
						}
					}
				);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	downloadCsvForDetail = (data) => {
		const { make_map, model_map, city_map } = this.state;
		if (data instanceof Array) {
			for (let i of data) {
				for (let j in i) {
					if (j === "Make") {
						i[j] = make_map[i[j]] ? make_map[i[j]] : "";
					} else if (j === "Model") {
						i[j] = model_map[i[j]] ? model_map[i[j]] : "";
					} else if (j === "City") {
						i[j] = city_map[i[j]] ? city_map[i[j]] : "";
					} else if (j === "CrossSell-City") {
						i[j] = city_map[i[j]] ? city_map[i[j]] : "";
					}
				}
			}

			this.setState({ csvDataIndividual: data }, async () => {
				if (data instanceof Array && data.length) {
					this.csvLink.current.link.click();
					await this.props.actions.SaveDownloadLog({
						module: "OpsReport",
						action: "1",
					});
				}
			});
		}
	};
	getParticularRecordDetail = (slug, property) => {
		const {
			filterData: {
				process_id,
				selected_month = "",
				campaign_id,
				selected_date,
			},
		} = this.state;
		const postData = {
			process_id: process_id,
			all_dates: property.split(" ")[0] === "MTD" ? true : false,
			slug: slug,
		};
		if (campaign_id) {
			postData["campaign_id"] = campaign_id;
		}
		let tempI = property.split(" ");
		let lastMonthDate = moment(this.state.selected_date).subtract(
			1,
			"months"
		);
		let lastMnth = dateformat(lastMonthDate, "mmm");
		let secLastMonthDate = moment(this.state.selected_date).subtract(
			2,
			"months"
		);
		let secLastMnth = dateformat(secLastMonthDate, "mmm");
		if (tempI.length && tempI[0] === "MTD" && tempI[1]) {
			if (tempI[1] === lastMnth)
				postData["selected_month"] = lastMonthDate;
			if (tempI[1] === secLastMnth)
				postData["selected_month"] = secLastMonthDate;
		} else {
			if (selected_month) {
				postData["selected_month"] = selected_month;
			}
		}
		// if (campaign_id) {
		// 	postData["campaign_id"] = campaign_id;
		// }
		const date = Date.parse(property)
			? dateformat(property, config.constants.DATE_FORMAT_YMD)
			: "";
		if (date) postData["date"] = date;
		postData["report_type"] = "ops_report";
		ReportService.getReportDetail(postData)
			.then((result) => {
				const {
					data: { data },
				} = result;
				this.downloadCsvForDetail(data);
			})
			.catch((err) => {});
	};
	getInnerTable = (obj, index) => {
		const { campaign_name } = this.state;
		let innerTd = [],
			countIndex = 0;
		const slug = obj["slug"] ? obj["slug"] : "";
		// const list_id = obj["List_id"] ? obj["List_id"] : "";
		let isHeading = false;
		for (let i in obj) {
			if (i !== "slug" && i !== "description") {
				isHeading = false;
				let property = "";
				if (
					slug &&
					!slug.includes("total_agent_loggened_in") &&
					![campaign_name].includes(i)
				) {
					property = (
						<span
							className={`${obj[i] > 0 ? "report-csv-list" : ""}`}
							onClick={() =>
								obj[i] > 0
									? this.getParticularRecordDetail(slug, i)
									: ""
							}
						>
							{obj[i]}
						</span>
					);
				} else {
					property = obj[i];
					isHeading = true;
				}
				// console.log(obj[i]);
				innerTd.push(
					<td
						key={`${countIndex}_${index}`}
						title={
							isHeading && obj.description ? obj.description : ""
						}
					>
						{property}
					</td>
				);
				countIndex++;
			}
		}
		return innerTd;
	};
	getInnerTr = (i, campaign_data) => {
		return <tr key={i}>{this.getInnerTable(campaign_data, i)}</tr>;
	};
	getSlugPartitionHtml = (slug, encounter, len) => {
		let heading = "",
			tempData = [],
			html = [];
		if (
			slug.includes("leads_attempted") &&
			!encounter.includes("leads_attempted")
		) {
			encounter.push("leads_attempted");
			heading = <b>{this.props.t("OPS_REPORT.CALL_DETAILS")}</b>;
		} else if (
			slug.includes("cross_sell") &&
			!encounter.includes("cross_sell")
		) {
			encounter.push("cross_sell");
			heading = <b>{this.props.t("OPS_REPORT.CROSS_SELL")}</b>;
		} else if (
			slug.includes("call_report") &&
			!encounter.includes("call_report")
		) {
			encounter.push("call_report");
			heading = <b>{this.props.t("OPS_REPORT.CURRENT_STATUS")}</b>;
		} else if (
			slug.includes("bought_a_car_unknown") &&
			!encounter.includes("bought_a_car_unknown")
		) {
			encounter.push("bought_a_car_unknown");
			heading = <b>{this.props.t("OPS_REPORT.BOUGHT_A_CAR")}</b>;
		}
		if (heading) {
			tempData.push(heading);
			for (let c = 0; c < len - 1; c++) {
				tempData.push("");
			}
			html.push(
				<tr key={`slu_tr_${slug}`}>
					{tempData.map((e, cuIn) => {
						return <td key={cuIn}>{e}</td>;
					})}
				</tr>
			);
		}
		return html;
	};
	getTableData = () => {
		const { campaign_data } = this.state;
		let html = [],
			encounter = [];
		for (let i = 0; i < campaign_data.length; i++) {
			let tempObj = campaign_data[i];
			if (tempObj) {
				const tempRow = this.getSlugPartitionHtml(
					tempObj["slug"],
					encounter,
					Object.keys(tempObj).length - 2
				);
				if (tempRow.length) html.push(tempRow);
			}

			let innerTr = this.getInnerTr(i, campaign_data[i]);
			html.push(innerTr);
		}

		return html;
	};

	updateOpsReport = () => {
		let params = {
			process_date: dateFormat(
				this.state.report_date_to_update,
				config.constants.DATE_FORMAT_YMD
			),
		};
		ReportService.updateOpsReport(params)
			.then((result) => {
				if (result && result.status && +result.status === 200)
					this.submitRequest(this.state.filterData);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	render() {
		const {
			campaign_name,
			last_updated,
			csvJsonData,
			campaign_data,
			subHeaders,
			csvDataIndividual,
			is_search,
			report_date_to_update,
			loading,
		} = this.state;
		return (
			<div>
				<SearchOpsReport
					onSubmitFilter={this.submitRequest}
					loading={loading}
				/>

				<div className="hidden">
					<CSVLink
						className="hidden"
						ref={this.csvLink}
						filename="lead_detail.csv"
						data={csvDataIndividual}
					>
						{" "}
					</CSVLink>
				</div>

				<div className="container-fluid reportContainer">
					<h2>OPS Report</h2>
					{is_search && (
						<div className="result-wrap">
							<div className="row mrg-b5">
								<div className="col-sm-8">
									{campaign_name && (
										<p className="countNo mrg-t5 mrg-b0">
											{this.props.t(
												"OPS_REPORT.CAMPAIGNS"
											)}
											-{campaign_name}
										</p>
									)}
									{last_updated && (
										<p className="countNo mrg-t5 mrg-b0">
											{this.props.t(
												"OPS_REPORT.LAST_UPDATED"
											)}
											-
											{dateformat(
												last_updated,
												config.constants.DATE_FORMAT
											)}
										</p>
									)}
								</div>
								{/* <div className="col-sm-2 text-right">
									<div>
										<button
											type="button"
											className="btn btn-primary"
											onClick={this.updateOpsReport.bind(
												this
											)}
										>
											{this.props.t(
												"OPS_REPORT.UPDATE_REPORT"
											)}
										</button>
									</div>
								</div> */}
								<div className="col-sm-4 text-right">
									<span>
										{csvJsonData && csvJsonData.length ? (
											<li className="searchitems text-right">
												<CSVLink
													data={csvJsonData}
													filename={"ops_report.csv"}
													className="btn btn-link"
													target="_blank"
													onClick={async () =>
														await this.props.actions.SaveDownloadLog(
															{
																module:
																	"OpsReport",
																action: "1",
															}
														)
													}
												>
													<i className="ic-file_download"></i>
													{this.props.t(
														"OPS_REPORT.DOWNLOAD_CSV"
													)}
												</CSVLink>
											</li>
										) : (
											""
										)}
									</span>
								</div>
							</div>
							<div className="card">
								<div className="table-responsive table-colFixed">
									{campaign_data instanceof Array &&
									campaign_data.length != 0 ? (
										<table className="table table-bordered table-hover salesReportTable ">
											<thead>
												<tr>
													{subHeaders instanceof Array
														? subHeaders.map(
																(e, i) => (
																	<th key={i}>
																		{e}
																	</th>
																)
														  )
														: ""}
												</tr>
											</thead>

											<tbody>{this.getTableData()}</tbody>
										</table>
									) : (
										this.props.t("OPS_REPORT.NOT_FOUND")
									)}
								</div>
								{loading && (
									<PageLoader
										loaderClass=" text-center loader content-loader"
										loadertext="true"
									/>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ config, inventory }) => {
	return {
		campaign_list:
			config.gm_campaign_list instanceof Array
				? config.gm_campaign_list.map((e) => {
						return { value: e.id, label: e.name };
				  })
				: [],
		gm_campaign_list: config.gm_campaign_list,
		make_map: inventory.make_map ? inventory.make_map : {},
		model_map: inventory.model_map ? inventory.model_map : {},
		city_map:
			inventory.city instanceof Array
				? inventory.city.reduce(
						(obj, item) => ((obj[item.id] = item.name), obj),
						{}
				  )
				: {},
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
			SaveDownloadLog: bindActionCreators(
				LeadActions.SaveDownloadLog,
				dispatch
			),
		},
	};
};
export default withTranslation("report")(
	connect(mapStateToProps, mapDispatchToProps)(OpsReport)
);
