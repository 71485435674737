/* eslint-disable */
import React, { Component } from "react";
import Select from "react-select";
import InputField from "../../../elements/InputField";
import { withTranslation } from "react-i18next";

class SearchProcess extends Component {
	constructor(props) {
		super(props);
		this.state = {
			country_list: this.props.country_list || [],
			business_type_list: this.props.business_type_list || [],
			filterData: this.props.filterData || {},
		};
	}

	static getDerivedStateFromProps(props, state) {
		let updateState = {};
		updateState["filterData"] = props.filterData;
		if (state.country_list != props.country_list) {
			updateState["country_list"] = props.country_list;
		}
		if (state.business_type_list != props.business_type_list) {
			updateState["business_type_list"] = props.business_type_list;
		}
		return updateState;
	}

	submitFilterForm = (event) => {
		event.preventDefault();
		if (typeof this.props.onSubmitFilter === "function") {
			let { filterData } = this.state;
			this.props.onSubmitFilter(filterData);
		}
	};

	ResetFilterForm = (event) => {
		event.preventDefault();
		let filterData = { page: 1 };
		this.props.onSubmitFilter(filterData, {});
	};

	handleChange = (sname, valueObj) => {
		let { filterData } = this.state;
		let value = valueObj.value || valueObj.business_type_id;
		if (sname === "name") value = valueObj.target.value;
		filterData[sname] = value;
		this.setState({ filterData: filterData });
	};

	render() {
		const { country_list, filterData, business_type_list } = this.state;

		return (
			<div className="search-wrap">
				<form onSubmit={this.submitFilterForm}>
					<ul className="search-flex">
						<li className="searchitems">
							<InputField
								inputProps={{
									id: "name",
									type: "text",
									value: filterData.name || "",
									name: "name",
									autoComplete: "off",
									placeholder: this.props.t("PROCESS.NAME"),
									label: this.props.t("PROCESS.NAME"),
								}}
								autoComplete="off"
								onChange={this.handleChange.bind(this, "name")}
							/>
						</li>

						<li className="searchitems selectWidth form-group">
							<Select
								id="country_id"
								onChange={this.handleChange.bind(
									this,
									"country_id"
								)}
								options={country_list}
								name="country_id"
								placeholder={this.props.t("PROCESS.COUNTRY")}
								value={country_list.filter(
									({ value }) =>
										value === filterData.country_id
								)}
								getOptionLabel={({ label }) => label}
								getOptionValue={({ value }) => value}
							/>
						</li>

						<li className="searchitems selectWidth form-group">
							<Select
								id="business_type_id"
								onChange={this.handleChange.bind(
									this,
									"business_type_id"
								)}
								options={business_type_list}
								name="business_type_id"
								placeholder={this.props.t(
									"PROCESS.BUSINESS_TYPE"
								)}
								value={business_type_list.filter(
									({ business_type_id }) =>
										business_type_id ===
										filterData.business_type_id
								)}
								getOptionLabel={({ name }) => name}
								getOptionValue={({ business_type_id }) =>
									business_type_id
								}
							/>
						</li>

						<li className="searchitems form-group">
							<div>
								<button
									type="submit"
									className="btn btn-primary"
								>
									{this.props.t("PROCESS.SEARCH")}
								</button>
							</div>
						</li>

						<li className="searchitems form-group">
							<button
								type="reset"
								className="btn btn-default btn-reset"
								onClick={this.ResetFilterForm}
							>
								{this.props.t("PROCESS.RESET")}
							</button>
						</li>
					</ul>
				</form>
			</div>
		);
	}
}

export default withTranslation("webfrom")(SearchProcess);
