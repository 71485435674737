/* eslint-disable */
import React, { Component } from "react";
import Select from "react-select";
import InputField from "../../elements/InputField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Radiobox from "../../elements/Radiobox";
import TestDriveCarDetails from "./TestDriveCarDetails";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { LeadActions } from "./../../../store/action";
import { bindActionCreators } from "redux";
import config from "../../../config/config";
import dateformat from "dateformat";
const now = new Date();
const currhour = Number(now.getHours());
const currmin = now.getMinutes();

const rejectedBy = [
	{ value: "CONSUMER", label: "Consumer" },
	{ value: "DEALER", label: "Dealer" },
];
class TestDriveForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tdStatusList: [],
			cityList: [],
			formData: {
				postal_code: "",
				address: "",
				comment: "",
			},
			errors: {},
			subListDetails: {},
			regexp: /^[0-9\b]+$/,
			is_future_date: false,
			home_td_available: "0",
			isChangeAccess: false,
			homeTdRequested: false,
			fieldsDisabled: true,
			originalTdStatus: 0,
		};
	}

	static getDerivedStateFromProps = (nextProps, prevState) => {
		let updatedStateData = {};
		if (
			nextProps.config_data &&
			nextProps.config_data.test_drive_status_list &&
			nextProps.config_data.test_drive_status_list !==
				prevState.tdStatusList
		) {
			updatedStateData["tdStatusList"] =
				nextProps.config_data.test_drive_status_list;
		}
		if (
			nextProps.config_data &&
			nextProps.config_data.city_state_list &&
			nextProps.config_data.city_state_list.city &&
			nextProps.config_data.city_state_list.city !== prevState.cityList
		) {
			updatedStateData["cityList"] =
				nextProps.config_data.city_state_list.city;
		}
		if (nextProps.subLeadDetails !== prevState.subListDetails) {
			updatedStateData["subListDetails"] = nextProps.subLeadDetails;
			let formData = Object.assign({}, nextProps.subLeadDetails);
			updatedStateData["originalTdStatus"] =
				nextProps.subLeadDetails.td_status_id || 0;
			if (formData.td_date) {
				let tDate = formData.td_date;
				formData.td_time =
					(formData &&
						dateformat(tDate, config.constants.TIME_FORMAT)) ||
					"";
				formData.td_date =
					(formData &&
						dateformat(
							formData.td_date,
							config.constants.DATE_FORMAT_YMD
						)) ||
					"";
			}
			if (formData.td_completed_date) {
				let tdcDate = formData.td_completed_date;
				formData.td_completed_time =
					(formData &&
						dateformat(tdcDate, config.constants.TIME_FORMAT)) ||
					"";
				formData.td_completed_date =
					(formData &&
						dateformat(
							formData.td_completed_date,
							config.constants.DATE_FORMAT_YMD
						)) ||
					"";
			}
			formData.comment = "";
			updatedStateData["formData"] = formData;
			if (nextProps.subLeadDetails && nextProps.subLeadDetails.td_date) {
				let is_future_date = false;
				if (
					new Date(nextProps.subLeadDetails.td_date).setHours(
						0,
						0,
						0,
						0
					) > new Date().setHours(0, 0, 0, 0)
				) {
					is_future_date = true;
				}
				updatedStateData["is_future_date"] = is_future_date;
			}
			if (
				nextProps.subLeadDetails &&
				nextProps.subLeadDetails.is_requested_at_home === "1"
			) {
				updatedStateData["homeTdRequested"] = true;
				updatedStateData["home_td_available"] = "1";
			} else if (
				nextProps.subLeadDetails &&
				nextProps.subLeadDetails.is_requested_at_home === null &&
				nextProps.subLeadDetails.home_td_available === "1"
			) {
				updatedStateData["homeTdRequested"] = true;
				updatedStateData["home_td_available"] = "1";
			}
			if (
				(nextProps.subLeadDetails &&
					nextProps.subLeadDetails.home_td_available === "1" &&
					nextProps.subLeadDetails &&
					nextProps.subLeadDetails.is_requested_at_home === null) ||
				(nextProps.subLeadDetails &&
					nextProps.subLeadDetails.home_td_available === "1" &&
					nextProps.subLeadDetails.td_status_id === 2)
			) {
				// updatedStateData["homeTdRequested"] = true;
				updatedStateData["fieldsDisabled"] = false;
				updatedStateData["isChangeAccess"] = true;
			}
			if (
				nextProps.subLeadDetails &&
				nextProps.subLeadDetails.is_requested_at_home === null
			) {
				updatedStateData["fieldsDisabled"] = false;
			}
		}

		return updatedStateData;
	};
	changeTdAvailableStatus = (value) => {
		let { homeTdRequested } = this.state;
		if (value === "1") {
			homeTdRequested = true;
		} else {
			homeTdRequested = false;
		}
		this.setState({ homeTdRequested: homeTdRequested });
	};
	handleChange = async (key, event) => {
		let { formData, errors, is_future_date, originalTdStatus } = this.state;
		if (event) {
			if (key === "td_status_id") {
				if (+originalTdStatus === 4 || +originalTdStatus === 5)
					formData = {};
				formData.td_status_id = event.id;
				errors.td_status_id = "";
				// formData["td_date"] = ""
				// formData["td_time"] = ""
				// if(event.id !== 2){
				// 	errors.td_time = "";
				// 	errors.td_date = "";
				// }
			}
			if (key === "city_id") {
				formData.city_id = event.id;
				errors.city_id = "";
			}
			if (key === "rejected_by") {
				formData.rejected_by = event.target.value;
				errors.rejected_by = "";
			}
		} else {
			delete formData.td_status_id;
			delete formData.city_id;
			delete errors.td_status_id;
		}
		if (key === "td_date") {
			formData.td_time = "";
			formData.td_date = event;
			errors.td_date = "";
			if (
				new Date(event).setHours(0, 0, 0, 0) >
				new Date().setHours(0, 0, 0, 0)
			) {
				is_future_date = true;
			} else is_future_date = false;
		}
		if (key === "td_time") {
			formData.td_time = dateformat(event, config.constants.TIME_FORMAT);
			errors.td_time = "";
		}
		if (key === "postal_code") {
			// console.log("event.target.value=>", event.target.value);
			if (this.state.regexp.test(event.target.value)) {
				formData.postal_code = event.target.value;
				errors.postal_code = "";
			} else if (event.target.value == "")
				formData.postal_code = event.target.value;
		}
		if (key === "address") {
			formData.address = event.target.value;
			errors.address = "";
		}
		if (key === "comment") formData.comment = event.target.value;
		if (key === "home_td_available") {
			// formData.home_td_available = event.target.value;
			this.changeTdAvailableStatus(event.target.value);
			// if (Number(formData.home_td_available) === 0)
			// 	errors.postal_code = "";
		}
		if (key === "td_completed_date") {
			formData.td_completed_time = "";
			formData.td_completed_date = event;
			errors.td_completed_date = "";
		}
		if (key === "td_completed_time") {
			formData.td_completed_time = dateformat(
				event,
				config.constants.TIME_FORMAT
			);
			errors.td_completed_time = "";
		}
		//console.log('event.target.value=>',event.target.value,'formdata=>',formData.postal_code)
		await this.setState({
			formData: formData,
			errors: errors,
			is_future_date: is_future_date,
		});
	};

	validForm = () => {
		let { formData, errors, homeTdRequested } = this.state;
		let formIsValid = true;
		if (!formData["td_status_id"]) {
			errors["td_status_id"] = this.props.t(
				"TEST_DRIVE.VALIDATION_ERROR.STATUS_REQUIRED"
			);
			formIsValid = false;
		}
		if (!formData["td_date"]) {
			if (formData["td_status_id"] === 2 || !formData["td_status_id"]) {
				errors["td_date"] = this.props.t(
					"TEST_DRIVE.VALIDATION_ERROR.DATE_REQUIRED"
				);
				formIsValid = false;
			}
		}
		if (!formData["td_time"]) {
			if (formData["td_status_id"] === 2 || !formData["td_status_id"]) {
				errors["td_time"] = this.props.t(
					"TEST_DRIVE.VALIDATION_ERROR.TIME_REQUIRED"
				);
				formIsValid = false;
			}
		}
		if (!formData["postal_code"] && +homeTdRequested === 1) {
			if (formData["td_status_id"] === 2 || !formData["td_status_id"]) {
				errors["postal_code"] = this.props.t(
					"TEST_DRIVE.VALIDATION_ERROR.PINCODE_REQUIRED"
				);
				formIsValid = false;
			}
		}
		if (!formData["city_id"] && +homeTdRequested === 1) {
			if (formData["td_status_id"] === 2 || !formData["td_status_id"]) {
				errors["city_id"] = this.props.t(
					"TEST_DRIVE.VALIDATION_ERROR.CITY_REQUIRED"
				);
				formIsValid = false;
			}
		}
		if (!formData["address"] && +homeTdRequested === 1) {
			if (formData["td_status_id"] === 2 || !formData["td_status_id"]) {
				errors["address"] = this.props.t(
					"TEST_DRIVE.VALIDATION_ERROR.ADDRESS_REQUIRED"
				);
				formIsValid = false;
			}
		}

		if (!formData["rejected_by"]) {
			if (formData.td_status_id && +formData["td_status_id"] === 5) {
				errors["rejected_by"] = this.props.t(
					"TEST_DRIVE.VALIDATION_ERROR.REJECTED_BY_REQUIRED"
				);
				formIsValid = false;
			}
		}

		// if(formData && formData.td_status_id && formData.td_date && formData.td_time && +formData["td_status_id"] === 3){
		// 	let curDt = dateformat(config.constants.DATE_FORMAT)
		// 	let formDt = dateformat(formData.td_date+' '+formData.td_time,config.constants.DATE_FORMAT)
		// 	if(curDt >= formDt){
		// 		toast.error(this.props.t('TEST_DRIVE.VALIDATION_ERROR.PAST_DATE_ERROR'))
		// 		formIsValid = false;

		// 	}
		// }
		this.setState({ errors: errors });
		return formIsValid;
	};

	saveTestdriveInfo = async () => {
		let { formData, subListDetails } = Object.assign({}, this.state);
		formData.td_type = 1;
		formData.sub_lead_id = subListDetails.id;
		if (!formData.home_td_available)
			formData.home_td_available =
				subListDetails.home_test_drive_available;
		//let formObj = Object.assign({}, formData);
		// if (formObj.postal_code === "") delete formObj.postal_code;
		let visitFormInfo = await this.makeFormDataToSave();
		if (this.validForm()) {
			let visit_date2send = visitFormInfo.td_date
				? new Date(
						dateformat(
							dateformat(
								visitFormInfo.td_date,
								config.constants.DATE_FORMAT_YMD
							) +
								" " +
								visitFormInfo.td_time,
							config.constants.DATE_FORMAT
						)
				  ).toISOString()
				: "";
			if (visit_date2send) visitFormInfo.td_date = visit_date2send;

			let com_date2send = visitFormInfo.td_completed_date
				? new Date(
						dateformat(
							dateformat(
								visitFormInfo.td_completed_date,
								config.constants.DATE_FORMAT_YMD
							) +
								" " +
								visitFormInfo.td_completed_time,
							config.constants.DATE_FORMAT
						)
				  ).toISOString()
				: "";
			if (com_date2send) visitFormInfo.td_completed_date = com_date2send;

			let result = await this.props.actions.saveTestdrive(visitFormInfo);
			if (result && result.status) {
				toast.success(result.response);
				this.closeModal();
				this.getAssignCarList();
				this.props.actions.updateHistoryFlag(true);
			}
		}
	};

	getAssignCarList = () => {
		const post_data = {
			lead_id: this.state.subListDetails.lead_id,
			_with: ["assigned_cars"],
		};
		this.props.actions.getAssignCar(post_data);
	};

	makeFormDataToSave = async () => {
		const { homeTdRequested } = this.state;
		let formObj = {};
		let { formData } = this.state;
		if (formData.td_status_id) formObj.td_status_id = formData.td_status_id;

		formObj.home_td_available = homeTdRequested ? "1" : "0";
		if (formData.td_date)
			formObj.td_date = dateformat(
				formData.td_date,
				config.constants.DATE_FORMAT_YMD
			);
		if (formData.td_time) formObj.td_time = formData.td_time;
		if (formData.sub_lead_id) formObj.sub_lead_id = formData.sub_lead_id;
		if (formData.postal_code && homeTdRequested)
			formObj.postal_code = formData.postal_code;
		if (formData.city_id && homeTdRequested)
			formObj.city_id = formData.city_id;
		if (formData.address && homeTdRequested)
			formObj.address = formData.address;
		formObj.comment = formData.comment || null;
		formObj.rejected_by =
			formData && formData.td_status_id === 5
				? formData.rejected_by
				: null;
		if (formData.td_completed_date) {
			formObj.td_completed_date = dateformat(
				formData.td_completed_date,
				config.constants.DATE_FORMAT_YMD
			);
		}
		if (formData.td_completed_time)
			formObj.td_completed_time = formData.td_completed_time;
		return formObj;
	};

	closeModal = () => {
		if (typeof this.props.closeModal === "function")
			this.props.closeModal();
	};

	render() {
		let {
			tdStatusList,
			cityList,
			formData,
			errors,
			is_future_date,
			subListDetails,
			homeTdRequested,
			isChangeAccess,
			fieldsDisabled,
			home_td_available,
			originalTdStatus,
		} = this.state;
		let modifiedTdStatusList = [];
		if (subListDetails) {
			modifiedTdStatusList = tdStatusList.map((v) => {
				if (+originalTdStatus === 0) {
					if (+v.id !== 2) v.isDisabled = true;
					else v.isDisabled = false;
				} else if (+originalTdStatus === 2) {
					if (+v.id === 4) v.isDisabled = true;
					else v.isDisabled = false;
					if (+v.id === 3) {
						if (formData && formData.td_date && formData.td_time) {
							let curDt = dateformat(
								config.constants.DATE_FORMAT
							);
							let formDt = dateformat(
								dateformat(
									formData.td_date,
									config.constants.DATE_FORMAT_YMD
								) +
									" " +
									formData.td_time,
								config.constants.DATE_FORMAT
							);
							if (curDt >= formDt) v.isDisabled = true;
						}
					}
				} else if (+originalTdStatus === 3) {
					if (+v.id === 2) v.isDisabled = true;
					else v.isDisabled = false;
				} else if (+originalTdStatus === 4 || +originalTdStatus === 5) {
					if (+v.id === 2) v.isDisabled = false;
					else v.isDisabled = true;
				} else {
					//v.isDisabled = false;
				}
				return v;
			});
		}
		if (formData && !formData.home_td_available) {
			formData.home_td_available = "0";
			subListDetails.home_td_available = subListDetails.home_td_available
				? subListDetails.home_td_available
				: "0";
		}
		if ((formData && formData.td_status_id === 2) || !formData.td_status_id)
			fieldsDisabled = false;
		else fieldsDisabled = true;

		return (
			<div>
				<div className="row">
					<TestDriveCarDetails subListDetails={subListDetails} />
					<div className="col-sm-8">
						<div>
							<div className="row">
								<div className="form-group col-sm-6">
									<label>
										{this.props.t(
											"TEST_DRIVE.TD_FORM.STATUS"
										)}
										<span className="text-danger">*</span>
									</label>
									<Select
										id="status"
										onChange={this.handleChange.bind(
											this,
											"td_status_id"
										)}
										options={modifiedTdStatusList}
										name="status"
										placeholder={this.props.t(
											"TEST_DRIVE.TD_FORM.STATUS"
										)}
										value={
											formData &&
											modifiedTdStatusList.filter(
												({ id }) =>
													id === formData.td_status_id
											)
										}
										getOptionLabel={({ name }) => name}
										getOptionValue={({ id }) => id}
										isClearable
									/>
									<span className="text-danger">
										{errors.td_status_id || ""}
									</span>
								</div>
								<div className=" col-sm-6">
									<div className="rc-inline">
										<div>
											<label>
												{this.props.t(
													"TEST_DRIVE.TD_FORM.HOME_VISIT_STATUS"
												)}
											</label>
										</div>
										<div className="customYesNo">
											<Radiobox
												type="radio"
												name="homevisite"
												id="homevisite-yes"
												label="Yes"
												value="1"
												disabled={
													fieldsDisabled === false
														? Number(
																home_td_available
														  ) === 1
															? false
															: true
														: true
												}
												checked={homeTdRequested}
												onChange={this.handleChange.bind(
													this,
													"home_td_available"
												)}
											/>
											<Radiobox
												type="radio"
												name="homevisite"
												id="homevisite-no"
												label="No"
												value="0"
												disabled={
													fieldsDisabled === false
														? Number(
																home_td_available
														  ) === 1
															? false
															: true
														: true
												}
												checked={
													homeTdRequested === false
												}
												onChange={this.handleChange.bind(
													this,
													"home_td_available"
												)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6 form-group">
									<label>
										{this.props.t(
											"TEST_DRIVE.TD_FORM.SET_DATE"
										)}
										{!fieldsDisabled ? (
											<span className="text-danger">
												*
											</span>
										) : (
											""
										)}
									</label>
									<DatePicker
										className="form-control"
										autoComplete="off"
										disabled={fieldsDisabled === true}
										placeholderText={this.props.t(
											"TEST_DRIVE.TD_FORM.SET_DATE"
										)}
										onChange={this.handleChange.bind(
											this,
											"td_date"
										)}
										selected={
											formData && formData.td_date
												? new Date(formData.td_date)
												: ""
										}
										dateFormat={
											config.constants.DATE_FORMAT_INPUT
										}
										minDate={new Date()}
										onKeyDown={(e) => e.preventDefault()}
									/>
									<span className="text-danger">
										{errors.td_date || ""}
									</span>
								</div>
								<div className="col-sm-6 form-group">
									<label>
										{this.props.t(
											"TEST_DRIVE.TD_FORM.SET_TIME"
										)}
										{!fieldsDisabled ? (
											<span className="text-danger">
												*
											</span>
										) : (
											""
										)}
									</label>
									<DatePicker
										className="form-control"
										autoComplete="off"
										disabled={fieldsDisabled === true}
										onChange={this.handleChange.bind(
											this,
											"td_time"
										)}
										value={
											(formData &&
												formData.td_time &&
												formData.td_time.split(":")[0] +
													":" +
													formData.td_time.split(
														":"
													)[1]) ||
											""
										}
										placeholderText={this.props.t(
											"TEST_DRIVE.TD_FORM.SET_TIME"
										)}
										showTimeSelect
										showTimeSelectOnly
										timeIntervals={15}
										timeCaption="Time"
										dateFormat={
											config.constants.TIME_FORMAT_INPUT
										}
										timeFormat={
											config.constants.TIME_FORMAT_INPUT
										}
										minTime={
											is_future_date
												? now.setHours(0, 0)
												: now.setHours(
														currhour,
														currmin
												  )
										}
										maxTime={now.setHours(23, 59)}
									/>
									<span className="text-danger">
										{errors.td_time || ""}
									</span>
								</div>

								{formData.td_status_id === 4 ? (
									<>
										<div className="col-sm-6 form-group">
											<label>
												{this.props.t(
													"TEST_DRIVE.TD_FORM.COMPLETED_DATE"
												)}
												{!fieldsDisabled ? (
													<span className="text-danger">
														*
													</span>
												) : (
													""
												)}
											</label>
											<DatePicker
												className="form-control"
												autoComplete="off"
												//disabled={fieldsDisabled === true}
												placeholderText={this.props.t(
													"TEST_DRIVE.TD_FORM.COMPLETED_DATE"
												)}
												onChange={this.handleChange.bind(
													this,
													"td_completed_date"
												)}
												selected={
													formData &&
													formData.td_completed_date
														? new Date(
																formData.td_completed_date
														  )
														: ""
												}
												dateFormat={
													config.constants
														.DATE_FORMAT_INPUT
												}
												minDate={
													formData.td_date &&
													Date.parse(formData.td_date)
														? new Date(
																formData.td_date
														  )
														: new Date()
												}
												maxDate={new Date()}
												onKeyDown={(e) =>
													e.preventDefault()
												}
											/>
											<span className="text-danger">
												{errors.td_completed_date || ""}
											</span>
										</div>

										<div className="col-sm-6 form-group">
											<label>
												{this.props.t(
													"TEST_DRIVE.TD_FORM.COMPLETED_TIME"
												)}
												{!fieldsDisabled ? (
													<span className="text-danger">
														*
													</span>
												) : (
													""
												)}
											</label>
											<DatePicker
												className="form-control"
												autoComplete="off"
												//disabled={fieldsDisabled === true}
												onChange={this.handleChange.bind(
													this,
													"td_completed_time"
												)}
												value={
													(formData &&
														formData.td_completed_time &&
														formData.td_completed_time.split(
															":"
														)[0] +
															":" +
															formData.td_completed_time.split(
																":"
															)[1]) ||
													""
												}
												placeholderText={this.props.t(
													"TEST_DRIVE.TD_FORM.COMPLETED_TIME"
												)}
												showTimeSelect
												showTimeSelectOnly
												timeIntervals={15}
												timeCaption="Time"
												dateFormat={
													config.constants
														.TIME_FORMAT_INPUT
												}
												timeFormat={
													config.constants
														.TIME_FORMAT_INPUT
												}
												minTime={now.setHours(
													currhour,
													currmin
												)}
												maxTime={now.setHours(23, 59)}
											/>
											<span className="text-danger">
												{errors.td_completed_time || ""}
											</span>
										</div>
									</>
								) : null}

								{homeTdRequested ? (
									<div className="col-sm-6 ">
										<InputField
											inputProps={{
												id: "pincode",
												type: "text",
												name: "pincode",
												autoComplete: "off",
												disabled:
													fieldsDisabled === true,
												placeholder: this.props.t(
													"TEST_DRIVE.TD_FORM.PINCODE"
												),
												label: this.props.t(
													"TEST_DRIVE.TD_FORM.PINCODE"
												),
												value:
													formData &&
													formData.postal_code
														? formData.postal_code
														: "",
												dataerror:
													errors.postal_code || "",
												validationreq: fieldsDisabled
													? "no"
													: "yes",
											}}
											autoComplete="off"
											onChange={this.handleChange.bind(
												this,
												"postal_code"
											)}
										/>
									</div>
								) : null}
								{homeTdRequested ? (
									<div className="form-group col-sm-6">
										<label>
											{this.props.t(
												"TEST_DRIVE.TD_FORM.CITY"
											)}
											{!fieldsDisabled ? (
												<span className="text-danger">
													*
												</span>
											) : (
												""
											)}
										</label>
										<Select
											id="city"
											onChange={this.handleChange.bind(
												this,
												"city_id"
											)}
											isDisabled={fieldsDisabled === true}
											options={cityList}
											name="city"
											placeholder={this.props.t(
												"TEST_DRIVE.TD_FORM.CITY"
											)}
											value={
												formData &&
												cityList.filter(
													({ id }) =>
														id === formData.city_id
												)
											}
											getOptionLabel={({ name }) => name}
											getOptionValue={({ id }) => id}
										/>
										<span className="text-danger">
											{errors.city_id || ""}
										</span>
									</div>
								) : null}
								{homeTdRequested ? (
									<div className="col-sm-12">
										<InputField
											inputProps={{
												id: "address",
												type: "text",
												name: "address",
												autoComplete: "off",
												disabled:
													fieldsDisabled === true,
												placeholder: this.props.t(
													"TEST_DRIVE.TD_FORM.ADDRESS"
												),
												label: this.props.t(
													"TEST_DRIVE.TD_FORM.ADDRESS"
												),
												value:
													formData && formData.address
														? formData.address
														: "",
												dataerror: errors.address || "",
												validationreq: fieldsDisabled
													? "no"
													: "yes",
											}}
											autoComplete="off"
											onChange={this.handleChange.bind(
												this,
												"address"
											)}
										/>
									</div>
								) : null}
								{formData && +formData.td_status_id === 5 ? (
									<div className="col-sm-12">
										<label>
											{this.props.t(
												"TEST_DRIVE.TD_FORM.REJECTED_BY"
											)}
											<span className="text-danger">
												*
											</span>
										</label>
										<div className="rc-inline formLable">
											{rejectedBy && rejectedBy.length
												? rejectedBy.map(
														(val, index) => (
															<Radiobox
																label={
																	val.label
																}
																value={
																	val.value
																}
																name="rejected_by"
																id={
																	"rejected_by" +
																	index
																}
																key={
																	"modalkey" +
																	index
																}
																type="radio"
																onChange={this.handleChange.bind(
																	this,
																	"rejected_by"
																)}
																checked={
																	formData
																		? formData.rejected_by ===
																		  val.value
																			? true
																			: false
																		: false
																}
															/>
														)
												  )
												: ""}
										</div>
										<span className="text-danger">
											{errors.rejected_by || ""}
										</span>
									</div>
								) : (
									""
								)}
								<div className="col-sm-12 form-group">
									<label>
										{this.props.t(
											"TEST_DRIVE.TD_FORM.COMMENTS"
										)}
									</label>
									<textarea
										name="comments"
										placeholder={this.props.t(
											"TEST_DRIVE.TD_FORM.COMMENTS"
										)}
										className="form-control"
										value={
											(formData && formData.comment) || ""
										}
										onChange={this.handleChange.bind(
											this,
											"comment"
										)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="actionFooter">
					<button
						type="button"
						className="btn btn-primary-outline mrg-r15"
						onClick={this.closeModal}
					>
						{this.props.t("TEST_DRIVE.TD_FORM.CANCEL")}
					</button>
					<button
						type="button"
						className="btn btn-primary"
						onClick={this.saveTestdriveInfo}
						disabled={subListDetails.status === "2" ? true : false}
					>
						{this.props.t("TEST_DRIVE.TD_FORM.UPDATE")}
					</button>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ config, lead }) => {
	return {
		config_data: config,
		//subListDetails: lead.subListDetails,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			saveTestdrive: bindActionCreators(
				LeadActions.saveTestdrive,
				dispatch
			),
			getAssignCar: bindActionCreators(
				LeadActions.getLeadDetail,
				dispatch
			),
			updateHistoryFlag: bindActionCreators(
				LeadActions.updateHistoryFlag,
				dispatch
			),
		},
	};
};
export default withTranslation("lead_detail")(
	connect(mapStateToProps, mapDispatchToProps)(TestDriveForm)
);
