export const AuthConstants = {
	REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
	REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
	REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

	LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
	LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
	LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
	LOGIN_CAPTCHA: "USERS_LOGIN_CAPTCHA",

	FORGOT_PASSWORD: "USERS_FORGOT_PASSWORD",
	FORGOT_PASSWORD_ERROR: "USERS_FORGOT_PASSWORD_ERROR",

	LOGOUT: "USERS_LOGOUT",

	GETALL_REQUEST: "USERS_GETALL_REQUEST",
	GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
	GETALL_FAILURE: "USERS_GETALL_FAILURE",

	DELETE_REQUEST: "USERS_DELETE_REQUEST",
	DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
	DELETE_FAILURE: "USERS_DELETE_FAILURE",

	REFRESH_TOKEN: "AUTH_REFRESH_TOKEN",
	REFRESH_TOKEN_ERROR: "AUTH_REFRESH_TOKEN_ERROR",

	RESEND_OTP: "RESEND_OTP",
	RESEND_OTP_ERROR: "RESEND_OTP_ERROR",

	RESET_PASSWORD_TOKEN: "RESET_PASSWORD_TOKEN",
	RESET_PASSWORD_TOKEN_ERROR: "RESET_PASSWORD_TOKEN_ERROR",

	RESET_PASSWORD: "RESET_PASSWORD",
	RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",

	VALIDATE_RESET_PASSWORD_TOKEN: "VALIDATE_RESET_PASSWORD_TOKEN",
	VALIDATE_RESET_PASSWORD_TOKEN_ERROR: "VALIDATE_RESET_PASSWORD_TOKEN_ERROR",

	ACCESS_FETCHED: "ACCESS_FETCHED",
	ACCESS_FETCHED_ERR: "ACCESS_FETCHED_ERR",

	SITE_SETTING_FETCHED: "SITE_SETTING_FETCHED",
	SITE_SETTING_FETCHED_ERR: "SITE_SETTING_FETCHED_ERR",
};
