import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { default as config } from "../../../config/config";
const currenDate = new Date();
var curryear = currenDate.getFullYear(),
	currmonth = currenDate.getMonth();
class SearchCrossSell extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterData: {
				start_date: new Date(curryear, currmonth, 1),
				end_date: new Date(),
			},
			campaign_list: [],
			csvJsonData: [],
		};
	}

	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		if (props.csvJsonData && props.csvJsonData !== state.csvJsonData) {
			updateProps["csvJsonData"] = props.csvJsonData;
		}
		return updateProps;
	}
	handleChange = async (sname, ovalue) => {
		const { filterData } = this.state;
		if (ovalue && ovalue.value) {
			filterData[sname] = ovalue.value;
		} else if (sname instanceof Date) {
			filterData[ovalue] = new Date(sname);
		} else {
			delete filterData[sname];
		}
		this.setState({
			filterData: filterData,
		});
	};
	componentDidMount = () => {};
	resetFilterForm = () => {
		this.setState({
			filterData: {},
		});
	};

	submitFilterForm = (event) => {
		if (event) event.preventDefault();
		const { filterData } = this.state;
		if (typeof this.props.onSubmitFilter === "function") {
			this.props.onSubmitFilter(filterData);
		}
	};
	render() {
		const { filterData } = this.state;
		return (
			<div className="search-wrap">
				<form onSubmit={this.submitFilterForm}>
					<ul className="search-flex">
						<li className="searchitems selectWidth form-group">
							<DatePicker
								showYearDropdown
								showMonthDropdown
								selected={filterData.start_date || ""}
								onChange={(date) =>
									this.handleChange(date, "start_date")
								}
								name="start_date"
								id="start_date"
								autoComplete="off"
								placeholderText={this.props.t(
									"CROSS_SELL.FROM"
								)}
								dateFormat={config.constants.DATE_FORMAT_INPUT}
								className="form-control"
								maxDate={new Date()}
								onKeyDown={(e) => e.preventDefault()}
							/>
						</li>

						<li className="searchitems selectWidth form-group">
							<DatePicker
								showYearDropdown
								showMonthDropdown
								selected={filterData.end_date || ""}
								onChange={(date) =>
									this.handleChange(date, "end_date")
								}
								name="end_date"
								id="end_date"
								autoComplete="off"
								placeholderText={this.props.t("CROSS_SELL.TO")}
								dateFormat={config.constants.DATE_FORMAT_INPUT}
								className="form-control"
								minDate={filterData.start_date || ""}
								maxDate={new Date()}
								onKeyDown={(e) => e.preventDefault()}
							/>
						</li>

						<li className="searchitems form-group">
							<div>
								<button
									type="submit"
									className="btn btn-primary"
								>
									{this.props.t("CROSS_SELL.DOWNLOAD_CSV")}
								</button>
							</div>
						</li>

						{/* <li className="searchitems form-group">
							<button
								type="reset"
								className="btn btn-default btn-reset"
								onClick={this.resetFilterForm}
							>
								{this.props.t("CROSS_SELL.RESET")}
							</button>
						</li> */}
					</ul>
				</form>
			</div>
		);
	}
}
export default withTranslation("report")(SearchCrossSell);
