import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import dateformat from "dateformat";
import config from "../../../../config/config";
class ChangeDetail extends Component {
	getActivityRelatedData(activityData) {
		let activity = activityData.activity_type;
		let historyId = activityData.history_id;

		let activityQueue = activityData.activity ?? [];
		switch (activity) {
			case "flag_enabled":
				return (
					<>
						{activityQueue.map((e, i) => (
							<div key={i + `flag`}>
								{e && Object.keys(e).length && "eaf" in e ? (
									<div key={i + `eaf_flag`}>
										<div key={i}>
											<strong key={i}>
												{this.props.t(
													`HISTORY.TBL_DETAILS.EAF`
												)}
											</strong>{" "}
											: {e.eaf}
										</div>
									</div>
								) : null}

								{e && Object.keys(e).length && "rf" in e ? (
									<div key={i + `rf_flag`}>
										<div key={i}>
											<strong key={i}>
												{this.props.t(
													`HISTORY.TBL_DETAILS.RF`
												)}
											</strong>{" "}
											: {e.rf}
										</div>
									</div>
								) : null}

								{e && Object.keys(e).length && "ncf" in e ? (
									<div key={i + `ncf_flag`}>
										<div key={i}>
											<strong key={i}>
												{this.props.t(
													`HISTORY.TBL_DETAILS.NCF`
												)}
											</strong>{" "}
											: {e.ncf}
										</div>
									</div>
								) : null}
							</div>
						))}
					</>
				);
			case "status":
				return (
					<>
						{activityQueue.map((e, i) => {
							let response = [];
							let inner = 0,
								label2show = "";
							for (let item in e.change_details) {
								let { oldvalue, newvalue } = this.getid2Text(
									item,
									e.change_details[item]
								);
								label2show = item.replace("_id", "");
								response.push(
									<div
										key={
											inner +
											i +
											historyId +
											"_Change_status"
										}
									>
										<strong>
											{label2show
												.slice(0, 1)
												.toUpperCase() +
												label2show.slice(1)}
										</strong>{" "}
										:{" "}
										{e.change_details[item].old
											? `${oldvalue} ${this.props.t(
													"HISTORY.TBL_DETAILS.TO"
											  )}  `
											: null}{" "}
										{newvalue || "None"}
									</div>
								);
								inner++;
							}

							if (e && Object.keys(e).length && "eaf" in e) {
								response.push(
									<div key={i + `eaf_flag_status`}>
										<div key={i}>
											<strong key={i}>
												{this.props.t(
													`HISTORY.TBL_DETAILS.EAF`
												)}
											</strong>{" "}
											: {e.eaf}
										</div>
									</div>
								);
							}

							if (e && Object.keys(e).length && "rf" in e) {
								response.push(
									<div key={i + `rf_flag_status`}>
										<div key={i}>
											<strong key={i}>
												{this.props.t(
													`HISTORY.TBL_DETAILS.RF`
												)}
											</strong>{" "}
											: {e.rf}
										</div>
									</div>
								);
							}

							if (e && Object.keys(e).length && "ncf" in e) {
								response.push(
									<div key={i + `ncf_flag_status`}>
										<div key={i}>
											<strong key={i}>
												{this.props.t(
													`HISTORY.TBL_DETAILS.NCF`
												)}
											</strong>{" "}
											: {e.ncf}
										</div>
									</div>
								);
							}

							if (
								e &&
								Object.keys(e).length &&
								"file_name" in e
							) {
								response.push(
									<div key={i + `file_name_status`}>
										<div key={i}>
											<strong key={i}>
												{this.props.t(
													`HISTORY.TBL_DETAILS.FILE_ADDED`
												)}
											</strong>{" "}
											:{" "}
											<a
												href={e.doc_aws_path || e.base_url+e.file_name}
												target="_blank"
											>
												{e.file_name || ""}
											</a>
										</div>
									</div>
								);
							}

							return response;
						})}
					</>
				);

			case "test_drive":
				return (
					<>
						{activityQueue.map((e, i) => (
							<div key={`${i}_${historyId}_test_drive`}>
								{e.td_status && (
									<div>
										<strong>
											{this.props.t(
												`HISTORY.TBL_DETAILS.STATUS`
											)}
										</strong>{" "}
										:{" "}
										{e.td_last_status
											? ` ${
													e.td_last_status
											  } ${this.props.t(
													"HISTORY.TBL_DETAILS.TO"
											  )}`
											: ""}
										{e.td_status}
									</div>
								)}
								{e.td_date_time && (
									<div>
										<strong>
											{this.props.t(
												`HISTORY.TBL_DETAILS.TD_REQ_TIME`
											)}
										</strong>{" "}
										:{" "}
										{dateformat(
											e.td_date_time,
											config.constants.DATE_FORMAT
										)}
									</div>
								)}
								{e.address && (
									<div>
										<strong>
											{this.props.t(
												`HISTORY.TBL_DETAILS.ADDRESS`
											)}
										</strong>{" "}
										: {e.address}
									</div>
								)}
								{e.td_completed_date && (
									<div>
										<strong>
											{this.props.t(
												`HISTORY.TBL_DETAILS.COMPLETED_DATE`
											)}
										</strong>{" "}
										:{" "}
										{dateformat(
											e.td_completed_date,
											config.constants.DATE_FORMAT_YMD
										)}
									</div>
								)}
								{e.td_completed_date && (
									<div>
										<strong>
											{this.props.t(
												`HISTORY.TBL_DETAILS.COMPLETED_TIME`
											)}
										</strong>{" "}
										:{" "}
										{dateformat(
											e.td_completed_date,
											config.constants.TIME_FORMAT
										)}
									</div>
								)}
								{e.rejected_by && (
									<div>
										<strong>
											{this.props.t(
												`HISTORY.TBL_DETAILS.REJECTED_BY`
											)}
										</strong>{" "}
										: {e.rejected_by}
									</div>
								)}
								{e.home_visit_status && (
									<div>
										<strong>
											{this.props.t(
												`HISTORY.TBL_DETAILS.HOME_VISIT_STATUS`
											)}
										</strong>{" "}
										: {e.home_visit_status}
									</div>
								)}
							</div>
						))}
					</>
				);
			case "dealer_visit":
				return (
					<>
						{activityQueue.map((e, i) => (
							<div key={`${i}_${historyId}_test_drive`}>
								{e.visit_status && (
									<div>
										<strong>
											{this.props.t(
												`HISTORY.TBL_DETAILS.STATUS`
											)}
										</strong>{" "}
										:{" "}
										{e.visit_last_status
											? ` ${
													e.visit_last_status
											  } ${this.props.t(
													"HISTORY.TBL_DETAILS.TO"
											  )}`
											: ""}
										{e.visit_status}
									</div>
								)}
								{e.visit_date && (
									<div>
										<strong>
											{this.props.t(
												`HISTORY.TBL_DETAILS.DEALER_VISIT_DATE_TIME`
											)}
										</strong>{" "}
										:{" "}
										{dateformat(
											e.visit_date,
											config.constants.DATE_FORMAT
										)}
									</div>
								)}
							</div>
						))}
					</>
				);
			case "customer":
				return (
					<>
						{activityQueue.map((e, i) => {
							let response = [];
							let inner = 0,
								label2show = "";
							for (let item in e.change_details) {
								let { oldvalue, newvalue } = this.getid2Text(
									item,
									e.change_details[item]
								);
								label2show =
									item === "customer_city_id"
										? "customer_city"
										: item;
								response.push(
									<div
										key={inner + i + historyId + "_Change"}
									>
										<strong>
											{label2show
												.slice(0, 1)
												.toUpperCase() +
												label2show.slice(1)}
										</strong>{" "}
										:{" "}
										{e.change_details[item].old
											? `${oldvalue} ${this.props.t(
													"HISTORY.TBL_DETAILS.TO"
											  )}  `
											: null}{" "}
										{newvalue || "None"}
									</div>
								);
								inner++;
							}

							if (e.action_type) {
								response.push(
									<div>
										<strong>
											{this.props.t(
												`HISTORY.TBL_DETAILS.MY_ACTIVE`
											)}
										</strong>
										{" : "}
										{e.agent_id ? "Enable" : "Disable"}
									</div>
								);
							}

							return response;
						})}
					</>
				);
			case "preferences":
				return (
					<>
						{activityQueue.map((e, i) => (
							<div key={`${i}_${historyId}_preferences`}>
								{i === 0 && (
									<div>
										<strong>
											{this.props.t(
												`HISTORY.TBL_DETAILS.ACTIVITY`
											)}
										</strong>{" "}
										: Cross Sell{" "}
									</div>
								)}
								{(e.city_id &&
									this.props.cityMap &&
									this.props.cityMap.size && (
										<div>
											<strong>
												{this.props.t(
													`HISTORY.TBL_DETAILS.CITY`
												)}
											</strong>{" "}
											:{" "}
											{`${
												this.props.cityMap.get(
													e.city_id
												) ?? ""
											}`}
										</div>
									)) ||
									null}
								{(e.make_id &&
									e.model_id &&
									this.props.makeMap &&
									this.props.modelMap &&
									Object.keys(this.props.makeMap).length &&
									Object.keys(this.props.modelMap).length && (
										<div>
											<strong>
												{this.props.t(
													`HISTORY.TBL_DETAILS.MAKE_MODEL`
												)}
											</strong>{" "}
											:{" "}
											{`${
												this.props.makeMap[e.make_id]
											} - ${
												this.props.modelMap[e.model_id]
											}`}
										</div>
									)) ||
									null}
								{e.dse_id ? (
									<div>
										<strong>
											{this.props.t(
												`HISTORY.TBL_DETAILS.DEALER_DSE`
											)}
										</strong>{" "}
										: {`${e.dealer_name} - ${e.dse_name}`}
									</div>
								) : null}
								{activityQueue.length > 1 &&
								i < activityQueue.length - 1 ? (
									<hr />
								) : null}
							</div>
						))}
					</>
				);
			case "reassign_dse":
				return (
					<>
						{activityQueue.map((e, i) => (
							<div key={`${i}_${historyId}_reasign`}>
								<div>
									<strong>
										{this.props.t(
											`HISTORY.TBL_DETAILS.STATUS`
										)}
									</strong>{" "}
									: DSE Reassignment
								</div>
								<div>
									<strong>
										{this.props.t(
											`HISTORY.TBL_DETAILS.DEALER`
										)}
									</strong>{" "}
									:{" "}
									{e.old_dealer
										? `${e.old_dealer} ${this.props.t(
												"HISTORY.TBL_DETAILS.TO"
										  )}  `
										: null}{" "}
									{e.new_dealer}
								</div>
								<div>
									<strong>
										{this.props.t(
											`HISTORY.TBL_DETAILS.DSE`
										)}
									</strong>{" "}
									:{" "}
									{e.old_dse
										? `${e.old_dse} ${this.props.t(
												"HISTORY.TBL_DETAILS.TO"
										  )}  `
										: null}{" "}
									{e.new_dse}
								</div>
							</div>
						))}
					</>
				);
			case "dialer_push":
				return (
					<>
						{activityQueue.map((e, i) => (
							<div key={i + historyId + "dialer_push"}>
								{e.dialer_activity ? (
									<div>
										<strong>
											{this.props.t(
												`HISTORY.TBL_DETAILS.DIALER_ACTIVITY`
											)}
										</strong>{" "}
										{this.props.t(
											`HISTORY.TBL_DETAILS.PUSHED_TO`
										)}{" "}
										{e.dialer_activity}
									</div>
								) : (
									""
								)}
								{e.dialer_push_status ? (
									<div>
										<strong>
											{this.props.t(
												`HISTORY.TBL_DETAILS.DIALER_STATUS`
											)}
										</strong>{" "}
										: {e.dialer_push_status}
									</div>
								) : (
									""
								)}
							</div>
						))}
					</>
				);
			case "dialer_disposition":
				return (
					<>
						{activityQueue.map((e, i) => (
							<div key={i + historyId + "dialer_disposition"}>
								{e.dialer_status ? (
									<div>
										<strong>
											{this.props.t(
												`HISTORY.TBL_DETAILS.DIALER_STATUS`
											)}
										</strong>{" "}
										: {e.dialer_status}
									</div>
								) : (
									""
								)}
								{e.dialed_by ? (
									<div>
										<strong>
											{this.props.t(
												`HISTORY.TBL_DETAILS.DIALED_BY`
											)}
										</strong>{" "}
										: {e.dialed_by}
									</div>
								) : (
									""
								)}
								{e.dialed_via ? (
									<div>
										<strong>
											{this.props.t(
												`HISTORY.TBL_DETAILS.DIALED_VIA`
											)}
										</strong>{" "}
										: {e.dialed_via}
									</div>
								) : (
									""
								)}
							</div>
						))}
					</>
				);
			case "myactive":
				// 				action_type: "reassign"
				// agent_id: 16
				// agent_name: "Khairunisa"
				// my active agent ka view
				return (
					<>
						{activityQueue.map((e, i) => (
							<div key={i + historyId + "myactive"}>
								<div>
									<strong>
										{this.props.t(
											`HISTORY.TBL_DETAILS.ACTIVITY`
										)}
									</strong>{" "}
									:{" "}
									{e.action_type
										? `${
												e.action_type
													.slice(0, 1)
													.toUpperCase() +
												e.action_type.slice(1)
										  } ${
												e.action_type === "remove"
													? this.props.t(
															"HISTORY.TBL_DETAILS.FROM"
													  )
													: this.props.t(
															"HISTORY.TBL_DETAILS.TO"
													  )
										  }  `
										: null}{" "}
									{e.action_type === "remove"
										? e.old_agent_name
										: e.agent_name || "None"}
								</div>
								<div>
									<strong>
										{this.props.t(
											`HISTORY.TBL_DETAILS.STATUS`
										)}
									</strong>
									{" : "}
									{e.is_active ? "Enable" : "Disable"}
								</div>
							</div>
						))}
					</>
				);
			case "finance":
				return (
					<>
						{activityQueue.map((e, i) => (
							<div key={i + historyId + "finance"}>
								<strong>
									{this.props.t(
										`HISTORY.TBL_DETAILS.FINANCE_DETAIL`
									)}
								</strong>{" "}
								: {e.is_active ? "Enable" : "Disable"}
							</div>
						))}
					</>
				);
			case "exchange":
				return (
					<>
						{activityQueue.map((e, i) => (
							<div key={i + historyId + "finance"}>
								<strong>
									{this.props.t(
										`HISTORY.TBL_DETAILS.EXCHANGE_DETAIL`
									)}
								</strong>{" "}
								: {e.is_active ? "Enable" : "Disable"}
							</div>
						))}
					</>
				);
			case "upload_doc":
				return (
					<>
						{activityQueue.map((e, i) => (
							<div key={i + historyId + "upload_doc"}>
								<strong>
									{this.props.t(
										`HISTORY.TBL_DETAILS.FILE_ADDED`
									)}
								</strong>{" "}
								:{" "}
								<a href={e.file_url} target="_blank">
									{e.file_name || ""}
								</a>
							</div>
						))}
					</>
				);
		}
	}
	getid2Text(key, keyObject) {
		let oldvalue = keyObject.old,
			newvalue = keyObject.new;
		switch (key) {
			case "customer_city_id":
			case "booking_city_id":
				if (this.props.cityMap && this.props.cityMap.size) {
					oldvalue = oldvalue
						? this.props.cityMap.get(+oldvalue) || ""
						: "";
					newvalue = newvalue
						? this.props.cityMap.get(+newvalue) || ""
						: "";
				}
				break;
			case "occupation":
				oldvalue = oldvalue
					? config.constants.OCCUPATION.filter(
							(v) => v.value == oldvalue
					  ).map((v) => v.en_label)[0] || ""
					: "";
				newvalue = newvalue
					? config.constants.OCCUPATION.filter(
							(v) => v.value == newvalue
					  ).map((v) => v.en_label)[0] || ""
					: "";

				break;
			case "marital_status":
				oldvalue = oldvalue
					? config.constants.MARITIAL_STATUS.filter(
							(v) => v.value == oldvalue
					  ).map((v) => v.label)[0] || ""
					: "";
				newvalue = newvalue
					? config.constants.MARITIAL_STATUS.filter(
							(v) => v.value == newvalue
					  ).map((v) => v.label)[0] || ""
					: "";

				break;
			case "family_size":
				oldvalue = oldvalue
					? config.constants.FAMILY_MEMBER.filter(
							(v) => v.value == oldvalue
					  ).map((v) => v.label)[0] || ""
					: "";
				newvalue = newvalue
					? config.constants.FAMILY_MEMBER.filter(
							(v) => v.value == newvalue
					  ).map((v) => v.label)[0] || ""
					: "";
				break;
			case "gender":
				oldvalue = oldvalue
					? config.constants.GENDER.filter(
							(v) => v.value == oldvalue
					  ).map((v) => v.label)[0] || ""
					: "";
				newvalue = newvalue
					? config.constants.GENDER.filter(
							(v) => v.value == newvalue
					  ).map((v) => v.label)[0] || ""
					: "";
				break;
			case "age_range":
				oldvalue = oldvalue
					? config.constants.AGE_RANGE.filter(
							(v) => v.value == oldvalue
					  ).map((v) => v.label)[0] || ""
					: "";
				newvalue = newvalue
					? config.constants.AGE_RANGE.filter(
							(v) => v.value == newvalue
					  ).map((v) => v.label)[0] || ""
					: "";
				break;
			case "make_id":
				if (this.props.makeMap) {
					oldvalue = this.props.makeMap[oldvalue] || "";
					newvalue = this.props.makeMap[newvalue] || "";
				}
				break;
			case "model_id":
				if (this.props.modelMap) {
					oldvalue = this.props.modelMap[+oldvalue] || "";
					newvalue = this.props.modelMap[+newvalue] || "";
				}

				break;
			case "followup_date":
				oldvalue = oldvalue
					? dateformat(oldvalue, config.constants.DATE_FORMAT)
					: "";
				newvalue = newvalue
					? dateformat(newvalue, config.constants.DATE_FORMAT)
					: "";

				break;
		}
		return { oldvalue: oldvalue, newvalue: newvalue };
	}
	render() {
		const { activityData } = this.props;

		return <>{this.getActivityRelatedData(activityData)}</>;
	}
}
const mapStateToProps = ({ inventory }) => {
	return {
		makeMap: inventory.make_map,
		modelMap: inventory.model_map,
		cityMap: (inventory.city ?? []).reduce(
			(obj, item) => (obj.set(item.id, item.name), obj),
			new Map()
		),
	};
};
export default withTranslation("lead_detail")(
	connect(mapStateToProps)(ChangeDetail)
);
