/* eslint-disable */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import SearchCrossSell from "./SearchCrossSell";
import { ReportService } from "../../../service/reportService";
import { ConfigAction, LeadActions } from "../../../store/action";
import { CSVLink, CSVDownload } from "react-csv";
import dateformat from "dateformat";
import config from "../../../config/config";
import PageLoader from "../../elements/PageLoader";
class CrossSellReport extends Component {
	csvLink = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			subHeaders: [],
			csvJsonData: [],
			loading: false,
		};
	}

	componentDidMount = () => {};

	submitRequest = async (filterData) => {
		this.setState({ loading: true });
		let Result = await ReportService.getCrossSellLead(filterData);
		if (Result && Result.status === 200 && Result.data) {
			let data = Result.data.data || [];
			let { make_map, model_map, city_map } = this.state;
			let report_data = [];
			if (data.length) {
				let single_info = {};
				for (let single_lead of data) {
					single_info = {
						"CRM ID": single_lead.lead_id,
						Name: single_lead.name,
						Mobile: single_lead.mobile,
						City: single_lead.customer_city_id
							? city_map[single_lead.customer_city_id] || ""
							: "",
						"Last Call Status": single_lead.last_call_status || "",
						"Cross Sell  Date": Date.parse(single_lead.created_date)
							? dateformat(
									single_lead.created_date,
									config.constants.DATE_FORMAT_YMD
							  )
							: "",
						"Cross Sell Make-Model": `${
							make_map[single_lead.make_id]
						}-${model_map[single_lead.model_id]}`,
						"Cross Sell Dealer-DSE": `${
							single_lead.dealer_dse || ""
						}`,
						"Cross Sell City": single_lead.city_id
							? city_map[single_lead.city_id] || ""
							: "",
						"Agent Name": single_lead.agent_name || "",
						"LD Status": single_lead.ld_status || "",
						Reason: single_lead.failed_reason || "",
						"Booking Date": single_lead.booking_date || "",
						"Last Updated By":
							single_lead.agent_name_updated_by || "",
					};
					report_data.push(single_info);
				}
			}
			this.setState(
				{ loading: false, csvJsonData: report_data },
				async () => {
					if (report_data instanceof Array && report_data.length) {
						this.csvLink.current.link.click();
						await this.props.actions.SaveDownloadLog({
							module: "CrossSellReport",
							action: "1",
						});
					}
				}
			);
		} else {
			this.setState({ loading: false, csvJsonData: [] });
		}
	};

	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		if (props.make_map && props.make_map !== state.make_map) {
			updateProps["make_map"] = props.make_map;
		}
		if (props.model_map && props.model_map !== state.model_map) {
			updateProps["model_map"] = props.model_map;
		}
		if (props.city_map && props.city_map !== state.city_map) {
			updateProps["city_map"] = props.city_map;
		}
		return updateProps;
	}

	render() {
		const { csvJsonData, loading } = this.state;
		return (
			<div>
				<SearchCrossSell onSubmitFilter={this.submitRequest} />

				<div className="hidden">
					<CSVLink
						className="hidden"
						ref={this.csvLink}
						filename="cross_sell_report.csv"
						data={csvJsonData}
					>
						{" "}
					</CSVLink>
				</div>

				<div className="container-fluid reportContainer">
					<div className="result-wrap">
						{loading && (
							<PageLoader
								loaderClass=" text-center loader content-loader"
								loadertext="false"
							/>
						)}
						{/* <div className="col-sm-12 text-right">
							<span>
								{csvJsonData && csvJsonData.length ? (
									<li className="searchitems form-group">
										<CSVLink
											data={csvJsonData}
											filename={"cross_sell_report.csv"}
											className="btn btn-primary"
											target="_blank"
										>
											{this.props.t(
												"DIALER.DOWNLOAD_CSV"
											)}
										</CSVLink>
									</li>
								) : (
									""
								)}
							</span>
						</div> */}
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ config, inventory }) => {
	return {
		make_map: inventory.make_map ? inventory.make_map : {},
		model_map: inventory.model_map ? inventory.model_map : {},
		city_map:
			inventory.city instanceof Array
				? inventory.city.reduce(
						(obj, item) => ((obj[item.id] = item.name), obj),
						{}
				  )
				: {},
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
			SaveDownloadLog: bindActionCreators(
				LeadActions.SaveDownloadLog,
				dispatch
			),
		},
	};
};
export default withTranslation("report")(
	connect(mapStateToProps, mapDispatchToProps)(CrossSellReport)
);
