import React, { Component } from "react";
import { default as Config } from "../../config/config";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import InputField from "../elements/InputField";
import { bindActionCreators } from "redux";
import { ConfigAction } from "../../store/action";

class SearchCampaignList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchItems: {},
			dealerType: [],
			campaignType: [],
		};
	}
	handleChange = (sname, ovalue) => {
		const { target = "" } = ovalue ? ovalue : {};
		const { searchItems } = this.state;
		if (target) {
			const { value } = target;
			searchItems[sname] = value;
		} else {
			searchItems[sname] = ovalue ? ovalue.value : null;
		}
		if (!searchItems[sname]) delete searchItems[sname];
		this.setState({ searchItems: searchItems });
	};
	static getDerivedStateFromProps = (nextProps, prevProps) => {
		const updateProps = [];
		if (
			nextProps.dealer_type instanceof Array &&
			prevProps.dealerType !== nextProps.dealer_type
		) {
			updateProps["dealerType"] = nextProps.dealer_type;
		}
		if (
			nextProps.campaign_type instanceof Array &&
			prevProps.campaignType !== nextProps.campaign_type
		) {
			updateProps["campaignType"] = nextProps.campaign_type;
		}
		return updateProps;
	};
	componentDidMount = () => {
		this.getConfigData();
	};
	getConfigData = () => {
		const requestBody = {
			config_data: ["dealer_type", "campaign_type"],
		};
		this.props.actions.getConfigData(requestBody);
	};
	getSearchRecord = (reset = false, obj) => {
		obj.preventDefault();
		let { searchItems } = this.state;
		if (reset) {
			searchItems = {};
			this.setState({ searchItems: {} });
		}
		this.props.getCampaignPackList(searchItems, reset);
	};
	render() {
		const { searchItems, dealerType, campaignType } = this.state;

		return (
			<div className="search-wrap">
				<form onSubmit={this.submitFilterForm}>
					<ul className="search-flex">
						<li className="searchitems">
							<InputField
								inputProps={{
									id: "search",
									type: "text",
									value: searchItems["search"] || "",
									name: "search",
									autoComplete: "off",
									placeholder: this.props.t(
										"SEARCH_CAMPAIGN.SEARCH_PARAM"
									),
									label: this.props.t(
										"SEARCH_CAMPAIGN.SEARCH_PARAM"
									),
								}}
								autoComplete="off"
								onChange={this.handleChange.bind(
									this,
									"search"
								)}
							/>
						</li>
						<li className="searchitems selectWidth form-group">
							<Select
								id="campaign_type"
								onChange={this.handleChange.bind(
									this,
									"campaign_type"
								)}
								options={campaignType}
								name="campaign_type"
								placeholder={this.props.t(
									"SEARCH_CAMPAIGN.CAMPAIGN_TYPE"
								)}
								isClearable
								value={campaignType.filter(
									({ value }) =>
										value === searchItems.campaign_type
								)}
								getOptionLabel={({ label }) => label}
								getOptionValue={({ value }) => value}
							/>
						</li>
						<li className="searchitems selectWidth form-group">
							<Select
								id="dealer_type"
								onChange={this.handleChange.bind(
									this,
									"dealer_type"
								)}
								options={dealerType}
								name="dealer_type"
								placeholder={this.props.t(
									"SEARCH_CAMPAIGN.DEALER_TYPE"
								)}
								isClearable
								value={dealerType.filter(
									({ value }) =>
										value === searchItems.dealer_type
								)}
								getOptionLabel={({ label }) => label}
								getOptionValue={({ value }) => value}
							/>
						</li>
						<li className="searchitems selectWidth form-group">
							<Select
								id="active_sattus"
								onChange={this.handleChange.bind(
									this,
									"status"
								)}
								isClearable
								options={Config.constants.ACTIVE_STATUS}
								name="status"
								placeholder={this.props.t(
									"SEARCH_CAMPAIGN.ACTIVE_STATUS"
								)}
								value={Config.constants.ACTIVE_STATUS.filter(
									({ value }) => value === searchItems.status
								)}
								getOptionLabel={({ label }) => label}
								getOptionValue={({ value }) => value}
							/>
						</li>
						<li className="searchitems form-group">
							<div>
								<button
									type="submit"
									className="btn btn-primary"
									onClick={this.getSearchRecord.bind(
										this,
										false
									)}
								>
									{this.props.t("SEARCH_CAMPAIGN.SEARCH")}
								</button>
							</div>
						</li>

						<li className="searchitems form-group">
							<button
								type="reset"
								className="btn btn-default btn-reset"
								onClick={this.getSearchRecord.bind(this, true)}
							>
								{this.props.t("SEARCH_CAMPAIGN.RESET")}
							</button>
						</li>
					</ul>
				</form>
			</div>
		);
	}
}
const mapStateToProps = ({ config }) => {
	return {
		dealer_type: config.dealer_type,
		campaign_type: config.campaign_type,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
		},
	};
};
export default withTranslation("campaign_pack")(
	connect(mapStateToProps, mapDispatchToProps)(SearchCampaignList)
);
