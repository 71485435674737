import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FordReportService } from "../../../../service/FordReportService";
import { LeadService } from "../../../../service/leadService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import PageLoader from "../../../elements/PageLoader";
import SearchDealer from "../../component/ford/SearchDealer";
import SwitchBtn from "../../../elements/SwitchBtn";
import ConfirmPopUp from "../../../common/ConfirmPopUp";
class DealerList extends Component {
	constructor(props) {
		super(props);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.state = {
			loading: true,
			leadList: this.props.leadList || [],
			pagination: this.props.pagination || {},
			city_states: this.props.city_states || {},
			filterData: { page: 1 },
			PopOver: false,
			actionType: "status",
		};
	}

	componentDidMount = async () => {
		await this.getConfigData();
		await this.getleadList();
	};

	getConfigData = async () => {
		const requestBody = {
			config_data: ["city_state_list_ph"],
		};
		LeadService.getConfig(requestBody)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					this.setState({
						city_states:
							response.data.data.city_state_list_ph || {},
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};
	getleadList = async () => {
		let { filterData } = this.state;
		this.setState({ loading: true });
		FordReportService.getDealerList(filterData)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let leadList = response.data.data.mapping_data || [];
					let pagination = response.data.data.pagination || {};

					this.setState({
						leadList: leadList,
						pagination: pagination,
						loading: false,
					});
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	handlePageChange(pageNumber) {
		let { filterData } = this.state;
		filterData["page"] = pageNumber;
		this.setState({ filterData: filterData, loading: true });
		this.getleadList();
	}
	submitFilterForm = async (filterData) => {
		filterData.page = 1;
		this.setState(
			{
				loading: true,
				filterData: filterData,
			},
			async () => {
				await this.getleadList();
			}
		);
	};

	updateStatus = async (id, status) => {
		this.setState({ loading: true });
		FordReportService.updateDealerStatus({ id: id, status: status })
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					this.setState(
						{
							loading: false,
							PopOver: false,
						},
						async () => {
							await this.getleadList();
						}
					);
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	displayPopUp = (list_id) => {
		this.setState({ PopOver: list_id });
	};
	confirmNo = (status_id) => {
		this.setState({ PopOver: false });
	};
	confirmYes = (status_id) => {
		let id = this.state.PopOver;
		let status2upd = status_id === "1" ? "0" : "1";
		this.updateStatus(id, status2upd);
	};

	render() {
		const {
			loading,
			leadList,
			filterData,
			pagination,
			city_states,
			PopOver,
		} = this.state;
		return (
			<div>
				<SearchDealer
					onSubmitFilter={this.submitFilterForm}
					filterData={filterData}
					city_states={city_states}
				/>
				<div className="container-fluid">
					<div className="result-wrap">
						<div className="row mrg-b15">
							<div className="col-sm-12 text-right">
								<Link
									to={"/add_dealer"}
									className="btn btn-success btn-sm"
									title={this.props.t("LIST.ADD")}
								>
									{this.props.t("LIST.ADD")}
								</Link>
							</div>
						</div>
						<div className="card">
							<div className="table-responsive">
								{loading && (
									<PageLoader loaderClass="text-center" />
								)}
								<table className="table table-bordered-h table-hover processTable">
									<thead>
										<tr>
											<th>
												{this.props.t(
													"LIST.DEALER_CODE"
												)}
											</th>

											<th>
												{this.props.t(
													"LIST.DEALER_NAME"
												)}
											</th>

											{/* <th>
												{this.props.t(
													"LIST.DEALER_CITY"
												)}
											</th>

											<th>
												{this.props.t(
													"LIST.DEALER_STATE"
												)}
											</th> */}
											<th>
												{this.props.t("LIST.ZIPCODE")}
											</th>

											<th>
												{this.props.t("LIST.STATUS")}
											</th>
											<th>{"Action"}</th>
										</tr>
									</thead>

									<tbody>
										{leadList.length > 0 ? (
											leadList.map(
												(singleinfo, index) => (
													<React.Fragment
														key={singleinfo.id}
													>
														<tr>
															<td>
																<div>
																	{
																		singleinfo.dealer_id
																	}
																</div>
															</td>

															<td>
																<div>
																	{
																		singleinfo.dealer_name
																	}
																</div>
															</td>

															{/* <td>
																<div>
																	{singleinfo.dealer_city ||
																		""}
																</div>
															</td>

															<td>
																<div>
																	{singleinfo.dealer_state ||
																		""}
																</div>
															</td> */}

															<td>
																<div>
																	{singleinfo.zip_code ||
																		""}
																</div>
															</td>

															<td>
																<div>
																	{singleinfo.status ===
																	"1"
																		? this.props.t(
																				"LIST.ACTIVE"
																		  )
																		: this.props.t(
																				"INACTIVE"
																		  )}
																</div>
															</td>

															<td>
																<div>
																	<SwitchBtn
																		label=""
																		htmlFor={
																			"Status" +
																			singleinfo.id
																		}
																		id={
																			"Status" +
																			singleinfo.id
																		}
																		name={
																			"Status" +
																			singleinfo.id
																		}
																		checked={
																			singleinfo.status ===
																			"1"
																				? true
																				: false
																		}
																		onChange={this.displayPopUp.bind(
																			this,
																			singleinfo.id
																		)}
																	/>
																	{PopOver ===
																		singleinfo.id && (
																		<ConfirmPopUp
																			onConfirm={() =>
																				this.confirmYes(
																					singleinfo.status
																				)
																			}
																			onReject={() =>
																				this.confirmNo(
																					singleinfo.status
																				)
																			}
																			message={this.props.t(
																				"LIST.ARE_YOU_SURE"
																			)}
																		/>
																	)}{" "}
																	&nbsp;&nbsp;
																	<Link
																		to={
																			"/edit_dealer/" +
																			singleinfo.id
																		}
																		className="btn btn-link text-link mrg-r15"
																		title={this.props.t(
																			"LIST.EDIT"
																		)}
																	>
																		<i className="ic-create-24px"></i>
																	</Link>
																</div>
															</td>
														</tr>
													</React.Fragment>
												)
											)
										) : (
											<tr>
												<td colSpan="10">
													{!loading ? (
														<h6 className="text-danger">
															{this.props.t(
																"LIST.NOT_FOUND"
															)}
														</h6>
													) : (
														""
													)}
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div className="clearfix">
								{pagination.totalpage >= 1 ? (
									<div className="main-div-pagination">
										<div className="col-sm-6 text-light">
											{this.props.t("LIST.SHOWING")}{" "}
											{filterData.page}{" "}
											{this.props.t("LIST.OF")}{" "}
											{pagination.totalpage}{" "}
											{this.props.t("LIST.PAGES")}
										</div>
										<div className="col-sm-6">
											<Pagination
												activePage={filterData.page}
												itemsCountPerPage={
													pagination.limit
												}
												totalItemsCount={
													pagination.totalrecords
												}
												pageRangeDisplayed={5}
												itemClass="page-item"
												linkClass="page-link"
												onChange={this.handlePageChange}
											/>
										</div>
									</div>
								) : (
									""
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation("ford")(DealerList);
