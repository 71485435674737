import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
const followup_date_list = [
	{ label: "Till Today", value: "0" },
	{ label: "Next 15 Days", value: "15" },
	{ label: "Next 30 Days", value: "30" },
	{ label: "Next 60 Days", value: "60" },
	{ label: "Next 90 Days", value: "90" },
	{ label: " > Next 90 Days", value: "gt90" },
];
class SearchMyActive extends Component {
	constructor(props) {
		super(props);
		this.state = {
			followup_date: "",
			csvJsonData: [],
			disablebtn: false,
		};
	}

	componentDidMount = () => {};

	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		if (props.csvJsonData && props.csvJsonData !== state.csvJsonData) {
			updateProps["csvJsonData"] = props.csvJsonData;
		}
		return updateProps;
	}
	handleChange = (sname, ovalue) => {
		this.setState({
			followup_date: ovalue ? ovalue.value : "",
			//disablebtn: ovalue && ovalue.value ? false : true,
		});
	};

	submitFilterForm = (event) => {
		if (event) event.preventDefault();
		const { followup_date } = this.state;
		if (typeof this.props.onSubmitFilter === "function") {
			this.props.onSubmitFilter({ followup_date: followup_date });
		}
	};
	render() {
		const { followup_date, disablebtn } = this.state;
		return (
			<div className="search-wrap">
				<form onSubmit={this.submitFilterForm}>
					<ul className="search-flex">
						<li className="searchitems selectWidth form-group">
							<Select
								id="followup_date"
								onChange={this.handleChange.bind(
									this,
									"followup_date"
								)}
								options={followup_date_list}
								name="followup_date"
								placeholder={this.props.t(
									"MYACTIVE.FOLLOWUP_DATE"
								)}
								value={
									followup_date_list &&
									followup_date_list.filter(
										({ value }) => value === followup_date
									)
								}
								getOptionLabel={({ label }) => label}
								getOptionValue={({ value }) => value}
								isClearable
							/>
						</li>

						<li className="searchitems form-group">
							<div>
								<button
									type="submit"
									className="btn btn-primary"
									disabled={disablebtn}
								>
									{this.props.t("MYACTIVE.DOWNLOAD_CSV")}
								</button>
							</div>
						</li>
					</ul>
				</form>
			</div>
		);
	}
}
export default withTranslation("report")(SearchMyActive);
