/* eslint-disable */
import React, { Component } from "react";
import Select from "react-select";
import ActivityResult from "./ActivityResult";
import { LeadService } from "../../../service/leadService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { LeadActions, ConfigAction } from "./../../../store/action";
import { bindActionCreators } from "redux";
import config from "../../../config/config";
class PreviousActivity extends Component {
	constructor(props) {
		super(props);
		this.lead_id =
			this.props.match && this.props.match.params
				? this.props.match.params.id
				: 0;
		this.state = {
			showTab: 1,
			fullCard: false,
			status_list: this.props.status_list || [],
			dse_list: [],
			historyData: this.props.historyData || [],
			filterData: {
				lead_id: 0,
				page: 1,
			},
			loading: false,
			page: 0,
			next_page: false,
			hasMoreItems: true,
			tabs: [
				{
					id: 1,
					title: this.props.t("HISTORY.TAB_HEADING.ALL"),
				},
				{
					id: 2,
					title: this.props.t(
						"HISTORY.TAB_HEADING." + config.constants.SOURCE_LABEL
					),
				},
				{
					id: 3,
					title: this.props.t("HISTORY.TAB_HEADING.LMS"),
				},
				{
					id: 4,
					title: this.props.t("HISTORY.TAB_HEADING.SYSTEM"),
				},
				{
					id: 5,
					title: this.props.t("HISTORY.TAB_HEADING.DIALER"),
				},
			],
			leadDetail: {},
			currentInstance: this,
			isHistoryUpdate: false,
			dealersData: [],
		};
	}

	componentDidMount = async () => {
		const leadId = new URLSearchParams(window.location.search).get("id");
		let { filterData } = this.state;
		if (leadId) {
			filterData.lead_id = leadId;
			await this.setState({ filterData });
		}
		this.getAssigncarDealerList();
		//await this.getDseList({'is_pagination_required':false});
		await this.getHistoryData(0);
	};

	static getDerivedStateFromProps = (nextProps, prevState) => {
		let updatedStateData = {};

		if (
			nextProps.config_data &&
			nextProps.config_data.status_list &&
			nextProps.config_data.status_list !== prevState.status_list
		) {
			updatedStateData["status_list"] = nextProps.config_data.status_list;
		}
		if (nextProps && nextProps.isHistoryUpdate === true) {
			prevState.currentInstance.getHistoryData(0);
		}
		// if (nextProps && nextProps.dealer_dse_list !== prevState.dse_list) {
		// 	updatedStateData["dse_list"] = nextProps.dealer_dse_list;
		// }
		return updatedStateData;
	};

	getHistoryData = async (loadmoreStatus = 0) => {
		this.props.actions.updateHistoryFlag(false);
		let { filterData } = this.state;
		filterData["page"] = loadmoreStatus ? filterData.page + 1 : 1;
		await this.setState({
			loading: true,
			hasMoreItems: false,
			filterData: filterData,
		});
		var reaquestBody = await this.getQueryParams();
		LeadService.getLeadHistory(reaquestBody)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let responseData = response.data.data;
					let currentList = this.state.historyData;
					let updatedLeadlist =
						loadmoreStatus === 1
							? currentList.concat(responseData)
							: responseData;
					updatedLeadlist = updatedLeadlist.map((e) => {
						e["history_type"] = "STATUS";
						return e;
					});
					this.setState({
						historyData: updatedLeadlist,
						pagination: response.data.pagination,
						loading: false,
						hasMoreItems:
							responseData.length &&
							response.data.pagination.next_page
								? true
								: false,
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	getAssigncarDealerList = async () => {
		let leadId = new URLSearchParams(window.location.search).get("id");
		await LeadService.getAssignCarDealers({ lead_id: leadId })
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let responseData = response.data.data;
					let dseList = [];
					let dealersDataArray = [];
					if (responseData.length) {
						responseData.map((v) => {
							if (v.dse && v.dse.length) {
								dseList = dseList.concat(v.dse);
								v.dse.map((dseinfo) => {
									dealersDataArray.push({
										value: dseinfo.dse_id,
										id: dseinfo.dse_id,
										name: `${
											v.dealer_name ||
											v.dealer_organization
										} - ${dseinfo.dse_name}`,
									});
								});
							}
						});
					}
					this.setState({
						//dealersData: dealersDataArray,
						dse_list: dealersDataArray,
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	getQueryParams = async () => {
		let { filterData } = this.state;
		let searchParams = {};
		if (filterData.lead_id) searchParams.lead_id = filterData.lead_id;
		if (filterData.dse_id) searchParams.dse_id = filterData.dse_id;
		if (filterData.status_id) searchParams.status_id = filterData.status_id;
		if (filterData.source) searchParams.source = filterData.source;
		//if (filterData.source) searchParams.source = (filterData.source === 'system') ? ['system','dialer'].toString() : [filterData.source].toString();
		if (filterData.page) searchParams.page = filterData.page;
		return searchParams;
	};

	handleChange = async (key, event) => {
		let { filterData } = this.state;
		if (key === "status") {
			if (event) filterData.status_id = event.status_id;
			else delete filterData.status_id;
		}
		if (key === "dse") {
			if (event) filterData.dse_id = event.id;
			else delete filterData.dse_id;
		}
		await this.setState({ filterData: filterData });
		await this.getHistoryData(0);
	};

	fullCardChange = () => {
		this.setState({ fullCard: !this.state.fullCard });
	};

	handleTabChange = async (event) => {
		let activeTab = event.currentTarget.dataset.key;
		let { filterData } = this.state;
		if (Number(activeTab) === 1) {
			delete filterData.source;
		} else if (Number(activeTab) === 2) {
			filterData.source = "crm";
		} else if (Number(activeTab) === 3) {
			filterData.source = "lms";
		} else if (Number(activeTab) === 4) {
			filterData.source = "system";
		} else if (Number(activeTab) === 5) {
			filterData.source = "dialer";
		}

		await this.setState({
			showTab: event.currentTarget.dataset.key,
			filterData: filterData,
		});
		await this.getHistoryData();
	};

	loadMoreitems = async () => {
		this.getHistoryData(1);
	};

	resetFilter = async () => {
		let showTab = 1;
		let filterData = {
			lead_id: this.state.filterData.lead_id,
			page: 1,
		};
		await this.setState({ filterData: filterData, showTab: showTab });
		this.getHistoryData(0);
	};

	render() {
		const {
			showTab,
			status_list,
			dse_list,
			filterData,
			hasMoreItems,
			tabs,
			loading,
		} = this.state;
		return (
			<div>
				<div
					className={
						this.state.fullCard === true ? "card fullCard" : "card"
					}
				>
					<div className="card-heading">
						<div className="previusCard headflex">
							<div className="flex-item">
								<ul
									className="tagacdtionbtn nav-tabs mrg-b0"
									role="tablist"
								>
									{tabs.map((e, index) => (
										<li
											key={index}
											onClick={this.handleTabChange}
											className={
												"nav-item " +
												(Number(showTab) ===
												Number(e.id)
													? "active"
													: "")
											}
											data-key={e.id}
										>
											<a
												href="#firstsubmit"
												id="first-submit"
												role="tab"
											>
												{e.title}
											</a>
										</li>
									))}
								</ul>
							</div>
							<div className="flex-item activityFilter">
								<div className="row">
									<div className="col-sm-7">
										<Select
											id="dse"
											onChange={this.handleChange.bind(
												this,
												"dse"
											)}
											options={dse_list}
											name="dse"
											placeholder={this.props.t(
												"HISTORY.TBL_DETAILS.DEALER_DSE_PLACEHOLDER"
											)}
											value={
												dse_list &&
												dse_list.filter(
													({ id }) =>
														id === filterData.dse_id
												)
											}
											getOptionLabel={({ name }) => name}
											getOptionValue={({ id }) => id}
											isClearable
										/>
									</div>
									<div className="col-sm-5">
										<Select
											id="status"
											onChange={this.handleChange.bind(
												this,
												"status"
											)}
											options={status_list}
											name="status"
											placeholder={this.props.t(
												"HISTORY.TBL_DETAILS.STATUS_PLACEHOLDER"
											)}
											value={status_list.filter(
												({ status_id }) =>
													status_id ===
													filterData.status_id
											)}
											getOptionLabel={({ name }) => name}
											getOptionValue={({ status_id }) =>
												status_id
											}
											isClearable
											isOptionDisabled={false}
										/>
									</div>
									{/* <div className="col-sm-2">
										<button
											type="button"
											className="btn btn-primary mrg-r15"
											onClick={this.resetFilter.bind(
												this
											)}
										>
											{this.props.t(
												"HISTORY.TBL_DETAILS.RESET"
											)}
										</button>
									</div> */}
								</div>
							</div>
							<div className="flex-item text-right">
								<span
									className="link-text"
									onClick={this.fullCardChange}
								>
									{this.state.fullCard === true ? (
										this.props.t(
											"HISTORY.TBL_DETAILS.X_CLOSE"
										)
									) : (
										<i className="ic-open_in_full-24px"></i>
									)}
								</span>
							</div>
						</div>
					</div>
					<div className="card-body scroll-y">
						<div className="tab-content">
							<div className="tab-pane active">
								<ActivityResult
									historyData={this.state.historyData}
									loadMoreitems={this.loadMoreitems}
									loading={loading}
									hasMoreItems={hasMoreItems}
								/>
							</div>
						</div>
					</div>
				</div>
				{this.state.fullCard === true ? (
					<div className="modal-backdrop"></div>
				) : (
					""
				)}
			</div>
		);
	}
}

const mapStateToProps = ({ config, lead }) => {
	return {
		config_data: config,
		isHistoryUpdate: lead.is_history_update,
		//dealer_dse_list: lead.dealer_dse_list,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getSubLeadDetails: bindActionCreators(
				LeadActions.getSubLeadDetails,
				dispatch
			),
			getConfig: bindActionCreators(ConfigAction.getConfigData, dispatch),
			updateHistoryFlag: bindActionCreators(
				LeadActions.updateHistoryFlag,
				dispatch
			),
		},
	};
};
export default withTranslation("lead_detail")(
	connect(mapStateToProps, mapDispatchToProps)(PreviousActivity)
);
