import React, { Component } from "react";
import Select from "react-select";
import InputField from "../../../elements/InputField";
import { LeadService } from "../../../../service/leadService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";

class SearchWebids extends Component {
	constructor(props) {
		super(props);
		this.state = {
			domain_list: this.props.domain_list || [],
			filterData: this.props.filterData || {},
		};
	}
	componentDidMount = async () => {
		await this.getConfigData();
	};
	static getDerivedStateFromProps(props, state) {
		return {
			filterData: props.filterData,
		};
	}

	submitFilterForm = (event) => {
		event.preventDefault();
		if (typeof this.props.onSubmitFilter === "function") {
			let { filterData } = this.state;
			this.props.onSubmitFilter(filterData);
		}
	};

	ResetFilterForm = (event) => {
		event.preventDefault();
		let filterData = { page: 1 };
		this.props.onSubmitFilter(filterData, {});
	};

	// get config
	getConfigData = async () => {
		const requestBody = {
			config_data: ["domain_list"],
		};
		LeadService.getConfig(requestBody)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					this.setState({
						domain_list: response.data.data.domain_list
							? response.data.data.domain_list[0]
							: [],
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	handleChange = (sname, valueObj) => {
		let { filterData } = this.state;
		let value = valueObj.value || valueObj.id;
		if (sname === "webid") value = valueObj.target.value;
		filterData[sname] = value;
		this.setState({ filterData: filterData });
	};

	render() {
		const { filterData, domain_list } = this.state;
		return (
			<div className="search-wrap">
				<form onSubmit={this.submitFilterForm}>
					<ul className="search-flex">
						<li className="searchitems">
							<InputField
								inputProps={{
									id: "webid",
									type: "text",
									value: filterData.webid || "",
									name: "webid",
									autoComplete: "off",
									placeholder: this.props.t("WEBIDS.NAME"),
									label: this.props.t("WEBIDS.NAME"),
								}}
								autoComplete="off"
								onChange={this.handleChange.bind(this, "webid")}
							/>
						</li>

						<li className="searchitems selectWidth form-group">
							<Select
								id="domain_id"
								onChange={this.handleChange.bind(
									this,
									"domain_id"
								)}
								options={domain_list}
								name="domain_id"
								placeholder={this.props.t("WEBIDS.DOMAIN")}
								value={domain_list.filter(
									({ id }) => id === filterData.domain_id
								)}
								getOptionLabel={({ name }) => name}
								getOptionValue={({ id }) => id}
							/>
						</li>

						<li className="searchitems form-group">
							<div>
								<button
									type="submit"
									className="btn btn-primary"
								>
									{this.props.t("WEBIDS.SEARCH")}
								</button>
							</div>
						</li>

						<li className="searchitems form-group">
							<button
								type="reset"
								className="btn btn-default btn-reset"
								onClick={this.ResetFilterForm}
							>
								{this.props.t("WEBIDS.RESET")}
							</button>
						</li>
					</ul>
				</form>
			</div>
		);
	}
}

export default withTranslation("webfrom")(SearchWebids);
