import MasterService from "./MasterService";

export const UserService = {
	...MasterService,
	saveRules(postData) {
		return this.post("/webform/save_lead_process_rule", postData);
	},
	getRules(postData) {
		return this.get("/webform/get_lead_process_rule", postData);
	},
	getProcessCampaignList(postData) {
		return this.post("/webform/list_process_campaign_list", postData);
	},
	saveProcessCampaignList(postData) {
		return this.post("/webform/save_process_campaign_list", postData);
	},
	getBlackList(postData) {
		return this.get("/customer/get_black_list_numbers", postData);
	},
	saveBlackList(postData) {
		return this.put("/customer/save_black_list_number", postData);
	},
	getCampaignPackList(postData) {
		return this.get("/campaign/list", postData);
	},
	saveCampaignList(postData) {
		return this.post("/campaign/save", postData);
	},
	getCampaignPackMapping(postData) {
		return this.get("/campaign/get_pack_list", postData);
	},
	updateCampaignPackMapping(postData) {
		return this.post("/campaign/assign_pack_id", postData);
	},
	syncDialerReportData() {
		return this.get("/cron/report/create_dialer_report");
	},
	getAllUsers() {
		return this.get("/user/get_users_list");
	},
	getUserById(postData) {
		return this.post("/user/get_user", postData)
	},
	saveUser(postData) {
		return this.post("/user/save_user", postData);
	},
	getRoleList(postData) {
		return this.get("/roles/list_role", postData);
	},
	getGmAssociatedDse(postData) {
		return this.get("/dse/get_gm_associated_dse", postData);
	},
};
