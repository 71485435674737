import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import {
	AuthReducer,
	LeadReducer,
	ConfigReducer,
	InventoryReducer,
	UserReducer,
} from "./store/reducer";

import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import secureStorage from "./config/encrypt";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_id from "./translations/id/common.json";
import common_en from "./translations/en/common.json";
import lead_detail_en from "./translations/en/lead_detail.json";
import lead_detail_id from "./translations/id/lead_detail.json";
import lead_list_en from "./translations/en/lead_list.json";
import lead_list_id from "./translations/id/lead_list.json";
import webform_en from "./translations/en/web_form.json";
import webform_id from "./translations/id/web_form.json";
import report_id from "./translations/id/report.json";
import report_en from "./translations/en/report.json";
import ford_id from "./translations/id/ford.json";
import ford_en from "./translations/en/ford.json";
import campaign_pack_id from "./translations/id/campaign_pack.json";
import campaign_pack_en from "./translations/en/campaign_pack.json";
//ph files
import common_ph from "./translations/ph/common.json";
import lead_detail_ph from "./translations/ph/lead_detail.json";
import lead_list_ph from "./translations/ph/lead_list.json";
import webform_ph from "./translations/ph/web_form.json";
import report_ph from "./translations/ph/report.json";
import ford_ph from "./translations/ph/ford.json";
import campaign_pack_ph from "./translations/ph/campaign_pack.json";
import audit_id from "./translations/id/audit.json";
import audit_en from "./translations/en/audit.json";
import audit_ph from "./translations/ph/audit.json";

const loggerMiddleware = createLogger();
const current_lang = secureStorage.getItem("lang")
	? secureStorage.getItem("lang")
	: process.env.REACT_APP_DEFAULT_LANG;
i18next.init({
	interpolation: { escapeValue: false },
	lng: current_lang,
	resources: {
		en: {
			common: common_en,
			lead_detail: lead_detail_en,
			lead_list: lead_list_en,
			webfrom: webform_en,
			report: report_en,
			ford: ford_en,
			campaign_pack: campaign_pack_en,
			audit: audit_en,
		},
		id: {
			common: common_id,
			lead_detail: lead_detail_id,
			lead_list: lead_list_id,
			webfrom: webform_id,
			report: report_id,
			ford: ford_id,
			campaign_pack: campaign_pack_id,
			audit: audit_id,
		},
		ph: {
			common: common_ph,
			lead_detail: lead_detail_ph,
			lead_list: lead_list_ph,
			webfrom: webform_ph,
			report: report_ph,
			ford: ford_ph,
			campaign_pack: campaign_pack_ph,
			audit: audit_ph,
		},
	},
});

const rootReducer = combineReducers({
	auth: AuthReducer,
	lead: LeadReducer,
	config: ConfigReducer,
	inventory: InventoryReducer,
	user: UserReducer,
});

let middleware = [];
if (process.env.NODE_ENV === "dev") {
	//set development to show errors
	middleware = [...middleware, thunkMiddleware, loggerMiddleware];
} else {
	middleware = [...middleware, thunkMiddleware];
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
	rootReducer,
	composeEnhancer(applyMiddleware(...middleware))
);

ReactDOM.render(
	<I18nextProvider i18n={i18next}>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</I18nextProvider>,
	document.getElementById("root")
);
//serviceWorker.unregister();
