import React, { Component } from "react";
import { Link } from "react-router-dom";
import { webFormService } from "../../../../service/webFormService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import PageLoader from "../../../elements/PageLoader";
import SearchCampaign from "../../component/campaign/SearchCampaign";
class CampaignList extends Component {
	constructor(props) {
		super(props);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.state = {
			loading: true,
			campaignList: this.props.campaignList || [],
			pagination: this.props.pagination || {},
			filterData: { page: 1 },
			PopOver: false,
		};
	}

	componentDidMount = async () => {
		await this.getcampaignList();
	};

	getcampaignList = async () => {
		let { filterData } = this.state;
		this.setState({ loading: true });
		webFormService
			.getCampaignList(filterData)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let campaignList = response.data.data.campaignInfo || [];
					let pagination = response.data.data.pagination || {};
					this.setState({
						campaignList: campaignList,
						pagination: pagination,
						loading: false,
					});
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	handlePageChange(pageNumber) {
		let { filterData } = this.state;
		filterData["page"] = pageNumber;
		this.setState({ filterData: filterData, loading: true });
		this.getcampaignList();
	}
	submitFilterForm = async (filterData) => {
		filterData.page = 1;
		this.setState(
			{
				loading: true,
				filterData: filterData,
			},
			async () => {
				await this.getcampaignList();
			}
		);
	};

	updateCampaignStatus = async (id, status) => {
		this.setState({ loading: true });
		webFormService
			.updateCampaignStatus({ id: id, status: status })
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					this.setState(
						{
							loading: false,
							PopOver: false,
						},
						async () => {
							await this.getcampaignList();
						}
					);
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	displayPopUp = (list_id) => {
		this.setState({ PopOver: list_id });
	};
	confirmNo = (status_id) => {
		this.setState({ PopOver: false });
	};
	confirmYes = (status_id) => {
		let campaign_id = this.state.PopOver;
		let status2upd = status_id === "1" ? "0" : "1";
		this.updateCampaignStatus(campaign_id, status2upd);
	};

	render() {
		const { loading, campaignList, filterData, pagination } = this.state;
		return (
			<div>
				<SearchCampaign
					onSubmitFilter={this.submitFilterForm}
					filterData={filterData}
				/>
				<div className="container-fluid">
					<div className="result-wrap">
						<div className="row mrg-b15">
							<div className="col-sm-6">
								<p className="countNo mrg-t5 mrg-b0">
									{pagination.totalrecords} -{" "}
									{this.props.t("CAMPAIGN.CAMPAIGNS")}
								</p>
							</div>
							<div className="col-sm-6 text-right">
								<Link
									to={"/add_campaign"}
									className="btn btn-success btn-sm"
									title={this.props.t("CAMPAIGN.ADD")}
								>
									{this.props.t("PROCESS.ADD")}
								</Link>
							</div>
						</div>
						<div className="card">
							<div className="table-responsive">
								{loading && (
									<PageLoader loaderClass="text-center" />
								)}
								<table className="table table-bordered-h table-hover processTable ">
									<thead>
										<tr>
											<th>
												{this.props.t("CAMPAIGN.NAME")}
											</th>
											<th>
												{this.props.t(
													"CAMPAIGN.PROCESS"
												)}
											</th>

											<th>
												{this.props.t("CAMPAIGN.TYPE")}
											</th>
											<th>
												{this.props.t(
													"CAMPAIGN.ACTION"
												)}
											</th>
										</tr>
									</thead>

									<tbody>
										{campaignList.length > 0 ? (
											campaignList.map(
												(singleinfo, index) => (
													<React.Fragment
														key={singleinfo.id}
													>
														<tr>
															<td>
																<div>
																	{singleinfo.name ||
																		""}
																</div>
															</td>
															<td>
																<div>
																	{(singleinfo.Process_info &&
																		singleinfo
																			.Process_info
																			.name) ||
																		""}
																</div>
															</td>

															<td>
																<div>
																	{singleinfo.type ||
																		""}
																</div>
															</td>
															<td>
																<div>
																	<Link
																		to={
																			"/edit_campaign/" +
																			singleinfo.id
																		}
																		className="btn btn-link text-link mrg-r15"
																		title={this.props.t(
																			"CAMPAIGN.EDIT"
																		)}
																	>
																		<i className="ic-create-24px"></i>
																	</Link>
																	{/* <SwitchBtn
																		label=""
																		htmlFor={
																			"Status" +
																			singleinfo.id
																		}
																		id={
																			"Status" +
																			singleinfo.id
																		}
																		name={
																			"Status" +
																			singleinfo.id
																		}
																		checked={
																			singleinfo.status ===
																			"1"
																				? true
																				: false
																		}
																		onChange={this.displayPopUp.bind(
																			this,
																			singleinfo.id
																		)}
																	/>
																	{PopOver ===
																		singleinfo.id && (
																		<ConfirmPopUp
																			onConfirm={() =>
																				this.confirmYes(
																					singleinfo.status
																				)
																			}
																			onReject={() =>
																				this.confirmNo(
																					singleinfo.status
																				)
																			}
																			message={this.props.t(
																				"COMMON.ARE_YOU_SURE"
																			)}
																		/>
																	)} */}
																	{/* <Link
																		to={
																			"/process-campaign-list?campaign_id=" +
																			singleinfo.id
																		}
																		className="btn btn-link text-link mrg-l15"
																		title={this.props.t(
																			"CAMPAIGN.MANAGE_LIST"
																		)}
																	>
																		<i className="ic-menu-24px"></i>
																	</Link> */}
																</div>
															</td>
														</tr>
													</React.Fragment>
												)
											)
										) : (
											<tr>
												<td colSpan="10">
													{!loading ? (
														<h6 className="text-danger">
															{this.props.t(
																"CAMPAIGN.NOT_FOUND"
															)}
														</h6>
													) : (
														""
													)}
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div className="clearfix">
								{pagination.totalpage >= 1 ? (
									<div className="main-div-pagination">
										<div className="col-sm-6 text-light">
											{this.props.t("CAMPAIGN.SHOWING")}{" "}
											{filterData.page}{" "}
											{this.props.t("CAMPAIGN.OF")}{" "}
											{pagination.totalpage}{" "}
											{this.props.t("CAMPAIGN.PAGES")}
										</div>
										<div className="col-sm-6">
											<Pagination
												activePage={filterData.page}
												itemsCountPerPage={
													pagination.pagelimit
												}
												totalItemsCount={
													pagination.totalrecords
												}
												pageRangeDisplayed={5}
												itemClass="page-item"
												linkClass="page-link"
												onChange={this.handlePageChange}
											/>
										</div>
									</div>
								) : (
									""
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation("webfrom")(CampaignList);
