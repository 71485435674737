import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { default as config } from "../../../../config/config";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { connect } from "react-redux";

import { toast } from "react-toastify";
class SearchRetailReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterData: {},
			isSearchButtonDisabled: false,
			process_list: [],
		};
	}

	handleChange = async (sname, value, event) => {
		const { filterData, process_list } = this.state;
		if (event.action === "select-option" && event.option.value === "ALL") {
			filterData["process_id"] = process_list.map((e) => e.value);
		} else if (
			event.action === "deselect-option" &&
			event.option.value === "ALL"
		) {
			filterData["process_id"] = [];
		} else if (
			event.action === "deselect-option" &&
			event.option.value !== "ALL"
		) {
			filterData["process_id"] = value
				.filter((f) => f.value !== "ALL")
				.map((o) => o.value);
		} else if (value.length === process_list.length - 1) {
			filterData["process_id"] = process_list.map((e) => e.value);
		} else {
			filterData["process_id"] = value.map((o) => o.value);
		}
		this.setState({
			filterData: filterData,
		});
	};
	static getDerivedStateFromProps = (nextProps, prevState) => {
		let updatedStateData = {};
		if (
			nextProps.isSearchButtonDisabled !==
			prevState.isSearchButtonDisabled
		) {
			updatedStateData["isSearchButtonDisabled"] =
				nextProps.isSearchButtonDisabled;
		}
		if (
			nextProps.process_list &&
			nextProps.process_list !== prevState.process_list
		) {
			updatedStateData["process_list"] = nextProps.process_list;
		}
		return updatedStateData;
	};
	componentDidMount = () => {};
	resetFilterForm = () => {
		this.setState({
			filterData: {},
		});
	};
	validateFilterData = (filterData) => {
		let error = "";
		if (filterData && !filterData.selected_month)
			error = this.props.t("RETAIL_REPORT.ERROR.MONTH_REQUIRED");

		if (error) {
			toast.error(error);
			return false;
		}
		return true;
	};
	submitFilterForm = (event) => {
		if (event) event.preventDefault();
		const { filterData } = this.state;
		let tempFilterData = { is_all_process_selected: false, ...filterData };
		filterData.is_all_process_selected = false;
		if (
			filterData &&
			filterData.process_id &&
			filterData.process_id.find((e) => e === "ALL")
		) {
			tempFilterData.process_id = filterData.process_id
				.filter((f) => f !== "ALL")
				.map((e) => e);
			tempFilterData.is_all_process_selected = true;
		}
		//if (this.validateFilterData(filterData)) {
		if (typeof this.props.onSubmitFilter === "function") {
			this.props.onSubmitFilter(tempFilterData);
		}
		//}
	};
	render() {
		const { filterData, isSearchButtonDisabled, process_list } = this.state;

		return (
			<div className="search-wrap">
				<form onSubmit={this.submitFilterForm}>
					<ul className="search-flex">
						<li className="searchitems selectWidth form-group selectIndex">
							<ReactMultiSelectCheckboxes
								isMulti
								placeholderButtonLabel={this.props.t(
									"RETAIL_REPORT.PROCESS"
								)}
								value={
									(process_list instanceof Array &&
										filterData.process_id &&
										process_list.filter((e) =>
											[
												...(filterData.process_id instanceof
												Array
													? filterData.process_id
													: []),
											].includes(e.value)
										)) ||
									[]
								}
								onChange={this.handleChange.bind(
									this,
									"process_id"
								)}
								options={process_list}
								getOptionLabel={({ label }) => label}
								getOptionValue={({ value }) => value}
							/>
						</li>

						<li className="searchitems form-group">
							<div>
								<button
									type="submit"
									className="btn btn-primary"
									disabled={isSearchButtonDisabled}
								>
									{this.props.t("RETAIL_REPORT.SEARCH")}
								</button>
							</div>
						</li>

						<li className="searchitems form-group">
							<button
								type="reset"
								className="btn btn-default btn-reset"
								onClick={this.resetFilterForm}
							>
								{this.props.t("RETAIL_REPORT.RESET")}
							</button>
						</li>
					</ul>
				</form>
			</div>
		);
	}
}
const mapStateToProps = ({ config, lead }) => {
	let origProcessList =
		config.gm_campaign_process_list instanceof Array
			? config.gm_campaign_process_list.map((e) => {
					return { value: e.campaign_id, label: e.campaign_name };
			  })
			: [];
	if (
		origProcessList.length &&
		origProcessList.find((e) => e.value !== "ALL")
	)
		origProcessList.unshift({ value: "ALL", label: "All", disabled: true });
	return {
		process_list: origProcessList,
	};
};

export default withTranslation("report")(
	connect(mapStateToProps, null)(SearchRetailReport)
);
