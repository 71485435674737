/* eslint-disable */
import React, { Component } from "react";
import { default as Config } from "../../../../../config/config";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
	InventoryAction,
	UserActions,
	ConfigAction,
} from "../../../../../store/action";

class ListWebRules extends Component {
	constructor(props) {
		super(props);
		this.state = {
			processListId: null,
			rule: [],
			rulesObj: {},
			makeMap: {},
			modelMap: {},
			webIds: {},
			sourceList: {},
			cityList: {},
			pinCodeMap: {},
			leadTypList: {},
			initialLoad: true,
		};
	}
	componentDidMount = () => {
		const search = new URLSearchParams(window.location.search);
		const id = search.get("id");
		this.setState({ processListId: id });
		const postAttr = {
			process_list_id: id,
		};
		this.props.actions.getRuleData(postAttr);
		this.props.actions.getMMV();
		const configPost = {
			config_data: [
				"webids_list",
				"source_list",
				"city_state_list",
				"lead_type_list",
			],
		};
		this.props.actions.getConfigData(configPost);
	};
	static getDerivedStateFromProps = (nextProps, prevProps) => {
		const updateProps = {};

		if (nextProps && nextProps.rule !== prevProps.rule) {
			updateProps["rule"] = nextProps.rule;
		}
		if (nextProps.makeMap && nextProps.makeMap !== prevProps.makeMap) {
			updateProps["makeMap"] = nextProps.makeMap;
		}
		if (nextProps.modelMap && nextProps.modelMap !== prevProps.modelMap) {
			updateProps["modelMap"] = nextProps.modelMap;
		}
		if (nextProps.modelMap && nextProps.modelMap !== prevProps.modelMap) {
			updateProps["modelMap"] = nextProps.modelMap;
		}
		if (nextProps.webIds && nextProps.webIds !== prevProps.webIds) {
			updateProps["webIds"] = nextProps.webIds;
		}
		if (
			nextProps.sourceList &&
			nextProps.sourceList !== prevProps.sourceList
		) {
			updateProps["sourceList"] = nextProps.sourceList;
		}
		if (nextProps.cityList && nextProps.cityList !== prevProps.cityList) {
			updateProps["cityList"] = nextProps.cityList;
		}
		if (
			nextProps.leadTypList &&
			nextProps.leadTypList !== prevProps.leadTypList
		) {
			updateProps["leadTypList"] = nextProps.leadTypList;
		}
		return updateProps;
	};
	componentDidUpdate = () => {
		const { rule, initialLoad } = this.state;

		if (
			rule &&
			rule.city_rule &&
			rule.city_rule.length > 0 &&
			initialLoad
		) {
			console.log("here");
			this.getLocality(rule.city_rule);
		}
	};
	getLocality = async (city) => {
		const data = await this.props.actions.getLocalityForCity(city);
		const { locality = [] } = data ? data : {};
		const pinCode = {};
		if (locality instanceof Array) {
			for (let i of locality) pinCode[i.value] = i.label;
		}

		this.setState({ initialLoad: false, pinCodeMap: pinCode });
	};
	handleUpdateRule = () => {
		const { processListId } = this.state;
		window.location.href = `/edit-web-rule?id=${processListId}`;
	};
	getMappedIdName = (type, ids) => {
		const {
			webIds,
			makeMap,
			modelMap,
			sourceList,
			cityList,
			// pinCodeMap,
			leadTypList,
		} = this.state;
		const buyTimeObj = Config.constants.BUY_TIME_RULE.reduce(
			(obj, item) => ((obj[item.value] = item.label), obj),
			{}
		);
		let name = "";
		// console.log(type);
		// ids = ids.split(",").map((e) => +e);
		switch (type) {
			case "brand_rule":
				name =
					makeMap && Object.keys(makeMap).length
						? ids
								.map((e) => makeMap[e])
								.filter((e) => e)
								.join(" , ")
						: "";
				break;
			case "model_rule":
				name =
					modelMap && Object.keys(modelMap).length
						? ids
								.map((e) => modelMap[e])
								.filter((e) => e)
								.join(" , ")
						: "";
				break;
			case "web_id_rule":
				name =
					webIds && Object.keys(webIds).length
						? ids
								.map((e) => webIds[e])
								.filter((e) => e)
								.join(" , ")
						: "";
				break;
			case "city_rule":
				name =
					cityList && Object.keys(cityList).length
						? ids
								.map((e) => cityList[e])
								.filter((e) => e)
								.join(" , ")
						: "";
				break;
			case "lead_source_rule":
				name =
					sourceList && Object.keys(sourceList).length
						? ids
								.map((e) => sourceList[e])
								.filter((e) => e)
								.join(" , ")
						: "";
				break;
			case "buy_time_rule":
				name =
					buyTimeObj && Object.keys(buyTimeObj).length
						? ids
								.map((e) => buyTimeObj[e])
								.filter((e) => e)
								.join(" , ")
						: "";
				break;
			// case "pincode_rule":
			// 	name =
			// 		pinCodeMap && Object.keys(pinCodeMap).length
			// 			? ids
			// 					.map((e) => pinCodeMap[e])
			// 					.filter((e) => e)
			// 					.join(" , ")
			// 			: "";
			// 	break;
			// case "pin_code_negate":
			// 	name = ids ? "true" : "false";
			// 	break;
			case "city_rule_negate":
				name = ids ? "true" : "false";
				break;
			case "lead_type_rule":
				name =
					leadTypList && Object.keys(leadTypList).length
						? ids
								.map((e) => leadTypList[e])
								.filter((e) => e)
								.join(" , ")
						: "";
				break;
			default:
				name = ids;
		}
		return name;
	};
	render() {
		const { processListId, rule } = this.state;

		const _this = this;
		return (
			<div className="container-fluid">
				<div className="card mrg-t15">
					<div className="card-heading">
						<div className="row">
							<div className="col-sm-6">
								<h2 className="card-title">
									{this.props.t("VIEW_RULES.VIEW_RULES")}
								</h2>
							</div>
							<div className="col-sm-6 text-right">
								{rule && Object.keys(rule).length === 10 && (
									<input
										type="submit"
										value={this.props.t(
											"VIEW_RULES.UPDATE_RULE"
										)}
										className="btn btn-success btn-sm"
										onClick={this.handleUpdateRule}
									/>
								)}
							</div>
						</div>
					</div>
					<div className="card-body">
						{processListId && (
							<ul className="ruleList">
								<li>
									<div className="row">
										<div className="col-sm-3 col-md-2">
											<strong>
												{this.props.t(
													"VIEW_RULES.RULES"
												)}
											</strong>
										</div>
										<div className="col-sm-9 col-md-10">
											<strong>
												{this.props.t(
													"VIEW_RULES.RULE_DESCRIPTION"
												)}
											</strong>
										</div>
									</div>
								</li>

								{rule &&
									Object.keys(rule).length > 0 &&
									Object.keys(rule).map(function (e, i) {
										return (
											<li key={i}>
												<div className="row">
													<div className="col-sm-3 col-md-2">
														<strong>
															{_this.props.t(
																`VIEW_RULES.${e}`
															)}
														</strong>
													</div>
													<div className="col-sm-9 col-md-10">
														{_this.getMappedIdName(
															e,
															rule[e]
														)}
													</div>
												</div>
											</li>
										);
									})}
								{rule && Object.keys(rule).length === 0 && (
									<li>
										<div className="row">
											<div className="col-sm-12">
												{this.props.t(
													"VIEW_RULES.NO_RULES_FOUND"
												)}
											</div>
										</div>
									</li>
								)}
								<li>
									<div className="row">
										<div className="col-sm-12">
											<input
												type="submit"
												value={this.props.t(
													"VIEW_RULES.UPDATE_RULE"
												)}
												className="btn btn-primary"
												onClick={this.handleUpdateRule}
											/>
										</div>
									</div>
								</li>
							</ul>
						)}
						{!processListId && <p>Error-List Id Missing</p>}
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ user, inventory, config }) => {
	return {
		rule: user.rule ? user.rule.rule : [],
		modelMap: inventory.model_map ? inventory.model_map : {},
		makeMap: inventory.make_map ? inventory.make_map : {},
		webIds:
			config.webids_list instanceof Array
				? config.webids_list.reduce(
						(obj, item) => ((obj[item.id] = item.webid), obj),
						{}
				  )
				: {},
		sourceList:
			config.source_list instanceof Array
				? config.source_list.reduce(
						(obj, item) => ((obj[item.source_id] = item.name), obj),
						{}
				  )
				: {},
		cityList:
			config.city_state_list && Object.keys(config.city_state_list).length
				? config.city_state_list.city.reduce(
						(obj, item) => ((obj[item.id] = item.name), obj),
						{}
				  )
				: {},
		leadTypList:
			config.lead_type_list && Object.keys(config.lead_type_list).length
				? config.lead_type_list.reduce(
						(obj, item) => ((obj[item.id] = item.name), obj),
						{}
				  )
				: {},
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getRuleData: bindActionCreators(UserActions.getRuleData, dispatch),
			getMMV: bindActionCreators(InventoryAction.getMMV, dispatch),
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
			getLocalityForCity: bindActionCreators(
				InventoryAction.getLocality,
				dispatch
			),
		},
	};
};
export default withTranslation("webfrom")(
	connect(mapStateToProps, mapDispatchToProps)(ListWebRules)
);
