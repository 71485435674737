/* eslint-disable */
import React, { Component } from "react";
import InputField from "../../../elements/InputField";
import Select from "react-select";
import { withTranslation } from "react-i18next";

class SearchFord extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterData: this.props.filterData || {},
			city_states: this.props.city_states || {},
		};
	}

	static getDerivedStateFromProps(props, state) {
		let updateState = {};
		updateState["filterData"] = props.filterData;
		if (state.city_states != props.city_states) {
			updateState["city_states"] = props.city_states;
		}
		return updateState;
	}

	submitFilterForm = (event) => {
		event.preventDefault();
		if (typeof this.props.onSubmitFilter === "function") {
			let { filterData } = this.state;
			this.props.onSubmitFilter(filterData);
		}
	};

	ResetFilterForm = (event) => {
		event.preventDefault();
		let filterData = { page: 1 };
		this.props.onSubmitFilter(filterData, {});
	};

	handleChange = (sname, valueObj) => {
		let { filterData } = this.state;
		let value = valueObj.value || valueObj.id;
		if (sname === "keywords") value = valueObj.target.value;
		filterData[sname] = value;
		this.setState({ filterData: filterData });
	};

	render() {
		const { filterData, city_states } = this.state;
		const states =
			city_states && city_states.state ? city_states.state : [];
		const cities = city_states && city_states.city ? city_states.city : [];
		if (cities.length && states.length && !cities[0].city_state_name) {
			cities.map((v) => {
				if (v.state_id) {
					let state_name =
						states
							.filter((s) => +s.id === +v.state_id)
							.map((f) => f.name)[0] || "";
					let cityname = v.name;
					v.city_state_name = `${cityname}-${state_name}`;
				}
				return v;
			});
		}
		//console.log("city_states",filterData)
		return (
			<div className="search-wrap">
				<form onSubmit={this.submitFilterForm}>
					<ul className="search-flex">
						<li className="searchitems">
							<InputField
								inputProps={{
									id: "keywords",
									type: "text",
									value: filterData.keywords || "",
									name: "keywords",
									autoComplete: "off",
									placeholder: this.props.t("LIST.KEYWORDS"),
									label: this.props.t("LIST.KEYWORDS"),
								}}
								autoComplete="off"
								onChange={this.handleChange.bind(
									this,
									"keywords"
								)}
							/>
						</li>

						<li className="searchitems selectWidth form-group">
							<Select
								id="city"
								onChange={this.handleChange.bind(this, "city")}
								options={cities}
								name="city"
								placeholder={this.props.t("LIST.CITY")}
								value={cities.filter(
									({ id }) => id === filterData.city
								)}
								getOptionLabel={({ city_state_name }) =>
									city_state_name
								}
								getOptionValue={({ id }) => id}
							/>
						</li>
						<li className="searchitems form-group">
							<div>
								<button
									type="submit"
									className="btn btn-primary"
								>
									{this.props.t("LIST.SEARCH")}
								</button>
							</div>
						</li>

						<li className="searchitems form-group">
							<button
								type="reset"
								className="btn btn-default btn-reset"
								onClick={this.ResetFilterForm}
							>
								{this.props.t("LIST.RESET")}
							</button>
						</li>
					</ul>
				</form>
			</div>
		);
	}
}

export default withTranslation("ford")(SearchFord);
