import { LeadConstants } from "../constants/lead.constants";

export default function LeadReducer(state = {}, action) {
	switch (action.type) {
		case LeadConstants.SUB_LEAD_DETAILS_SUCCESS:
			return {
				success: true,
				subListDetails: action.resp,
				...state,
			};
		case LeadConstants.SUB_LEAD_DETAILS_FAILURE:
			return {
				success: false,
				subListDetails: {},
				...state,
			};
		case LeadConstants.GET_LEAD_DETAIL:
			return {
				...state,
				...action.resp,
			};
		case LeadConstants.GET_LEAD_DETAIL_ERR:
			return {
				...state,
			};
		case LeadConstants.IS_HISTORY_UPDATE:
			return {
				...state,
				is_history_update: action.resp,
			};
		case LeadConstants.DEALER_DSE_LIST_SUCCESS:
			return {
				success: true,
				dealer_dse_list: action.resp,
				...state,
			};
		case LeadConstants.DEALER_DSE_LIST_FAILURE:
			return {
				success: false,
				dealer_dse_list: {},
				...state,
			};
		case LeadConstants.IS_PUSHED_TO_DAILER:
			return {
				...state,
				is_pushed_to_dailer: action.resp,
			};
		default:
			return state;
	}
}
