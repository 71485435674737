import React from "react";
import InputField from "../../../elements/InputField";
import { connect } from "react-redux";
import { AuthActions } from "../../../../store/action";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import Captcha from "../../../common/Captcha";
import LanguageSelector from "../../../common/LanguageSelector";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
//import secureStorage from './../../../../config/encrypt';
import { default as config } from "./../../../../config/config";
import * as constant from "./../../../../config/constant";

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.child = React.createRef();
		this.state = {
			fields: {
				userId: "",
				password: "",
				captcha: "",
			},
			errors: {},
			submitted: false,
		};
	}

	/**
	 * Input Handler
	 * @param e (synthatic element)
	 */
	handleInputChange = async (e) => {
		let { fields, errors } = this.state;
		fields[e.target.name] = e.target.value;
		if (e.target.name === "userId") errors.userId = "";
		if (e.target.name === "password") errors.password = "";
		await this.setState({
			fields,
			errors,
		});
	};

	/**
	 * Login Submit Handler
	 */

	submitLogin = async (e) => {
		e.preventDefault();
		this.setState({ submitted: true });
		const { userId, password } = this.state.fields;
		if (await this.validateForm()) {
			this.props.actions
				.loginSuccess(userId, password)
				.then(async (resp) => {
					if (resp.status === 200) {
						//console.log("Success.....");
					}
				});
		}
	};

	captchaFieldHandler = (childData) => {
		let { fields, errors } = this.state;
		fields["captcha"] = childData;
		errors.captcha = "";
		this.setState({
			fields,
			errors,
		});
	};

	/**
	 * Form Validations
	 */

	validateForm = async function () {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

		this.child.resetCaptchaError(); // RESET CAPTCHA ERROR

		if (!fields["userId"].trim()) {
			formIsValid = false;
			errors["userId"] = this.props.t("LOGIN.ENTER_USER_ID");
		}

		if (!fields["password"].trim()) {
			formIsValid = false;
			errors["password"] = this.props.t("LOGIN.ENTER_PASSWORD");
		}
		if (fields["captcha"].trim() !== window.atob(this.props.captchaValue)) {
			formIsValid = false;
			errors["captcha"] = this.props.t("LOGIN.CAPTCHA_NOT_MATCHED");

			this.child.loadCaptchaImage(errors); //Load captcha again
		}
		await this.setState({
			errors: errors,
			submitted: formIsValid,
		});

		return formIsValid;
	};

	componentDidUpdate() {
		const { invalidLogin } = this.props;
		if (invalidLogin) {
			this.child.loadCaptchaImage(); //Load captcha again
		}
	}

	static getDerivedStateFromProps = (nextProps, prevState) => {
		if (nextProps.invalidLogin !== prevState.invalidLogin) {
			return { submitted: false };
		}
		return null;
	};

	render() {
		const { redirectTo } = this.props;
		const { submitted, errors } = this.state;
		let isLeadListAccess = constant.isUserHasRightsToAccessPage(
			"lead",
			"lead",
			"/lead-list"
		);
		if (redirectTo) {
			if (isLeadListAccess) return <Redirect to="/lead-list" />;
			else return <Redirect to="/dashboard" />;
		}

		return (
			<div className="container-fluid" id="homePage">
				<div className="loginwrap">
					<div className="row">
						<div className="col-sm-6 col-md-4 col-sm-offset-4 col-sm-offset-6">
							<div className="mrg-b15 text-center">
								<img
									src={config.constants.LOGIN_PAGE_LOGO}
									className="brand-logo"
									alt="Loan Box"
								/>
							</div>
							<div className="card">
								<div className="card-body pd-30">
									<div className="signUp-container text-left">
										<h1 className="login-h1">
											{this.props.t("LOGIN.LOGIN")}
										</h1>
										<p className="login-p">
											{this.props.t("LOGIN.LOGIN_TITLE")}
										</p>
										<form
											method="post"
											className="text-left os-animation animated fadeInDown"
											onSubmit={this.submitLogin}
										>
											<div className="loginbox">
												<div
													className={
														"form-group" +
														(!submitted &&
														this.state.errors.userId
															? " has-error"
															: "")
													}
												>
													<InputField
														inputProps={{
															id: "user_name",
															type: "text",
															name: "userId",
															label: this.props.t(
																"LOGIN.USERNAME"
															),
															placeholder: this.props.t(
																"LOGIN.USERNAME_PLACEHOLDER"
															),
															value: this.state
																.fields.userId,
															dataerror:
																errors.userId ||
																"",
															autoFocus: true,
														}}
														onChange={
															this
																.handleInputChange
														}
													/>
												</div>
												<div
													className={
														"form-group" +
														(!submitted &&
														this.state.errors
															.password
															? " has-error"
															: "")
													}
												>
													<InputField
														inputProps={{
															id: "password",
															type: "password",
															name: "password",
															label: this.props.t(
																"LOGIN.PASSWORD"
															),
															placeholder: this.props.t(
																"LOGIN.PASSWORD_PLACEHOLDER"
															),
															value: this.state
																.fields
																.password,
															dataerror:
																errors.password ||
																"",
														}}
														onChange={
															this
																.handleInputChange
														}
													/>
												</div>
												<Captcha
													onKeyPressCaptcha={
														this.captchaFieldHandler
													}
													childRef={(Ref) =>
														(this.child = Ref)
													}
													errors={errors}
												/>
												{/* <div className="row">
													<div className="col-sm-6"></div>
													<div className="col-sm-6 text-right">
														<label
															className="forgotP"
															id="forgotPassword"
														>
															<Link
																to={`/forgot-password`}
																data-key="forgot"
															>
																{this.props.t(
																	"LOGIN.FORGOT_PASSWORD"
																)}
															</Link>
														</label>
													</div>
												</div> */}
												<div className="">
													{!submitted ? (
														<button
															type="submit"
															onSubmit={
																this.submitLogin
															}
															className="btn btn-primary btn-block"
														>
															{this.props.t(
																"LOGIN.LOGIN"
															)}
														</button>
													) : (
														<button
															type="button"
															className="btn btn-primary btn-block"
														>
															{this.props.t(
																"LOGIN.PLEASE_WAIT"
															)}
														</button>
													)}
												</div>
											</div>
											{/* </div> */}
										</form>
									</div>
								</div>
							</div>
							<footer>
								<LanguageSelector />
							</footer>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth.auth,
		loggingIn: state.auth.loggingIn,
		redirectTo: state.auth.redirectTo,
		captchaImage: state.auth.captchaImage,
		captchaValue: state.auth.captchaValue,
		captchaError: state.auth.captchaError,
		invalidLogin: state.auth.invalidLogin,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			loginSuccess: bindActionCreators(AuthActions.login, dispatch),
		},
	};
};

export default withTranslation("common")(
	withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
);
