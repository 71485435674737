/* eslint-disable */
import React, { Component } from "react";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ConfigAction } from "./../../../store/action";
import { bindActionCreators } from "redux";
import ConfirmPopUp from "../../common/ConfirmPopUp";
import InputField from "../../elements/InputField";
class MyactiveForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			agent_list: [],
			agent_id: 0,
			disablebtn: true,
			myActivePopOver: false,
			agent_name: "",
			comment: "",
		};
	}
	componentDidMount = async () => {
		await this.getConfigData();
	};

	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		if (props.agent_list && props.agent_list !== state.agent_list) {
			updateProps["agent_list"] = props.agent_list;
		}
		return updateProps;
	}

	handleChange = (sname, ovalue) => {
		let stateObj = {};
		if (sname === "comment") {
			stateObj = { comment: ovalue.target.value };
		} else {
			stateObj = {
				agent_id: ovalue ? ovalue.value : 0,
				agent_name: ovalue ? ovalue.label : "",
				disablebtn: ovalue && ovalue.value ? false : true,
			};
		}
		this.setState(stateObj);
	};

	getConfigData = async () => {
		const requestBody = { config_data: ["agent_list"] };
		this.props.actions.getConfigData(requestBody);
	};

	submitMyActive = (event) => {
		event.preventDefault();
		this.setState({ myActivePopOver: true });
	};

	confirmYes = () => {
		this.setState({ myActivePopOver: false });
		let { agent_id, agent_name, comment } = this.state;
		this.props.updateMyActive("reassign", agent_id, agent_name, comment);
		this.props.modalClose();
	};

	confirmNo = () => {
		this.setState({ myActivePopOver: false });
	};

	render() {
		const {
			agent_list,
			agent_id,
			disablebtn,
			myActivePopOver,
			agent_name,
			comment,
		} = this.state;
		let { current_agent_id, current_agent_name } = this.props;
		return (
			<div>
				<div className="modal-header">
					<div className="modal-title h4">
						{current_agent_name
							? this.props.t("MYACTIVE.HEADING")
							: this.props.t("MYACTIVE.ASSIGN_HEADING")}
					</div>
				</div>
				<form>
					<div className="pad-15">
						<div className="row">
							{myActivePopOver && (
								<ConfirmPopUp
									onConfirm={() => this.confirmYes()}
									onReject={() => this.confirmNo()}
									message={
										current_agent_name
											? `${this.props.t(
													"MYACTIVE.ALERT_REMOVE_MYACTIVE"
											  )} "${current_agent_name}" ${this.props.t(
													"MYACTIVE.ALERT_REASSIGN"
											  )} "${agent_name}" ?`
											: `${this.props.t(
													"MYACTIVE.ALERT_ASSIGN"
											  )} "${agent_name}"`
									}
								/>
							)}
							<div className="form-group col-sm-12">
								<label>
									{this.props.t("MYACTIVE.AGENT")}
									<span className="text-danger">*</span>
								</label>

								<Select
									id="agent_id"
									onChange={this.handleChange.bind(
										this,
										"agent_id"
									)}
									options={agent_list.filter(
										(v) => v.value != current_agent_id
									)}
									name="agent_id"
									placeholder={this.props.t("MYACTIVE.AGENT")}
									value={
										agent_list &&
										agent_list.filter(
											({ value }) => value === agent_id
										)
									}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
									isClearable
								/>
							</div>
							<div className="form-group col-sm-12">
								<InputField
									inputProps={{
										id: "comment",
										type: "text",
										name: "comment",
										autoComplete: "off",

										placeholder: this.props.t(
											"MYACTIVE.COMMENT"
										),
										label: this.props.t("MYACTIVE.COMMENT"),
										value: comment || "",
									}}
									required
									autoComplete="off"
									onChange={this.handleChange.bind(
										this,
										"comment"
									)}
								/>
							</div>
						</div>
						<div className="actionFooter">
							<button
								type="button"
								className="btn btn-primary-outline mrg-r15"
								onClick={this.props.modalClose}
							>
								{this.props.t("MYACTIVE.CANCEL")}
							</button>
							<button
								type="button"
								className="btn btn-primary"
								onClick={this.submitMyActive}
								disabled={disablebtn}
							>
								{this.props.t("MYACTIVE.UPDATE")}
							</button>
						</div>
					</div>
				</form>
			</div>
		);
	}
}
const mapStateToProps = ({ config, inventory }) => {
	return {
		agent_list:
			config.agent_list instanceof Array
				? config.agent_list.map((e) => {
						return {
							label: e.name,
							value: e.id,
						};
				  })
				: [],
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
		},
	};
};
export default withTranslation("lead_list")(
	connect(mapStateToProps, mapDispatchToProps)(MyactiveForm)
);
