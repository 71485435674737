import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { AuditAction } from "../../../store/action";
import { bindActionCreators } from "redux";
import Select from "react-select";
import { toast } from "react-toastify";
import InputField from "../../elements/InputField";

const AuditPriority = [
	{ value: "LOW", label: "Low" },
	{ value: "MEDIUM", label: "Medium" },
	{ value: "HIGH", label: "High" },
];

class ChangePriority extends Component {
	constructor(props) {
		super(props);
		this.state = {
			actionType: this.props.actionType,
			selectedIds: this.props.selectedIds,
			audit_priority: "",
			errors: {},
            formData:{}
		};
	}
	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		return updateProps;
	}
	componentDidMount = () => {};

	handleChange = (sname, event) => {
        let {formData,errors} = this.state;
		const { value } = sname === 'audit_priority' ? event : event.target;
        formData[sname] = value;
        errors[sname]=''
		this.setState({ formData: formData, errors: errors });
	};
	closeModal = () => {
		if (typeof this.props.modalClose === "function") {
			this.props.modalClose();
		}
	};
	updateStatus = async () => {
		const { selectedIds, actionType, errors, formData } = this.state;
		if (formData && !formData.audit_priority) {
			errors["audit_priority"] = this.props.t("SAVE.REQUIRED_FIELD");
			this.setState({ errors });
			return;
		} else {
			let postData = {
				auditlead_id: selectedIds,
				priority: formData.audit_priority,
                comment: formData.comment
			};
			let response = await this.props.actions.changePriority(postData);
			if (response && response.status) {
				this.props.updateList();
				toast.success(response.response);
			}
		}
	};

	render() {
		const { auditLeadId, actionType, formData, errors } = this.state;
		return (
			<div className="">
                <div className="row">
                    <div className=" col-sm-8 form-group">
                    <h6>{this.props.t("SEARCH_AUDIT.CHANGE_PRIORITY_MSG")}</h6>
                    </div>
                    <div className=" col-sm-3 form-group">
                    <Select
						id="audit_priority"
						onChange={this.handleChange.bind(
							this,
							"audit_priority"
						)}
						isClearable
						options={AuditPriority}
						name="audit_priority"
						placeholder={this.props.t(
							"SEARCH_AUDIT.AUDIT_PRIORITY"
						)}
						value={
							formData && formData.audit_priority
								? AuditPriority.filter(
										({ value }) => value === formData.audit_priority
								  )
								: ""
						}
						getOptionLabel={({ label }) => label}
						getOptionValue={({ value }) => value}
					/>
					<span className="text-danger">
						{errors.audit_priority || ""}
					</span>
                    </div>
                </div>
                <div className="row">
                    <div className=" col-sm-11 form-group">
                    <InputField
						inputProps={{
							id: "comment",
							type: "text",
							value: formData.comment || '',
							name: "comment",
							autoComplete: "off",
                            placeholder:'comment'
						}}
						autoComplete="off"
						onChange={this.handleChange.bind(this, "comment")}
					/>
					</div>
                </div>
                
				<div className="add-to-dialer-footer">
					<div className="clearfix">
						<div className="col-sm-6">
							<button
								type="button"
								className="btn btn-primary"
								onClick={this.updateStatus}
							>
								{this.props.t("SEARCH_AUDIT.SUBMIT")}
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ config }) => {
	return {};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			changePriority: bindActionCreators(
				AuditAction.changePriority,
				dispatch
			),
		},
	};
};
export default withTranslation("audit")(
	connect(mapStateToProps, mapDispatchToProps)(ChangePriority)
);
