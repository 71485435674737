import React, { Component } from "react";
import Select from "react-select";
import InputField from "../../elements/InputField";
import Radiobox from "../../elements/Radiobox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { InventoryAction, ConfigAction } from "./../../../store/action";
import { bindActionCreators } from "redux";
import { default as config } from "../../../config/config";

const ClickOutHandler = require("react-onclickout");
const searchBy = [
	{ value: "all", label: "All Cases" },
	{ value: "myactive", label: "My Active" },
];
const dateType = [
	{ value: "followup_date", label: "Follow up Date" },
	{ value: "created_date", label: "Created Date" },
	{ value: "updated_date", label: "Last Updated Date" },
	{ value: "updated_date_crm", label: "Last Updated Date(CRM)" },
	{ value: "updated_date_lms", label: "Last Updated Date(LMS)" },
	{ value: "purchase_date", label: "Purchase Date" },
	{ value: "booking_date", label: "Booking Date" },
	{ value: "td_requested_date", label: "TD Requested Date" },
	{ value: "td_scheduled_date", label: "TD Scheduled Date" },
	{ value: "td_completed_date", label: "TD Completed Date" },
	{
		value: "stnk_follow_up_date",
		label: config.constants.STNK_FOLLOW_UP_LABEL,
	},
	{ value: "buy_time", label: "Buy Time" },
];
const yesNo = [
	{ value: "1", label: "Yes" },
	{ value: "0", label: "No" },
];
const Call_Attempts = [
	{ value: "0", label: "0" },
	{ value: "1", label: "1" },
	{ value: "2", label: "2" },
	{ value: "3", label: "3" },
	{ value: "4", label: "4" },
	{ value: "5", label: "5" },
	{ value: "6", label: "6" },
	{ value: "7", label: "7" },
	{ value: "8", label: "8" },
	{ value: "9", label: "9" },
	{ value: "10", label: "10" },
	{ value: "11", label: "11" },
	{ value: "gt11", label: " > 11" },
];
const Saleable = [{ value: "1", label: "Enable" }];
const LMS_FLAG = [
	{ value: "rf", label: "RF" },
	{ value: "ncf", label: "NCF" },
	{ value: "eaf", label: "EAF" },
];
const NotConnectable = [{ value: "1", label: "Enable" }];
class SearchLead extends Component {
	constructor(props) {
		super(props);
		this.state = {
			date: new Date(),
			searchMobile: false,
			advanceSearchEnable: false,
			LeadInfoData: {},
			filterData: {},
			tempFilterData: {},
			filterDataText: {},
			dealer_visit_status_list: [],
			call_status_list: [],
			source_list: [],
			dealer_status_list: [],
			dealer_list: [],
			datePicker: {
				from_date_disabled: true,
				to_date_disabled: false,
			},
			city_list: [],
			filter_city_list: [],
			model_status_list: [],
			status_list: [],
			sub_status_list: [],
			all_sub_status: [],
			test_drive_status_list: [],
			make_model: [],
			update_filter: false,
			lms_status_list: [],
			gm_id: [],
			dse_suggestions: [],
			dse_id: [],
			ld_status: [],
			attribution_status_list: [],
			billing_status_list: [],
			agent_list: [],
			Bought_Makes: [],
			make_list: [],
			model_list: [],
			lead_type_list: [],
			cluster_list: [],
			cluster_city_map: new Map(),
			temp_gm_id: [],
		};
		this.handleOutsideClick = this.handleOutsideClick.bind(this);
	}
	componentDidMount = async () => {
		await this.getConfigData();
		//await this.props.actions.getMMV();
		this.handlePredisableInputs();
	};
	handleOutsideClick = (e) => {
		// ignore clicks on the component itself

		if (this.node && this.node.contains(e.target)) {
			return;
		}
		if (this.node && !this.node.contains(e.target)) {
			this.ToogleAdvanceSearch();
		}
	};
	onClickOut = (e) => {
		this.setState({ advanceSearchEnable: false });
	};
	handlePredisableInputs = () => {
		// let { tempFilterData } = this.state;

		this.setState({
			advanceSearchEnable: false,
			searchMobile: false,
			// tempFilterData: tempFilterData,
			// datePicker: {
			// 	from_date_disabled: false,
			// 	to_date_disabled: false,
			// },
		});
	};

	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		if (
			props.call_status_list &&
			props.call_status_list !== state.call_status_list
		) {
			updateProps["call_status_list"] = props.call_status_list;
		}
		if (props.dealer_list && props.dealer_list !== state.dealer_list) {
			updateProps["dealer_list"] = props.dealer_list;
		}
		if (
			props.dealer_status_list &&
			props.dealer_status_list !== state.dealer_status_list
		) {
			updateProps["dealer_status_list"] = props.dealer_status_list;
		}
		if (
			props.dealer_visit_status_list &&
			props.dealer_visit_status_list !== state.dealer_visit_status_list
		) {
			updateProps["dealer_visit_status_list"] =
				props.dealer_visit_status_list;
		}
		if (
			props.model_status_list &&
			props.model_status_list !== state.model_status_list
		) {
			updateProps["model_status_list"] = props.model_status_list;
		}
		if (props.source_list && props.source_list !== state.source_list) {
			updateProps["source_list"] = props.source_list;
		}
		if (props.status_list && props.status_list !== state.status_list) {
			updateProps["status_list"] = props.status_list;
		}
		if (
			props.lms_status_list &&
			props.lms_status_list !== state.lms_status_list
		) {
			updateProps["lms_status_list"] = props.lms_status_list;
		}
		if (
			props.sub_status_list &&
			props.sub_status_list !== state.all_sub_status
		) {
			updateProps["all_sub_status"] = props.sub_status_list;
		}
		if (
			props.test_drive_status_list &&
			props.test_drive_status_list !== state.test_drive_status_list
		) {
			updateProps["test_drive_status_list"] =
				props.test_drive_status_list;
		}
		if (props.city_list && props.city_list !== state.city_list) {
			updateProps["city_list"] = props.city_list;
		}
		if (props.make_model && props.make_model !== state.make_model) {
			updateProps["make_model"] = props.make_model;
		}
		// && props.update_filter && state.update_filter
		if (props.gm_id && props.gm_id !== state.gm_id) {
			updateProps["gm_id"] = props.gm_id;
			updateProps["temp_gm_id"] = [
				{ label: "ALL", value: "ALL" },
				...props.gm_id,
			];
		}
		if (props.dse_id && props.dse_id !== state.dse_id) {
			updateProps["dse_id"] = props.dse_id;
		}
		if (props.ld_status && props.ld_status !== state.ld_status) {
			updateProps["ld_status"] = props.ld_status;
		}
		if (props.filterData && props.filterData && props.filterData.toUpdate) {
			props.filterData.toUpdate = false;
			updateProps["tempFilterData"] = { ...props.filterData };
			if (props.filterData.date_type) {
				updateProps["datePicker"] = {
					from_date_disabled: false,
					to_date_disabled: false,
				};
			} else {
				updateProps["datePicker"] = {
					from_date_disabled: true,
					to_date_disabled: false,
				};
			}

			if (!props.filterData.cluster_list || !props.filterData.city_id) {
				updateProps["filter_city_list"] = [];
			}
		}

		if (
			props.filterDataText &&
			props.filterDataText !== state.filterDataText &&
			props.filterDataText.toUpdate
		) {
			props.filterDataText.toUpdate = false;
			updateProps["filterDataText"] = { ...props.filterDataText };
		}
		if (
			props.attribution_status_list &&
			props.attribution_status_list !== state.attribution_status_list
		) {
			updateProps["attribution_status_list"] =
				props.attribution_status_list;
		}
		if (
			props.billing_status_list &&
			props.billing_status_list !== state.billing_status_list
		) {
			updateProps["billing_status_list"] = props.billing_status_list;
		}
		if (props.agent_list && props.agent_list !== state.agent_list) {
			updateProps["agent_list"] = props.agent_list;
		}
		if (props.Bought_Makes && props.Bought_Makes !== state.Bought_Makes) {
			updateProps["Bought_Makes"] = props.Bought_Makes;
		}
		if (props.make_list && props.make_list !== state.make_list) {
			updateProps["make_list"] = props.make_list;
		}
		if (props.model_list && props.model_list !== state.model_list) {
			updateProps["model_list"] = props.model_list;
		}
		if (
			props.lead_type_list &&
			props.lead_type_list !== state.lead_type_list
		) {
			updateProps["lead_type_list"] = props.lead_type_list;
		}
		if (props.cluster_list && props.cluster_list !== state.cluster_list) {
			updateProps["cluster_list"] = props.cluster_list;
			for (let eachCluster of props.cluster_list) {
				state.cluster_city_map.set(
					eachCluster.value,
					eachCluster.mapped_city
				);
			}
		}

		return updateProps;

		// return {
		// 	filterData: props.filterData,
		// 	filterDataText: props.filterDataText,
		// };
	}
	searchClose = () => {
		this.setState({ searchMobile: false });
	};
	searchShow = () => {
		this.setState({ searchMobile: true });
	};
	ToogleAdvanceSearch = () => {
		if (!this.state.advanceSearchEnable) {
			// attach/remove event handler
			document.addEventListener("click", this.handleOutsideClick, false);
		} else {
			document.removeEventListener(
				"click",
				this.handleOutsideClick,
				false
			);
		}
		this.setState({ advanceSearchEnable: !this.state.advanceSearchEnable });
	};
	submitFilterForm = (event, reset = false) => {
		if (event) event.preventDefault();
		this.setState({ advanceSearchEnable: false });
		if (typeof this.props.onSubmitFilter === "function") {
			let {
				filterData,
				filterDataText,
				tempFilterData,
				gm_id,
			} = this.state;
			if (tempFilterData.start_date && !tempFilterData.end_date) {
				if (
					tempFilterData.date_type === "followup_date" &&
					tempFilterData.start_date > new Date()
				)
					tempFilterData.end_date = tempFilterData.start_date;
				else tempFilterData.end_date = new Date();
				this.setState({ tempFilterData });
			}
			if (reset) {
				tempFilterData = {};
				filterDataText = {};
			}
			filterData = { ...tempFilterData };
			if (filterData.gm_id)
				filterData.gm_id = filterData.gm_id
					.filter((f) => f !== "ALL")
					.map((e) => e);
			if (filterData.is_saleable && +filterData.is_saleable === 1) {
				let excludeGmid = filterData.gm_id ? filterData.gm_id : [0];
				let gmidlist = gm_id.length
					? gm_id
							.filter(
								(v) =>
									v.value && !excludeGmid.includes(+v.value)
							)
							.map((v) => v.value)
					: [];
				filterData.exclude_gm_id = gmidlist;
			}
			if(filterData && filterData.gm_id && filterData.gm_id.length === 0) delete filterData.gm_id;

			this.props.onSubmitFilter(filterData, filterDataText, reset, reset);
		}
	};

	ResetFilterForm = (event) => {
		///////////////////function to reset filter form///////////////
		event.preventDefault();
		this.handlePredisableInputs();
		this.submitFilterForm(null, true);
		// this.props.onSubmitFilter(filterData, {});
	};
	handleDependentActions = (sname, ovalue = null) => {
		let { datePicker, tempFilterData, sub_status_list } = this.state;
		let filter_model = [];
		switch (sname) {
			case "date_type":
				datePicker.from_date_disabled = false;
				if (!tempFilterData.date_type) {
					datePicker.from_date_disabled = true;
					//datePicker.to_date_disabled = true;
					delete tempFilterData.start_date;
					delete tempFilterData.end_date;
				}
				delete tempFilterData.start_date;
				delete tempFilterData.end_date;
				break;
			case "start_date":
				datePicker.to_date_disabled = false;
				delete tempFilterData.end_date;
				break;
			case "end_date":
				break;
			case "status_id":
				if (ovalue instanceof Array) {
					sub_status_list = [
						...[].concat.apply(
							[],
							ovalue.map((e) => e.sub_status)
						),
					];
				}
				break;
			default:
		}
		this.setState({
			datePicker: datePicker,
			sub_status_list: sub_status_list,
		});
	};
	addCluster = (cluster_id) => {
		let {
			city_list,
			filter_city_list,
			tempFilterData,
			filterDataText,
		} = this.state;
		///removes cluster id from selection and removed all city of that cluster
		const city_to_be_added = city_list.filter((e) =>
			cluster_id.includes(e.cluster_id)
		);

		filter_city_list.push(...city_to_be_added);
		if (!(tempFilterData["city_id"] instanceof Array))
			tempFilterData["city_id"] = [];
		if (!filterDataText["city_id"]) filterDataText["city_id"] = "";

		tempFilterData["city_id"].push(...city_to_be_added.map((e) => e.value));
		tempFilterData["city_id"] = Array.from(
			new Set(tempFilterData["city_id"])
		);
		//////////get all labels of city id//////////
		const allAvailableLabels = filter_city_list
			.filter((e) => tempFilterData["city_id"].includes(e.value))
			.map((e) => e.label);
		filterDataText["city_id"] = filterDataText["city_id"].concat(
			filterDataText["city_id"].length ? "," : "",
			city_to_be_added.map((e) => e.label).join(",")
		);
		let cityUniqeSet = new Set(filterDataText["city_id"].split(","));
		filterDataText["city_id"] = Array.from(cityUniqeSet);
		filterDataText["city_id"] = filterDataText["city_id"].filter((e) =>
			allAvailableLabels.includes(e)
		);
		filterDataText["city_id"] = Array.from(
			new Set(filterDataText["city_id"])
		).join(",");
		this.setState({
			filter_city_list: filter_city_list,
			tempFilterData: tempFilterData,
			filterDataText: filterDataText,
		});
	};
	removeCluster = (cluster_id) => {
		let {
			city_list,
			filter_city_list,
			tempFilterData,
			filterDataText,
		} = this.state;
		////add cluster id andaddall city of that cluster
		const city_to_be_deleted = city_list.filter((e) =>
			cluster_id.includes(e.cluster_id)
		);
		const city_to_be_deleted_id = city_to_be_deleted.map((e) => e.value);
		const city_to_be_deleted_label = city_to_be_deleted.map((e) => e.label);

		filter_city_list = filter_city_list.filter(
			(e) => !city_to_be_deleted_id.includes(e.value)
		);

		if (tempFilterData["city_id"] instanceof Array) {
			tempFilterData["city_id"] = tempFilterData["city_id"].filter(
				(e) => !city_to_be_deleted_id.includes(e)
			);
		}
		if (filterDataText["city_id"]) {
			let text = filterDataText["city_id"]
				.split(",")
				.filter((e) => !city_to_be_deleted_label.includes(e))
				.join(",");
			filterDataText["city_id"] = text;
		}

		this.setState({
			filter_city_list: filter_city_list,
			tempFilterData: tempFilterData,
			filterDataText: filterDataText,
		});
	};
	handleChange = (sname, ovalue, event) => {
		const { target } = ovalue ? ovalue : {};
		let {
			tempFilterData,
			filterDataText,
			model_status_list,
			test_drive_status_list,
			dealer_visit_status_list,
			call_status_list,
			source_list,
			ld_status,
			filter_city_list,
			cluster_list,
		} = this.state;
		let updateCityList = false;
		if (target && target.value) {
			///////////////text box case////////////
			const { value, type } = target;
			tempFilterData[sname] = value;
			filterDataText[sname] = value;

			if (type === "radio") {
				filterDataText[sname] = "";

				/////////////////handle radio box name////////////
				switch (sname) {
					case "model_status_id":
						filterDataText[sname] =
							(model_status_list instanceof Array &&
								model_status_list
									.filter((e) => +e.value === +value)
									.map((e) => e.label)
									.join("")) ||
							"";
						break;
					case "test_drive_type":
						filterDataText[sname] =
							(test_drive_status_list instanceof Array &&
								test_drive_status_list
									.filter((e) => +e.value === +value)
									.map((e) => e.label)
									.join("")) ||
							"";
						break;
					case "dealer_visit_status":
						filterDataText[sname] =
							(dealer_visit_status_list instanceof Array &&
								dealer_visit_status_list
									.filter((e) => +e.value === +value)
									.map((e) => e.label)
									.join("")) ||
							"";
						break;
					case "call_status":
						filterDataText[sname] =
							(call_status_list instanceof Array &&
								call_status_list
									.filter((e) => +e.value === +value)
									.map((e) => e.label)
									.join("")) ||
							"";
						break;
					case "finance_required":
						filterDataText[sname] =
							(yesNo instanceof Array &&
								yesNo
									.filter((e) => +e.value === +value)
									.map((e) => e.label)
									.join("")) ||
							"";
						break;
					case "exchange_required":
						filterDataText[sname] =
							(yesNo instanceof Array &&
								yesNo
									.filter((e) => +e.value === +value)
									.map((e) => e.label)
									.join("")) ||
							"";
						break;
					case "source_id":
					case "customer_source_id":
						filterDataText[sname] =
							(source_list instanceof Array &&
								source_list
									.filter((e) => +e.value === +value)
									.map((e) => e.label)
									.join("")) ||
							"";
						break;
					case "ld_status":
						filterDataText[sname] =
							(ld_status instanceof Array &&
								ld_status
									.filter((e) => e.value === value)
									.map((e) => e.label)
									.join("")) ||
							"";
						break;
					case "is_saleable":
						filterDataText[sname] =
							(Saleable instanceof Array &&
								Saleable.filter((e) => +e.value === +value)
									.map((e) => e.label)
									.join("")) ||
							"";
						break;
					case "is_not_connectable":
						filterDataText[sname] =
							(NotConnectable instanceof Array &&
								NotConnectable.filter(
									(e) => +e.value === +value
								)
									.map((e) => e.label)
									.join("")) ||
							"";
						break;
					case "lms_flag":
						filterDataText[sname] =
							(LMS_FLAG instanceof Array &&
								LMS_FLAG.filter((e) => e.value === value)
									.map((e) => e.label)
									.join("")) ||
							"";
						break;
					default:
				}
			}
		} else if (ovalue && ovalue.value) {
			///////////////////select case////////////////
			tempFilterData[sname] = ovalue.value;
			filterDataText[sname] = ovalue.label;
		} else if (sname instanceof Date) {
			////////////////date picker case////////////////
			tempFilterData[ovalue] = new Date(sname);
			sname = ovalue;
		} else if (ovalue instanceof Array && ovalue.length > 0) {
			//////////////multiselect case////////////////////

			///////fetch new value added to list
			if (["city_id", "cluster_list"].includes(sname)) {
				if (sname === "city_id") {
					///on remove city id cluster should be removed
					///get removed city id from old city id stock
					// tempFilterData[sname]
					////city that are currently selected are represented by ovalue
					const selected_cluster_id = new Set(
						ovalue.map((e) => e.cluster_id)
					);
					if (
						[1, 0].includes(selected_cluster_id.size) &&
						selected_cluster_id.has(-1)
					) {
						/////thr is no cluster selected remove all clusters

						delete tempFilterData["cluster_list"];
						delete filterDataText["cluster_list"];
					} else if (
						tempFilterData["cluster_list"] &&
						tempFilterData["cluster_list"].length
					) {
						/////this only works iff cluster_list is selected

						// cluster_list.filter(e=>e.id)
						const removedCluster = tempFilterData[
							"cluster_list"
						].filter((e) => !selected_cluster_id.has(e));
						tempFilterData["cluster_list"] = tempFilterData[
							"cluster_list"
						].filter((e) => selected_cluster_id.has(e));

						///get labels to feed cluster_list
						filterDataText["cluster_list"] = cluster_list
							.filter((e) => selected_cluster_id.has(e.value))
							.map((e) => e.label)
							.join(",");
						this.removeCluster(removedCluster);
					}
				} else {
					////cluster
					///we can add the cluster or remove it

					let addedCluster = ovalue.filter(
						(e) => !(tempFilterData[sname] || []).includes(e.value)
					);
					// let removeCluster = ovalue.filter(e=>(tempFilterData[sname] instanceof Array && !tempFilterData[sname].includes(e)));
					if (addedCluster.length)
						this.addCluster(addedCluster.map((e) => e.value));
					// ovalue.map(e=>e.cluster_id)
					////get all selected cluster id
					let allSelectedCluster = ovalue.map((e) => e.value);

					let removedCluster = (tempFilterData[sname] || []).filter(
						(e) => !allSelectedCluster.includes(e)
					);

					if (removedCluster.length)
						this.removeCluster(removedCluster);

					// for()
				}
			}
			if (sname === "gm_id") {
				ovalue = this.getMultiselectedValue(ovalue, event);
			}
			tempFilterData[sname] = ovalue.map((e) => e.value);
			filterDataText[sname] = ovalue.map((e) => e.label).join(",");
		} else if (ovalue && ovalue.id) {
			tempFilterData[sname] = ovalue.id;
			filterDataText[sname] = ovalue.make ? ovalue.make : ovalue.label;
		} else {
			updateCityList = true;
			////////////////delete request////////////////
			if (sname === "cluster_list") {
				delete tempFilterData["city_id"];
				delete filterDataText["city_id"];
				filter_city_list = [];
			} else if (sname == "city_id") {
				filter_city_list = [];
				delete tempFilterData["cluster_list"];
				delete filterDataText["cluster_list"];
			}
			delete tempFilterData[sname];
			delete filterDataText[sname];
		}
		/////////////////////default/Eveyrun Case////////////////////////
		if (["make_model"].includes(sname)) {
			// if (tempFilterData[sname]) {
			// 	tempFilterData["make_id"] = ovalue.mk_id;
			// 	tempFilterData["model_id"] = ovalue.value;
			// } else {
			// 	["make_id", "model_id"].forEach(
			// 		(e) => delete tempFilterData[e]
			// 	);
			// }
		}
		if (!tempFilterData[sname]) delete tempFilterData[sname];

		if (
			[
				"date_type",
				"start_date",
				"end_date",
				"status_id",
				"cluster_list",
				"city_id",
			].includes(sname)
		)
			this.handleDependentActions(sname, ovalue);
		// this.handleFilterDataText(sname,tempFilterData[sname]);
		// this.props.onUpdateFilter(tempFilterData);
		if (ovalue instanceof Array && sname === "gm_id")
			this.props.showHidePriorityPushButton(tempFilterData[sname]);

		let obj = { filter_city_list: filter_city_list };
		if (!updateCityList) obj = {};
		this.setState({
			tempFilterData: tempFilterData,
			filterDataText: filterDataText,
			...obj,
		});
	};
	// get config
	getConfigData = async () => {
		const requestBody = {
			config_data: [
				"status_list",
				"model_status_list",
				"city_state_list",
				"model_status_list",
				"test_drive_status_list",
				"dealer_visit_status_list",
				"call_status_list",
				"source_list",
				"dealer_status_list",
				"dealer_list",
				"lms_status_list",
				"gm_campaign_process_list",
				"dse_list",
				"ld_status",
				"attribution_status_list",
				"billing_status_list",
				"agent_list",
				"gm_campaign_list",
				"lead_type_list",
				"cluster_list",
			],
		};
		this.props.actions.getConfigData(requestBody);
	};
	fetchSuggestions = ({ value }) => {
		const inputValue = value.trim().toLowerCase();
		const inputLength = inputValue.length;
		const { dse_id } = this.state;
		const suggestedValues =
			inputLength === 0
				? []
				: dse_id.filter((e) =>
						e.label.toLowerCase().includes(inputValue)
				  );

		this.setState({
			dse_suggestions: suggestedValues,
		});
	};
	onSuggestionCLear = () => {
		this.setState({
			dse_suggestions: [],
		});
	};
	getSuggestedValue = (value) => {
		const target = {
			target: {
				value: value.label,
			},
		};

		this.handleChange("dse_name", target);
	};

	customFilter(option, searchText) {
		if (
			option.data.label
				.toLowerCase()
				.includes(searchText.toLowerCase()) ||
			option.data.mobile.toLowerCase().includes(searchText.toLowerCase())
		) {
			return true;
		} else {
			return false;
		}
	}

	getMultiselectedValue = (value, event) => {
		const { temp_gm_id } = this.state;
		let selectedVal = [];
		if (event.action === "select-option" && event.option.value === "ALL") {
			selectedVal = temp_gm_id;
		} else if (
			event.action === "deselect-option" &&
			event.option.value === "ALL"
		) {
			selectedVal = [];
		} else if (
			event.action === "deselect-option" &&
			event.option.value !== "ALL"
		) {
			selectedVal = value.filter((f) => f.value !== "ALL").map((o) => o);
		} else if (value.length === temp_gm_id.length - 1) {
			selectedVal = temp_gm_id;
		} else {
			selectedVal = value.map((o) => o);
		}
		return selectedVal;
	};

	render() {
		let {
			model_status_list,
			status_list,
			sub_status_list,
			test_drive_status_list,
			dealer_visit_status_list,
			call_status_list,
			source_list,
			dealer_list,
			tempFilterData,
			datePicker,
			city_list,
			make_model,
			lms_status_list,
			gm_id,
			dse_id,
			ld_status,
			attribution_status_list,
			billing_status_list,
			agent_list,
			Bought_Makes,
			make_list,
			model_list,
			lead_type_list,
			cluster_list,
			filter_city_list,
			temp_gm_id,
		} = this.state;

		let dse_id_options = [];
		dse_id_options =
			tempFilterData.dealer_id && dse_id.length
				? dse_id.filter((v) =>
						tempFilterData.dealer_id.includes(v.dealer_id)
				  )
				: dse_id;
		let { authUser } = this.props;
		let myactiveOptions =
			authUser && (authUser.role_id === 1 || authUser.role_id === 5)
				? searchBy
						.concat(agent_list)
						.filter((v) => v.value != "myactive")
				: authUser.role_id === 3
				? searchBy
				: searchBy.filter((v) => v.value != "myactive");
		let filter_Model = [];
		if (tempFilterData && tempFilterData.make_id) {
			filter_Model = model_list.filter(
				(e) => Number(e.mk_id) === Number(tempFilterData.make_id)
			);
		}
		if (!(filter_city_list instanceof Array && filter_city_list.length)) {
			filter_city_list = city_list;
		}

		return (
			<>
				<div
					className={
						this.state.searchMobile === true
							? "search-wrap open"
							: "search-wrap"
					}
				>
					<div className="searchClose">
						<span onClick={this.searchClose}>
							<i className="ic-arrow_back-black-24dp"></i>
						</span>
						Filters
						<span
							className="closefilter"
							onClick={this.searchClose}
						>
							<i className="ic-close-24px"></i>
						</span>
					</div>
					<form onSubmit={this.submitFilterForm}>
						<ul className="search-flex">
							<li className="searchitems selectWidth form-group">
								<Select
									id="my_active"
									onChange={this.handleChange.bind(
										this,
										"my_active"
									)}
									isClearable
									options={myactiveOptions}
									name="my_active"
									placeholder={this.props.t(
										"SEARCH_LEAD.SEARCH_BY"
									)}
									value={
										tempFilterData.my_active
											? myactiveOptions.filter(
													({ value }) =>
														value ===
														tempFilterData.my_active
											  )
											: ""
									}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
								/>
							</li>
							<li className="searchitems selectWidth">
								<InputField
									inputProps={{
										id: "search",
										type: "text",
										value: tempFilterData.search || "",
										name: "search",
										autoComplete: "off",
										placeholder: this.props.t(
											"SEARCH_LEAD.SEARCH_BY_TEXT"
										),
										label: this.props.t(
											"SEARCH_LEAD.SEARCH_BY_LABEL"
										),
									}}
									autoComplete="off"
									onChange={this.handleChange.bind(
										this,
										"search"
									)}
								/>
							</li>
							<li className="searchitems selectWidth form-group">
								<Select
									id="date_type"
									onChange={this.handleChange.bind(
										this,
										"date_type"
									)}
									isClearable
									options={dateType}
									name="date_type"
									placeholder={this.props.t(
										"SEARCH_LEAD.DATE_TYPE"
									)}
									value={
										tempFilterData.date_type
											? dateType.filter(
													({ value }) =>
														value ===
														tempFilterData.date_type
											  )
											: ""
									}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
								/>
							</li>
							<li className="searchitems selectWidth form-group">
								<DatePicker
									showYearDropdown
									showMonthDropdown
									selected={tempFilterData.start_date || ""}
									onChange={(date) =>
										this.handleChange(date, "start_date")
									}
									name="start_date"
									id="start_date"
									autoComplete="off"
									placeholderText={this.props.t(
										"SEARCH_LEAD.FROM"
									)}
									dateFormat={
										config.constants.DATE_FORMAT_INPUT
									}
									className="form-control"
									maxDate={
										tempFilterData.date_type ===
											"created_date" ||
										tempFilterData.date_type ===
											"updated_date" ||
										tempFilterData.date_type ===
											"updated_date_crm" ||
										tempFilterData.date_type ===
											"updated_date_lms"
											? new Date()
											: ""
									}
									disabled={datePicker.from_date_disabled}
									onKeyDown={(e) => e.preventDefault()}
								/>
							</li>
							<li className="searchitems selectWidth form-group">
								<DatePicker
									showYearDropdown
									showMonthDropdown
									selected={tempFilterData.end_date || ""}
									onChange={(date) =>
										this.handleChange(date, "end_date")
									}
									name="end_date"
									id="end_date"
									autoComplete="off"
									placeholderText={this.props.t(
										"SEARCH_LEAD.TO"
									)}
									dateFormat={
										config.constants.DATE_FORMAT_INPUT
									}
									className="form-control"
									minDate={tempFilterData.start_date || ""}
									maxDate={
										tempFilterData.date_type ===
										"created_date"
											? new Date()
											: ""
									}
									//disabled={datePicker.to_date_disabled}
									onKeyDown={(e) => e.preventDefault()}
								/>
							</li>
							<li className="searchitems selectWidth form-group">
								<ReactMultiSelectCheckboxes
									isMulti
									placeholderButtonLabel={this.props.t(
										"SEARCH_LEAD.GM_ID"
									)}
									value={
										(temp_gm_id instanceof Array &&
											tempFilterData.gm_id &&
											temp_gm_id.filter((e) =>
												[
													...(tempFilterData.gm_id instanceof
													Array
														? tempFilterData.gm_id
														: []),
												].includes(e.value)
											)) ||
										[]
									}
									onChange={this.handleChange.bind(
										this,
										"gm_id"
									)}
									options={temp_gm_id}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
								/>
							</li>
							<li className="searchitems selectWidth form-group">
								<ReactMultiSelectCheckboxes
									isMulti
									placeholderButtonLabel={this.props.t(
										"SEARCH_LEAD.STATUS"
									)}
									// defaultValue={filterData.status || []}
									value={
										(status_list instanceof Array &&
											tempFilterData.status_id &&
											status_list.filter((e) =>
												[
													...(tempFilterData.status_id instanceof
													Array
														? tempFilterData.status_id
														: []),
												].includes(e.value)
											)) ||
										[]
									}
									onChange={this.handleChange.bind(
										this,
										"status_id"
									)}
									options={status_list}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
								/>
							</li>
							<li className="searchitems selectWidth form-group">
								<ReactMultiSelectCheckboxes
									isMulti
									placeholderButtonLabel={this.props.t(
										"SEARCH_LEAD.SUB_STATUS"
									)}
									value={
										(sub_status_list instanceof Array &&
											tempFilterData.sub_status_id &&
											sub_status_list.filter((e) =>
												[
													...(tempFilterData.sub_status_id instanceof
													Array
														? tempFilterData.sub_status_id
														: []),
												].includes(e.value)
											)) ||
										[]
									}
									onChange={this.handleChange.bind(
										this,
										"sub_status_id"
									)}
									options={sub_status_list}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
								/>
							</li>

							<li className="searchitems selectWidth form-group">
								<ReactMultiSelectCheckboxes
									isMulti
									placeholderButtonLabel={this.props.t(
										"SEARCH_LEAD.ATTRIBUTION_STATUS"
									)}
									value={
										(attribution_status_list instanceof
											Array &&
											tempFilterData.attribution_status_id &&
											attribution_status_list.filter(
												(e) =>
													[
														...(tempFilterData.attribution_status_id instanceof
														Array
															? tempFilterData.attribution_status_id
															: []),
													].includes(e.value)
											)) ||
										[]
									}
									onChange={this.handleChange.bind(
										this,
										"attribution_status_id"
									)}
									options={attribution_status_list}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
								/>
							</li>

							<li className="searchitems selectWidth form-group">
								<ReactMultiSelectCheckboxes
									isMulti
									placeholderButtonLabel={this.props.t(
										"SEARCH_LEAD.BILLING_STATUS"
									)}
									value={
										(billing_status_list instanceof Array &&
											tempFilterData.billing_status_id &&
											billing_status_list.filter((e) =>
												[
													...(tempFilterData.billing_status_id instanceof
													Array
														? tempFilterData.billing_status_id
														: []),
												].includes(e.value)
											)) ||
										[]
									}
									onChange={this.handleChange.bind(
										this,
										"billing_status_id"
									)}
									options={billing_status_list}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
								/>
							</li>

							<li className="searchitems selectWidth form-group">
								<ReactMultiSelectCheckboxes
									isMulti
									placeholderButtonLabel={this.props.t(
										"SEARCH_LEAD.TOTAL_CALL_ATTEMPTS"
									)}
									value={
										(Call_Attempts instanceof Array &&
											tempFilterData.num_attempt &&
											Call_Attempts.filter((e) =>
												[
													...(tempFilterData.num_attempt instanceof
													Array
														? tempFilterData.num_attempt
														: []),
												].includes(e.value)
											)) ||
										[]
									}
									onChange={this.handleChange.bind(
										this,
										"num_attempt"
									)}
									options={Call_Attempts}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
								/>
							</li>

							<li className="searchitems form-group">
								<button
									type="reset"
									className="btn btn-default btn-reset"
									onClick={this.ToogleAdvanceSearch}
								>
									{this.props.t("SEARCH_LEAD.ADVANCE_SEARCH")}
								</button>
								{this.state.advanceSearchEnable && (
									<ClickOutHandler
										onClickOut={this.onClickOut}
									>
										<div className="adSearchBox">
											<span className="arrow"></span>
											<span className="arrow"></span>
											<div className="adSearch-body">
												<div className="form-group">
													<label>
														{this.props.t(
															"SEARCH_LEAD.BOUGHT_MAKE"
														)}
													</label>
													<Select
														id="bought_makeid"
														onChange={this.handleChange.bind(
															this,
															"bought_makeid"
														)}
														isClearable
														options={Bought_Makes}
														name="bought_makeid"
														placeholder={this.props.t(
															"SEARCH_LEAD.BOUGHT_MAKE"
														)}
														value={
															tempFilterData.bought_makeid
																? Bought_Makes.filter(
																		({
																			id,
																		}) =>
																			id ===
																			tempFilterData.bought_makeid
																  )
																: ""
														}
														getOptionLabel={({
															make,
														}) => make}
														getOptionValue={({
															id,
														}) => id}
													/>
												</div>
												<div className="form-group">
													<label>
														{this.props.t(
															`SEARCH_LEAD.${config.constants.CLUSTER}`
														)}
													</label>
													<ReactMultiSelectCheckboxes
														isMulti
														placeholderButtonLabel={this.props.t(
															`SEARCH_LEAD.${config.constants.CLUSTER}`
														)}
														value={
															(cluster_list instanceof
																Array &&
																tempFilterData.cluster_list &&
																cluster_list.filter(
																	(e) =>
																		[
																			...(tempFilterData.cluster_list instanceof
																			Array
																				? tempFilterData.cluster_list
																				: []),
																		].includes(
																			e.value
																		)
																)) ||
															[]
														}
														onChange={this.handleChange.bind(
															this,
															"cluster_list"
														)}
														options={cluster_list}
														getOptionLabel={({
															label,
														}) => label}
														getOptionValue={({
															value,
														}) => value}
													/>
												</div>
												<div className="form-group">
													<label>
														{this.props.t(
															"SEARCH_LEAD.CITY"
														)}
													</label>
													<ReactMultiSelectCheckboxes
														isMulti
														placeholderButtonLabel="city"
														value={
															(filter_city_list instanceof
																Array &&
																filter_city_list.filter(
																	(e) =>
																		[
																			...(tempFilterData.city_id instanceof
																			Array
																				? tempFilterData.city_id
																				: []),
																		].includes(
																			e.value
																		)
																)) ||
															[]
														}
														onChange={this.handleChange.bind(
															this,
															"city_id"
														)}
														options={
															filter_city_list
														}
														getOptionLabel={({
															label,
														}) => label}
														getOptionValue={({
															value,
														}) => value}
													/>
												</div>
												<div className="form-group">
													<label>
														{this.props.t(
															"SEARCH_LEAD.MAKE"
														)}
													</label>
													<Select
														id="make_id"
														onChange={this.handleChange.bind(
															this,
															"make_id"
														)}
														isClearable
														options={make_list}
														name="make_id"
														placeholder={this.props.t(
															"SEARCH_LEAD.MAKE"
														)}
														value={
															tempFilterData.make_id
																? make_list.filter(
																		({
																			id,
																		}) =>
																			id ===
																			tempFilterData.make_id
																  )
																: ""
														}
														getOptionLabel={({
															make,
														}) => make}
														getOptionValue={({
															id,
														}) => id}
													/>
												</div>

												<div className="form-group">
													<label>
														{this.props.t(
															"SEARCH_LEAD.MODEL"
														)}
													</label>
													<ReactMultiSelectCheckboxes
														isMulti
														placeholderButtonLabel="Model"
														value={
															(filter_Model instanceof
																Array &&
																filter_Model.filter(
																	(e) =>
																		[
																			...(tempFilterData.model_id instanceof
																			Array
																				? tempFilterData.model_id
																				: []),
																		].includes(
																			e.value
																		)
																)) ||
															[]
														}
														onChange={this.handleChange.bind(
															this,
															"model_id"
														)}
														options={filter_Model}
														getOptionLabel={({
															label,
														}) => label}
														getOptionValue={({
															value,
														}) => value}
													/>
												</div>

												<div className="form-group">
													<label>
														{this.props.t(
															"SEARCH_LEAD.DEALER"
														)}
													</label>
													<ReactMultiSelectCheckboxes
														isMulti
														placeholderButtonLabel={this.props.t(
															"SEARCH_LEAD.DEALER"
														)}
														value={
															(dealer_list instanceof
																Array &&
																tempFilterData.dealer_id &&
																dealer_list.filter(
																	(e) =>
																		[
																			...(tempFilterData.dealer_id instanceof
																			Array
																				? tempFilterData.dealer_id
																				: []),
																		].includes(
																			e.value
																		)
																)) ||
															[]
														}
														onChange={this.handleChange.bind(
															this,
															"dealer_id"
														)}
														options={dealer_list}
														getOptionLabel={({
															label,
														}) => label}
														getOptionValue={({
															value,
														}) => value}
													/>
												</div>
												<div className="form-group">
													<label>
														{this.props.t(
															"SEARCH_LEAD.DSE_NAME"
														)}
													</label>
													<ReactMultiSelectCheckboxes
														isMulti
														placeholderButtonLabel={this.props.t(
															"SEARCH_LEAD.DSE_NAME"
														)}
														value={
															(dse_id_options instanceof
																Array &&
																tempFilterData.dse_name &&
																dse_id_options.filter(
																	(e) =>
																		[
																			...(tempFilterData.dse_name instanceof
																			Array
																				? tempFilterData.dse_name
																				: []),
																		].includes(
																			e.value
																		)
																)) ||
															[]
														}
														onChange={this.handleChange.bind(
															this,
															"dse_name"
														)}
														options={dse_id_options}
														getOptionLabel={({
															label,
														}) => label}
														getOptionValue={({
															value,
														}) => value}
													/>
												</div>
												<div className="form-group">
													<label>
														{this.props.t(
															"SEARCH_LEAD.LMS_STATUS"
														)}
													</label>
													<Select
														id="lms_status_id"
														onChange={this.handleChange.bind(
															this,
															"lms_status_id"
														)}
														isClearable
														options={
															lms_status_list
														}
														name="lms_status_id"
														placeholder={this.props.t(
															"SEARCH_LEAD.LMS_STATUS"
														)}
														value={
															tempFilterData.lms_status_id
																? lms_status_list.filter(
																		({
																			value,
																		}) =>
																			+value ===
																			+tempFilterData.lms_status_id
																  )
																: ""
														}
														getOptionLabel={({
															label,
														}) => label}
														getOptionValue={({
															value,
														}) => value}
													/>
												</div>
												<div className="form-group">
													<label>
														{this.props.t(
															"SEARCH_LEAD.MODAL_STATUS"
														)}
													</label>
													<div className="rc-inline formLable">
														{model_status_list &&
														model_status_list.length
															? model_status_list.map(
																	(
																		val,
																		index
																	) => (
																		<Radiobox
																			label={
																				val.label
																			}
																			value={
																				val.value
																			}
																			name="model_status_id"
																			id={
																				"modal_status" +
																				index
																			}
																			key={
																				"modalkey" +
																				index
																			}
																			type="radio"
																			onChange={this.handleChange.bind(
																				this,
																				"model_status_id"
																			)}
																			checked={
																				tempFilterData.model_status_id
																					? +tempFilterData.model_status_id ===
																					  +val.value
																						? true
																						: false
																					: false
																			}
																		/>
																	)
															  )
															: ""}
													</div>
												</div>
												<div className="form-group">
													<label>
														{this.props.t(
															"SEARCH_LEAD.TEST_DRIVE"
														)}
													</label>
													<div className="rc-inline formLable">
														{test_drive_status_list &&
														test_drive_status_list.length
															? test_drive_status_list.map(
																	(
																		val,
																		index
																	) => (
																		<Radiobox
																			label={
																				val.label
																			}
																			value={
																				val.value
																			}
																			name="test_drive_type"
																			id={
																				"test_drive" +
																				index
																			}
																			key={
																				"testdrivekey" +
																				index
																			}
																			type="radio"
																			onChange={this.handleChange.bind(
																				this,
																				"test_drive_type"
																			)}
																			checked={
																				tempFilterData.test_drive_type
																					? +tempFilterData.test_drive_type ===
																					  +val.value
																						? true
																						: false
																					: false
																			}
																		/>
																	)
															  )
															: ""}
													</div>
												</div>
												{/* //////ld status */}
												<div className="form-group">
													<label>
														{this.props.t(
															"SEARCH_LEAD.ld_status"
														)}
													</label>
													<div className="rc-inline formLable">
														{ld_status &&
														ld_status.length
															? ld_status.map(
																	(
																		val,
																		index
																	) => (
																		<Radiobox
																			label={
																				val.label
																			}
																			value={
																				val.value
																			}
																			name="ld_status"
																			id={
																				"ld_status" +
																				index
																			}
																			key={
																				"ldkey" +
																				index
																			}
																			type="radio"
																			onChange={this.handleChange.bind(
																				this,
																				"ld_status"
																			)}
																			checked={
																				tempFilterData.ld_status
																					? tempFilterData.ld_status ===
																					  val.value
																						? true
																						: false
																					: false
																			}
																		/>
																	)
															  )
															: ""}
													</div>
												</div>
												<div className="form-group">
													<label>
														{this.props.t(
															"SEARCH_LEAD.DEALER_VISIT_STATUS"
														)}
													</label>
													<div className="rc-inline formLable">
														{dealer_visit_status_list &&
														dealer_visit_status_list.length
															? dealer_visit_status_list.map(
																	(
																		val,
																		index
																	) => (
																		<Radiobox
																			label={
																				val.label
																			}
																			value={
																				val.value
																			}
																			name="dealer_visit_status"
																			id={
																				"dealer_visit_status" +
																				index
																			}
																			key={
																				"dealer_visitkey" +
																				index
																			}
																			type="radio"
																			onChange={this.handleChange.bind(
																				this,
																				"dealer_visit_status"
																			)}
																			checked={
																				tempFilterData.dealer_visit_status
																					? +tempFilterData.dealer_visit_status ===
																					  +val.value
																						? true
																						: false
																					: false
																			}
																		/>
																	)
															  )
															: ""}
													</div>
												</div>
												<div className="form-group">
													<label>
														{this.props.t(
															"SEARCH_LEAD.LAST_CALL_STATUS"
														)}
													</label>

													<Select
														id="call_status"
														onChange={this.handleChange.bind(
															this,
															"call_status"
														)}
														isClearable
														options={
															call_status_list
														}
														name="call_status"
														placeholder={this.props.t(
															"SEARCH_LEAD.LAST_CALL_STATUS"
														)}
														value={
															tempFilterData.call_status
																? call_status_list.filter(
																		({
																			value,
																		}) =>
																			value ===
																			tempFilterData.call_status
																  )
																: ""
														}
														getOptionLabel={({
															label,
														}) => label}
														getOptionValue={({
															value,
														}) => value}
													/>
												</div>
												<div className="form-group">
													<label>
														{this.props.t(
															"SEARCH_LEAD.CONSUMER_SOURCE"
														)}
													</label>
													<div className="rc-inline formLable">
														{source_list &&
														source_list.length
															? source_list.map(
																	(
																		val,
																		index
																	) => (
																		<Radiobox
																			label={
																				val.label
																			}
																			value={
																				val.value
																			}
																			name="customer_source_id"
																			id={
																				"customer_source_id" +
																				index
																			}
																			key={
																				"customer_source_id" +
																				index
																			}
																			type="radio"
																			onChange={this.handleChange.bind(
																				this,
																				"customer_source_id"
																			)}
																			checked={
																				tempFilterData.customer_source_id
																					? +tempFilterData.customer_source_id ===
																					  +val.value
																						? true
																						: false
																					: false
																			}
																		/>
																	)
															  )
															: ""}
													</div>
												</div>
												<div className="form-group">
													<label>
														{this.props.t(
															"SEARCH_LEAD.SOURCE"
														)}
													</label>
													<div className="rc-inline formLable">
														{source_list &&
														source_list.length
															? source_list.map(
																	(
																		val,
																		index
																	) => (
																		<Radiobox
																			label={
																				val.label
																			}
																			value={
																				val.value
																			}
																			name="source_id"
																			id={
																				"source_id" +
																				index
																			}
																			key={
																				"source_id" +
																				index
																			}
																			type="radio"
																			onChange={this.handleChange.bind(
																				this,
																				"source_id"
																			)}
																			checked={
																				tempFilterData.source_id
																					? +tempFilterData.source_id ===
																					  +val.value
																						? true
																						: false
																					: false
																			}
																		/>
																	)
															  )
															: ""}
													</div>
												</div>
												<div className="form-group">
													<label>
														{this.props.t(
															"SEARCH_LEAD.FINANCE_DETAIL"
														)}
													</label>
													<div className="rc-inline formLable">
														{yesNo && yesNo.length
															? yesNo.map(
																	(
																		val,
																		index
																	) => (
																		<Radiobox
																			label={
																				val.label
																			}
																			value={
																				val.value
																			}
																			name="finance_required"
																			id={
																				"finance_required" +
																				index
																			}
																			key={
																				"finance_required" +
																				index
																			}
																			type="radio"
																			onChange={this.handleChange.bind(
																				this,
																				"finance_required"
																			)}
																			checked={
																				tempFilterData.finance_required
																					? +tempFilterData.finance_required ===
																					  +val.value
																						? true
																						: false
																					: false
																			}
																		/>
																	)
															  )
															: ""}
													</div>
												</div>
												<div className="form-group">
													<label>
														{this.props.t(
															"SEARCH_LEAD.EXCHANGE_DETAIL"
														)}
													</label>
													<div className="rc-inline formLable">
														{yesNo && yesNo.length
															? yesNo.map(
																	(
																		val,
																		index
																	) => (
																		<Radiobox
																			label={
																				val.label
																			}
																			value={
																				val.value
																			}
																			name="exchange_required"
																			id={
																				"exchange_required" +
																				index
																			}
																			key={
																				"exchange_required" +
																				index
																			}
																			type="radio"
																			onChange={this.handleChange.bind(
																				this,
																				"exchange_required"
																			)}
																			checked={
																				tempFilterData.exchange_required
																					? +tempFilterData.exchange_required ===
																					  +val.value
																						? true
																						: false
																					: false
																			}
																		/>
																	)
															  )
															: ""}
													</div>
												</div>
												<div className="form-group">
													<label>
														{this.props.t(
															"SEARCH_LEAD.SALEABLE"
														)}
													</label>
													<div className="rc-inline formLable">
														{Saleable &&
														Saleable.length
															? Saleable.map(
																	(
																		val,
																		index
																	) => (
																		<Radiobox
																			label={
																				val.label
																			}
																			value={
																				val.value
																			}
																			name="is_saleable"
																			id={
																				"is_saleable" +
																				index
																			}
																			key={
																				"is_saleable" +
																				index
																			}
																			type="radio"
																			onChange={this.handleChange.bind(
																				this,
																				"is_saleable"
																			)}
																			checked={
																				tempFilterData.is_saleable
																					? +tempFilterData.is_saleable ===
																					  +val.value
																						? true
																						: false
																					: false
																			}
																		/>
																	)
															  )
															: ""}
													</div>
												</div>
												<div className="form-group">
													<label>
														{this.props.t(
															"SEARCH_LEAD.DIALER_ALL_TIME_NOT_CONNECT"
														)}
													</label>
													<div className="rc-inline formLable">
														{NotConnectable &&
														NotConnectable.length
															? NotConnectable.map(
																	(
																		val,
																		index
																	) => (
																		<Radiobox
																			label={
																				val.label
																			}
																			value={
																				val.value
																			}
																			name="is_not_connectable"
																			id={
																				"is_not_connectable" +
																				index
																			}
																			key={
																				"is_not_connectable" +
																				index
																			}
																			type="radio"
																			onChange={this.handleChange.bind(
																				this,
																				"is_not_connectable"
																			)}
																			checked={
																				tempFilterData.is_not_connectable
																					? +tempFilterData.is_not_connectable ===
																					  +val.value
																						? true
																						: false
																					: false
																			}
																		/>
																	)
															  )
															: ""}
													</div>
												</div>

												<div className="form-group">
													<label>
														{this.props.t(
															"SEARCH_LEAD.LEAD_TYPE"
														)}
													</label>
													<ReactMultiSelectCheckboxes
														isMulti
														placeholderButtonLabel={this.props.t(
															"SEARCH_LEAD.LEAD_TYPE"
														)}
														value={
															(lead_type_list instanceof
																Array &&
																tempFilterData.lead_type_id &&
																lead_type_list.filter(
																	(e) =>
																		[
																			...(tempFilterData.lead_type_id instanceof
																			Array
																				? tempFilterData.lead_type_id
																				: []),
																		].includes(
																			e.value
																		)
																)) ||
															[]
														}
														onChange={this.handleChange.bind(
															this,
															"lead_type_id"
														)}
														options={lead_type_list}
														getOptionLabel={({
															label,
														}) => label}
														getOptionValue={({
															value,
														}) => value}
													/>
												</div>
												<div className="form-group">
													<label>
														{this.props.t(
															"SEARCH_LEAD.LMS_FLAG"
														)}
													</label>
													<div className="rc-inline formLable">
														{LMS_FLAG &&
														LMS_FLAG.length
															? LMS_FLAG.map(
																	(
																		val,
																		index
																	) => (
																		<Radiobox
																			label={
																				val.label
																			}
																			value={
																				val.value
																			}
																			name="lms_flag"
																			id={
																				"lms_flag" +
																				index
																			}
																			key={
																				"lms_flag" +
																				index
																			}
																			type="radio"
																			onChange={this.handleChange.bind(
																				this,
																				"lms_flag"
																			)}
																			checked={
																				tempFilterData.lms_flag
																					? tempFilterData.lms_flag ===
																					  val.value
																						? true
																						: false
																					: false
																			}
																		/>
																	)
															  )
															: ""}
													</div>
												</div>
											</div>
											<div className="adSearch-footer">
												<div className="clearfix">
													<div className="col-sm-6">
														<button
															type="reset"
															className="btn btn-default btn-reset"
															onClick={
																this
																	.ResetFilterForm
															}
														>
															{this.props.t(
																"SEARCH_LEAD.RESET"
															)}
														</button>
													</div>
													<div className="col-sm-6 text-right">
														<button
															type="submit"
															className="btn btn-primary"
														>
															{this.props.t(
																"SEARCH_LEAD.SEARCH"
															)}
														</button>
													</div>
												</div>
											</div>
										</div>
									</ClickOutHandler>
								)}
							</li>
							{this.state.advanceSearchEnable ? null : (
								<li className="searchitems form-group searchBtns">
									<button
										type="submit"
										className="btn btn-primary"
									>
										{this.props.t("SEARCH_LEAD.SEARCH")}
									</button>
									<button
										type="reset"
										className="btn btn-default btn-reset mrg-l10"
										onClick={this.ResetFilterForm}
									>
										{this.props.t("SEARCH_LEAD.RESET")}
									</button>
								</li>
							)}
						</ul>
					</form>
				</div>
				<span
					className="searchFilter floating-btn"
					onClick={this.searchShow}
				>
					<i className="ic-search-24px"></i>
				</span>
			</>
		);
	}
}
const mapStateToProps = ({ config, inventory }) => {
	const all_cluster_list = config.cluster_list;
	let city_cluster_mapping = new Map();
	if (all_cluster_list && all_cluster_list.length) {
		for (let { id = null, mapped_city = [] } of all_cluster_list) {
			if (id && mapped_city.length) {
				for (let eachCity of mapped_city) {
					city_cluster_mapping.set(eachCity.city_id, id);
				}
			}
		}
	}

	let state = config.city_state_list ? config.city_state_list.state : [];

	let allState = new Map();
	if (state instanceof Array) {
		allState = state.reduce(
			(obj, item) => (obj.set(item.id, item.name), obj),
			new Map()
		);
	}

	////create city to cluster mapping here
	// const city_to_cluster_mapping =
	return {
		call_status_list:
			config.call_status_list instanceof Array
				? config.call_status_list.map((e) => {
						return {
							label: e.name,
							value: e.call_status_id,
						};
				  })
				: [],
		dealer_list:
			config.dealer_list instanceof Array
				? config.dealer_list.map((e) => {
						return {
							label: e.name,
							value: e.dealer_id,
							organization: e.organization,
						};
				  })
				: [],
		dealer_status_list:
			config.dealer_status_list instanceof Array
				? config.dealer_status_list.map((e) => {
						return {
							label: e.name,
							value: e.status_id,
							sub_status: e.sub_status_master,
						};
				  })
				: [],
		dealer_visit_status_list:
			config.dealer_visit_status_list instanceof Array
				? config.dealer_visit_status_list.map((e) => {
						return {
							label: e.name,
							value: e.id,
						};
				  })
				: [],
		model_status_list:
			config.modalStatus instanceof Array
				? config.modalStatus.map((e) => {
						return {
							label: e.name,
							value: e.id,
						};
				  })
				: [],
		source_list:
			config.source_list instanceof Array
				? config.source_list.map((e) => {
						return {
							label: e.name,
							value: e.source_id,
						};
				  })
				: [],
		status_list:
			config.status_list instanceof Array
				? config.status_list.map((e) => {
						return {
							label: e.name,
							value: e.status_id,
							sub_status:
								e.sub_status_master instanceof Array
									? e.sub_status_master.map(function (e) {
											return {
												label: e.sub_status_name,
												value: e.sub_status_id,
											};
									  })
									: [],
						};
				  })
				: [],
		// sub_status_list:
		// 	config.status_list instanceof Array
		// 		? [].concat
		// 				.apply(
		// 					[],
		// 					config.status_list.map((e) => e.sub_status_master)
		// 				)
		// 				.map(function (e) {
		// 					return {
		// 						label: e.sub_status_name,
		// 						value: e.sub_status_id,
		// 					};
		// 				})
		// 		: [],
		test_drive_status_list:
			config.test_drive_status_list instanceof Array
				? config.test_drive_status_list.map(function (e) {
						return { label: e.name, value: e.id };
				  })
				: [],
		city_list:
			config.city_state_list && Object.keys(config.city_state_list).length
				? config.city_state_list.city.map(function (e) {
						return {
							label: `${e.name}-${allState.get(e.state_id)}`,
							value: e.id,
							state_id: e.state_id,
							cluster_id: city_cluster_mapping.get(e.id) || -1,
						};
				  })
				: [],
		make_model: inventory.mm instanceof Array ? inventory.mm : [],
		lms_status_list: config.lms_status_list,
		gm_id:
			config.gm_campaign_process_list instanceof Array
				? config.gm_campaign_process_list.map(function (e) {
						return {
							label: `${
								e.campaign_name
									? e.campaign_name.charAt(0).toUpperCase() +
									  e.campaign_name.slice(1)
									: ""
							} - ${e.gm_id}`,
							value: e.id,
						};
				  })
				: [],
		dse_id:
			config.dse_list instanceof Array
				? config.dse_list.map(function (e) {
						return {
							label: e.name,
							value: e.id,
							mobile: e.phone_number,
							dealer_id: e.dealer_id,
						};
				  })
				: [],
		ld_status: config.ld_status,
		attribution_status_list:
			config.attribution_status_list instanceof Array
				? config.attribution_status_list.map((e) => {
						return {
							label: e.name,
							value: e.attribution_id,
						};
				  })
				: [],
		billing_status_list:
			config.billing_status_list instanceof Array
				? config.billing_status_list.map((e) => {
						return {
							label: e.name,
							value: e.billing_status_id,
						};
				  })
				: [],
		agent_list:
			config.agent_list instanceof Array
				? config.agent_list.map((e) => {
						return {
							label: e.name,
							value: e.id,
						};
				  })
				: [],
		Bought_Makes:
			inventory.mmv && inventory.mmv.make ? inventory.mmv.make : [],
		make_list:
			inventory.mmv && inventory.mmv.make ? inventory.mmv.make : [],
		model_list:
			inventory.mmv && inventory.mmv.model
				? inventory.mmv.model.map((e) => {
						return {
							label: e.m,
							value: e.id,
							mk_id: e.mk_id,
						};
				  })
				: [],
		lead_type_list:
			config.lead_type_list instanceof Array
				? config.lead_type_list.map((e) => {
						return {
							label: e.name,
							value: e.id,
						};
				  })
				: [],
		cluster_list:
			config.cluster_list instanceof Array
				? config.cluster_list.map((e) => {
						return {
							label: e.cluster_name,
							value: e.id,
							mapped_city:
								e.mapped_city.map((e) => e.city_id) || [],
						};
				  })
				: [],
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getMMV: bindActionCreators(InventoryAction.getMMV, dispatch),
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
		},
	};
};
export default withTranslation("lead_list")(
	connect(mapStateToProps, mapDispatchToProps)(SearchLead)
);
