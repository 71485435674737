/* eslint-disable */
import React, { Component } from "react";
import Select from "react-select";
import InputField from "../../elements/InputField";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { default as config } from "../../../config/config";
import secureStorage from "../../../config/encrypt";
import CheckBox from "../../elements/CheckBox";
class PersonalDetailForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addNewCarState: false,
			minPrice: "",
			checkedItems: false,
			leadDetail: {},
			regexp: /^[0-9\b]+$/,
			updateMyActive: false,
		};
		this.ChangeCheckbox = this.ChangeCheckbox.bind(this);
	}
	componentDidMount = () => {
		const { leadDetail, cityList } = this.props;
		const city = cityList.map(function (e) {
			return {
				label: e.name,
				value: e.id,
			};
		});
		this.setState({ leadDetail: { ...leadDetail }, cityList: city });
	};
	handleChange = (sname, ovalue) => {
		const { leadDetail } = this.state;
		if (ovalue) {
			const { target } = ovalue;
			if (target) {
				const { value } = target;
				if (sname === "alternate_mobile" || sname === "postal_code") {
					if (value === "" || this.state.regexp.test(value))
						leadDetail[sname] = value;
				} else leadDetail[sname] = value;
			} else {
				leadDetail[sname] = ovalue.value;
			}
		} else {
			leadDetail[sname] = "";
			// if(sname === "age_range"){
			// 	leadDetail[sname] = ''
			// }else{
			// 	delete leadDetail[sname];
			// }
		}
		this.setState({ leadDetail: leadDetail });
	};
	isNumber = (value) => {
		if (typeof value !== "number") {
			return false;
		}
		if (value !== Number(value)) {
			return false;
		}
		if (value === Infinity || value === !Infinity) {
			return false;
		}
		if (("" + value).length < 10 && ("" + value).length > 12) {
			return false;
		}
		return true;
	};
	validateMobile = (mobile) => {
		return this.isNumber(+mobile);
	};
	validateEmail = (email) => {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
			return true;
		}
		return false;
	};
	validateCustomer = () => {
		const {
			leadDetail: {
				lead_name,
				lead_mobile,
				alternate_mobile,
				lead_email,
				lead_city,
			},
		} = this.state;
		let err = "";
		if (!lead_name) err = "LEAD_NAME_REQUIRED";
		else if (!lead_mobile) err = "LEAD_MOBILE_REQUIRED";
		else if (lead_mobile && !this.validateMobile(lead_mobile))
			err = "INCORRECT_MOBILE";
		else if (alternate_mobile && !this.validateMobile(alternate_mobile))
			err = "INCORRECT_MOBILE";
		// else if (!lead_email) err = "LEAD_EMAIL_REQUIRED";
		else if (lead_email && !this.validateEmail(lead_email))
			err = "LEAD_EMAIL_NOT_VALID";
		// else if (!postal_code) err = "POST_CODE_REQUIRED";
		else if (!lead_city) err = "LEAD_CITY_REQUIRED";
		// else if (!address) err = "ADDRESS_REQUIRED";
		// else if (!gender) err = "GENDER_REQUIRED";
		if (
			lead_mobile &&
			alternate_mobile &&
			Number(alternate_mobile) === Number(lead_mobile)
		)
			err = "MOBILE_AND_ALTERNATE_MOBILE_SHOULD_BE_DIFFERENT";
		if (err) {
			toast.error(this.props.t(`PERSONAL_DETAIL.ERROR.${err}`));
			return false;
		}
		return true;
	};
	updateCustomer = () => {
		if (this.validateCustomer()) {
			const lead_id = new URLSearchParams(window.location.search).get(
				"id"
			);
			const {
				leadDetail,
				leadDetail: {
					customer_id,
					lead_name: name,
					lead_mobile: mobile,
					alternate_mobile,
					lead_email: email,
					postal_code,
					lead_city: customer_city_id,
					address,
					occupation,
					marital_status,
					family_size,
					gender,
					age_range,
					myactive_agent_id,
				},
				updateMyActive,
			} = this.state;
			const postData = {
				id: customer_id,
				name,
				mobile,
				email,
				customer_city_id,
			};
			postData["alternate_mobile"] = alternate_mobile || "";
			postData["postal_code"] = postal_code || 0;
			postData["address"] = address || "";
			postData["occupation"] = occupation ? occupation : "";
			postData["marital_status"] = marital_status ? marital_status : "";
			postData["family_size"] = family_size ? family_size : 0;
			postData["gender"] = gender ? gender : "";
			postData["age_range"] = age_range ? age_range : 0;
			postData["lead_id"] = lead_id;
			if (updateMyActive)
				postData["myactive_agent_id"] = myactive_agent_id;
			// console.log(postData)
			this.props.updateCustomer(postData, leadDetail);
		}
	};
	ChangeCheckbox = (event) => {
		const target = event.target;
		let { leadDetail } = this.state;
		leadDetail[target.name] = target.checked ? 1 : 0;
		this.setState({ leadDetail: leadDetail, updateMyActive: true });
	};
	render() {
		const { leadDetail, cityList, updateMyActive } = this.state;
		const current_lang = secureStorage.getItem("lang");
		const { authUser } = this.props;
		return (
			<div>
				<div className="modal-header">
					<div className="modal-title h4">
						{this.props.t("PERSONAL_DETAIL.HEADING")}
					</div>
				</div>
				<form>
					<div className="pad-15">
						<div className="row">
							<div className="col-sm-6 ">
								<InputField
									inputProps={{
										id: "fullname",
										type: "text",
										name: "fullname",
										autoComplete: "off",

										placeholder: this.props.t(
											"PERSONAL_DETAIL.PLACEHOLDER.FULL_NAME"
										),
										label: this.props.t(
											"PERSONAL_DETAIL.PLACEHOLDER.FULL_NAME"
										),
										value: leadDetail.lead_name || "",
										// dataerror: "Enter email address",
										validationreq: "yes",
									}}
									required
									autoComplete="off"
									onChange={this.handleChange.bind(
										this,
										"lead_name"
									)}
								/>
							</div>
							<div className="col-sm-3 ">
								<InputField
									inputProps={{
										id: "mobile",
										type: "tel",
										name: "mobile",
										autoComplete: "off",
										placeholder: this.props.t(
											"PERSONAL_DETAIL.PLACEHOLDER.MOBILE"
										),
										label: this.props.t(
											"PERSONAL_DETAIL.PLACEHOLDER.MOBILE"
										),
										value: leadDetail.lead_mobile || "",
										maxLength: "12",
										disabled: true,
										// dataerror: "Enter email address",
										validationreq: "yes",
									}}
									autoComplete="off"
									// onChange={this.handleChange.bind(
									// 	this,
									// 	"lead_mobile"
									// )}
								/>
							</div>
							<div className="col-sm-3 ">
								<InputField
									inputProps={{
										id: "alternatemobile",
										type: "tel",
										name: "alternatemobile",
										autoComplete: "off",
										placeholder: this.props.t(
											"PERSONAL_DETAIL.PLACEHOLDER.ALT_MOBILE"
										),
										label: this.props.t(
											"PERSONAL_DETAIL.PLACEHOLDER.ALT_MOBILE"
										),
										value:
											leadDetail.alternate_mobile || "",
										maxLength: "12",
										// dataerror: "Enter email address",
									}}
									autoComplete="off"
									onChange={this.handleChange.bind(
										this,
										"alternate_mobile"
									)}
								/>
							</div>
							<div className="col-sm-6 ">
								<InputField
									inputProps={{
										id: "email",
										type: "email",
										name: "email",
										autoComplete: "off",
										placeholder: this.props.t(
											"PERSONAL_DETAIL.PLACEHOLDER.EMAIL"
										),
										label: this.props.t(
											"PERSONAL_DETAIL.PLACEHOLDER.EMAIL"
										),
										value: leadDetail.lead_email || "",
										// validationreq:"yes"
										//maxLength: "20",
										// dataerror: "Enter email address",
									}}
									autoComplete="off"
									onChange={this.handleChange.bind(
										this,
										"lead_email"
									)}
								/>
							</div>
							<div className="col-sm-3 ">
								<InputField
									inputProps={{
										id: "pin",
										type: "text",
										name: "pin",
										autoComplete: "off",
										placeholder: this.props.t(
											"PERSONAL_DETAIL.PLACEHOLDER.POSTAL_CODE"
										),
										label: this.props.t(
											"PERSONAL_DETAIL.PLACEHOLDER.POSTAL_CODE"
										),
										value: leadDetail.postal_code || "",
										maxLength: "10",
										// dataerror: "Enter email address",
									}}
									autoComplete="off"
									onChange={this.handleChange.bind(
										this,
										"postal_code"
									)}
								/>
							</div>
							<div className="form-group col-sm-3">
								<label>
									{this.props.t(
										"PERSONAL_DETAIL.PLACEHOLDER.CITY"
									)}
									<span className="text-danger">*</span>
								</label>
								<Select
									id="city"
									onChange={this.handleChange.bind(
										this,
										"lead_city"
									)}
									options={cityList}
									name="city"
									placeholder={this.props.t(
										"PERSONAL_DETAIL.PLACEHOLDER.CITY"
									)}
									value={
										cityList &&
										cityList.filter(
											({ value }) =>
												value === leadDetail.lead_city
										)
									}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
									isClearable
								/>
							</div>
							<div className="col-sm-6 ">
								<InputField
									inputProps={{
										id: "address",
										type: "text",
										name: "address",
										autoComplete: "off",
										placeholder: this.props.t(
											"PERSONAL_DETAIL.PLACEHOLDER.ADDRESS"
										),
										label: this.props.t(
											"PERSONAL_DETAIL.PLACEHOLDER.ADDRESS"
										),
										value: leadDetail.address || "",
										maxLength: "50",
										// dataerror: "Enter email address",
									}}
									autoComplete="off"
									onChange={this.handleChange.bind(
										this,
										"address"
									)}
								/>
							</div>
							<div className="col-sm-6 form-group">
								<label>
									{this.props.t(
										"PERSONAL_DETAIL.PLACEHOLDER.OCCUPATION"
									)}
								</label>
								<Select
									id="occupation"
									onChange={this.handleChange.bind(
										this,
										"occupation"
									)}
									options={config.constants.OCCUPATION}
									name="occupation"
									placeholder={this.props.t(
										"PERSONAL_DETAIL.PLACEHOLDER.OCCUPATION"
									)}
									value={config.constants.OCCUPATION.filter(
										({ value }) =>
											value === leadDetail.occupation
									)}
									getOptionLabel={
										current_lang && current_lang === "en"
											? ({ en_label }) => en_label
											: ({ id_label }) => id_label
									}
									getOptionValue={({ value }) => value}
									isClearable
								/>
							</div>
							<div className="col-sm-3 form-group">
								<label>
									{this.props.t(
										"PERSONAL_DETAIL.PLACEHOLDER.MARITAL_STATUS"
									)}
								</label>
								<Select
									id="maritalStatus"
									onChange={this.handleChange.bind(
										this,
										"marital_status"
									)}
									options={config.constants.MARITIAL_STATUS}
									name="maritalStatus"
									placeholder={this.props.t(
										"PERSONAL_DETAIL.PLACEHOLDER.MARITAL_STATUS"
									)}
									value={config.constants.MARITIAL_STATUS.filter(
										({ value }) =>
											value === leadDetail.marital_status
									)}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
									isClearable
								/>
							</div>

							<div className="col-sm-3 form-group">
								<label>
									{this.props.t(
										"PERSONAL_DETAIL.PLACEHOLDER.FAMILY_MEMBER"
									)}
								</label>
								<Select
									id="familymember"
									onChange={this.handleChange.bind(
										this,
										"family_size"
									)}
									options={config.constants.FAMILY_MEMBER}
									name="familymember"
									placeholder={this.props.t(
										"PERSONAL_DETAIL.PLACEHOLDER.FAMILY_MEMBER"
									)}
									value={config.constants.FAMILY_MEMBER.filter(
										({ value }) =>
											value === leadDetail.family_size
									)}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
									isClearable
								/>
							</div>
							<div className="col-sm-3 form-group">
								<label>
									{this.props.t(
										"PERSONAL_DETAIL.PLACEHOLDER.GENDER"
									)}
								</label>
								<Select
									id="gender"
									onChange={this.handleChange.bind(
										this,
										"gender"
									)}
									options={config.constants.GENDER}
									name="gender"
									placeholder={this.props.t(
										"PERSONAL_DETAIL.PLACEHOLDER.GENDER"
									)}
									value={config.constants.GENDER.filter(
										({ value }) =>
											value === leadDetail.gender
									)}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
									isClearable
								/>
							</div>
							<div className="col-sm-3 form-group">
								<label>
									{this.props.t(
										"PERSONAL_DETAIL.PLACEHOLDER.AGE_RANGE"
									)}
								</label>
								<Select
									onChange={this.handleChange.bind(
										this,
										"age_range"
									)}
									options={config.constants.AGE_RANGE}
									name="age_range"
									placeholder={this.props.t(
										"PERSONAL_DETAIL.PLACEHOLDER.AGE_RANGE"
									)}
									value={config.constants.AGE_RANGE.filter(
										({ value }) =>
											value === leadDetail.age_range
									)}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
									isClearable
								/>
							</div>
							<div className="col-sm-3 form-group">
								<CheckBox
									label={this.props.t(
										"PERSONAL_DETAIL.MY_ACTIVE"
									)}
									name="myactive_agent_id"
									type="checkbox"
									id="myactive_agent_id"
									checked={
										leadDetail.myactive_agent_id
											? true
											: false
									}
									value="1"
									disabled={
										authUser.role_id !== 3
											? true
											: !updateMyActive &&
											  leadDetail.myactive_agent_id &&
											  leadDetail.myactive_agent_id !==
													authUser.user_id
											? true
											: false
									}
									onChange={this.ChangeCheckbox}
								/>
							</div>
						</div>
						<div className="actionFooter">
							<button
								type="button"
								className="btn btn-primary-outline mrg-r15"
								onClick={this.props.modalClose}
							>
								{this.props.t("PERSONAL_DETAIL.CANCEL")}
							</button>
							<button
								type="button"
								className="btn btn-primary"
								onClick={this.updateCustomer}
							>
								{this.props.t("PERSONAL_DETAIL.UPDATE")}
							</button>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

export default withTranslation("lead_detail")(PersonalDetailForm);
