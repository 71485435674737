import React, { Component } from "react";
import ModalPopup from "./../../elements/Popup";
import TabPrefreRecommend from "./TabPrefreRecommend";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
	LeadActions,
	ConfigAction,
	InventoryAction,
} from "./../../../store/action";
import { bindActionCreators } from "redux";
import PageLoader from "../../elements/PageLoader";
class PreferencesCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			showrightsmodal: false,
			modalStatus: [],
			makeMap: [],
			modelMap: [],
			preferenceList: [],
			pagination: {},
		};
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		const updatedState = {};

		if (prevState.modalStatus !== nextProps.modalStatus) {
			updatedState["modalStatus"] = nextProps.modalStatus;
		}
		if (prevState.makeMap !== nextProps.makeMap) {
			updatedState["makeMap"] = nextProps.makeMap;
		}
		if (prevState.modelMap !== nextProps.modelMap) {
			updatedState["modelMap"] = nextProps.modelMap;
		}
		if (
			nextProps.preferenceList &&
			nextProps.preferenceList.record &&
			prevState.preferenceList !== nextProps.preferenceList.record
		) {
			updatedState["preferenceList"] = [
				...nextProps.preferenceList.record,
			];
		}
		if (
			nextProps.preferenceList &&
			nextProps.preferenceList.pagination &&
			prevState.preferenceList !== nextProps.preferenceList.pagination
		) {
			updatedState["pagination"] = nextProps.preferenceList.pagination;
		}

		return updatedState;
	}
	componentDidMount = async () => {
		// await this.getPreferenceList();
		if (
			!(
				this.props.modalStatus &&
				this.props.modalStatus instanceof Array &&
				this.props.modalStatus.length
			)
		) {
			await this.props.actions.getAllModalStatus();
		}

		if (
			!(
				this.props.mm &&
				this.props.mm instanceof Array &&
				this.props.mm.length
			)
		) {
			await this.props.actions.getMMV();
		}
	};

	showrightsmodal = () => {
		this.setState({ showrightsmodal: true });
	};
	closerightsModal = () => {
		//this.getPreferenceList();
		this.setState({ showrightsmodal: false });
	};
	getPreferenceList = (isPreferenceUpdate = false) => {
		const urlInfo = new URLSearchParams(window.location.search);
		const id = urlInfo.get("id");
		if (id) {
			const post_data = {
				lead_id: id,
				_with: ["preference_list"],
			};
			if (isPreferenceUpdate) post_data._with.push("assigned_cars");
			this.props.actions.getPreferenceList(post_data);
		}
	};

	render() {
		///////////make preference data/////////////////////
		const {
			preferenceList,
			modalStatus,
			loading,
			makeMap,
			modelMap,
		} = this.state;

		const allModalStatus = {};
		if (modalStatus) {
			for (let i of modalStatus) {
				allModalStatus[i.id] = i.name;
			}
		}

		const preferences =
			preferenceList &&
			preferenceList instanceof Array &&
			preferenceList
				.filter((e, n) => n < 3)
				.map(function (e, n) {
					// modalStatus.filter(e=>e./)
					return (
						<li
							key={n}
							className={
								allModalStatus[e.model_status_id]
									? `${allModalStatus[
											e.model_status_id
									  ].toLowerCase()}`
									: ""
							}
						>
							<span className="counts">{n + 1}</span>
							<span className="carname">
								{makeMap
									? makeMap[e.make_id]
										? makeMap[e.make_id]
										: ""
									: ""}{" "}
								-{" "}
								{modelMap
									? modelMap[e.model_id]
										? modelMap[e.model_id]
										: ""
									: ""}
							</span>
							<span className="status">
								-{" "}
								{allModalStatus[e.model_status_id]
									? allModalStatus[e.model_status_id]
									: ""}
							</span>
						</li>
					);
				});
		return (
			<div>
				<div className="card">
					<div className="card-heading">
						<div className="row">
							<div className="col-sm-6">
								<h2 className="card-title">
									{this.props.t("PREFERENCE_CARD.PREFERENCE")}
								</h2>
							</div>

							<div className="col-sm-6 text-right">
								<span
									className="link-text"
									onClick={this.showrightsmodal}
								>
									{this.props.t("PREFERENCE_CARD.EDIT")}
								</span>
							</div>
						</div>
						{loading && <PageLoader loaderClass="text-center" />}
					</div>
					<div className="card-body">
						<ul className="preferenceCar">{preferences}</ul>
					</div>
				</div>

				{this.state.showrightsmodal === false ? (
					""
				) : (
					<ModalPopup
						id="preferForm"
						className="modal modal-lg"
						title=""
						modalClose={this.closerightsModal}
					>
						<TabPrefreRecommend
							modalClose={this.closerightsModal}
							// leadId={this.props.leadId}
							preferenceList={this.state.preferenceList}
							getPreferenceList={this.getPreferenceList}
						/>
					</ModalPopup>
				)}
			</div>
		);
	}
}
const mapStateToProps = ({ config, inventory, lead }) => {
	return {
		modalStatus: config.modalStatus,
		makeMap: inventory.make_map,
		modelMap: inventory.model_map,
		preferenceList: lead.preference_list,
		mm: inventory.mm,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getPreferenceList: bindActionCreators(
				LeadActions.getLeadDetail,
				dispatch
			),
			getAllModalStatus: bindActionCreators(
				ConfigAction.getModalStatus,
				dispatch
			),
			getMMV: bindActionCreators(InventoryAction.getMMV, dispatch),
			getAssignCar: bindActionCreators(
				LeadActions.getLeadDetail,
				dispatch
			),
		},
	};
};
export default withTranslation("lead_detail")(
	connect(mapStateToProps, mapDispatchToProps)(PreferencesCard)
);
