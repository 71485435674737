import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { UserActions } from "./../../../store/action";
import { bindActionCreators } from "redux";
import PageLoader from "../../elements/PageLoader";
import Select from "react-select";
import { toast } from "react-toastify";
import ConfirmPopUp from "../../common/ConfirmPopUp";

class EditDse extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			gm_associated_dse: [],
			post_data: {},
			display_popover: false,
		};
	}
	componentDidMount = async () => {
		const { data } = this.props;
		this.setState({ post_data: {} });

		await this.getGmAssociatedDse(data);
	};
	getGmAssociatedDse = ({ dse_id, assign_id }) => {
		const postData = {
			dse_id: dse_id,
			assign_id: assign_id,
		};
		this.props.actions.getGmAssociatedDse(postData);
	};
	handleChange = (sname, ovalue) => {
		const { post_data } = this.state;
		const { target: { value = "" } = {} } = ovalue ? ovalue : {};

		if (ovalue && ovalue.value) {
			post_data[sname] = ovalue.value;
		} else {
			post_data[sname] = value;
		}

		///delete unneccessary data
		if (!post_data[sname]) delete post_data[sname];
		this.setState({ post_data: post_data });
	};
	verifyPostData = (post_data) => {
		let error = "";
		const { dse_id } = post_data;

		if (!dse_id) error = this.props.t("EDIT_DSE.NO_DSE_SELECTED");
		if (!error) return true;
		toast.error(error);
		return false;
	};
	handleUpdateDse = () => {
		// assign_car_id
		const { data: { request_id = null } = {} } = this.props;
		const { post_data } = this.state;
		this.setState({ display_popover: false });
		if (this.verifyPostData(post_data)) {
			this.setState({ loading: true }, () => {
				this.props.updateDse(post_data, request_id);
			});
		}
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		const updatedState = {};
		if (prevState.gm_associated_dse !== nextProps.gm_associated_dse) {
			updatedState["gm_associated_dse"] = nextProps.gm_associated_dse;
			updatedState["loading"] = false;
		}
		return updatedState;
	}
	togglePopOver = (status) => {
		this.setState({ display_popover: status });
	};
	render() {
		const { loading, gm_associated_dse, display_popover } = this.state;
		return (
			<>
				{loading && (
					<PageLoader loaderClass="text-center" loadertext="false" />
				)}
				<div className="container-fluid">
					<div className="col-xs-12">
						<h3>{this.props.t("EDIT_DSE.REASSIGN_DSE")}</h3>
						<Select
							id="dse_id"
							options={gm_associated_dse}
							onChange={this.handleChange.bind(this, "dse_id")}
							placeholder={this.props.t("EDIT_DSE.SEARCH_DSE")}
							isClearable
							name="dse_id"
						/>
					</div>
					<div className="col-xs-12 reassignComment">
						<textarea
							className="commentArea"
							id="reassign_dse_area"
							onChange={this.handleChange.bind(this, "comment")}
							name="comment"
							placeholder={this.props.t("EDIT_DSE.COMMENT")}
						/>
					</div>
					<div className="col-xs-12">
						<div className="text-center editDseFooter">
							<button
								type="button"
								className="btn btn-primary-outline mrg-r15"
								disabled={false}
								onClick={() => this.props.modalClose()}
							>
								{this.props.t("EDIT_DSE.CANCEL")}
							</button>
							<button
								type="button"
								className="btn btn-primary"
								disabled={loading ? true : false}
								// onClick={this.handleUpdateDse.bind(this)}
								onClick={this.togglePopOver.bind(this, true)}
							>
								{this.props.t("EDIT_DSE.UPDATE")}
							</button>
							{display_popover && (
								<ConfirmPopUp
									onConfirm={this.handleUpdateDse.bind(this)}
									onReject={this.togglePopOver.bind(
										this,
										false
									)}
									message={this.props.t(
										"EDIT_DSE.UPDATE_DSE"
									)}
								/>
							)}
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ user }) => {
	return {
		gm_associated_dse: user.gm_associated_dse
			? user.gm_associated_dse.map((e) => {
					return {
						label: `${e.dealer_name} - ${e.dse_name}`,
						value: e.dse_id,
					};
			  })
			: [],
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getGmAssociatedDse: bindActionCreators(
				UserActions.getGmAssociatedDse,
				dispatch
			),
		},
	};
};
export default withTranslation("lead_detail")(
	connect(mapStateToProps, mapDispatchToProps)(EditDse)
);
