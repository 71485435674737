import { ConfigConstants } from "../constants/config.constants";
import secureStorage from "../../config/encrypt";
// import { ConfigAction } from "../action";

let configData = "";
let initialState = "";
try {
	configData = secureStorage.getItem("config");
	initialState = configData ? configData : {};
} catch (err) {
	console.log(err);
}

export default function ConfigReducer(state = initialState, action) {
	switch (action.type) {
		case ConfigConstants.GET_MODAL_STATUS:
			return {
				...action.list,
				...state,
			};
		case ConfigConstants.GET_SOURCE_LIST:
			return {
				...action.list,
				...state,
			};
		case ConfigConstants.GET_STATUS_LIST:
			return {
				statusList: action.list,
				...state,
			};
		case ConfigConstants.GET_CONFIG_DATA:
			return {
				...action.list,
				...state,
			};
		case ConfigConstants.GET_CONFIG_DATA_ERR:
			return {
				...state,
			};
		case ConfigConstants.GET_CITY:
			return {
				...action.list,
				...state,
			};
		case ConfigConstants.GET_DEALER:
			return {
				...action.list,
				...state,
			};
		default:
			return state;
	}
}
