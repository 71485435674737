import { LeadConstants } from "../constants/lead.constants";
import { LeadService } from "../../service/leadService";
export const LeadActions = {
	updatePreferences,
	getSubLeadDetails,
	sendToUb,
	saveTestdrive,
	getLeadDetail,
	updateCustomer,
	updateExchange,
	updateFinance,
	getAssignCar,
	updateAssignCar,
	saveDealerVisit,
	updateHistoryFlag,
	getDealerDse,
	pushedToDailer,
	getLeadList,
	updateMyActive,
	reassignDse,
	SaveDownloadLog,
	updateWhatsappClickCount,
};

function updatePreferences(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { message } = {},
			} = await LeadService.updatePreference(postData);

			if (status === 200) {
				return {
					status: true,
					response: message,
				};
			} else
				return {
					status: false,
					response: message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}
function sendToUb(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { message } = {},
			} = await LeadService.sendDataToUB(postData);

			if (status === 200) {
				return {
					status: true,
					response: message,
				};
			} else
				return {
					status: false,
					response: message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}

function getSubLeadDetails(postData) {
	return async (dispatch) => {
		return new Promise((resolve, reject) => {
			LeadService.getSubLeadDetailsById(postData).then(
				(resp) => {
					if (resp && resp.data && resp.data.status === 200) {
						dispatch(success(resp.data.data[0]));
						return resolve((resp && resp.data.data[0]) || []);
					} else {
						dispatch(failure("not_found"));
						return resolve((resp && resp["data"]) || []);
					}
				},
				(error) => {
					dispatch(failure(error));
					return reject(error);
				}
			);
		});
	};

	function success(resp) {
		return { type: LeadConstants.SUB_LEAD_DETAILS_SUCCESS, resp };
	}
	function failure(error) {
		return { type: LeadConstants.SUB_LEAD_DETAILS_FAILURE, error };
	}
}

function saveTestdrive(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { message } = {},
			} = await LeadService.saveTestdrive(postData);

			if (status === 200) {
				return {
					status: true,
					response: message,
				};
			} else
				return {
					status: false,
					response: message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}
function getLeadDetail(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { data } = {},
			} = await LeadService.getLeadDetails(postData);
			if (status === 200) {
				dispatch(success(data));
			} else {
				dispatch(failure("not_found"));
			}
		} catch (err) {
			dispatch(failure(err));
		}
	};
	function success(resp) {
		return { type: LeadConstants.GET_LEAD_DETAIL, resp };
	}
	function failure(error) {
		return { type: LeadConstants.GET_LEAD_DETAIL_ERR, error };
	}
}
function updateCustomer(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { message } = {},
			} = await LeadService.updateCustomer(postData);

			if (status === 200) {
				return {
					status: true,
					response: message,
				};
			} else
				return {
					status: false,
					response: message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}
function updateExchange(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { message } = {},
			} = await LeadService.updateExchange(postData);

			if (status === 200) {
				return {
					status: true,
					response: message,
				};
			} else
				return {
					status: false,
					response: message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}
function updateFinance(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { message } = {},
			} = await LeadService.updateFinance(postData);

			if (status === 200) {
				return {
					status: true,
					response: message,
				};
			} else
				return {
					status: false,
					response: message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}

function getAssignCar(postData) {
	return async (dispatch) => {
		return new Promise((resolve, reject) => {
			LeadService.getAssignCarList(postData).then((resp) => {
				if (resp && resp.data && resp.data.status === 200) {
					return resolve((resp && resp.data) || []);
				} else {
					return resolve((resp && resp["data"]) || []);
				}
			});
		});
	};
}

function updateAssignCar(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { message } = {},
			} = await LeadService.updateAssignCar(postData);

			if (status === 200) {
				return {
					status: true,
					response: message,
				};
			} else
				return {
					status: false,
					response: message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}

function saveDealerVisit(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { message } = {},
			} = await LeadService.saveDealerVisit(postData);

			if (status === 200) {
				return {
					status: true,
					response: message,
				};
			} else
				return {
					status: false,
					response: message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}

function updateHistoryFlag(postData) {
	return async (dispatch) => {
		dispatch(success(postData));
	};

	function success(resp) {
		return { type: LeadConstants.IS_HISTORY_UPDATE, resp };
	}
}

function getDealerDse() {
	return async (dispatch) => {
		return new Promise((resolve, reject) => {
			LeadService.getDealerDseList().then(
				(resp) => {
					if (resp && resp.data && resp.data.status === 200) {
						dispatch(success(resp.data.data));
						return resolve((resp && resp.data) || []);
					} else {
						dispatch(failure("not_found"));
						return resolve((resp && resp["data"]) || []);
					}
				},
				(error) => {
					dispatch(failure(error));
					return reject(error);
				}
			);
		});
	};

	function success(resp) {
		return { type: LeadConstants.DEALER_DSE_LIST_SUCCESS, resp };
	}
	function failure(error) {
		return { type: LeadConstants.DEALER_DSE_LIST_FAILURE, error };
	}
}

function pushedToDailer(postData) {
	return async (dispatch) => {
		dispatch(success(postData));
	};

	function success(resp) {
		return { type: LeadConstants.IS_PUSHED_TO_DAILER, resp };
	}
}

function getLeadList(postData) {
	return async (dispatch) => {
		return new Promise((resolve, reject) => {
			LeadService.getLeads(postData)
				.then((response) => {
					if (
						response.status === 200 &&
						response.data.status === 200
					) {
						resolve(response.data);
					}
				})
				.catch((error) => {
					// toast.error(error);
				});
		});
	};
}

function updateMyActive(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { message } = {},
			} = await LeadService.updateMyActive(postData);

			if (status === 200) {
				return {
					status: true,
					response: message,
				};
			} else
				return {
					status: false,
					response: message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}
function reassignDse(postData) {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { message } = {},
			} = await LeadService.reassignDse(postData);

			if (status === 200) {
				return {
					status: true,
					response: message,
				};
			} else
				return {
					status: false,
					response: message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}

function SaveDownloadLog(postData) {
	let full_url = window.location.href;
	postData.url = full_url;
	return async (dispatch) => {
		try {
			const {
				status,
				data: { message } = {},
			} = await LeadService.saveCsvDownloadLog(postData);

			if (status === 200) {
				return {
					status: true,
					response: message,
				};
			} else
				return {
					status: false,
					response: message,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}
function updateWhatsappClickCount(postData) {
	return async (dispatch) => {
		try {
			await LeadService.updateWhatsappClickCount(postData);
		} catch (err) {
			// dispatch(failure(err));
		}
	};
}
