import React, { Component } from "react";
import { withTranslation } from "react-i18next";
class Dashboard extends Component {
	render() {
		return (
			<div className="container-fluid text-center noaccess">
				<h1>{this.props.t("DASHBOARD.TITLE")}</h1>
			</div>
		);
	}
}
export default withTranslation("common")(Dashboard);
