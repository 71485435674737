import React, { Component } from "react";
import { default as Config } from "../../../../../config/config";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { toast } from "react-toastify";
import { UserActions, ConfigAction } from "../../../../../store/action";
import InputField from "../../../../elements/InputField";

class EditList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectItems: {
				daily_limit: "0",
			},
			processListId: null,
			processId: null,
			selectedRule: {},
			campaign: [],
		};
	}
	componentDidMount = () => {
		const search = new URLSearchParams(window.location.search);
		const id = search.get("id");
		const process_id = search.get("process_id");
		this.setState({ processListId: id, processId: process_id });
		this.getConfigData();
		if (id) {
			const rulePost = {
				process_list_id: id,
			};
			this.props.actions.getRuleData(rulePost);
		}
	};
	static getDerivedStateFromProps = (nextProps, prevProps) => {
		const search = new URLSearchParams(window.location.search);
		const id = search.get("id");

		const updateProps = [];
		if (
			nextProps.campaignList &&
			nextProps.campaignList !== prevProps.campaign
		) {
			updateProps["campaign"] = nextProps.campaignList;
		}
		if (
			nextProps.selectedRule &&
			nextProps.selectedRule !== prevProps.selectedRule &&
			Object.keys(prevProps.selectedRule).length === 0 &&
			id
		) {
			updateProps["selectItems"] = { ...nextProps.selectedRule };
			updateProps["selectedRule"] = { ...nextProps.selectedRule };
		}
		return updateProps;
	};
	handleChange = (sname, ovalue) => {
		const { target } = ovalue;
		const { selectItems } = this.state;
		if (target) {
			const { value } = target;
			selectItems[sname] = value;
		} else {
			selectItems[sname] = ovalue.value;
		}
		if (!selectItems[sname]) delete selectItems[sname];
		this.setState({ selectItems: selectItems });
	};
	validateSubmit = () => {
		const {
			selectItems: {
				daily_limit,
				campaign_id,
				list_id,
				list_name,
				status,
			},
		} = this.state;
		let error = "";

		if (!list_name) error = this.props.t("EditList.ERROR.LIST_NAME");
		else if (!list_id) error = this.props.t("EditList.ERROR.LIST_ID");
		else if (list_id && Number.isNaN(Math.sign(list_id)))
			error = this.props.t("EditList.ERROR.LIST_ID_NUMBER");
		else if (list_id && Math.sign(list_id) === -1)
			error = this.props.t("EditList.ERROR.LIST_ID_POSITIVE");
		else if (!status) error = this.props.t("EditList.ERROR.STATUS");
		else if (!campaign_id)
			error = this.props.t("EditList.ERROR.CAMPAIGN_ID");
		// if(!status) error = this.props.t("EditList.ERROR.STATUS")
		else if (!daily_limit)
			error = this.props.t("EditList.ERROR.DAILY_LIMIT");
		else if (daily_limit && Number.isNaN(Math.sign(daily_limit)))
			error = this.props.t("EditList.ERROR.DAILY_LIMIT_NUMBER");
		else if (daily_limit && Math.sign(daily_limit) === -1)
			error = this.props.t("EditList.ERROR.DAILY_LIMIT_POSITIVE");
		if (error) {
			toast.error(error);
			return false;
		}
		return true;
	};
	getConfigData = () => {
		const requestBody = {
			config_data: ["process_list", "campaign_list"],
		};
		this.props.actions.getConfigData(requestBody);
	};
	handleCancel = () => {
		window.location.href = "/process-campaign-list";
	};
	handleSubmit = async (e) => {
		e.preventDefault();
		if (this.validateSubmit()) {
			const { selectItems, processId } = this.state;
			selectItems["process_id"] = processId;
			const {
				status,
				response,
			} = await this.props.actions.saveProcessCampaignList(selectItems);
			if (status) {
				toast.success(response);
				setTimeout(() => {
					window.location.href = "/process-campaign-list";
				}, 1000);
			}
		}
	};
	render() {
		const { selectItems, processListId, campaign, processId } = this.state;

		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-6 col-sm-offset-3">
						{processId && processListId && (
							<h1>{this.props.t("EditList.EDIT_LIST")}</h1>
						)}
						{processId && !processListId && (
							<h1>{this.props.t("EditList.ADD_LIST")}</h1>
						)}
						<div className="card">
							<div className="card-body">
								<div className="row">
									<div className="col-sm-6 col-md-6 ">
										<InputField
											inputProps={{
												id: "list_name",
												type: "text",
												value:
													selectItems["list_name"] ||
													"",
												name: "list_name",
												autoComplete: "off",
												placeholder: this.props.t(
													"EditList.LIST_NAME"
												),
												label: this.props.t(
													"EditList.LIST_NAME"
												),
												maxLength: 20,
											}}
											autoComplete="off"
											onChange={this.handleChange.bind(
												this,
												"list_name"
											)}
										/>
										<InputField
											inputProps={{
												id: "list_id",
												type: "text",
												value:
													selectItems["list_id"] ||
													"",
												name: "list_id",
												autoComplete: "off",
												placeholder: this.props.t(
													"EditList.LIST_ID"
												),
												label: this.props.t(
													"EditList.LIST_ID"
												),
												maxLength: 10,
											}}
											autoComplete="off"
											onChange={this.handleChange.bind(
												this,
												"list_id"
											)}
										/>
									</div>
									<div className="col-sm-6 col-md-6 form-group">
										<label htmlFor="active_sattus">
											{this.props.t(
												"EditList.ACTIVE_STATUS"
											)}
										</label>
										<Select
											id="active_sattus"
											onChange={this.handleChange.bind(
												this,
												"status"
											)}
											options={
												Config.constants.ACTIVE_STATUS
											}
											name="status"
											placeholder={this.props.t(
												"EditList.ACTIVE_STATUS"
											)}
											value={Config.constants.ACTIVE_STATUS.filter(
												({ value }) =>
													value === selectItems.status
											)}
											getOptionLabel={({ label }) =>
												label
											}
											getOptionValue={({ value }) =>
												value
											}
										/>
									</div>
									<div className="col-sm-6 col-md-6 form-group">
										<label htmlFor="campaign_id">
											{this.props.t(
												"EditList.CAMPAIGN_NAME"
											)}
										</label>
										<Select
											id="campaign_id"
											onChange={this.handleChange.bind(
												this,
												"campaign_id"
											)}
											options={campaign}
											name="campaign_id"
											placeholder={this.props.t(
												"EditList.CAMPAIGN_NAME"
											)}
											value={campaign.filter(
												({ value }) =>
													value ===
													selectItems.campaign_id
											)}
											getOptionLabel={({ label }) =>
												label
											}
											getOptionValue={({ value }) =>
												value
											}
										/>
									</div>
									<div className="col-sm-6 col-md-6">
										<InputField
											inputProps={{
												id: "daily_limit",
												type: "text",
												value:
													selectItems[
														"daily_limit"
													] || "",
												name: "daily_limit",
												autoComplete: "off",
												placeholder: this.props.t(
													"EditList.DAILY_LIMIT"
												),
												label: this.props.t(
													"EditList.DAILY_LIMIT"
												),
											}}
											autoComplete="off"
											onChange={this.handleChange.bind(
												this,
												"daily_limit"
											)}
										/>
									</div>
									<div className="col-sm-12 form-group">
										<label htmlFor="text-area-edit">
											{this.props.t(
												"EditList.DESCRIPTION"
											)}
										</label>
										<textarea
											id="text-area-edit"
											className="text-area-description form-control"
											onChange={this.handleChange.bind(
												this,
												"description"
											)}
											value={
												selectItems["description"] || ""
											}
										></textarea>
									</div>
									{processListId && (
										<div className="col-sm-2 form-group">
											<input
												type="submit"
												className="btn btn-primary"
												value={this.props.t(
													"EditList.UPDATE"
												)}
												onClick={this.handleSubmit}
											/>
										</div>
									)}
									{!processListId && (
										<div className="col-sm-2 form-group">
											<input
												type="submit"
												className="btn btn-primary"
												value={this.props.t(
													"EditList.CREATE"
												)}
												onClick={this.handleSubmit}
											/>
										</div>
									)}
									{
										<div className="col-sm-2 form-group">
											<input
												type="submit"
												className="btn btn-default"
												value={this.props.t(
													"EditList.CANCEL"
												)}
												onClick={this.handleCancel}
											/>
										</div>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ config, user }) => {
	return {
		campaignList:
			config.campaign_list instanceof Array
				? config.campaign_list.map(function (e) {
						return { label: e.name, value: e.id };
				  })
				: [],
		selectedRule: user.rule ? user.rule : [],
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			saveProcessCampaignList: bindActionCreators(
				UserActions.saveProcessCampaignList,
				dispatch
			),
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
			getRuleData: bindActionCreators(UserActions.getRuleData, dispatch),
		},
	};
};
export default withTranslation("webfrom")(
	connect(mapStateToProps, mapDispatchToProps)(EditList)
);
