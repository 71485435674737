export const InventoryConstants = {
	GET_MAKE_MODEL_VERSION: "GET_MAKE_MODEL_VERSION",
	GET_MAKE_MODEL_VERSION_ERR: "GET_MAKE_MODEL_VERSION_ERR",
	STATE_CITY_LIST: "STATE_CITY_LIST",
	STATE_CITY_LIST_ERR: "STATE_CITY_LIST_ERR",
	FILTER_FETCH: "FILTER_FETCH",
	FILTER_FETCH_ERR: "FILTER_FETCH_ERR",
	CITY_STATE: "CITY_STATE",
	CITY_STATE_ERR: "CITY_STATE_ERR",
	LOCALITY_DATA: "LOCALITY_DATA",
	LOCALITY_DATA_ERR: "LOCALITY_DATA_ERR",
};
