import React, { Component } from "react";

class SelectableActivity extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
		};
	}
	showAdd = () => {
		this.setState({ active: true });
	};
	hideAdd = () => {
		this.setState({ active: false });
	};
	handleAddElement = () => {
		const { value } = this.props.value;
		if (value) this.props.addElement(value);
	};
	handleRemoveElement = () => {
		const { value } = this.props.value;
		if (value) this.props.removeEvent(value);
	};
	render() {
		return (
			<div
				className={this.props.selected ? "selected" : ""}
				onMouseEnter={this.showAdd}
				onMouseLeave={this.hideAdd}
			>
				{this.props.type && this.props.type === "remove" && (
					<span onClick={this.handleRemoveElement}>
						{this.props.value ? this.props.value.label : ""}
						<span className="close">×</span>
					</span>
				)}
				{/* {this.props.type && this.props.type === "remove" && active && (
						 <span className="close">×</span>
						 <input 
							type="button"
							className="btn btn-reset"
							value="Remove"
						onClick={this.handleRemoveElement}
						/>
					)} */}

				{this.props.type && this.props.type !== "remove" && (
					<div
						className="addLabeled clearfix"
						onClick={this.handleAddElement}
					>
						{this.props.value ? this.props.value.label : ""}
						<span className="pull-right">
							<i className="ic-add-24px"></i>
						</span>
						{/* <input
							type="button"
							className="btn btn-primary"
							value="Add"
							onClick={this.handleAddElement}
						/> */}
					</div>
				)}
			</div>
		);
	}
}
export default SelectableActivity;
