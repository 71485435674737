import React, { Component } from "react";
import Select from "react-select";
import InputField from "../../elements/InputField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { InventoryAction, ConfigAction } from "./../../../store/action";
import { bindActionCreators } from "redux";
import { default as config } from "../../../config/config";

const dateType = [
	{ value: "audit_created_date", label: "Audit Created Date" },
	{ value: "calling_date", label: "Calling Date" },
];
const PurchaseMonth = [
	{ value: "01", label: "1" },
	{ value: "02", label: "2" },
	{ value: "03", label: "3" },
	{ value: "04", label: "4" },
	{ value: "05", label: "5" },
	{ value: "06", label: "6" },
	{ value: "07", label: "7" },
	{ value: "08", label: "8" },
	{ value: "09", label: "9" },
	{ value: "10", label: "10" },
	{ value: "11", label: "11" },
	{ value: "12", label: "12" },
];
class SearchAudit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			date: new Date(),
			searchMobile: false,
			LeadInfoData: {},
			filterData: {},
			tempFilterData: {},
			filterDataText: {},
			datePicker: {
				from_date_disabled: true,
				to_date_disabled: false,
			},
			status_list: [],
			sub_status_list: [],
			all_sub_status: [],
			gm_id: [],
			agent_list: [],
			Bought_Makes: [],
			make_list: [],
			model_list: [],
			lead_type_list: [],

			audit_user_list: [],
			process_list: [],
			dealer_list: [],
			loggedInUserRoleId: this.props.authUser.role_id,
			agent_role_id: config.constants.AGENT_ROLE_ID,
		};
	}
	componentDidMount = async () => {
		let {
			isUserTypeAgent,
			loggedInUserRoleId,
			agent_role_id
		} = this.state;
		isUserTypeAgent = loggedInUserRoleId === agent_role_id ? true : false;
		await this.setState({
			isUserTypeAgent: isUserTypeAgent,
		});
		await this.getConfigData();
		await this.props.actions.getMMV();
		this.handlePredisableInputs();
		
	};

	handlePredisableInputs = () => {
		// let { tempFilterData } = this.state;

		this.setState({
			advanceSearchEnable: false,
			searchMobile: false,
		});
	};

	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		if (props.process_list && props.process_list !== state.process_list) {
			updateProps["process_list"] = props.process_list;
		}
		if (props.filterData && props.filterData && props.filterData.toUpdate) {
			props.filterData.toUpdate = false;
			updateProps["tempFilterData"] = { ...props.filterData };
			if (props.filterData.date_type) {
				updateProps["datePicker"] = {
					from_date_disabled: false,
					to_date_disabled: false,
				};
			} else {
				updateProps["datePicker"] = {
					from_date_disabled: true,
					to_date_disabled: false,
				};
			}
		}

		if (
			props.filterDataText &&
			props.filterDataText !== state.filterDataText &&
			props.filterDataText.toUpdate
		) {
			props.filterDataText.toUpdate = false;
			updateProps["filterDataText"] = { ...props.filterDataText };
		}
		if (props.agent_list && props.agent_list !== state.agent_list) {
			updateProps["agent_list"] = props.agent_list;
		}
		if (props.Bought_Makes && props.Bought_Makes !== state.Bought_Makes) {
			updateProps["Bought_Makes"] = props.Bought_Makes;
		}
		if (
			props.audit_user_list &&
			props.audit_user_list !== state.audit_user_list
		) {
			updateProps["audit_user_list"] = props.audit_user_list;
		}
		if (props.dealer_list && props.dealer_list !== state.dealer_list) {
			updateProps["dealer_list"] = props.dealer_list;
		}

		return updateProps;

	}

	searchClose = () => {
		this.setState({ searchMobile: false });
	};
	searchShow = () => {
		this.setState({ searchMobile: true });
	};

	submitFilterForm = (event, reset = false) => {
		if (event) event.preventDefault();
		if (typeof this.props.onSubmitFilter === "function") {
			let { filterData, filterDataText, tempFilterData } = this.state;

			if (reset) {
				tempFilterData = {};
				filterDataText = {};
			}
			filterData = { ...tempFilterData };

			this.props.onSubmitFilter(filterData, filterDataText, reset, reset);
		}
	};

	ResetFilterForm = (event) => {
		///////////////////function to reset filter form///////////////
		event.preventDefault();
		this.handlePredisableInputs();
		this.submitFilterForm(null, true);
		// this.props.onSubmitFilter(filterData, {});
	};
	handleDependentActions = (sname, ovalue = null) => {
		let { datePicker, tempFilterData } = this.state;
		let filter_model = [];
		switch (sname) {
			case "date_type":
				datePicker.from_date_disabled = false;
				if (!tempFilterData.date_type) {
					datePicker.from_date_disabled = true;
					//datePicker.to_date_disabled = true;
					delete tempFilterData.start_date;
					delete tempFilterData.end_date;
				}
				delete tempFilterData.start_date;
				delete tempFilterData.end_date;
				break;
			case "start_date":
				datePicker.to_date_disabled = false;
				delete tempFilterData.end_date;
				break;
			case "end_date":
				break;
			default:
		}
		this.setState({
			datePicker: datePicker,
		});
	};

	handleChange = (sname, ovalue) => {
		const { target } = ovalue ? ovalue : {};

		let { tempFilterData, filterDataText } = this.state;
		if (target && target.value) {
			///////////////text box case////////////
			const { value, type } = target;
			tempFilterData[sname] = value;
			filterDataText[sname] = value;
		} else if (ovalue && ovalue.value) {
			///////////////////select case////////////////
			tempFilterData[sname] = ovalue.value;
			filterDataText[sname] = ovalue.label;
		} else if (sname instanceof Date) {
			////////////////date picker case////////////////
			tempFilterData[ovalue] = new Date(sname);
			sname = ovalue;
		} else if (ovalue instanceof Array && ovalue.length > 0) {
			//////////////multiselect case////////////////////

			///////fetch new value added to list

			tempFilterData[sname] = ovalue.map((e) => e.value);
			filterDataText[sname] = ovalue.map((e) => e.label).join(",");
		} else if (ovalue && ovalue.id) {
			tempFilterData[sname] = ovalue.id;
			filterDataText[sname] = ovalue.make ? ovalue.make : ovalue.label;
		} else {
			////////////////delete request////////////////

			delete tempFilterData[sname];
			delete filterDataText[sname];
		}
		/////////////////////default/Eveyrun Case////////////////////////

		if (!tempFilterData[sname]) delete tempFilterData[sname];

		if (["date_type", "start_date", "end_date"].includes(sname))
			this.handleDependentActions(sname, ovalue);

		this.setState({
			tempFilterData: tempFilterData,
			filterDataText: filterDataText,
		});
	};
	// get config
	getConfigData = async () => {
		const requestBody = {
			config_data: [
				"gm_campaign_process_list",
				"agent_list",
				"gm_campaign_list",
				"audit_user_list",
				"dealer_list"
			],
		};
		this.props.actions.getConfigData(requestBody);
	};
	fetchSuggestions = ({ value }) => {
		const inputValue = value.trim().toLowerCase();
		const inputLength = inputValue.length;
		const { dse_id } = this.state;
		const suggestedValues =
			inputLength === 0
				? []
				: dse_id.filter((e) =>
						e.label.toLowerCase().includes(inputValue)
				  );

		this.setState({
			dse_suggestions: suggestedValues,
		});
	};
	onSuggestionCLear = () => {
		this.setState({
			dse_suggestions: [],
		});
	};
	getSuggestedValue = (value) => {
		const target = {
			target: {
				value: value.label,
			},
		};

		this.handleChange("dse_name", target);
	};

	customFilter(option, searchText) {
		if (
			option.data.label
				.toLowerCase()
				.includes(searchText.toLowerCase()) ||
			option.data.mobile.toLowerCase().includes(searchText.toLowerCase())
		) {
			return true;
		} else {
			return false;
		}
	}

	render() {
		let {
			tempFilterData,
			agent_list,
			Bought_Makes,
			audit_user_list,
			process_list,
			dealer_list,
			isUserTypeAgent
		} = this.state;
		const {
			AUDIT_PRIORITY,
		} = config.constants;
		const authUser = this.props.authUser;
		if(isUserTypeAgent)
			tempFilterData.agent_id = authUser.user_id;
		let isDateFieldDisabled = (tempFilterData && tempFilterData.date_type) ? false : true
		return (
			<>
				<div
					className={
						this.state.searchMobile === true
							? "search-wrap open"
							: "search-wrap"
					}
				>
					<div className="searchClose">
						<span onClick={this.searchClose}>
							<i className="ic-arrow_back-black-24dp"></i>
						</span>
						Filters
						<span
							className="closefilter"
							onClick={this.searchClose}
						>
							<i className="ic-close-24px"></i>
						</span>
					</div>
					<form onSubmit={this.submitFilterForm}>
						<ul className="search-flex">
							<li className="searchitems selectWidth">
								<InputField
									inputProps={{
										id: "search",
										type: "text",
										value: tempFilterData.search || "",
										name: "search",
										autoComplete: "off",
										placeholder: this.props.t(
											"SEARCH_AUDIT.SEARCH_BY_TEXT"
										),
										label: this.props.t(
											"SEARCH_AUDIT.SEARCH_BY_LABEL"
										),
									}}
									autoComplete="off"
									onChange={this.handleChange.bind(
										this,
										"search"
									)}
								/>
							</li>
							<li className="searchitems selectWidth form-group">
								<Select
									id="date_type"
									onChange={this.handleChange.bind(
										this,
										"date_type"
									)}
									isClearable
									options={dateType}
									name="date_type"
									placeholder={this.props.t(
										"SEARCH_AUDIT.DATE_TYPE"
									)}
									value={
										tempFilterData.date_type
											? dateType.filter(
													({ value }) =>
														value ===
														tempFilterData.date_type
											  )
											: ""
									}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
								/>
							</li>
							<li className="searchitems selectWidth form-group">
								<DatePicker
									showYearDropdown
									showMonthDropdown
									selected={tempFilterData.start_date || ""}
									onChange={(date) =>
										this.handleChange(date, "start_date")
									}
									name="start_date"
									id="start_date"
									autoComplete="off"
									placeholderText={this.props.t(
										"SEARCH_AUDIT.FROM"
									)}
									dateFormat={
										config.constants.DATE_FORMAT_INPUT
									}
									className="form-control"
									maxDate={new Date()}
									onKeyDown={(e) => e.preventDefault()}
									disabled={isDateFieldDisabled}
								/>
							</li>
							<li className="searchitems selectWidth form-group">
								<DatePicker
									showYearDropdown
									showMonthDropdown
									selected={tempFilterData.end_date || ""}
									onChange={(date) =>
										this.handleChange(date, "end_date")
									}
									name="end_date"
									id="end_date"
									autoComplete="off"
									placeholderText={this.props.t(
										"SEARCH_AUDIT.TO"
									)}
									dateFormat={
										config.constants.DATE_FORMAT_INPUT
									}
									className="form-control"
									minDate={tempFilterData.start_date || ""}
									maxDate={new Date()}
									onKeyDown={(e) => e.preventDefault()}
									disabled={isDateFieldDisabled}
								/>
							</li>

							<li className="searchitems selectWidth form-group">
								<Select
									id="audit_by"
									onChange={this.handleChange.bind(
										this,
										"audit_user_id"
									)}
									isClearable
									options={audit_user_list}
									name="audit_user_id"
									placeholder={this.props.t(
										"SEARCH_AUDIT.AUDIT_BY"
									)}
									value={
										tempFilterData.audit_user_id
											? audit_user_list.filter(
													({ value }) =>
														value ===
														tempFilterData.audit_user_id
											  )
											: ""
									}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
								/>
							</li>
							<li className="searchitems selectWidth form-group">
								<Select
									id="agent_id"
									onChange={this.handleChange.bind(
										this,
										"agent_id"
									)}
									isClearable
									options={agent_list}
									name="agent_id"
									placeholder={this.props.t(
										"SEARCH_AUDIT.CALLING_AGENT"
									)}
									value={
										tempFilterData.agent_id
											? agent_list.filter(
													({ value }) =>
														value ===
														tempFilterData.agent_id
											  )
											: ""
									}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
									isDisabled={isUserTypeAgent}
								/>
							</li>

							<li className="searchitems selectWidth form-group">
								<Select
									id="audit_priority"
									onChange={this.handleChange.bind(
										this,
										"audit_priority"
									)}
									isClearable
									options={AUDIT_PRIORITY}
									name="audit_priority"
									placeholder={this.props.t(
										"SEARCH_AUDIT.AUDIT_PRIORITY"
									)}
									value={
										tempFilterData.audit_priority
											? AUDIT_PRIORITY.filter(
													({ value }) =>
														value ===
														tempFilterData.audit_priority
											  )
											: ""
									}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
								/>
							</li>
							<li className="searchitems selectWidth form-group">
								<Select
									id="process_id"
									onChange={this.handleChange.bind(
										this,
										"process_id"
									)}
									isClearable
									options={process_list}
									name="process_id"
									placeholder={this.props.t(
										"SEARCH_AUDIT.PROCESS_NAME"
									)}
									value={
										tempFilterData.process_id
											? process_list.filter(
													({ value }) =>
														value ===
														tempFilterData.process_id
											  )
											: ""
									}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
								/>
							</li>
							<li className="searchitems selectWidth form-group">
								<Select
									id="bought_makeid"
									onChange={this.handleChange.bind(
										this,
										"bought_makeid"
									)}
									isClearable
									options={Bought_Makes}
									name="bought_makeid"
									placeholder={this.props.t(
										"SEARCH_AUDIT.BOUGHT_MAKE"
									)}
									value={
										tempFilterData.bought_makeid
											? Bought_Makes.filter(
													({ id }) =>
														id ===
														tempFilterData.bought_makeid
											  )
											: ""
									}
									getOptionLabel={({ make }) => make}
									getOptionValue={({ id }) => id}
								/>
							</li>

							<li className="searchitems selectWidth form-group">
								<Select
									id="dealer_id"
									onChange={this.handleChange.bind(
										this,
										"dealer_id"
									)}
									isClearable
									options={dealer_list}
									name="dealer_id"
									placeholder={this.props.t(
										"SEARCH_AUDIT.DEALERSHIP"
									)}
									value={
										tempFilterData.dealer_id
											? dealer_list.filter(
													({ value }) =>
														value ===
														tempFilterData.dealer_id
											  )
											: ""
									}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
								/>
							</li>
							<li className="searchitems selectWidth form-group">
								<Select
									id="purchase_month"
									onChange={this.handleChange.bind(
										this,
										"purchase_month"
									)}
									isClearable
									options={PurchaseMonth}
									name="purchase_month"
									placeholder={this.props.t(
										"SEARCH_AUDIT.PURCHASE_MONTH"
									)}
									value={
										tempFilterData.purchase_month
											? PurchaseMonth.filter(
													({ value }) =>
														value ===
														tempFilterData.purchase_month
											  )
											: ""
									}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
								/>
							</li>

							<li className="searchitems form-group searchBtns">
								<button
									type="submit"
									className="btn btn-primary"
								>
									{this.props.t("SEARCH_AUDIT.SEARCH")}
								</button>
								<button
									type="reset"
									className="btn btn-default btn-reset mrg-l10"
									onClick={this.ResetFilterForm}
								>
									{this.props.t("SEARCH_AUDIT.RESET")}
								</button>
							</li>
						</ul>
					</form>
				</div>
				<span
					className="searchFilter floating-btn"
					onClick={this.searchShow}
				>
					<i className="ic-search-24px"></i>
				</span>
			</>
		);
	}
}
const mapStateToProps = ({ config, inventory }) => {
	return {
		audit_user_list:
			config.audit_user_list instanceof Array
				? config.audit_user_list
						.filter((f) => [1, 5, 7].includes(f.role_id))
						.map((e) => {
							return {
								label: e.name,
								value: e.id,
							};
						})
				: [],

		process_list:
			config.gm_campaign_process_list instanceof Array
				? config.gm_campaign_process_list.map(function (e) {
						return {
							label: `${
								e.campaign_name
									? e.campaign_name.charAt(0).toUpperCase() +
									  e.campaign_name.slice(1)
									: ""
							}`,
							value: e.id,
						};
				  })
				: [],

		agent_list:
			config.agent_list instanceof Array
				? config.agent_list.map((e) => {
						return {
							label: e.name,
							value: e.id,
						};
				  })
				: [],
		Bought_Makes:
			inventory.mmv && inventory.mmv.make ? inventory.mmv.make : [],
		
		dealer_list:
		config.dealer_list instanceof Array
			? config.dealer_list.map((e) => {
					return {
						label: e.name,
						value: e.dealer_id,
						organization: e.organization,
					};
				})
			: [],
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getMMV: bindActionCreators(InventoryAction.getMMV, dispatch),
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
		},
	};
};
export default withTranslation("audit")(
	connect(mapStateToProps, mapDispatchToProps)(SearchAudit)
);
