import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import PageLoader from "../../elements/PageLoader";
import { connect } from "react-redux";
import Activity from "../activity/Activity";
class ActivityResult extends Component {
	constructor(props) {
		super(props);
		this.state = {
			historyData: this.props.historyData,
			loading: this.props.loading,
			hasMoreItems: this.props.hasMoreItems,
		};
	}

	static getDerivedStateFromProps = (nextProps, prevState) => {
		let updatedStateData = {};
		if (nextProps.historyData !== prevState.historyData) {
			updatedStateData["historyData"] = nextProps.historyData;
		}
		if (nextProps.loading !== prevState.loading) {
			updatedStateData["loading"] = nextProps.loading;
		}
		if (nextProps.hasMoreItems !== prevState.hasMoreItems) {
			updatedStateData["hasMoreItems"] = nextProps.hasMoreItems;
		}
		return updatedStateData;
	};

	loadMoreitems = async () => {
		if (typeof this.props.loadMoreitems === "function") {
			this.props.loadMoreitems();
		}
	};

	render() {
		const { historyData, hasMoreItems, loading } = this.state;
		const { mmv, assignCarList } = this.props;
		let carlist = (assignCarList && assignCarList.record) || [];
		return (
			<div ref={(ref) => (this.scrollParentRef = ref)}>
				<div className="table-responsive">
					<InfiniteScroll
						pageStart={0}
						loadMore={this.loadMoreitems.bind(this)}
						hasMore={hasMoreItems || false}
						loader={
							loading && (
								<PageLoader loaderClass="text-center" key={0} />
							)
						}
						getScrollParent={() => this.scrollParentRef}
						useWindow={false}
					>
						<table className="table table-bordered-h table-hover recommendedTable prevActTable">
							<thead>
								<tr>
									<th>
										{this.props.t(
											"HISTORY.TBL_DETAILS.UPDATE_DETAILS"
										)}
									</th>
									<th>
										{this.props.t(
											"HISTORY.TBL_DETAILS.CHANGE_DETAILS"
										)}
									</th>
									<th>
										{this.props.t(
											"HISTORY.TBL_DETAILS.COMMENTS"
										)}
									</th>
								</tr>
							</thead>

							<tbody>
								{historyData &&
								historyData.length === 0 &&
								!loading ? (
									<tr>
										<td align="center" colSpan="3">
											<h6 className="text-center text-danger text-bold">
												{this.props.t(
													"HISTORY.TBL_DETAILS.NO_RECORD_FOUND"
												)}
											</h6>
										</td>
									</tr>
								) : null}

								<Activity historyData={historyData} />
							</tbody>
						</table>
					</InfiniteScroll>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ lead, inventory }) => {
	return {
		assignCarList: lead.assigned_cars,
		mmv: inventory.mmv,
	};
};
export default withTranslation("lead_detail")(
	connect(mapStateToProps)(ActivityResult)
);
//export default withTranslation("lead_detail")(ActivityResult);
