import MasterService from "./MasterService";

export const webFormService = {
	...MasterService,

	getCampaignList(postData) {
		return this.post("/webform/get_campaign_list", postData);
	},
	getCampaign(postData) {
		return this.post("/webform/get_campaign", postData);
	},
	saveCampaign(postData) {
		return this.post("/webform/save_campaign", postData);
	},
	getProcessList(postData) {
		return this.post("/webform/list_process", postData);
	},
	saveProcess(postData) {
		return this.post("/webform/save_process", postData);
	},
	getDomainList(postData) {
		return this.post("/webform/list_domain", postData);
	},
	saveDomain(postData) {
		return this.post("/webform/save_domain", postData);
	},
	getwebidsList(postData) {
		return this.post("/webform/get_webids_list", postData);
	},
	saveWebids(postData) {
		return this.post("/webform/save_webids", postData);
	},
	getUtmList(postData) {
		return this.post("/webform/utm_campaign_list", postData);
	},
	saveUtm(postData) {
		return this.post("/webform/save_utmcampaign", postData);
	},
	updateCampaignStatus(postData) {
		return this.post("/webform/update_campaign_status", postData);
	},
	updateWebidsStatus(postData) {
		return this.post("/webform/update_webids_status", postData);
	},
	updateProcessStatus(postData) {
		return this.post("/webform/save_process", postData);
	},
	getLeadTypeList(postData) {
		return this.post("/webform/lead_type_list", postData);
	},
	updateLeadType(postData) {
		return this.post("/webform/save_lead_type", postData);
	},
};
