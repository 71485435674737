export const UserConstants = {
	SAVE_RULE: "SAVE_RULE",
	SAVE_RULE_ERR: "SAVE_RULE_ERR",
	GET_RULE: "GET_RULE",
	GET_RULE_ERR: "GET_RULE_ERR",
	GET_PROCESS_CAMPAIGN_LIST: "GET_PROCESS_CAMPAIGN_LIST",
	GET_PROCESS_CAMPAIGN_LIST_ERR: "GET_PROCESS_CAMPAIGN_LIST_ERR",
	GET_BLACK_LIST: "GET_BLACK_LIST",
	GET_BLACK_LIST_ERR: "GET_BLACK_LIST_ERR",
	GET_CAMPAIGN_INFO: "GET_CAMPAIGN_INFO",
	GET_CAMPAIGN_INFO_ERR: "GET_CAMPAIGN_INFO_ERR",
	GET_CAMPAIGN_PACK_LIST: "GET_CAMPAIGN_PACK_LIST",
	GET_CAMPAIGN_PACK_LIST_ERR: "GET_CAMPAIGN_PACK_LIST_ERR",
	GET_ASSIGNED_PACKS: "GET_ASSIGNED_PACKS",
	GET_ASSIGNED_PACKS_ERR: "GET_ASSIGNED_PACKS_ERR",
	GET_ASSOCIATED_GM_DSE: "GET_ASSOCIATED_GM_DSE",
	GET_ASSOCIATED_GM_DSE_ERR: "GET_ASSOCIATED_GM_DSE_ERR",
};
