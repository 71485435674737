/* eslint-disable */
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { ReportService } from "../../../../service/reportService";
import { CSVLink, CSVDownload } from "react-csv";
import PageLoader from "../../../elements/PageLoader";
import AssignCarReportSearch from "./AssignCarReportSearch";
import dateformat from "dateformat";
import { default as config } from "../../../../config/config";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ConfigAction, LeadActions } from "../../../../store/action";
import secureStorage from "../../../../config/encrypt";
class AssignCarReport extends Component {
	csvLink = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			subHeaders: [],
			csvJsonData: [],
			loading: false,
		};
	}

	componentDidMount = () => {};

	submitRequest = async (filterData) => {
		let country_code = secureStorage.getItem("countryCode");
		this.setState({ loading: true });
		let Result = await ReportService.getAllLeads(filterData);
		if (Result && Result.status === 200 && Result.data) {
			let data = Result.data.data || [];
			let { make_map = {}, model_map = {}, city_map = {} } = this.props;
			let report_data = [];
			if (data.length) {
				for (let i of data) {
					let crm_flags = [];
					if (i.rf === "1") crm_flags.push("RF");
					if (i.ncf === "1") crm_flags.push("NCF");
					if (i.eaf === "1") crm_flags.push("EAF");
					report_data.push({
						"CRM ID": i.crm_id,
						"Request Id": i.request_id,
						"Creation Date": Date.parse(i.created_date)
							? dateformat(
									i.created_date,
									config.constants.DATE_FORMAT_YMD_REPORT
							  )
							: "",
						"Triggerd Date": Date.parse(i.triggered_date)
							? dateformat(
									i.triggered_date,
									config.constants.DATE_FORMAT_YMD_REPORT
							  )
							: "",
						"Lead Name": i.lead_name,
						"Lead Phone Number": i.lead_mobile,
						Brand: i.make_id ? make_map[i.make_id] : "",
						Model: i.model_id ? model_map[i.model_id] : "",
						City: i.city_id ? city_map[i.city_id] : "",
						"Current State": i.current_state,
						"Total Agent Attempts": i.total_agent_attempts,
						"Total Agent Connects": i.total_agent_connect,
						"Total Talk time / Total Call Duration": "",
						"Average Call Duration": "",
						"Longest Call Duration": "",
						"Days since last attempt": i.days_since_last_attempt,
						"Days since last connect": i.days_sicnce_last_connect,
						"Create Source": i.created_source, //oto,crm
						"Enquiry Source": i.enquiry_source, //notknown
						...(country_code === "ph" && {
							"LMS - Booking Done": i.lmd_spk_done,
						}),
						...(country_code !== "ph" && {
							"LMS - SPK Done": i.lmd_spk_done,
						}),
						...(country_code === "ph" && {
							"CRM - Booking Done": i.crm_spk_done,
						}),
						...(country_code !== "ph" && {
							"CRM - SPK Done": i.crm_spk_done,
						}),
						...(country_code === "ph" && {
							"LMS - Booking Date": Date.parse(i.lms_spk_date)
								? dateformat(
										i.lms_spk_date,
										config.constants.DATE_FORMAT_YMD_REPORT
								  )
								: "",
						}),
						...(country_code !== "ph" && {
							"LMS - SPK Date": Date.parse(i.lms_spk_date)
								? dateformat(
										i.lms_spk_date,
										config.constants.DATE_FORMAT_YMD_REPORT
								  )
								: "",
						}),
						"DSE Id": i.dse_id,
						"Assigned DSE Phone number": i.assigned_dse_phone,
						"Assigned DSE Name": i.assigned_dse_name,
						"Dealer Name": i.assigned_dealer_name,
						"Dealer Group": "",
						"Lead Created Month": i.lead_created_month,
						"Lead Triggered Month": i.lead_triggered_month,
						"CRM Status": i.crm_current_status,
						"CRM Sub Status": i.crm_current_sub_status,
						"Purchased Date": Date.parse(i.purchase_date)
							? dateformat(
									i.purchase_date,
									config.constants.DATE_FORMAT_YMD_REPORT
							  )
							: "",
						...(country_code === "ph" && {
							"Booking happens after X Days": i.spk_happen_days,
							"Booking time flag": i.spk_time,
						}),
						...(country_code !== "ph" && {
							"SPK happens after X Days": i.spk_happen_days,
							"SPK time flag": i.spk_time,
						}),
						"Utm Medium": i.utm_medium,
						"Utm Campaign": i.utm_campaign,
						"utm_type / SOURCE": i.utm_source,
						"CRM Campaign Name": i.crm_campaign_name,
						"Purchased New Car?": i.purchase_new_car,
						"Purchase Used Car": i.purchase_used_car,
						"Has KTP ID?": "", //unknwon
						"Booking Name": i.booking_name,
						"Booking Relation": i.booking_relation,
						"Booking Mobile": i.booking_mobile,
						"Booking Brand": i.purchase_make
							? make_map[i.purchase_make]
							: "",
						"Booking Model": i.purchase_model
							? model_map[i.purchase_model]
							: "",
						"Booking City": i.booking_city_id
							? city_map[i.booking_city_id]
							: "",
						"Campaign ID": i.campaign_id ? i.campaign_id : "",
						"Booking Date": i.booking_date || "",
						"Last Updated By": i.agent_name || "",
						"Status Reason": i.status_reason || "",
						"CRM Flag": crm_flags.length
							? crm_flags.join(",")
							: "None",
					});

					// Booking City
					// Booking Mobile
					// Booking Relation
					// Booking Name
					// Has KTP ID?
					// utm_type / SOURCE
					// Dealer Group
					// SPK time flag
				}
				// report_data = data;
			}
			this.setState(
				{ loading: false, csvJsonData: report_data },
				async () => {
					if (report_data instanceof Array && report_data.length) {
						this.csvLink.current.link.click();
						await this.props.actions.SaveDownloadLog({
							module: "LeadRawData",
							action: "1",
						});
					}
				}
			);
		} else {
			this.setState({ loading: false, csvJsonData: [] });
		}
	};

	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		if (props.make_map && props.make_map !== state.make_map) {
			updateProps["make_map"] = props.make_map;
		}
		if (props.model_map && props.model_map !== state.model_map) {
			updateProps["model_map"] = props.model_map;
		}
		if (props.city_map && props.city_map !== state.city_map) {
			updateProps["city_map"] = props.city_map;
		}
		return updateProps;
	}

	render() {
		const { csvJsonData, loading } = this.state;
		return (
			<div>
				<AssignCarReportSearch onSubmitFilter={this.submitRequest} />

				<div className="hidden">
					<CSVLink
						className="hidden"
						ref={this.csvLink}
						filename="Lead Raw Data.csv"
						data={csvJsonData}
					>
						{" "}
					</CSVLink>
				</div>

				<div className="container-fluid reportContainer">
					<h2>{this.props.t("ASSIGN_CAR.HEADING")}</h2>
					<div className="result-wrap">
						{loading && (
							<PageLoader
								loaderClass=" text-center loader content-loader"
								loadertext="false"
							/>
						)}
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ config, inventory }) => {
	return {
		make_map: inventory.make_map ? inventory.make_map : {},
		model_map: inventory.model_map ? inventory.model_map : {},
		city_map:
			inventory.city instanceof Array
				? inventory.city.reduce(
						(obj, item) => ((obj[item.id] = item.name), obj),
						{}
				  )
				: {},
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
			SaveDownloadLog: bindActionCreators(
				LeadActions.SaveDownloadLog,
				dispatch
			),
		},
	};
};
export default withTranslation("report")(
	connect(mapStateToProps, mapDispatchToProps)(AssignCarReport)
);
