import MasterService from "./MasterService";

export const FordReportService = {
	...MasterService,

	getList(postData) {
		return this.post("/ford_report/get_list", postData);
	},
	getDetail(postData) {
		return this.post("/ford_report/get_detail", postData);
	},
	saveMapping(postData) {
		return this.post("/ford_report/save_mapping", postData);
	},
	updateStatus(postData) {
		return this.post("/ford_report/update_status", postData);
	},
	getSmList(postData) {
		return this.post("/ford_report/get_sm_list", postData);
	},
	getSmDetail(postData) {
		return this.post("/ford_report/get_sm_detail", postData);
	},
	saveSm(postData) {
		return this.post("/ford_report/save_sm", postData);
	},
	updateSmStatus(postData) {
		return this.post("/ford_report/update_sm_status", postData);
	},
	getDealerList(postData) {
		return this.post("/ford_report/get_dealer_list", postData);
	},
	getDealerDetail(postData) {
		return this.post("/ford_report/get_dealer_detail", postData);
	},
	saveDealer(postData) {
		return this.post("/ford_report/save_dealer", postData);
	},
	updateDealerStatus(postData) {
		return this.post("/ford_report/update_dealer_status", postData);
	},
};
