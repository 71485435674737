/* eslint-disable */
import React, { Component } from "react";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
	LeadActions,
	ConfigAction,
	InventoryAction,
} from "./../../../store/action";
import { bindActionCreators } from "redux";
import PageLoader from "../../elements/PageLoader";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroller";
import { default as config } from "../../../config/config";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { LeadService } from "../../../service/leadService";
import _ from "lodash";
import dateformat from "dateformat";
//import config from "../../../config/config";
class PreferenceForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showTab: "1",
			addNewCarState: false,
			preferenceData: [],
			pagination: [],
			loading: false,
			page: {},
			modalStatus: [],
			sources: [],
			mm: [],
			make_model: {},
			city: null,
			dealers: [],
			make_map: [],
			model_map: [],
			hasMoreItems: true,
			lead_id: new URLSearchParams(window.location.search).get("id"),
			count: 0,
			city_list: [],
			errors: {},
			dse_list: [],
			lead_detail: {},
			isCityRest: false,
			tempPreferenceList: [],
			assignCarList: [],
		};
	}
	componentDidMount = async () => {
		// await this.getPreferenceList(0);
		// const leadId = new URLSearchParams(window.location.search).get("id");
		// if (leadId) {
		// 	this.setState({ lead_id: leadId });
		// }

		if (
			!(
				this.props.modalStatus &&
				this.props.modalStatus instanceof Array &&
				this.props.modalStatus.length
			)
		) {
			await this.props.actions.getAllModalStatus();
		}
		if (
			!(
				this.props.sources &&
				this.props.sources instanceof Array &&
				this.props.sources.length
			)
		) {
			await this.props.actions.getAllSource();
		}
		if (
			!(
				this.props.mm &&
				this.props.mm instanceof Array &&
				this.props.mm.length
			)
		) {
			await this.props.actions.getMMV();
		}
		const { preferenceList } = this.props;
		const preferCars = [...preferenceList];
		this.setState({
			preferenceData: preferCars,
			tempPreferenceList: preferCars,
		});
	};
	componentDidUpdate = () => {
		if (this.props.preferenceList !== this.state.preferenceData) {
			this.setState({ preferenceData: this.props.preferenceList });
		}
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		const updatedState = {};
		if (prevState.modalStatus !== nextProps.modalStatus) {
			updatedState["modalStatus"] = nextProps.modalStatus;
		}
		if (prevState.sources !== nextProps.sources) {
			updatedState["sources"] = nextProps.sources;
		}
		if (prevState.mm !== nextProps.mm) {
			updatedState["mm"] = nextProps.mm;
		}
		if (prevState.make_map !== nextProps.make_map) {
			updatedState["make_map"] = nextProps.make_map;
		}
		if (prevState.model_map !== nextProps.model_map) {
			updatedState["model_map"] = nextProps.model_map;
		}

		if (prevState.city_list !== nextProps.city_list) {
			updatedState["city_list"] = nextProps.city_list;
		}
		if (
			nextProps &&
			nextProps.lead_detail &&
			nextProps.lead_detail.lead_city !== prevState.city &&
			!prevState.city &&
			!prevState.isCityRest &&
			nextProps.lead_detail.lead_city &&
			nextProps.lead_detail.lead_city > 0
		) {
			updatedState["city"] = nextProps.lead_detail.lead_city;
		}
		if (
			nextProps.assignCarList &&
			nextProps.assignCarList.record !== prevState.assignCarList
		) {
			updatedState["assignCarList"] = nextProps.assignCarList.record;
		}
		return updatedState;
	}

	handleChangeModalStatus = ({ make_id, model_id, index }, value) => {
		const { preferenceData } = this.state;
		preferenceData[index].model_status_id = value;
		// for (let i of preferenceData) {
		// 	if (+i.make_id === +make_id && +i.model_id === +model_id) {
		// 		i.model_status_id = value;
		// 		break;
		// 	}
		// }
		this.setState({ preferenceData: preferenceData });
	};
	handleChangePriorityStatus = (make_id, model_id, order, value) => {
		const { preferenceData } = this.state;
		const orderToAssign = order + value;

		if (orderToAssign >= 1 && orderToAssign <= preferenceData.length) {
			//////////get order of item at current pos/////////////
			for (let i of preferenceData) {
				if (i.sort_order === orderToAssign) {
					i.sort_order = order;
					break;
				}
			}
			/////////////assign new order//////////////////
			for (let i of preferenceData) {
				if (+i.make_id === +make_id && +i.model_id === +model_id) {
					i.sort_order = orderToAssign;
					break;
				}
			}

			preferenceData.sort((e, f) => e.sort_order - f.sort_order);
			this.setState({ preferenceData: preferenceData });
		} else {
			toast.error(
				this.props.t("LEAD_DETAIL_PREFERENCE.ERROR.UPDATE_SORT")
			);
		}
	};

	handleChange = (sname, obj, ovalue) => {
		let state = {};
		let { errors, make_model, city } = this.state;
		if (ovalue) {
			if (sname === "model_status") {
				this.handleChangeModalStatus(obj, ovalue.value);
			}
			if (sname === "make_model") {
				errors["make_model"] = "";
				state = {
					make_model: ovalue,
					errors: errors,
				};
				if (city)
					this.getDseList({
						city_id: city,
						make_id: ovalue.mk_id,
						is_pagination_required: false,
					});
			} else if (sname === "city") {
				errors["city"] = "";
				state = {
					city: ovalue.id,
					errors: errors,
				};
				if (make_model && make_model.mk_id)
					this.getDseList({
						city_id: ovalue.id,
						make_id: make_model.mk_id,
						is_pagination_required: false,
					});
			} else {
				state = {
					[sname]: ovalue.value,
				};
			}
		} else {
			if (sname === "make_model") state = { make_model: {} };
			if (sname === "city") {
				state = { city: null, isCityRest: true };
			}
			if (sname === "model_status") state = { model_status_id: null };
		}
		this.setState({ ...state });
	};
	handleUpdatePreference = async () => {
		const { preferenceData, tempPreferenceList } = this.state;
		let modalFlag = 1,
			preferencArr = [];

		for (let elm of tempPreferenceList) {
			if (elm.dse_id) {
				let checkForExistingMakeModel = _.find(preferenceData, {
					city_id: elm.city_id,
					make_id: elm.make_id,
					model_id: elm.model_id,
					lead_id: elm.lead_id,
				});
				if (
					checkForExistingMakeModel &&
					Number(elm.dse_id) !==
						Number(checkForExistingMakeModel.dse_id)
				) {
					elm.model_status_id =
						checkForExistingMakeModel.model_status_id;
					elm.sort_order = checkForExistingMakeModel.sort_order;
					//preferenceData.push(elm)
				}
			}
			preferencArr.push(elm);
		}
		for (let e of preferencArr) {
			delete e.mobile;
			delete e.created_date;
			delete e.updated_date;
			if (!e.dse_id) delete e.dse_id;
			if (!e.city_id) delete e.city_id;
			if (!e.model_status_id) modalFlag = 0;
		}
		if (modalFlag === 0) {
			toast.error(
				this.props.t(
					"LEAD_DETAIL_PREFERENCE.ERROR.MODAL_STATUS_IS_REQUIRED"
				)
			);
			return;
		}
		const postData = {
			lead_id: this.state.lead_id,
			car_preference: preferencArr,
		};
		const { status, response } = await this.props.actions.updatePreferences(
			postData
		);
		if (status) {
			await this.props.actions.updateHistoryFlag(true);
			toast.success(response);
			this.props.getPreferenceList(true); //true is used to call get assign car
			this.props.modalClose();
		} else {
			if (response) toast.error(response);
			else
				toast.error(
					this.props.t("LEAD_DETAIL_PREFERENCE.ERROR.BAD_GATEWAY")
				);
		}
	};
	getPreferenceList = async (cur_page = 0, reset = false) => {
		this.props.getPreferenceList();
	};
	validateAddNewCar = () => {
		const { make_model, city, errors } = this.state;
		let formIsValid = true;
		if (make_model && Object.keys(make_model).length === 0) {
			errors["make_model"] = this.props.t(
				"LEAD_DETAIL_PREFERENCE.ERROR.MAKE_MODEL_REQUIRED"
			);
			formIsValid = false;
		}
		if (!city) {
			errors["city"] = this.props.t(
				"LEAD_DETAIL_PREFERENCE.ERROR.CITY_REQUIRED"
			);
			formIsValid = false;
		}

		this.setState({ errors });
		return formIsValid;
	};
	handleAddNewCar = async () => {
		const validated = this.validateAddNewCar();
		if (validated) {
			let {
				preferenceData,
				lead_id,
				make_model: { mk_id: make_id, value: model_id },
				city,
				dealers,
				tempPreferenceList,
				assignCarList,
			} = this.state;
			tempPreferenceList = tempPreferenceList ? tempPreferenceList : [];
			preferenceData = preferenceData ? preferenceData : [];
			let isRecordUniqueFlag = false,
				checkForUnique = {};
			if (dealers && dealers.length) {
				dealers.map((v) => {
					let keyToValidateUnique = {
						make_id: make_id,
						model_id: model_id,
						city_id: city,
						dse_id: v.dse_id,
					};
					checkForUnique = _.find(
						tempPreferenceList,
						keyToValidateUnique
					);
					if (!checkForUnique) {
						if (keyToValidateUnique.city_id)
							delete keyToValidateUnique.city_id;
						keyToValidateUnique["assign_car_city_id"] = city;
						checkForUnique = _.find(
							assignCarList,
							keyToValidateUnique
						);
					}

					isRecordUniqueFlag = checkForUnique ? true : false;
				});
			} else {
				checkForUnique = _.find(tempPreferenceList, {
					make_id: make_id,
					model_id: model_id,
					city_id: city,
				});
				isRecordUniqueFlag = checkForUnique ? true : false;
			}
			if (isRecordUniqueFlag) {
				/////////////////error case ///////////////////////////
				toast.error(
					this.props.t("LEAD_DETAIL_PREFERENCE.ERROR.SAME_MAKE_MODEL")
				);
			} else {
				if (dealers && dealers.length) {
					dealers.map((v) => {
						let prefObj = {
							lead_id: Number(lead_id),
							make_id: make_id,
							model_id: model_id,
							city_id: city,
							dse_id: v.dse_id,
							model_status_id: 0,
							source_id: config.constants.DEFAULT_SOURCE || 3,
							sort_order: preferenceData.length + +1,
						};
						let checkForUnique = _.find(preferenceData, {
							make_id: make_id,
							model_id: model_id,
							city_id: city,
						});
						if (!checkForUnique) preferenceData.push(prefObj);
						tempPreferenceList.push(prefObj);
					});
				} else {
					let prefObj = {
						lead_id: Number(lead_id),
						make_id: make_id,
						model_id: model_id,
						city_id: city,
						model_status_id: 0,
						source_id: config.constants.DEFAULT_SOURCE || 3,
						sort_order: preferenceData.length + +1,
					};
					preferenceData.push(prefObj);
					tempPreferenceList.push(prefObj);
				}
				this.setState({
					preferenceData: preferenceData,
					tempPreferenceList: tempPreferenceList,
					addNewCarState: false,
					dealers: [],
				});
			}
		}
	};

	updatePreferenceListData = () => {};

	addNewCar = () => {
		this.setState({ addNewCarState: true });
	};
	hideNewCar = () => {
		let initial_city = this.props.lead_detail
			? this.props.lead_detail.lead_city
			: 0;
		this.setState({
			addNewCarState: false,
			make_model: {},
			city: initial_city,
		});
	};
	handleTabChange = (event) => {
		this.setState({ showTab: event.currentTarget.dataset.key });
	};

	getDseList = async (requestBody) => {
		await LeadService.getDseList(requestBody)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let dealer_dse_list =
						response &&
						response.data &&
						response.data.data &&
						response.data.data.length
							? response.data.data.map(function (e) {
									e.dealer_dse_name = e.dealer_deal_type
										? e.dealer_dse_name +
										  "-" +
										  e.dealer_deal_type
										: e.dealer_dse_name;
									return e;
							  })
							: [];
					this.setState({
						dse_list: dealer_dse_list,
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	handleChangeMultiSel = (field_name, field_obj) => {
		let state = {};
		if (field_name === "dealers") {
			state = {
				dealers: field_obj,
			};
		}
		this.setState({ ...state });
	};

	render() {
		const {
			preferenceData,
			loading,
			modalStatus,
			sources,
			mm,
			make_map,
			model_map,
			city_list,
			errors,
			dse_list,
		} = this.state;
		const _this = this;
		const allModalStatus = modalStatus
			? modalStatus.map(function (e) {
					return {
						label: e.name,
						value: e.id,
					};
			  })
			: [];
		const allSources = {};
		if (sources) {
			for (let i of sources) allSources[i.source_id] = i;
		}
		const preferenceList =
			preferenceData &&
			preferenceData.map(function (e, i) {
				e.sort_order = i + +1;
				///////////////////////cretae make model/////////////////////
				return (
					<tr key={`${i}_preference`}>
						<td>
							<div className="">
								{i !== preferenceData.length - 1 ? (
									<span className=" priorityBtns down">
										{i < preferenceData.length - 1 ? (
											<i
												onClick={_this.handleChangePriorityStatus.bind(
													this,
													e.make_id,
													e.model_id,
													e.sort_order,
													1
												)}
												className="ic-arrow_drop_down-24px"
												disabled={
													i ===
													preferenceData.length - 1
														? true
														: false
												}
											></i>
										) : (
											<i
												className="ic-arrow_drop_down-24px"
												disabled={true}
											></i>
										)}
									</span>
								) : (
									""
								)}
								{i !== 0 ? (
									<span className=" priorityBtns up">
										{i > 0 ? (
											<i
												onClick={_this.handleChangePriorityStatus.bind(
													this,
													e.make_id,
													e.model_id,
													e.sort_order,
													-1
												)}
												className="ic-arrow_drop_up-24px"
												disabled={
													i === 0 ? true : false
												}
											></i>
										) : (
											<i
												className="ic-arrow_drop_up-24px"
												disabled={true}
											></i>
										)}
									</span>
								) : (
									""
								)}
							</div>
						</td>
						<td>
							{make_map
								? make_map[e.make_id]
									? make_map[e.make_id]
									: ""
								: ""}{" "}
							-{" "}
							{model_map
								? model_map[e.model_id]
									? model_map[e.model_id]
									: ""
								: ""}
						</td>
						<td>
							<Select
								id="model_status"
								onChange={_this.handleChange.bind(
									this,
									"model_status",
									{
										make_id: e.make_id,
										model_id: e.model_id,
										index: i,
									}
								)}
								options={allModalStatus}
								name="model_status"
								value={allModalStatus.filter(
									({ value }) => value === e.model_status_id
								)}
								getOptionLabel={({ label }) => label}
								getOptionValue={({ value }) => value}
							/>
						</td>
						<td>
							{(Object.keys(allSources).length &&
							allSources[e.source_id]
								? allSources[e.source_id].name
								: "") || ""}
						</td>
						<td>
							{e.created_date
								? dateformat(
										e.created_date,
										config.constants.DATE_FORMAT
								  )
								: ""}
						</td>
					</tr>
				);
			});

		return (
			<div>
				{loading && <PageLoader loaderClass="text-center" />}
				<div
					className="table-responsive"
					ref={(ref) => (this.scrollParentRef = ref)}
				>
					<InfiniteScroll
						pageStart={0}
						loadMore={this.getPreferenceList.bind(this)}
						hasMore={false}
						loader={
							<PageLoader loaderClass="text-center" key={0} />
						}
						getScrollParent={() => this.scrollParentRef}
						useWindow={false}
					>
						<table className="table table-bordered-h table-hover recommendedTable ">
							<thead>
								<tr>
									<th>
										{this.props.t(
											"LEAD_DETAIL_PREFERENCE.TBL_HEADING.SET_PRIORITY"
										)}
									</th>
									<th>
										{this.props.t(
											"LEAD_DETAIL_PREFERENCE.TBL_HEADING.MAKE_MODEL"
										)}
									</th>
									<th>
										{this.props.t(
											"LEAD_DETAIL_PREFERENCE.TBL_HEADING.MODEL_STATUS"
										)}
									</th>
									<th>
										{this.props.t(
											"LEAD_DETAIL_PREFERENCE.TBL_HEADING.SOURCE"
										)}
									</th>
									<th>
										{this.props.t(
											"LEAD_DETAIL_PREFERENCE.TBL_HEADING.CREATED_ON"
										)}
									</th>
								</tr>
							</thead>
							<tbody>{preferenceList}</tbody>
						</table>
					</InfiniteScroll>
				</div>
				{this.state.addNewCarState === true ? (
					""
				) : (
					<div className="text-center pad-t10 mrg-b15">
						<button
							type="button"
							className="btn btn-primary-outline"
							onClick={this.addNewCar}
						>
							{this.props.t("LEAD_DETAIL_PREFERENCE.ADD_NEW_CAR")}
						</button>
					</div>
				)}
				{this.state.addNewCarState === true ? (
					<div className="addNewCar">
						<div className="row">
							{/* <div className="col-sm-12">
								<strong>
									{this.props.t(
										"LEAD_DETAIL_PREFERENCE.ADD_NEW_CAR_HEADING"
									)}
								</strong>
							</div> */}
							<div className="col-sm-3 form-group">
								<label htmlFor="city">
									{this.props.t(
										"LEAD_DETAIL_PREFERENCE.CITY"
									)}
									<span className="text-danger">*</span>
								</label>
								<Select
									id="city_id"
									onChange={this.handleChange.bind(
										this,
										"city",
										""
									)}
									options={city_list}
									name="city_id"
									placeholder={this.props.t(
										"LEAD_DETAIL_PREFERENCE.CITY"
									)}
									getOptionLabel={({ name }) => name}
									getOptionValue={({ id }) => id}
									value={city_list.filter(
										({ id }) =>
											Number(id) ===
											Number(this.state.city)
									)}
									isClearable
								/>
								<span className="text-danger">
									{errors.city || ""}
								</span>
							</div>
							<div className="col-sm-4 form-group">
								<label htmlFor="make_model">
									{this.props.t(
										"LEAD_DETAIL_PREFERENCE.MAKE_MODEL"
									)}
									<span className="text-danger">*</span>
								</label>
								<Select
									id="make_model"
									onChange={this.handleChange.bind(
										this,
										"make_model",
										""
									)}
									options={mm}
									name="make_model"
									placeholder={this.props.t(
										"LEAD_DETAIL_PREFERENCE.MAKE_MODEL"
									)}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
									isClearable
								/>
								<span className="text-danger">
									{errors.make_model || ""}
								</span>
							</div>
							<div className="col-sm-5 form-group">
								<label>
									{this.props.t(
										"LEAD_DETAIL_PREFERENCE.DEALER_DSE"
									)}
								</label>
								<ReactMultiSelectCheckboxes
									isMulti
									placeholderButtonLabel={this.props.t(
										"LEAD_DETAIL_PREFERENCE.DEALER_DSE"
									)}
									getDropdownButtonLabel={({
										placeholderButtonLabel,
										value,
									}) =>
										value && value.length > 0
											? value.length +
											  " " +
											  this.props.t(
													"LEAD_DETAIL_PREFERENCE.DEALER_DSE"
											  )
											: placeholderButtonLabel
									}
									onChange={this.handleChangeMultiSel.bind(
										this,
										"dealers"
									)}
									options={dse_list}
									getOptionLabel={({ dealer_dse_name }) =>
										dealer_dse_name
									}
									getOptionValue={({ dse_id }) => dse_id}
								/>
							</div>
							<div className="col-sm-12 text-right">
								<button
									type="button"
									className="btn btn-primary-outline mrg-r15"
									onClick={this.hideNewCar}
								>
									{this.props.t(
										"LEAD_DETAIL_PREFERENCE.CANCEL_ADD_CAR"
									)}
								</button>
								<button
									type="button"
									className="btn btn-primary "
									onClick={this.handleAddNewCar.bind(this)}
								>
									{this.props.t(
										"LEAD_DETAIL_PREFERENCE.ADD_CAR"
									)}
								</button>
							</div>
						</div>
					</div>
				) : (
					""
				)}
				<div className="actionFooter">
					<button
						type="button"
						className="btn btn-primary-outline mrg-r15"
						disabled={
							this.state.addNewCarState === true ? true : false
						}
						onClick={() => this.props.modalClose()}
					>
						{this.props.t(
							"LEAD_DETAIL_PREFERENCE.CANCEL_UPDATE_PREFERENCE"
						)}
					</button>
					<button
						type="button"
						className="btn btn-primary"
						disabled={
							this.state.addNewCarState === true ? true : false
						}
						onClick={this.handleUpdatePreference.bind(this)}
					>
						{this.props.t(
							"LEAD_DETAIL_PREFERENCE.UPDATE_PREFERENCE"
						)}
					</button>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ config, inventory, lead }) => {
	return {
		modalStatus: config.modalStatus,
		sources: config.source_list,
		mm: inventory.filteredMM,
		make_map: inventory.make_map,
		model_map: inventory.model_map,
		city_list: inventory.city,
		lead_detail: lead.lead_detail,
		assignCarList: lead.assigned_cars,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getAllModalStatus: bindActionCreators(
				ConfigAction.getModalStatus,
				dispatch
			),
			//getAllSource: bindActionCreators(ConfigAction.getSources, dispatch),
			updatePreferences: bindActionCreators(
				LeadActions.updatePreferences,
				dispatch
			),
			getMMV: bindActionCreators(InventoryAction.getMMV, dispatch),
			updateHistoryFlag: bindActionCreators(
				LeadActions.updateHistoryFlag,
				dispatch
			),
		},
	};
};
export default withTranslation("lead_detail")(
	connect(mapStateToProps, mapDispatchToProps)(PreferenceForm)
);
