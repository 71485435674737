import React, { Component } from "react";
const ClickOutHandler = require("react-onclickout");

class ModalPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showmodal: true,
		};
		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}

	/**
	 * Set the wrapper ref
	 */
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	/**
	 * Alert if clicked on outside of element
	 */
	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.props.modalClose();
		}
	}
	onClickOut = (e) => {
		this.props.modalClose();
	};

	render() {
		const { id } = this.state;
		var { children } = this.props;
		return (
			<div>
				<div className={this.props.className} id={id}>
					<ClickOutHandler onClickOut={this.onClickOut}>
						<div className="modal-dialog">
							<div className="modal-content">
								<button
									type="button"
									className="close"
									onClick={() => {
										this.props.modalClose();
									}}
								>
									<span aria-hidden="true">×</span>
								</button>

								<div className="modal-body">{children}</div>
								{/* <div className="modal-footer">
                        <button type="button" className="btn btn-link">Close</button>
                        <button type="button" className="btn btn-primary">Save Changes</button>
                    </div> */}
							</div>
						</div>
					</ClickOutHandler>
				</div>
				<div
					className="modal-backdrop"
					onClick={() => {
						this.props.modalClose();
					}}
				></div>
			</div>
		);
	}
}
export default ModalPopup;
