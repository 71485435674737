import React, { Component } from "react";
import { Link } from "react-router-dom";
import { webFormService } from "../../../../service/webFormService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import PageLoader from "../../../elements/PageLoader";
import Searchwebids from "../../component/webids/Searchwebids";
import config from "../../../../config/config";
import dateformat from "dateformat";
class webidsList extends Component {
	constructor(props) {
		super(props);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.state = {
			loading: true,
			webidsList: this.props.webidsList || [],
			pagination: this.props.pagination || {},
			filterData: { page: 1 },
			PopOver: false,
		};
	}

	componentDidMount = async () => {
		await this.getwebidsList();
	};

	getwebidsList = async () => {
		let { filterData } = this.state;
		this.setState({ loading: true });
		webFormService
			.getwebidsList(filterData)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let webidsList = response.data.data.webidsInfo || [];
					let pagination = response.data.data.pagination || {};
					this.setState({
						webidsList: webidsList,
						pagination: pagination,
						loading: false,
					});
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	handlePageChange(pageNumber) {
		let { filterData } = this.state;
		filterData["page"] = pageNumber;
		this.setState({ filterData: filterData, loading: true });
		this.getwebidsList();
	}
	submitFilterForm = async (filterData) => {
		filterData.page = 1;
		this.setState(
			{
				loading: true,
				filterData: filterData,
			},
			async () => {
				await this.getwebidsList();
			}
		);
	};

	saveWebidsStatus = async (id, status) => {
		this.setState({ loading: true });
		webFormService
			.updateWebidsStatus({ id: id, status: status })
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					this.setState(
						{
							loading: false,
							PopOver: false,
						},
						async () => {
							await this.getwebidsList();
						}
					);
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	displayPopUp = (list_id) => {
		this.setState({ PopOver: list_id });
	};
	confirmNo = (status_id) => {
		this.setState({ PopOver: false });
	};
	confirmYes = (status_id) => {
		let webids_id = this.state.PopOver;
		let status2upd = status_id === "1" ? "0" : "1";
		this.saveWebidsStatus(webids_id, status2upd);
	};

	render() {
		const { loading, webidsList, filterData, pagination } = this.state;
		return (
			<div>
				<Searchwebids
					onSubmitFilter={this.submitFilterForm}
					filterData={filterData}
				/>
				<div className="container-fluid">
					<div className="result-wrap">
						<div className="row mrg-b15">
							<div className="col-sm-6">
								<p className="countNo mrg-t5 mrg-b0">
									{pagination.totalrecords} -{" "}
									{this.props.t("WEBIDS.WEBIDS")}
								</p>
							</div>
							<div className="col-sm-6 text-right">
								<Link
									to={"/add_webids"}
									className="btn btn-success btn-sm"
									title={this.props.t("WEBIDS.ADD")}
								>
									{this.props.t("WEBIDS.ADD")}
								</Link>
							</div>
						</div>

						<div className="card">
							<div className="table-responsive">
								{loading && (
									<PageLoader loaderClass="text-center" />
								)}
								<table className="table table-bordered-h table-hover processTable">
									<thead>
										<tr>
											<th>
												{this.props.t(
													"WEBIDS.WEBIDS_ID"
												)}
											</th>
											<th>
												{this.props.t("WEBIDS.WEBID")}
											</th>
											<th>
												{this.props.t("WEBIDS.DOMAIN")}
											</th>
											<th>
												{this.props.t("WEBIDS.STATUS")}
											</th>
											<th>
												{this.props.t(
													"WEBIDS.ADDED_DATE"
												)}
											</th>
											<th>
												{this.props.t(
													"WEBIDS.UPDATED_DATE"
												)}
											</th>
											<th>
												{this.props.t("WEBIDS.ACTION")}
											</th>
										</tr>
									</thead>

									<tbody>
										{webidsList.length > 0 ? (
											webidsList.map(
												(singleinfo, index) => (
													<React.Fragment
														key={singleinfo.id}
													>
														<tr>
															<td>
																<div>
																	{singleinfo.id ||
																		""}
																</div>
															</td>
															<td>
																<div>
																	{singleinfo.webid ||
																		""}
																</div>
															</td>
															<td>
																<div>
																	{(singleinfo.domain_info &&
																		singleinfo
																			.domain_info
																			.name) ||
																		""}
																</div>
															</td>

															<td>
																<div>
																	{singleinfo.status ===
																	"1"
																		? this.props.t(
																				"WEBIDS.ACTIVE"
																		  )
																		: this.props.t(
																				"WEBIDS.INACTIVE"
																		  )}
																</div>
															</td>

															<td>
																{singleinfo &&
																singleinfo.created_date ? (
																	<div>
																		{
																			/* {moment(
																			singleinfo.created_date
																		).format(
																			config
																				.constants
																				.DATE_FORMAT
																		)} */
																			dateformat(
																				singleinfo.created_date,
																				config
																					.constants
																					.DATE_FORMAT
																			)
																		}
																	</div>
																) : (
																	""
																)}
															</td>
															<td>
																{singleinfo &&
																singleinfo.updated_date ? (
																	<div>
																		{
																			/* {moment(
																			singleinfo.updated_date
																		).format(
																			config
																				.constants
																				.DATE_FORMAT
																		) */
																			dateformat(
																				singleinfo.updated_date,
																				config
																					.constants
																					.DATE_FORMAT
																			)
																		}
																	</div>
																) : (
																	""
																)}
															</td>

															<td>
																<div>
																	<Link
																		to={
																			"/edit_webids/" +
																			singleinfo.id
																		}
																		className="btn btn-link text-link mrg-r15"
																		title={this.props.t(
																			"WEBIDS.EDIT"
																		)}
																	>
																		<i className="ic-create-24px"></i>
																	</Link>
																	{/* <SwitchBtn
																		label=""
																		htmlFor={
																			"Status" +
																			singleinfo.id
																		}
																		id={
																			"Status" +
																			singleinfo.id
																		}
																		name={
																			"Status" +
																			singleinfo.id
																		}
																		checked={
																			singleinfo.status ===
																			"1"
																				? true
																				: false
																		}
																		onChange={this.displayPopUp.bind(
																			this,
																			singleinfo.id
																		)}
																	/>
																	{PopOver ===
																		singleinfo.id && (
																		<ConfirmPopUp
																			onConfirm={() =>
																				this.confirmYes(
																					singleinfo.status
																				)
																			}
																			onReject={() =>
																				this.confirmNo(
																					singleinfo.status
																				)
																			}
																			message={this.props.t(
																				"COMMON.ARE_YOU_SURE"
																			)}
																		/>
																	)} */}
																</div>
															</td>
														</tr>
													</React.Fragment>
												)
											)
										) : (
											<tr>
												<td colSpan="10">
													{!loading ? (
														<h6 className="text-danger">
															{this.props.t(
																"WEBIDS.NOT_FOUND"
															)}
														</h6>
													) : (
														""
													)}
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div className="clearfix">
								{pagination.totalpage >= 1 ? (
									<div className="main-div-pagination">
										<div className="col-sm-6 text-light">
											{this.props.t("WEBIDS.SHOWING")}{" "}
											{filterData.page}{" "}
											{this.props.t("WEBIDS.OF")}{" "}
											{pagination.totalpage}{" "}
											{this.props.t("WEBIDS.PAGES")}
										</div>
										<div className="col-sm-6">
											<Pagination
												activePage={filterData.page}
												itemsCountPerPage={
													pagination.pagelimit
												}
												totalItemsCount={
													pagination.totalrecords
												}
												pageRangeDisplayed={5}
												itemClass="page-item"
												linkClass="page-link"
												onChange={this.handlePageChange}
											/>
										</div>
									</div>
								) : (
									""
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation("webfrom")(webidsList);
