import { InventoryConstants } from "../constants/inventory.constants";
import secureStorage from "../../config/encrypt";
import { toast } from "react-toastify";
// import { InventoryAction } from "../action";

let inventoryData = "";
let initialState = "";
try {
	inventoryData = secureStorage.getItem("inventory");
	console.log("inventory data", inventoryData);
	initialState = inventoryData ? inventoryData : {};
} catch (err) {
	console.log(err);
}

export default function InventoryReducer(state = initialState, action) {
	switch (action.type) {
		case InventoryConstants.GET_MAKE_MODEL_VERSION: {
			return {
				...action.list,
				...state,
			};
		}
		case InventoryConstants.GET_MAKE_MODEL_VERSION_ERR: {
			toast.error(action.error);
			return {
				...state,
			};
		}
		case InventoryConstants.STATE_CITY_LIST: {
			return {
				...action.list,
				...state,
			};
		}
		case InventoryConstants.STATE_CITY_LIST_ERR: {
			toast.error(action.error);
			return {
				...state,
			};
		}

		case InventoryConstants.FILTER_FETCH: {
			return {
				...action.list,
				...state,
			};
		}
		case InventoryConstants.FILTER_FETCH_ERR: {
			toast.error(action.error);
			return {
				...state,
			};
		}
		case InventoryConstants.LOCALITY_DATA: {
			return {
				...action.list,
				...state,
			};
		}
		case InventoryConstants.LOCALITY_DATA_ERR: {
			return {
				...state,
			};
		}
		default:
			return state;
	}
}
