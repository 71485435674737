export const SITE_LOGO = require("../../webroot/images/oto-logo-2x.png");
export const LOGIN_PAGE_LOGO = require("../../webroot/images/oto-logo-black.png");

export const TITLE = "OTO";
export const language = [
	{ value: "1", label: "English", iso_code: "en" },
	{ value: "2", label: "Indonesian", iso_code: "id" },
];
export const ISD_CODE = [
	{
		isdCode: "+62",
		isdCodeWithoutSign: "62",
		startsFrom: 8,
		minLength: 8,
		maxLength: 12,
	},
];
export const DATE_FORMAT = "dd mmm yyyy, HH:MM";
export const DATE_FORMAT_DMY = "dd mmm yyyy";
export const TIME_FORMAT = "HH:MM";
export const DATE_FORMAT_INPUT = "yyyy-MM-dd";
export const DATE_FORMAT_INPUT_MONTH = "MM/yyyy";
export const TIME_FORMAT_INPUT = "HH:mm";
export const DATE_FORMAT_YMD = "yyyy-mm-dd";
export const DATE_FORMAT_YMD_REPORT = "yyyy/mm/dd";
export const DATE_FORMAT_WITH_TIME = "yyyy-mm-dd HH:MM";
export const COLOUR_ARR = [
	{ value: "Black", label: "Black" },
	{ value: "Blue", label: "Blue" },
	{ value: "White", label: "White" },
	{ value: "Dark Silver", label: "Dark Silver" },
	{ value: "Silver", label: "Silver" },
	{ value: "Bright Green", label: "Bright Green" },
	{ value: "Cobalt", label: "Cobalt" },
	{ value: "Green", label: "Green" },
	{ value: "Golden", label: "Golden" },
	{ value: "Mineral White", label: "Mineral White" },
	{ value: "Fiery Black", label: "Fiery Black" },
	{ value: "Mint White", label: "Mint White" },
	{ value: "Berry Red", label: "Berry Red" },
	{ value: "Purple", label: "Purple" },
	{ value: "Dark Blue", label: "Dark Blue" },
	{ value: "Tan", label: "Tan" },
	{ value: "Rosso Mugello", label: "Rosso Mugello" },
	{ value: "Dark Red", label: "Dark Red" },
	{ value: "Volcano Red", label: "Volcano Red" },
	{ value: "Matt Black", label: "Matt Black" },
	{ value: "Red", label: "Red" },
	{ value: "Pink", label: "Pink" },
	{ value: "Burgundy", label: "Burgundy" },
	{ value: "Orange", label: "Orange" },
	{ value: "Lavender", label: "Lavender" },
	{ value: "Yellow", label: "Yellow" },
	{ value: "Teal", label: "Teal" },
	{ value: "Torquoise", label: "Torquoise" },
	{ value: "Grey", label: "Grey" },
	{ value: "Sand Drift Grey", label: "Sand Drift Grey" },
	{ value: "Brown", label: "Brown" },
	{ value: "Violet", label: "Violet" },
];
export const NO_SEAT_REQ = [
	{ value: "2", label: "Less Than 5" },
	{ value: "5", label: "5" },
	{ value: "6", label: "6" },
	{ value: "7", label: "7" },
	{ value: "8", label: "8" },
	{ value: "9", label: "More Than 8" },
];
export const OWNER = [
	{ value: "0", label: "Unregistered" },
	{ value: "1", label: "First" },
	{ value: "2", label: "Second" },
	{ value: "3", label: "Third" },
	{ value: "4", label: "Fourth" },
];
export const GENDER = [
	{ value: "1", label: "Male" },
	{ value: "2", label: "Female" },
];
export const MARITIAL_STATUS = [
	{ value: "1", label: "Married" },
	{ value: "2", label: "Un-Married" },
	{ value: "3", label: "Widowed" },
	{ value: "4", label: "Divorced" },
];

export const FAMILY_MEMBER = new Array(20).fill().map(function (e, i) {
	return { label: `${i + 1}`, value: i + 1 };
});
export const OCCUPATION = [
	{ value: "1", en_label: "Paid", id_label: "Bergaji" },
	{
		value: "2",
		en_label: "Self-employed (Business)",
		id_label: "Self-employed(Bisnis)",
	},
	{
		value: "3",
		en_label: "Self-employed(Professional)",
		id_label: "Self-employed(Professional)",
	},
	{ value: "5", en_label: "Retired", id_label: "Pensiunan" },
	{ value: "4", en_label: "Others", id_label: "Lainnya" },
];

export const DEFAULT_MODAL_STATUS = 2; ///warm;
export const DEFAULT_SOURCE = 3; ///CRM
export const AGE_RANGE = [
	{ value: 1, label: "<25" },
	{ value: 2, label: "26-35" },
	{ value: 3, label: "36-50" },
	{ value: 4, label: "50+" },
];

export const CONNECT_TO_SCORE = new Array(10).fill(1).map(function (e, i) {
	return { label: i + 1, value: i + 1 };
});

export const BUY_TIME_RULE = [
	{ label: "1 Week", value: 7 },
	{ label: "2 Week", value: 14 },
	{ label: "1 Month", value: 30 },
	{ label: "2 Months", value: 60 },
];
export const ACTIVE_STATUS = [
	{ label: "Active", value: "1" },
	{ label: "In-Active", value: "0" },
];

export const SOURCE_NAME = "OTO";
export const SOURCE_ICON = "sprite ic-otologo";
export const SOURCE_LABEL = "OTO";
export const LABEL_SPK = "SPK";
export const LABEL_DO = "DO";
export const PRIORITY_PUSH_DIALER_LIMIT = 5000;
export const CLUSTER = "CLUSTER";
export const cluster_list = "cluster_list";
export const IS_VIBER_SHOW = false;
export const STNK_FOLLOW_UP_LABEL = "STNK Follow Up Date";
export const STNK_DETAIL = "STNK_DETAIL";
export const STNK_FOLLOW_UP_DATE = "STNK_FOLLOW_UP_DATE";
export const STNK_NUMBER = "STNK_NUMBER";

export const AUDIT_QUEST_ANS = [
	{ value: "YES", label: "Yes" },
	{ value: "NO", label: "No" },
	{ value: "NA", label: "NA" },
	{ value: "FATAL", label: "Fatal" },
];
export const AUDIT_PRIORITY = [
	{ value: "LOW", label: "Low" },
	{ value: "MEDIUM", label: "Medium" },
	{ value: "HIGH", label: "High" },
];
export const DISPLAY_AUDIT_STATUS = [
	{ value: "NEW", label: "New" },
	{ value: "PENDING_ON_AGENT", label: "Audited" },
	{ value: "PENDING_ON_AUDITOR", label: "Audited" },
	{ value: "ACCEPTED", label: "Audited" },
	{ value: "REJECTED", label: "Rejected" },
	{ value: "REFUSED", label: "Refused" },
	{ value: "CLOSED", label: "Closed" },
	{ value: "AUDITED", label: "Audited" },
];
export const AGENT_ROLE_ID = 3;
export const MAX_UPLOAD_FILE_SIZE = 50000000; /* 50 MB */
