import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { LeadService } from "../../../../service/leadService";
import { toast } from "react-toastify";
import Select from "react-select";
class BulkUpload extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			headers: [
				{ name: "BULK_UPLOAD.HEADER.NAME" },
				{ name: "BULK_UPLOAD.HEADER.MOBILE" },
				{ name: "BULK_UPLOAD.HEADER.BRAND" },
				{ name: "BULK_UPLOAD.HEADER.MODEL" },
				{ name: "BULK_UPLOAD.HEADER.City" },
				{ name: "BULK_UPLOAD.HEADER.LMS_STATUS" },
			],
			action: "leadBulkUpload",
			isPremiumField: true,
			errors: {},
			formData: {},
			gm_campaign_list: [],
		};
	}

	componentDidMount = async () => {
		await this.getConfigData();
	};

	getConfigData = async () => {
		const requestBody = {
			config_data: ["gm_campaign_list"],
		};
		LeadService.getConfig(requestBody)
			.then(async (response) => {
				if (response.status === 200 && response.data.status === 200) {
					let list = [];
					let result = response.data.data.gm_campaign_list;
					list = result.map((v) => {
						v.name = v.name + " - " + v.list_id;
						return v;
					});
					this.setState({
						gm_campaign_list: list || [],
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	handleChange = async (key, event) => {
		let { formData, errors, is_future_date } = this.state;
		if (event) {
			formData[key] = event.list_id;
			errors = "";
		} else {
			delete formData[key];
		}

		await this.setState({
			formData: formData,
			errors: errors,
			is_future_date: is_future_date,
		});
	};

	onChangeHandler = (event) => {
		let selectedFile = event.target.files[0];
		if (
			selectedFile &&
			selectedFile.name &&
			selectedFile.name.split(".")[1] !== "csv"
		) {
			toast.error(this.props.t("BULK_UPLOAD.UPLOAD_CSV_FILE_ONLY"));
		} else {
			this.setState({
				selectedFile: selectedFile,
			});
		}
	};

	submitUpload = async () => {
		let { errors, formData } = this.state;
		let postData = {};
		if (this.state.selectedFile) {
			if (!formData["list_id"]) {
				errors["list_id"] = this.props.t(
					"BULK_UPLOAD.CAMPAIGN_LIST_ID_REQUIRED"
				);
				this.setState({ errors: errors });
				return;
			}
			this.setState({ loading: true });
			postData = new FormData();
			postData.append("file", this.state.selectedFile);
			postData.append("list_id", this.state.formData.list_id);
			let uploadResult = await LeadService.submitBulkUpload(postData);
			if (
				uploadResult &&
				uploadResult.status === 200 &&
				uploadResult.data
			) {
				toast.success(uploadResult.data.message);
				this.setState({
					loading: false,
					formData: {},
					selectedFile: null,
					errors: {},
				});
				this.fileInput.value = "";
			} else {
				this.setState({ loading: false, selectedFile: null });
				this.fileInput.value = "";
			}
		} else {
			toast.error(this.props.t("BULK_UPLOAD.SELECT_FILE_TO_UPLOAD"));
			this.setState({ loading: false });
		}
	};

	render() {
		const {
			headers,
			loading,
			gm_campaign_list,
			formData,
			errors,
		} = this.state;
		return (
			<div className="container-fluid">
				<h1>{this.props.t("BULK_UPLOAD.IMPROT_DATA")}</h1>
				<div className="col-md-8 col-md-offset-2 bulkUploadLeads">
					<div className="row">
						<div className="form-group col-sm-6">
							<label>
								{this.props.t("BULK_UPLOAD.CAMPAIGN_LIST_ID")}
							</label>
							<span className="text-danger">*</span>
							<Select
								id="list_id"
								onChange={this.handleChange.bind(
									this,
									"list_id"
								)}
								options={gm_campaign_list}
								name="list_id"
								placeholder={this.props.t(
									"BULK_UPLOAD.CAMPAIGN_LIST_ID"
								)}
								value={
									formData &&
									gm_campaign_list.filter(
										({ list_id }) =>
											Number(list_id) ===
											Number(formData.list_id)
									)
								}
								getOptionLabel={({ name }) => name}
								getOptionValue={({ list_id }) => list_id}
								isClearable
							/>
							<span className="text-danger">
								{errors.list_id || ""}
							</span>
						</div>
					</div>
					<div className="row">
						<div className=" clearfix  mrg-T20">
							<div className="col-sm-3">
								<label>
									<strong>
										{this.props.t(
											"BULK_UPLOAD.SELECT_FILE_TO_UPLOAD"
										)}
									</strong>
									<span className="text-danger">*</span>
								</label>
							</div>

							<div className="col-sm-4">
								<div className=" form-group">
									<input
										type="file"
										id="file"
										name="myfile"
										onChange={this.onChangeHandler}
										ref={(ref) => (this.fileInput = ref)}
									/>
									<span></span>
									<div id="fileErr"></div>
								</div>
							</div>

							<div className="col-sm-3 clearfix form-group">
								<span className="mrg-L40">
									<a
										href="/files/sample_bulk_upload.csv"
										target="_blank"
										download="sample_bulk_upload.csv"
									>
										{this.props.t(
											"BULK_UPLOAD.DOWNLOAD_SAMPALE_FILE"
										)}
									</a>
								</span>
							</div>
						</div>

						<div className="clearfix mrg-T20">
							<div className="col-sm-12">
								<label>
									<strong>
										{this.props.t(
											"BULK_UPLOAD.REQUIRED_FORMAT"
										)}
									</strong>
									<span>*</span>
								</label>
							</div>
						</div>

						<div className="clearfix">
							<div className="col-sm-12 table-responsive">
								<table className="table table-bordered table-striped table-hover enquiry-table">
									<tbody>
										<tr>
											{headers &&
												headers.map((el, key) => {
													return (
														<th key={key}>
															{this.props.t(
																el.name
															)}
														</th>
													);
												})}
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div className="clearfix">
							<div className="col-sm-10">
								<div id="status"></div>
							</div>
						</div>

						<div className="clearfix mrg-T20 form-group">
							<div className="col-sm-3 text-right"></div>
							<div className="col-sm-4">
								{loading ? (
									<button
										type="submit"
										className="btn btn-lg btn-primary btn- pull-right"
										disabled
									>
										{this.props.t("BULK_UPLOAD.UPLOADING")}
									</button>
								) : (
									<button
										type="submit"
										className="btn btn-lg btn-primary btn- pull-right"
										onClick={this.submitUpload}
										disabled={
											this.state.selectedFile
												? false
												: true
										}
									>
										{this.props.t("BULK_UPLOAD.UPLOAD")}
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation("webfrom")(BulkUpload);
