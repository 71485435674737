import React, { Component } from "react";

class PopupOverTips extends Component {
	constructor(props) {
		super(props);

		this.state = {
			popupVisible: false,
			cursorPos: "",
		};
		this.myRef = React.createRef();
		// this.wrapperRef = React.createRef();
		// this.handleClick = this.handleClick.bind(this);
		// this.handleOutsideClick = this.handleOutsideClick.bind(this);
	}
	// handleClick = (e) => {
	// 	e.stopPropagation();
	// 	const cursorPos = {
	// 		top: e.clientY,
	// 		left: e.clientX,
	// 	};
	// 	console.log(cursorPos);
	// 	if (!this.state.popupVisible) {
	// 		// attach/remove event handler
	// 		document.addEventListener("click", this.handleOutsideClick, false);
	// 	} else {
	// 		document.removeEventListener(
	// 			"click",
	// 			this.handleOutsideClick,
	// 			false
	// 		);
	// 	}

	// 	this.setState((prevState) => ({
	// 		popupVisible: !prevState.popupVisible,
	// 	}));
	// };

	render() {
		const { id } = this.state;
		var {
			children,
			position,
			style,
			heading,
			arrowstyle,
			abc,
		} = this.props;
		return (
			<div
				className={`popover fade  bs-popover-${position}`}
				id={id}
				ref={abc}
				style={style}
				x-placement="bottom"
			>
				<div className="arrow" style={arrowstyle}></div>
				<h3 className="popover-header">{heading}</h3>
				<div className="popover-body">{children}</div>
			</div>
		);
	}
}
export default PopupOverTips;
