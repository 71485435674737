/* eslint-disable */
import React, { Component } from "react";
import Select from "react-select";
import InputField from "../../../elements/InputField";
import CheckBox from "../../../elements/CheckBox";
import { webFormService } from "../../../../service/webFormService";
import { LeadService } from "../../../../service/leadService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import PageLoader from "../../../elements/PageLoader";
import { Redirect } from "react-router-dom";
class EditDomain extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: this.props.match.params.id ? true : false,
			domaininfo: this.props.domaininfo || {},
			country_list: this.props.country_list || [],
			business_type_list: this.props.business_type_list || [],
			domain_id: this.props.match.params.id || 0,
			errors: {},
			redirectTolist: false,
		};
		this.ChangeCheckbox = this.ChangeCheckbox.bind(this);
	}

	componentDidMount = async () => {
		await this.getConfigData();
		if (this.state.domain_id) await this.getDomain();
	};

	getConfigData = async () => {
		const requestBody = {
			config_data: ["country_list", "business_type_list"],
		};
		LeadService.getConfig(requestBody)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					this.setState({
						country_list: response.data.data.country_list || [],
						business_type_list:
							response.data.data.business_type_list || [],
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	getDomain = async () => {
		this.setState({ loading: true });
		let domain_id = this.state.domain_id;
		webFormService
			.getDomainList({ id: domain_id, list_type: "ALL" })
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let domaininfo = response.data.data
						? response.data.data[0]
						: [];
					this.setState({
						domaininfo: domaininfo || {},
						loading: false,
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	handleChange = (sname, valueObj) => {
		let { domaininfo } = this.state;
		let value = valueObj.value || valueObj.business_type_id;
		if (sname === "name") value = valueObj.target.value;
		domaininfo[sname] = value;
		this.setState({ domaininfo: domaininfo });
	};

	ChangeCheckbox = (event) => {
		const target = event.target;
		let { domaininfo } = this.state;
		domaininfo[target.name] = target.checked ? 1 : 0;
		this.setState({ domaininfo: domaininfo });
	};

	validForm = () => {
		let { domaininfo } = this.state;
		let formIsValid = true,
			errors = {};
		if (!domaininfo["name"]) {
			errors["name"] = this.props.t("DOMAIN.VALIDATION_ERROR.REQUIRED");
			formIsValid = false;
		} else {
			var re = new RegExp(
				/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/
			);
			if (!domaininfo["name"].match(re)) {
				errors["name"] = this.props.t(
					"DOMAIN.VALIDATION_ERROR.INVALID_DOMAIN"
				);
				formIsValid = false;
			}
			// eslint-disable-next-line
		}
		if (!domaininfo["country_id"]) {
			errors["country_id"] = this.props.t(
				"DOMAIN.VALIDATION_ERROR.REQUIRED"
			);
			formIsValid = false;
		}
		if (!domaininfo["business_type_id"]) {
			errors["business_type_id"] = this.props.t(
				"DOMAIN.VALIDATION_ERROR.REQUIRED"
			);
			formIsValid = false;
		}

		this.setState({ errors: errors });
		return formIsValid;
	};

	saveDomain = (event) => {
		event.preventDefault();
		let { domaininfo, domain_id } = this.state;
		if (domain_id) domaininfo["id"] = domain_id;
		if (this.validForm()) {
			this.setState({ loading: true });
			domaininfo["status"] =
				Number(domaininfo["status"]) === 1 ? "1" : "0";
			webFormService
				.saveDomain(domaininfo)
				.then((response) => {
					if (
						response.status === 200 &&
						response.data.status === 200
					) {
						toast.success(response.data.message);
						this.setState({
							domaininfo: domaininfo,
							loading: false,
							redirectTolist: true,
						});
					} else {
						this.setState({ loading: false });
					}
				})
				.catch((error) => {
					toast.error(error);
				});
		}
	};
	handleRedirect = () => {
		this.setState({ redirectTolist: true });
	};

	render() {
		const {
			loading,
			domaininfo,
			country_list,
			domain_id,
			business_type_list,
			errors,
			redirectTolist,
		} = this.state;
		//console.log("domain_list", domaininfo);
		if (redirectTolist) return <Redirect to="/domains" />;
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-6 col-sm-offset-3">
						<h1>
							{domain_id
								? this.props.t("DOMAIN.UPDATE_DOMAIN") +
								  ` #${domain_id}`
								: this.props.t("DOMAIN.ADD_DOMAIN")}
						</h1>
						<div className="card">
							{loading && (
								<PageLoader loaderClass="text-center" />
							)}

							<div className="card-body">
								<div className="row">
									<form onSubmit={this.saveDomain}>
										<div className="col-sm-6 col-md-6 form-group">
											<label>
												{this.props.t("DOMAIN.COUNTRY")}
											</label>

											<Select
												id="country_id"
												onChange={this.handleChange.bind(
													this,
													"country_id"
												)}
												options={country_list}
												name="country_id"
												placeholder={this.props.t(
													"DOMAIN.COUNTRY"
												)}
												value={country_list.filter(
													({ value }) =>
														value ===
														domaininfo.country_id
												)}
												getOptionLabel={({ label }) =>
													label
												}
												getOptionValue={({ value }) =>
													value
												}
											/>
											<span className="text-danger">
												{errors.country_id || ""}
											</span>
										</div>
										<div className="col-sm-6 col-md-6 form-group">
											<label>
												{this.props.t(
													"DOMAIN.BUSINESS_TYPE"
												)}
											</label>

											<Select
												id="business_type_id"
												onChange={this.handleChange.bind(
													this,
													"business_type_id"
												)}
												options={business_type_list}
												name="business_type_id"
												placeholder={this.props.t(
													"DOMAIN.BUSINESS_TYPE"
												)}
												value={business_type_list.filter(
													({ business_type_id }) =>
														business_type_id ===
														domaininfo.business_type_id
												)}
												getOptionLabel={({ name }) =>
													name
												}
												getOptionValue={({
													business_type_id,
												}) => business_type_id}
											/>
											<span className="text-danger">
												{errors.business_type_id || ""}
											</span>
										</div>

										<div className="col-sm-6 col-md-6">
											<InputField
												inputProps={{
													id: "name",
													type: "text",
													value:
														domaininfo.name || "",
													name: "name",
													autoComplete: "off",
													placeholder: this.props.t(
														"DOMAIN.NAME"
													),
													label: this.props.t(
														"DOMAIN.NAME"
													),
													dataerror:
														errors.name || "",
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"name"
												)}
											/>
										</div>

										<div className="col-sm-12 form-group rc-inline">
											<CheckBox
												label={this.props.t(
													"DOMAIN.IS_OEM"
												)}
												name="is_oem"
												type="checkbox"
												id="is_oem"
												checked={domaininfo.is_oem || 0}
												value="1"
												onChange={this.ChangeCheckbox}
											/>
											<CheckBox
												label={this.props.t(
													"DOMAIN.IS_CONNECT_TO_PUSH"
												)}
												name="is_connecto_push"
												type="checkbox"
												id="is_connecto_push"
												checked={
													domaininfo.is_connecto_push ||
													0
												}
												value="1"
												onChange={this.ChangeCheckbox}
											/>
											<CheckBox
												label={this.props.t(
													"DOMAIN.ACTIVE"
												)}
												name="status"
												type="checkbox"
												id="status"
												checked={
													Number(
														domaininfo.status
													) === 1
														? true
														: false
												}
												value="1"
												onChange={this.ChangeCheckbox}
											/>
										</div>

										<div className="col-sm-12 form-group text-right">
											<button
												type="button"
												className="btn btn-default mrg-r15"
												onClick={this.handleRedirect}
											>
												{this.props.t("DOMAIN.CANCEL")}
											</button>
											<button
												type="submit"
												className="btn btn-primary "
											>
												{domain_id
													? this.props.t(
															"DOMAIN.UPDATE"
													  )
													: this.props.t(
															"DOMAIN.CREATE"
													  )}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation("webfrom")(EditDomain);
