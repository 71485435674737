/* eslint-disable */
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import SearchMyActive from "./SearchMyActive";
import { ReportService } from "../../../../service/reportService";
import PageLoader from "../../../elements/PageLoader";
import { ConfigAction, LeadActions } from "../../../../store/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class MyactiveReport extends Component {
	csvLink = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			subHeaders: [],
			csvJsonData: [],
			loading: false,
		};
	}

	componentDidMount = () => {};

	submitRequest = async (filterData) => {
		this.setState({ loading: true });
		let Result = await ReportService.getMyActiveLead(filterData);
		if (Result && Result.status === 200 && Result.data) {
			let data = Result.data.data || [];
			let report_data = [],
				single_info = {},
				Verification = 0,
				Nurturing = 0,
				Attribution = 0,
				Not_eligible = 0,
				Verification_Array = [],
				Nurturing_Array = [],
				Attribution_ARRAY = [],
				Not_eligible_Array = [],
				Attribution_REJECTED_ARRAY = [],
				Attribution_Rejected = [];
			if (data.length) {
				let agents = data
					.map((item) => item.agent_name)
					.filter(
						(value, index, self) => self.indexOf(value) === index
					);

				if (agents.length) {
					for (let single_agent of agents) {
						Verification_Array = data.filter(
							(v) =>
								v.agent_name === single_agent &&
								v.campgain_name === "NCL2VC" &&
								v.all_active_leads === 1
						);

						Verification = Verification_Array.map(
							(v) => v.all_active_leads
						).reduce((prev, curr) => prev + curr, 0);

						Nurturing_Array = data.filter(
							(v) =>
								v.agent_name === single_agent &&
								v.campgain_name === "NCL2NC" &&
								v.all_active_leads === 1
						);

						Nurturing = Nurturing_Array.map(
							(v) => v.all_active_leads
						).reduce((prev, curr) => prev + curr, 0);

						Attribution_ARRAY = data.filter(
							(v) =>
								v.agent_name === single_agent &&
								v.campgain_name === "NCL2AC" &&
								v.all_active_leads === 1
						);

						Attribution = Attribution_ARRAY.map(
							(v) => v.all_active_leads
						).reduce((prev, curr) => prev + curr, 0);

						Attribution_REJECTED_ARRAY = data.filter(
							(v) =>
								v.agent_name === single_agent &&
								v.campgain_name === "NCL2RA" &&
								v.all_active_leads === 1
						);

						Attribution_Rejected = Attribution_REJECTED_ARRAY.map(
							(v) => v.all_active_leads
						).reduce((prev, curr) => prev + curr, 0);

						console.log("");

						Not_eligible_Array = data.filter(
							(v) =>
								v.agent_name === single_agent &&
								v.not_eligible_for_dialer === 1 &&
								v.lead_id
						);

						// Not_eligible = Not_eligible_Array.map(
						// 	(v) => v.not_eligible_for_dialer
						// ).reduce((prev, curr) => prev + curr, 0);
						let Not_eligible_ids = Not_eligible_Array.reduce(
							(prev, single_result) => {
								if (
									single_result.lead_id &&
									prev.indexOf(single_result.lead_id) < 0
								)
									prev.push(single_result.lead_id);
								return prev;
							},
							[]
						);

						Not_eligible = Not_eligible_ids.length;

						single_info = {
							Agent_Name: single_agent,
							Verification: Verification,
							Verification_lead_ids: Verification_Array.map(
								(v) => v.lead_id
							),
							Nurturing: Nurturing,
							Nurturing_lead_ids: Nurturing_Array.map(
								(v) => v.lead_id
							),
							Attribution: Attribution,
							Attribution_lead_ids: Attribution_ARRAY.map(
								(v) => v.lead_id
							),
							Attribution_Rejected: Attribution_Rejected,
							Attribution_rejected_lead_ids: Attribution_REJECTED_ARRAY.map(
								(v) => v.lead_id
							),
							Not_Eligible_For_Dialer: Not_eligible,
							Not_Eligible_For_Dialer_lead_ids: Not_eligible_ids,
						};
						report_data.push(single_info);
					}
				}
			}
			this.setState(
				{ loading: false, csvJsonData: report_data },
				async () => {
					if (report_data instanceof Array && report_data.length) {
						await this.props.actions.SaveDownloadLog({
							module: "MyActiveReport",
							action: "2",
						});
					}
				}
			);
		} else {
			this.setState({ loading: false, csvJsonData: [] });
		}
	};

	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		return updateProps;
	}

	tableBody = (csvJsonData) => {
		let tableData = [],
			count = 0;
		for (let i of csvJsonData) {
			const {
				Agent_Name,
				Verification,
				Nurturing,
				Attribution,
				Not_Eligible_For_Dialer,
				Verification_lead_ids,
				Nurturing_lead_ids,
				Attribution_lead_ids,
				Not_Eligible_For_Dialer_lead_ids,
				Attribution_Rejected,
				Attribution_rejected_lead_ids,
			} = i;
			tableData.push(
				<tr key={count}>
					<td>{Agent_Name}</td>
					<td>
						{Verification ? (
							<>
								<a
									href={`/lead-list?lead_ids=${Verification_lead_ids.join(
										","
									)}`}
									target="_blank"
									onClick={async () =>
										await this.props.actions.SaveDownloadLog(
											{
												module: "MyActiveReport",
												action: "1",
											}
										)
									}
								>
									{Verification}
								</a>
							</>
						) : (
							Verification
						)}
					</td>
					<td>
						{Nurturing ? (
							<>
								<a
									href={`/lead-list?lead_ids=${Nurturing_lead_ids.join(
										","
									)}`}
									target="_blank"
									onClick={async () =>
										await this.props.actions.SaveDownloadLog(
											{
												module: "MyActiveReport",
												action: "1",
											}
										)
									}
								>
									{Nurturing}
								</a>
							</>
						) : (
							Nurturing
						)}
					</td>
					<td>
						{Attribution ? (
							<>
								<a
									href={`/lead-list?lead_ids=${Attribution_lead_ids.join(
										","
									)}`}
									target="_blank"
									onClick={async () =>
										await this.props.actions.SaveDownloadLog(
											{
												module: "MyActiveReport",
												action: "1",
											}
										)
									}
								>
									{Attribution}
								</a>
							</>
						) : (
							Attribution
						)}
					</td>
					<td>
						{Attribution_Rejected ? (
							<>
								<a
									href={`/lead-list?lead_ids=${Attribution_rejected_lead_ids.join(
										","
									)}`}
									target="_blank"
									onClick={async () =>
										await this.props.actions.SaveDownloadLog(
											{
												module: "MyActiveReport",
												action: "1",
											}
										)
									}
								>
									{Attribution_Rejected}
								</a>
							</>
						) : (
							Attribution_Rejected
						)}
					</td>
					<td>
						{Not_Eligible_For_Dialer ? (
							<>
								<a
									href={`/lead-list?lead_ids=${Not_Eligible_For_Dialer_lead_ids.join(
										","
									)}`}
									target="_blank"
									onClick={async () =>
										await this.props.actions.SaveDownloadLog(
											{
												module: "MyActiveReport",
												action: "1",
											}
										)
									}
								>
									{Not_Eligible_For_Dialer}
								</a>
							</>
						) : (
							Not_Eligible_For_Dialer
						)}
					</td>
				</tr>
			);
			count += 1;
		}

		return tableData;
	};

	render() {
		const { csvJsonData, loading } = this.state;
		return (
			<div>
				<SearchMyActive onSubmitFilter={this.submitRequest} />
				<div className="container-fluid">
					<div className="result-wrap">
						<h2>{this.props.t("MYACTIVE.MYACTIVE_REPORT")}</h2>
						<div className="card">
							{loading && (
								<PageLoader
									loaderClass=" text-center loader content-loader"
									loadertext="true"
								/>
							)}
							{!loading && (
								<div className="table-responsive table-colFixed">
									<table className="table table-bordered table-hover salesReportTable">
										<thead>
											<tr>
												<th>
													{this.props.t(
														"MYACTIVE.AGENT_NAME"
													)}
												</th>
												<th>
													{this.props.t(
														"MYACTIVE.VERIFICATION"
													)}
												</th>
												<th>
													{this.props.t(
														"MYACTIVE.NURTURING"
													)}
												</th>
												<th>
													{this.props.t(
														"MYACTIVE.ATTRIBUTION"
													)}
												</th>
												<th>
													{this.props.t(
														"MYACTIVE.ATTRIBUTION_REJECTED"
													)}
												</th>
												<th>
													{this.props.t(
														"MYACTIVE.NOT_ELIGIBLE_FOR_DIALER"
													)}
												</th>
											</tr>
										</thead>
										<tbody>
											{csvJsonData &&
											Object.keys(csvJsonData).length ? (
												this.tableBody(csvJsonData)
											) : (
												<tr>
													<td colSpan="6">
														{this.props.t(
															"MYACTIVE.NOT_FOUND"
														)}
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
			SaveDownloadLog: bindActionCreators(
				LeadActions.SaveDownloadLog,
				dispatch
			),
		},
	};
};
export default withTranslation("report")(
	connect(mapStateToProps, mapDispatchToProps)(MyactiveReport)
);
