import React, { Component } from "react";
class SwitchBtn extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isChecked: false,
		};
		// this.handleInputChange = this.handleInputChange.bind(this);
		// this.handleChecked = this.handleChecked.bind(this); // set this, because you need get methods from CheckBox
	}
	componentDidMount = () => {
		const { isChecked } = this.state;
		if (isChecked !== this.props.checked) {
			this.setState({ isChecked: this.props.checked });
		}
	};
	componentDidUpdate = () => {
		const { isChecked } = this.state;
		if (isChecked !== this.props.checked) {
			// console.log("here", this.props.checked);
			this.setState({ isChecked: this.props.checked });
		}
	};
	render() {
		return (
			<label
				className="switch-btn btn btn-link"
				htmlFor={this.props.htmlFor}
			>
				<input
					className="switch-btn "
					id={this.props.id}
					name={this.props.name}
					type="checkbox"
					checked={this.state.isChecked}
					onChange={this.props.onChange}
					disabled={this.props.disabled || false}
				/>
				<div className="slider round"></div>
				<span className="switch-label">{this.props.label}</span>
			</label>
		);
	}
}

export default SwitchBtn;
