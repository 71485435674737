import React, { Component } from "react";
import FormActivity from "./FormActivity";
import { default as Config } from "../../../../../config/config";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
	ConfigAction,
	InventoryAction,
	UserActions,
} from "../../../../../store/action";
import { toast } from "react-toastify";

class EditWebRules extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cityList: [],
			makeList: [],
			modelList: [],
			allModels: [],
			webIdRules: [],
			connectToScore: [],
			sourceList: [],
			timeOutEvent: {},
			cityNegate: false,
			pinCodeNegate: false,
			buyTimeRule: [],
			pinCodeList: [],
			processListId: null,
			locality: [],
			leadTypeRule: [],
			initialLoad: true,
		};
	}
	componentDidMount = () => {
		const processListId = new URLSearchParams(window.location.search).get(
			"id"
		);
		const rulePost = {
			process_list_id: processListId,
		};
		this.props.actions.getRuleData(rulePost);
		const postData = {
			config_data: [
				"city_state_list",
				"source_list",
				"webids_list",
				"lead_type_list",
			],
		};
		this.props.actions.getConfigData(postData);

		this.setState({ processListId: processListId });
	};
	static getDerivedStateFromProps = (nextProps, prevProps) => {
		const updateData = {};

		if (
			nextProps.makeModel &&
			nextProps.makeModel.model &&
			nextProps.makeModel.model !== prevProps.allModels
		) {
			updateData["allModels"] = nextProps.makeModel.model.map(function (
				e
			) {
				return {
					label: e.m,
					value: e.id,
					mk_id: e.mk_id,
				};
			});
		}
		if (
			nextProps.selectedRule &&
			nextProps.selectedRule.city_rule instanceof Array &&
			nextProps.city instanceof Array &&
			nextProps.city.length !== prevProps.cityList.length
		) {
			const { city_rule, city_rule_negate } = nextProps.selectedRule
				? nextProps.selectedRule
				: {};
			if (
				city_rule instanceof Array &&
				city_rule.length > 0 &&
				nextProps.city instanceof Array &&
				nextProps.city.length > 0
			) {
				for (let i of nextProps.city) {
					if (city_rule.includes(i.value)) {
						i.selected = true;
					}
				}
			}
			updateData["cityNegate"] = city_rule_negate;
			updateData["cityList"] = nextProps.city;
		}

		if (
			nextProps.selectedRule &&
			nextProps.selectedRule.lead_source_rule instanceof Array &&
			nextProps.sourceList instanceof Array &&
			nextProps.sourceList.length !== prevProps.sourceList.length
		) {
			const { lead_source_rule } = nextProps.selectedRule
				? nextProps.selectedRule
				: {};

			if (
				lead_source_rule instanceof Array &&
				nextProps.sourceList instanceof Array
			) {
				for (let i of nextProps.sourceList) {
					if (lead_source_rule.includes(i.value)) {
						i.selected = true;
					}
				}
			}
			updateData["sourceList"] = nextProps.sourceList;
		}
		if (
			Config.constants.BUY_TIME_RULE instanceof Array &&
			Config.constants.BUY_TIME_RULE.length > 0 &&
			prevProps.buyTimeRule instanceof Array &&
			prevProps.buyTimeRule.filter((e) => e.selected).length !==
				Config.constants.BUY_TIME_RULE.length
		) {
			const { buy_time_rule } = nextProps.selectedRule
				? nextProps.selectedRule
				: {};
			if (
				buy_time_rule instanceof Array &&
				buy_time_rule.length > 0 &&
				Config.constants.BUY_TIME_RULE instanceof Array &&
				Config.constants.BUY_TIME_RULE.length > 0
			) {
				for (let i of Config.constants.BUY_TIME_RULE) {
					if (buy_time_rule.includes(i.value)) {
						i.selected = true;
					}
				}
			}
			updateData["buyTimeRule"] = Config.constants.BUY_TIME_RULE;
		}

		if (
			nextProps.make instanceof Array &&
			nextProps.selectedRule &&
			nextProps.selectedRule.brand_rule instanceof Array &&
			nextProps.make instanceof Array &&
			nextProps.make.length !== prevProps.makeList.length
		) {
			const models = [];
			const { brand_rule } = nextProps.selectedRule
				? nextProps.selectedRule
				: {};
			if (
				brand_rule instanceof Array &&
				nextProps.sourceList instanceof Array
			) {
				for (let i of nextProps.make) {
					if (brand_rule.includes(i.value)) {
						i.selected = true;
					}
				}
				//////////////////////////////get all models for particular selected brand///////////
				if (
					prevProps.allModels instanceof Array &&
					prevProps.allModels.length > 0 &&
					brand_rule.length > 0
				) {
					const { model_rule } = nextProps.selectedRule
						? nextProps.selectedRule
						: {};

					for (let i of prevProps.allModels) {
						if (brand_rule.includes(+i.mk_id)) {
							if (
								model_rule instanceof Array &&
								nextProps.selectedRule.model_rule instanceof
									Array
							) {
								i.selected = true;
							}
							models.push(i);
						}
					}
				}
			}
			updateData["makeList"] = nextProps.make;
			updateData["modelList"] = models;
		}

		if (
			nextProps.selectedRule &&
			nextProps.selectedRule.web_id_rule instanceof Array &&
			nextProps.webIds instanceof Array &&
			nextProps.webIds.length !== prevProps.webIdRules.length
		) {
			const { web_id_rule } = nextProps.selectedRule
				? nextProps.selectedRule
				: {};
			if (
				web_id_rule instanceof Array &&
				nextProps.webIds instanceof Array
			) {
				for (let i of nextProps.webIds) {
					if (web_id_rule.includes(i.value)) {
						i.selected = true;
					}
				}
				updateData["webIdRules"] = nextProps.webIds;
			}
		}
		if (
			nextProps.selectedRule &&
			nextProps.selectedRule.lead_type_rule instanceof Array &&
			nextProps.leadTypeRule instanceof Array &&
			nextProps.leadTypeRule.length !== prevProps.leadTypeRule.length
		) {
			const { lead_type_rule } = nextProps.selectedRule
				? nextProps.selectedRule
				: {};
			if (
				lead_type_rule instanceof Array &&
				nextProps.leadTypeRule instanceof Array
			) {
				for (let i of nextProps.leadTypeRule) {
					if (lead_type_rule.includes(i.value)) {
						i.selected = true;
					}
				}
				updateData["leadTypeRule"] = nextProps.leadTypeRule;
			}
		}
		return updateData;
	};
	componentDidUpdate = () => {
		const { cityList, locality } = this.state;
		console.log(
			cityList.filter((e) => e.selected).length,
			" ",
			locality.length
		);
		if (
			cityList instanceof Array &&
			cityList.filter((e) => e.selected).length !== locality.length
		) {
			this.getLocality(
				cityList.filter((e) => e.selected).map((e) => e.value)
			);
		}
	};
	getLocality = async (city) => {
		let { pinCodeList, initialLoad } = this.state;
		if (city.length === 0) {
			pinCodeList = [];
		} else {
			const data = await this.props.actions.getLocalityForCity(city);
			const { locality = [] } = data ? data : {};
			pinCodeList = locality;

			if (
				pinCodeList instanceof Array &&
				pinCodeList.length > 0 &&
				this.props.selectedRule &&
				this.props.selectedRule.pincode_rule instanceof Array &&
				this.props.selectedRule.pincode_rule.length > 0 &&
				initialLoad
			) {
				for (let i of pinCodeList) {
					if (
						this.props.selectedRule.pincode_rule.includes(i.value)
					) {
						i.selected = true;
					}
				}
				initialLoad = false;
			}
		}
		this.setState({
			locality: city,
			pinCodeList: pinCodeList,
			initialLoad: initialLoad,
		});
	};
	getModelsForMake = (selectedIds) => {
		const { allModels } = this.state;

		let selectedModels = allModels.filter((e) =>
			selectedIds.includes(e.mk_id)
		);
		selectedModels = selectedModels.length ? selectedModels : [];

		this.setState({ modelList: selectedModels });
	};
	getLocalityForCity = (selectedIds) => {
		if (selectedIds instanceof Array && selectedIds.length > 0)
			this.props.actions.getLocalityForCity(selectedIds);
	};
	handleAddChangeEvent = (selectedIds, type) => {
		const {
			sourceList,
			cityList,
			makeList,
			modelList,
			webIdRules,
			buyTimeRule,
			allModels,
			pinCodeList,
			leadTypeRule,
		} = this.state;
		switch (type) {
			case "lead_source_rule":
				for (let i of sourceList) {
					if (selectedIds === -1 || selectedIds === i.value) {
						i.selected = true;
					}
				}
				break;
			case "city_rule":
				for (let i of cityList) {
					if (selectedIds === -1 || selectedIds === i.value) {
						i.selected = true;
					}
				}
				break;
			case "brand_rule":
				for (let i of makeList) {
					if (selectedIds === -1 || selectedIds === i.value) {
						i.selected = true;
					}
				}
				for (let i = 0; i < allModels.length; i++) {
					if (selectedIds === -1) {
						modelList.push(...allModels);
						break;
					} else if (+selectedIds === allModels[i].mk_id) {
						modelList.push(allModels[i]);
					}
				}
				break;
			case "model_rule":
				for (let i of modelList) {
					if (selectedIds === -1 || selectedIds === i.value) {
						i.selected = true;
					}
				}
				break;
			case "web_id_rule":
				for (let i of webIdRules) {
					if (selectedIds === -1 || selectedIds === i.value) {
						i.selected = true;
					}
				}
				break;
			case "buy_time_rule":
				for (let i of buyTimeRule) {
					if (selectedIds === -1 || selectedIds === i.value) {
						i.selected = true;
					}
				}
				break;
			case "lead_type_rule":
				for (let i of leadTypeRule) {
					if (selectedIds === -1 || selectedIds === i.value) {
						i.selected = true;
					}
				}
				break;
			// case "pincode_rule":
			// 	for (let i of pinCodeList) {
			// 		if (selectedIds === -1 || +selectedIds === i.value) {
			// 			i.selected = true;
			// 		}
			// 	}
			// 	break;
			default:
		}

		this.setState({
			sourceList: sourceList,
			cityList: cityList,
			makeList: makeList,
			modelList: modelList,
			webIdRules: webIdRules,
			buyTimeRule: buyTimeRule,
			pinCodeList: pinCodeList,
			leadTypeRule: leadTypeRule,
		});
	};
	handleRemoveEvent = (selectedIds, type) => {
		let {
			sourceList,
			cityList,
			makeList,
			modelList,
			webIdRules,
			buyTimeRule,
			pinCodeList,
			leadTypeRule,
		} = this.state;
		switch (type) {
			case "lead_source_rule":
				for (let i of sourceList) {
					if (selectedIds === -1 || selectedIds === i.value) {
						i.selected = false;
					}
				}

				break;
			case "city_rule":
				for (let i of cityList) {
					if (selectedIds === -1 || +selectedIds === i.value) {
						i.selected = false;
					}
				}
				if (selectedIds === -1) {
					pinCodeList = [];
					break;
				} else {
					// const model=[];
					// for (let i =0;i<modelList.length;i++) {
					// 	if(+selectedIds !== modelList[i].mk_id){
					// 		model.push(modelList[i])
					// 	}
					// }
					// modelList = model
				}
				break;
			case "brand_rule":
				for (let i of makeList) {
					if (selectedIds === -1 || +selectedIds === i.value) {
						i.selected = false;
					}
				}
				if (selectedIds === -1) {
					modelList = [];
					break;
				} else {
					const model = [];
					for (let i = 0; i < modelList.length; i++) {
						if (+selectedIds !== modelList[i].mk_id) {
							model.push(modelList[i]);
						}
					}
					modelList = model;
				}

				break;
			case "model_rule":
				for (let i of modelList) {
					if (selectedIds === -1 || selectedIds === i.value) {
						i.selected = false;
					}
				}
				break;
			case "web_id_rule":
				for (let i of webIdRules) {
					if (selectedIds === -1 || +selectedIds === i.value) {
						i.selected = false;
					}
				}

				break;
			case "buy_time_rule":
				for (let i of buyTimeRule) {
					if (selectedIds === -1 || selectedIds === i.value) {
						i.selected = false;
					}
				}
				break;
			case "lead_type_rule":
				for (let i of leadTypeRule) {
					if (selectedIds === -1 || selectedIds === i.value) {
						i.selected = false;
					}
				}
				break;
			// case "pincode_rule":
			// 	for (let i of pinCodeList) {
			// 		if (selectedIds === -1 || +selectedIds === i.value) {
			// 			i.selected = false;
			// 		}
			// 	}
			// 	break;
			default:
		}

		this.setState({
			sourceList: sourceList,
			cityList: cityList,
			makeList: makeList,
			modelList: modelList,
			webIdRules: webIdRules,
			buyTimeRule: buyTimeRule,
			pinCodeList: pinCodeList,
			leadTypeRule: leadTypeRule,
		});
	};

	// triggerChange = async (ids, type) => {
	// 	const { processListId } = this.state;
	// 	const postData = {
	// 		process_list_id: processListId,
	// 	};
	// 	postData[type] = ids;
	// 	console.log("hit api here", ids, " for type", type);
	// 	await UserActions.updateWebRule(postData);
	// };
	handleNegation = (status, type) => {
		let { cityNegate, pinCodeNegate } = this.state;
		if (type === "city_rule") {
			cityNegate = status;
		} else if (type === "pincode_rule") {
			pinCodeNegate = status;
		}
		this.setState({ cityNegate: cityNegate, pinCodeNegate: pinCodeNegate });
	};
	submitRuleChange = async () => {
		const selectedRules = {};
		const {
			cityNegate,
			cityList,
			buyTimeRule,
			webIdRules,
			makeList,
			modelList,
			sourceList,
			processListId,
			leadTypeRule,
		} = this.state;
		selectedRules["city_rule"] = cityList
			.filter((e) => e.selected)
			.map((e) => e.value);
		selectedRules["buy_time_rule"] = buyTimeRule
			.filter((e) => e.selected)
			.map((e) => e.value);
		selectedRules["web_id_rule"] = webIdRules
			.filter((e) => e.selected)
			.map((e) => e.value);
		selectedRules["brand_rule"] = makeList
			.filter((e) => e.selected)
			.map((e) => e.value);
		selectedRules["model_rule"] = modelList
			.filter((e) => e.selected)
			.map((e) => e.value);
		selectedRules["lead_source_rule"] = sourceList
			.filter((e) => e.selected)
			.map((e) => e.value);
		selectedRules["lead_type_rule"] = leadTypeRule
			.filter((e) => e.selected)
			.map((e) => e.value);
		// selectedRules["pincode_rule"] = pinCodeList
		// 	.filter((e) => e.selected)
		// 	.map((e) => e.value);

		selectedRules["city_rule_negate"] = cityNegate;
		// selectedRules["pincode_negate"] = pinCodeNegate;
		const postData = {
			...selectedRules,
		};
		postData["process_list_id"] = processListId;
		const { status, response } = await this.props.actions.postRuleData(
			postData
		);

		if (status) {
			toast.success(response);
			setTimeout(() => {
				window.location.href = `/list-web-rule?id=${processListId}`;
			}, 1000);
		} else {
			if (response) toast.error(response);
			else toast.error("ERROR");
		}
	};
	handleCancelEvent = () => {
		window.location.href = `/process-campaign-list`;
	};
	render() {
		const {
			cityList,
			makeList,
			modelList,
			sourceList,
			buyTimeRule,
			webIdRules,
			processListId,
			leadTypeRule,
			// pinCodeList,
		} = this.state;

		return (
			<div className="container-fluid">
				<h1>Web Rules</h1>

				{processListId && (
					<div className="web-rules">
						{/* <FormActivity
						title="Connect-to Score"
						onChange={this.handleChangeEvent}
						value={connectToScore}
						type="connect_to_rule"
						selected={selectedRules["connect_to_rule"]}
					/> */}
						<FormActivity
							title={this.props.t("WEB_RULE.SOURCE")}
							type="lead_source_rule"
							value={sourceList}
							onAdd={this.handleAddChangeEvent}
							onRemove={this.handleRemoveEvent}

							// selected={selectedRules["lead_source_rule"]}
						/>
						<FormActivity
							title={this.props.t("WEB_RULE.BUY_TIME")}
							type="buy_time_rule"
							value={buyTimeRule}
							onAdd={this.handleAddChangeEvent}
							onRemove={this.handleRemoveEvent}

							// selected={selectedRules["buy_time_rule"]}
						/>
						<FormActivity
							title={this.props.t("WEB_RULE.CITY")}
							value={cityList}
							onChange={this.handleNegation}
							type="city_rule"
							negate={this.state.cityNegate}
							negationRequired={true}
							negationTitle={this.props.t("WEB_RULE.CITY_NEGATE")}
							onAdd={this.handleAddChangeEvent}
							onRemove={this.handleRemoveEvent}
						/>
						{/* <FormActivity
							title={this.props.t("WEB_RULE.PINCODE")}
							value={pinCodeList}
							onChange={this.handleNegation}
							type="pincode_rule"
							negate={this.state.pinCodeNegate}
							negationRequired={true}
							negationTitle={this.props.t(
								"WEB_RULE.PINCODE_NEGATE"
							)}
							onAdd={this.handleAddChangeEvent}
							onRemove={this.handleRemoveEvent}
						/> */}
						<FormActivity
							title={this.props.t("WEB_RULE.BRAND_RULE")}
							value={makeList}
							type="brand_rule"
							onAdd={this.handleAddChangeEvent}
							onRemove={this.handleRemoveEvent}
						/>
						<FormActivity
							title={this.props.t("WEB_RULE.MODEL_RULE")}
							value={modelList}
							type="model_rule"
							onAdd={this.handleAddChangeEvent}
							onRemove={this.handleRemoveEvent}
						/>
						<FormActivity
							title={this.props.t("WEB_RULE.WEB_ID_RULE")}
							value={webIdRules}
							type="web_id_rule"
							onAdd={this.handleAddChangeEvent}
							onRemove={this.handleRemoveEvent}
						/>
						<FormActivity
							title={this.props.t("WEB_RULE.LEAD_TYPE_RULE")}
							type="lead_type_rule"
							value={leadTypeRule}
							onAdd={this.handleAddChangeEvent}
							onRemove={this.handleRemoveEvent}

							// selected={selectedRules["buy_time_rule"]}
						/>
					</div>
				)}
				{processListId && (
					<div className="col-xs-12">
						<div className="text-center">
							<div className="col-xs-6 text-right">
								<input
									type="button"
									onClick={this.submitRuleChange}
									value="Submit"
									className="btn btn-primary"
								/>
							</div>
							<div className="col-xs-6 text-left">
								<input
									type="button"
									onClick={this.handleCancelEvent}
									value="Cancel"
									className="btn btn-default "
								/>
							</div>
						</div>
					</div>
				)}
				{!processListId && "Please provide product list id"}
			</div>
		);
	}
}
const mapStateToProps = ({ config, inventory, user }) => {
	return {
		makeModel: inventory.mmv,
		make: inventory.mmv
			? inventory.mmv.make instanceof Array
				? inventory.mmv.make.map(function (e) {
						return {
							label: e.make,
							value: e.id,
						};
				  })
				: []
			: [],
		configData: config,
		city: config.city_state_list
			? config.city_state_list.city instanceof Array
				? config.city_state_list.city.map(function (e) {
						return {
							label: e.name,
							value: e.id,
							state_id: e.state_id,
						};
				  })
				: []
			: [],
		sourceList: config.source_list
			? config.source_list.map(function (e) {
					return {
						label: e.name,
						value: e.source_id,
					};
			  })
			: [],
		selectedRule: user.rule ? user.rule.rule : [],
		locality: inventory.locality ? inventory.locality : [],
		webIds:
			config.webids_list instanceof Array
				? config.webids_list.map(function (e) {
						return {
							label: e.webid,
							value: e.id,
						};
				  })
				: [],
		leadTypeRule:
			config.lead_type_list instanceof Array
				? config.lead_type_list.map(function (e) {
						return {
							label: e.name,
							value: e.id,
						};
				  })
				: [],
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getMMV: bindActionCreators(InventoryAction.getMMV, dispatch),
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
			getRuleData: bindActionCreators(UserActions.getRuleData, dispatch),
			postRuleData: bindActionCreators(
				UserActions.updateWebRule,
				dispatch
			),
			getLocalityForCity: bindActionCreators(
				InventoryAction.getLocality,
				dispatch
			),
		},
	};
};
export default withTranslation("webfrom")(
	connect(mapStateToProps, mapDispatchToProps)(EditWebRules)
);
