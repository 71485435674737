export const ConfigConstants = {
	GET_MODAL_STATUS: "GET_MODAL_STATUS",
	GET_MODAL_STATUS_ERR: "GET_MODAL_STATUS_ERR",
	GET_SOURCE_LIST: "GET_SOURCE_LIST",
	GET_SOURCE_LIST_ERR: "GET_SOURCE_LIST_ERR",
	GET_STATUS_LIST: "GET_STATUS_LIST",
	GET_CONFIG_DATA: "GET_CONFIG_DATA",
	GET_CONFIG_DATA_ERR: "GET_CONFIG_DATA_ERR",
	GET_CITY: "GET_CITY",
	GET_CITY_ERR: "GET_CITY_ERR",
	GET_DEALER: "GET_DEALER",
	GET_DEALER_ERR: "GET_DEALER_ERR",
};
