import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { ConfigAction } from "../../store/action";
import { bindActionCreators } from "redux";
import PageLoader from "../elements/PageLoader";

class AllDialerPush extends Component {
	constructor(props) {
		super(props);
		this.state = {
			gm_id: [],
			process_id: -1,
			loading: true,
		};
	}
	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		if (props.gm_id && props.gm_id !== state.gm_id) {
			updateProps["gm_id"] = props.gm_id;
			updateProps["loading"] = false;
		}
		return updateProps;
	}
	componentDidMount = () => {
		const requestBody = {
			config_data: ["gm_campaign_process_list"],
		};
		this.props.actions.getConfigData(requestBody);
	};
	handleChange = (sname, ovalue) => {
		let val = null;
		if (ovalue) val = ovalue.value;
		this.setState({
			[sname]: val,
		});
	};
	closeModal = () => {
		if (typeof this.props.closeModal === "function") {
			this.props.closeModal();
		}
	};
	pushLead = () => {
		const { process_id } = this.state;
		if (typeof this.props.handleAddToDialer === "function") {
			this.setState({ loading: true });
			const postData = {
				push_to_dialer: true,
				is_dailer_push_global: true,
			};
			if (process_id && process_id > 0) {
				postData["gm_id"] = process_id;
			}
			this.props.handleAddToDialer(postData);
		}
	};
	render() {
		const { gm_id, process_id, loading } = this.state;
		const allProcess = [...gm_id];
		allProcess.unshift({
			label: "All",
			value: -1,
		});

		return (
			<div className="">
				{loading && (
					<PageLoader
						loaderClass=" text-center loader content-loader"
						loadertext="true"
					/>
				)}
				<h2>{this.props.t("PUSH_DIALER.HEADING")}</h2>
				<Select
					id="process_id"
					value={allProcess.filter((e) => e.value === process_id)}
					onChange={this.handleChange.bind(this, "process_id")}
					options={allProcess}
					name="process_id"
					placeholder={this.props.t("PUSH_DIALER.PROCESS_NAME")}
					isOptionDisabled={(e) => e.isdisabled}
					isClearable
				/>
				<div className="add-to-dialer-footer">
					<div className="clearfix">
						<div className="col-sm-6">
							<button
								type="reset"
								className="btn btn-default btn-reset"
								onClick={this.closeModal}
								disabled={loading}
							>
								{this.props.t("PUSH_DIALER.CANCEL")}
							</button>
						</div>
						<div className="col-sm-6 text-right">
							<button
								type="submit"
								className="btn btn-primary"
								onClick={this.pushLead}
								disabled={loading}
							>
								{this.props.t("PUSH_DIALER.PUSH_TO_DIALER")}
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ config }) => {
	return {
		gm_id:
			config.gm_campaign_process_list instanceof Array
				? config.gm_campaign_process_list.map(function (e) {
						return {
							label: `${
								e.campaign_name
									? e.campaign_name.charAt(0).toUpperCase() +
									  e.campaign_name.slice(1)
									: ""
							} - ${e.gm_id}`,
							value: e.id,
						};
				  })
				: [],
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
		},
	};
};
export default withTranslation("common")(
	connect(mapStateToProps, mapDispatchToProps)(AllDialerPush)
);
