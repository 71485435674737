import React from "react";
import { connect } from "react-redux";
import { AuthActions } from "../../store/action";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import secureStorage from "../../config/encrypt";
import { withRouter } from "react-router-dom";

class RefreshToken extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};

		this.getRefreshToken();
	}

	getRefreshToken = () => {
		const getAuthUser = secureStorage.getItem("authUserInfo");
		let refreshToken =
			getAuthUser["refreshToken"] && getAuthUser["refreshToken"];
		let authEmail = getAuthUser["authUser"]["email"];
		this.props.actions.refreshToken({ refreshToken, authEmail });
	};

	render() {
		const { redirectTo } = this.props;

		if (typeof redirectTo !== "undefined") {
			return <Redirect to={redirectTo} />;
		}

		return "";
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth.auth,
		redirectTo: state.auth.redirectTo,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			refreshToken: bindActionCreators(
				AuthActions.getRefreshToken,
				dispatch
			),
		},
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(RefreshToken)
);
