/* eslint-disable */
import React, { Component } from "react";

class ConfirmPopUp extends Component {
	render() {
		return (
			<div className="popBlock">
				<div
					className="popover fade bs-popover-left"
					style={{
						right: "calc(100% + 32px)",
						width: "260px",
						left: "inherit",
						top: "-32px",
						// transform: "translate3d(-110px, -20px, 0px)",
					}}
				>
					<div className="arrow" style={{ top: "15px" }}></div>
					<h3 className="popover-header"></h3>
					<div className="popover-body">
						<p className="text-bold">
							{this.props.message ||
								"Are you sure to wnat to update ?"}
						</p>
						<div className="text-right">
							<button
								type="button"
								className="btn btn-default  mrg-r15"
								onClick={this.props.onReject}
							>
								<span>No</span>
							</button>

							<button
								type="button"
								className="btn btn-primary"
								onClick={this.props.onConfirm}
							>
								<span>Yes</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default ConfirmPopUp;
