/* eslint-disable */
import React, { Component } from "react";
import { LeadService } from "../../../../service/leadService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import PageLoader from "../../../elements/PageLoader";
import SwitchBtn from "../../../elements/SwitchBtn";
import secureStorage from "../../../../config/encrypt";
class EditRolePrivilege extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			roleList: [],
			rolePrivilegeList: [],
			pagination: this.props.pagination || {},
			filterData: { page: 1 },
			PopOver: false,
			actionType: "status",
			role_id: this.props.role_id,
		};
	}

	componentDidMount = async () => {
		await this.getRolePrivileList();
	};

	getRolePrivileList = async () => {
		let { role_id } = this.state;
		this.setState({ loading: true });
		let searchParams = "?role_id=" + role_id;
		LeadService.getRolePrivilegeList(searchParams)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let rolePrivilegeList = response.data.data || [];
					let pagination = response.data.pagination || {};
					this.setState({
						rolePrivilegeList: rolePrivilegeList,
						pagination: pagination,
						loading: false,
					});
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	updateStatus = (list_id, index) => {
		let { rolePrivilegeList } = this.state;
		let status = rolePrivilegeList[index].status;
		rolePrivilegeList[index].status =
			status && Number(status) === 1 ? 0 : 1;
		this.setState({ rolePrivilegeList: rolePrivilegeList });
	};

	updateRolePrivilege = () => {
		let { rolePrivilegeList, role_id } = this.state;
		let prvilegeArr = [];

		rolePrivilegeList.map((v) => {
			let objectToUpdate = {};
			if (typeof v.status !== "object") {
				objectToUpdate["role_id"] = role_id;
				objectToUpdate["status"] = v.status ? String(v.status) : "0";
				objectToUpdate["privilege_id"] = v.id;
				if (v.privilege_map_id)
					objectToUpdate["id"] = v.privilege_map_id;
			}
			if (objectToUpdate.role_id) prvilegeArr.push(objectToUpdate);
		});
		LeadService.saveRolePrivilegeList({ role_privilege: prvilegeArr })
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					this.props.closeModal();
					toast.success("Role privile saved successfully");
					this.setState({
						loading: false,
						PopOver: false,
					});
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	render() {
		const {
			loading,
			rolePrivilegeList,
			pagination,
			filterData,
			role_id,
		} = this.state;
		return (
			<div>
				<div className="container-fluid">
					<div className="result-wrap">
						<div className="row mrg-b15">
							<div className="col-sm-6">
								<p className="countNo mrg-t5 mrg-b0">
									{pagination.total} -{" "}
									{this.props.t("ROLE.PRIVILEGE_LIST")}
								</p>
							</div>
						</div>
						<div className="card">
							<div className="table-responsive">
								{loading && (
									<PageLoader loaderClass="text-center" />
								)}
								<table className="table table-bordered-h table-hover processTable">
									<thead>
										<tr>
											<th>
												{this.props.t(
													"ROLE.PRIVILEGE_NAME"
												)}
											</th>

											<th>
												{this.props.t("ROLE.STATUS")}
											</th>
										</tr>
									</thead>

									<tbody>
										{rolePrivilegeList.length > 0 ? (
											rolePrivilegeList.map(
												(singleinfo, index) => (
													<tr key={index}>
														<td>
															<div>
																{singleinfo.name ||
																	""}
															</div>
														</td>

														<td>
															{(role_id !== 1 &&
																Number(
																	singleinfo.id
																) !== 40) ||
															role_id === 1 ? (
																<div>
																	<SwitchBtn
																		label=""
																		htmlFor={
																			"Status" +
																			index
																		}
																		id={
																			"Status" +
																			index
																		}
																		name={
																			"Status" +
																			index
																		}
																		checked={
																			singleinfo.status ===
																			1
																				? true
																				: false
																		}
																		onChange={this.updateStatus.bind(
																			this,
																			singleinfo.id,
																			index
																		)}
																	/>
																</div>
															) : (
																""
															)}
														</td>
													</tr>
												)
											)
										) : (
											<tr>
												<td colSpan="10">
													{!loading ? (
														<h6 className="text-danger">
															{this.props.t(
																"DOMAIN.NOT_FOUND"
															)}
														</h6>
													) : (
														""
													)}
												</td>
											</tr>
										)}
									</tbody>
								</table>
								<div className="col-sm-6 text-right">
									<p
										className="btn btn-success btn-sm"
										title={this.props.t("ROLE.UPDATE")}
										onClick={this.updateRolePrivilege}
									>
										{this.props.t("ROLE.UPDATE")}
									</p>
								</div>
							</div>

							<div className="clearfix">
								{pagination.pages >= 1 ? (
									<div className="main-div-pagination">
										<div className="col-sm-6 text-light">
											{this.props.t("DOMAIN.SHOWING")}{" "}
											{filterData.page}{" "}
											{this.props.t("DOMAIN.OF")}{" "}
											{pagination.totalpage}{" "}
											{this.props.t("DOMAIN.PAGES")}
										</div>
										<div className="col-sm-6">
											<Pagination
												activePage={filterData.page}
												itemsCountPerPage={
													pagination.limit
												}
												totalItemsCount={
													pagination.total
												}
												pageRangeDisplayed={5}
												itemClass="page-item"
												linkClass="page-link"
												onChange={this.handlePageChange}
											/>
										</div>
									</div>
								) : (
									""
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation("common")(EditRolePrivilege);
