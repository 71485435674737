/* eslint-disable */
import { InventoryConstants } from "../constants/inventory.constants";
import { InventoryService } from "../../service/inventoryService";
import secureStorage from "../../config/encrypt";
import moment from "moment";
import _ from "lodash";
export const InventoryAction = {
	getMMV,
	getCityState,
	getFilter,
	getLocality,
};
function success(type, list) {
	return { type: type, list };
}
function failure(type, error) {
	return { type: type, error };
}
function getMMV() {
	return async (dispatch) => {
		try {
			const { status, data } = await InventoryService.getMMV();
			if (status === 200 && data.status === 200) {
				let mmvData = data.data;
				let filterData = { ...data.data };
				let cur_year = moment().format("YYYY");
				filterData.model =
					filterData && filterData.model && filterData.model.length
						? filterData.model.filter((v) =>
								v.e_year ? v.e_year >= cur_year : v
						  )
						: [];
				filterData.model_count = filterData.model.length;
				let makeArray = [];
				let res =
					filterData && filterData.make && filterData.make.length
						? filterData.make.map((v) => {
								let mk = _.find(filterData.model, {
									mk_id: v.id,
								});
								if (mk) makeArray.push(v);
						  })
						: [];
				filterData.make = makeArray;
				filterData.make_count = makeArray.length;

				const { make, model } = mmvData;
				let finalMM = [],
					make_map = {},
					model_map = {},
					Filtered_finalMM = [];
				const make_model = make.map(function (e) {
					return model
						.filter((f) => f.mk_id === e.id)
						.map(function (d) {
							make_map[e.id] = e.make;
							model_map[d.id] = d.m;
							return {
								label: `${e.make} ${d.m}`,
								value: d.id,
								mk_id: e.id,
							};
						});
				});

				for (let i of make_model) finalMM.push(...i);

				// new filtered mm

				const Filtered_Make_model = make.map(function (e) {
					return model
						.filter((f) =>
							f.e_year
								? f.mk_id === e.id && f.e_year >= cur_year
								: f.mk_id === e.id
						)
						.map(function (d) {
							make_map[e.id] = e.make;
							model_map[d.id] = d.m;
							return {
								label: `${e.make} ${d.m}`,
								value: d.id,
								mk_id: e.id,
							};
						});
				});
				for (let i of Filtered_Make_model) Filtered_finalMM.push(...i);

				const inventoryData = secureStorage.getItem("inventory");
				const stateData = {
					...inventoryData,
					mmv: mmvData,
					mm: finalMM,
					make_map: make_map,
					model_map: model_map,
					filterMMV: filterData,
					filteredMM: Filtered_finalMM,
				};
				console.log('set inventory')
				//secureStorage.setItem("inventory", stateData);
				dispatch(
					success(
						InventoryConstants.GET_MAKE_MODEL_VERSION,
						stateData
					)
				);
			} else throw new Error("not_found");
		} catch (err) {
			dispatch(
				failure(InventoryConstants.GET_MAKE_MODEL_VERSION_ERR, err)
			);
		}
	};
}

function getFilter() {
	return async (dispatch) => {
		try {
			const { status, data } = await InventoryService.getFilter();
			if (status === 200 && data.status === 200) {
				const inventoryData = secureStorage.getItem("inventory");
				///////////cast filter data to object////////////////////
				let filterData =
					data.data && data.data instanceof Array ? data.data : [];
				const filterObj = {};

				if (filterData.length) {
					for (let i of filterData) {
						filterObj[i.key] = {
							...i,
						};
					}
				}

				const finalObj = {
					minPrice: [],
					maxPrice: [],
					minYear: [],
					maxYear: [],
					fuelType: [],
					ucTransmission: [],
					bodyType: [],
					seats: [],
					owner: [],
					color: [],
				};

				if (filterObj && Object.keys(filterObj).length) {
					finalObj.minPrice =
						filterObj["min_car_price"] &&
						filterObj["min_car_price"].list
							? filterObj["min_car_price"].list.map(function (e) {
									return { label: e.key, value: +e.key };
							  })
							: [];
					finalObj.maxPrice =
						filterObj["max_car_price"] &&
						filterObj["max_car_price"].list
							? filterObj["max_car_price"].list.map(function (e) {
									return { label: e.key, value: +e.key };
							  })
							: [];
					finalObj.minYear =
						filterObj["min_make_year"] &&
						filterObj["min_make_year"].list
							? filterObj["min_make_year"].list.map(function (e) {
									return { label: e.key, value: e.value };
							  })
							: [];
					finalObj.maxYear =
						filterObj["max_make_year"] &&
						filterObj["max_make_year"].list
							? filterObj["max_make_year"].list.map(function (e) {
									return { label: e.key, value: e.value };
							  })
							: [];
					finalObj.fuelType =
						filterObj["fuel_type_id"] &&
						filterObj["fuel_type_id"].list
							? filterObj["fuel_type_id"].list.map(function (e) {
									return { label: e.value, value: e.key };
							  })
							: [];
					finalObj.ucTransmission =
						filterObj["uc_transmission_id"] &&
						filterObj["uc_transmission_id"].list
							? filterObj["uc_transmission_id"].list.map(
									function (e) {
										return { label: e.value, value: e.key };
									}
							  )
							: [];
					finalObj.bodyType =
						filterObj["body_type"] && filterObj["body_type"].list
							? filterObj["body_type"].list.map(function (e) {
									return { label: e.value, value: e.key };
							  })
							: [];
					// finalObj.seats = filterObj['uc_transmission_id']?filterObj['uc_transmission_id'].list:[]
					// finalObj.owner = filterObj['owner_type'] && filterObj['owner_type'].list?filterObj['owner_type'].list.map(function(e){return{label : e.key,value:+e.key}}):[]
					// finalObj.color = filterObj['uc_transmission_id']?filterObj['uc_transmission_id'].list:[]
				}
				const stateData = {
					...inventoryData,
					filterData: filterObj,
					filterValues: finalObj,
				};
				secureStorage.setItem("inventory", stateData);
				dispatch(success(InventoryConstants.FILTER_FETCH, stateData));
			} else throw new Error("not_found");
		} catch (err) {
			dispatch(failure(InventoryConstants.FILTER_FETCH_ERR, err));
		}
	};
}

function getCityState() {
	return async (dispatch) => {
		try {
			const { status, data } = await InventoryService.getCityState();
			if (status === 200 && data.status === 200) {
				const { city, state } = data.data;
				const inventoryData = secureStorage.getItem("inventory");
				const stateData = {
					...inventoryData,
					city: city,
					state: state,
				};
				secureStorage.setItem("inventory", stateData);
				dispatch(success(InventoryConstants.CITY_STATE, stateData));
			} else throw new Error("not_found");
		} catch (err) {
			dispatch(failure(InventoryConstants.CITY_STATE_ERR, err));
		}
	};
}
function getLocality(params) {
	return async (dispatch) => {
		try {
			const { status, data } = await InventoryService.getLocality(params);
			if (status === 200 && data.status === 200) {
				let response = data.data;
				// const inventoryData = secureStorage.getItem("inventory");
				response = response.map(function (e) {
					return {
						label: `${e.name} ${e.pin_code}`,
						value: e.id,
						pin_code: e.pin_code,
						city_id: e.city_id,
					};
				});
				const stateData = {
					locality: [...response],
				};
				// secureStorage.setItem("inventory", stateData);
				dispatch(success(InventoryConstants.LOCALITY_DATA, stateData));
				return stateData;
			} else throw new Error("not_found");
		} catch (err) {
			dispatch(failure(InventoryConstants.LOCALITY_DATA_ERR, err));
		}
	};
}
