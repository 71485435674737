import React, { Component } from "react";
import Select from "react-select";
import InputField from "../../../elements/InputField";
import { webFormService } from "../../../../service/webFormService";
import { LeadService } from "../../../../service/leadService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import PageLoader from "../../../elements/PageLoader";
import UtmCampaign from "../webids/UtmCampaign";
import { Redirect } from "react-router-dom";
class EditWebids extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: this.props.match.params.id ? true : false,
			webidsinfo: this.props.webidsinfo || {},
			domain_list: this.props.domain_list || [],
			webids_id: this.props.match.params.id || 0,
			errors: {},
			redirectTolist: false,
		};
	}

	componentDidMount = async () => {
		await this.getConfigData();
		if (this.state.webids_id) await this.getwebidsInfo();
	};

	getConfigData = async () => {
		const requestBody = {
			config_data: ["domain_list"],
		};
		LeadService.getConfig(requestBody)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					this.setState({
						domain_list: response.data.data.domain_list
							? response.data.data.domain_list[0]
							: [],
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	getwebidsInfo = async () => {
		this.setState({ loading: true });
		let webids_id = this.state.webids_id;
		webFormService
			.getwebidsList({ id: webids_id, page: 1 })
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let webidsinfo = response.data.data
						? response.data.data.webidsInfo[0]
						: [];
					console.log("webidsinfo", webidsinfo);
					this.setState({
						webidsinfo: webidsinfo,
						loading: false,
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	handleChange = (sname, valueObj) => {
		let { webidsinfo } = this.state;
		let value = valueObj.value || valueObj.id;
		if (sname === "webid") value = valueObj.target.value;
		webidsinfo[sname] = value;
		this.setState({ webidsinfo: webidsinfo });
	};

	validForm = () => {
		let { webidsinfo } = this.state;
		let formIsValid = true,
			errors = {};
		if (!webidsinfo["webid"]) {
			errors["webid"] = this.props.t("WEBIDS.VALIDATION_ERROR.REQUIRED");
			formIsValid = false;
		}
		if (!webidsinfo["domain_id"]) {
			errors["domain_id"] = this.props.t(
				"WEBIDS.VALIDATION_ERROR.REQUIRED"
			);
			formIsValid = false;
		}

		this.setState({ errors: errors });
		return formIsValid;
	};

	Updatewebids = (event) => {
		event.preventDefault();
		let { webidsinfo, webids_id } = this.state;
		if (webids_id) webidsinfo["id"] = webids_id;
		if (this.validForm()) {
			this.setState({ loading: true });
			webFormService
				.saveWebids(webidsinfo)
				.then((response) => {
					if (
						response.status === 200 &&
						response.data.status === 200
					) {
						toast.success(response.data.message);
						this.setState({
							webidsinfo: webidsinfo,
							loading: false,
							redirectTolist: true,
						});
					} else {
						this.setState({ loading: false });
					}
				})
				.catch((error) => {
					toast.error(error);
				});
		}
	};
	handleRedirect = () => {
		this.setState({ redirectTolist: true });
	};

	render() {
		const {
			loading,
			webidsinfo,
			webids_id,
			domain_list,
			errors,
			redirectTolist,
		} = this.state;
		if (redirectTolist) return <Redirect to="/webids" />;
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-6 col-sm-offset-3">
						<h1>
							{webids_id
								? this.props.t("WEBIDS.UPDATE_WEBIDS") +
								  ` #${webids_id}`
								: this.props.t("WEBIDS.ADD_WEBIDS")}
						</h1>
						<div className="card">
							{loading && (
								<PageLoader loaderClass="text-center" />
							)}

							<div className="card-body">
								<div className="row">
									<form onSubmit={this.Updatewebids}>
										<div className="col-sm-6 col-md-6">
											<InputField
												inputProps={{
													id: "webid",
													type: "text",
													value:
														webidsinfo.webid || "",
													name: "webid",
													autoComplete: "off",
													placeholder: this.props.t(
														"WEBIDS.WEBID"
													),
													label: this.props.t(
														"WEBIDS.WEBID"
													),
													dataerror:
														errors.webid || "",
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"webid"
												)}
											/>
										</div>
										<div className="col-sm-6 col-md-6 form-group">
											<label>
												{this.props.t("WEBIDS.DOMAIN")}
											</label>

											<Select
												id="domain_id"
												onChange={this.handleChange.bind(
													this,
													"domain_id"
												)}
												options={domain_list}
												name="domain_id"
												placeholder={this.props.t(
													"WEBIDS.DOMAIN"
												)}
												value={domain_list.filter(
													({ id }) =>
														id ===
														webidsinfo.domain_id
												)}
												getOptionLabel={({ name }) =>
													name
												}
												getOptionValue={({ id }) => id}
											/>
											<span className="text-danger">
												{errors.domain_id || ""}
											</span>
										</div>

										<div className="col-sm-12 form-group text-right">
											<button
												type="button"
												className="btn btn-default mrg-r15"
												onClick={this.handleRedirect}
											>
												{this.props.t("WEBIDS.CANCEL")}
											</button>
											<button
												type="submit"
												className="btn btn-primary"
											>
												{this.props.t("WEBIDS.SUBMIT")}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>

						{webids_id ? <UtmCampaign webids_id={webids_id} /> : ""}
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation("webfrom")(EditWebids);
