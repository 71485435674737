import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import DateFormat from "dateformat";
import { default as config } from "./../../../config/config";
import { AuditService } from "../../../service/auditService";
import PageLoader from "../../elements/PageLoader";
class AuditActivity extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activityList: [],
			audit_id: props.auditId || 0,
			loading: true,
		};
	}

	componentDidMount = async () => {
		await this.getAuditActivity();
	};

	static getDerivedStateFromProps = (nextProps, prevState) => {
		let updatedStateData = {};
		updatedStateData.audit_id = nextProps.auditId;
		return updatedStateData;
	};

	getAuditActivity = async () => {
		let postData = { audit_id: this.state.audit_id };
		AuditService.getAuditActivity(postData)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let responseData = response.data.data || [];
					this.setState(
						{
							activityList: responseData,
							loading: false,
						},
						() => {
							//console.log("state set successfully");
						}
					);
				}
			})
			.catch((error) => {
				// toast.error(error);
			});
	};

	capitalizeFirstLetter = (str) => {
		return str
			.replace("_", " ")
			.toLowerCase()
			.split(" ")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(" ");
	};

	render() {
		const { activityList, loading } = this.state;
		return (
			<div className="container-fluid">
				<div className="card">
					{activityList && activityList.length ? (
						<div className="card-body">
							{activityList.map((activity_list, index) => (
								<React.Fragment key={index}>
									<div className="row">
										<ul>
											<li className=" col-sm-12">
												{DateFormat(
													activity_list.created_date,
													config.constants.DATE_FORMAT
												)}{" "}
												&nbsp;
												<strong>
													{this.capitalizeFirstLetter(
														activity_list.audit_status ===
															"PENDING_ON_AGENT"
															? "AUDITED"
															: activity_list.audit_status
													)}
												</strong>{" "}
												&nbsp; By{" "}
												{activity_list.customer_name}(
												{activity_list.role})
												{activity_list.audit_status ===
													"AUDIT_TRIGGERED" &&
												activity_list.status_name ? (
													<li>
														{" "}
														{activity_list.status_name &&
															activity_list.status_name}{" "}
														-{" "}
														{activity_list.sub_status_name &&
															activity_list.sub_status_name}{" "}
														-{" "}
														{activity_list.attribution_status_name &&
															activity_list.attribution_status_name}
													</li>
												) : (
													""
												)}
											</li>
											{activity_list.comment ? (
												<li className=" col-sm-12">
													{activity_list.comment}
												</li>
											) : (
												""
											)}
										</ul>
									</div>
									<br></br>
								</React.Fragment>
							))}
						</div>
					) : loading ? (
						<PageLoader
							loaderClass="text-center"
							loadertext="false"
						/>
					) : (
						<div className="card-body">
							<div className="row">
								<div className="col-sm-12 form-group">
									<h1>
										{this.props.t("SEARCH_AUDIT.NOT_FOUND")}
									</h1>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default withTranslation("audit")(AuditActivity);
