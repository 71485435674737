import React, { Component } from "react";
import { bindActionCreators } from "redux";

import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import SearchRetailReport from "./SearchRetailReport";
import { ReportService } from "../../../../service/reportService";
import PageLoader from "../../../elements/PageLoader";
import { CSVLink } from "react-csv";
import { LeadActions } from "../../../../store/action";
import { ConfigAction } from "../../../../store/action";
import { toast } from "react-toastify";
class RetailReport extends Component {
	csvLink = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			reportData: {},
			filterData: {},
			isSearchClicked: false,
			loading: false,
			isSearchButtonDisabled: false,
			csvDataIndividual: [],
			make_map: [],
			model_map: [],
			city_map: [],
		};
	}
	// static getDerivedStateFromProps(props, state) {
	// 	const updateProps = {};
	// 	return updateProps;
	// }
	componentDidMount = () => {
		const requestBody = {
			config_data: ["gm_campaign_process_list"],
		};
		this.props.actions.getConfigData(requestBody);
	};
	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		if (props.make_map && props.make_map !== state.make_map) {
			updateProps["make_map"] = props.make_map;
		}
		if (props.model_map && props.model_map !== state.model_map) {
			updateProps["model_map"] = props.model_map;
		}
		if (props.city_map && props.city_map !== state.city_map) {
			updateProps["city_map"] = props.city_map;
		}
		return updateProps;
	}
	isFormValid = (filterData) => {
		let isValid = false;
		if (
			filterData &&
			filterData.process_id &&
			filterData.process_id.length
		) {
			isValid = true;
		}
		if (!isValid)
			toast.error(this.props.t("RETAIL_REPORT.ERROR.PROCESS_REQUIRED"));
		return isValid;
	};

	onSubmitFilter = (filterData) => {
		this.setState({ loading: true, isSearchButtonDisabled: true });
		if (this.isFormValid(filterData)) {
			ReportService.getRetailReport(filterData)
				.then((result) => {
					const {
						data: { data: response = {} },
					} = result;
					this.setState(
						{
							filterData: filterData,
							reportData: response,
							isSearchClicked: true,
							loading: false,
							isSearchButtonDisabled: false,
						},
						async () => {
							if (Object.keys(response).length) {
								await this.props.actions.SaveDownloadLog({
									module: "RetailReport",
									action: "2",
								});
							}
						}
					);
				})
				.catch((err) => {});
		} else {
			this.setState({ loading: false, isSearchButtonDisabled: false });
		}
	};
	downloadCsvForDetail = (data) => {
		const { make_map, model_map, city_map } = this.state;
		if (data instanceof Array) {
			for (let i of data) {
				for (let j in i) {
					if (j === "Make") {
						i[j] = make_map[i[j]] ? make_map[i[j]] : "";
					} else if (j === "Model") {
						i[j] = model_map[i[j]] ? model_map[i[j]] : "";
					} else if (j === "City") {
						i[j] = city_map[i[j]] ? city_map[i[j]] : "";
					} else if (j === "CrossSell-City") {
						i[j] = city_map[i[j]] ? city_map[i[j]] : "";
					}
				}
			}

			this.setState({ csvDataIndividual: data }, async () => {
				if (data instanceof Array && data.length) {
					this.csvLink.current.link.click();
					await this.props.actions.SaveDownloadLog({
						module: "RetailReport",
						action: "1",
					});
				}
			});
		}
	};
	requestCsvDownload = (slug, process_id, month, year, slug_val) => {
		const {
			filterData: { selected_month, is_all_process_selected },
		} = this.state;
		process_id = is_all_process_selected
			? this.state.filterData.process_id.toString()
			: String(process_id);
		const postData = {
			slug: slug,
			report_type: "retail_oem_summary",
			process_ids: process_id,
			month: month,
			year: year,
		};
		if (selected_month) {
			postData["selected_month"] = selected_month;
		}
		if (Number(slug_val) > 0) {
			ReportService.getReportDetail(postData)
				.then((result) => {
					const {
						data: { data = [] },
					} = result;
					this.downloadCsvForDetail(data);
				})
				.catch((err) => {});
		}
	};
	tableBody = (reportData) => {
		let tableData = [],
			count = 0;
		for (let dataObj of Object.values(reportData)) {
			for (let data of dataObj) {
				tableData.push(
					<tr key={count}>
						<td>{data.process_name}</td>
						<td>{data.identifier_type}</td>
						<td
							className={
								Number(data.lms_overall) > 0
									? "report-csv-list"
									: ""
							}
							onClick={() =>
								this.requestCsvDownload(
									"lms_overall",
									data.process_id,
									data.month,
									data.year,
									data.lms_overall
								)
							}
						>
							{data.lms_overall}
						</td>
						<td
							className={
								Number(data.lms_unique) > 0
									? "report-csv-list"
									: ""
							}
							onClick={() =>
								this.requestCsvDownload(
									"lms_unique",
									data.process_id,
									data.month,
									data.year,
									data.lms_unique
								)
							}
						>
							{data.lms_unique}
						</td>
						<td
							className={
								Number(data.call_verified) > 0
									? "report-csv-list"
									: ""
							}
							onClick={() =>
								this.requestCsvDownload(
									"call_verified",
									data.process_id,
									data.month,
									data.year,
									data.call_verified
								)
							}
						>
							{data.call_verified}
						</td>
						<td
							className={
								Number(data.DCB) > 0 ? "report-csv-list" : ""
							}
							onClick={() =>
								this.requestCsvDownload(
									"DCB",
									data.process_id,
									data.month,
									data.year,
									data.DCB
								)
							}
						>
							{data.DCB}
						</td>
						<td
							className={
								Number(data.otp) > 0 ? "report-csv-list" : ""
							}
							onClick={() =>
								this.requestCsvDownload(
									"otp",
									data.process_id,
									data.month,
									data.year,
									data.otp
								)
							}
						>
							{data.otp}
						</td>
						<td
							className={
								Number(data.other_lead) > 0
									? "report-csv-list"
									: ""
							}
							onClick={() =>
								this.requestCsvDownload(
									"crm_spk_lead",
									data.process_id,
									data.month,
									data.year,
									data.other_lead
								)
							}
						>
							{data.other_lead}
						</td>
						<td
							className={
								Number(data.retail_create_total) > 0
									? "report-csv-list"
									: ""
							}
							onClick={() =>
								this.requestCsvDownload(
									"retail_create_total",
									data.process_id,
									data.month,
									data.year,
									data.retail_create_total
								)
							}
						>
							{data.retail_create_total}
						</td>
						<td
							className={
								Number(data.retail_create_crm) > 0
									? "report-csv-list"
									: ""
							}
							onClick={() =>
								this.requestCsvDownload(
									"retail_create_crm",
									data.process_id,
									data.month,
									data.year,
									data.retail_create_crm
								)
							}
						>
							{data.retail_create_crm}
						</td>
						<td
							className={
								Number(data.retail_create_lms) > 0
									? "report-csv-list"
									: ""
							}
							onClick={() =>
								this.requestCsvDownload(
									"retail_create_lms",
									data.process_id,
									data.month,
									data.year,
									data.retail_create_lms
								)
							}
						>
							{data.retail_create_lms}
						</td>
						<td
							className={
								Number(data.retail_update_total) > 0
									? "report-csv-list"
									: ""
							}
							onClick={() =>
								this.requestCsvDownload(
									"retail_update_total",
									data.process_id,
									data.month,
									data.year,
									data.retail_update_total
								)
							}
						>
							{data.retail_update_total || 0}
						</td>
						<td
							className={
								Number(data.retail_update_crm) > 0
									? "report-csv-list"
									: ""
							}
							onClick={() =>
								this.requestCsvDownload(
									"retail_update_crm",
									data.process_id,
									data.month,
									data.year,
									data.retail_update_crm
								)
							}
						>
							{data.retail_update_crm || 0}
						</td>
						<td
							className={
								Number(data.retail_update_lms) > 0
									? "report-csv-list"
									: ""
							}
							onClick={() =>
								this.requestCsvDownload(
									"retail_update_lms",
									data.process_id,
									data.month,
									data.year,
									data.retail_update_lms
								)
							}
						>
							{data.retail_update_lms || 0}
						</td>
						<td>
							{(data.retail_created_date_percentage || 0).toFixed(
								2
							)}
							%
						</td>
						<td>
							{(
								data.retail_created_date_crm_percentage || 0
							).toFixed(2)}
							%
						</td>
						<td>
							{(
								data.retail_created_date_lms_percentage || 0
							).toFixed(2)}
							%
						</td>
					</tr>
				);
				count += 1;
			}
		}

		return tableData;
	};
	render() {
		const {
			reportData,
			filterData,
			isSearchClicked,
			loading,
			isSearchButtonDisabled,
			csvDataIndividual,
		} = this.state;
		let selectedMonth =
			filterData && filterData.selected_month
				? +filterData.selected_month.getMonth() + +1
				: "";
		return (
			<div>
				<SearchRetailReport
					onSubmitFilter={this.onSubmitFilter}
					isSearchButtonDisabled={isSearchButtonDisabled}
				/>
				<div className="hidden">
					<CSVLink
						className="hidden"
						ref={this.csvLink}
						filename="lead_detail.csv"
						data={csvDataIndividual}
					>
						{" "}
					</CSVLink>
				</div>
				<div className="container-fluid reportContainer">
					<div className="result-wrap">
						<h2>{this.props.t("RETAIL_REPORT.TITLE")}</h2>
						<div className="card">
							{isSearchClicked ? (
								<div className="table-responsive table-colFixed">
									<table className="table table-bordered table-hover salesReportTable ">
										<thead>
											<tr>
												<th>
													{this.props.t(
														"RETAIL_REPORT.TABLE.PROCESS_NAME"
													)}
												</th>
												<th>
													{this.props.t(
														"RETAIL_REPORT.TABLE.MONTH"
													)}
												</th>
												<th>
													{this.props.t(
														"RETAIL_REPORT.TABLE.LMS_TRIGGERED_OVERALL"
													)}
												</th>
												<th>
													{this.props.t(
														"RETAIL_REPORT.TABLE.LMS_TRIGGERED_UNIQUE"
													)}
												</th>
												<th>
													{this.props.t(
														"RETAIL_REPORT.TABLE.CALL_VERIFIED"
													)}
												</th>
												<th>
													{this.props.t(
														"RETAIL_REPORT.TABLE.DCB"
													)}
												</th>
												<th>
													{this.props.t(
														"RETAIL_REPORT.TABLE.OTP"
													)}
												</th>
												<th>
													{this.props.t(
														"RETAIL_REPORT.TABLE.OTHER"
													)}
												</th>
												<th>
													{this.props.t(
														"RETAIL_REPORT.TABLE.RETAIL_CREATE_DATE"
													)}
												</th>
												<th>
													{this.props.t(
														"RETAIL_REPORT.TABLE.RCD_CRM"
													)}
												</th>
												<th>
													{this.props.t(
														"RETAIL_REPORT.TABLE.RCD_LMS"
													)}
												</th>
												<th>
													{this.props.t(
														"RETAIL_REPORT.TABLE.RETAIL_UPDATE_DATE"
													)}
												</th>
												<th>
													{this.props.t(
														"RETAIL_REPORT.TABLE.RUD_CRM"
													)}
												</th>
												<th>
													{this.props.t(
														"RETAIL_REPORT.TABLE.RUD_LMS"
													)}
												</th>
												<th>
													{this.props.t(
														"RETAIL_REPORT.TABLE.RCD_PERCENT"
													)}
												</th>
												<th>
													{this.props.t(
														"RETAIL_REPORT.TABLE.RCD__PERCENT_CRM"
													)}
												</th>
												<th>
													{this.props.t(
														"RETAIL_REPORT.TABLE.RCD_PERCENT_LMS"
													)}
												</th>
											</tr>
										</thead>
										<tbody>
											{reportData &&
											Object.keys(reportData).length ? (
												this.tableBody(reportData)
											) : (
												<tr>
													<td>No record found</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							) : (
								""
							)}
							{loading && (
								<PageLoader
									loaderClass=" text-center loader content-loader"
									loadertext="true"
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ config, inventory }) => {
	return {
		make_map: inventory.make_map ? inventory.make_map : {},
		model_map: inventory.model_map ? inventory.model_map : {},
		city_map:
			inventory.city instanceof Array
				? inventory.city.reduce(
						(obj, item) => ((obj[item.id] = item.name), obj),
						{}
				  )
				: {},
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			SaveDownloadLog: bindActionCreators(
				LeadActions.SaveDownloadLog,
				dispatch
			),
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
		},
	};
};
export default withTranslation("report")(
	connect(mapStateToProps, mapDispatchToProps)(RetailReport)
);
