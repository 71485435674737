import secureStorage from "./encrypt";
import _ from "lodash";
export const API_URL = {
	// GETWAY_API: "http://localhost:7010/",
	// INVENTORY_API: "http://localhost:4021/",
	// DEALER_API: "http://localhost:4022/",
	//GETWAY_API: process.env.REACT_APP_GETWAY_API
	// GETWAY_API: "https://int-gcloud.gaadi.com/"
	// GETWAY_API: "http://192.168.72.36:8626/"
	// GETWAY_API: "http://localhost:8626/"
};

export const isUserHasRightsToAccessPage = (moduleName, controller, action) => {
	let isUserHasRights = false;
	const rolePrivilege = secureStorage.getItem("userAccess");

	let checkForRights = _.findKey(rolePrivilege, function (v) {
		if (action && v.action) {
			return (
				v.module.toLowerCase() === moduleName.toLowerCase() &&
				v.controller.toLowerCase() === controller.toLowerCase() &&
				v.action.split("/")[1].toLowerCase() ===
					action.split("/")[1].toLowerCase()
			);
		} else {
			return (
				v.module.toLowerCase() === moduleName.toLowerCase() &&
				v.controller.toLowerCase() === controller.toLowerCase()
			);
		}
	});
	isUserHasRights = checkForRights && checkForRights.length ? true : false;
	return isUserHasRights;
};

export const SETTING = {
	CSV_EXPORT_LIMIT_IN_NORMAL_TIME: 30000,
	OFFICE_START_TIME: 9,
	OFFICE_END_TIME: 18,
};
