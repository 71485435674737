import React, { Component } from "react";
import { bindActionCreators } from "redux";

import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import SearchRetailUpdateReport from "./SearchRetailUpdateReport";
import { ReportService } from "../../../../service/reportService";
import PageLoader from "../../../elements/PageLoader";
import { CSVLink } from "react-csv";
import { LeadActions } from "../../../../store/action";
import { ConfigAction } from "../../../../store/action";
import { toast } from "react-toastify";
class RetailUpdateReport extends Component {
	csvLink = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			reportData: {},
			filterData: {},
			isSearchClicked: false,
			loading: false,
			isSearchButtonDisabled: false,
			csvDataIndividual: [],
			make_map: [],
			model_map: [],
			city_map: [],
		};
	}
	// static getDerivedStateFromProps(props, state) {
	// 	const updateProps = {};
	// 	return updateProps;
	// }
	componentDidMount = () => {
		const requestBody = {
			config_data: ["gm_campaign_process_list"],
		};
		this.props.actions.getConfigData(requestBody);
	};
	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		if (props.make_map && props.make_map !== state.make_map) {
			updateProps["make_map"] = props.make_map;
		}
		if (props.model_map && props.model_map !== state.model_map) {
			updateProps["model_map"] = props.model_map;
		}
		if (props.city_map && props.city_map !== state.city_map) {
			updateProps["city_map"] = props.city_map;
		}
		return updateProps;
	}
	isFormValid = (filterData) => {
		let isValid = false;
		if (filterData && filterData.selected_month) {
			isValid = true;
		}
		if (!isValid)
			toast.error(
				this.props.t("RETAIL_UPDATE_REPORT.ERROR.MONTH_REQUIRED")
			);
		return isValid;
	};

	onSubmitFilter = (filterData) => {
		this.setState({ loading: true, isSearchButtonDisabled: true });
		filterData["report_type"] = "update_report";
		if (this.isFormValid(filterData)) {
			ReportService.getRetailReportSummary(filterData)
				.then((result) => {
					const {
						data: { data: response = {} },
					} = result;

					this.setState(
						{
							filterData: filterData,
							reportData: response,
							isSearchClicked: true,
							loading: false,
							isSearchButtonDisabled: false,
						},
						async () => {
							if (Object.keys(response).length) {
								await this.props.actions.SaveDownloadLog({
									module: "RetailReport",
									action: "2",
								});
							}
						}
					);
				})
				.catch((err) => {});
		} else {
			this.setState({ loading: false, isSearchButtonDisabled: false });
		}
	};
	downloadCsvForDetail = (data) => {
		const { make_map, model_map, city_map } = this.state;
		if (data instanceof Array) {
			for (let i of data) {
				for (let j in i) {
					if (j === "Make") {
						i[j] = make_map[i[j]] ? make_map[i[j]] : "";
					} else if (j === "Model") {
						i[j] = model_map[i[j]] ? model_map[i[j]] : "";
					} else if (j === "City") {
						i[j] = city_map[i[j]] ? city_map[i[j]] : "";
					} else if (j === "CrossSell-City") {
						i[j] = city_map[i[j]] ? city_map[i[j]] : "";
					}
				}
			}
			this.setState({ csvDataIndividual: data }, async () => {
				if (data instanceof Array && data.length) {
					this.csvLink.current.link.click();
					await this.props.actions.SaveDownloadLog({
						module: "RetailReport",
						action: "1",
					});
				}
			});
		}
	};
	requestCsvDownload = (slug, process_id, month, year, slug_val) => {
		const {
			filterData: { selected_month },
		} = this.state;
		const postData = {
			slug: slug,
			report_type: "update_report",
			process_id: process_id,
			month: month,
			year: year,
		};
		if (selected_month) {
			postData["selected_month"] = selected_month;
		}
		if (Number(slug_val) > 0) {
			ReportService.getReportDetail(postData)
				.then((result) => {
					const {
						data: { data = [] },
					} = result;
					this.downloadCsvForDetail(data);
				})
				.catch((err) => {});
		}
	};
	tableBody = (reportData) => {
		let tableData = [],
			count = 0,
			keyCount = 0,
			heading = "",
			month = 0,
			year = 0;
		month = Number(this.state.filterData.selected_month.getMonth()) + 1;
		year = this.state.filterData.selected_month.getFullYear();
		for (let dataObj of Object.values(reportData)) {
			if (Object.keys(reportData)[keyCount] === "CRM") {
				heading = "CRM";
			}
			if (Object.keys(reportData)[keyCount] === "LMS") {
				heading = "LMS";
			}
			let html = (
				<thead key={"hed_" + keyCount}>
					{Object.keys(reportData)[keyCount] !== "TOTAL" ? (
						<tr key={"row_" + keyCount}>
							<th>{heading}</th>
						</tr>
					) : null}

					<tr key={"head_row_" + keyCount}>
						<th>
							{this.props.t("RETAIL_UPDATE_REPORT.TABLE.PROCESS")}
						</th>
						<th>
							{this.props.t(
								"RETAIL_UPDATE_REPORT.TABLE.TOTAL_RETAIL"
							)}
						</th>

						<th>
							{this.props.t("RETAIL_UPDATE_REPORT.TABLE.0_30")}
						</th>
						<th>
							{this.props.t("RETAIL_UPDATE_REPORT.TABLE.31_60")}
						</th>
						<th>
							{this.props.t("RETAIL_UPDATE_REPORT.TABLE.61_90")}
						</th>
						<th>
							{this.props.t("RETAIL_UPDATE_REPORT.TABLE.91_120")}
						</th>
						<th>
							{this.props.t("RETAIL_UPDATE_REPORT.TABLE.121_150")}
						</th>
						<th>
							{this.props.t("RETAIL_UPDATE_REPORT.TABLE.151_180")}
						</th>
						<th>
							{this.props.t("RETAIL_UPDATE_REPORT.TABLE.GT_180")}
						</th>

						<th></th>
						<th>
							{this.props.t(
								"RETAIL_UPDATE_REPORT.TABLE.PER_0_30"
							)}
						</th>
						<th>
							{this.props.t(
								"RETAIL_UPDATE_REPORT.TABLE.PER_31_60"
							)}
						</th>
						<th>
							{this.props.t(
								"RETAIL_UPDATE_REPORT.TABLE.PER_61_90"
							)}
						</th>
						<th>
							{this.props.t(
								"RETAIL_UPDATE_REPORT.TABLE.PER_91_120"
							)}
						</th>
						<th>
							{this.props.t(
								"RETAIL_UPDATE_REPORT.TABLE.PER_121_150"
							)}
						</th>
						<th>
							{this.props.t(
								"RETAIL_UPDATE_REPORT.TABLE.PER_151_180"
							)}
						</th>
						<th>
							{this.props.t(
								"RETAIL_UPDATE_REPORT.TABLE.PER_GT_180"
							)}
						</th>
					</tr>
				</thead>
			);

			tableData.push(html);
			for (let data of dataObj) {
				let slug_type = "";
				if (Object.keys(reportData)[keyCount] === "CRM") {
					slug_type = "crm_";
				}
				if (Object.keys(reportData)[keyCount] === "LMS") {
					slug_type = "lms_";
				}
				tableData.push(
					<tr key={count}>
						<td>{data.reporting_name}</td>
						<td
							className={
								Number(data.total_retail) > 0
									? "report-csv-list"
									: ""
							}
							onClick={() =>
								this.requestCsvDownload(
									slug_type + "total_retail",
									data.process_id,
									month,
									year,
									data.total_retail
								)
							}
						>
							{data.total_retail || 0}
						</td>

						<td
							className={
								Number(data.till_30) > 0
									? "report-csv-list"
									: ""
							}
							onClick={() =>
								this.requestCsvDownload(
									slug_type + "total_till_30",
									data.process_id,
									month,
									year,
									data.till_30
								)
							}
						>
							{data.till_30 || 0}
						</td>
						<td
							className={
								Number(data.till_60) > 0
									? "report-csv-list"
									: ""
							}
							onClick={() =>
								this.requestCsvDownload(
									slug_type + "total_till_60",
									data.process_id,
									month,
									year,
									data.till_60
								)
							}
						>
							{data.till_60 || 0}
						</td>
						<td
							className={
								Number(data.till_90) > 0
									? "report-csv-list"
									: ""
							}
							onClick={() =>
								this.requestCsvDownload(
									slug_type + "total_till_90",
									data.process_id,
									month,
									year,
									data.till_90
								)
							}
						>
							{data.till_90 || 0}
						</td>
						<td
							className={
								Number(data.till_120) > 0
									? "report-csv-list"
									: ""
							}
							onClick={() =>
								this.requestCsvDownload(
									slug_type + "total_till_120",
									data.process_id,
									month,
									year,
									data.till_120
								)
							}
						>
							{data.till_120 || 0}
						</td>
						<td
							className={
								Number(data.till_150) > 0
									? "report-csv-list"
									: ""
							}
							onClick={() =>
								this.requestCsvDownload(
									slug_type + "total_till_150",
									data.process_id,
									month,
									year,
									data.till_150
								)
							}
						>
							{data.till_150 || 0}
						</td>
						<td
							className={
								Number(data.till_180) > 0
									? "report-csv-list"
									: ""
							}
							onClick={() =>
								this.requestCsvDownload(
									slug_type + "total_till_180",
									data.process_id,
									month,
									year,
									data.till_180
								)
							}
						>
							{data.till_180 || 0}
						</td>
						<td
							className={
								Number(data.gt_180) > 0 ? "report-csv-list" : ""
							}
							onClick={() =>
								this.requestCsvDownload(
									slug_type + "total_gt_180",
									data.process_id,
									month,
									year,
									data.gt_180
								)
							}
						>
							{data.gt_180 || 0}
						</td>

						<td></td>

						<td>
							{(
								data.percentage && data.percentage.till_30
							).toFixed(2) || 0}
							%
						</td>
						<td>
							{(
								data.percentage && data.percentage.till_60
							).toFixed(2) || 0}
							%
						</td>
						<td>
							{(
								data.percentage && data.percentage.till_90
							).toFixed(2) || 0}
							%
						</td>
						<td>
							{(
								data.percentage && data.percentage.till_120
							).toFixed(2) || 0}
							%
						</td>
						<td>
							{(
								data.percentage && data.percentage.till_150
							).toFixed(2) || 0}
							%
						</td>
						<td>
							{(
								data.percentage && data.percentage.till_180
							).toFixed(2) || 0}
							%
						</td>
						<td>
							{(
								data.percentage && data.percentage.gt_180
							).toFixed(2) || 0}
							%
						</td>
					</tr>
				);
				count += 1;
			}
			keyCount += 1;
		}

		return tableData;
	};
	render() {
		const {
			reportData,
			filterData,
			isSearchClicked,
			loading,
			isSearchButtonDisabled,
			csvDataIndividual,
		} = this.state;
		let selectedMonth =
			filterData && filterData.selected_month
				? +filterData.selected_month.getMonth() + +1
				: "";
		return (
			<div>
				<SearchRetailUpdateReport
					onSubmitFilter={this.onSubmitFilter}
					isSearchButtonDisabled={isSearchButtonDisabled}
				/>
				<div className="hidden">
					<CSVLink
						className="hidden"
						ref={this.csvLink}
						filename="lead_detail.csv"
						data={csvDataIndividual}
					>
						{" "}
					</CSVLink>
				</div>
				<div className="container-fluid reportContainer">
					<div className="result-wrap">
						<h2>{this.props.t("RETAIL_UPDATE_REPORT.TITLE")}</h2>
						<div className="card">
							{isSearchClicked ? (
								<div className="table-responsive table-colFixed">
									<table className="table table-bordered table-hover salesReportTable ">
										{/* <tbody> */}
										{reportData &&
										Object.keys(reportData).length ? (
											this.tableBody(reportData)
										) : (
											<tr>
												<td>No record found</td>
											</tr>
										)}
										{/* </tbody> */}
									</table>
								</div>
							) : (
								""
							)}
							{loading && (
								<PageLoader
									loaderClass=" text-center loader content-loader"
									loadertext="true"
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ config, inventory }) => {
	return {
		make_map: inventory.make_map ? inventory.make_map : {},
		model_map: inventory.model_map ? inventory.model_map : {},
		city_map:
			inventory.city instanceof Array
				? inventory.city.reduce(
						(obj, item) => ((obj[item.id] = item.name), obj),
						{}
				  )
				: {},
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			SaveDownloadLog: bindActionCreators(
				LeadActions.SaveDownloadLog,
				dispatch
			),
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
		},
	};
};
export default withTranslation("report")(
	connect(mapStateToProps, mapDispatchToProps)(RetailUpdateReport)
);
