import { ConfigConstants } from "../constants/config.constants";
import { ConfigService } from "../../service/configService";
import secureStorage from "../../config/encrypt";
export const ConfigAction = {
	getModalStatus,
	getSources,
	getStatus,
	getConfigData,
};
function success(type, list) {
	return { type: type, list };
}
function failure(type, error) {
	return { type: type, error };
}

function getModalStatus() {
	return async (dispatch) => {
		try {
			const modalData = secureStorage.getItem("config");
			const { status, data } = await ConfigService.getAllModalStatus();
			if (status === 200 && data.status === 200) {
				const {
					data: { model_status_list },
				} = data;
				const stateData = {
					...modalData,
					modalStatus: model_status_list,
				};
				secureStorage.setItem("config", stateData);
				dispatch(success(ConfigConstants.GET_MODAL_STATUS, stateData));
			} else dispatch(failure("not_found"));
		} catch (err) {
			dispatch(failure(ConfigConstants.GET_MODAL_STATUS_ERR, err));
		}
	};
}
function getSources() {
	return async (dispatch) => {
		try {
			const sourceData = secureStorage.getItem("config");
			const { status, data } = await ConfigService.getSources();
			if (status === 200 && data.status === 200) {
				const {
					data: { source_list },
				} = data;
				const stateData = {
					...sourceData,
					sources: source_list,
				};
				secureStorage.setItem("config", stateData);
				dispatch(success(ConfigConstants.GET_SOURCE_LIST, stateData));
			} else
				dispatch(
					failure(ConfigConstants.GET_SOURCE_LIST_ERR, "not_found")
				);
		} catch (err) {
			dispatch(failure(ConfigConstants.GET_SOURCE_LIST_ERR, err));
		}
	};
}

function getStatus() {
	return async (dispatch) => {
		try {
			const { status, data } = await ConfigService.getStatus();
			if (status === 200 && data.status === 200) {
				const {
					data: { status_list },
				} = data;
				dispatch(success(status_list));
			} else dispatch(failure("not_found"));
		} catch (err) {
			dispatch(failure(err));
		}
	};
	function success(list) {
		return { type: ConfigConstants.GET_STATUS_LIST, list };
	}
	function failure(error) {
		return { type: ConfigConstants.GET_STATUS_LIST_ERR, error };
	}
}

function getConfigData(postData) {
	return async (dispatch) => {
		try {
			const { status, data } = await ConfigService.getConfigData(
				postData
			);
			if (status === 200 && data.status === 200) {
				const config_data = data.data;
				dispatch(success(config_data));
			} else dispatch(failure("not_found"));
		} catch (err) {
			dispatch(failure(err));
		}
	};
	function success(list) {
		return { type: ConfigConstants.GET_CONFIG_DATA, list };
	}
	function failure(error) {
		return { type: ConfigConstants.GET_CONFIG_DATA_ERR, error };
	}
}
