import MasterService from "./MasterService";

export const LeadService = {
	...MasterService,

	getLeadList(postData) {
		return this.post("/lead/list_preference", postData);
	},
	getLeadHistory(searchParams) {
		return this.get("/activity/get_activity", searchParams);
	},
	getConfig(params) {
		return this.post("config/get_data", params);
	},
	getDseList(params) {
		return this.post("dse/get_dse_user", params);
	},
	updatePreference(postData) {
		return this.post("/lead/update_car_preference", postData);
	},
	addNewCarPreference(postData) {
		return this.post("/lead/save_recommended_car", postData);
	},
	getSubLeadDetailsById(postData) {
		return this.post("/lead/list_assign_car", postData);
	},
	sendDataToUB(postData) {
		return this.post("/used_car/send_to_ub", postData);
	},
	saveTestdrive(postData) {
		return this.post("/testdrive/save", postData);
	},
	getLeads(params) {
		return this.post("lead/lead_list", params);
	},
	getLeadDetails(params) {
		return this.get("lead/lead_details", params);
	},
	updateCustomer(params) {
		return this.post("/customer/edit_customer", params);
	},
	updateExchange(params) {
		return this.put("/lead/update_exchange", params);
	},
	updateFinance(params) {
		return this.put("/lead/update_finance", params);
	},
	getAssignCarList(postData) {
		return this.post("/lead/list_assign_car", postData);
	},
	updateAssignCar(postData) {
		return this.post("/lead/update_assign_car", postData);
	},
	getAssignCarDealers(postData) {
		return this.get("/lead/list_assign_car_dealers", postData);
	},
	updateStatus(postData) {
		return this.put("/lead/update_status", postData);
	},
	saveDealerVisit(postData) {
		return this.post("/testdrive/save_dealer_visit", postData);
	},
	submitBulkUpload(postData) {
		return this.post("/lead/raw_lead_bulk_upload", postData);
	},
	getDealerDseList() {
		return this.get("/dse/get_dealer_dse_list");
	},
	getRoleList() {
		return this.get("/roles/list_role");
	},
	getRolePrivilegeList(params) {
		return this.get("/roles/list_privilege" + params);
	},
	saveRolePrivilegeList(postData) {
		return this.post("/roles/save_privilege", postData);
	},
	updateLeadViaCsv(postData) {
		return this.post("/lead/update_lead_csv", postData);
	},
	updateLeadStatusViaCsv(postData) {
		return this.post("/lead/update_lead_status_csv", postData);
	},
	saveRequestDownloadCsv(postData) {
		return this.post("/lead/save_download_csv", postData);
	},
	uploadDocInfo(postData) {
		return this.post("/lead/upload_document_info", postData);
	},
	getDocumentList(postData) {
		return this.post("/lead/get_document_list", postData);
	},
	updateMyActive(postData) {
		return this.post("/lead/update_my_active", postData);
	},
	updateLeadSpkDateViaCsv(postData) {
		return this.post("/lead/update_lead_spk_date", postData);
	},
	reassignDse(postData) {
		return this.post("/lead/reassign_dse", postData);
	},
	saveCsvDownloadLog(postData) {
		return this.post("/lead/save_csv_download_log", postData);
	},
	updateWhatsappClickCount(postData) {
		return this.patch("/lead/update_whatsapp_count", postData);
	},
	updateFordLeadLdStatusCsv(postData) {
		return this.patch("/lead/update_ld_status_ford_csv", postData);
	},
};
