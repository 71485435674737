import React, { Component } from "react";
import Rupees from "../../elements/Rupees";
import ModalPopup from "../../elements/Popup";
import TabTestDriveDealer from "./TabTestDriveDealer";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { LeadActions } from "./../../../store/action";
import { bindActionCreators } from "redux";
import config from "../../../config/config";
import { toast } from "react-toastify";
// import InfiniteScroll from "react-infinite-scroller";
// import { LeadService } from "../../../service/leadService";
// import PageLoader from "../../elements/PageLoader";
import PopupOverTips from "./../../elements/PopupOverTips";
import dateformat from "dateformat";
import EditDse from "./EditDse";
import * as constant from "../../../config/constant";

class AssignCarList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editModal: false,
			PopupOverTips: false,
			subLeadInfo: {
				sub_lead_id: 0,
				lead_id: 0,
			},
			assignCarList: [],
			pagination: this.props.pagination || {},
			filterData: { page: 1 },
			hasMoreItems: true,
			loading: false,
			mmv: {},
			isDeleteAction: false,
			editDseModal: false,
			editDse: {},
			hasAccessToReassign: false,
			copiedText: "",
			istextCopied: 0,
		};
	}
	componentDidMount = async () => {
		const lead_id = new URLSearchParams(window.location.search).get("id");
		let { subLeadInfo, filterData } = this.state;
		subLeadInfo.lead_id = lead_id;
		filterData.lead_id = lead_id;
		await this.setState({ subLeadInfo, filterData });
		//this.getAssignCarList()
	};

	static getDerivedStateFromProps = (nextProps, prevState) => {
		let updatedStateData = {};
		if (
			nextProps.assignCarList &&
			nextProps.assignCarList.record !== prevState.assignCarList
		) {
			updatedStateData["assignCarList"] = nextProps.assignCarList.record;
		}
		if (nextProps.mmv !== prevState.mmv) {
			updatedStateData["mmv"] = nextProps.mmv;
		}
		if (nextProps.hasAccessToReassign !== prevState.hasAccessToReassign) {
			updatedStateData["hasAccessToReassign"] =
				nextProps.hasAccessToReassign;
		}
		return updatedStateData;
	};

	showEditModal = (subLeadId) => {
		let { subLeadInfo } = this.state;
		subLeadInfo.sub_lead_id = subLeadId;
		this.setState({ editModal: true, subLeadInfo: subLeadInfo });
	};
	closeEditModal = () => {
		this.setState({ editModal: false });
	};
	togglePopover = () => {
		// if (!this.state.PopupOverTips) {
		// 	// attach/remove event handler
		// 	document.addEventListener("click", this.handleOutsideClick, false);
		// } else {
		// 	document.removeEventListener(
		// 		"click",
		// 		this.handleOutsideClick,
		// 		false
		// 	);
		// }
		this.setState({
			PopupOverTips: !this.state.PopupOverTips,
		});
		console.log(this.state.PopupOverTips);
	};
	// handleOutsideClick = (e) => {
	// 	// ignore clicks on the component itself
	// 	if (this.node.contains(e.target)) {
	// 		return;
	// 	}

	// 	this.togglePopover();
	// };

	getAssignCarList = async () => {
		const urlInfo = new URLSearchParams(window.location.search);
		const id = urlInfo.get("id");
		if (id) {
			const post_data = {
				lead_id: id,
				_with: ["assigned_cars"],
			};
			this.props.actions.getAssignCar(post_data);
		}
		// await LeadService.getLeadDetails(post_data)
		// 	.then((response) => {
		// 		if (response.status === 200 && response.data.status === 200) {
		// 			let responseData = response.data.data;
		// 			let currentList = this.state.assignCarList;
		// 			let updatedLeadlist =
		// 				loadmoreStatus === 1
		// 					? currentList.concat(responseData)
		// 					: responseData;
		// 			this.setState({
		// 				assignCarList: updatedLeadlist,
		// 				pagination: response.data.pagination,
		// 				loading: false,
		// 				hasMoreItems:
		// 					responseData.length &&
		// 						response.data.pagination.next_page
		// 						? true
		// 						: false,
		// 			});
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		toast.error(error);
		// 	});
	};

	loadMoreitems = async () => {
		this.getAssignCarList(1);
	};

	removeCar = async (subLeadId) => {
		let params = {
			id: subLeadId,
			status: 2,
		};
		let result = await this.props.actions.updateAssignCar(params);
		if (result && result.status) toast.success(result.response);
		this.getAssignCarList({ lead_id: this.state.subLeadInfo.lead_id });
	};
	showDseEditModal = (request_id, assign_id, dse_id) => {
		const editDse = {
			request_id: request_id,
			assign_id: assign_id,
			dse_id: dse_id,
		};
		this.setState({ editDseModal: true, editDse: editDse });
	};
	closeDseEditModal = () => {
		this.setState({ editDseModal: false, editDse: {} });
	};
	updateDse = async (post_data, request_id) => {
		const params = {
			...post_data,
			request_id: request_id,
		};
		let result = await this.props.actions.reassignDse(params);
		if (result && result.status) {
			this.setState({ editDseModal: false });
			toast.success(this.props.t("ASSIGN_CAR.DSE_UDPATED_SUCCESSFULLY"));
			this.getAssignCarList(1);
			this.props.actions.updateHistoryFlag(true);
		}
	};
	updateWhatsappClickCount = async (history_type, assigncar_id) => {
		const leadId = new URLSearchParams(window.location.search).get("id");
		const postData = {
			lead_id: leadId,
			update_type: "dse",
			history_type: history_type,
			assigncar_id: assigncar_id,
		};
		this.props.actions.updateWhatsappClickCount(postData);
	};

	copyTextToClipboard = (value) => {
		let { copiedText, istextCopied, mmv } = this.state;
		let makeName =
			mmv && value.make_id && mmv.make && mmv.make.length
				? mmv.make
						.filter((v) => Number(v.id) === Number(value.make_id))
						.map((v) => v.make)[0]
				: "";
		let modelName =
			mmv && value.model_id && mmv.model && mmv.model.length
				? mmv.model
						.filter((v) => Number(v.id) === Number(value.model_id))
						.map((v) => v.m)[0]
				: "";
		let textSapmle = `Please find below the Dealer details for your enquiry on ${makeName} - ${modelName}.`;
		textSapmle += `\n\nDealer Name : ${value.dealer_name}\nDSE Name : ${value.dse_name}\nDSE Mobile : ${value.dse_mobile}\n`;
		textSapmle += `\nLet us know in case any further details are required.`;
		copiedText = textSapmle;
		istextCopied = value.id;
		setTimeout(() => {
			this.setState({ istextCopied: 0 });
		}, 1000);
		this.setState({ copiedText: copiedText, istextCopied: istextCopied });
		navigator.clipboard.writeText(copiedText);
	};

	render() {
		const {
			assignCarList,
			mmv,
			hasAccessToReassign,
			copiedText,
			istextCopied,
		} = this.state;
		return (
			<div>
				<div className="card">
					<div
						ref={(ref) => (this.scrollParentRef = ref)}
						className="card-body scroll-y"
						style={{ maxHeight: "290px" }}
					>
						<div className="table-responsive">
							<table className="table table-bordered-h table-hover recommendedTable ">
								<thead>
									<tr>
										<th></th>
										<th className="pad-l0">
											{this.props.t(
												"ASSIGN_CAR.CAR_DETAILS"
											)}
										</th>
										<th>
											{this.props.t(
												"ASSIGN_CAR.DEALER_DSE_DETAILS"
											)}
										</th>
										<th>
											{this.props.t(
												"ASSIGN_CAR.TEST_DRIVE"
											)}
										</th>
										<th>
											{this.props.t(
												"ASSIGN_CAR.DEALER_VISIT"
											)}
										</th>
										{/* <th>{this.props.t(
												"ASSIGN_CAR.SOURCE"
											)}</th> */}
										<th></th>
									</tr>
								</thead>
								<tbody>
									{assignCarList &&
										assignCarList.length !== 0 &&
										assignCarList.map((data, k) => (
											<tr key={k}>
												<td>
													<span
														className={
															Number(
																data.status
															) === 1
																? "dseStatus text-success"
																: Number(
																		data.status
																  ) === 2
																? "dseStatus text-danger"
																: "dseStatus text-orange"
														}
														onClick={this.togglePopover.bind(
															this,
															data.id
														)}
													>
														<i
															className={
																Number(
																	data.status
																) === 1
																	? "ic-check_circle-24px"
																	: Number(
																			data.status
																	  ) === 2
																	? "ic-cancel-24px"
																	: "ic-pending-24px"
															}
														></i>
														<PopupOverTips
															title="sd"
															heading={
																Number(
																	data.status
																) === 1
																	? this.props.t(
																			"ASSIGN_CAR.APPROVED"
																	  )
																	: Number(
																			data.status
																	  ) === 2
																	? this.props.t(
																			"ASSIGN_CAR.REJECTED"
																	  )
																	: this.props.t(
																			"ASSIGN_CAR.PENDING"
																	  )
															}
															position="right"
															style={{
																transform:
																	"translate3d(13px, -24px, 0px)",
															}}
															// ref={(node) => {
															// 	if(node) this.node = node;
															// }}
															arrowstyle={{
																top: "30px",
															}}
														>
															<div>
																<p>
																	{this.props.t(
																		"ASSIGN_CAR.LD_STATUS"
																	)}
																	:
																	{Number(
																		data.status
																	) === 1
																		? this.props.t(
																				"ASSIGN_CAR.APPROVED"
																		  )
																		: Number(
																				data.status
																		  ) ===
																		  2
																		? this.props.t(
																				"ASSIGN_CAR.REJECTED"
																		  )
																		: this.props.t(
																				"ASSIGN_CAR.PENDING"
																		  )}
																</p>
																{data &&
																Number(
																	data.status
																) === 2 &&
																data.failed_reason ? (
																	<p>
																		{this.props.t(
																			"ASSIGN_CAR.FAILED_REASON"
																		)}
																		:
																		{data.failed_reason ||
																			""}
																	</p>
																) : (
																	""
																)}

																{data &&
																data.triggered_date ? (
																	<p>
																		{this.props.t(
																			"ASSIGN_CAR.ON_DATE"
																		)}
																		:
																		{data.triggered_date
																			? dateformat(
																					data.triggered_date,
																					config
																						.constants
																						.DATE_FORMAT_DMY
																			  )
																			: ""}
																	</p>
																) : (
																	""
																)}
																{data &&
																data.updated_by_user &&
																data.source_id &&
																+data.source_id ===
																	3 ? (
																	<p>
																		{this.props.t(
																			"ASSIGN_CAR.BY"
																		)}
																		:{" "}
																		{
																			data.updated_by_user
																		}
																	</p>
																) : (
																	""
																)}
															</div>
														</PopupOverTips>
													</span>
													{/* {this.state
														.PopupOverTips ? (
														<PopupOverTips
															title="sd"
															heading="Rejected"
															position="right"
															style={{
																transform:
																	"translate3d(20px, -24px, 0px)",
															}}
															ref={(node) => {
																this.node = node;
															}}
															arrowstyle={{
																top: "30px",
															}}
														>
															<div>
																<p>
																	LD Status:
																	Rejectd
																</p>
																<p>
																	On Date:28
																	Jul 2020,
																	04:30 pm
																</p>
																<p>By: OTO</p>
															</div>
														</PopupOverTips>
													) : (
														""
													)} */}
												</td>
												<td className="pad-l0">
													<div id="customer_name">
														<strong className="mrg-r5">
															{`${
																mmv &&
																data.make_id &&
																mmv.make &&
																mmv.make.length
																	? mmv.make
																			.filter(
																				(
																					v
																				) =>
																					Number(
																						v.id
																					) ===
																					Number(
																						data.make_id
																					)
																			)
																			.map(
																				(
																					v
																				) =>
																					v.make
																			)[0]
																	: ""
															}
															${
																mmv &&
																data.model_id &&
																mmv.model &&
																mmv.model.length
																	? mmv.model
																			.filter(
																				(
																					v
																				) =>
																					Number(
																						v.id
																					) ===
																					Number(
																						data.model_id
																					)
																			)
																			.map(
																				(
																					v
																				) =>
																					v.m
																			)[0] ||
																	  ""
																	: ""
															}`}
														</strong>
														{data &&
														data.model_status_id ? (
															<span
																className={
																	data.model_status_id ===
																	1
																		? "badge badge-error"
																		: data.model_status_id ===
																		  2
																		? "badge badge-warm"
																		: "badge badge-primary"
																}
															>
																{data.modal_status_name
																	? data.modal_status_name
																	: ""}
															</span>
														) : (
															""
														)}
														<i
															className={
																Number(
																	data.source_id
																) === 1
																	? config
																			.constants
																			.SOURCE_ICON
																	: Number(
																			data.source_id
																	  ) === 3 &&
																	  data.sub_source_id ===
																			6
																	? "sprite ic-csvmlogo"
																	: Number(
																			data.source_id
																	  ) === 3
																	? "sprite ic-crmlogo"
																	: ""
															}
														></i>
													</div>
													<div className="small-details">
														<span>
															{data && data.color
																? data.color
																: ""}
														</span>
														<span>
															{data && data.fuel
																? data.fuel
																: ""}
														</span>
														<span>
															{data &&
															data.transmission
																? data.transmission
																: ""}
														</span>
													</div>
													<div className="f12">
														{data && data.price ? (
															<Rupees />
														) : (
															""
														)}{" "}
														{data && data.price
															? data.price
															: ""}
													</div>
												</td>
												<td>
													<div>
														{data &&
														data.dealer_name
															? data.dealer_name
															: ""}
													</div>
													<div>
														{data && data.dse_name
															? data.dse_name
															: ""}
														{data &&
														data.dse_mobile &&
														config.constants
															.IS_VIBER_SHOW ? (
															<a
																href={
																	"https://msng.link/o/?" +
																	`${
																		(data.dse_mobile
																			? data.dse_mobile
																			: ""
																		).includes(
																			"+"
																		)
																			? data.dse_mobile
																			: `${config.constants.ISD_CODE[0].isdCode}${data.dse_mobile}`
																	}` +
																	"=vi"
																}
																onClick={() =>
																	this.updateWhatsappClickCount(
																		"VIBER",
																		data.id
																	)
																}
																target="_blank"
															>
																<i
																	className="viber-img"
																	aria-hidden="true"
																	title={this.props.t(
																		"CUSTOMER_INFO.VIBER_TITLE"
																	)}
																></i>
															</a>
														) : (
															""
														)}
														{data &&
														data.dse_mobile ? (
															<a
																href={
																	"https://web.whatsapp.com/send?phone=" +
																	`${
																		(data.dse_mobile
																			? data.dse_mobile
																			: ""
																		).includes(
																			"+"
																		)
																			? data.dse_mobile
																			: `${config.constants.ISD_CODE[0].isdCode}${data.dse_mobile}`
																	}` +
																	"&text="
																}
																onClick={() =>
																	this.updateWhatsappClickCount(
																		"WHATSAPP",
																		data.id
																	)
																}
																target="_blank"
															>
																<i
																	className="whatsapp-img"
																	aria-hidden="true"
																	title={this.props.t(
																		"CUSTOMER_INFO.WHATSAPP_TITLE"
																	)}
																></i>
															</a>
														) : (
															""
														)}
														{data &&
															data.status ===
																"1" &&
															hasAccessToReassign && (
																<span className="actionBtn">
																	<button
																		type="button"
																		onClick={this.showDseEditModal.bind(
																			this,
																			data.request_id,
																			data.id,
																			data.dse_id
																		)}
																		title={this.props.t(
																			"ASSIGN_CAR.REASSIGN_DSE"
																		)}
																	>
																		<i className="ic-create-24px"></i>
																	</button>
																</span>
															)}
														{data &&
														data.status === "1" ? (
															<span
																className="actionBtn"
																title={this.props.t(
																	"CUSTOMER_INFO.COPY_TITLE"
																)}
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="20"
																	height="20"
																	viewBox="0 0 24 24"
																	onClick={this.copyTextToClipboard.bind(
																		this,
																		data
																	)}
																	title="copy"
																>
																	<path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
																</svg>
																{istextCopied ===
																data.id ? (
																	<span
																		style={{
																			color:
																				"green",
																		}}
																	>
																		{this.props.t(
																			"CUSTOMER_INFO.COPY_TEXT"
																		)}
																	</span>
																) : null}
																{/* <i className="copy-icon" title="copy" onClick={this.copyTextToClipboard.bind(this,data)}></i> */}
															</span>
														) : (
															""
														)}
													</div>
													{data &&
													data.dealer_type &&
													data.campaign_type &&
													+data.status === 1 ? (
														<div>
															<span className="badge badge-error">
																{data.dealer_type
																	? data.dealer_type
																	: ""}
															</span>
															<span className="badge badge-primary">
																{data.campaign_type
																	? data.campaign_type
																	: ""}
															</span>
														</div>
													) : (
														""
													)}
													{data &&
													data.home_td_available ? (
														<div className="text-orange f12">
															(
															{this.props.t(
																"ASSIGN_CAR.HOME_TD"
															)}{" "}
															{data &&
															data.home_td_available_status
																? data.home_td_available_status
																: ""}
															)
														</div>
													) : (
														""
													)}
												</td>
												<td>
													<div>
														{data.td_status_name ? (
															<div>
																{data.is_requested_at_home ===
																	"1" &&
																[2, 3].includes(
																	data.td_status_id
																)
																	? ` ${
																			data.td_status_name
																	  } ${this.props.t(
																			"ASSIGN_CAR.AT_HOME"
																	  )}`
																	: data.td_status_name}
															</div>
														) : (
															""
														)}

														<div className="date-text">
															{data.td_status_id ===
															4
																? data.td_completed_date
																	? dateformat(
																			data.td_completed_date,
																			config
																				.constants
																				.DATE_FORMAT
																	  )
																	: ""
																: data.td_date
																? dateformat(
																		data.td_date,
																		config
																			.constants
																			.DATE_FORMAT
																  )
																: ""}
														</div>
													</div>
												</td>

												<td>
													<div>
														<div>
															{data.visit_status_name
																? data.visit_status_name
																: ""}
														</div>
														<div className="date-text">
															{data.visit_date
																? dateformat(
																		data.visit_date,
																		config
																			.constants
																			.DATE_FORMAT
																  )
																: ""}
															{/* {data.visit_time
																? ", " +
																  (data.visit_time.split(
																		":"
																  )[0] +
																		":" +
																		data.visit_time.split(
																			":"
																		)[1])
																: ""} */}
														</div>
													</div>
												</td>
												{/* <td>
												<i className={
															Number(
																data.source_id
															) === 1
																? "sprite ic-otologo"
																: Number(
																		data.source_id
																  ) === 3
																? "sprite ic-crmlogo"
																: Number(
																		data.source_id
																  ) === 4
																? "sprite ic-csvlogo"
																: ""
														}></i>
												</td> */}
												<td className="text-right actionBtn">
													{Number(data.status) ===
													1 ? (
														<button
															type="button"
															onClick={this.showEditModal.bind(
																this,
																data.id
															)}
														>
															<i className="ic-create-24px"></i>
														</button>
													) : null}

													{/* <Confirm
														onConfirm={this.removeCar.bind(
															this,
															data.id
														)}
														body="Are you sure you want to delete?"
														confirmText="Confirm Delete"
														title="Delete!"
														confirmBSStyle="primary"
														id="asdas"
													>
														<button type="button">
															<i className="ic-delete-24px"></i>
														</button>
													</Confirm> */}
												</td>
											</tr>
										))}

									{this.state.loading ? (
										<tr>
											<td
												className="loading"
												colSpan="3"
											></td>
										</tr>
									) : null}
								</tbody>
							</table>
							{/* </InfiniteScroll> */}
						</div>
					</div>
				</div>
				{this.state.editModal === false ? (
					""
				) : (
					<ModalPopup
						id="asd"
						className="modal modal-lg"
						title=""
						modalClose={this.closeEditModal}
					>
						<TabTestDriveDealer
							subLeadInfo={this.state.subLeadInfo}
							closeModal={this.closeEditModal}
						/>
					</ModalPopup>
				)}
				{this.state.editDseModal === false ? (
					""
				) : (
					<ModalPopup
						id="edit_dse"
						className="modal"
						title=""
						modalClose={this.closeDseEditModal}
					>
						<EditDse
							data={this.state.editDse}
							modalClose={this.closeDseEditModal}
							updateDse={this.updateDse}
						/>
					</ModalPopup>
				)}
			</div>
		);
	}
}
const mapStateToProps = ({ lead, inventory, auth }) => {
	let hasAccess = false;
	if (constant.isUserHasRightsToAccessPage("lead", "lead", "/change-dse")) {
		hasAccess = true;
	}
	return {
		assignCarList: lead.assigned_cars,
		mmv: inventory.mmv,
		hasAccessToReassign: hasAccess,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getAssignCar: bindActionCreators(
				LeadActions.getLeadDetail,
				dispatch
			),
			updateAssignCar: bindActionCreators(
				LeadActions.updateAssignCar,
				dispatch
			),
			reassignDse: bindActionCreators(LeadActions.reassignDse, dispatch),
			updateHistoryFlag: bindActionCreators(
				LeadActions.updateHistoryFlag,
				dispatch
			),
			updateWhatsappClickCount: bindActionCreators(
				LeadActions.updateWhatsappClickCount,
				dispatch
			),
		},
	};
};
export default withTranslation("lead_detail")(
	connect(mapStateToProps, mapDispatchToProps)(AssignCarList)
);
