import React, { Component } from "react";
import Select from "react-select";
import InputField from "../../../elements/InputField";
import { webFormService } from "../../../../service/webFormService";
import { LeadService } from "../../../../service/leadService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import PageLoader from "../../../elements/PageLoader";
import { Redirect } from "react-router-dom";
class EditCampaign extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: this.props.match.params.id ? true : false,
			campaigninfo: this.props.campaigninfo || {},
			process_list: this.props.process_list || [],
			type: this.props.type || [],
			server: this.props.server || [],
			campaign_id: this.props.match.params.id || 0,
			errors: {},
			redirectTolist: false,
		};
	}

	componentDidMount = async () => {
		await this.getConfigData();
		if (this.state.campaign_id) await this.getCampaign();
	};

	getConfigData = async () => {
		const requestBody = {
			config_data: ["process_list", "type_list", "server_list"],
		};
		LeadService.getConfig(requestBody)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					this.setState({
						process_list: response.data.data.process_list || [],
						type: response.data.data.type_list || [],
						server: response.data.data.server_list || [],
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	getCampaign = async () => {
		this.setState({ loading: true });
		let campaign_id = this.state.campaign_id;
		webFormService
			.getCampaign({ id: campaign_id })
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let campaigninfo = response.data.data || {};
					console.log("campaigninfo", campaigninfo);
					this.setState({
						campaigninfo: campaigninfo,
						loading: false,
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	handleChange = (sname, valueObj) => {
		let { campaigninfo } = this.state;
		let value = valueObj.value || valueObj.process_id;
		if (sname === "name") value = valueObj.target.value;
		campaigninfo[sname] = value;
		this.setState({ campaigninfo: campaigninfo });
	};

	validForm = () => {
		let { campaigninfo } = this.state;
		let formIsValid = true,
			errors = {};
		if (!campaigninfo["name"]) {
			errors["name"] = this.props.t("CAMPAIGN.VALIDATION_ERROR.REQUIRED");
			formIsValid = false;
		}
		if (!campaigninfo["type"]) {
			errors["type"] = this.props.t("CAMPAIGN.VALIDATION_ERROR.REQUIRED");
			formIsValid = false;
		}
		// if (!campaigninfo["server"]) {
		// 	errors["server"] = this.props.t(
		// 		"CAMPAIGN.VALIDATION_ERROR.REQUIRED"
		// 	);
		// 	formIsValid = false;
		// }
		if (!campaigninfo["process_id"]) {
			errors["process_id"] = this.props.t(
				"CAMPAIGN.VALIDATION_ERROR.REQUIRED"
			);
			formIsValid = false;
		}

		this.setState({ errors: errors });
		return formIsValid;
	};

	Updatecampaign = (event) => {
		event.preventDefault();
		let { campaigninfo, campaign_id } = this.state;
		if (campaign_id) campaigninfo["id"] = campaign_id;
		if (this.validForm()) {
			this.setState({ loading: true });
			webFormService
				.saveCampaign(campaigninfo)
				.then((response) => {
					if (
						response.status === 200 &&
						response.data.status === 200
					) {
						toast.success(response.data.message);
						this.setState({
							campaigninfo: campaigninfo,
							loading: false,
							redirectTolist: true,
						});
					} else {
						this.setState({ loading: false });
					}
				})
				.catch((error) => {
					toast.error(error);
				});
		}
	};

	handleRedirect = () => {
		this.setState({ redirectTolist: true });
	};

	render() {
		const {
			loading,
			campaigninfo,
			process_list,
			campaign_id,
			type,
			errors,
			redirectTolist,
		} = this.state;
		if (redirectTolist) return <Redirect to="/campaigns" />;
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-6 col-sm-offset-3">
						<h1>
							{campaign_id
								? this.props.t("CAMPAIGN.UPDATE_CAMPAIGN") +
								  ` #${campaign_id}`
								: this.props.t("CAMPAIGN.ADD_CAMPAIGN")}
						</h1>
						<div className="card">
							{loading && (
								<PageLoader loaderClass="text-center" />
							)}
							<div className="card-body">
								<div className="row">
									<form onSubmit={this.Updatecampaign}>
										<div className="col-sm-6 col-md-6">
											<InputField
												inputProps={{
													id: "name",
													type: "text",
													value:
														campaigninfo.name || "",
													name: "name",
													autoComplete: "off",
													placeholder: this.props.t(
														"CAMPAIGN.NAME"
													),
													label: this.props.t(
														"CAMPAIGN.NAME"
													),
													dataerror:
														errors.name || "",
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"name"
												)}
											/>
										</div>
										<div className="col-sm-6 col-md-6 form-group">
											<label>
												{this.props.t(
													"CAMPAIGN.PROCESS"
												)}
											</label>

											<Select
												id="process_id"
												onChange={this.handleChange.bind(
													this,
													"process_id"
												)}
												options={process_list}
												name="process_id"
												placeholder={this.props.t(
													"CAMPAIGN.PROCESS"
												)}
												value={process_list.filter(
													({ process_id }) =>
														process_id ===
														campaigninfo.process_id
												)}
												getOptionLabel={({ name }) =>
													name
												}
												getOptionValue={({
													process_id,
												}) => process_id}
											/>
											<span className="text-danger">
												{errors.process_id || ""}
											</span>
										</div>
										<div className="col-sm-6 col-md-6 form-group">
											<label>
												{this.props.t("CAMPAIGN.TYPE")}
											</label>

											<Select
												id="type"
												onChange={this.handleChange.bind(
													this,
													"type"
												)}
												options={type}
												name="type"
												placeholder={this.props.t(
													"CAMPAIGN.TYPE"
												)}
												value={type.filter(
													({ value }) =>
														value ===
														campaigninfo.type
												)}
												getOptionLabel={({ label }) =>
													label
												}
												getOptionValue={({ value }) =>
													value
												}
											/>
											<span className="text-danger">
												{errors.type || ""}
											</span>
										</div>
										{/* <div className="col-sm-6 col-md-6 form-group">
											<label>
												{this.props.t(
													"CAMPAIGN.SERVER"
												)}
											</label>

											<Select
												id="server"
												onChange={this.handleChange.bind(
													this,
													"server"
												)}
												options={server}
												name="server"
												placeholder={this.props.t(
													"CAMPAIGN.SERVER"
												)}
												value={server.filter(
													({ value }) =>
														value ===
														campaigninfo.server
												)}
												getOptionLabel={({ label }) =>
													label
												}
												getOptionValue={({ value }) =>
													value
												}
											/>
											<span className="text-danger">
												{errors.server || ""}
											</span>
										</div> */}

										<div className="col-sm-12 form-group text-right">
											<button
												type="button"
												className="btn btn-default mrg-r15"
												onClick={this.handleRedirect}
											>
												{this.props.t(
													"CAMPAIGN.CANCEL"
												)}
											</button>
											<button
												type="submit"
												className="btn btn-primary "
											>
												{campaign_id
													? this.props.t(
															"CAMPAIGN.UPDATE"
													  )
													: this.props.t(
															"CAMPAIGN.CREATE"
													  )}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation("webfrom")(EditCampaign);
