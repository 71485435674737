import React, { Component } from "react";
import SwitchBtn from "./../../elements/SwitchBtn";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { LeadActions } from "../../../store/action";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import ConfirmPopUp from "../../common/ConfirmPopUp";
class FinanceDetailsCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			leadDetail: {},
			financePopOver: false,
			exchangePopOver: false,
		};
		// this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this); // set this, because you need get methods from CheckBox
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		const updatedState = {};
		if (
			nextProps.leadDetail &&
			nextProps.leadDetail !== prevState.leadDetail
		) {
			updatedState["leadDetail"] = nextProps.leadDetail;
		}
		return updatedState;
	}
	async handleChecked(type) {
		let { leadDetail } = this.state;
		const leadId = new URLSearchParams(window.location.search).get("id");

		if (
			type === "update_exchange" &&
			leadDetail["exchange_required"] !== undefined
		) {
			leadDetail["exchange_required"] = !leadDetail["exchange_required"];
			this.setState({ exchangePopover: !this.state.exchangePopover });

			const postData = {
				exchange_status: leadDetail["exchange_required"],
				lead_id: leadId,
			};
			await this.props.actions.exchangeUpdate(postData);
			await this.props.actions.updateHistoryFlag(true);
		} else if (leadDetail["finance_required"] !== undefined) {
			//update_finance
			// this.setState((financePopover = true));
			this.setState({ financePopover: !this.state.financePopover });
			leadDetail["finance_required"] = !leadDetail["finance_required"];
			const postData = {
				finance_status: leadDetail["finance_required"],
				lead_id: leadId,
			};
			await this.props.actions.financeUpdate(postData);
			await this.props.actions.updateHistoryFlag(true);
		} else {
			toast.error(this.props.t("FINANCE_EXCHANGE.LEAD_ID_REQUIRED"));
		}
		this.setState({ leadDetail: leadDetail });
	}
	confirmYes = (type) => {
		this.handleChecked(type);
		this.confirmNo(type);
	};
	confirmNo = (type) => {
		if (type === "update_exchange") {
			this.setState({ exchangePopOver: false });
		} else {
			this.setState({ financePopOver: false });
		}
	};
	displayPopUp = (type) => {
		if (type === "update_exchange") {
			this.setState({ exchangePopOver: true });
		} else {
			this.setState({ financePopOver: true });
		}
	};
	render() {
		const {
			leadDetail: { exchange_required, finance_required } = {},
			exchangePopOver,
			financePopOver,
		} = this.state;

		return (
			<div>
				<div className="card">
					<div className="card-heading pad-b15">
						<div className="row">
							<div className="col-sm-6">
								<h2 className="card-title">
									{this.props.t("FINANCE_EXCHANGE.FINANCE")}
								</h2>
							</div>
							<div className="col-sm-6 text-right">
								<SwitchBtn
									label=""
									htmlFor="financeStatus"
									id="financeStatus"
									name="financeStatus"
									checked={finance_required || false}
									onChange={this.displayPopUp.bind(
										this,
										"update_finance"
									)}
								/>
								{financePopOver && (
									<ConfirmPopUp
										onConfirm={() =>
											this.confirmYes("update_finance")
										}
										onReject={() =>
											this.confirmNo("update_finance")
										}
										message={this.props.t(
											"COMMON.UPDATE_POPUP"
										)}
									/>
								)}
							</div>
						</div>
					</div>

					{/* <div className="card-body">
						<div className="row">
							<div className="col-sm-9 col-md-10">
								<ul>
									<li>
										<span>Detail 1: </span>
										<strong>
											Lorem Ipsum is a dummy text
										</strong>
									</li>
									<li>
										<span>Detail 2: </span>
										<strong>
											Lorem Ipsum is a dummy text
										</strong>
									</li>
								</ul>
							</div>
							<div className="col-sm-3 col-md-2 text-right">
								<span className="link-text">Edit</span>
								<PopOver
									position="bottom"
									title="View Selfy pic"
									style={{ width: "300px" }}
								>
									<div>
										<img src="http://int-sfa-stage.gaadi.com/sfa_secured/uploads/sfa_app/proofs/f4b000c6ad0b45b1b9ecb171aea644e1_15917931111096001297.jpg" />
									</div>
								</PopOver>
							</div>
						</div>

						<div className="text-center pad-t10">
							<span className="link-text f12">
								View More Details
							</span>
						</div>
					</div> */}
					<div className="card-heading pad-b15 brdr-top">
						<div className="row">
							<div className="col-sm-6">
								<h2 className="card-title">Exchange Details</h2>
							</div>

							<div className="col-sm-6 text-right">
								<SwitchBtn
									label=""
									htmlFor="exchangeStatus"
									id="exchangeStatus"
									name="exchangeStatus"
									checked={exchange_required || false}
									onChange={this.displayPopUp.bind(
										this,
										"update_exchange"
									)}
								/>
								{exchangePopOver && (
									<ConfirmPopUp
										onConfirm={() =>
											this.confirmYes("update_exchange")
										}
										onReject={() =>
											this.confirmNo("update_exchange")
										}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ lead }) => {
	return {
		leadDetail: lead.lead_detail,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			exchangeUpdate: bindActionCreators(
				LeadActions.updateExchange,
				dispatch
			),
			financeUpdate: bindActionCreators(
				LeadActions.updateFinance,
				dispatch
			),
			updateHistoryFlag: bindActionCreators(
				LeadActions.updateHistoryFlag,
				dispatch
			),
		},
	};
};
export default withTranslation("lead_detail")(
	connect(mapStateToProps, mapDispatchToProps)(FinanceDetailsCard)
);
