/* eslint-disable */
import React, { Component } from "react";
import InputField from "../../../elements/InputField";
import { connect } from "react-redux";
import { AuthActions } from "../../../../store/action";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LanguageSelector from "../../../common/LanguageSelector";
import { Redirect } from "react-router-dom";
import { default as config } from "./../../../../config/config";

class ForgotPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fields: {
				mobile_or_email: "",
				otp: "",
			},
			errors: {},
			submitted: false,
		};
	}

	/**
	 * Input Handler
	 * @param e (synthatic element)
	 */
	handleInputChange = async (e) => {
		let fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		await this.setState({
			fields,
		});
	};

	/**
	 * Submit Handler
	 */

	submitHandler = async (e) => {
		e.preventDefault();
		this.setState({ submitted: true });
		const { mobile_or_email } = this.state.fields;
		const { otpSent } = this.props;

		if (!otpSent && (await this.validateForm())) {
			this.props.actions.forgotPassword({
				mobile_or_email: mobile_or_email,
			});
		}

		if (otpSent && (await this.validateForm("withOtp"))) {
			this.props.actions.getResetPasswordToken(this.state.fields);
		}
	};

	/**
	 * Form Validations
	 */

	validateForm = async function (param = "") {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

		// if (!fields["mobile"].trim()) {
		//     formIsValid = false;
		//     errors["mobile"] = this.props.t('forgot.mobile_invalid');
		// }else{

		//     if(fields['isdCode'] === config.constants.ISD_CODE[0]['isdCodeWithoutSign'] && (!fields['mobile'].startsWith(config.constants.ISD_CODE[0]['startsFrom']) || fields['mobile'].length < config.constants.ISD_CODE[0]['minLength'] || fields['mobile'].length > config.constants.ISD_CODE[0]['maxLength'])){
		//         formIsValid = false;
		//         errors["mobile"] = this.props.t('forgot.mobile_invalid');
		//     }

		// }

		if (param === "withOtp") {
			if (!fields["otp"].trim()) {
				formIsValid = false;
				errors["otp"] = this.props.t("FORGET.ENTER_OTP");
			}
		}

		await this.setState({
			errors: errors,
			submitted: formIsValid,
		});
		return formIsValid;
	};

	updateState = () => {
		this.setState({ submitted: false });
	};

	resendOtp = async (e) => {
		e.preventDefault();
		this.setState({ submitted: true });
		const { mobile_or_email } = this.state.fields;
		if (await this.validateForm()) {
			this.props.actions.resendOtp({ mobile_or_email: mobile_or_email });
		}
	};

	render() {
		const { otpSent, resetToken, otpMatchPerform } = this.props;

		const { submitted } = this.state;

		if (resetToken) {
			return <Redirect to={"/reset-password/" + resetToken} />;
		}

		return (
			<div className="container-fluid" id="homePage">
				<div className="loginwrap">
					<div className="row">
						<div className="col-sm-6 col-md-4 col-sm-offset-4 col-sm-offset-6">
							<div className="mrg-b15 text-center">
								<img
									src={config.constants.SITE_LOGO}
									className="brand-logo"
									alt="Loan Box"
								/>
							</div>
							<div className="card">
								<div className="card-body pd-30">
									<div className="signUp-container text-left">
										<h1 className="login-h1">
											{this.props.t("FORGET.FORGET")}
										</h1>
										<p className="login-p">
											{this.props.t(
												"FORGET.FORGET_TITLE"
											)}
										</p>
										<form
											method="post"
											className="text-left os-animation animated fadeInDown forgotPassword"
										>
											<div className="loginbox">
												<div
													className={
														"form-group" +
														(!submitted &&
														this.state.errors.mobile
															? " has-error"
															: "")
													}
												>
													<div className="input-group mb-3">
														<div className="input-group-prepend">
															<select
																className="form-control"
																name="isdCode"
															>
																<option
																	key="91"
																	value="91"
																>
																	{"+91"}
																</option>
															</select>
														</div>
														<input
															type="text"
															className="form-control username"
															name="mobile_or_email"
															aria-describedby="basic-addon1"
															placeholder={this.props.t(
																"FORGET.ENTER_MOBILE"
															)}
															value={
																this.state
																	.fields
																	.mobile_or_email
															}
															onChange={
																this
																	.handleInputChange
															}
														/>
													</div>
													{!submitted &&
														this.state.errors
															.mobile && (
															<div className="error-block">
																{
																	this.state
																		.errors
																		.mobile
																}
															</div>
														)}
												</div>

												{otpSent || otpMatchPerform ? (
													<div
														className={
															"form-group" +
															(!submitted &&
															this.state.errors
																.otp
																? " has-error"
																: "")
														}
													>
														<InputField
															inputProps={{
																id: "otp",
																type: "text",
																name: "otp",
																label: this.props.t(
																	"FORGET.OTP"
																),
																placeholder: this.props.t(
																	"FORGET.OTP_PLACEHOLDER"
																),
																value: this
																	.state
																	.fields.otp,
															}}
															onChange={
																this
																	.handleInputChange
															}
														/>
														{!submitted &&
															this.state.errors
																.otp && (
																<div className="error-block">
																	{
																		this
																			.state
																			.errors
																			.otp
																	}
																</div>
															)}
													</div>
												) : (
													""
												)}
												<div className="row">
													<div className="col-sm-6 text-left">
														{otpSent ||
														otpMatchPerform ? (
															<label
																className="forgotP"
																id="forgotPassword"
															>
																<a
																	href="#"
																	onClick={
																		this
																			.resendOtp
																	}
																>
																	{this.props.t(
																		"FORGET.RESEND_OTP"
																	)}
																</a>
															</label>
														) : (
															""
														)}
													</div>
													<div className="col-sm-6 text-right">
														<label
															className="forgotP"
															id="forgotPassword"
														>
															<Link
																to={`/login`}
																data-key="forgot"
															>
																{this.props.t(
																	"LOGIN.BACK_TO_LOGIN"
																)}
															</Link>
														</label>
													</div>
												</div>
												<div className="">
													{
														// (!submitted)
														// ?
														<button
															type="button"
															onClick={
																this
																	.submitHandler
															}
															className="btn btn-primary btn-block"
														>
															{this.props.t(
																"LOGIN.SUBMIT"
															)}
														</button>
														// :
														// <button type="button" className="btn btn-primary btn-block">{this.props.t('login.please_wait')}</button>
													}
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
							<footer>
								<LanguageSelector />
							</footer>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		otpSent: state.auth.otpSent,
		resetToken: state.auth.resetToken || false,
		otpMatchPerform: state.auth.otpMatchPerform,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			forgotPassword: bindActionCreators(
				AuthActions.forgotPassword,
				dispatch
			),
			resendOtp: bindActionCreators(AuthActions.resendOtp, dispatch),
			getResetPasswordToken: bindActionCreators(
				AuthActions.getResetPasswordToken,
				dispatch
			),
		},
	};
};

export default withTranslation("common")(
	connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
);
