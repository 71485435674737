import axios from "axios";
import { default as Config } from "./../config/config";
import secureStorage from "./../config/encrypt";
import { toast } from "react-toastify";

axios.interceptors.response.use(
	(response) => {
		if (response.data && response.data.status !== 200) {
			// console.error(`${response.data.status} - ${response.data.message}`, response.data)
			// if(response.data.status == 400)
			//     window.commonErrorToast(response.data.message)
			//     toast.error(`${response.data.status} - ${response.data.message}`);
		}
		return response;
	},
	(error) => {
		// handle error
		if (error.response) {
			if (error.response.status === 503) {
				// toast.error(`${error.response.status} - ${error.response.statusText}`);
			} else if (error.response.status === 401) {
				if (error.response.data.errors === "TokenEmptyInCache")
					toast.error(
						`${error.response.data.status} - ${error.response.data.message}`
					);
				//window.location.href = '/refresh-token?redirectUrl='+window.location.pathname;
				window.location.href = "/logout";
			} else if (
				error.response.data.status === 400 &&
				(error.response.data.message === "invalid_refresh_token" ||
					error.response.data.message === "access_token_expired")
			) {
				// if(error.response.data.errors == "TokenEmptyInCache")
				//toast.error(`${error.response.data.status} - ${error.response.data.message}`);
				// window.location.href = '/logout';
			} else if (error.response.data.status === 400) {
				toast.error(error.response.data.message);
			}
		}

		return error.response;
	}
);

window.commonErrorToast = function (error) {
	if (typeof error === "string") toast.error(error);
	else if (error.message) toast.error(error.message);
};

export default {
	getToken() {
		// let fetchHeader = (secureStorage.getItem('loginUserInfo'))
		//     ? secureStorage.getItem('loginUserInfo')
		//     : {};
		// return (fetchHeader && fetchHeader.data && fetchHeader.data.token)
		//     ? fetchHeader.data.token : null;
		return secureStorage.getItem("authUserInfo") &&
			secureStorage.getItem("authUserInfo")["token"]
			? secureStorage.getItem("authUserInfo")["token"]
			: "";
	},
	delete(URL, body, headers) {
		headers = headers || {};
		headers["Authorization"] = this.getToken();
		headers["Accept-Language"] = secureStorage.getItem("lang");
		URL = URL[0] === "/" ? URL.substr(1) : URL;
		return axios.delete(`${Config.API_URL.GETWAY_API}${URL}`, body, {
			headers,
		});
	},
	post(URL, body, headers) {
		headers = headers || {};
		headers["Authorization"] = this.getToken();
		headers["Accept-Language"] = secureStorage.getItem("lang");
		URL = URL[0] === "/" ? URL.substr(1) : URL;
		return axios.post(`${Config.API_URL.GETWAY_API}${URL}`, body, {
			headers,
		});
	},
	put(URL, body, headers) {
		headers = headers || {};
		headers["Authorization"] = this.getToken();
		headers["Accept-Language"] = secureStorage.getItem("lang");
		URL = URL[0] === "/" ? URL.substr(1) : URL;
		return axios.put(`${Config.API_URL.GETWAY_API}${URL}`, body, {
			headers,
		});
	},
	get(URL, query, headers) {
		headers = headers || {};
		headers["Authorization"] = this.getToken();
		headers["Accept-Language"] = secureStorage.getItem("lang");
		URL = URL[0] === "/" ? URL.substr(1) : URL;
		return axios.get(`${Config.API_URL.GETWAY_API}${URL}`, {
			params: query,
			headers,
		});
	},
	patch(URL, body, headers) {
		headers = headers || {};
		headers["Authorization"] = this.getToken();
		headers["Accept-Language"] = secureStorage.getItem("lang");
		URL = URL[0] === "/" ? URL.substr(1) : URL;
		return axios.patch(`${Config.API_URL.GETWAY_API}${URL}`, body, {
			headers,
		});
	},
	uploadFilesToS3(postdata, headers) {
		headers = headers || {};
		headers["Authorization"] = this.getToken();
		headers["Accept-Language"] = secureStorage.getItem("lang");
		return axios.post(
			`${Config.API_URL.COMMON_GATEWAY_API}core/commonservice/docs_upload`,
			postdata,
			{ headers }
		);
	},
};
