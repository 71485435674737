import React, { Component } from "react";
import { default as Config } from "../../../../config/config";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import InputField from "../../../elements/InputField";
import { ConfigAction } from "../../../../store/action";

class Search extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchItems: {},
		};
	}
	handleChange = (sname, ovalue) => {
		const { target } = ovalue;
		const { searchItems } = this.state;
		if (target) {
			const { value } = target;
			searchItems[sname] = value;
		} else {
			searchItems[sname] = ovalue.value;
		}
		if (!searchItems[sname]) delete searchItems[sname];
		this.setState({ searchItems: searchItems });
	};
	getSearchRecord = (reset = false, obj) => {
		obj.preventDefault();
		let { searchItems } = this.state;
		if (reset) {
			searchItems = {};
			this.setState({ searchItems: {} });
		}
		this.props.getBlackList(searchItems);
	};
	render() {
		const { searchItems } = this.state;

		return (
			<div className="search-wrap">
				<form onSubmit={this.submitFilterForm}>
					<ul className="search-flex">
						<li className="searchitems">
							<InputField
								inputProps={{
									id: "mobile",
									type: "text",
									value: searchItems["mobile"] || "",
									name: "mobile",
									autoComplete: "off",
									placeholder: this.props.t(
										"BLACK_LIST.MOBILE"
									),
									label: this.props.t("BLACK_LIST.MOBILE"),
								}}
								autoComplete="off"
								onChange={this.handleChange.bind(
									this,
									"mobile"
								)}
							/>
						</li>

						<li className="searchitems selectWidth form-group">
							<Select
								id="active_sattus"
								onChange={this.handleChange.bind(
									this,
									"status"
								)}
								options={Config.constants.ACTIVE_STATUS}
								name="status"
								placeholder={this.props.t(
									"BLACK_LIST.ACTIVE_STATUS"
								)}
								value={Config.constants.ACTIVE_STATUS.filter(
									({ value }) => value === searchItems.status
								)}
								getOptionLabel={({ label }) => label}
								getOptionValue={({ value }) => value}
							/>
						</li>
						<li className="searchitems">
							<InputField
								inputProps={{
									id: "reason",
									type: "text",
									value: searchItems["reason"] || "",
									name: "reason",
									autoComplete: "off",
									placeholder: this.props.t(
										"BLACK_LIST.REASON"
									),
									label: this.props.t("BLACK_LIST.REASON"),
								}}
								autoComplete="off"
								onChange={this.handleChange.bind(
									this,
									"reason"
								)}
							/>
						</li>

						<li className="searchitems form-group">
							<div>
								<button
									type="submit"
									className="btn btn-primary"
									onClick={this.getSearchRecord.bind(
										this,
										false
									)}
								>
									{this.props.t("BLACK_LIST.SEARCH")}
								</button>
							</div>
						</li>

						<li className="searchitems form-group">
							<button
								type="reset"
								className="btn btn-default btn-reset"
								onClick={this.getSearchRecord.bind(this, true)}
							>
								{this.props.t("BLACK_LIST.RESET")}
							</button>
						</li>
					</ul>
				</form>
			</div>
		);
	}
}
const mapStateToProps = ({ config }) => {
	return {};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
		},
	};
};
export default withTranslation("webfrom")(
	connect(mapStateToProps, mapDispatchToProps)(Search)
);
