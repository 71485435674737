/* eslint-disable */
import { UserConstants } from "../constants/user.constants";

export default function UserReducer(state = {}, action) {
	switch (action.type) {
		case UserConstants.GET_RULE:
			return {
				...state,
				rule: action.resp,
			};
		case UserConstants.GET_RULE_ERR:
			return {
				...state,
				rule: [],
			};
		case UserConstants.GET_PROCESS_CAMPAIGN_LIST:
			return {
				...state,
				processCampaignList: action.resp,
			};
		case UserConstants.GET_PROCESS_CAMPAIGN_LIST:
			return {
				...state,
				processCampaignList: action.resp,
			};
		case UserConstants.GET_BLACK_LIST:
			return {
				...state,
				blackList: action.resp,
			};
		case UserConstants.GET_CAMPAIGN_PACK_LIST:
			return {
				...state,
				campaignPackList: action.resp,
			};
		case UserConstants.GET_ASSIGNED_PACKS:
			return {
				...state,
				campaignAvailablePacks: action.resp,
			};
		case UserConstants.GET_CAMPAIGN_INFO:
			return {
				...state,
				...action,
			};
		case UserConstants.GET_ASSOCIATED_GM_DSE:
			return {
				...state,
				gm_associated_dse: action.resp,
			};
		default:
			return state;
	}
}
