import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { ConfigAction } from "../../../../store/action";
import Select from "react-select";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes";
import { toast } from "react-toastify";

const crmStatus = [
	{
		label: "Booking",
		value: 1,
	},
	{
		label: "Retail",
		value: 2,
	},
	{
		label: "Lost Purchase",
		value: 3,
	},
];
class RetailProcessWiseSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			processes: [],
			utmSource: [],
			utmType: [],
			utmMedium: [],
			makeList: [],
			modelList: [],
			currentStatus: [],
			cluster: [],
			cityList: [],
			leadTypeList: [],
			leadFormLocation: [],
			filterData: {
				current_crm_status: 2,
			},
			isSearchButtonDisabled: false,
		};
	}
	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		if (
			props.gm_campaign_process_list &&
			props.gm_campaign_process_list !== state.processes
		) {
			updateProps["processes"] = props.gm_campaign_process_list;
		}
		if (props.makeList && props.makeList !== state.makeList) {
			updateProps["makeList"] = props.makeList;
		}
		if (props.modelList && props.modelList !== state.modelList) {
			updateProps["modelList"] = props.modelList;
		}
		if (props.cityList && props.cityList !== state.cityList) {
			updateProps["cityList"] = props.cityList;
		}
		if (props.leadTypeList && props.leadTypeList !== state.leadTypeList) {
			updateProps["leadTypeList"] = props.leadTypeList;
		}
		if (props.cluster && props.cluster !== state.cluster) {
			updateProps["cluster"] = props.cluster;
			// for (let eachCluster of props.cluster_list) {
			// 	state.cluster_city_map.set(
			// 		eachCluster.value,
			// 		eachCluster.mapped_city
			// 	);
			// }
		}
		if (props.isSearchButtonDisabled !== state.isSearchButtonDisabled) {
			updateProps["isSearchButtonDisabled"] =
				props.isSearchButtonDisabled;
		}
		// if (props.make_map && props.make_map !== state.make_map) {
		//     updateProps["make_map"] = props.make_map;
		// }
		// if (props.model_map && props.model_map !== state.model_map) {
		//     updateProps["model_map"] = props.model_map;
		// }
		// if (props.city_map && props.city_map !== state.city_map) {
		//     updateProps["city_map"] = props.city_map;
		// }
		return updateProps;
	}
	render() {
		const {
			processes,
			utmSource,
			makeList,
			modelList,
			filterData,
			cityList,
			leadTypeList,
			cluster,
			isSearchButtonDisabled,
		} = this.state;

		let filterModel = [];
		if (filterData && filterData.make_id) {
			filterModel = modelList.filter(
				(e) => Number(e.mk_id) === Number(filterData.make_id)
			);
		}
		let availableCityList = cityList;
		if (
			filterData &&
			filterData.cluster_id &&
			filterData.cluster_id.length
		) {
			let selectedClusters = cluster
				.filter((e) => filterData.cluster_id.includes(e.value))
				.map((e) => e.mapped_city)
				.flat();
			availableCityList = availableCityList.filter((e) =>
				selectedClusters.includes(e.value)
			);
		}
		return (
			<>
				<div className="search-wrap">
					<form onSubmit={this.submitFilterForm.bind(this)}>
						<ul className="search-flex">
							<li className="searchitems selectWidth form-group">
								{/* <label>
									{this.props.t(
										"RETAIL_PROCESS_REPORT.PROCESS"
									)}
								</label> */}

								<Select
									id="process_id"
									onChange={this.handleChange.bind(
										this,
										"process_id"
									)}
									isClearable
									options={[
										{ label: "ALL", value: "ALL" },
										...processes,
									]}
									value={
										filterData.process_id
											? processes.find(
													(e) =>
														e.value ===
														filterData.process_id
											  )
											: ""
									}
									name="process_id"
									placeholder={this.props.t(
										"RETAIL_PROCESS_REPORT.PROCESS"
									)}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
								/>
							</li>
							{/* <li className="searchitems selectWidth form-group">
							<label>
								{this.props.t(
									"RETAIL_PROCESS_REPORT.UTM_SOURCE"
								)}
							</label>
							<ReactMultiselectCheckboxes
								isMulti
								placeholderButtonLabel={this.props.t(
									"RETAIL_PROCESS_REPORT.UTM_SOURCE"
								)}
								value={
									[]
								}
								onChange={this.handleChange.bind(
									this,
									"utm_source"
								)}
								options={utmSource}
								getOptionLabel={({ label }) => label}
								getOptionValue={({ value }) => value}
							/>
						</li> */}
							{/* <li className="searchitems selectWidth form-group">
							<label>
								{this.props.t(
									"RETAIL_PROCESS_REPORT.UTM_TYPE"
								)}
							</label>
							<ReactMultiselectCheckboxes
								isMulti
								placeholderButtonLabel={this.props.t(
									"RETAIL_PROCESS_REPORT.UTM_TYPE"
								)}
								value={
									[]
								}
								onChange={this.handleChange.bind(
									this,
									"utm_type"
								)}
								options={utmSource}
								getOptionLabel={({ label }) => label}
								getOptionValue={({ value }) => value}
							/>
						</li> */}
							{/* <li className="searchitems selectWidth form-group">
							<label>
								{this.props.t(
									"RETAIL_PROCESS_REPORT.UTM_MEDIUM"
								)}
							</label>
							<ReactMultiselectCheckboxes
								isMulti
								placeholderButtonLabel={this.props.t(
									"RETAIL_PROCESS_REPORT.UTM_MEDIUM"
								)}
								value={
									[]
								}
								onChange={this.handleChange.bind(
									this,
									"utm_medium"
								)}
								options={utmSource}
								getOptionLabel={({ label }) => label}
								getOptionValue={({ value }) => value}
							/>
						</li> */}
							<li className="searchitems selectWidth form-group">
								{/* <label>
									{this.props.t("RETAIL_PROCESS_REPORT.MAKE")}
								</label> */}
								<Select
									id="make_id"
									onChange={this.handleChange.bind(
										this,
										"make_id"
									)}
									isClearable
									options={makeList}
									name="make_id"
									placeholder={this.props.t(
										"RETAIL_PROCESS_REPORT.MAKE"
									)}
									value={
										filterData.make_id
											? makeList.find(
													(e) =>
														e.value ===
														filterData.make_id
											  )
											: ""
									}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
								/>
							</li>
							<li className="searchitems selectWidth form-group">
								{/* <label>
									{this.props.t(
										"RETAIL_PROCESS_REPORT.MODEL"
									)}
								</label> */}
								<ReactMultiselectCheckboxes
									isMulti
									placeholderButtonLabel={this.props.t(
										"RETAIL_PROCESS_REPORT.MODEL"
									)}
									onChange={this.handleChange.bind(
										this,
										"model_id"
									)}
									value={
										filterData.model_id instanceof Array
											? filterModel.filter((e) =>
													filterData.model_id.includes(
														e.value
													)
											  )
											: []
									}
									options={filterModel}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
								/>
							</li>
							<li className="searchitems selectWidth form-group">
								{/* <label>
									{this.props.t(
										"RETAIL_PROCESS_REPORT.CURRENT_STATUS"
									)}
								</label> */}
								<Select
									id="current_crm_status"
									onChange={this.handleChange.bind(
										this,
										"current_crm_status"
									)}
									isClearable
									options={crmStatus}
									name="current_crm_status"
									value={
										filterData.current_crm_status
											? crmStatus.find(
													(e) =>
														e.value ===
														filterData.current_crm_status
											  )
											: ""
									}
									placeholder={this.props.t(
										"RETAIL_PROCESS_REPORT.CURRENT_STATUS"
									)}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
								/>
							</li>
							<li className="searchitems selectWidth form-group">
								{/* <label>
									{this.props.t(
										"RETAIL_PROCESS_REPORT.CLUSTER"
									)}
								</label> */}
								<ReactMultiselectCheckboxes
									isMulti
									placeholderButtonLabel={this.props.t(
										"RETAIL_PROCESS_REPORT.CLUSTER"
									)}
									onChange={this.handleChange.bind(
										this,
										"cluster_id"
									)}
									options={cluster}
									value={
										filterData.cluster_id instanceof Array
											? cluster.filter((e) =>
													filterData.cluster_id.includes(
														e.value
													)
											  )
											: []
									}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
								/>
							</li>
							<li className="searchitems selectWidth form-group override-zindex-multiselect">
								{/* <label>
									{this.props.t("RETAIL_PROCESS_REPORT.CITY")}
								</label> */}
								<ReactMultiselectCheckboxes
									isMulti
									placeholderButtonLabel={this.props.t(
										"RETAIL_PROCESS_REPORT.CITY"
									)}
									onChange={this.handleChange.bind(
										this,
										"city_id"
									)}
									options={availableCityList}
									value={
										filterData.city_id instanceof Array
											? availableCityList.filter((e) =>
													filterData.city_id.includes(
														e.value
													)
											  )
											: []
									}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
								/>
							</li>
							{/* <li className="searchitems selectWidth form-group override-zindex-multiselect">
								<label>
									{this.props.t(
										"RETAIL_PROCESS_REPORT.LEAD_TYPE"
									)}
								</label>
								<ReactMultiselectCheckboxes
									isMulti
									placeholderButtonLabel={this.props.t(
										"RETAIL_PROCESS_REPORT.LEAD_TYPE"
									)}
									onChange={this.handleChange.bind(
										this,
										"lead_type_id"
									)}
									options={leadTypeList}
									value={
										filterData.lead_type_id instanceof Array
											? leadTypeList.filter((e) =>
													filterData.lead_type_id.includes(
														e.value
													)
											  )
											: []
									}
									getOptionLabel={({ label }) => label}
									getOptionValue={({ value }) => value}
								/>
							</li> */}
							{/* <li className="searchitems selectWidth form-group">
							<label>
								{this.props.t(
									"RETAIL_PROCESS_REPORT.LEAD_FORM_LOCATION"
								)}
							</label>
							<ReactMultiselectCheckboxes
								isMulti
								placeholderButtonLabel={this.props.t(
									"RETAIL_PROCESS_REPORT.LEAD_FORM_LOCATION"
								)}
								value={
									[]
								}
								onChange={this.handleChange.bind(
									this,
									"lead_form_location"
								)}
								options={utmSource}
								getOptionLabel={({ label }) => label}
								getOptionValue={({ value }) => value}
							/>
						</li> */}

							<li className="searchitems form-group searchBtns top-label-adjust">
								<button
									type="submit"
									className="btn btn-primary"
									disabled={isSearchButtonDisabled}
								>
									{this.props.t(
										"RETAIL_PROCESS_REPORT.SEARCH"
									)}
								</button>
								<button
									type="reset"
									className="btn btn-default btn-reset mrg-l10"
									onClick={this.resetFilterForm.bind(this)}
								>
									{this.props.t(
										"RETAIL_PROCESS_REPORT.RESET"
									)}
								</button>
							</li>
						</ul>
					</form>
				</div>
			</>
		);
	}
}

RetailProcessWiseSearch.prototype.getConfigData = function () {
	const requestBody = {
		config_data: [
			"gm_campaign_process_list",
			"city_state_list",
			"lead_type_list",
			"cluster_list",
		],
	};
	this.props.actions.getConfigData(requestBody);
};
RetailProcessWiseSearch.prototype.componentDidMount = function () {
	this.getConfigData();
};
RetailProcessWiseSearch.prototype.validate = function (filterData) {
	try {
		if (!filterData || !filterData.process_id)
			throw this.props.t("RETAIL_PROCESS_REPORT.ERROR.PROCESS_MANDATORY");
		return true;
	} catch (err) {
		toast.error(err);
		return false;
	}
};
RetailProcessWiseSearch.prototype.submitFilterForm = function (e) {
	e.preventDefault();
	const { filterData, processes } = this.state;
	let tempFilterData = { ...filterData };
	let selectedProcess = [];
	selectedProcess =
		filterData && filterData.process_id === "ALL"
			? processes.map((e) => e.value)
			: [filterData.process_id];
	tempFilterData.process_id = selectedProcess;
	if (this.validate.call(this, tempFilterData)) {
		/////if default value of current_crm_status is not set set it to 1
		// console.log(filterData);
		if (!("current_crm_status" in tempFilterData))
			tempFilterData["current_crm_status"] = 2;
		this.props.onSubmitFilter(tempFilterData);
	}
};
RetailProcessWiseSearch.prototype.handleChange = function (sname, ovalue) {
	const { filterData } = this.state;

	if (ovalue && ovalue.value) {
		///////////////////select case////////////////
		filterData[sname] = ovalue.value;
	} else if (sname instanceof Date) {
		////////////////date picker case////////////////
		filterData[ovalue] = new Date(sname);
	} else if (ovalue instanceof Array) {
		filterData[sname] = ovalue.map((e) => e.value);
	} else {
		delete filterData[sname];
	}

	// this.handleDependencyFilter.call(null,filterData,sname);
	this.setState({
		filterData: filterData,
	});
};
RetailProcessWiseSearch.prototype.resetFilterForm = function () {
	this.setState({
		filterData: {
			current_crm_status: 2,
		},
	});
};
const mapStateToProps = function ({ config, inventory }) {
	let state = config.city_state_list ? config.city_state_list.state : [];
	let allState = new Map();
	if (state instanceof Array) {
		allState = state.reduce(
			(obj, item) => (obj.set(item.id, item.name), obj),
			new Map()
		);
	}

	return {
		gm_campaign_process_list:
			config.gm_campaign_process_list instanceof Array
				? config.gm_campaign_process_list.map(function (e) {
						return {
							label: `${
								e.campaign_name
									? e.campaign_name.charAt(0).toUpperCase() +
									  e.campaign_name.slice(1)
									: ""
							} - ${e.gm_id}`,
							value: e.campaign_id,
						};
				  })
				: [],
		makeList:
			inventory.mmv && inventory.mmv.make
				? inventory.mmv.make.map((e) => {
						return {
							label: e.make,
							value: e.id,
						};
				  })
				: [],
		modelList:
			inventory.mmv && inventory.mmv.model
				? inventory.mmv.model.map((e) => {
						return {
							label: e.m,
							value: e.id,
							mk_id: e.mk_id,
						};
				  })
				: [],
		cityList:
			config.city_state_list && Object.keys(config.city_state_list).length
				? config.city_state_list.city.map(function (e) {
						return {
							label: `${e.name}-${allState.get(e.state_id)}`,
							value: e.id,
							state_id: e.state_id,
						};
				  })
				: [],
		leadTypeList:
			config.lead_type_list && Object.keys(config.lead_type_list).length
				? config.lead_type_list.map(function (e) {
						return {
							label: e.name,
							value: e.id,
						};
				  })
				: [],
		cluster:
			config.cluster_list instanceof Array
				? config.cluster_list.map((e) => {
						return {
							label: e.cluster_name,
							value: e.id,
							mapped_city:
								e.mapped_city.map((e) => e.city_id) || [],
						};
				  })
				: [],
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		actions: {
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
		},
	};
};
export default withTranslation("report")(
	connect(mapStateToProps, mapDispatchToProps)(RetailProcessWiseSearch)
);
