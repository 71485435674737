import MasterService from "./MasterService";

export const AuditService = {
	...MasterService,

	getAuditList(postData) {
		return this.post("/audit/lead_list", postData);
	},
	saveAudit(postData) {
		return this.post("/audit/save_audit", postData);
	},
	getAuditActivity(postData) {
		return this.post("/audit/get_audit_activity", postData);
	},
	getTieUpBrand(postData) {
		return this.post("/audit/get_tie_up_brand", postData);
	},
	saveTieUpBrand(postData) {
		return this.post("/audit/save_tieup_brand", postData);
	},
	getAllAudits(postData) {
		return this.post("/audit/get_audits", postData);
	},
	changePriority(postData) {
		return this.post("/audit/change_priority", postData);
	},
	updateStatus(postData) {
		return this.post("/audit/change_audit_status", postData);
	},
	getAuditDetails(postData) {
		return this.post("/audit/get_audit_detail", postData);
	},
};
