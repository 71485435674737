export const LeadConstants = {
	GET_PREFERENCE_LIST: "USER_GET_PREFERENCE_LIST",
	GET_PREFERENCE_LIST_ERROR: "USER_GET_PREFERENCE_LIST_ERR",
	SUB_LEAD_DETAILS_SUCCESS: "SUB_LEAD_DETAILS_SUCCESS",
	SUB_LEAD_DETAILS_FAILURE: "SUB_LEAD_DETAILS_FAILURE",
	GET_LEAD_DETAIL: "GET_LEAD_DETAIL",
	GET_LEAD_DETAIL_ERR: "GET_LEAD_DETAIL_ERR",
	IS_HISTORY_UPDATE: "IS_HISTORY_UPDATE",
	DEALER_DSE_LIST_SUCCESS: "DEALER_DSE_LIST_SUCCESS",
	DEALER_DSE_LIST_FAILURE: "DEALER_DSE_LIST_FAILURE",
	IS_PUSHED_TO_DAILER: "IS_PUSHED_TO_DAILER",
	UPDATE_WHATSAPP_COUNT: "UPDATE_WHATSAPP_COUNT",
	UPDATE_WHATSAPP_COUNT_ERR: "UPDATE_WHATSAPP_COUNT_ERR",
};
