// 'use strict'

import React from "react";
//import { HtmlTag } from 'elements';

const defaultProps = {
	error: {
		status: true,
		message: "",
	},
	popupCls: "",
};
class CheckBox extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			active: (props.locked && props.active) || false,
			value: props.value || "",
			id: props.id || "",
			name: props.name || "",
			type: props.type || "type",
			error: props.error || "",
			checked: props.checked || "",
			label: props.label || "Label",
			disabled: props.disabled || false,
		};
	}

	changeValue(event) {
		const value = event.target.value;
		this.setState({ value, error: "" });
	}

	// componentWillReceiveProps(nextProps) {
	//     this.setState({
	//         checked: nextProps.checked
	//     });
	// }

	static getDerivedStateFromProps = (nextProps, prevState) => {
		let updatedStateData = {
			checked: nextProps.checked,
		};
		if (prevState.disabled !== nextProps.disabled) {
			updatedStateData.disabled = nextProps.disabled;
		}

		return updatedStateData;
	};

	render() {
		const { value, checked, label, id, type, name, disabled } = this.state;
		//let error = this.props.error;
		return (
			<label htmlFor={id} className={"gs_control gs_radio"}>
				{label}

				<input
					id={id}
					className="form-control"
					type={type}
					value={value}
					name={name}
					data-checked={checked}
					checked={checked}
					disabled={disabled}
					onChange={this.props.onChange}
				/>
				<span className="gs_control__indicator"></span>
			</label>
		);
	}
}
CheckBox.defaultProps = defaultProps;
export default CheckBox;
