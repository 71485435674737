import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { default as config } from "../../../config/config";
import { toast } from "react-toastify";
class SearchDialer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterData: {},
			campaign_list: [],
			csvJsonData: [],
			loading: false,
		};
	}

	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		if (
			props.campaign_list &&
			props.campaign_list !== state.campaign_list
		) {
			updateProps["campaign_list"] = props.campaign_list;
		}
		if (props.csvJsonData && props.csvJsonData !== state.csvJsonData) {
			updateProps["csvJsonData"] = props.csvJsonData;
		}
		if (props.loading != state.loading) {
			updateProps["loading"] = props.loading;
		}
		return updateProps;
	}
	handleChange = async (sname, ovalue) => {
		const { filterData } = this.state;
		if (ovalue && ovalue.value) {
			///////////////////select case////////////////
			filterData[sname] = ovalue.value;
		} else if (sname instanceof Date) {
			////////////////date picker case////////////////
			filterData[ovalue] = new Date(sname);
		} else {
			delete filterData[sname];
		}
		this.setState({
			filterData: filterData,
		});
	};
	componentDidMount = () => {};
	resetFilterForm = () => {
		this.setState({
			filterData: {},
		});
	};
	validateFilterData = (filterData) => {
		let error = "";
		if (filterData && Object.keys(filterData).length === 0)
			error = this.props.t("DIALER.ERROR.ALL_FIELDS_REQUIRED");
		else if (filterData && !filterData.campaign_id)
			error = this.props.t("DIALER.ERROR.CAMPAGIN_REQUIRED");
		else if (filterData && !filterData.selected_month)
			error = this.props.t("DIALER.ERROR.MONTH_REQUIRED");

		if (error) {
			toast.error(error);
			return false;
		}
		return true;
	};
	submitFilterForm = (event) => {
		if (event) event.preventDefault();
		const { filterData } = this.state;
		if (this.validateFilterData(filterData)) {
			if (typeof this.props.onSubmitFilter === "function") {
				this.props.onSubmitFilter(filterData);
			}
		}
	};
	render() {
		const { filterData, campaign_list, loading } = this.state;
		return (
			<div className="search-wrap">
				<form onSubmit={this.submitFilterForm}>
					<ul className="search-flex">
						<li className="searchitems selectWidth form-group">
							<Select
								id="campaign_id"
								onChange={this.handleChange.bind(
									this,
									"campaign_id"
								)}
								isClearable
								options={campaign_list}
								name="campaign_id"
								placeholder={this.props.t(
									"DIALER.CAMPAIGN_NAME"
								)}
								value={
									campaign_list instanceof Array
										? campaign_list.filter(
												({ value }) =>
													value ===
													filterData.campaign_id
										  )
										: ""
								}
								// getOptionLabel={({ name }) => name}
								// getOptionValue={({ process_id }) => process_id}
							/>
						</li>

						<li className="searchitems selectWidth form-group">
							<DatePicker
								className="form-control"
								autoComplete="off"
								placeholderText={this.props.t(
									"DIALER.MONTH_PICK"
								)}
								showMonthYearPicker
								name="selected_month"
								id="selected_month"
								onChange={(date) =>
									this.handleChange(date, "selected_month")
								}
								dateFormat={
									config.constants.DATE_FORMAT_INPUT_MONTH
								}
								maxDate={new Date()}
								minDate={new Date("2020-08-31:23:59:59")}
								selected={filterData.selected_month || ""}
								onKeyDown={(e) => e.preventDefault()}
							/>
						</li>

						<li className="searchitems form-group">
							<div>
								<button
									type="submit"
									className="btn btn-primary"
									disabled={loading}
								>
									{this.props.t("DIALER.SEARCH")}
								</button>
							</div>
						</li>

						<li className="searchitems form-group">
							<button
								type="reset"
								className="btn btn-default btn-reset"
								onClick={this.resetFilterForm}
							>
								{this.props.t("DIALER.RESET")}
							</button>
						</li>
					</ul>
				</form>
			</div>
		);
	}
}
const mapStateToProps = ({ config, lead }) => {
	return {
		campaign_list:
			config.gm_campaign_list instanceof Array
				? config.gm_campaign_list.map((e) => {
						return { value: e.id, label: e.name };
				  })
				: [],
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {},
	};
};
export default withTranslation("report")(
	connect(mapStateToProps, mapDispatchToProps)(SearchDialer)
);
