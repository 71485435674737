import React, { Component } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TestDriveCarDetails from "./TestDriveCarDetails";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { LeadActions } from "./../../../store/action";
import { bindActionCreators } from "redux";
import config from "../../../config/config";
import dateformat from "dateformat";
const now = new Date();
const currhour = Number(now.getHours());
const currmin = now.getMinutes();

class DealerVisit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tdStatusList: [],
			cityList: [],
			formData: {},
			errors: {},
			subLeadDetails: {},
			is_future_date: false,
		};
	}
	static getDerivedStateFromProps = (nextProps, prevState) => {
		let updatedStateData = {};
		if (
			nextProps.config_data &&
			nextProps.config_data.test_drive_status_list &&
			nextProps.config_data.test_drive_status_list !==
				prevState.tdStatusList
		) {
			updatedStateData["tdStatusList"] =
				nextProps.config_data.dealer_visit_status_list;
		}
		if (nextProps.subLeadDetails !== prevState.subLeadDetails) {
			updatedStateData["subLeadDetails"] = nextProps.subLeadDetails;
			let formData = nextProps.subLeadDetails;

			if (formData.visit_date) {
				let vDate = formData.visit_date;
				formData.visit_time =
					(formData &&
						dateformat(vDate, config.constants.TIME_FORMAT)) ||
					"";
				formData.visit_date =
					(formData &&
						dateformat(
							formData.visit_date,
							config.constants.DATE_FORMAT_YMD
						)) ||
					"";
			}
			formData.dealer_visit_comment = "";
			updatedStateData["formData"] = formData;
			if (
				nextProps.subLeadDetails &&
				nextProps.subLeadDetails.visit_date
			) {
				let is_future_date = false;
				if (
					new Date(nextProps.subLeadDetails.visit_date).setHours(
						0,
						0,
						0,
						0
					) > new Date().setHours(0, 0, 0, 0)
				) {
					is_future_date = true;
				}
				updatedStateData["is_future_date"] = is_future_date;
			}
		}
		return updatedStateData;
	};

	handleChange = async (key, event) => {
		let { formData, errors, is_future_date } = this.state;
		if (event) {
			if (key === "visit_status_id") {
				formData.visit_status_id = event.id;
				errors.visit_status_id = "";
				// formData["visit_date"] = ""
				// formData["visit_time"] = ""
				if (event.id !== 1) {
					errors.visit_time = "";
					errors.visit_date = "";
				}
			}
		} else {
			delete formData.visit_status_id;
			delete errors.visit_status_id;
		}
		if (key === "visit_date") {
			formData.visit_time = "";
			formData.visit_date = event;
			errors.visit_date = "";
			if (
				new Date(event).setHours(0, 0, 0, 0) >
				new Date().setHours(0, 0, 0, 0)
			) {
				is_future_date = true;
			} else is_future_date = false;
		}
		if (key === "visit_time") {
			formData.visit_time = dateformat(
				event,
				config.constants.TIME_FORMAT
			);
			errors.visit_time = "";
		}
		if (key === "comment")
			formData.dealer_visit_comment = event.target.value;
		await this.setState({
			formData: formData,
			errors: errors,
			is_future_date: is_future_date,
		});
	};

	validForm = (formData) => {
		let { errors } = this.state;
		let formIsValid = true;
		if (!formData["visit_status_id"]) {
			errors["visit_status_id"] = this.props.t(
				"TEST_DRIVE.VALIDATION_ERROR.STATUS_REQUIRED"
			);
			formIsValid = false;
		}
		if (!formData["visit_date"] && formData["visit_status_id"] === 1) {
			errors["visit_date"] = this.props.t(
				"TEST_DRIVE.VALIDATION_ERROR.DATE_REQUIRED"
			);
			formIsValid = false;
		}
		if (!formData["visit_time"] && formData["visit_status_id"] === 1) {
			errors["visit_time"] = this.props.t(
				"TEST_DRIVE.VALIDATION_ERROR.TIME_REQUIRED"
			);
			formIsValid = false;
		}
		this.setState({ errors: errors });
		return formIsValid;
	};

	saveTestdriveInfo = async () => {
		let { formData, subLeadDetails } = Object.assign({}, this.state);
		formData.sub_lead_id = subLeadDetails.id;
		let visitFormInfo = await this.makeFormDataToSave();
		if (this.validForm(visitFormInfo)) {
			let visit_date2send = new Date(
				dateformat(
					dateformat(
						visitFormInfo.visit_date,
						config.constants.DATE_FORMAT_YMD
					) +
						" " +
						visitFormInfo.visit_time,
					config.constants.DATE_FORMAT
				)
			).toISOString();
			visitFormInfo.visit_date = visit_date2send;
			let result = await this.props.actions.saveDealerVisit(
				visitFormInfo
			);
			if (result && result.status) {
				await this.props.actions.updateHistoryFlag(true);
				toast.success(result.response);
				this.closeModal();
				this.getAssignCarList();
			}
		}
	};
	getAssignCarList = () => {
		const post_data = {
			lead_id: this.state.subLeadDetails.lead_id,
			_with: ["assigned_cars"],
		};
		this.props.actions.getAssignCar(post_data);
	};

	makeFormDataToSave = async () => {
		let formObj = {};
		let { formData } = this.state;
		if (formData.visit_status_id)
			formObj.visit_status_id = formData.visit_status_id;
		if (formData.visit_date)
			formObj.visit_date = dateformat(
				formData.visit_date,
				config.constants.DATE_FORMAT_YMD
			);
		if (formData.visit_time) formObj.visit_time = formData.visit_time;
		if (formData.sub_lead_id) formObj.sub_lead_id = formData.sub_lead_id;
		if (formData.dealer_visit_comment)
			formObj.comment = formData.dealer_visit_comment;
		return formObj;
	};

	closeModal = () => {
		if (typeof this.props.closeModal === "function")
			this.props.closeModal();
	};

	render() {
		const {
			tdStatusList,
			formData,
			errors,
			is_future_date,
			subLeadDetails,
		} = this.state;
		let fieldDisabled = false;
		if (formData && formData.visit_status_id !== 1) {
			fieldDisabled = true;
		}
		return (
			<div>
				<div className="row">
					<TestDriveCarDetails subListDetails={subLeadDetails} />
					<div className="col-sm-8">
						<div>
							<div className="form-group">
								<label>
									{this.props.t(
										"TEST_DRIVE.DEALER_FORM.STATUS"
									)}
									<span className="text-danger">*</span>
								</label>
								<Select
									id="status"
									onChange={this.handleChange.bind(
										this,
										"visit_status_id"
									)}
									options={tdStatusList}
									name="status"
									placeholder={this.props.t(
										"TEST_DRIVE.DEALER_FORM.STATUS"
									)}
									value={
										formData &&
										tdStatusList.filter(
											({ id }) =>
												id === formData.visit_status_id
										)
									}
									getOptionLabel={({ name }) => name}
									getOptionValue={({ id }) => id}
									isClearable
								/>
								<span className="text-danger">
									{errors.visit_status_id || ""}
								</span>
							</div>
							<div className="row">
								<div className="col-sm-6 form-group">
									<label>
										{this.props.t(
											"TEST_DRIVE.DEALER_FORM.SET_DATE"
										)}
										{!fieldDisabled ? (
											<span className="text-danger">
												*
											</span>
										) : (
											""
										)}
									</label>
									<DatePicker
										className="form-control"
										autoComplete="off"
										placeholderText={this.props.t(
											"TEST_DRIVE.DEALER_FORM.SET_DATE"
										)}
										onChange={this.handleChange.bind(
											this,
											"visit_date"
										)}
										selected={
											formData && formData.visit_date
												? new Date(formData.visit_date)
												: ""
										}
										dateFormat={
											config.constants.DATE_FORMAT_INPUT
										}
										minDate={new Date()}
										onKeyDown={(e) => e.preventDefault()}
										disabled={fieldDisabled}
									/>
									<span className="text-danger">
										{errors.visit_date || ""}
									</span>
								</div>
								<div className="col-sm-6 form-group">
									<label>
										{this.props.t(
											"TEST_DRIVE.DEALER_FORM.SET_TIME"
										)}
										{!fieldDisabled ? (
											<span className="text-danger">
												*
											</span>
										) : (
											""
										)}
									</label>
									<DatePicker
										className="form-control"
										autoComplete="off"
										onChange={this.handleChange.bind(
											this,
											"visit_time"
										)}
										value={
											(formData &&
												formData.visit_time &&
												formData.visit_time.split(
													":"
												)[0] +
													":" +
													formData.visit_time.split(
														":"
													)[1]) ||
											""
										}
										placeholderText={this.props.t(
											"TEST_DRIVE.DEALER_FORM.SET_TIME"
										)}
										showTimeSelect
										showTimeSelectOnly
										timeIntervals={15}
										timeCaption="Time"
										dateFormat={
											config.constants.TIME_FORMAT_INPUT
										}
										timeFormat={
											config.constants.TIME_FORMAT_INPUT
										}
										minTime={
											is_future_date
												? now.setHours(0, 0)
												: now.setHours(
														currhour,
														currmin
												  )
										}
										maxTime={now.setHours(23, 59)}
										disabled={fieldDisabled}
									/>
									<span className="text-danger">
										{errors.visit_time || ""}
									</span>
								</div>
								<div className="col-sm-12 form-group">
									<label>
										{this.props.t(
											"TEST_DRIVE.DEALER_FORM.COMMENTS"
										)}
									</label>
									<textarea
										name="comments"
										placeholder={this.props.t(
											"TEST_DRIVE.DEALER_FORM.COMMENTS"
										)}
										className="form-control"
										value={
											(formData &&
												formData.dealer_visit_comment) ||
											""
										}
										onChange={this.handleChange.bind(
											this,
											"comment"
										)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="actionFooter">
					<button
						type="button"
						className="btn btn-primary-outline mrg-r15"
						onClick={this.closeModal}
					>
						{this.props.t("TEST_DRIVE.DEALER_FORM.CANCEL")}
					</button>
					<button
						type="button"
						className="btn btn-primary"
						onClick={this.saveTestdriveInfo}
						disabled={subLeadDetails.status === "2" ? true : false}
					>
						{this.props.t("TEST_DRIVE.DEALER_FORM.UPDATE")}
					</button>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ config, lead }) => {
	return {
		config_data: config,
		//subListDetails: lead.subListDetails,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			saveDealerVisit: bindActionCreators(
				LeadActions.saveDealerVisit,
				dispatch
			),
			getAssignCar: bindActionCreators(
				LeadActions.getLeadDetail,
				dispatch
			),
			updateHistoryFlag: bindActionCreators(
				LeadActions.updateHistoryFlag,
				dispatch
			),
		},
	};
};
export default withTranslation("lead_detail")(
	connect(mapStateToProps, mapDispatchToProps)(DealerVisit)
);
