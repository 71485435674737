import React, { Component } from "react";
import SelectedActivity from "./SelectableActivity";
import CheckBox from "../../../../elements/CheckBox";

class FormActivity extends Component {
	constructor(props) {
		super(props);
		this.state = {
			available: [],
			runSelected: false,
			search: "",
			mulitDropDown: false,
		};
	}
	static getDerivedStateFromProps = (nextProps, prevProps) => {
		const updateData = {};
		if (nextProps.value && nextProps.value !== prevProps.available) {
			updateData["available"] = nextProps.value;
		}
		return updateData;
	};
	handleAddEvent = (value) => {
		this.props.onAdd(value, this.props.type);
	};
	callChange = (prevSelected) => {
		if (typeof this.props.onChange === "function") {
			let { selected } = this.state;
			let selectedItems = [];
			if (prevSelected) {
				selectedItems =
					prevSelected instanceof Array
						? [...prevSelected]
						: [prevSelected];
			} else {
				selectedItems = selected.map((e) => e.value);
			}

			this.props.onChange(selectedItems, this.props.type);
		}
	};
	showMultiDrop = () => {
		if (!this.state.mulitDropDown) {
			// attach/remove event handler
			document.addEventListener("click", this.handleOutsideClick, false);
		} else {
			document.removeEventListener(
				"click",
				this.handleOutsideClick,
				false
			);
		}
		this.setState((prevState) => ({
			mulitDropDown: !this.state.mulitDropDown,
			//   dealerDropDown: !prevState.dealerDropDown,
		}));
	};
	handleOutsideClick = (e) => {
		// ignore clicks on the component itself
		if (this.node.contains(e.target)) {
			return;
		}

		this.showMultiDrop();
	};
	getSearchItem = (searchItem) => {
		let { available } = this.state;

		const {
			target: { value },
		} = searchItem;
		// this.props.onSearch(searchItem,this.props.type);

		for (let i of available) {
			if (
				("" + i.label.toLocaleLowerCase()).includes(
					value.toLocaleLowerCase()
				)
			) {
				i.searched = true;
			} else {
				i.searched = false;
			}
		}

		this.setState({
			available: available,
			search: value,
			mulitDropDown: true,
		});
	};
	handleRemoveEvent = (value) => {
		this.props.onRemove(value, this.props.type);
	};
	handleAddAllItems = () => {
		this.props.onAdd(-1, this.props.type);
	};
	handleRemoveAllItems = () => {
		this.props.onRemove(-1, this.props.type);
	};
	handleNegationCheck = (type) => {
		this.props.onChange(!this.props.negate, type);
	};
	render() {
		let { available, search } = this.state;
		const _this = this;
		// let displayItem = available;
		// if (search) {
		// 	displayItem = searchArr;
		// }
		const selectedItems =
			available instanceof Array
				? available.filter((e) => e.selected)
				: [];
		return (
			<div className="card">
				<div className="card-heading">
					<div className="row">
						<div className="col-sm-4">
							<h2 className="card-title">{this.props.title}</h2>
						</div>
						<div className="col-sm-6">
							<input
								type="text"
								className="searchInput form-control"
								placeholder="Search..."
								onClick={this.showMultiDrop}
								onChange={this.getSearchItem.bind(this)}
							/>
							{this.state.mulitDropDown === true ? (
								<div
									className="mulitDropDown"
									ref={(node) => {
										this.node = node;
									}}
								>
									<ul>
										{available instanceof Array &&
											available
												.filter(function (e) {
													if (search)
														return (
															e.searched ===
																true &&
															!e.selected
														);
													else return !e.selected;
												})
												.map((e, i) => (
													<li key={i}>
														<SelectedActivity
															value={e}
															selected={
																e.selected
																	? e.selected
																	: false
															}
															addElement={
																_this.handleAddEvent
															}
															type="add"
															// onClick={this.props.toggleSelect}
														/>
													</li>
												))}
									</ul>
								</div>
							) : (
								""
							)}
						</div>
						<div className="col-sm-2">
							<input
								type="button"
								className="btn btn-link"
								value="Add-All"
								onClick={this.handleAddAllItems}
							/>
						</div>
					</div>
				</div>
				<div className="card-body">
					<div className="heading-wrap">
						<h6 className="text-bold">
							({selectedItems.length}) Elements
						</h6>
						{selectedItems.length === 0 ? (
							""
						) : (
							<button
								type="button"
								className="btn btn-link"
								value="Remove-All"
								onClick={this.handleRemoveAllItems}
							>
								Remove-All
							</button>
						)}
					</div>
					<div className="badge-group">
						{selectedItems &&
							selectedItems.map((e, i) => (
								<span
									className="badge badge-default badge-md"
									key={i}
								>
									<SelectedActivity
										value={e}
										selected={
											e.selected ? e.selected : false
										}
										removeEvent={this.handleRemoveEvent}
										type="remove"
										// onClick={this.props.toggleSelect}
									/>
								</span>
							))}
					</div>
				</div>
				<div className="container">
					{this.props.negationRequired && (
						<div className="col-xs-12 web-rule-nagation">
							<CheckBox
								checked={this.props.negate || false}
								name="rule-nagation"
								label={this.props.negationTitle}
								type="checkbox"
								onChange={this.handleNegationCheck.bind(
									this,
									this.props.type
								)}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
}
export default FormActivity;
