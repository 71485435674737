import React, { Component } from "react";
import UpdateDetail from "./subActivity/UpdateDetail";
import ChangeDetail from "./subActivity/ChangeDetail";
import Comment from "./subActivity/Comment";
class Activity extends Component {
	getMappedComponent(activity, index) {
		return (
			<tr key={index}>
				<td>
					<UpdateDetail key={index} activityData={activity} />
				</td>
				<td>
					<ChangeDetail key={index} activityData={activity} />
				</td>
				<td>
					<Comment key={index} activityData={activity} />
				</td>
			</tr>
		);
	}
	render() {
		const { historyData = [] } = this.props;

		return (
			<>
				{historyData instanceof Array &&
					historyData.map(this.getMappedComponent)}
			</>
		);
	}
}
export default Activity;
