import React, { Component } from "react";

import Brandlogo from "./../elements/Brandlogo";
import Navbar from "./Navbar";
import { withRouter } from "react-router-dom";

class Header extends Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			isOpen: false,
			redirect: 0,
			loginuser: "",
			menuOpen: false,
		};
	}

	toggle() {
		this.setState({
			isOpen: !this.state.isOpen,
		});
	}

	handleMenuClick() {
		this.setState({ menuOpen: !this.state.menuOpen });
		document.body.classList.toggle("body-hidden");
	}

	handleLinkClick() {
		this.setState({ menuOpen: false });
	}

	render() {
		const styles = {};
		const currentPath = this.props.location.pathname;

		return (
			<header className="mainHeader clearfix">
				<span
					className={
						this.state.menuOpen
							? "ic-close-24px hamburger-icon"
							: "ic-menu-24px hamburger-icon"
					}
					open={this.state.menuOpen}
					onClick={() => this.handleMenuClick()}
				></span>
				<Brandlogo />
				{!currentPath.includes("login") &&
				!currentPath.includes("-password") ? (
					<Navbar
						navClass={this.state.menuOpen ? "open" : ""}
						style={styles.container}
						fromHeader={currentPath}
					/>
				) : (
					""
				)}

				<div
					className={
						this.state.menuOpen
							? "modal-backdrop"
							: "modal-backdrop hide"
					}
					onClick={() => this.handleMenuClick()}
				></div>
			</header>
		);
	}
}

export default withRouter(Header);
