import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import DateFormat from "dateformat";
import _ from "lodash";
import { default as config } from "./../../../config/config";
import secureStorage from "./../../../config/encrypt";
import { connect } from "react-redux";
import { AuditAction } from "./../../../store/action";
import { bindActionCreators } from "redux";
import PageLoader from "../../elements/PageLoader";

class ViewAuditDetails extends Component {
	user_id;
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			logginUser: secureStorage.getItem("authUserInfo")["authUser"],
			agent_list: [],
			action_needed_list: [],
			audit_list: [],
			tab_list: [],
			activeTab: 0,
			selectedLeadId: this.props.selectedLeadId,
			isUserTypeAgent: this.props.isUserTypeAgent,
			loading: true,
			parentId: this.props.parentId,
		};
	}

	componentDidMount = async () => {
		await this.getAuditDetails();
	};

	handleTabChange = async (event) => {
		let activeTab = event.currentTarget.dataset.key;
		await this.setState({
			activeTab: activeTab,
		});
	};

	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		return updateProps;
	}

	getAuditDetails = async () => {
		let {
			selectedLeadId,
			logginUser,
			isUserTypeAgent,
			audit_list,
			activeTab,
			parentId,
		} = this.state;
		let postData = {
			lead_id: selectedLeadId,
			parent_id: parentId,
		};
		if (isUserTypeAgent) postData["agent_id"] = logginUser.user_id || 0;
		let audit_res = await this.props.actions.getAllAudits(postData);
		if (
			audit_res &&
			audit_res.status &&
			audit_res.data &&
			audit_res.data.length
		) {
			audit_list = audit_res.data;
			activeTab = audit_list[0].id;
		}
		this.setState({
			audit_list: audit_list,
			activeTab: activeTab,
			loading: false,
		});
	};

	render() {
		const { audit_list, activeTab, loading, selectedLeadId } = this.state;
		const {
			AUDIT_QUEST_ANS,
			DATE_FORMAT_YMD,
			TIME_FORMAT,
			DATE_FORMAT_WITH_TIME,
		} = config.constants;
		const auditDetails = audit_list.length
			? audit_list.filter((e) => +e.id === +activeTab)[0]
			: {};
		return audit_list && audit_list.length ? (
			<div className="container-fluid">
				<ul className="tagacdtionbtn nav-tabs mrg-b0" role="tablist">
					{audit_list.map((e, index) => (
						<li
							key={index}
							onClick={this.handleTabChange}
							className={
								"nav-item " +
								(Number(activeTab) === Number(e.id)
									? "active"
									: "")
							}
							style={{ display: "inline" }}
							data-key={e.id}
						>
							{
								<span>
									{index === 0 ? (
										this.props.t("VIEW.LAST_AUDIT")
									) : e.parent_id ? (
										<a
											href={`/Leads-Details?id=
									${selectedLeadId}
									&auditlead_id=${e.auditlead_id}&parent_id=${e.parent_id}
									&is_reaudit=true`}
										>
											{this.props.t("VIEW.RE_AUDIT")}
										</a>
									) : (
										""
									)}
								</span>
							}
							<br />
							<a
								href="#firstsubmit"
								id="first-submit"
								role="tab"
								className="text-transform"
							>
								{e.created_date
									? DateFormat(
											e.created_date,
											DATE_FORMAT_YMD
									  )
									: ""}
								<br />
								&nbsp;&nbsp;&nbsp;
								{e.created_date
									? DateFormat(e.created_date, TIME_FORMAT)
									: ""}
							</a>
						</li>
					))}
				</ul>

				<hr />
				<div className="card">
					<div className="card-body">
						<div className="row">
							<div className=" col-sm-2 form-group">
								<label>{this.props.t("VIEW.AUDITOR")}</label>
							</div>
							<div className=" col-sm-2 form-group">
								<strong>
									{auditDetails && auditDetails.auditor
										? auditDetails.auditor
										: ""}
								</strong>
							</div>
							<div className=" col-sm-2 form-group">
								<label>
									{this.props.t("VIEW.AUDIT_DATE_TIME")}
								</label>
							</div>
							<div className=" col-sm-4 form-group">
								<strong>
									{auditDetails && auditDetails.created_date
										? DateFormat(
												auditDetails.created_date,
												DATE_FORMAT_WITH_TIME
										  )
										: ""}
								</strong>
							</div>
						</div>

						<div className="row">
							<div className=" col-sm-2 form-group">
								<label>{this.props.t("VIEW.AGENT_NAME")}</label>
							</div>
							<div className=" col-sm-2 form-group">
								<strong>
									{auditDetails && auditDetails.agent_name
										? auditDetails.agent_name
										: ""}
								</strong>
							</div>
							<div className="col-sm-2 ">
								<label>
									{this.props.t("VIEW.CALLING_DATE_TIME")}
								</label>
							</div>
							<div className=" col-sm-4 form-group">
								<strong>
									{auditDetails && auditDetails.calling_date
										? DateFormat(
												auditDetails.calling_date,
												DATE_FORMAT_WITH_TIME
										  )
										: ""}
								</strong>
							</div>
						</div>

						<div className="row">
							<div className=" col-sm-2 form-group">
								<label>{this.props.t("VIEW.NEW_USED")}</label>
							</div>
							<div className=" col-sm-2 form-group">
								<strong>
									{auditDetails && auditDetails.new_used
										? AUDIT_QUEST_ANS.filter(
												(e) =>
													e.value ===
													auditDetails.new_used
										  ).map((m) => m.label)[0] || ""
										: ""}
								</strong>
							</div>
							<div className="col-sm-2 ">
								<label>
									{this.props.t(
										"VIEW.PURCHASED_CPS_BRAND_CAR"
									)}
								</label>
							</div>
							<div className=" col-sm-2 form-group">
								<strong>
									{auditDetails &&
									auditDetails.purchase_cps_brand_car
										? AUDIT_QUEST_ANS.filter(
												(e) =>
													e.value ===
													auditDetails.purchase_cps_brand_car
										  ).map((m) => m.label)[0] || ""
										: ""}
								</strong>
							</div>
							<div className="col-sm-2 ">
								<label>
									{this.props.t("VIEW.VEHICLE_BRAND")}
								</label>
							</div>
							<div className=" col-sm-2 form-group">
								<strong>
									{auditDetails && auditDetails.vehicle_brand
										? AUDIT_QUEST_ANS.filter(
												(e) =>
													e.value ===
													auditDetails.vehicle_brand
										  ).map((m) => m.label)[0] || ""
										: ""}
								</strong>
							</div>
						</div>

						<div className="row">
							<div className=" col-sm-2 form-group">
								<label>
									{this.props.t("VIEW.DEALERSHIP_NAME")}
								</label>
							</div>
							<div className=" col-sm-2 form-group">
								<strong>
									{auditDetails &&
									auditDetails.dealership_name
										? AUDIT_QUEST_ANS.filter(
												(e) =>
													e.value ===
													auditDetails.dealership_name
										  ).map((m) => m.label)[0] || ""
										: ""}
								</strong>
							</div>
							<div className="col-sm-2 ">
								<label>
									{this.props.t("VIEW.DEALERSHIP_CITY")}
								</label>
							</div>
							<div className=" col-sm-2 form-group">
								<strong>
									{auditDetails &&
									auditDetails.dealership_city
										? AUDIT_QUEST_ANS.filter(
												(e) =>
													e.value ===
													auditDetails.dealership_city
										  ).map((m) => m.label)[0] || ""
										: ""}
								</strong>
							</div>
							<div className="col-sm-2 ">
								<label>{this.props.t("VIEW.DSE_NAME")}</label>
							</div>
							<div className=" col-sm-2 form-group">
								<strong>
									{auditDetails && auditDetails.dse_name
										? AUDIT_QUEST_ANS.filter(
												(e) =>
													e.value ===
													auditDetails.dse_name
										  ).map((m) => m.label)[0] || ""
										: ""}
								</strong>
							</div>
						</div>

						<div className="row">
							<div className=" col-sm-2 form-group">
								<label>
									{this.props.t("VIEW.BOOKING_NAME")}
								</label>
							</div>
							<div className=" col-sm-2 form-group">
								<strong>
									{auditDetails && auditDetails.booking_name
										? AUDIT_QUEST_ANS.filter(
												(e) =>
													e.value ===
													auditDetails.booking_name
										  ).map((m) => m.label)[0] || ""
										: ""}
								</strong>
							</div>
							<div className="col-sm-2 ">
								<label>
									{this.props.t("VIEW.BOOKING_MOBILE")}
								</label>
							</div>
							<div className=" col-sm-2 form-group">
								<strong>
									{auditDetails && auditDetails.booking_mobile
										? AUDIT_QUEST_ANS.filter(
												(e) =>
													e.value ===
													auditDetails.booking_mobile
										  ).map((m) => m.label)[0] || ""
										: ""}
								</strong>
							</div>
							<div className="col-sm-2 ">
								<label>
									{this.props.t("VIEW.PURCHASED_DATE")}
								</label>
							</div>
							<div className=" col-sm-2 form-group">
								<strong>
									{auditDetails && auditDetails.purchase_date
										? AUDIT_QUEST_ANS.filter(
												(e) =>
													e.value ===
													auditDetails.purchase_date
										  ).map((m) => m.label)[0] || ""
										: ""}
								</strong>
							</div>
						</div>

						<div className="row">
							<div className=" col-sm-2 form-group">
								<label>
									{this.props.t("VIEW.LOST_REASON")}
								</label>
							</div>
							<div className=" col-sm-2 form-group">
								<strong>
									{auditDetails && auditDetails.lost_reason
										? AUDIT_QUEST_ANS.filter(
												(e) =>
													e.value ===
													auditDetails.lost_reason
										  ).map((m) => m.label)[0] || ""
										: ""}
								</strong>
							</div>
							<div className="col-sm-2 ">
								<label>{this.props.t("VIEW.CRM_UPDATE")}</label>
							</div>
							<div className=" col-sm-2 form-group">
								<strong>
									{auditDetails && auditDetails.crm_update
										? AUDIT_QUEST_ANS.filter(
												(e) =>
													e.value ===
													auditDetails.crm_update
										  ).map((m) => m.label)[0] || ""
										: ""}
								</strong>
							</div>
							<div className="col-sm-2 ">
								<label>
									{this.props.t("VIEW.CASH_FINANCE")}
								</label>
							</div>
							<div className=" col-sm-2 form-group">
								<strong>
									{auditDetails &&
									auditDetails.cash_or_finance
										? AUDIT_QUEST_ANS.filter(
												(e) =>
													e.value ===
													auditDetails.cash_or_finance
										  ).map((m) => m.label)[0] || ""
										: ""}
								</strong>
							</div>
						</div>

						<div className="row">
							<div className=" col-sm-2 form-group">
								<label>
									{this.props.t("VIEW.PURCHASED_PROOF")}
								</label>
							</div>
							<div className=" col-sm-2 form-group">
								<strong>
									{auditDetails && auditDetails.purchase_proof
										? AUDIT_QUEST_ANS.filter(
												(e) =>
													e.value ===
													auditDetails.purchase_proof
										  ).map((m) => m.label)[0] || ""
										: ""}
								</strong>
							</div>

							<div className=" col-sm-2 form-group">
								<label>{this.props.t("VIEW.COMPLIANCE")}</label>
							</div>
							<div className=" col-sm-2 form-group">
								<strong>
									{auditDetails && auditDetails.compliance
										? auditDetails.compliance
										: ""}{" "}
									%
								</strong>
							</div>
							<div className=" col-sm-2 form-group">
								<label>{this.props.t("VIEW.ATTACHMENT")}</label>
							</div>
							<div className=" col-sm-2 form-group">
								{auditDetails && auditDetails.attachment_url ? (
									<strong>
										<a
											href={auditDetails.attachment_url}
											target="_blank"
										>
											{this.props.t("VIEW.VIEW")}
										</a>
									</strong>
								) : null}
							</div>
						</div>
						<div className="row">
							<div className="col-sm-2 form-group">
								<label>
									{this.props.t("VIEW.ACTION_NEEDED")}
								</label>
							</div>
							<div className=" col-sm-6 form-group">
								<strong>
									{auditDetails &&
									auditDetails.action_required_name
										? auditDetails.action_required_name
										: ""}
								</strong>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-2 form-group">
								<label>{this.props.t("VIEW.COMMENTS")}</label>
							</div>
							<div className=" col-sm-6 form-group">
								<strong>
									{auditDetails && auditDetails.comments
										? auditDetails.comments
										: ""}
								</strong>
							</div>
						</div>
					</div>
				</div>
			</div>
		) : loading ? (
			<PageLoader loaderClass="text-center" loadertext="false" />
		) : (
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12 form-group">
						<h1>{this.props.t("VIEW.NO_RECORD_FOUND")}</h1>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ config, inventory }) => {
	return {
		agent_list:
			config.agent_list instanceof Array
				? config.agent_list.map((e) => {
						return {
							label: e.name,
							value: e.id,
						};
				  })
				: [],
		action_needed_list:
			config.action_needed_list instanceof Array
				? config.action_needed_list.map((e) => {
						return {
							label: e.name,
							value: e.action_id,
						};
				  })
				: [],
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getAllAudits: bindActionCreators(
				AuditAction.getAllAudits,
				dispatch
			),
		},
	};
};
export default withTranslation("audit")(
	connect(mapStateToProps, mapDispatchToProps)(ViewAuditDetails)
);
