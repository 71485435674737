/* eslint-disable */
import React, { Component } from "react";
import Select from "react-select";
import InputField from "../../../elements/InputField";
import { FordReportService } from "../../../../service/FordReportService";
import { LeadService } from "../../../../service/leadService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import PageLoader from "../../../elements/PageLoader";
import { Redirect } from "react-router-dom";
class EditDealer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: this.props.match.params.id ? true : false,
			leadinfo: this.props.leadinfo || {},
			city_states: this.props.city_states || [],
			lead_id: this.props.match.params.id || 0,
			errors: {},
			redirectTolist: false,
			regexp: /^[0-9\b]+$/,
			regexpEmail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		};
	}

	componentDidMount = async () => {
		await this.getConfigData();
		if (this.state.lead_id) await this.getDetail();
	};

	getConfigData = async () => {
		const requestBody = {
			config_data: ["city_state_list_ph"],
		};
		LeadService.getConfig(requestBody)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					this.setState({
						city_states:
							response.data.data.city_state_list_ph || {},
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	getDetail = async () => {
		this.setState({ loading: true });
		let lead_id = this.state.lead_id;
		FordReportService.getDealerDetail({ id: lead_id })
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let leadinfo = response.data.data ? response.data.data : {};
					this.setState({
						leadinfo: leadinfo || {},
						loading: false,
					});
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	handleChange = async (sname, ovalue) => {
		const { leadinfo } = this.state;
		if (sname instanceof Date) {
			leadinfo[ovalue] = new Date(sname);
		} else if (sname === "dealer_city" || sname === "dealer_state") {
			leadinfo[sname] = ovalue.name;
			if (sname === "dealer_state") leadinfo["dealer_city"] = "";
		} else {
			if (sname === "dealer_id" || sname === "zip_code") {
				if (
					ovalue.target.value === "" ||
					this.state.regexp.test(ovalue.target.value)
				)
					leadinfo[sname] = ovalue.target.value;
			} else leadinfo[sname] = ovalue.target.value;
		}
		this.setState({
			leadinfo: leadinfo,
		});
	};

	validForm = () => {
		let { leadinfo, regexpEmail } = this.state;
		let formIsValid = true,
			errors = {};

		if (!leadinfo["dealer_name"]) {
			errors["dealer_name"] = this.props.t(
				"FORM.VALIDATION_ERROR.REQUIRED"
			);
			formIsValid = false;
		}
		if (!leadinfo["dealer_id"]) {
			errors["dealer_id"] = this.props.t(
				"FORM.VALIDATION_ERROR.REQUIRED"
			);
			formIsValid = false;
		}

		// if (!leadinfo["dealer_city"]) {
		// 	errors["dealer_city"] = this.props.t(
		// 		"FORM.VALIDATION_ERROR.REQUIRED"
		// 	);
		// 	formIsValid = false;
		// }

		// if (!leadinfo["dealer_state"]) {
		// 	errors["dealer_state"] = this.props.t(
		// 		"FORM.VALIDATION_ERROR.REQUIRED"
		// 	);
		// 	formIsValid = false;
		// }

		if (!leadinfo["zip_code"]) {
			errors["zip_code"] = this.props.t("FORM.VALIDATION_ERROR.REQUIRED");
			formIsValid = false;
		}

		this.setState({ errors: errors });
		return formIsValid;
	};

	saveLead = (event) => {
		event.preventDefault();
		let { leadinfo, lead_id } = this.state;
		if (lead_id) leadinfo["id"] = lead_id;
		if (this.validForm()) {
			this.setState({ loading: true });
			leadinfo["status"] = Number(leadinfo["status"]) === 1 ? "1" : "0";
			FordReportService.saveDealer(leadinfo)
				.then((response) => {
					if (
						response.status === 200 &&
						response.data.status === 200
					) {
						toast.success(response.data.message);
						this.setState({
							leadinfo: leadinfo,
							loading: false,
							redirectTolist: true,
						});
					} else {
						this.setState({ loading: false });
					}
				})
				.catch((error) => {
					toast.error(error);
				});
		}
	};
	handleRedirect = () => {
		this.setState({ redirectTolist: true });
	};

	render() {
		const {
			loading,
			leadinfo,
			lead_id,
			city_states,
			errors,
			redirectTolist,
		} = this.state;
		const states =
			city_states && city_states.state ? city_states.state : [];
		let dealer_state_id = leadinfo.dealer_state
			? states
					.filter((v) => v.name === leadinfo.dealer_state)
					.map((v) => v.id)[0] || 0
			: 0;

		const cities = city_states && city_states.city ? city_states.city : [];

		if (redirectTolist) return <Redirect to="/dealer_list" />;
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-6 col-sm-offset-3">
						<h1>
							{lead_id
								? this.props.t("FORM.UPDATE_LEAD_DEALER") +
								  ` #${lead_id}`
								: this.props.t("FORM.ADD_LEAD_DEALER")}
						</h1>
						<div className="card">
							{loading && (
								<PageLoader loaderClass="text-center" />
							)}

							<div className="card-body">
								<div className="row">
									<form onSubmit={this.saveLead}>
										<div className="col-sm-6 col-md-6">
											<InputField
												inputProps={{
													id: "dealer_name",
													type: "text",
													value:
														leadinfo.dealer_name ||
														"",
													name: "dealer_name",
													autoComplete: "off",
													placeholder: this.props.t(
														"FORM.DEALER_NAME"
													),
													label: this.props.t(
														"FORM.DEALER_NAME"
													),
													dataerror:
														errors.dealer_name ||
														"",
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"dealer_name"
												)}
											/>
										</div>

										<div className="col-sm-6 col-md-6">
											<InputField
												inputProps={{
													id: "last_name",
													type: "text",
													value:
														leadinfo.dealer_id ||
														"",
													name: "dealer_id",
													autoComplete: "off",
													placeholder: this.props.t(
														"FORM.DEALER_CODE"
													),
													label: this.props.t(
														"FORM.DEALER_CODE"
													),
													dataerror:
														errors.dealer_id || "",
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"dealer_id"
												)}
											/>
										</div>

										{/* <div className="col-sm-6 col-md-6 form-group">
											<label>
												{this.props.t(
													"FORM.DEALER_STATE"
												)}
											</label>

											<Select
												id="dealer_state"
												onChange={this.handleChange.bind(
													this,
													"dealer_state"
												)}
												options={states}
												name="dealer_state"
												placeholder={this.props.t(
													"LIST.DEALER_STATE"
												)}
												value={states.filter(
													({ name }) =>
														name ===
														leadinfo.dealer_state
												)}
												getOptionLabel={({ name }) =>
													name
												}
												getOptionValue={({ name }) =>
													name
												}
											/>
											<span className="error show">
												{errors.dealer_state || ""}
											</span>
										</div>

										<div className="col-sm-6 col-md-6 form-group">
											<label>
												{this.props.t(
													"FORM.DEALER_CITY"
												)}
											</label>

											<Select
												id="dealer_city"
												onChange={this.handleChange.bind(
													this,
													"dealer_city"
												)}
												options={cities.filter(
													(v) =>
														v.state_id ===
														dealer_state_id
												)}
												name="dealer_city"
												placeholder={this.props.t(
													"LIST.DEALER_CITY"
												)}
												value={cities.filter(
													({ name }) =>
														name ===
														leadinfo.dealer_city
												)}
												getOptionLabel={({ name }) =>
													name
												}
												getOptionValue={({ name }) =>
													name
												}
											/>
											<span className="error show">
												{errors.dealer_city || ""}
											</span>
										</div> */}

										<div className="col-sm-6 col-md-6">
											<InputField
												inputProps={{
													id: "zip_code",
													type: "text",
													value:
														leadinfo.zip_code || "",
													name: "phone",
													autoComplete: "off",
													placeholder: this.props.t(
														"FORM.ZIPCODE"
													),
													label: this.props.t(
														"FORM.ZIPCODE"
													),
													dataerror:
														errors.zip_code || "",
												}}
												autoComplete="off"
												onChange={this.handleChange.bind(
													this,
													"zip_code"
												)}
											/>
										</div>

										<div className="col-sm-12 form-group text-right">
											<button
												type="button"
												className="btn btn-default mrg-r15"
												onClick={this.handleRedirect}
											>
												{this.props.t("FORM.CANCEL")}
											</button>
											<button
												type="submit"
												className="btn btn-primary "
											>
												{lead_id
													? this.props.t(
															"FORM.UPDATE"
													  )
													: this.props.t(
															"FORM.CREATE"
													  )}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation("ford")(EditDealer);
