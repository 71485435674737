import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { default as config } from "../../../../config/config";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { ConfigAction } from "../../../../store/action";

class SearchPerformanceReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterData: {},
			process_list: [],
			csvJsonData: [],
			campaign_list: [
				{ label: "First0", value: "0" },
				{ label: "Second1", value: "1" },
			],
			agent_list: [],
			loading: false,
		};
	}

	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		if (props.process_list && props.process_list !== state.process_list) {
			updateProps["process_list"] = props.process_list;
		}
		if (props.agent_list && props.agent_list !== state.agent_list) {
			updateProps["agent_list"] = [
				{ label: "All Agents", value: -1 },
				...props.agent_list,
			];
		}

		if (props.csvJsonData && props.csvJsonData !== state.csvJsonData) {
			updateProps["csvJsonData"] = props.csvJsonData;
		}
		if (props.loading != state.loading) {
			updateProps["loading"] = props.loading;
		}
		return updateProps;
	}
	handleChange = async (sname, ovalue) => {
		const { filterData } = this.state;
		if (ovalue && ovalue.value) {
			///////////////////select case////////////////
			filterData[sname] = ovalue.value;
		} else if (sname instanceof Date) {
			////////////////date picker case////////////////
			filterData[ovalue] = new Date(sname);
		} else {
			delete filterData[sname];
		}
		this.setState({
			filterData: filterData,
		});
	};

	componentDidMount = () => {
		const requestBody = {
			config_data: ["gm_campaign_list", "agent_list"],
		};
		this.props.actions.getConfigData(requestBody);
	};

	resetFilterForm = () => {
		this.setState({
			filterData: {},
		});
	};
	validateFilterData = (filterData) => {
		let error = "";
		if (filterData && Object.keys(filterData).length === 0)
			error = this.props.t("AGENT_REPORT.ERROR.ALL_FIELDS_REQUIRED");
		else if (filterData && !filterData.process_id)
			error = this.props.t("AGENT_REPORT.ERROR.PROCESS_REQUIRED");
		else if (filterData && !filterData.selected_month)
			error = this.props.t("AGENT_REPORT.ERROR.MONTH_REQUIRED");
		else if (filterData && !filterData.agent_id)
			error = this.props.t("AGENT_REPORT.ERROR.AGENT_REQUIRED");

		if (error) {
			toast.error(error);
			return false;
		}
		return true;
	};
	submitFilterForm = (event) => {
		if (event) event.preventDefault();
		const { filterData, agent_list } = this.state;
		if (this.validateFilterData(filterData)) {
			if (typeof this.props.onSubmitFilter === "function") {
				let tfilterData = { ...filterData }; //deep copy of object to refrain changing inner array
				///default campaign id adding//
				if (tfilterData.agent_id === -1) {
					tfilterData.agent_id = agent_list
						.map((e) => e.value)
						.filter((e) => e != -1);
				} else {
					tfilterData.agent_id = [tfilterData.agent_id];
				}
				this.props.onSubmitFilter(tfilterData);
			}
		}
	};
	render() {
		const { filterData, process_list, agent_list, loading } = this.state;
		return (
			<div className="search-wrap">
				<form onSubmit={this.submitFilterForm}>
					<ul className="search-flex">
						<li className="searchitems selectWidth form-group">
							<Select
								id="process_id"
								onChange={this.handleChange.bind(
									this,
									"process_id"
								)}
								isClearable
								options={process_list}
								value={process_list.filter(
									({ value }) =>
										value === filterData.process_id
								)}
								name="process_id"
								placeholder={this.props.t(
									"AGENT_REPORT.PROCESS_NAME"
								)}
							/>
						</li>
						<li className="searchitems selectWidth form-group">
							<Select
								id="agent_id"
								onChange={this.handleChange.bind(
									this,
									"agent_id"
								)}
								isClearable
								options={agent_list}
								value={agent_list.filter(
									({ value }) => value === filterData.agent_id
								)}
								name="agent_id"
								placeholder={this.props.t(
									"AGENT_REPORT.AGENT_NAME"
								)}
							/>
						</li>
						<li className="searchitems selectWidth form-group">
							<DatePicker
								className="form-control"
								autoComplete="off"
								placeholderText={this.props.t(
									"AGENT_REPORT.MONTH_PICK"
								)}
								showMonthYearPicker
								name="selected_month"
								id="selected_month"
								onChange={(date) =>
									this.handleChange(date, "selected_month")
								}
								dateFormat={
									config.constants.DATE_FORMAT_INPUT_MONTH
								}
								maxDate={new Date()}
								minDate={new Date("2020-10-01:23:59:59")}
								selected={filterData.selected_month || ""}
								onKeyDown={(e) => e.preventDefault()}
							/>
						</li>

						<li className="searchitems form-group">
							<div>
								<button
									type="submit"
									className="btn btn-primary"
									disabled={loading}
								>
									{this.props.t("AGENT_REPORT.SEARCH")}
								</button>
							</div>
						</li>

						<li className="searchitems form-group">
							<button
								type="reset"
								className="btn btn-default btn-reset"
								onClick={this.resetFilterForm}
							>
								{this.props.t("AGENT_REPORT.RESET")}
							</button>
						</li>
					</ul>
				</form>
			</div>
		);
	}
}
const mapStateToProps = ({ config, lead }) => {
	return {
		process_list:
			config.gm_campaign_list instanceof Array
				? config.gm_campaign_list.map((e) => {
						return { value: e.id, label: e.name };
				  })
				: [],
		agent_list:
			config.agent_list instanceof Array
				? config.agent_list.map((e) => {
						return {
							label: e.name,
							value: e.id,
						};
				  })
				: [],
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
		},
	};
};
export default withTranslation("report")(
	connect(mapStateToProps, mapDispatchToProps)(SearchPerformanceReport)
);
