/* eslint-disable */
import React, { Component } from "react";
import SearchLead from "./SearchLead";
import { LeadService } from "../../../service/leadService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { InventoryAction, LeadActions } from "./../../../store/action";
import { bindActionCreators } from "redux";
import config from "../../../config/config";
import PageLoader from "../../elements/PageLoader";
import InfiniteScroll from "react-infinite-scroller";
import * as constant from "../../../config/constant";
import dateformat from "dateformat";
import ConfirmPopUp from "../../common/ConfirmPopUp";
const assigned_cars_color_class = ["red", "blue", "orange"];
import CheckBox from "../../elements/CheckBox";
import ModalPopup from "./../../elements/Popup";
import MyactiveForm from "./MyactiveForm";
import { Redirect } from "react-router-dom";
import PriorityCalling from "./PriorityCalling";
class LeadFinder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			leadList: this.props.leadList || [],
			pagination: this.props.pagination || {},
			filterData: {},
			filterDataText: {},
			mmv: [],
			make_model: {},
			hasMoreItems: false,
			tempFilterData: {},
			tempTextData: {},
			toploading: false,
			is_pushed_to_dailer: false,
			currentInstance: this,
			dialerPopOver: false,
			displayCheckBox: false,
			myActivePopOver: false,
			myactiveModal: false,
			action_type: "assign",
			priorityPushPopOver: false,
			priorityCallingModal: false,
			isPriorityPushButtonEnable: false,
		};
		this.ChangeCheckbox = this.ChangeCheckbox.bind(this);
	}

	static getDerivedStateFromProps = (nextProps, prevState) => {
		let updatedStateData = {};
		if (nextProps && nextProps.is_pushed_to_dailer === true) {
			prevState.currentInstance.handleAddToDialer();
		}
		return updatedStateData;
	};

	componentDidMount = async () => {
		let { filterData, filterDataText } = this.state;
		const lead_ids = new URLSearchParams(window.location.search).get(
			"lead_ids"
		);
		if (lead_ids) {
			filterData = { lead_ids: lead_ids, toUpdate: false };
		} else {
			filterData = {
				date_type: "followup_date",
				start_date: new Date(),
				end_date: new Date(),
				toUpdate: true,
			};
			filterDataText["date_type"] = "Follow up Date";
		}

		filterDataText.toUpdate = true;
		await this.setState({ filterData, filterDataText });
		await this.getLeadList();
		if (
			!(
				this.props.mmv &&
				this.props.mmv instanceof Array &&
				this.props.mmv.length
			)
		) {
			await this.props.actions.getMMV();
		}
		if (
			!(
				this.props.city &&
				this.props.city instanceof Array &&
				this.props.city.length
			)
		) {
			await this.props.actions.getCityState();
		}
	};
	validator = (filterData) => {
		let error = "";
		if (filterData.date_type) {
			if (!filterData.start_date) {
				error = this.props.t(
					"SEARCH_LEAD.ERROR.PLEASE_ENTER_VALID_START_DATE"
				);
			} else if (!filterData.end_date) {
				error = this.props.t(
					"SEARCH_LEAD.ERROR.PLEASE_ENTER_VALID_END_DATE"
				);
			}
		}
		if (error) {
			toast.error(error);
			return false;
		}
		return true;
	};
	submitFilterForm = async (
		filterData,
		filterDataText,
		toUpdate = false,
		reset = false
	) => {
		if (this.validator(filterData)) {
			if (Object.keys(filterData).length === 0) {
				toUpdate = true;
			}
			if (filterData.call_status)
				filterData.call_status = String(filterData.call_status);
			this.setState(
				{
					loading: false,
					toploading: reset ? false : true,
					filterData: { ...filterData, toUpdate: toUpdate },
					filterDataText: { ...filterDataText, toUpdate: toUpdate },
					displayCheckBox:
						(this.props.authUser.role_id == 1 ||
							this.props.authUser.role_id == 5) &&
						+filterData.my_active > 0
							? true
							: false,
				},
				async () => {
					if (!reset) await this.getLeadList();
				}
			);
		}
	};
	ClearAllHandle = async (action) => {
		let { filterData, filterDataText } = this.state;
		if (action === "all") {
			filterData = {};
			filterDataText = {};
		} else {
			delete filterData[action];
			delete filterDataText[action];
			if (action === "city_id" || action === "cluster_list") {
				delete filterData["city_id"];
				delete filterDataText["city_id"];
				delete filterData["cluster_list"];
				delete filterDataText["cluster_list"];
			}
			if (action === "date_type") {
				delete filterData["start_date"];
				delete filterData["end_date"];
			}
		}
		this.submitFilterForm(filterData, filterDataText, true, true);
	};
	manipulateParams = (postData) => {
		if (postData["city_id"] instanceof Array) {
			postData["city_id"] = postData["city_id"].join(",");
		}
		if (postData["status_id"] instanceof Array) {
			postData["status_id"] = postData["status_id"].join(",");
		}
		if (postData["sub_status_id"] instanceof Array) {
			postData["sub_status_id"] = postData["sub_status_id"].join(",");
		}
		if (postData["make_model"]) {
			delete postData["make_model"];
		}
		if (postData["model_id"] instanceof Array) {
			postData["model_id"] = postData["model_id"].join(",");
		}
		if (postData["attribution_status_id"] instanceof Array) {
			postData["attribution_status_id"] = postData[
				"attribution_status_id"
			].join(",");
		}
		if (postData["billing_status_id"] instanceof Array) {
			postData["billing_status_id"] = postData["billing_status_id"].join(
				","
			);
		}
		if (postData["num_attempt"] instanceof Array) {
			let filteredArr = [];
			if (
				_.find(postData["num_attempt"], function (val) {
					return val === "gt11";
				})
			) {
				filteredArr = postData["num_attempt"].filter(function (
					value,
					index,
					arr
				) {
					return value !== "gt11";
				});
				postData["num_attempt_gt5"] = 11;
			} else filteredArr = postData["num_attempt"];
			if (filteredArr instanceof Array)
				postData["num_attempt"] = filteredArr.join(",");
		}
		if (postData["dealer_id"] instanceof Array) {
			postData["dealer_id"] = postData["dealer_id"].join(",");
		}
		if (postData["dse_name"] instanceof Array) {
			postData["dse_name"] = postData["dse_name"].join(",");
		}
		if (postData["lms_flag"]) {
			if (postData["lms_flag"] === "rf") postData["rf"] = "1";
			if (postData["lms_flag"] === "ncf") postData["ncf"] = "1";
			if (postData["lms_flag"] === "eaf") postData["eaf"] = "1";
		}
		// if (postData["date_type"] && postData["start_date"]) {
		// 	postData["start_date"] = postData["start_date"]
		// }
		// if (postData["date_type"] && postData["end_date"]) {

		// 	postData["end_date"] = postData["end_date"]
		// }
		return postData;
	};
	getLeadList = async (loadmoreStatus = 0) => {
		let { filterData, pagination, isPriorityPushButtonEnable } = this.state;
		let postData = { ...filterData };

		this.setState({
			loading: loadmoreStatus ? true : false,
			hasMoreItems: false,
			toploading: loadmoreStatus ? false : true,
		});
		postData = this.manipulateParams(postData);
		postData["page"] = loadmoreStatus
			? (pagination.page ? pagination.page : 0) + 1
			: 1;

		LeadService.getLeads(postData)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let responseData = response.data.data || [];
					let currentList = this.state.leadList || [];
					let updatedLeadlist =
						loadmoreStatus === 1
							? currentList.concat(responseData)
							: responseData;
					this.setState(
						{
							leadList: updatedLeadlist,
							pagination: response.data.pagination || {},
							loading: false,
							toploading: false,
							hasMoreItems:
								responseData.length &&
								response.data.pagination &&
								response.data.pagination.next_page
									? true
									: false,
							isPriorityPushButtonEnable: true,
						},
						() => {
							//console.log("state set successfully");
						}
					);
				}
			})
			.catch((error) => {
				// toast.error(error);
			});
	};

	loadMoreitems = async () => {
		this.getLeadList(1);
	};
	handleTempUpdate = (tempData) => {
		this.setState({ tempFilterData: tempData });
	};
	handleAddToDialer = async () => {
		this.setState({ dialerPopOver: false });
		await this.props.actions.pushedToDailer(false);
		let { filterData } = this.state;
		filterData = this.manipulateParams(filterData);
		const copyFilter = { ...filterData, push_to_dialer: true };
		const {
			status,
			data: { message = "" } = {},
		} = await LeadService.getLeads(copyFilter);

		if (status === 200) {
			toast.success(message);
		} else {
			const errorMessage = message
				? message
				: this.props.t("SEARCH_LEAD.BAD_REQUEST");
			// toast.error(errorMessage);
		}
	};
	handlePriorityPushDialer = async (priorityPushObj) => {
		if (!priorityPushObj) this.setState({ priorityCallingModal: true });
		else {
			this.setState({ priorityCallingModal: false });
			let { filterData } = this.state;
			filterData = this.manipulateParams(filterData);
			const copyFilter = {
				...filterData,
				priority_push_to_dialer: true,
				priority_list_id: priorityPushObj.list_id,
			};
			const {
				status,
				data: { message = "" } = {},
			} = await LeadService.getLeads(copyFilter);

			if (status === 200) {
				toast.success(message);
			} else {
				const errorMessage = message
					? message
					: this.props.t("SEARCH_LEAD.BAD_REQUEST");
				// toast.error(errorMessage);
			}
		}
	};
	handleDisplayPopOver = async (type) => {
		if (type === "ADD_TO_DIALER") this.setState({ dialerPopOver: true });
		else if (type === "PRIORITY_PUSH")
			this.setState({ priorityPushPopOver: true });
	};
	confirmNo = (type = "") => {
		if (type === "my_active") {
			this.setState({ myActivePopOver: false });
		} else {
			this.setState({ dialerPopOver: false, priorityPushPopOver: false });
		}
	};
	handleDownloadCsv = async () => {
		let { filterData, pagination } = this.state;
		let postData = { ...filterData };
		this.setState({ toploading: true }, async () => {
			await this.props.actions.SaveDownloadLog({
				module: "ExportLeadCsv",
				action: "1",
			});
		});
		postData = this.manipulateParams(postData);
		postData.total_record = (pagination && pagination.total) || 0;
		LeadService.saveRequestDownloadCsv(postData)
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let message2display = this.props.t(
						"SEARCH_LEAD.CSV_DATA_WILL_SEND_OVER_EMAIL"
					);
					toast.success(message2display);
				} else {
					toast.error(response.data.message);
				}
				this.setState({ toploading: false });
			})
			.catch((error) => {
				toast.error(error);
				this.setState({ toploading: false });
			});
	};

	ChangeCheckbox = (event) => {
		const target = event.target;
		const lead_id = target.value;
		let { leadList } = this.state;
		leadList
			.filter((v) => +v.lead_id === +lead_id)
			.map((v) => {
				v.checked = target.checked ? 1 : 0;
				return v;
			});
		this.setState({ leadList: leadList });
	};

	handleMyctive = async (type) => {
		this.setState({ myActivePopOver: true, action_type: type });
	};

	updateMyActive = async (
		action_type,
		agent_id = 0,
		agent_name = "",
		comment = ""
	) => {
		let { leadList } = this.state;
		let lead_ids = leadList.filter((v) => v.checked).map((v) => v.lead_id);
		this.setState({ toploading: true });
		const postData = {
			lead_id: lead_ids,
			agent_id: agent_id,
			action_type: action_type ? action_type : this.state.action_type,
			comment: comment || "",
		};
		const { status, response } = await this.props.actions.updateMyActive(
			postData
		);
		if (status) {
			toast.success(response);
			await this.getLeadList();
		}
		leadList.map((v) => {
			v.checked = false;
			return v;
		});
		this.setState({ toploading: false, myActivePopOver: false });
	};

	showModal = () => {
		this.setState({ myactiveModal: true });
	};
	closeModal = () => {
		this.setState({ myactiveModal: false });
	};

	closePriorityModal = () => {
		this.setState({ priorityCallingModal: false });
	};
	showHidePriorityPushButton = (data) => {
		let isEnable = false;
		if (data && data.length) isEnable = false;
		else isEnable = true;
		this.setState({ isPriorityPushButtonEnable: isEnable });
	};
	nameMapping = (name) => {
		console.log(name);
		switch (name) {
			case "cluster_list":
				name = config.constants.cluster_list;
				break;
		}
		return name;
	};
	render() {
		if (!constant.isUserHasRightsToAccessPage("lead", "lead", "/lead-list"))
			return <Redirect to="/not-found" />;
		const {
			loading,
			leadList,
			pagination,
			filterData,
			filterDataText,
			toploading,
			dialerPopOver,
			displayCheckBox,
			myActivePopOver,
			isPriorityPushButtonEnable,
		} = this.state;
		const mmvlist = this.props.mmv || [];
		const cityList = this.props.city || [];
		const { page, toUpdate, ...filters } = filterDataText
			? filterDataText
			: {};
		let hours = new Date().getHours();
		let showExportButton =
			hours >= constant.SETTING.OFFICE_START_TIME &&
			hours <= constant.SETTING.OFFICE_END_TIME - 1 &&
			pagination &&
			pagination.total <= constant.SETTING.CSV_EXPORT_LIMIT_IN_NORMAL_TIME
				? true
				: hours >= constant.SETTING.OFFICE_END_TIME ||
				  (hours >= 1 &&
						hours <= constant.SETTING.OFFICE_START_TIME - 1)
				? true
				: false;
		let isGMSelected = filterData && !filterData.gm_id ? false : true;
		let showPriorityPushButton =
			filterData &&
			pagination &&
			pagination.total < config.constants.PRIORITY_PUSH_DIALER_LIMIT &&
			filterData
				? true
				: false;

		return (
			<div>
				<SearchLead
					onSubmitFilter={this.submitFilterForm}
					filterData={filterData}
					filterDataText={filterDataText}
					onUpdateFilter={this.handleTempUpdate}
					authUser={this.props.authUser}
					showHidePriorityPushButton={this.showHidePriorityPushButton}
				/>

				<div className="container-fluid">
					<div className="result-wrap">
						<div className="row">
							<div className="col-sm-6">
								<p className="countNo">
									{pagination && pagination.total
										? pagination.total
										: 0}{" "}
									- {this.props.t("SEARCH_LEAD.TOTAL_CASES")}
								</p>
							</div>
							<div className="col-sm-6 text-right">
								{showPriorityPushButton &&
								constant.isUserHasRightsToAccessPage(
									"lead",
									"lead",
									"/priority_push_dailer"
								) ? (
									<button
										type="button"
										className="btn btn-success btn-sm mrg-b5"
										title="Add"
										href="#"
										onClick={() =>
											this.handlePriorityPushDialer()
										}
									>
										<i className="ic-call-24px mrg-r5"></i>
										{this.props.t(
											"SEARCH_LEAD.PRIORITY_PUSH_TO_DIALER"
										)}
									</button>
								) : (
									""
								)}

								{filterData &&
								filterData.gm_id &&
								leadList &&
								leadList.length &&
								constant.isUserHasRightsToAccessPage(
									"lead",
									"lead",
									"/add-dailer"
								) ? (
									<button
										type="button"
										className="btn btn-success btn-sm mrg-b5 mrg-l15"
										title="Add"
										href="#"
										onClick={() =>
											this.handleDisplayPopOver(
												"ADD_TO_DIALER"
											)
										}
									>
										<i className="ic-call-24px mrg-r5"></i>
										{this.props.t(
											"SEARCH_LEAD.ADD_RECORD_TO_DIALER"
										)}
									</button>
								) : (
									""
								)}
								{dialerPopOver && (
									<ConfirmPopUp
										onConfirm={() =>
											this.handleAddToDialer()
										}
										onReject={() => this.confirmNo()}
										message={this.props.t(
											"SEARCH_LEAD.ALERT_ADD_DIALER"
										)}
									/>
								)}

								{displayCheckBox &&
								filterData &&
								filterData.my_active &&
								leadList &&
								leadList.filter((v) => v.checked).length &&
								constant.isUserHasRightsToAccessPage(
									"lead",
									"lead",
									"/reassign_my_active"
								) ? (
									<button
										onClick={() => this.showModal()}
										className="btn btn-success btn-sm mrg-b5 mrg-l15"
									>
										{this.props.t("SEARCH_LEAD.REASSIGN")}
									</button>
								) : (
									""
								)}

								{displayCheckBox &&
								filterData &&
								filterData.my_active &&
								leadList &&
								leadList.filter((v) => v.checked).length &&
								constant.isUserHasRightsToAccessPage(
									"lead",
									"lead",
									"/remove_my_active"
								) ? (
									<button
										onClick={() =>
											this.handleMyctive("remove")
										}
										className="btn btn-success btn-sm mrg-b5 mrg-l15"
									>
										{this.props.t(
											"SEARCH_LEAD.REMOVE_MY_ACTIVE"
										)}
									</button>
								) : (
									""
								)}

								{myActivePopOver && (
									<ConfirmPopUp
										onConfirm={() => this.updateMyActive()}
										onReject={() =>
											this.confirmNo("my_active")
										}
										message={`${this.props.t(
											"SEARCH_LEAD.ALERT_REMOVE_MYACTIVE"
										)} "${filterDataText.my_active}" ?`}
									/>
								)}

								{filterData &&
								leadList &&
								leadList.length &&
								constant.isUserHasRightsToAccessPage(
									"lead",
									"lead",
									"/download-csv"
								) &&
								showExportButton ? (
									<button
										onClick={() => this.handleDownloadCsv()}
										className="btn btn-success btn-sm mrg-b5 mrg-l15"
									>
										{this.props.t("SEARCH_LEAD.DOWNLOAD")}
									</button>
								) : filterData &&
								  leadList &&
								  leadList.length &&
								  constant.isUserHasRightsToAccessPage(
										"lead",
										"lead",
										"/download-csv"
								  ) &&
								  !showExportButton ? (
									<>
										<button
											title={
												this.props.t(
													"SEARCH_LEAD.SEARCH_RESULTS_SHOULD_BE_BELOW"
												) +
												constant.SETTING
													.CSV_EXPORT_LIMIT_IN_NORMAL_TIME /
													1000 +
												"k"
											}
											className="btn btn-success btn-sm mrg-b5 mrg-l15 disabled"
										>
											{this.props.t(
												"SEARCH_LEAD.DOWNLOAD"
											)}
										</button>
										{/* <span className="text-danger mrg-b5 mrg-l15">
											{this.props.t(
												"SEARCH_LEAD.SEARCH_RESULTS_SHOULD_BE_BELOW"
											) +
												constant.SETTING
													.CSV_EXPORT_LIMIT_IN_NORMAL_TIME /
													1000 +
												"k"}
										</span> */}
									</>
								) : (
									""
								)}
							</div>
						</div>

						{filters && Object.keys(filters).length ? (
							<div className="selectFilters">
								{Object.keys(filters).map(
									(current_filter, filerIndex) => (
										<span
											className="badge badge-secondery mrg-r10"
											key={filerIndex}
										>
											{`${this.props.t(
												`SEARCH_LEAD.${this.nameMapping(
													current_filter
												)}`
											)} - ${
												filterDataText[current_filter]
											}`}
											<span
												className="close"
												onClick={this.ClearAllHandle.bind(
													this,
													current_filter
												)}
											>
												×
											</span>
										</span>
									)
								)}

								<span
									className="link-text"
									onClick={this.ClearAllHandle.bind(
										this,
										"all"
									)}
								>
									{this.props.t("SEARCH_LEAD.CLEAR_ALL")}
								</span>
							</div>
						) : (
							""
						)}

						<div className="card">
							{toploading && (
								<PageLoader
									loaderClass=" text-center loader content-loader"
									loadertext="true"
								/>
							)}
							<div ref={(ref) => (this.scrollParentRef = ref)}>
								<div className="table-responsive">
									<InfiniteScroll
										pageStart={0}
										loadMore={this.loadMoreitems}
										hasMore={
											this.state.hasMoreItems || false
										}
										loader={
											<PageLoader
												loaderClass="text-cente"
												loadertext="false"
												key={0}
											/>
										}
										getScrollParent={() =>
											this.scrollParentRef
										}
										useWindow={true}
									>
										<table className="table table-bordered-h table-hover">
											<thead>
												<tr>
													{displayCheckBox && (
														<th>&nbsp;</th>
													)}
													<th>
														{this.props.t(
															"SEARCH_LEAD.Lead_ID"
														)}
													</th>
													<th>
														{this.props.t(
															"SEARCH_LEAD.LEAD_DETAILS"
														)}
													</th>

													<th>
														{this.props.t(
															"SEARCH_LEAD.STATUS"
														)}
													</th>
													<th>
														{this.props.t(
															"SEARCH_LEAD.INTERESTED_IN"
														)}
													</th>
													<th>
														{this.props.t(
															"SEARCH_LEAD.TOTAL_CALLS"
														)}
													</th>
													<th>
														{this.props.t(
															"SEARCH_LEAD.LAST_UPDATED"
														)}
													</th>
													<th>
														{this.props.t(
															"SEARCH_LEAD.FOLLOW_UP"
														)}
													</th>
													<th>
														{this.props.t(
															"SEARCH_LEAD.CREATED_ON"
														)}
													</th>
												</tr>
											</thead>

											<tbody>
												{leadList && leadList.length ? (
													leadList.map(
														(
															current_lead,
															index
														) => (
															<React.Fragment
																key={
																	current_lead.lead_id
																}
															>
																<tr>
																	{displayCheckBox && (
																		<td>
																			<CheckBox
																				label={
																					false
																				}
																				name="myactive_agent_id"
																				type="checkbox"
																				id="myactive_agent_id"
																				checked={
																					current_lead.checked
																						? true
																						: false
																				}
																				value={
																					current_lead.lead_id
																				}
																				onChange={
																					this
																						.ChangeCheckbox
																				}
																			/>
																		</td>
																	)}
																	<td>
																		{constant.isUserHasRightsToAccessPage(
																			"lead",
																			"lead",
																			"/Leads-Details"
																		) ? (
																			<a
																				href={
																					"/Leads-Details?id=" +
																					current_lead.lead_id
																				}
																				target="_blank"
																				title="Lead ID"
																			>
																				{
																					current_lead.lead_id
																				}
																			</a>
																		) : (
																			current_lead.lead_id
																		)}
																	</td>
																	<td>
																		<div id="customer_name">
																			<strong className="mrg-r5">
																				{current_lead
																					.customer_details
																					.customer_name ||
																					""}
																			</strong>
																		</div>
																		<div>
																			{current_lead
																				.customer_details
																				.customer_mobile ||
																				""}
																		</div>
																		<div>
																			{current_lead
																				.customer_details
																				.customer_city_id &&
																			cityList.length
																				? cityList
																						.filter(
																							(
																								v
																							) =>
																								v.id ==
																								current_lead
																									.customer_details
																									.customer_city_id
																						)
																						.map(
																							(
																								v
																							) =>
																								v.name
																						)[0] ||
																				  ""
																				: ""}
																		</div>
																	</td>

																	<td>
																		<div>
																			{current_lead.status ||
																				""}
																		</div>
																		<div>
																			<span
																				className={
																					Number(
																						current_lead.sub_status_id
																					) ===
																					2
																						? "badge badge-error"
																						: Number(
																								current_lead.sub_status_id
																						  ) ===
																						  4
																						? "badge badge-primary"
																						: "badge badge-warm"
																				}
																			>
																				{current_lead.sub_status ||
																					""}
																			</span>
																		</div>
																		<div className="badge badge-warm">
																			{current_lead.attribution_status ||
																				""}
																		</div>
																	</td>
																	<td>
																		{mmvlist.make &&
																		mmvlist.model &&
																		current_lead.interested_in &&
																		current_lead
																			.interested_in
																			.length ? (
																			<ul className="intrestedList">
																				{current_lead.interested_in.map(
																					(
																						assignedcar,
																						index
																					) => (
																						<li
																							key={
																								"interested_in" +
																								index
																							}
																						>
																							<span
																								className={`dot ${assigned_cars_color_class[index]} mrg-r8`}
																							></span>
																							{`${
																								mmvlist &&
																								assignedcar &&
																								assignedcar.make_id &&
																								mmvlist.make
																									? mmvlist.make
																											.filter(
																												(
																													v
																												) =>
																													v.id ==
																													assignedcar.make_id
																											)
																											.map(
																												(
																													v
																												) =>
																													v.make
																											)[0] ||
																									  ""
																									: ""
																							} ${
																								mmvlist &&
																								assignedcar &&
																								assignedcar.model_id &&
																								mmvlist.model
																									? mmvlist.model
																											.filter(
																												(
																													v
																												) =>
																													v.id ==
																													assignedcar.model_id
																											)
																											.map(
																												(
																													v
																												) =>
																													v.m
																											)[0] ||
																									  ""
																									: ""
																							}`}
																						</li>
																					)
																				)}
																			</ul>
																		) : (
																			""
																		)}
																	</td>
																	<td>
																		{current_lead.num_attempt ||
																			0}
																	</td>
																	<td>
																		<div>
																			{current_lead.last_modified_date_crm ? (
																				<React.Fragment>
																					{dateformat(
																						current_lead.last_modified_date_crm,
																						config
																							.constants
																							.DATE_FORMAT
																					)}
																				</React.Fragment>
																			) : (
																				""
																			)}
																		</div>
																		<div>
																			{current_lead.agent_name ||
																				""}
																		</div>
																	</td>
																	<td>
																		<div>
																			{current_lead.followup_date ? (
																				<React.Fragment>
																					{dateformat(
																						current_lead.followup_date,
																						config
																							.constants
																							.DATE_FORMAT
																					)}
																				</React.Fragment>
																			) : (
																				""
																			)}
																		</div>
																		<div>
																			{current_lead.myactive_agent ||
																				""}
																		</div>
																	</td>
																	<td>
																		{current_lead.customer_details &&
																		current_lead
																			.customer_details
																			.created_date ? (
																			<React.Fragment>
																				{dateformat(
																					current_lead
																						.customer_details
																						.created_date,
																					config
																						.constants
																						.DATE_FORMAT
																				)}
																			</React.Fragment>
																		) : (
																			""
																		)}
																	</td>
																</tr>
															</React.Fragment>
														)
													)
												) : (
													<tr>
														<td colSpan="10">
															{!loading ? (
																<h6 className="text-danger">
																	{this.props.t(
																		"SEARCH_LEAD.NOT_FOUND"
																	)}
																</h6>
															) : (
																""
															)}
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</InfiniteScroll>
									{loading && (
										<PageLoader
											loaderClass="text-center"
											loadertext="false"
										/>
									)}
								</div>
							</div>

							{this.state.myactiveModal === false ? (
								""
							) : (
								<ModalPopup
									id="asd"
									className="modal modal-lg"
									title=""
									modalClose={this.closeModal}
								>
									<MyactiveForm
										modalClose={this.closeModal}
										updateMyActive={this.updateMyActive}
										current_agent_id={filterData.my_active}
										current_agent_name={
											filterDataText.my_active
										}
									/>
								</ModalPopup>
							)}

							{this.state.priorityCallingModal === false ? (
								""
							) : (
								<ModalPopup
									id="asd"
									className="modal modal-lg"
									title=""
									modalClose={this.closePriorityModal}
								>
									<PriorityCalling
										modalClose={this.closePriorityModal}
										addPriorityCalling={
											this.handlePriorityPushDialer
										}
										current_agent_id={filterData.my_active}
										current_agent_name={
											filterDataText.my_active
										}
									/>
								</ModalPopup>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ config, inventory, lead, auth }) => {
	return {
		mmv: inventory.mmv,
		city: inventory.city,
		is_pushed_to_dailer: lead.is_pushed_to_dailer,
		authUser: auth.authUser.authUser,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getMMV: bindActionCreators(InventoryAction.getMMV, dispatch),
			getCityState: bindActionCreators(
				InventoryAction.getCityState,
				dispatch
			),
			pushedToDailer: bindActionCreators(
				LeadActions.pushedToDailer,
				dispatch
			),
			updateMyActive: bindActionCreators(
				LeadActions.updateMyActive,
				dispatch
			),
			SaveDownloadLog: bindActionCreators(
				LeadActions.SaveDownloadLog,
				dispatch
			),
		},
	};
};
export default withTranslation("lead_list")(
	connect(mapStateToProps, mapDispatchToProps)(LeadFinder)
);
