import React, { Component } from "react";

class NotFound extends Component {
	render() {
		return (
			<div className="container-fluid text-center noaccess">
				<h2>403</h2>
				<h4>Access Denied</h4>
				<p>
					Sorry, but you don&apos;t have permission to access this
					page
				</p>
			</div>
		);
	}
}
export default NotFound;
