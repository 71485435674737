import MasterService from "./MasterService";

export const InventoryService = {
	...MasterService,

	getMMV() {
		return this.get("/common/get_mvv");
	},
	getFilter() {
		return this.get("/common/get_filter");
	},
	getCityState() {
		return this.get("/common/get_city_state");
	},
	getLocality(params) {
		let condition = "";
		if (params && params instanceof Array) {
			condition = `?city_ids=${params.join(",")}`;
		}
		return this.get(`/common/get_locality${condition}`);
	},
};
