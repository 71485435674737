import React, { Component } from "react";
import Select from "react-select";
import CheckBox from "../../elements/CheckBox";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
	LeadActions,
	ConfigAction,
	InventoryAction,
} from "./../../../store/action";
import { bindActionCreators } from "redux";
import { default as config } from "../../../config/config";
import { toast } from "react-toastify";

class RecommendedForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addNewCarState: false,
			minPrice: "",
			checkedItems: false,
			mm: [],
			selectedOptions: {},
			city: [],
			state: [],
			filterValues: {},
			dealers: [],
			locality: [],
		};
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		const updatedState = {};
		if (prevState.mm !== nextProps.mm) updatedState["mm"] = nextProps.mm;

		if (
			nextProps.configData &&
			nextProps.configData.city_state_list &&
			nextProps.configData.city_state_list.state instanceof Array &&
			nextProps.configData.city_state_list.state !== prevState.state
		) {
			updatedState["state"] = nextProps.configData.city_state_list.state;
		}
		if (
			nextProps.configData &&
			nextProps.configData.city_state_list &&
			nextProps.configData.city_state_list.city instanceof Array &&
			nextProps.configData.city_state_list.city !== prevState.city
		) {
			updatedState[
				"city"
			] = nextProps.configData.city_state_list.city.map(function (e) {
				return { label: e.name, value: e.id };
			});
		}
		if (
			nextProps.configData &&
			nextProps.configData.dealer_list instanceof Array &&
			nextProps.configData.dealer_list !== prevState.dealers
		) {
			updatedState["dealers"] = nextProps.configData.dealer_list.map(
				function (e) {
					return {
						label: e.name,
						value: e.dealer_id,
						organisation: e.organization,
					};
				}
			);
		}
		if (prevState.filterValues !== nextProps.filterValues) {
			if (
				nextProps.filterValues &&
				Object.keys(nextProps.filterValues).length &&
				nextProps.filterValues.minPrice
			) {
				nextProps.filterValues.minPrice = nextProps.filterValues.minPrice.map(
					function (e) {
						return { ...e, isdisabled: false };
					}
				);
			}
			if (
				nextProps.filterValues &&
				Object.keys(nextProps.filterValues).length &&
				nextProps.filterValues.maxPrice
			) {
				nextProps.filterValues.maxPrice = nextProps.filterValues.maxPrice.map(
					function (e) {
						return { ...e, isdisabled: false };
					}
				);
			}
			if (
				nextProps.filterValues &&
				Object.keys(nextProps.filterValues).length &&
				nextProps.filterValues.minYear
			) {
				nextProps.filterValues.minYear = nextProps.filterValues.minYear.map(
					function (e) {
						return { ...e, isdisabled: false };
					}
				);
			}
			if (
				nextProps.filterValues &&
				Object.keys(nextProps.filterValues).length &&
				nextProps.filterValues.maxYear
			) {
				nextProps.filterValues.maxYear = nextProps.filterValues.maxYear.map(
					function (e) {
						return { ...e, isdisabled: false };
					}
				);
			}
			updatedState["filterValues"] = nextProps.filterValues;
		}
		if (prevState.locality !== nextProps.locality)
			updatedState["locality"] = nextProps.locality;
		return updatedState;
	}
	componentDidMount = async () => {
		if (
			!(
				this.props.mm &&
				this.props.mm instanceof Array &&
				this.props.mm.length
			)
		) {
			await this.props.actions.getMMV();
		}

		// const requestBody = {
		// 	config_data: ["dealer_list"],
		// };
		// await this.props.actions.getConfigData(requestBody);

		if (
			!(
				this.props.filterData &&
				Object.keys(this.props.filterData).length > 0
			)
		) {
			await this.props.actions.getFilter();
		}
	};
	componentWillUnmount = () => {};
	diabledPriceRange = (stateObj, price, type) => {
		if (type === 0) {
			//////////////////disable prices below this range///////////////
			if (stateObj.maxPrice && stateObj.maxPrice instanceof Array)
				stateObj.maxPrice = stateObj.maxPrice.map(function (e) {
					return {
						...e,
						isdisabled: e.value <= price ? true : false,
					};
				});
		} else if (type === 1) {
			//////////////////disable prices above this range///////////////
			if (stateObj.minPrice && stateObj.minPrice instanceof Array)
				stateObj.minPrice = stateObj.minPrice.map(function (e) {
					return {
						...e,
						isdisabled: e.value >= price ? true : false,
					};
				});
		}
		return stateObj;
	};
	disableYearRange = (stateObj, year, type) => {
		if (type === 0) {
			//////////////////disable prices below this range///////////////
			if (stateObj.maxYear && stateObj.maxYear instanceof Array)
				stateObj.maxYear = stateObj.maxYear.map(function (e) {
					return { ...e, isdisabled: e.value < year ? true : false };
				});
		} else if (type === 1) {
			//////////////////disable prices above this range///////////////
			if (stateObj.minYear && stateObj.minYear instanceof Array)
				stateObj.minYear = stateObj.minYear.map(function (e) {
					return { ...e, isdisabled: e.value > year ? true : false };
				});
		}

		return stateObj;
	};
	getLocalityListForCityId = (cityId) => {
		this.props.actions.getLocality([cityId]);
	};
	handleChange = (sname, ovalue, action = {}) => {
		let { selectedOptions, filterValues } = this.state;
		if (ovalue) {
			if (action && action.action === "select-option")
				selectedOptions[sname] = ovalue;
			else selectedOptions[sname] = ovalue.value;
			/////////////////////////////conditions///////////////////////////////////
			if (sname === "max_price") {
				filterValues = this.diabledPriceRange(
					filterValues,
					ovalue.value,
					1
				);
			} else if (sname === "min_price") {
				filterValues = this.diabledPriceRange(
					filterValues,
					ovalue.value,
					0
				);
			} else if (sname === "min_year") {
				filterValues = this.disableYearRange(
					filterValues,
					ovalue.value,
					0
				);
			} else if (sname === "max_year") {
				filterValues = this.disableYearRange(
					filterValues,
					ovalue.value,
					1
				);
			} else if (sname === "residing_city") {
				this.getLocalityListForCityId(ovalue.value);
			}
		} else {
			delete selectedOptions[sname];
		}
		//////////////////////////////////////////end////////////////////////////
		this.setState({
			selectedOptions: selectedOptions,
			filterValues: filterValues,
		});
	};
	addNewCar = () => {
		this.setState({ addNewCarState: true });
	};
	hideNewCar = () => {
		this.setState({ addNewCarState: false });
	};
	ChangeCheckbox = ({ target }) => {
		const { name } = target;
		const { selectedOptions } = this.state;
		if (selectedOptions[name]) selectedOptions[name] = false;
		else selectedOptions[name] = true;
		this.setState({ selectedOptions: selectedOptions });
	};
	validateFrom = (selectedOptions) => {
		const {
			min_price,
			max_price,
			min_year,
			max_year,
			fuel_type,
			transmission,
			body_type,
			seats,
			owner,
			color,
			residing_city,
			customer_locality,
		} = selectedOptions;
		let error = "";
		if (!min_price)
			error = this.props.t(
				"LEAD_DETAIL_RECOMMENDED_USED.ERROR.MIN_PRICE"
			);
		else if (!max_price)
			error = this.props.t(
				"LEAD_DETAIL_RECOMMENDED_USED.ERROR.MAX_PRICE"
			);
		else if (!min_year)
			error = this.props.t("LEAD_DETAIL_RECOMMENDED_USED.ERROR.MIN_YEAR");
		else if (!max_year)
			error = this.props.t("LEAD_DETAIL_RECOMMENDED_USED.ERROR.MAX_YEAR");
		else if (!fuel_type)
			error = this.props.t(
				"LEAD_DETAIL_RECOMMENDED_USED.ERROR.FUEL_TYPE"
			);
		else if (!transmission)
			error = this.props.t(
				"LEAD_DETAIL_RECOMMENDED_USED.ERROR.TRANSMISSION"
			);
		else if (!body_type)
			error = this.props.t(
				"LEAD_DETAIL_RECOMMENDED_USED.ERROR.BODY_TYPE"
			);
		else if (!seats)
			error = this.props.t("LEAD_DETAIL_RECOMMENDED_USED.ERROR.SEAT");
		else if (!owner)
			error = this.props.t("LEAD_DETAIL_RECOMMENDED_USED.ERROR.OWNER");
		else if (!color)
			error = this.props.t("LEAD_DETAIL_RECOMMENDED_USED.ERROR.COLOR");
		else if (!residing_city)
			error = this.props.t(
				"LEAD_DETAIL_RECOMMENDED_USED.ERROR.RESIDING_CITY"
			);
		else if (!customer_locality)
			error = this.props.t(
				"LEAD_DETAIL_RECOMMENDED_USED.ERROR.CUSTOMER_LOCALITY"
			);
		if (!error) return true;
		toast.error(error);
		return false;
	};
	sendToUb = async () => {
		const { selectedOptions } = this.state;
		const urlInfo = new URLSearchParams(window.location.search);
		const lead_id = urlInfo.get("id");
		if (this.validateFrom(selectedOptions)) {
			const {
				min_price,
				max_price,
				min_year,
				max_year,
				fuel_type,
				transmission,
				body_type,
				seats,
				owner,
				color,
				residing_city,
				customer_locality,
				// city,
				dealer,
				with_picture,
				with_sunroof,
				dealer_car,
				individual_car,
				// rto_city,
				make_model,
			} = selectedOptions;
			////////////////prepare data//////////////////
			const { value = null, mk_id = null } = make_model ? make_model : {};
			const postData = {
				min_price: min_price.value,
				max_price: max_price.value,
				min_year: min_year.value,
				max_year: max_year.value,
				fuel_type_id: fuel_type.value,
				transmission_id: transmission.value,
				body_type_id: body_type.value,
				number_of_seat: seats.value,
				owner_type_id: owner.value,
				color_ids: [color.value],
				city_id: residing_city.value,
				customer_locality_id: customer_locality.value,
				// city_id: city.value,
				// rto_city_id: rto_city.value,,
				with_picture,
				with_sunroof,
				dealer_car,
				individual_car,
				make_ids: [mk_id],
				model_ids: [value],
				lead_id: lead_id,
			};
			if (dealer && dealer.value) {
				postData["dealer_id"] = dealer.value;
			}
			const { status, response } = await this.props.actions.sendToUb(
				postData
			);
			if (status) {
				toast.success(response);
				this.props.modalClose(true);
			} else {
				if (response) toast.error(response);
				else toast.error(this.props.t("COMMON_ERROR.BAD_GATEWAY"));
			}
		}
	};
	render() {
		const {
			mm,
			selectedOptions,
			city,
			filterValues,
			locality,
		} = this.state;
		////////////////////////set filters here///////////////////////

		const {
			minPrice = [],
			maxPrice = [],
			minYear = [],
			maxYear = [],
			fuelType = [],
			ucTransmission = [],
			bodyType = [],
		} =
			filterValues && Object.keys(filterValues).length
				? filterValues
				: {};

		////////////////////////end setting filters/////////////////

		return (
			<div>
				<form>
					<div className="row">
						<div className="col-sm-6">
							<label>
								{this.props.t(
									"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.BUDGET_RANGE"
								)}
							</label>
							<div className="row">
								<div className="col-sm-6 form-group">
									<Select
										id="min_price"
										value={selectedOptions.min_price}
										onChange={this.handleChange.bind(
											this,
											"min_price"
										)}
										options={minPrice}
										name="minPrice"
										placeholder={this.props.t(
											"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.MIN_PRICE"
										)}
										isOptionDisabled={(e) => e.isdisabled}
										isClearable
									/>
								</div>
								<div className="col-sm-6 form-group">
									<Select
										id="max_price"
										value={selectedOptions.max_price}
										onChange={this.handleChange.bind(
											this,
											"max_price"
										)}
										options={maxPrice}
										name="max_price"
										placeholder={this.props.t(
											"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.MAX_PRICE"
										)}
										isOptionDisabled={(e) => e.isdisabled}
										isClearable
									/>
								</div>
							</div>
						</div>
						<div className="col-sm-6">
							<label>
								<label>
									{this.props.t(
										"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.YEAR_RANGE"
									)}
								</label>
							</label>
							<div className="row">
								<div className="col-sm-6 form-group">
									<Select
										id="min_year"
										value={selectedOptions.min_year}
										onChange={this.handleChange.bind(
											this,
											"min_year"
										)}
										options={minYear}
										name="min_year"
										placeholder={this.props.t(
											"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.MIN_YEAR"
										)}
										isOptionDisabled={(e) => e.isdisabled}
										isClearable
									/>
								</div>
								<div className="col-sm-6 form-group">
									<Select
										id="max_year"
										value={selectedOptions.max_year}
										onChange={this.handleChange.bind(
											this,
											"max_year"
										)}
										options={maxYear}
										name="max_year"
										placeholder={this.props.t(
											"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.MAX_YEAR"
										)}
										isOptionDisabled={(e) => e.isdisabled}
										isClearable
									/>
								</div>
							</div>
						</div>
						<div className="col-sm-6 form-group">
							<label>
								{this.props.t(
									"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.MAKE_MODEL"
								)}
							</label>
							<Select
								id="make_model"
								value={selectedOptions.make_model}
								onChange={this.handleChange.bind(
									this,
									"make_model"
								)}
								options={mm}
								name="make_model"
								placeholder={this.props.t(
									"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.MAKE_MODEL"
								)}
								isClearable
							/>
						</div>
						<div className="col-sm-6">
							<div className="row">
								<div className="col-sm-6 form-group">
									<label>
										{this.props.t(
											"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.FUEL_TYPE"
										)}
									</label>
									<Select
										id="fuel_type"
										value={selectedOptions.fuel_type}
										onChange={this.handleChange.bind(
											this,
											"fuel_type"
										)}
										options={fuelType}
										name="fuel_type"
										placeholder={this.props.t(
											"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.FUEL_TYPE"
										)}
										isClearable
									/>
								</div>
								<div className="col-sm-6 form-group">
									<label>
										{this.props.t(
											"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.TRANSMISSION"
										)}
									</label>
									<Select
										id="transmission"
										value={selectedOptions.transmission}
										onChange={this.handleChange.bind(
											this,
											"transmission"
										)}
										options={ucTransmission}
										name="transmission"
										placeholder={this.props.t(
											"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.TRANSMISSION"
										)}
										isClearable
									/>
								</div>
							</div>
						</div>
						<div className="col-sm-6">
							<div className="row">
								<div className="col-sm-6 form-group">
									<label>
										{this.props.t(
											"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.BODY_TYPE"
										)}
									</label>
									<Select
										id="body_type"
										value={selectedOptions.body_type}
										onChange={this.handleChange.bind(
											this,
											"body_type"
										)}
										options={bodyType}
										name="body_type"
										placeholder={this.props.t(
											"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.BODY_TYPE"
										)}
										isClearable
									/>
								</div>
								<div className="col-sm-6 form-group">
									<label>
										{this.props.t(
											"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.SEATS"
										)}
									</label>
									<Select
										id="seats"
										value={selectedOptions.seats}
										onChange={this.handleChange.bind(
											this,
											"seats"
										)}
										options={config.constants.NO_SEAT_REQ}
										name="seats"
										placeholder={this.props.t(
											"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.SEATS"
										)}
										isClearable
									/>
								</div>
							</div>
						</div>
						<div className="col-sm-6">
							<div className="row">
								<div className="col-sm-6 form-group">
									<label>
										{this.props.t(
											"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.OWNER"
										)}
									</label>
									<Select
										id="owner"
										value={selectedOptions.owner}
										onChange={this.handleChange.bind(
											this,
											"owner"
										)}
										options={config.constants.OWNER}
										name="owner"
										placeholder={this.props.t(
											"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.OWNER"
										)}
										isClearable
									/>
								</div>
								<div className="col-sm-6 form-group">
									<label>
										{this.props.t(
											"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.COLOR"
										)}
									</label>
									<Select
										id="colors"
										value={selectedOptions.color}
										onChange={this.handleChange.bind(
											this,
											"color"
										)}
										options={config.constants.COLOUR_ARR}
										name="color"
										placeholder={this.props.t(
											"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.COLOR"
										)}
										isClearable
									/>
								</div>
							</div>
						</div>
						{/* <div className="col-sm-6 form-group">
							<Select
								id="city"
								value={selectedOptions.city}
								onChange={this.handleChange.bind(this, "city")}
								options={city}
								name="city"
								placeholder={this.props.t(
									"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.CITY"
								)}
							/>
						</div> */}
						<div className="col-sm-6">
							<div className="row">
								<div className="col-sm-6 form-group">
									<label>
										{this.props.t(
											"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.RECIDING_CITY"
										)}
									</label>
									<Select
										id="residing_city"
										value={selectedOptions.residing_city}
										onChange={this.handleChange.bind(
											this,
											"residing_city"
										)}
										options={city}
										name="residing_city"
										placeholder={this.props.t(
											"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.RECIDING_CITY"
										)}
										isClearable
									/>
								</div>
								<div className="col-sm-6 form-group">
									<label>
										{this.props.t(
											"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.CUSTOMER_LOCALITY"
										)}
									</label>
									<Select
										id="customer_locality"
										value={
											selectedOptions.customer_locality
										}
										onChange={this.handleChange.bind(
											this,
											"customer_locality"
										)}
										options={locality}
										name="customer_locality"
										placeholder={this.props.t(
											"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.CUSTOMER_LOCALITY"
										)}
										isClearable
									/>
								</div>
							</div>
						</div>
						{/* <div className="col-sm-6 form-group">
							<Select
								id="rto_city"
								value={selectedOptions.rto_city}
								onChange={this.handleChange.bind(
									this,
									"rto_city"
								)}
								options={city}
								name="rto_city"
								placeholder={this.props.t(
									"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.RTOAREG"
								)}
							/>
						</div> */}
						{/* <div className="col-sm-6 form-group">
							<label>
								{this.props.t(
									"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.DEALERS"
								)}
							</label>
							<Select
								id="dealer"
								value={selectedOptions.dealer}
								onChange={this.handleChange.bind(
									this,
									"dealer"
								)}
								options={dealers}
								name="dealer"
								placeholder={this.props.t(
									"LEAD_DETAIL_RECOMMENDED_USED.PLACEHOLDERS.DEALERS"
								)}
							/>
						</div> */}
						<div className="col-sm-12 rc-inline">
							<div className="text-bold mrg-b5">Features</div>
							<CheckBox
								label={this.props.t(
									"LEAD_DETAIL_RECOMMENDED_USED.LABEL.PICTURES"
								)}
								name="with_picture"
								id="features-1"
								type="checkbox"
								onChange={this.ChangeCheckbox}
							/>

							<CheckBox
								label={this.props.t(
									"LEAD_DETAIL_RECOMMENDED_USED.LABEL.SUNROOF"
								)}
								name="with_sunroof"
								id="features-2"
								type="checkbox"
								onChange={this.ChangeCheckbox}
							/>
							<CheckBox
								label={this.props.t(
									"LEAD_DETAIL_RECOMMENDED_USED.LABEL.DEALER_CARS"
								)}
								name="dealer_car"
								id="features-3"
								type="checkbox"
								onChange={this.ChangeCheckbox}
							/>
							<CheckBox
								label={this.props.t(
									"LEAD_DETAIL_RECOMMENDED_USED.LABEL.INDIVIDUAL_CAR"
								)}
								type="checkbox"
								name="individual_car"
								id="features-4"
								onChange={this.ChangeCheckbox}
							/>
						</div>
					</div>
					<div className="actionFooter">
						<button
							type="button"
							className="btn btn-primary-outline mrg-r15"
							onClick={() => this.props.modalClose(true)}
						>
							{this.props.t(
								"LEAD_DETAIL_RECOMMENDED_USED.CANCEL"
							)}
						</button>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.sendToUb()}
						>
							{this.props.t(
								"LEAD_DETAIL_RECOMMENDED_USED.SEND_UB"
							)}
						</button>
					</div>
				</form>
			</div>
		);
	}
}
const mapStateToProps = ({ config, inventory }) => {
	return {
		mm: inventory.mm,
		configData: config,
		filterValues: inventory.filterValues,
		locality: inventory.locality,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getMMV: bindActionCreators(InventoryAction.getMMV, dispatch),
			getLocality: bindActionCreators(
				InventoryAction.getLocality,
				dispatch
			),
			getConfigData: bindActionCreators(
				ConfigAction.getConfigData,
				dispatch
			),
			getFilter: bindActionCreators(InventoryAction.getFilter, dispatch),
			sendToUb: bindActionCreators(LeadActions.sendToUb, dispatch),
		},
	};
};
export default withTranslation("lead_detail")(
	connect(mapStateToProps, mapDispatchToProps)(RecommendedForm)
);
