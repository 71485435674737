import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { default as config } from "../../../../config/config";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { connect } from "react-redux";

import { toast } from "react-toastify";
class SearchRetailCreateReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterData: {},
			isSearchButtonDisabled: false,
			process_list: [],
		};
	}

	handleChange = async (sname, ovalue) => {
		const { filterData } = this.state;
		if (ovalue && ovalue.value) {
			///////////////////select case////////////////
			filterData[sname] = ovalue.value;
		} else if (sname instanceof Date) {
			////////////////date picker case////////////////
			filterData[ovalue] = new Date(sname);
		} else {
			delete filterData[sname];
		}
		this.setState({
			filterData: filterData,
		});
	};
	static getDerivedStateFromProps = (nextProps, prevState) => {
		let updatedStateData = {};
		if (
			nextProps.isSearchButtonDisabled !==
			prevState.isSearchButtonDisabled
		) {
			updatedStateData["isSearchButtonDisabled"] =
				nextProps.isSearchButtonDisabled;
		}
		if (
			nextProps.process_list &&
			nextProps.process_list !== prevState.process_list
		) {
			updatedStateData["process_list"] = nextProps.process_list;
		}
		return updatedStateData;
	};
	componentDidMount = () => {};
	resetFilterForm = () => {
		this.setState({
			filterData: {},
		});
	};
	validateFilterData = (filterData) => {
		let error = "";
		if (filterData && !filterData.selected_month)
			error = this.props.t("RETAIL_CREATE_REPORT.ERROR.MONTH_REQUIRED");

		if (error) {
			toast.error(error);
			return false;
		}
		return true;
	};
	submitFilterForm = (event) => {
		if (event) event.preventDefault();
		const { filterData } = this.state;
		//if (this.validateFilterData(filterData)) {
		if (typeof this.props.onSubmitFilter === "function") {
			this.props.onSubmitFilter(filterData);
		}
		//}
	};
	render() {
		const { filterData, isSearchButtonDisabled, process_list } = this.state;
		return (
			<div className="search-wrap">
				<form onSubmit={this.submitFilterForm}>
					<ul className="search-flex">
						<li className="searchitems selectWidth form-group">
							<DatePicker
								className="form-control"
								autoComplete="off"
								placeholderText={this.props.t(
									"RETAIL_CREATE_REPORT.MONTH_PICK"
								)}
								showMonthYearPicker
								name="selected_month"
								id="selected_month"
								onChange={(date) =>
									this.handleChange(date, "selected_month")
								}
								dateFormat={
									config.constants.DATE_FORMAT_INPUT_MONTH
								}
								maxDate={new Date()}
								minDate={new Date("2020-08-31:23:59:59")}
								selected={filterData.selected_month || ""}
								onKeyDown={(e) => e.preventDefault()}
							/>
						</li>

						<li className="searchitems form-group">
							<div>
								<button
									type="submit"
									className="btn btn-primary"
									disabled={isSearchButtonDisabled}
								>
									{this.props.t(
										"RETAIL_CREATE_REPORT.SEARCH"
									)}
								</button>
							</div>
						</li>

						<li className="searchitems form-group">
							<button
								type="reset"
								className="btn btn-default btn-reset"
								onClick={this.resetFilterForm}
							>
								{this.props.t("RETAIL_CREATE_REPORT.RESET")}
							</button>
						</li>
					</ul>
				</form>
			</div>
		);
	}
}
const mapStateToProps = ({ config, lead }) => {
	return {
		process_list:
			config.gm_campaign_process_list instanceof Array
				? config.gm_campaign_process_list.map((e) => {
						return { value: e.campaign_id, label: e.campaign_name };
				  })
				: [],
	};
};

export default withTranslation("report")(
	connect(mapStateToProps, null)(SearchRetailCreateReport)
);
