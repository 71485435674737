import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import config from "../../../../config/config";
import dateformat from "dateformat";

class UpdateDetail extends Component {
	getActivityRelatedData(activityData) {
		let activity = activityData.activity_type;
		let historyId = activityData.history_id;

		let activityQueue = activityData.activity ?? [];

		switch (activity) {
			case "dialer":
				return <></>;
			default:
				return (
					<>
						<div>
							<strong>
								{activityData.activity_type === "dialer_push"
									? this.props.t(
											"HISTORY.TBL_DETAILS.TRIGGERED_ON"
									  )
									: this.props.t(
											"HISTORY.TBL_DETAILS.UPDATED_ON"
									  )}{" "}
								:{" "}
							</strong>{" "}
							{activityData.created_date
								? dateformat(
										activityData.created_date,
										config.constants.DATE_FORMAT
								  )
								: ""}
						</div>
						<div>
							{activityData.activity_source === "crm" ? (
								<strong>
									{this.props.t("HISTORY.TBL_DETAILS.AGENT")}{" "}
								</strong>
							) : activityData.activity_source === "lms" ? (
								activityData.activity_type ===
								"reassign_dse" ? (
									<strong>
										{this.props.t(
											"HISTORY.TBL_DETAILS.LMS_AGENT"
										)}{" "}
									</strong>
								) : (
									<strong>
										{this.props.t(
											"HISTORY.TBL_DETAILS.DSE"
										)}{" "}
									</strong>
								)
							) : activityData.activity_source ===
							  "reassign_dse" ? (
								<strong>
									{this.props.t(
										"HISTORY.TBL_DETAILS.LMS_AGENT"
									)}{" "}
								</strong>
							) : activityData.activity_source === "system" ||
							  activityData.activity_source === "dialer" ? (
								<strong>
									{this.props.t("HISTORY.TBL_DETAILS.SYSTEM")}{" "}
								</strong>
							) : (
								""
							)}{" "}
							:
							{activityData.added_by ? activityData.added_by : ""}
						</div>
					</>
				);
		}
	}
	render() {
		const { activityData } = this.props;

		return <>{this.getActivityRelatedData(activityData)}</>;
	}
}
export default withTranslation("lead_detail")(connect(null)(UpdateDetail));
