import React, { Component } from "react";
import { LeadService } from "../../../../service/leadService";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import PageLoader from "../../../elements/PageLoader";
import EditRolePrivilege from "./EditRolePrivilege";
import ModalPopup from "../../../elements/Popup";
class rolePrivilegeList extends Component {
	constructor(props) {
		super(props);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.state = {
			loading: true,
			roleList: [],
			pagination: this.props.pagination || {},
			filterData: { page: 1 },
			PopOver: false,
			actionType: "status",
			editModal: false,
			role_id: 0,
		};
	}

	componentDidMount = async () => {
		await this.getRoleList();
	};

	getRoleList = async () => {
		this.setState({ loading: true });
		LeadService.getRoleList()
			.then((response) => {
				if (response.status === 200 && response.data.status === 200) {
					let roleList = response.data.data || [];
					let pagination = response.data.pagination || {};

					this.setState({
						roleList: roleList,
						pagination: pagination,
						loading: false,
					});
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	handlePageChange(pageNumber) {
		let { filterData } = this.state;
		filterData["page"] = pageNumber;
		this.setState({ filterData: filterData, loading: true });
		this.getRoleList();
	}

	updateDomainStatus = async (id, status) => {
		this.setState({ loading: true });
		// webFormService
		// 	.saveDomain({ id: id, status: status })
		// 	.then((response) => {
		// 		if (response.status === 200 && response.data.status === 200) {
		// 			this.setState(
		// 				{
		// 					loading: false,
		// 					PopOver: false,
		// 				},
		// 				async () => {
		// 					await this.getdomainList();
		// 				}
		// 			);
		// 		} else {
		// 			this.setState({ loading: false });
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		toast.error(error);
		// 	});
	};

	displayPopUp = (list_id, type) => {
		this.setState({ PopOver: list_id, actionType: type });
	};
	confirmNo = (status_id) => {
		this.setState({ PopOver: false });
	};
	confirmYes = (status_id) => {
		let domain_id = this.state.PopOver;
		let actionType = this.state.actionType,
			status2upd = "2";
		if (actionType !== "delete") status2upd = status_id === "1" ? "0" : "1";
		this.updateDomainStatus(domain_id, status2upd);
	};

	showEditModal = (role_id) => {
		this.setState({ editModal: true, role_id: role_id });
	};

	closeEditModal = () => {
		this.setState({ editModal: false });
	};

	render() {
		const { loading, roleList, pagination, filterData } = this.state;
		return (
			<div>
				<div className="container-fluid">
					<div className="result-wrap">
						<div className="row mrg-b15">
							<div className="col-sm-6">
								<p className="countNo mrg-t5 mrg-b0">
									{pagination.total} -{" "}
									{this.props.t("ROLE.ROLE_LIST")}
								</p>
							</div>
						</div>
						<div className="card">
							<div className="table-responsive">
								{loading && (
									<PageLoader loaderClass="text-center" />
								)}
								<table className="table table-bordered-h table-hover processTable">
									<thead>
										<tr>
											<th>{this.props.t("ROLE.ID")}</th>
											<th>{this.props.t("ROLE.NAME")}</th>

											<th>
												{this.props.t("ROLE.ACTION")}
											</th>
										</tr>
									</thead>

									<tbody>
										{roleList.length > 0 ? (
											roleList.map(
												(singleinfo, index) => (
													<React.Fragment
														key={singleinfo.id}
													>
														<tr>
															<td>
																<div>
																	{singleinfo.id ||
																		""}
																</div>
															</td>

															<td>
																<div>
																	{singleinfo.name ||
																		""}
																</div>
															</td>

															<td className="actionBtn">
																<div>
																	<button
																		type="button"
																		onClick={this.showEditModal.bind(
																			this,
																			singleinfo.id,
																			index
																		)}
																	>
																		<i className="ic-create-24px"></i>
																	</button>
																</div>
															</td>
														</tr>
													</React.Fragment>
												)
											)
										) : (
											<tr>
												<td colSpan="10">
													{!loading ? (
														<h6 className="text-danger">
															{this.props.t(
																"DOMAIN.NOT_FOUND"
															)}
														</h6>
													) : (
														""
													)}
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							{this.state.editModal === false ? (
								""
							) : (
								<ModalPopup
									id="asd"
									className="modal modal-lg"
									title=""
									modalClose={this.closeEditModal}
								>
									<EditRolePrivilege
										role_id={this.state.role_id}
										closeModal={this.closeEditModal}
									/>
								</ModalPopup>
							)}
							<div className="clearfix">
								{pagination.pages >= 1 ? (
									<div className="main-div-pagination">
										<div className="col-sm-6 text-light">
											{this.props.t("DOMAIN.SHOWING")}{" "}
											{filterData.page}{" "}
											{this.props.t("DOMAIN.OF")}{" "}
											{pagination.totalpage}{" "}
											{this.props.t("DOMAIN.PAGES")}
										</div>
										<div className="col-sm-6">
											<Pagination
												activePage={filterData.page}
												itemsCountPerPage={
													pagination.limit
												}
												totalItemsCount={
													pagination.total
												}
												pageRangeDisplayed={5}
												itemClass="page-item"
												linkClass="page-link"
												onChange={this.handlePageChange}
											/>
										</div>
									</div>
								) : (
									""
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation("common")(rolePrivilegeList);
